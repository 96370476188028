/* eslint-disable */

/*
 * @generated 
 * This file was automatically generated and should not be manually edited.
 */


export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BasisPoint: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateRange: { input: [string, string | null]; output: [string, string | null]; }
  DateTime: { input: string; output: string; }
  DateTimeISO: { input: string; output: string; }
  DecimalFraction: { input: string; output: string; }
  FullDate: { input: string; output: string; }
  ISODuration: { input: string; output: string; }
  IdentityNumber: { input: string; output: string; }
  JSON: { input: any; output: any; }
  Money: { input: string; output: string; }
  NaturalPersonIdentifier: { input: string; output: string; }
  PersonalIdentityNumber: { input: string; output: string; }
  Undefined: { input: undefined; output: undefined; }
}

export enum AccidentCoverage {
  /** Insurance covers you in case of accident at any time */
  AROUND_THE_CLOCK = 'AROUND_THE_CLOCK',
  /** Insurance covers you in case of accident at work */
  AT_WORK = 'AT_WORK'
}

export interface AccountingCost {
  readonly __typename?: 'AccountingCost';
  readonly object: AccountingObject;
  readonly share: Scalars['DecimalFraction']['output'];
}

export interface AccountingCostItem {
  readonly __typename?: 'AccountingCostItem';
  readonly accountingCost: ReadonlyArray<AccountingCost>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface AccountingDimension {
  readonly __typename?: 'AccountingDimension';
  readonly id: Scalars['String']['output'];
  readonly identifier: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly objects: Maybe<ReadonlyArray<AccountingObject>>;
}

export interface AccountingItem {
  readonly __typename?: 'AccountingItem';
  readonly accountingObject: AccountingObject;
  readonly from: Scalars['Date']['output'];
  readonly share: Scalars['DecimalFraction']['output'];
}

export interface AccountingObject {
  readonly __typename?: 'AccountingObject';
  readonly dimension: Maybe<AccountingDimension>;
  readonly id: Scalars['String']['output'];
  readonly identifier: Scalars['String']['output'];
  readonly memberships: Maybe<MembershipConnection>;
  readonly name: Maybe<Scalars['String']['output']>;
}


export interface AccountingObjectmembershipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface AccountingObjectConnection {
  readonly __typename?: 'AccountingObjectConnection';
  readonly edges: ReadonlyArray<AccountingObjectEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface AccountingObjectEdge {
  readonly __typename?: 'AccountingObjectEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: AccountingObject;
}

export interface ActivatePlusInput {
  /** Don't use: Ignored by backend */
  readonly acceptsTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
}

export interface ActivatePlusPayload {
  readonly __typename?: 'ActivatePlusPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly plusSubscription: PlusSubscription;
}

export enum ActivityLevel {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE'
}

export interface Actor {
  readonly __typename?: 'Actor';
  readonly displayName: Maybe<Scalars['String']['output']>;
}

export interface AddAccountingDimensionInput {
  readonly companyId: Scalars['String']['input'];
  /** identifier needs to be a number > 20 */
  readonly identifier: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
}

export interface AddAccountingObjectInput {
  readonly companyId: Scalars['String']['input'];
  readonly dimensionId: Scalars['String']['input'];
  readonly identifier: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
}

export interface AddCompanyBillingConfigurationInput {
  readonly attachmentFormats: ReadonlyArray<CompanyBillingAttachmentFormat>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly email: Scalars['String']['input'];
}

export interface AddCompanyBillingConfigurationPayload {
  readonly __typename?: 'AddCompanyBillingConfigurationPayload';
  readonly billingConfiguration: CompanyBillingConfiguration;
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface AddCustomItemInput {
  /** Required if membershipId is missing */
  readonly companyId?: InputMaybe<Scalars['String']['input']>;
  readonly from?: InputMaybe<Scalars['Date']['input']>;
  readonly itemTypeId: Scalars['String']['input'];
  /** Required if companyId or userAccountId is missing. Takes precedence. */
  readonly membershipId?: InputMaybe<Scalars['String']['input']>;
  /** Required if membershipId is missing */
  readonly userAccountId?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['DecimalFraction']['input']>;
}

export interface AddCustomTypeInput {
  readonly companyId: Scalars['String']['input'];
  readonly currency?: InputMaybe<Scalars['String']['input']>;
  readonly superType: SUPER_TYPE;
  readonly typeId: Scalars['String']['input'];
  readonly typeName?: InputMaybe<Scalars['String']['input']>;
  readonly unitCode?: InputMaybe<Scalars['String']['input']>;
}

export interface AddEmploymentActivityInput {
  readonly companyId?: InputMaybe<Scalars['ID']['input']>;
  readonly effectiveDate: Scalars['Date']['input'];
  readonly effectiveUntil?: InputMaybe<Scalars['Date']['input']>;
  /**
   * Either employmentId or companyId + userAccountId is required to identify the membership
   * and find an employment to add the activity to
   */
  readonly employmentId?: InputMaybe<Scalars['ID']['input']>;
  readonly rate?: InputMaybe<Scalars['DecimalFraction']['input']>;
  readonly type: EmploymentActivityType;
  /**
   * If upsert is set and activity matches exactly existing
   * activity lower bound we update the existing one.
   * If there is overlap with an activity we throw.
   * Defaults to false
   */
  readonly upsert?: InputMaybe<Scalars['Boolean']['input']>;
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface AddEmploymentActivityPayload {
  readonly __typename?: 'AddEmploymentActivityPayload';
  readonly employmentActivity: EmploymentActivity;
}

export interface AddEmploymentInput {
  readonly companyId: Scalars['ID']['input'];
  /** Required if upsert is false */
  readonly effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  readonly effectiveUntil?: InputMaybe<Scalars['Date']['input']>;
  readonly hoursWorked?: InputMaybe<Scalars['DecimalFraction']['input']>;
  readonly rate?: InputMaybe<Scalars['DecimalFraction']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly upsert?: InputMaybe<Scalars['Boolean']['input']>;
  readonly userAccountId: Scalars['ID']['input'];
}

export interface AddEmploymentPayload {
  readonly __typename?: 'AddEmploymentPayload';
  readonly employment: Employment;
}

export interface AddMembershipAccountingItemInput {
  readonly accountingObjectId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['String']['input'];
  readonly dimensionId: Scalars['String']['input'];
  readonly effectiveDate: Scalars['Date']['input'];
  readonly userAccountId: Scalars['String']['input'];
}

export interface AddPayrollElementInput {
  /**  Amount in 6+2 digits  */
  readonly amount: Scalars['Money']['input'];
  /**  Nullable but required. Allowed to be null for when membershipId is supported.  */
  readonly companyId: Scalars['ID']['input'];
  /**  Will be shown in payroll file  */
  readonly description: Scalars['String']['input'];
  /**  First day  */
  readonly from: Scalars['Date']['input'];
  /**  The element code. Used for accounting and similar processing.  */
  readonly payrollElementAccountCode: PayrollElementAccountCodes;
  /**  An ID like string grouping this with other lines, like 'salary_exchanges_2022-01'  */
  readonly source: Scalars['String']['input'];
  /**  Last day, inclusive  */
  readonly until: Scalars['Date']['input'];
  /**  Nullable but required. Allowed to be null for when membershipId is supported.  */
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface AddPayrollElementPayload {
  readonly __typename?: 'AddPayrollElementPayload';
  readonly amount: Scalars['Money']['output'];
  readonly companyId: Scalars['ID']['output'];
  readonly description: Maybe<Scalars['String']['output']>;
  readonly from: Scalars['Date']['output'];
  readonly payrollElementAccountCode: PayrollElementAccountCodes;
  readonly personalIdentityNumber: Maybe<Scalars['String']['output']>;
  readonly reportId: Scalars['Int']['output'];
  readonly source: Scalars['String']['output'];
  readonly transactionNumber: Scalars['Int']['output'];
  /**  Last day, inclusive  */
  readonly until: Maybe<Scalars['Date']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface AdditionalItemProperty {
  readonly __typename?: 'AdditionalItemProperty';
  readonly name: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

/** A physical address somewhere in the world */
export interface Address {
  readonly __typename?: 'Address';
  readonly careOf: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  /** @deprecated Use 'careOf' instead */
  readonly co: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly line1: Maybe<Scalars['String']['output']>;
  /**
   * "
   * Line 2 is required in practice, but it currently breaks company so it's
   * temporarily nullable
   */
  readonly line2: Maybe<Scalars['String']['output']>;
  readonly postalCode: Scalars['String']['output'];
  /** Indicates whether the address has been verified in SPAR, Statens Personadressregister */
  readonly sparVerified: Scalars['Boolean']['output'];
}

export interface AdminUser {
  readonly __typename?: 'AdminUser';
  /** A unique user identifier such as a Nordea ID or Google account email. */
  readonly externalId: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
}

export interface AdministratorInput {
  readonly email: Scalars['String']['input'];
  readonly isOwner: Scalars['Boolean']['input'];
  /** 12 digits without separator */
  readonly personalIdentityNumber: Scalars['String']['input'];
  readonly phone: Scalars['String']['input'];
}

export interface AdministratorInput2 {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly isOwner: Scalars['Boolean']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['input'];
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly role: MembershipRole;
}

export interface Advisor {
  readonly __typename?: 'Advisor';
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
}

export interface Agreement {
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementActiway extends Agreement {
  readonly __typename?: 'AgreementActiway';
  readonly actiwayId: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementAdminPoa extends Agreement {
  readonly __typename?: 'AgreementAdminPoa';
  readonly adminUserAccountId: Scalars['ID']['output'];
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementAttachment {
  readonly __typename?: 'AgreementAttachment';
  readonly name: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface AgreementCustom extends Agreement {
  readonly __typename?: 'AgreementCustom';
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementDocument {
  readonly __typename?: 'AgreementDocument';
  readonly httpUrl: Scalars['String']['output'];
}

export interface AgreementDocumentRecord {
  readonly __typename?: 'AgreementDocumentRecord';
  readonly offer: File;
  readonly otherDocuments: ReadonlyArray<File>;
}

export interface AgreementEuroAccident extends Agreement {
  readonly __typename?: 'AgreementEuroAccident';
  readonly agreementNumber: Scalars['String']['output'];
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  readonly euroAccidentAgreementType: Scalars['String']['output'];
  readonly extendedFullyCapableForWorkRequired: Scalars['Boolean']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly qualifyingPeriod: Maybe<AgreementEuroAccidentQualifyingPeriod>;
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  readonly startDate: Scalars['Date']['output'];
  readonly status: AgreementStatus;
  readonly subAgreementNumber: Scalars['String']['output'];
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export enum AgreementEuroAccidentQualifyingPeriod {
  MONTHS_12 = 'MONTHS_12',
  NONE = 'NONE'
}

export enum AgreementEuroAccidentSubAgreementType {
  GRUPP = 'GRUPP',
  TJP = 'TJP'
}

export interface AgreementLink {
  readonly __typename?: 'AgreementLink';
  readonly name: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
}

export interface AgreementNordea extends Agreement {
  readonly __typename?: 'AgreementNordea';
  /** @deprecated No longer supported */
  readonly agreementNumber: Scalars['String']['output'];
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  readonly feeDiscounts: Maybe<NLPAgreementFeeDiscounts>;
  readonly fundFeeDiscountType: Maybe<NLPFundFeeDiscountType>;
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly pensionPlanGroups: Maybe<ReadonlyArray<Maybe<NLPAgreementPensionPlanGroup>>>;
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Scalars['Date']['output'];
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementNordnet extends Agreement {
  readonly __typename?: 'AgreementNordnet';
  /** @deprecated No longer supported */
  readonly agreementNumber: Scalars['String']['output'];
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Scalars['Date']['output'];
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementRequest {
  readonly __typename?: 'AgreementRequest';
  readonly advinansBaseAgreement: Scalars['Boolean']['output'];
  readonly advinansPlusAgreement: Scalars['Boolean']['output'];
  readonly advinansPowerOfAttorney: Maybe<AgreementDocument>;
  readonly createdAt: Scalars['String']['output'];
  readonly euroAccidentAgreement: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['String']['output']>;
  readonly status: AgreementRequestStatus;
}

export interface AgreementRequestAdminPoa extends Agreement {
  readonly __typename?: 'AgreementRequestAdminPoa';
  readonly adminUserAccountId: Scalars['ID']['output'];
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementRequestEuroAccident extends Agreement {
  readonly __typename?: 'AgreementRequestEuroAccident';
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementRequestNordea extends Agreement {
  readonly __typename?: 'AgreementRequestNordea';
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export interface AgreementRequestNordnet extends Agreement {
  readonly __typename?: 'AgreementRequestNordnet';
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export enum AgreementRequestStaticAttachmentsType {
  ADVINANS = 'ADVINANS',
  EURO_ACCIDENT_GRUPP = 'EURO_ACCIDENT_GRUPP',
  EURO_ACCIDENT_TJP = 'EURO_ACCIDENT_TJP'
}

export enum AgreementRequestStatus {
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED'
}

export interface AgreementRequestSuperAdmin extends Agreement {
  readonly __typename?: 'AgreementRequestSuperAdmin';
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export enum AgreementStatus {
  /** Agreement is active */
  ACTIVE = 'ACTIVE',
  /** Agreement request was canceled. It cannot be updated. */
  CANCELED = 'CANCELED',
  /** @deprecated No longer supported */
  FAILED = 'FAILED',
  /** Agreement is no longer active */
  INACTIVE = 'INACTIVE',
  /** Agreement request could not be activated */
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  /** @deprecated No longer supported */
  PENDING = 'PENDING',
  /** Agreement request is signed and is pending activation */
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  /** Agreement request is awaiting signatory approval. It cannot be updated. */
  PENDING_SIGNATORIES = 'PENDING_SIGNATORIES',
  /** Agreement request is being prepared and can still be updated */
  PREPARATION = 'PREPARATION',
  /** @deprecated No longer supported */
  SIGNED = 'SIGNED'
}

export interface AgreementSuperAdmin extends Agreement {
  readonly __typename?: 'AgreementSuperAdmin';
  /** @deprecated No longer supported */
  readonly agreementNumber: Maybe<Scalars['String']['output']>;
  readonly attachments: ReadonlyArray<AgreementAttachment>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly httpURL: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly signedAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: AgreementStatus;
  /** @deprecated No longer supported */
  readonly subAgreementNumber: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  readonly type: AgreementType;
  /** @deprecated No longer supported */
  readonly version: Scalars['Int']['output'];
}

export enum AgreementType {
  ACTIWAY = 'ACTIWAY',
  ADMIN_POA = 'ADMIN_POA',
  /** @deprecated No longer supported */
  ADVINANS_BASE = 'ADVINANS_BASE',
  /** @deprecated No longer supported */
  ADVINANS_PLUS = 'ADVINANS_PLUS',
  EURO_ACCIDENT = 'EURO_ACCIDENT',
  /** @deprecated No longer supported */
  EURO_ACCIDENT_GRUPP = 'EURO_ACCIDENT_GRUPP',
  /** @deprecated No longer supported */
  EURO_ACCIDENT_TJP = 'EURO_ACCIDENT_TJP',
  NORDEA = 'NORDEA',
  NORDNET = 'NORDNET',
  /** @deprecated No longer supported */
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  /** @deprecated No longer supported */
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export interface AnalysisResult {
  readonly __typename?: 'AnalysisResult';
  readonly inactiveEmployments: AnalysisResultInactiveEmploymentConnection;
  readonly missingEmployments: AnalysisResultMissingEmploymentConnection;
  readonly overlappingEmployments: AnalysisResultOverlappingEmploymentConnection;
}


export interface AnalysisResultinactiveEmploymentsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface AnalysisResultmissingEmploymentsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface AnalysisResultoverlappingEmploymentsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface AnalysisResultInactiveEmployment {
  readonly __typename?: 'AnalysisResultInactiveEmployment';
  readonly companyId: Scalars['String']['output'];
  readonly employmentStart: Scalars['String']['output'];
  readonly employmentUntil: Maybe<Scalars['String']['output']>;
  readonly index: Scalars['Int']['output'];
  readonly membership: Maybe<Membership>;
  readonly userAccountId: Maybe<Scalars['String']['output']>;
}

export interface AnalysisResultInactiveEmploymentConnection {
  readonly __typename?: 'AnalysisResultInactiveEmploymentConnection';
  readonly edges: ReadonlyArray<AnalysisResultInactiveEmploymentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface AnalysisResultInactiveEmploymentEdge {
  readonly __typename?: 'AnalysisResultInactiveEmploymentEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: AnalysisResultInactiveEmployment;
}

export interface AnalysisResultMissingEmployment {
  readonly __typename?: 'AnalysisResultMissingEmployment';
  readonly membership: Membership;
}

export interface AnalysisResultMissingEmploymentConnection {
  readonly __typename?: 'AnalysisResultMissingEmploymentConnection';
  readonly edges: ReadonlyArray<AnalysisResultMissingEmploymentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface AnalysisResultMissingEmploymentEdge {
  readonly __typename?: 'AnalysisResultMissingEmploymentEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: AnalysisResultMissingEmployment;
}

export interface AnalysisResultOverlappingEmployment {
  readonly __typename?: 'AnalysisResultOverlappingEmployment';
  readonly companyId: Scalars['String']['output'];
  readonly employmentStart: Scalars['String']['output'];
  readonly employmentUntil: Maybe<Scalars['String']['output']>;
  readonly index: Scalars['Int']['output'];
  readonly membership: Membership;
  readonly userAccountId: Scalars['String']['output'];
}

export interface AnalysisResultOverlappingEmploymentConnection {
  readonly __typename?: 'AnalysisResultOverlappingEmploymentConnection';
  readonly edges: ReadonlyArray<AnalysisResultOverlappingEmploymentEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface AnalysisResultOverlappingEmploymentEdge {
  readonly __typename?: 'AnalysisResultOverlappingEmploymentEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: AnalysisResultOverlappingEmployment;
}

export enum AspectRatio {
  /** 21:9 aspect ratio */
  CINEMATIC = 'CINEMATIC',
  /** 1:1 aspect ratio */
  SQUARE = 'SQUARE',
  /** 16:9 aspect ratio */
  WIDESCREEN = 'WIDESCREEN'
}

export interface AssertFullyCapableForWorkInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly effectiveDate: Scalars['Date']['input'];
  readonly infoText?: InputMaybe<Scalars['String']['input']>;
  readonly userAccountId: Scalars['ID']['input'];
}

export interface AssertFullyCapableForWorkPayload {
  readonly __typename?: 'AssertFullyCapableForWorkPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface AttachedFile {
  readonly __typename?: 'AttachedFile';
  readonly filename: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly url: Scalars['String']['output'];
}

export enum AuthenticationMethod {
  BACKSTAGE_IMPERSONATION = 'BACKSTAGE_IMPERSONATION',
  BANKID = 'BANKID',
  EMAIL = 'EMAIL'
}

export interface Author {
  readonly __typename?: 'Author';
  readonly adminUserExternalId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly userAccountId: Maybe<Scalars['ID']['output']>;
}

export interface AuthorInput {
  readonly adminUserExternalId?: InputMaybe<Scalars['String']['input']>;
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface BalanceItem {
  readonly __typename?: 'BalanceItem';
  readonly currency: Maybe<Scalars['String']['output']>;
  readonly from: Maybe<Scalars['Date']['output']>;
  readonly type: BalanceItemType;
  readonly unitCode: Maybe<Scalars['String']['output']>;
  readonly value: Maybe<Scalars['Money']['output']>;
}

export interface BalanceItemType {
  readonly __typename?: 'BalanceItemType';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface BankIdTicketFailure {
  readonly __typename?: 'BankIdTicketFailure';
  readonly status: Scalars['String']['output'];
}

export interface BankIdTicketProgress {
  readonly __typename?: 'BankIdTicketProgress';
  readonly qrData: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
}

export interface Benchmark {
  readonly __typename?: 'Benchmark';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly performance: ReadonlyArray<BenchmarkPerformanceItem>;
  /** Precomputed periodic returns for the benchmark. */
  readonly periodicReturns: Maybe<ReadonlyArray<BenchmarkPeriodicReturn>>;
  /** Total return index represents price series for a benchmark */
  readonly totalReturnIndex: ReadonlyArray<PriceItem>;
}


export interface BenchmarkperformanceArgs {
  benchmarkIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface BenchmarktotalReturnIndexArgs {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}

export interface BenchmarkPerformanceItem {
  readonly __typename?: 'BenchmarkPerformanceItem';
  readonly at: Scalars['Date']['output'];
  /** Expressed in basis points (100 basis points = 1%) */
  readonly value: Scalars['Float']['output'];
}

export interface BenchmarkPeriodicReturn {
  readonly __typename?: 'BenchmarkPeriodicReturn';
  readonly asOf: Maybe<Scalars['Date']['output']>;
  /** Precision of 4 */
  readonly return1M: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return1Y: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return3M: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return3Y: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return5Y: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return6M: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly returnSinceStart: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly returnYTD: Maybe<Scalars['DecimalFraction']['output']>;
}

export interface BenefitPackage {
  readonly __typename?: 'BenefitPackage';
  readonly author: Maybe<Scalars['String']['output']>;
  readonly benefits: FlexBenefitConnection;
  readonly company: Company;
  readonly companyId: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly employerContributionsAvailable: Scalars['Boolean']['output'];
  readonly hasBenefitPortal: Scalars['Boolean']['output'];
  readonly hidden: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly memberships: Maybe<MembershipConnection>;
  readonly name: Scalars['String']['output'];
  readonly showTotalCompensation: Scalars['Boolean']['output'];
  /** @deprecated Will always be empty */
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
}


export interface BenefitPackagebenefitsArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
  onlyGetBenefits?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface BenefitPackagemembershipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface BenefitPackageConnection {
  readonly __typename?: 'BenefitPackageConnection';
  readonly edges: ReadonlyArray<BenefitPackageEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface BenefitPackageCost {
  readonly __typename?: 'BenefitPackageCost';
  readonly employersPension: Maybe<Scalars['Money']['output']>;
  readonly occupationalPension: Maybe<Scalars['Money']['output']>;
  /** The total accident cost */
  readonly riskAccident: Maybe<Scalars['Money']['output']>;
  /** The total healthcare cost */
  readonly riskHealthcare: Maybe<Scalars['Money']['output']>;
  /** The total life cost */
  readonly riskLife: Maybe<Scalars['Money']['output']>;
  /** The total pension premium waiver cost */
  readonly riskPensionPremiumWaiver: Maybe<Scalars['Money']['output']>;
  /** The total sickness cost */
  readonly riskSickness: Maybe<Scalars['Money']['output']>;
  /** The total TGL cost */
  readonly riskTgl: Maybe<Scalars['Money']['output']>;
  /** The total risk cost */
  readonly riskTotal: Maybe<Scalars['Money']['output']>;
  /** The total risk cost */
  readonly total: Scalars['Money']['output'];
}

export interface BenefitPackageEdge {
  readonly __typename?: 'BenefitPackageEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: BenefitPackage;
}

export interface BenefitPackageItem {
  readonly __typename?: 'BenefitPackageItem';
  readonly benefitPackage: Maybe<M2BenefitPackage>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export enum BenefitType {
  ACCIDENT_INSURANCE = 'ACCIDENT_INSURANCE',
  HEALTHCARE_INSURANCE = 'HEALTHCARE_INSURANCE',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  PENSION = 'PENSION',
  PREMIUM_WAIVER = 'PREMIUM_WAIVER',
  SICKNESS_INSURANCE = 'SICKNESS_INSURANCE',
  TGL = 'TGL'
}

export interface BookingLink {
  readonly __typename?: 'BookingLink';
  readonly link: Scalars['String']['output'];
}

export interface BookkeepingAccount {
  readonly __typename?: 'BookkeepingAccount';
  readonly accountKey: BookkeepingAccountKey;
  readonly customAccountName: Maybe<Scalars['String']['output']>;
  readonly customAccountNumber: Maybe<Scalars['String']['output']>;
}

export interface BookkeepingAccountConnection {
  readonly __typename?: 'BookkeepingAccountConnection';
  readonly edges: ReadonlyArray<BookkeepingAccountEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface BookkeepingAccountEdge {
  readonly __typename?: 'BookkeepingAccountEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: BookkeepingAccount;
}

export enum BookkeepingAccountKey {
  ACTIWAY_ACCOUNTS_PAYABLE = 'ACTIWAY_ACCOUNTS_PAYABLE',
  ADVINANS_ACCOUNTS_PAYABLE = 'ADVINANS_ACCOUNTS_PAYABLE',
  ADVINANS_PERSONAL_ADVICE_COST = 'ADVINANS_PERSONAL_ADVICE_COST',
  ADVINANS_PLUS_COST = 'ADVINANS_PLUS_COST',
  BENEFIT_PORTAL_COST = 'BENEFIT_PORTAL_COST',
  COMPANY_PORTAL_COST = 'COMPANY_PORTAL_COST',
  ESTIMATED_SPECIAL_PAYROLL_TAX_ON_PENSION = 'ESTIMATED_SPECIAL_PAYROLL_TAX_ON_PENSION',
  EUROACCIDENT_ACCOUNTS_PAYABLE = 'EUROACCIDENT_ACCOUNTS_PAYABLE',
  /** Deprecated BookkeepingAccountKey used in custom account mapping */
  FORETAG_STOR_COST = 'FORETAG_STOR_COST',
  FUTUR_ACCOUNTS_PAYABLE = 'FUTUR_ACCOUNTS_PAYABLE',
  GROUP_ACCIDENT_INSURANCE_PREMIUMS = 'GROUP_ACCIDENT_INSURANCE_PREMIUMS',
  GROUP_LIFE_INSURANCE_PREMIUMS = 'GROUP_LIFE_INSURANCE_PREMIUMS',
  INCOMING_VAT_SWEDEN = 'INCOMING_VAT_SWEDEN',
  LF_ACCOUNTS_PAYABLE = 'LF_ACCOUNTS_PAYABLE',
  MEDICAL_AND_HEALTH_SERVICES_DEDUCTIBLE = 'MEDICAL_AND_HEALTH_SERVICES_DEDUCTIBLE',
  NLP_ACCOUNTS_PAYABLE = 'NLP_ACCOUNTS_PAYABLE',
  NORDEA_NODE_ACCOUNTS_PAYABLE = 'NORDEA_NODE_ACCOUNTS_PAYABLE',
  NORDEA_NODE_PERSONAL_ADVICE_COST = 'NORDEA_NODE_PERSONAL_ADVICE_COST',
  NORDNET_ACCOUNTS_PAYABLE = 'NORDNET_ACCOUNTS_PAYABLE',
  PAYROLL_TAX_ON_PENSION_COSTS = 'PAYROLL_TAX_ON_PENSION_COSTS',
  PENSION_INSURANCE_PREMIUMS = 'PENSION_INSURANCE_PREMIUMS',
  PREMIUMS_FOR_SOCIAL_INSURANCE = 'PREMIUMS_FOR_SOCIAL_INSURANCE',
  PREMIUM_WAIVER_INSURANCE_PREMIUMS = 'PREMIUM_WAIVER_INSURANCE_PREMIUMS',
  PREPAID_AMOUNT = 'PREPAID_AMOUNT',
  ROUNDING_OFF = 'ROUNDING_OFF',
  SALARY_EXCHANGE_PREMIUMS = 'SALARY_EXCHANGE_PREMIUMS',
  SICKNESS_INSURANCE_PREMIUMS = 'SICKNESS_INSURANCE_PREMIUMS',
  SURVIVORS_PENSION_INSURANCE_PREMIUMS = 'SURVIVORS_PENSION_INSURANCE_PREMIUMS'
}

export interface BuyPlusInput {
  /** Don't use: Ignored by backend */
  readonly acceptsTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly purchaseContext?: InputMaybe<PurchaseContext>;
}

export interface BuyPlusPayload {
  readonly __typename?: 'BuyPlusPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  /** URL to an external party where you type in payment details to buy Plus */
  readonly url: Scalars['String']['output'];
}

export interface CancelInput {
  /** DEPRECATED: use ticketId. BankID order reference to cancel. */
  readonly orderRef?: InputMaybe<Scalars['ID']['input']>;
  /** Internal ticket reference to cancel. */
  readonly ticketId?: InputMaybe<Scalars['ID']['input']>;
}

export interface CancelLoginInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly ticketId: Scalars['ID']['input'];
}

export interface CancelLoginPayload {
  readonly __typename?: 'CancelLoginPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface CancelMeetingInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface CancelMeetingPayload {
  readonly __typename?: 'CancelMeetingPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface CancelPayload {
  readonly __typename?: 'CancelPayload';
  readonly orderRef: Maybe<Scalars['ID']['output']>;
  readonly ticketId: Maybe<Scalars['ID']['output']>;
}

export interface CancelSignPlusInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly ticketId: Scalars['ID']['input'];
}

export interface CancelSignPlusPayload {
  readonly __typename?: 'CancelSignPlusPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface CancelSignPoaInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly ticketId: Scalars['ID']['input'];
}

export interface CancelSignPoaPayload {
  readonly __typename?: 'CancelSignPoaPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export enum ChildrenStatus {
  /** At least one child under 20 years of age */
  CHILDREN = 'CHILDREN',
  /** No children */
  NO_CHILDREN = 'NO_CHILDREN',
  /** All children are at least 20 years of age */
  ONLY_ADULT_CHILDREN = 'ONLY_ADULT_CHILDREN'
}

export enum CivilStatus {
  /** @deprecated Use COHABITING instead */
  COHABITATING = 'COHABITATING',
  /** Living with someone in a relationship without being married */
  COHABITING = 'COHABITING',
  /** Being married */
  MARRIED = 'MARRIED',
  /** Living alone */
  SINGLE = 'SINGLE'
}

export interface ClaimPensionTransferTasksInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly pensionTransferTaskIds: ReadonlyArray<Scalars['Int']['input']>;
}

export interface ClaimPensionTransferTasksPayload {
  readonly __typename?: 'ClaimPensionTransferTasksPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface ClearingCode {
  readonly __typename?: 'ClearingCode';
  readonly code: Scalars['String']['output'];
  readonly displayName: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
}

export enum CollectiveAgreement {
  /** AIP */
  AIP = 'AIP',
  /** AKAP-KL */
  AKAPKL = 'AKAPKL',
  /** AKAP-KR */
  AKAPKR = 'AKAPKR',
  /** BTP 2 */
  BTP = 'BTP',
  /** BTP 1 */
  BTP1 = 'BTP1',
  /** BTP 1, hängavtal */
  BTP1X = 'BTP1X',
  /** FTP 2 */
  FTP = 'FTP',
  /** FTP 1 */
  FTP1 = 'FTP1',
  /** ITP 2 */
  ITP = 'ITP',
  /** ITP 1 */
  ITP1 = 'ITP1',
  /** KAP (KFO-LO) */
  KAP = 'KAP',
  /** KAP-KL */
  KAPKL = 'KAPKL',
  /** KTP 2 */
  KTP = 'KTP',
  /** KTP 1 */
  KTP1 = 'KTP1',
  /** PA 16 avdelning 1 */
  PA161 = 'PA161',
  /** PA 16 avdelning 2 */
  PA162 = 'PA162',
  /** PA-KFS 09 */
  PAKFS09 = 'PAKFS09',
  /** PPA 13 */
  PPA13 = 'PPA13',
  /** SAF-LO */
  SAFLO = 'SAFLO'
}

export enum CollectiveAgreementOption {
  NO = 'NO',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  YES = 'YES'
}

export interface CollectiveAgreementType {
  readonly __typename?: 'CollectiveAgreementType';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface CompaniesOrderBy {
  readonly direction: CompaniesOrderDirection;
  readonly field: CompaniesOrderByFields;
}

export enum CompaniesOrderByFields {
  NAME = 'NAME',
  PROPOSAL_STATUS = 'PROPOSAL_STATUS'
}

export enum CompaniesOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface Company {
  readonly __typename?: 'Company';
  /** Int value between 1 and 12, representing the month of account closure for the company */
  readonly accountClosureMonth: Maybe<Scalars['Int']['output']>;
  readonly accountNumber: Maybe<Scalars['String']['output']>;
  readonly address: Address;
  readonly administrators: Maybe<MembershipConnection>;
  readonly agreements: ReadonlyArray<Agreement>;
  readonly benefitPackages: Maybe<BenefitPackageConnection>;
  readonly benefitSuppliers: Maybe<ReadonlyArray<FlexBenefitSupplier>>;
  /** Whether benefit package date should default to employment start for new memberships when importing */
  readonly benefitsStartWithEmployment: Scalars['Boolean']['output'];
  readonly billingConfigurations: CompanyBillingConfigurationConnection;
  /** List of bookkeeping accounts. If includeDefault is true, show all (not just customer specific) */
  readonly bookkeepingAccounts: Maybe<BookkeepingAccountConnection>;
  /** @deprecated No longer supported */
  readonly bucketName: Maybe<Scalars['String']['output']>;
  readonly clearingCode: Maybe<ClearingCode>;
  readonly companyAnalytics: Maybe<CompanyAnalytics>;
  readonly costCenters: Maybe<CostCenterConnection>;
  /** Create new organizational units from import files */
  readonly createOuFromImport: Scalars['Boolean']['output'];
  readonly customTypes: Maybe<CustomTypeConnection>;
  /** @deprecated No longer supported */
  readonly defaultCostCenter: Maybe<CostCenter>;
  readonly displayName: Scalars['String']['output'];
  readonly email: Maybe<Scalars['String']['output']>;
  readonly emailLoginAllowed: Maybe<Scalars['Boolean']['output']>;
  readonly employeeEmailLanguage: Maybe<Language>;
  readonly fcfwRequests: FlexFcfwRequestConnection;
  readonly flexBenefits: Maybe<FlexBenefitConnection>;
  /** Date which company identity was registered with Bolagsverket */
  readonly foundedAt: Maybe<Scalars['Date']['output']>;
  readonly fullyCapableForWorkInfo: Maybe<FullyCapableForWorkInfo>;
  readonly fullyCapableForWorkRequests: ReadonlyArray<FullyCapableForWorkRequest>;
  readonly hasCompanyGroup: Maybe<Scalars['Boolean']['output']>;
  readonly hasJobOffers: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly imports: ImportConnection;
  readonly internalPostTags: ReadonlyArray<Scalars['String']['output']>;
  readonly internalPosts: Maybe<InternalPostConnection>;
  readonly invoiceLineItemNames: ReadonlyArray<Scalars['String']['output']>;
  readonly invoiceSuppliers: ReadonlyArray<InvoiceSupplier>;
  readonly invoices: InvoiceConnection;
  /** View all job offers, SUPER & HR can view all, CONTENT can only view authored */
  readonly jobOffers: Maybe<JobOfferConnection>;
  readonly latestProposalStatus: Maybe<ProposalStatus>;
  readonly legalEntityType: LegalEntityType;
  /**
   * The earliest date and time from which a session can be created for the company
   * @deprecated Use status/statuses
   */
  readonly loginAllowedFrom: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use logoUrl */
  readonly logo: Maybe<Scalars['String']['output']>;
  readonly logoUrl: Maybe<Scalars['String']['output']>;
  readonly managedBy: ManagingCompany;
  readonly membershipExportUrl: Maybe<Scalars['String']['output']>;
  readonly memberships: Maybe<MembershipConnection>;
  readonly name: Scalars['String']['output'];
  /**
   * Whether the company has opted out from its employees to receive our
   * newsletter emails.
   */
  readonly newsletterOptOut: Maybe<Scalars['Boolean']['output']>;
  readonly nlpSalesRep: Maybe<AdminUser>;
  readonly notifications: Maybe<Scalars['Int']['output']>;
  readonly organizationalUnits: Maybe<OrganizationalUnitConnection>;
  readonly paymentMethod: Maybe<PaymentMethod>;
  /** Imported payslip files, ordered from latest to first */
  readonly payslipImports: Maybe<PayslipImportConnection>;
  readonly payslips: Maybe<PayslipConnection>;
  readonly pensionProviders: FlexPensionProvider;
  /** @deprecated No longer supported */
  readonly persons: Maybe<PersonConnection>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly registrationNumber: Scalars['String']['output'];
  readonly remunerationTypes: Maybe<RemunerationTypeConnection>;
  readonly salaryReports: SalaryReportConnection;
  /** Int value between 1 and 12, representing the month of salary review for the company */
  readonly salaryReviewMonth: Maybe<Scalars['Int']['output']>;
  readonly settings: CompanySettings;
  readonly signatory: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<CompanyStatus>;
  readonly statuses: ReadonlyArray<CompanyStatus>;
  readonly supplierReferenceMapping: Maybe<SupplierReferenceMapping>;
  readonly totalCompensationConfiguration: Maybe<TotalCompensationConfiguration>;
  readonly uploadedFiles: Maybe<ReadonlyArray<UploadedCompanyFileInfoData>>;
  /** @deprecated No longer supported */
  readonly userAccounts: ReadonlyArray<Maybe<Person>>;
  readonly vacationDaysPaidIncludesVacationDaysSaved: Scalars['Boolean']['output'];
  /**
   * Returns null if you are not allowed to read the company's kyc status.
   *
   * Returns true if the company either has valid kyc or an exemption. Returns
   * false if the company has no exemption and invalid kyc.
   */
  readonly validKyc: Maybe<Scalars['Boolean']['output']>;
}


export interface CompanyadministratorsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  showInternalAdmins?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface CompanyagreementsArgs {
  status?: InputMaybe<AgreementStatus>;
  type?: InputMaybe<AgreementType>;
}


export interface CompanybenefitPackagesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface CompanybillingConfigurationsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanybookkeepingAccountsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeDefault?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanycostCentersArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  identifiers?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanycustomTypesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  superType?: InputMaybe<SUPER_TYPE>;
}


export interface CompanyflexBenefitsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  onlyGetBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  onlyGetCategories?: InputMaybe<Scalars['Boolean']['input']>;
  onlyGetDiscounts?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface CompanyfullyCapableForWorkRequestsArgs {
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface CompanyimportsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanyinternalPostsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  local?: InputMaybe<Scalars['Boolean']['input']>;
  orgUnitIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  publishedSince?: InputMaybe<Scalars['DateTime']['input']>;
  publishedUntil?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InternalPostStatus>>;
  tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}


export interface CompanyinvoicesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InvoiceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<InvoiceSortBy>;
  sortOrder?: InputMaybe<InvoiceSortOrder>;
}


export interface CompanyjobOffersArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanymembershipExportUrlArgs {
  format?: InputMaybe<MembershipExportFileFormat>;
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface CompanymembershipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  showInternalAdmins?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface CompanypayslipImportsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanypayslipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface CompanypersonsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Order>;
  pids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  userAccountIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}


export interface CompanyremunerationTypesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}


export interface CompanysalaryReportsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface CompanystatusArgs {
  asOf?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface CompanyuserAccountsArgs {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}

export interface CompanyAnalytics {
  readonly __typename?: 'CompanyAnalytics';
  readonly adminUserAccountId: Maybe<Scalars['ID']['output']>;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  /** Current count of employees */
  readonly currentEmployeesCount: Maybe<Scalars['Int']['output']>;
  /** Total AUM brokered by Advinans */
  readonly insuranceAUM: Maybe<Scalars['DecimalFraction']['output']>;
  /** The date of the latest file import for a given company, returns null if multiple companies are given as this stat is not applicable for multiple companies */
  readonly latestFileImportDate: Maybe<Scalars['Date']['output']>;
  /** The status of the latest file import for a given company, returns null if multiple companies are given as this stat is not applicable for multiple companies */
  readonly latestFileImportStatus: Maybe<ImportStatus>;
  /** Total amount of monthly invoiced pension premiums for given companies by month */
  readonly monthlyInvoicedPensionPremiums: Maybe<ReadonlyArray<GraphQLHistoricalInvoicedPremium>>;
  /** Total amount of monthly invoiced risk insurance premiums for given companies by month */
  readonly monthlyInvoicedRiskPremiums: Maybe<ReadonlyArray<GraphQLHistoricalInvoicedPremium>>;
  /** The total monthly pension premiums for Nordea Fund for the given companies. */
  readonly monthlyPensionPremiumsForNordeaFund: Maybe<Scalars['Money']['output']>;
  /** The total monthly pension premiums for Nordea Traditional for the given companies. */
  readonly monthlyPensionPremiumsForNordeaTrad: Maybe<Scalars['Money']['output']>;
  /** The total monthly pension premiums for Nordnet for the given companies. */
  readonly monthlyPensionPremiumsForNordnet: Maybe<Scalars['Money']['output']>;
  readonly newEmployeesLastMonth: Maybe<Scalars['Int']['output']>;
  /** The number of active employments for the given company */
  readonly numberOfActiveEmployments: Maybe<Scalars['Int']['output']>;
  /** The number of active salary exchanges on the given companies. */
  readonly numberOfActiveSalaryExchanges: Maybe<Scalars['Int']['output']>;
  /** Number of advice meetings since 'since'. */
  readonly numberOfAdviceMeetings: Maybe<Scalars['Int']['output']>;
  /** The number of benefit packages for the given company */
  readonly numberOfBenefitPackages: Maybe<Scalars['Int']['output']>;
  /** The number of employees for the given company */
  readonly numberOfEmployees: Maybe<Scalars['Int']['output']>;
  /** The number of employees that are eligible for ongoing salary exchange */
  readonly numberOfEmployeesAbleToSalaryExchange: Maybe<Scalars['Int']['output']>;
  /** Total number of employees that have had at least one advice session in the last 36 months for a company. */
  readonly numberOfEmployeesWithAdviceSessionsLast36Months: Maybe<Scalars['Int']['output']>;
  /** The number of employees on the given companies that have chosen Nordea Fund as their pension provider. */
  readonly numberOfEmployeesWithNordeaFundPension: Maybe<Scalars['Int']['output']>;
  /** The number of employees on the given companies that have chosen Nordea Traditional as their pension provider. */
  readonly numberOfEmployeesWithNordeaTradPension: Maybe<Scalars['Int']['output']>;
  /** The number of employees on the given companies that have chosen Nordnet as their pension provider. */
  readonly numberOfEmployeesWithNordnetPension: Maybe<Scalars['Int']['output']>;
  /** The number of employees that are on off duty absence for the given company */
  readonly numberOfEmployeesWithOffDutyAbsence: Maybe<Scalars['Int']['output']>;
  /** The number of employees that are on parental leave absence for the given company */
  readonly numberOfEmployeesWithParentalLeaveAbsence: Maybe<Scalars['Int']['output']>;
  /** The number of employees without Swedish personal identity number that have a Euro accident A number for the given company */
  readonly numberOfEmployeesWithoutSePinWithANumber: Maybe<Scalars['Int']['output']>;
  /** The number of employees without Swedish personal identity number that have a coordination number for the given company */
  readonly numberOfEmployeesWithoutSePinWithCNumber: Maybe<Scalars['Int']['output']>;
  /** The number of employees without Swedish personal identity number that are entirely without any natural person identifier for the given company */
  readonly numberOfEmployeesWithoutSePinWithNPI: Maybe<Scalars['Int']['output']>;
  /** The number of employees new employees for the given company in the last 30 days */
  readonly numberOfNewEmployeesLast30Days: Maybe<Scalars['Int']['output']>;
  /** The total number of rows in the latest import for the given company, this function returns null if multiple companies are given as this stat is not applicable for multiple companies */
  readonly numberOfRowsInLatestImport: Maybe<Scalars['Int']['output']>;
  /** The number of rows with errors in the latest import for the given company, this function returns null if multiple companies are given as this stat is not applicable for multiple companies */
  readonly numberOfRowsWithErrorsInLatestImport: Maybe<Scalars['Int']['output']>;
  /** Total number of logins for the given company */
  readonly numberOfTotalLogins: Maybe<Scalars['Int']['output']>;
  /** Total number of unique logins for the given company */
  readonly numberOfUniqueLogins: Maybe<Scalars['Int']['output']>;
  /** Accumulated pension capital owned by the given company and brokered by Nordea Node */
  readonly pensionCapital: Maybe<CompanyPensionCapital>;
  /** Percentage of users logged in since 'since' */
  readonly percentageOfUsersLoggedIn: Maybe<Scalars['Float']['output']>;
  /** Total number of advice meetings held the last 12 months for all users on the companies. */
  readonly totalAdviceSessionsLast12Months: Maybe<Scalars['Int']['output']>;
  /** Total monthly salary exchange premiums that given companies pay towards employees with Nordea fund as their chosen provider. */
  readonly totalSalaryExchangePremiumsForNordeaFund: Maybe<Scalars['Money']['output']>;
  /** Total monthly salary exchange premiums that given companies pay towards employees with Nordea traditional management as their chosen provider. */
  readonly totalSalaryExchangePremiumsForNordeaTrad: Maybe<Scalars['Money']['output']>;
  /** Total monthly salary exchange premiums that given companies pay towards employees with Nordnet as their chosen provider. */
  readonly totalSalaryExchangePremiumsForNordnet: Maybe<Scalars['Money']['output']>;
  /** The total wellness allowance available for all the employees at the given companies. */
  readonly totalWellnessAllowanceAvailable: Maybe<Scalars['Money']['output']>;
}


export interface CompanyAnalyticsnumberOfAdviceMeetingsArgs {
  since: Scalars['Date']['input'];
}


export interface CompanyAnalyticspercentageOfUsersLoggedInArgs {
  since: Scalars['Date']['input'];
}

export enum CompanyBillingAttachmentFormat {
  CSV = 'CSV',
  PDF = 'PDF',
  PDF_SUMMARY = 'PDF_SUMMARY',
  SIE4 = 'SIE4',
  XLSX = 'XLSX'
}

export interface CompanyBillingConfiguration {
  readonly __typename?: 'CompanyBillingConfiguration';
  readonly attachmentFormats: ReadonlyArray<CompanyBillingAttachmentFormat>;
  readonly email: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
}

export interface CompanyBillingConfigurationConnection {
  readonly __typename?: 'CompanyBillingConfigurationConnection';
  readonly edges: ReadonlyArray<CompanyBillingConfigurationEdge>;
  readonly pageInfo: PageInfo;
}

export interface CompanyBillingConfigurationEdge {
  readonly __typename?: 'CompanyBillingConfigurationEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: CompanyBillingConfiguration;
}

export interface CompanyConnection {
  readonly __typename?: 'CompanyConnection';
  readonly edges: ReadonlyArray<CompanyEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface CompanyEdge {
  readonly __typename?: 'CompanyEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: Company;
}

export interface CompanyFilters {
  readonly assignedSalesRep?: InputMaybe<Scalars['ID']['input']>;
  readonly clearingCode?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly proposalStatuses?: InputMaybe<ReadonlyArray<ProposalStatusFilter>>;
  readonly smeOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  readonly statuses?: InputMaybe<ReadonlyArray<CompanyStatusStatus>>;
}

export interface CompanyPensionCapital {
  readonly __typename?: 'CompanyPensionCapital';
  readonly averageFees: Maybe<InsuranceComponentCapitalFees>;
  readonly totalCapital: CurrencyValue;
  /** Percentual total returns represented as a decimal with a precision of 2, with values between -1 and 1. */
  readonly totalReturns: Maybe<Scalars['DecimalFraction']['output']>;
  /** Monetary value of total returns */
  readonly totalReturnsEarned: Maybe<CurrencyValue>;
}

export interface CompanyRepresentative {
  readonly __typename?: 'CompanyRepresentative';
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly identificationReference: Maybe<Scalars['String']['output']>;
  readonly identificationType: CompanyRepresentativeIdentificationType;
  readonly lastName: Scalars['String']['output'];
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['output'];
  readonly role: CompanyRepresentativeRole;
}

export enum CompanyRepresentativeIdentificationType {
  BANKID = 'BANKID',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  EU_PASSPORT = 'EU_PASSPORT',
  /** Some other advisor has identified the representative */
  ID_BY_OTHER_ADVISOR = 'ID_BY_OTHER_ADVISOR',
  NATIONAL_ID_CARD = 'NATIONAL_ID_CARD',
  SSI_ID_CARD = 'SSI_ID_CARD'
}

export enum CompanyRepresentativeRole {
  REPRESENTATIVE = 'REPRESENTATIVE',
  SIGNATORY = 'SIGNATORY'
}

export interface CompanySettings {
  readonly __typename?: 'CompanySettings';
  readonly salaryReportCutoffDay: Maybe<Scalars['Int']['output']>;
}

export enum CompanySize {
  GTE_50 = 'GTE_50',
  GTE_250 = 'GTE_250',
  GTE_1000 = 'GTE_1000',
  LT_10 = 'LT_10',
  LT_50 = 'LT_50'
}

export interface CompanyStatus {
  readonly __typename?: 'CompanyStatus';
  readonly effective: Scalars['DateTime']['output'];
  readonly status: CompanyStatusStatus;
}

export enum CompanyStatusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING_ONBOARDING = 'PENDING_ONBOARDING'
}

export interface ComponentPerformanceItem {
  readonly __typename?: 'ComponentPerformanceItem';
  /** Insurance benchmark change in basis points, if applicable */
  readonly benchmark: Maybe<Scalars['Int']['output']>;
  /** Component indexed value change in basis points */
  readonly component: Scalars['Int']['output'];
  readonly date: Scalars['Date']['output'];
  /** Current market value in SEK */
  readonly marketValue: Scalars['Money']['output'];
  /** Total premiums paid into the insurance in SEK */
  readonly totalInvested: Scalars['Money']['output'];
}

export interface ComponentTransaction {
  readonly __typename?: 'ComponentTransaction';
  readonly amount: CurrencyValue;
  readonly component: Maybe<InsuranceComponent>;
  readonly date: Scalars['Date']['output'];
  readonly type: ComponentTransactionType;
}

export interface ComponentTransactionConnection {
  readonly __typename?: 'ComponentTransactionConnection';
  readonly edges: ReadonlyArray<ComponentTransactionEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface ComponentTransactionEdge {
  readonly __typename?: 'ComponentTransactionEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: ComponentTransaction;
}

export enum ComponentTransactionType {
  OTHER = 'OTHER',
  PREMIUM = 'PREMIUM',
  TRANSFER = 'TRANSFER',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum ComponentType {
  EFT = 'EFT',
  INV = 'INV',
  OLD = 'OLD',
  OLY = 'OLY',
  PRE = 'PRE',
  SJU = 'SJU',
  SJV = 'SJV'
}

export interface Configuration {
  readonly data: Maybe<Scalars['JSON']['output']>;
}

export interface ConfirmMeetingInput {
  /** Required if service token. */
  readonly author?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly date: Scalars['Date']['input'];
  readonly eventTypeCategory: EventTypeCategory;
  readonly userAccountId: Scalars['ID']['input'];
}

export interface ConfirmMeetingPayload {
  readonly __typename?: 'ConfirmMeetingPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface ContactReference {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export interface Cost {
  readonly __typename?: 'Cost';
  /** The employer pension cost for the user, null if not entitled to any employers pension benefit */
  readonly employersPension: Maybe<Scalars['Money']['output']>;
  /** The occupational pension cost for the user, null if not entitled to any occupational pension benefit */
  readonly occupationalPension: Maybe<Scalars['Money']['output']>;
  /** The accident cost for the user, null if not entitled */
  readonly riskAccident: Maybe<Scalars['Money']['output']>;
  /** The healthcare cost for the user, null if not entitled */
  readonly riskHealthcare: Maybe<Scalars['Money']['output']>;
  /** The life cost for the user, null if not entitled */
  readonly riskLife: Maybe<Scalars['Money']['output']>;
  /** The pension premium waiver cost for the user, null if not entitled */
  readonly riskPensionPremiumWaiver: Maybe<Scalars['Money']['output']>;
  /** The sickness cost for the user, null if not entitled */
  readonly riskSickness: Maybe<Scalars['Money']['output']>;
  /** The tgl cost for the user, null if not entitled */
  readonly riskTgl: Maybe<Scalars['Money']['output']>;
  /** The total risk cost for the user, null if not entitled */
  readonly riskTotal: Maybe<Scalars['Money']['output']>;
  /** The total cost for the user */
  readonly total: Maybe<Scalars['Money']['output']>;
}

export interface CostCenter {
  readonly __typename?: 'CostCenter';
  readonly company: Company;
  readonly id: Scalars['ID']['output'];
  readonly identifier: Scalars['String']['output'];
  readonly memberships: Maybe<MembershipConnection>;
  readonly name: Scalars['String']['output'];
  /** @deprecated No longer supported */
  readonly persons: Maybe<PersonConnection>;
}


export interface CostCentermembershipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
}


export interface CostCenterpersonsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Order>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  userAccountIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}

export interface CostCenterConnection {
  readonly __typename?: 'CostCenterConnection';
  readonly edges: ReadonlyArray<CostCenterEdge>;
  readonly pageInfo: PageInfo;
}

export interface CostCenterEdge {
  readonly __typename?: 'CostCenterEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: CostCenter;
}

export interface CreateBenefitPackageInput {
  readonly companyId: Scalars['String']['input'];
  readonly hasBenefitPortal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optionally create the benefit package with a predefined id */
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly name: Scalars['String']['input'];
  readonly showTotalCompensation?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CreateBenefitPackagePayload {
  readonly __typename?: 'CreateBenefitPackagePayload';
  readonly benefitPackage: Maybe<BenefitPackage>;
}

export interface CreateCompany2Input {
  /** Int value between 1 and 12, representing the month of account closure for the company */
  readonly accountClosureMonth?: InputMaybe<Scalars['Int']['input']>;
  readonly accountNumber?: InputMaybe<Scalars['String']['input']>;
  /** List of administrators to create, email + phone is required if the role is super */
  readonly administrator?: InputMaybe<ReadonlyArray<AdministratorInput2>>;
  readonly clearingCode?: InputMaybe<Scalars['String']['input']>;
  readonly companyStatusEffective: Scalars['DateTime']['input'];
  readonly eaAgreement?: InputMaybe<EaAgreementInput>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly employeeEmailLanguage?: InputMaybe<Language>;
  readonly hasCompanyGroup?: InputMaybe<Scalars['Boolean']['input']>;
  readonly logo?: InputMaybe<Scalars['String']['input']>;
  readonly nordeaAgreement?: InputMaybe<NordeaAgreementInput>;
  /** Payment method for the company. Used for generating SME onboarding proposals. */
  readonly paymentMethod?: InputMaybe<PaymentMethod>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly registrationNumber: Scalars['IdentityNumber']['input'];
  /** Int value between 1 and 12, representing the month of salary review for the company */
  readonly salaryReviewMonth?: InputMaybe<Scalars['Int']['input']>;
  readonly salesRep?: InputMaybe<Scalars['ID']['input']>;
  readonly smeOnboarding: Scalars['Boolean']['input'];
}

export interface CreateCompany2Payload {
  readonly __typename?: 'CreateCompany2Payload';
  readonly company: Company;
}

export interface CreateCompanyRepresentativeInput {
  /** Given name of the representative */
  readonly firstName: Scalars['String']['input'];
  /** Identification method used, for example drivers license */
  readonly identification: CompanyRepresentativeIdentificationType;
  /** For example, an advisor ID, passport number or drivers license number */
  readonly identificationReference: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['input'];
  readonly proposalId: Scalars['ID']['input'];
  readonly role: CompanyRepresentativeRole;
}

export interface CreateCostCenterInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly identifier: Scalars['String']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly upsert?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CreateCostCenterPayload {
  readonly __typename?: 'CreateCostCenterPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly costCenter: CostCenter;
}

export interface CreateDocumentInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** One or more document contents in different formats */
  readonly content: ReadonlyArray<DocumentContent>;
  /** Document type name */
  readonly documentType: Scalars['String']['input'];
  /** Optional custom labels (tags) */
  readonly labels?: InputMaybe<ReadonlyArray<DocumentLabelInput>>;
  /** Name of the document */
  readonly name: Scalars['String']['input'];
  /**
   * User account ID which will own the document.
   * If not set and user token is used then the user id will be set
   * as the owner. Service accounts should explicitly set owners if
   * required.
   */
  readonly owner?: InputMaybe<Scalars['ID']['input']>;
}

export interface CreateDocumentPayload {
  readonly __typename?: 'CreateDocumentPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly document: Document;
}

export interface CreateImportInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /**
   * For CSV/Excel imports, map columns in source file to these record columns.
   * Use null to skip columns.
   */
  readonly columns?: InputMaybe<ReadonlyArray<InputMaybe<ImportColumn>>>;
  readonly companyId: Scalars['ID']['input'];
  /** If source file is not UTF-8, provide an alternative file encoding, such as "latin1" */
  readonly encoding?: InputMaybe<Scalars['String']['input']>;
  readonly fileId: Scalars['ID']['input'];
  /** Depracated: Has no effect */
  readonly generateChanges?: InputMaybe<Scalars['Boolean']['input']>;
  /** If source CSV does not use "," as a separator, provide an alternative character, such as tab or semicolon */
  readonly separator?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateImportPayload {
  readonly __typename?: 'CreateImportPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly import: Import;
}

export interface CreateInternalPostInput {
  readonly attachmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly body: Scalars['String']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly costCenterIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly description: Scalars['String']['input'];
  readonly heroImageId?: InputMaybe<Scalars['ID']['input']>;
  readonly important?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orgUnitIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly publicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly status?: InputMaybe<InternalPostStatus>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly title: Scalars['String']['input'];
}

export interface CreateInternalPostPayload {
  readonly __typename?: 'CreateInternalPostPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly internalPost: InternalPost;
}

export interface CreateInvoiceInput {
  /** Create invoice for a specific, otherwise creates for all companies */
  readonly companyId?: InputMaybe<Scalars['ID']['input']>;
  /** When to create invoicing for, will use the 1st of the month provided */
  readonly month: Scalars['Date']['input'];
}

export interface CreateInvoicePayload {
  readonly __typename?: 'CreateInvoicePayload';
  readonly success: Scalars['Boolean']['output'];
}

export interface CreateJobOfferInput {
  readonly attachmentFiles?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly benefitPackageId?: InputMaybe<Scalars['String']['input']>;
  readonly birthdate?: InputMaybe<Scalars['Date']['input']>;
  /** Rich text */
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly collectiveAgreement?: InputMaybe<TotalCompensationCollectiveAgreement>;
  readonly companyId: Scalars['ID']['input'];
  readonly coverPictureFile?: InputMaybe<Scalars['String']['input']>;
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly monthlyCompanyCarValue?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlyLunchAllowance?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlySalary?: InputMaybe<Scalars['Money']['input']>;
  readonly optionsProgram?: InputMaybe<Scalars['String']['input']>;
  readonly optionsProgrammeFiles?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly profilePictureFile?: InputMaybe<Scalars['String']['input']>;
  readonly prospectName: Scalars['String']['input'];
  readonly remunerations?: InputMaybe<ReadonlyArray<JobOfferRemunerationInput>>;
  readonly showDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly validUntil?: InputMaybe<Scalars['Date']['input']>;
  readonly workLocation?: InputMaybe<Scalars['String']['input']>;
  readonly workSetting?: InputMaybe<Scalars['String']['input']>;
  readonly yearlyVacationDays?: InputMaybe<Scalars['Int']['input']>;
  readonly yearlyWellnessAllowance?: InputMaybe<Scalars['Money']['input']>;
}

export interface CreateMembershipInput {
  readonly address?: InputMaybe<MembershipAddressInput>;
  readonly benefitPackageEffectiveDate?: InputMaybe<Scalars['Date']['input']>;
  readonly benefitPackageId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use birthdate instead */
  readonly birthDate?: InputMaybe<Scalars['Date']['input']>;
  readonly birthdate?: InputMaybe<Scalars['Date']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly collectiveAgreement?: InputMaybe<Scalars['String']['input']>;
  readonly companyCarMonthlyValue?: InputMaybe<Scalars['Money']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly costCenterId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Use field-specific effective date fields instead */
  readonly effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  /**
   * E-mail is not required, but strongly recommended. Without e-mail, Advinans
   * cannot enable alternative login methods, contact employees or properly
   * manage their support channels.
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly employeeNumber?: InputMaybe<Scalars['String']['input']>;
  readonly employerContributionShare?: InputMaybe<Scalars['DecimalFraction']['input']>;
  readonly employmentEnd?: InputMaybe<Scalars['Date']['input']>;
  readonly employmentStart?: InputMaybe<Scalars['Date']['input']>;
  readonly fitnessContributionBalance?: InputMaybe<Scalars['Money']['input']>;
  /** If givenName is omitted it is read from the userAccount service */
  readonly givenName?: InputMaybe<Scalars['String']['input']>;
  /** Optionally pre-defined ID. Will fail on collision */
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly incomeTaxTable?: InputMaybe<Scalars['Int']['input']>;
  /** Rules for risk insurances are different for company owners. Hence, if this membership represents an owner, this must be provided. Assumed false. */
  readonly isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  /** If lastName is omitted it is read from the userAccount service */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use remunerations instead */
  readonly monthlySalary?: InputMaybe<Scalars['Money']['input']>;
  readonly organizationalUnitId?: InputMaybe<Scalars['ID']['input']>;
  readonly personalIdentityNumber?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  /**
   * A list of remunerations to add for the membership. Will be created in ascending order based on
   * from date, regardless of ordering in the input.
   */
  readonly remunerations?: InputMaybe<ReadonlyArray<MembershipRemunerationItemInput>>;
  readonly roles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  readonly sex?: InputMaybe<Sex>;
  readonly skipWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  readonly vacationDaysAdvance?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysPaid?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysPerYear?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysSaved?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysUnpaid?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateMembershipPayload {
  readonly __typename?: 'CreateMembershipPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly membership: Membership;
}

export interface CreateNoteInput {
  readonly category: NoteCategory;
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly companyId?: InputMaybe<Scalars['ID']['input']>;
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly fileIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly subject: Scalars['String']['input'];
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface CreateNotePayload {
  readonly __typename?: 'CreateNotePayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['String']['output'];
}

export interface CreateOrganizationalUnitInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly name: Scalars['String']['input'];
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly unitOf?: InputMaybe<Scalars['ID']['input']>;
  readonly upsert?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CreateOrganizationalUnitPayload {
  readonly __typename?: 'CreateOrganizationalUnitPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly organizationalUnit: OrganizationalUnit;
}

export interface CreatePlusProfileInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly preferences: PlusPreferencesInput;
}

export interface CreatePlusProfilePayload {
  readonly __typename?: 'CreatePlusProfilePayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly profile: PlusProfile;
}

export interface CreatePreSignedAgreementsInput {
  readonly adminUserAccountId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly createEaGrupp?: InputMaybe<Scalars['Boolean']['input']>;
  readonly createEaTjp?: InputMaybe<Scalars['Boolean']['input']>;
  readonly createNordea?: InputMaybe<Scalars['Boolean']['input']>;
  readonly createNordnet?: InputMaybe<Scalars['Boolean']['input']>;
  /** optionally create ea agreements with predefined agreementType */
  readonly eaAgreementType?: InputMaybe<Scalars['String']['input']>;
  /** Will be passed on to EA. 0-1. Not provided means we get 100% commission */
  readonly eaCommissionFactor?: InputMaybe<Scalars['Float']['input']>;
  /** required if createEaGrupp or eaTjp */
  readonly eaCompanySize?: InputMaybe<CompanySize>;
  /**
   * required if createEaGrupp or eaTjp
   * @deprecated No longer supported
   */
  readonly eaExtendedFcfw?: InputMaybe<Scalars['Boolean']['input']>;
  /** required if createEaGrupp */
  readonly eaGruppAgreementType?: InputMaybe<EAGruppAgreementType>;
  /** required if createEaGrupp or eaTjp */
  readonly eaStartDate?: InputMaybe<Scalars['Date']['input']>;
  /** If to use NLP agreementPlans our not. Defaults to false */
  readonly eaUseNlpAgreementPlan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional agreementId, if this is set only 1 agreement can be created at a time */
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  /** optional if createNordea */
  readonly nlpAdministrativeFeeDiscount?: InputMaybe<ReadonlyArray<InputMaybe<NLPAdministrativeFeeDiscount>>>;
  /** required if createNordea */
  readonly nlpAdvisor?: InputMaybe<Scalars['String']['input']>;
  /** required if createNordea */
  readonly nlpClearingCode?: InputMaybe<Scalars['String']['input']>;
  /** required if createNordea, 1-12 */
  readonly nlpClosureMonth?: InputMaybe<Scalars['Int']['input']>;
  /** optional if createNordea */
  readonly nlpFundFeeDiscountType?: InputMaybe<NLPFundFeeDiscountType>;
  /** required if nlpPaymentMethodType is DIRECT_DEBIT, COLLECTION_ACCOUNT_BANK_ACCOUNT */
  readonly nlpPaymentMethodClearing?: InputMaybe<Scalars['String']['input']>;
  /** required if nlpPaymentMethodType is DIRECT_DEBIT, COLLECTION_ACCOUNT_PLUSGIRO or COLLECTION_ACCOUNT_BANK_ACCOUNT */
  readonly nlpPaymentMethodNumber?: InputMaybe<Scalars['String']['input']>;
  /** required if createNordea */
  readonly nlpPaymentMethodType?: InputMaybe<NLPPaymentMethodType>;
  /** required if createNordea */
  readonly nlpResponsible?: InputMaybe<NLPResponsible>;
  /** required if createNordea */
  readonly nlpStartDate?: InputMaybe<Scalars['Date']['input']>;
  /** required if createNordnet */
  readonly nordnetStartDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface CreatePreSignedAgreementsPayload {
  readonly __typename?: 'CreatePreSignedAgreementsPayload';
  readonly agreements: Maybe<ReadonlyArray<Agreement>>;
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface CreateProposalAccidentBenefitInput {
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly level: ProposalAccidentLevel;
  readonly proposalId: Scalars['ID']['input'];
}

export interface CreateProposalBenefitPackageInput {
  readonly defaultBenefitPackage?: InputMaybe<ProposalDefaultBenefitPackage>;
  readonly name: Scalars['String']['input'];
  readonly proposalId: Scalars['ID']['input'];
}

export interface CreateProposalEmployersPensionBenefitInput {
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly convertingPremiumWaiverFrom?: InputMaybe<Scalars['String']['input']>;
  readonly defaultManagementType: ProposalDefaultManagementType;
  readonly extraFixedPremium: Scalars['Boolean']['input'];
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod;
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>;
  /** Premium matrix for the employers pension. Must be set if premiumCalculationMethod is CUSTOM_MATRIX */
  readonly premiumMatrix?: InputMaybe<ProposalPensionPremiumMatrixInput>;
  readonly premiumWaiver: Scalars['Boolean']['input'];
  readonly proposalId: Scalars['ID']['input'];
  readonly retirementAge: Scalars['Int']['input'];
}

export interface CreateProposalHealthcareBenefitInput {
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  /** If this field is true, convertingFrom is required */
  readonly converting: Scalars['Boolean']['input'];
  /** This field is required if convertingFrom is set to true */
  readonly convertingFrom?: InputMaybe<Scalars['String']['input']>;
  readonly level: ProposalHealthcareLevel;
  readonly proposalId: Scalars['ID']['input'];
  readonly waitingPeriod: Scalars['Boolean']['input'];
}

export interface CreateProposalInput {
  /** If ADVICE_WAIVED_CUSTOMER_INITIATED or ADVICE_WAIVED_OWNER, the need and product knowledge fields are not required on the proposal */
  readonly adviceWaived: ProposalAdviceOption;
  readonly companyId: Scalars['ID']['input'];
  readonly companySize: ProposalCompanySize;
  /** Unique per company */
  readonly name: Scalars['String']['input'];
}

export interface CreateProposalLifeBenefitInput {
  readonly agreementType: LifeAgreementType;
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly level: ProposalLifeLevel;
  readonly proposalId: Scalars['ID']['input'];
}

export interface CreateProposalMembershipInput {
  readonly benefitPackageId: Scalars['ID']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  /** Whether the user is fully capable for work or not, null before being sett */
  readonly isFcfw?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the user is the owner of the company or not, defaults to false */
  readonly isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lastName: Scalars['String']['input'];
  /** Set if the admin wants the user to have fixed premium. Either on top of their proportional or on a fixed premium benefit. */
  readonly monthlyFixedPensionPremium?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlySalary: Scalars['Money']['input'];
  /** Coordination number or swedish personal identity number */
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly proposalId: Scalars['ID']['input'];
}

export interface CreateProposalOccupationalPensionBenefitInput {
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly convertingPremiumWaiverFrom?: InputMaybe<Scalars['String']['input']>;
  readonly defaultManagementType: ProposalDefaultManagementType;
  readonly extraFixedPremium: Scalars['Boolean']['input'];
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod;
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>;
  /** Premium matrix for the occupational pension. Must be set if premiumCalculationMethod is CUSTOM_MATRIX */
  readonly premiumMatrix?: InputMaybe<ProposalPensionPremiumMatrixInput>;
  readonly premiumWaiver: Scalars['Boolean']['input'];
  readonly proposalId: Scalars['ID']['input'];
  readonly retirementAge: Scalars['Int']['input'];
}

export interface CreateProposalSalaryExchangeBenefitInput {
  readonly automaticApproval: Scalars['Boolean']['input'];
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly incomeMinIBB: Scalars['Float']['input'];
  readonly ongoingMax: Scalars['Money']['input'];
  readonly pensionTaxBenefitShare: Scalars['Float']['input'];
  readonly pensionToSalaryMax: Scalars['Float']['input'];
  readonly premiumWaiver: Scalars['Boolean']['input'];
  readonly proposalId: Scalars['ID']['input'];
  readonly retirementAge: Scalars['Int']['input'];
  /** This field is the yearly salary multiplier for this benefit. It must be either 12 or 12.2 */
  readonly salaryMultiplier: Scalars['Float']['input'];
}

export interface CreateProposalSicknessBenefitInput {
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  /** If this field is true, convertingFrom is required */
  readonly converting: Scalars['Boolean']['input'];
  /** This field is required if convertingFrom is set to true */
  readonly convertingFrom?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the salary limit can be extended for 1-9 insured. Required for CompanySize.LT_10 */
  readonly extendSalaryLimit?: InputMaybe<Scalars['Boolean']['input']>;
  readonly extendedSickPay: Scalars['Boolean']['input'];
  readonly level: ProposalSicknessLevel;
  readonly proposalId: Scalars['ID']['input'];
  /** This field is the yearly salary multiplier for this benefit. It must be either 12 or 12.2 */
  readonly salaryMultiplier: Scalars['Float']['input'];
}

export interface CreateProposalTglBenefitInput {
  /** Array of benefit package ids must be provided. If empty, the benefit is not added to any benefit package. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly proposalId: Scalars['ID']['input'];
}

export interface CreateSharedFilesInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly companyId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Refers to either company files or user files depending on which of
   * `companyId` or `userAccountId` is given.
   */
  readonly fileIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly isGdprProtected?: InputMaybe<Scalars['Boolean']['input']>;
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface CreateSharedFilesPayload {
  readonly __typename?: 'CreateSharedFilesPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

/** Projection results of cumulative return with reflected input parameters */
export interface CumulativeReturn {
  readonly __typename?: 'CumulativeReturn';
  /** Breakdown of applicable fees included in the projection */
  readonly fees: Maybe<ProjectionFees>;
  readonly horizon: Maybe<Scalars['Date']['output']>;
  readonly initialDeposit: Scalars['Money']['output'];
  readonly initialValue: Scalars['Money']['output'];
  readonly managementFee: Scalars['DecimalFraction']['output'];
  readonly monthlySavings: Scalars['Money']['output'];
  /** Scored positions each period */
  readonly positions: ReadonlyArray<Position>;
  /** Projected cumulative wealth over time. Always starts with the initial value of the portfolio, excluding any new deposits. If horizon is <1 month into the future, just the current value of the portfolio. */
  readonly projection: ReadonlyArray<Projection>;
  /** Savings recommendations in order to reach target value with 2/3 probability. Null if no target value is set. */
  readonly recommendation: Maybe<Recommendation>;
  readonly serviceFee: Scalars['DecimalFraction']['output'];
  readonly startDate: Scalars['Date']['output'];
  readonly targetMonthlyWithdrawals: Maybe<Scalars['Money']['output']>;
  readonly targetValue: Maybe<Scalars['Money']['output']>;
  readonly taxScheme: Maybe<TaxScheme>;
  readonly withdrawalHorizon: Maybe<Scalars['Date']['output']>;
  /** Projected monthly withdrawal amount, if a withdrawal horizon is provided. */
  readonly withdrawalProjection: Maybe<Scalars['Money']['output']>;
}

/** Starting with an initial value, project growth over time. Deposits happen immediately after value date, i.e. deposits earn the same return as value. */
export interface CumulativeReturnInput {
  /** Savings horizon (default 30 years) */
  readonly horizon?: InputMaybe<Scalars['Date']['input']>;
  /** One-time deposit made today */
  readonly initialDeposit?: InputMaybe<Scalars['Money']['input']>;
  /** Initial investment, or current portfolio value */
  readonly initialValue?: InputMaybe<Scalars['Money']['input']>;
  /** Annual management fee charged by the institute for advisory services */
  readonly managementFee?: InputMaybe<Scalars['DecimalFraction']['input']>;
  /** Monthly deposits starting today */
  readonly monthlySavings?: InputMaybe<Scalars['Money']['input']>;
  /** Positions held each period */
  readonly positions: ReadonlyArray<PositionInput>;
  /** Annual service fee charged for maintaining the account */
  readonly serviceFee?: InputMaybe<Scalars['DecimalFraction']['input']>;
  /** Desired monthly withdrawals starting at horizon (end of savings) */
  readonly targetMonthlyWithdrawals?: InputMaybe<Scalars['Money']['input']>;
  /** For recommendations, the desired value at horizon */
  readonly targetValue?: InputMaybe<Scalars['Money']['input']>;
  /** Applicable tax scheme for return projection */
  readonly taxScheme?: InputMaybe<TaxScheme>;
  /** Desired period of withdrawals starting at horizon */
  readonly withdrawalHorizon?: InputMaybe<Scalars['Date']['input']>;
}

export interface Currency extends Instrument {
  readonly __typename?: 'Currency';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly price: Maybe<CurrencyValue>;
  readonly priceAsOf: Maybe<Scalars['Date']['output']>;
}

/**
 * A monetary amount with specific currency.
 *
 * *Note* that amounts denoted in base amounts e.g. "prisbasbelopp" and "inkomstbasbelopp"
 * will be denoted with currency "XPB" (PBB) and "XIB" (IBB) with value representing the base amount e.g;
 * \`{ "value": "7.5", "currency": "XIB" }\` represents 7.5 IBB.
 */
export interface CurrencyValue {
  readonly __typename?: 'CurrencyValue';
  readonly currency: Scalars['String']['output'];
  readonly value: Scalars['DecimalFraction']['output'];
}

/**
 * A monetary amount with specific currency.
 *
 * *Note* that amounts denoted in base amounts e.g. "prisbasbelopp" and "inkomstbasbelopp"
 * will be denoted with currency "XPB" (PBB) and "XIB" (IBB) with value representing the base amount e.g;
 * \`{ "value": "7.5", "currency": "XIB" }\` represents 7.5 IBB.
 */
export interface CurrencyValueInput {
  readonly currency: Scalars['String']['input'];
  readonly value: Scalars['DecimalFraction']['input'];
}

export interface CustomItemWrapper {
  readonly __typename?: 'CustomItemWrapper';
  readonly balance: Maybe<BalanceItem>;
  readonly remuneration: Maybe<RemunerationItem>;
}

export interface CustomType {
  readonly __typename?: 'CustomType';
  readonly currency: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly superType: SUPER_TYPE;
  readonly unitCode: Maybe<Scalars['String']['output']>;
}

export interface CustomTypeConnection {
  readonly __typename?: 'CustomTypeConnection';
  readonly edges: ReadonlyArray<CustomTypeEdge>;
  readonly pageInfo: PageInfo;
}

export interface CustomTypeEdge {
  readonly __typename?: 'CustomTypeEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: CustomType;
}

export interface CustomTypeType {
  readonly __typename?: 'CustomTypeType';
  readonly id: Maybe<Scalars['String']['output']>;
  readonly type: Maybe<SUPER_TYPE>;
}

export enum DataCollectionStatus {
  CLIENT_AT_INSTITUTE = 'CLIENT_AT_INSTITUTE',
  INVALID_RESPONSE = 'INVALID_RESPONSE',
  NOT_CLIENT_AT_INSTITUTE = 'NOT_CLIENT_AT_INSTITUTE',
  NO_ANSWER = 'NO_ANSWER',
  PVF_SENT = 'PVF_SENT',
  SUCCESSFUL = 'SUCCESSFUL'
}

export interface DateFilter {
  readonly from?: InputMaybe<Scalars['Date']['input']>;
  readonly to?: InputMaybe<Scalars['Date']['input']>;
}

export interface DeathAmountSurvivorCover {
  readonly __typename?: 'DeathAmountSurvivorCover';
  readonly unit: InsuranceAmountUnit;
  readonly value: Scalars['String']['output'];
}

export interface DeleteCompanyBillingConfigurationInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface DeleteCompanyBillingConfigurationPayload {
  readonly __typename?: 'DeleteCompanyBillingConfigurationPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface DeleteCompanyRepresentativeInput {
  readonly id: Scalars['ID']['input'];
  readonly proposalId: Scalars['ID']['input'];
}

export interface DeleteEmploymentActivityInput {
  readonly id: Scalars['ID']['input'];
}

export interface DeleteEmploymentActivityPayload {
  readonly __typename?: 'DeleteEmploymentActivityPayload';
  readonly employmentActivity: EmploymentActivity;
}

export interface DeleteEmploymentInput {
  readonly id: Scalars['ID']['input'];
}

export interface DeleteEmploymentPayload {
  readonly __typename?: 'DeleteEmploymentPayload';
  readonly employment: Employment;
}

export interface DeleteInsurancesInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly instituteId?: InputMaybe<Scalars['ID']['input']>;
  readonly insuranceIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}

export interface DeleteInsurancesPayload {
  readonly __typename?: 'DeleteInsurancesPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface DeleteInternalPostInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface DeleteInternalPostPayload {
  readonly __typename?: 'DeleteInternalPostPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['ID']['output']>;
}

export interface DeleteJobOfferInput {
  readonly id: Scalars['ID']['input'];
}

export interface DeleteJobOfferPayload {
  readonly __typename?: 'DeleteJobOfferPayload';
  readonly jobOfferId: Scalars['ID']['output'];
}

export interface DeleteMeetingInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface DeleteMeetingPayload {
  readonly __typename?: 'DeleteMeetingPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface DeleteNoteInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface DeleteNotePayload {
  readonly __typename?: 'DeleteNotePayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['String']['output'];
}

export interface DeleteOrganizationalUnitInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface DeleteOrganizationalUnitPayload {
  readonly __typename?: 'DeleteOrganizationalUnitPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
}

export interface DeleteProposalBenefitInput {
  readonly id: Scalars['ID']['input'];
  readonly proposalId: Scalars['ID']['input'];
}

export interface DeleteProposalBenefitPackageInput {
  readonly id: Scalars['ID']['input'];
  /** @deprecated No need to send this in, `id` is sufficient */
  readonly proposalId?: InputMaybe<Scalars['ID']['input']>;
}

export interface DeleteProposalInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly proposalId: Scalars['ID']['input'];
}

export interface DeleteProposalMembershipInput {
  readonly id: Scalars['ID']['input'];
  readonly proposalId: Scalars['ID']['input'];
}

export interface DeleteProposalPayload {
  readonly __typename?: 'DeleteProposalPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface DeleteSharedFileInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface DeleteSharedFilePayload {
  readonly __typename?: 'DeleteSharedFilePayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

/**
 * A dependent is someone living in the household reliant on the users income.
 * Only applicable to users over 50 years of age.
 */
export enum DependentsStatus {
  HAS_DEPENDENTS = 'HAS_DEPENDENTS',
  NO_DEPENDENTS = 'NO_DEPENDENTS'
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface DisablePoaInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface DisablePoaPayload {
  readonly __typename?: 'DisablePoaPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface Document {
  readonly __typename?: 'Document';
  /** Retrieve document content (as base64-encoded string) with specific content type */
  readonly content: Maybe<Scalars['String']['output']>;
  /** Available content types */
  readonly contentTypes: ReadonlyArray<Scalars['String']['output']>;
  /** Date and time of the document creation */
  readonly createdAt: Scalars['DateTime']['output'];
  /** Document type name */
  readonly documentType: Scalars['String']['output'];
  /** Document ID */
  readonly id: Scalars['ID']['output'];
  /** Custom labels (tags) */
  readonly labels: ReadonlyArray<DocumentLabel>;
  /** Name of the documents */
  readonly name: Scalars['String']['output'];
  /** Optional user account who owns the document */
  readonly owner: Maybe<Scalars['ID']['output']>;
  /**
   * Url for document content with specific content type.
   * If content type is not provided then it will be selected automatically
   */
  readonly url: Maybe<Scalars['String']['output']>;
}


export interface DocumentcontentArgs {
  contentType: Scalars['String']['input'];
}


export interface DocumenturlArgs {
  contentType?: InputMaybe<Scalars['String']['input']>;
}

export interface DocumentConnection {
  readonly __typename?: 'DocumentConnection';
  readonly edges: ReadonlyArray<DocumentEdge>;
  readonly pageInfo: PageInfo;
}

export interface DocumentContent {
  /** The document's body encoded as Base64 */
  readonly body: Scalars['String']['input'];
  /** MIME content type */
  readonly contentType: Scalars['String']['input'];
}

export interface DocumentEdge {
  readonly __typename?: 'DocumentEdge';
  readonly node: Document;
}

export interface DocumentLabel {
  readonly __typename?: 'DocumentLabel';
  readonly name: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

export interface DocumentLabelInput {
  readonly name: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
}

export interface DocumentTypeInfo {
  readonly __typename?: 'DocumentTypeInfo';
  /** Long description of the type */
  readonly description: Maybe<Scalars['String']['output']>;
  /** Short readable string uniquely identifying the type */
  readonly type: Scalars['String']['output'];
}

export interface DuplicateProposalInput {
  /** If ADVICE_WAIVED_CUSTOMER_INITIATED or ADVICE_WAIVED_OWNER, the need and product knowledge fields are not required on the proposal */
  readonly adviceWaived?: InputMaybe<ProposalAdviceOption>;
  readonly companySize?: InputMaybe<ProposalCompanySize>;
  /** Unique per company. Defaults to "[Copied proposal name] - Copy" */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly proposalId: Scalars['ID']['input'];
}

export enum EAGruppAgreementType {
  AdvinansRisk1 = 'AdvinansRisk1',
  AdvinansRisk1Alder = 'AdvinansRisk1Alder',
  AdvinansRisk2 = 'AdvinansRisk2',
  AdvinansRisk2Alder = 'AdvinansRisk2Alder',
  AdvinansRisk3 = 'AdvinansRisk3',
  AdvinansRisk3Alder = 'AdvinansRisk3Alder',
  AdvinansRisk4 = 'AdvinansRisk4',
  AdvinansRisk4Alder = 'AdvinansRisk4Alder',
  AdvinansRisk5 = 'AdvinansRisk5',
  AdvinansRisk5Alder = 'AdvinansRisk5Alder',
  AdvinansRisk6 = 'AdvinansRisk6',
  AdvinansRisk6Alder = 'AdvinansRisk6Alder',
  AdvinansRisk7 = 'AdvinansRisk7',
  AdvinansRiskNY = 'AdvinansRiskNY',
  AdvinansRiskNYAlder = 'AdvinansRiskNYAlder',
  AdvinansRiskSma = 'AdvinansRiskSma',
  AdvinansRiskSmaAlder = 'AdvinansRiskSmaAlder',
  AdvinansukRisk1 = 'AdvinansukRisk1',
  AdvinansukRisk2 = 'AdvinansukRisk2',
  AdvinansukRisk3 = 'AdvinansukRisk3',
  AdvinansukRisk4 = 'AdvinansukRisk4',
  AdvinansukRisk5 = 'AdvinansukRisk5',
  AdvinansukRisk6 = 'AdvinansukRisk6',
  AdvinansukRisk7 = 'AdvinansukRisk7',
  AdvinansukRiskNY = 'AdvinansukRiskNY',
  AdvinansukRiskSma = 'AdvinansukRiskSma',
  FiftySevenFiftySevenSixty = 'FiftySevenFiftySevenSixty',
  NinetySevenNinetySevenNinetySeven = 'NinetySevenNinetySevenNinetySeven',
  NordeaNodeLIV10_99 = 'NordeaNodeLIV10_99',
  NordeaNodeLIV100plus = 'NordeaNodeLIV100plus',
  NordeaNodemkRisk1 = 'NordeaNodemkRisk1',
  NordeaNodemkRisk2 = 'NordeaNodemkRisk2',
  NordeaNodemkRisk3 = 'NordeaNodemkRisk3',
  NordeaNodemkRisk4 = 'NordeaNodemkRisk4',
  NordeaNodemkRisk5 = 'NordeaNodemkRisk5',
  NordeaNodemkRisk6 = 'NordeaNodemkRisk6',
  NordeaNodemkRisk7 = 'NordeaNodemkRisk7',
  NordeaNodemkSma = 'NordeaNodemkSma',
  NordeaNodeukNY = 'NordeaNodeukNY',
  NordeaNodeukRisk1 = 'NordeaNodeukRisk1',
  NordeaNodeukRisk2 = 'NordeaNodeukRisk2',
  NordeaNodeukRisk3 = 'NordeaNodeukRisk3',
  NordeaNodeukRisk4 = 'NordeaNodeukRisk4',
  NordeaNodeukRisk5 = 'NordeaNodeukRisk5',
  NordeaNodeukRisk6 = 'NordeaNodeukRisk6',
  NordeaNodeukRiskSma = 'NordeaNodeukRiskSma',
  NordeanodemkNY = 'NordeanodemkNY',
  NordeanodeukRisk7 = 'NordeanodeukRisk7'
}

export interface EaAgreementInput {
  readonly createEaGrupp?: InputMaybe<Scalars['Boolean']['input']>;
  readonly createEaTjp?: InputMaybe<Scalars['Boolean']['input']>;
  /** Will be passed on to EA. 0-1. Not provided means we get 100% commission */
  readonly eaCommissionFactor?: InputMaybe<Scalars['Float']['input']>;
  readonly eaCompanySize: CompanySize;
  readonly eaExtendedFcfw: Scalars['Boolean']['input'];
  /** required if createEaGrupp */
  readonly eaGruppAgreementType?: InputMaybe<EAGruppAgreementType>;
  readonly eaStartDate: Scalars['Date']['input'];
}

export interface EaWorkRole {
  readonly __typename?: 'EaWorkRole';
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
  readonly workRole: Maybe<WorkRole>;
}

export enum EaWorkRoleId {
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER'
}

/** Parameters for simulating pension and generating advice */
export interface EconomicProfile {
  readonly __typename?: 'EconomicProfile';
  readonly assets: Maybe<Scalars['Money']['output']>;
  /** Determines what stub items are generated */
  readonly collectiveAgreement: Maybe<Scalars['String']['output']>;
  readonly desiredPension: Maybe<Scalars['Money']['output']>;
  readonly initialMonthlyIncome: Maybe<Scalars['Money']['output']>;
  readonly itp2StartYear: Maybe<Scalars['Int']['output']>;
  readonly liabilities: Maybe<Scalars['Money']['output']>;
  readonly monthlyExpenses: Maybe<Scalars['Money']['output']>;
  readonly monthlyIncome: Maybe<Scalars['Money']['output']>;
  readonly monthlyIncomeAtPension: Maybe<Scalars['Money']['output']>;
  readonly occupationalPensionPremium: Maybe<Scalars['Money']['output']>;
  readonly pensionAge: Maybe<Scalars['Int']['output']>;
  readonly riskPreference: Maybe<RiskPreference>;
  readonly salaryExchangeAmount: Maybe<Scalars['Money']['output']>;
  readonly sustainabilityCategory: Maybe<SustainabilityCategory>;
  readonly workStartAge: Maybe<Scalars['Int']['output']>;
}

export interface EconomicProfileInput {
  readonly assets?: InputMaybe<Scalars['Money']['input']>;
  /** Determines what stub items are generated */
  readonly collectiveAgreement?: InputMaybe<Scalars['String']['input']>;
  readonly desiredPension?: InputMaybe<Scalars['Money']['input']>;
  readonly initialMonthlyIncome?: InputMaybe<Scalars['Money']['input']>;
  readonly itp2StartYear?: InputMaybe<Scalars['Int']['input']>;
  readonly liabilities?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlyExpenses?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlyIncome?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlyIncomeAtPension?: InputMaybe<Scalars['Money']['input']>;
  readonly occupationalPensionPremium?: InputMaybe<Scalars['Money']['input']>;
  readonly pensionAge?: InputMaybe<Scalars['Int']['input']>;
  readonly riskPreference?: InputMaybe<RiskPreference>;
  readonly salaryExchangeAmount?: InputMaybe<Scalars['Money']['input']>;
  readonly sustainabilityCategory?: InputMaybe<SustainabilityCategory>;
  readonly userAccountId: Scalars['ID']['input'];
  readonly workStartAge?: InputMaybe<Scalars['Int']['input']>;
}

/** Hur väl skulle du klara dig ekonomiskt om du förlorade delar av eller hela det flyttade kapitalet? */
export enum EffectOfLoss {
  /** Jag skulle få svårt att klara mig om inte en större del av det pensionkapital som jag vill flytta är garanterat */
  ADVERSE = 'ADVERSE',
  /** Jag har tillräckliga tillgångar och andra inkomster för att klara mig även om jag förlorade hela det pensionskapital som jag vill flytta */
  INEFFECTIVE = 'INEFFECTIVE',
  /** Jag har tillräckliga tillgångar för att klara mig även om jag förlorade en del av kapitalet, men skulle få svårare att klara mig om jag förlorade hela det pensionkapital som jag vill flytta */
  PARTIAL_EFFECTIVE = 'PARTIAL_EFFECTIVE'
}

export enum EmployeeHistoryEventAuthorType {
  ADMIN = 'ADMIN',
  ADVINANS_ADMIN = 'ADVINANS_ADMIN',
  NO_DATA = 'NO_DATA',
  UNKNOWN = 'UNKNOWN'
}

export enum EmployeeHistoryEventSource {
  IMPORT = 'IMPORT',
  NO_DATA = 'NO_DATA',
  PORTAL = 'PORTAL'
}

export enum EmployeeHistoryEventType {
  ACTIVITY_SET_DELETED = 'ACTIVITY_SET_DELETED',
  ACTIVITY_SET_END = 'ACTIVITY_SET_END',
  ACTIVITY_SET_RATE = 'ACTIVITY_SET_RATE',
  ACTIVITY_SET_START = 'ACTIVITY_SET_START',
  ACTIVITY_SET_TYPE = 'ACTIVITY_SET_TYPE',
  BENEFIT_SET = 'BENEFIT_SET',
  EMPLOYMENT_DELETED = 'EMPLOYMENT_DELETED',
  EMPLOYMENT_SET_END = 'EMPLOYMENT_SET_END',
  EMPLOYMENT_SET_START = 'EMPLOYMENT_SET_START',
  PERSON_ADDED = 'PERSON_ADDED',
  REMUNERATION_SET_PRICE = 'REMUNERATION_SET_PRICE',
  REMUNERATION_SET_TYPE = 'REMUNERATION_SET_TYPE'
}

export interface EmployeeMeeting {
  readonly __typename?: 'EmployeeMeeting';
  readonly advisorName: Maybe<Scalars['String']['output']>;
  readonly companyId: Scalars['ID']['output'];
  readonly confirmed: Scalars['Boolean']['output'];
  readonly date: Scalars['Date']['output'];
  readonly eventTypeCategory: EventTypeCategory;
  readonly id: Scalars['ID']['output'];
  readonly latestEmailSentAt: Maybe<Scalars['Date']['output']>;
  readonly membership: Maybe<Membership>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface EmployeeMeetingConnection {
  readonly __typename?: 'EmployeeMeetingConnection';
  readonly edges: ReadonlyArray<EmployeeMeetingEdge>;
  readonly pageInfo: PageInfo;
}

export interface EmployeeMeetingEdge {
  readonly __typename?: 'EmployeeMeetingEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: EmployeeMeeting;
}

export interface EmployeeSalaryEvent {
  readonly __typename?: 'EmployeeSalaryEvent';
  readonly created: Scalars['String']['output'];
  readonly effectiveDate: Scalars['String']['output'];
  readonly monthlySalary: Scalars['Int']['output'];
}

export enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  OFF_DUTY = 'OFF_DUTY',
  PARENTAL_LEAVE_WITHOUT_RISK = 'PARENTAL_LEAVE_WITHOUT_RISK',
  PARENTAL_LEAVE_WITH_RISK = 'PARENTAL_LEAVE_WITH_RISK',
  TERMINATED = 'TERMINATED'
}

export interface EmployeeStatusEvent {
  readonly __typename?: 'EmployeeStatusEvent';
  readonly created: Scalars['String']['output'];
  readonly effectiveDate: Scalars['String']['output'];
  readonly status: EmployeeStatus;
}

export interface EmployeeWithPersonalAdvice {
  readonly __typename?: 'EmployeeWithPersonalAdvice';
  readonly allowedToHaveMeeting: Maybe<Scalars['Boolean']['output']>;
  readonly companyId: Scalars['ID']['output'];
  readonly latestEmailSentAt: Maybe<Scalars['Date']['output']>;
  readonly latestMeetingDate: Maybe<Scalars['Date']['output']>;
  readonly membership: Maybe<Membership>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface EmployeeWithPersonalAdviceConnection {
  readonly __typename?: 'EmployeeWithPersonalAdviceConnection';
  readonly edges: ReadonlyArray<EmployeeWithPersonalAdviceEdge>;
  readonly pageInfo: PageInfo;
}

export interface EmployeeWithPersonalAdviceEdge {
  readonly __typename?: 'EmployeeWithPersonalAdviceEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: EmployeeWithPersonalAdvice;
}

export interface EmployeeWithTransferableCapital {
  readonly __typename?: 'EmployeeWithTransferableCapital';
  readonly capital: Scalars['Money']['output'];
  readonly latestEmailSentAt: Maybe<Scalars['Date']['output']>;
  readonly latestMeetingDate: Maybe<Scalars['Date']['output']>;
  readonly memberships: Maybe<MembershipConnection>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface EmployeeWithTransferableCapitalConnection {
  readonly __typename?: 'EmployeeWithTransferableCapitalConnection';
  readonly edges: ReadonlyArray<EmployeeWithTransferableCapitalEdge>;
  readonly pageInfo: PageInfo;
}

export interface EmployeeWithTransferableCapitalEdge {
  readonly __typename?: 'EmployeeWithTransferableCapitalEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: EmployeeWithTransferableCapital;
}

export interface Employment {
  readonly __typename?: 'Employment';
  readonly activities: Maybe<EmploymentActivityConnection>;
  /** Hours worked on this employment the past month, or whenever */
  readonly hoursWorked: Maybe<Scalars['DecimalFraction']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly membership: Membership;
  readonly period: Scalars['DateRange']['output'];
  readonly rate: Scalars['DecimalFraction']['output'];
  readonly salaries: Maybe<EmploymentSalariesConnection>;
  readonly type: Maybe<Scalars['String']['output']>;
}


export interface EmploymentactivitiesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  effectiveAt?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface EmploymentsalariesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface EmploymentActivity {
  readonly __typename?: 'EmploymentActivity';
  readonly id: Scalars['ID']['output'];
  readonly period: Scalars['DateRange']['output'];
  /**
   * Extent of activity (absence) measured relative to the employment's rate;.
   * For example, a 20% absence rate on a 60% employment rate corresponds to an "effective" employment rate of 48%.
   */
  readonly rate: Scalars['DecimalFraction']['output'];
  readonly type: EmploymentActivityType;
}

export interface EmploymentActivityConnection {
  readonly __typename?: 'EmploymentActivityConnection';
  readonly edges: ReadonlyArray<EmploymentActivityEdge>;
  readonly pageInfo: PageInfo;
}

export interface EmploymentActivityEdge {
  readonly __typename?: 'EmploymentActivityEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: EmploymentActivity;
}

export enum EmploymentActivityType {
  OFF_DUTY = 'OFF_DUTY',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE'
}

export interface EmploymentConnection {
  readonly __typename?: 'EmploymentConnection';
  readonly edges: ReadonlyArray<EmploymentEdge>;
  readonly pageInfo: PageInfo;
}

export interface EmploymentEdge {
  readonly __typename?: 'EmploymentEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: Employment;
}

export interface EmploymentRateItem {
  readonly __typename?: 'EmploymentRateItem';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly employmentRate: Maybe<Scalars['DecimalFraction']['output']>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface EmploymentRevision {
  readonly __typename?: 'EmploymentRevision';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface EmploymentRole {
  readonly __typename?: 'EmploymentRole';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface EmploymentRoleItem {
  readonly __typename?: 'EmploymentRoleItem';
  readonly employmentRole: Maybe<EmploymentRole>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface EmploymentSalariesConnection {
  readonly __typename?: 'EmploymentSalariesConnection';
  readonly edges: ReadonlyArray<EmploymentSalariesEdge>;
  readonly pageInfo: PageInfo;
}

export interface EmploymentSalariesEdge {
  readonly __typename?: 'EmploymentSalariesEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: EmploymentSalary;
}

export interface EmploymentSalary {
  readonly __typename?: 'EmploymentSalary';
  readonly effectiveDate: Scalars['Date']['output'];
  readonly monthlySalary: Scalars['Money']['output'];
}

export interface EmploymentType {
  readonly __typename?: 'EmploymentType';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface EmploymentTypeItem {
  readonly __typename?: 'EmploymentTypeItem';
  readonly employmentType: Maybe<EmploymentType>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface EpassiBikeConfiguration extends Configuration {
  readonly __typename?: 'EpassiBikeConfiguration';
  readonly author: Maybe<Scalars['String']['output']>;
  readonly customerId: Maybe<Scalars['String']['output']>;
  readonly data: Maybe<Scalars['JSON']['output']>;
  readonly files: Maybe<ReadonlyArray<FlexFile>>;
  readonly onboardingDate: Maybe<Scalars['String']['output']>;
  readonly useDefaultPolicy: Maybe<Scalars['Boolean']['output']>;
}

export interface ErrorSummary {
  readonly __typename?: 'ErrorSummary';
  readonly cause: Scalars['String']['output'];
  readonly count: Scalars['Int']['output'];
}

export interface EventType {
  readonly __typename?: 'EventType';
  readonly category: EventTypeCategory;
  readonly name: Scalars['String']['output'];
  readonly uri: Scalars['String']['output'];
}

export enum EventTypeCategory {
  FOLLOW_UP = 'FOLLOW_UP',
  PENSION_TRANSFER = 'PENSION_TRANSFER',
  PERSONAL_ADVICE = 'PERSONAL_ADVICE'
}

export interface ExecuteProposalInput {
  /** The date people signed the proposal. Affects the startDate.Signed <= 15th the start date is the current month.Signed > 15th the start date is the next month.Must not be in the future */
  readonly dateOfSigning: Scalars['String']['input'];
  readonly proposalId: Scalars['ID']['input'];
  /** Must be set to true, false will throw an exposed error back */
  readonly signedByRelevantParties: Scalars['Boolean']['input'];
}

export interface Exposure {
  readonly __typename?: 'Exposure';
  readonly name: Scalars['String']['output'];
  /** Precision of 4 */
  readonly share: Scalars['DecimalFraction']['output'];
}

export interface Extensions {
  readonly __typename?: 'Extensions';
  readonly eaWorkRole: ReadonlyArray<EaWorkRole>;
}

export interface ExternalAccountReport {
  readonly __typename?: 'ExternalAccountReport';
  readonly date: Scalars['Date']['output'];
}

export interface ExternalInsurance extends ExternalItem {
  readonly __typename?: 'ExternalInsurance';
  readonly category: ExternalItemCategory;
  readonly components: ReadonlyArray<ExternalInsuranceComponent>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Institute-specific identifier of this insurance. */
  readonly insuranceNumber: Maybe<Scalars['String']['output']>;
  /**
   * Management type of the insurance as a whole, if known and applicable.
   * Advinans cannot model the case where a single insurance has component
   * specific management types at the same time; the whole insurance must be
   * managed in a consistent fashion.
   */
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly name: Maybe<Scalars['String']['output']>;
  /** Latest Plus advice for this insurance */
  readonly plusAdvice: Maybe<PlusAdvice>;
  /**
   * Plus advice for this insurance
   * @deprecated Use plusAdvice instead
   */
  readonly plusAdviceHistory: Maybe<PlusAdviceConnection>;
  /** Latest Plus Suggestion for this insurance */
  readonly plusSuggestion: Maybe<PlusSuggestion>;
  /** MIS Life product code, if known and applicable. */
  readonly productCode: Maybe<Scalars['String']['output']>;
  /** Generates an account report for this insurance based on the given share. */
  readonly report: Maybe<ExternalAccountReport>;
  readonly source: ExternalItemSource;
  readonly status: Maybe<ExternalInsuranceStatus>;
  readonly zt: Maybe<Scalars['Date']['output']>;
}


export interface ExternalInsurancemanagementTypeArgs {
  origin?: InputMaybe<FieldOrigin>;
}


export interface ExternalInsurancenameArgs {
  origin?: InputMaybe<FieldOrigin>;
}


export interface ExternalInsuranceplusAdviceHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface ExternalInsuranceproductCodeArgs {
  origin?: InputMaybe<FieldOrigin>;
}


export interface ExternalInsurancereportArgs {
  share?: InputMaybe<Scalars['DecimalFraction']['input']>;
}


export interface ExternalInsurancestatusArgs {
  origin?: InputMaybe<FieldOrigin>;
}


export interface ExternalInsuranceztArgs {
  origin?: InputMaybe<FieldOrigin>;
}

export interface ExternalInsuranceComponent {
  readonly componentCode: Maybe<Scalars['String']['output']>;
  readonly deathAmountSurvivorCover: Maybe<DeathAmountSurvivorCover>;
  readonly id: Scalars['ID']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly misCode: Maybe<Scalars['String']['output']>;
  readonly misLifeType: ComponentType;
  readonly pensionSchemeType: Maybe<PensionSchemeType>;
  readonly qualifyingSicknessPayouts: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /**
   * Tax category of this component, if known and applicable. Inherits from
   * the insurance unless explicitly different.
   */
  readonly taxCategory: Maybe<InsuranceTaxCategory>;
}

export interface ExternalInsuranceComponentAccident extends ExternalInsuranceComponent {
  readonly __typename?: 'ExternalInsuranceComponentAccident';
  readonly componentCode: Maybe<Scalars['String']['output']>;
  readonly coverage: Maybe<AccidentCoverage>;
  readonly deathAmountSurvivorCover: Maybe<DeathAmountSurvivorCover>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly misCode: Maybe<Scalars['String']['output']>;
  readonly misLifeType: ComponentType;
  readonly pensionSchemeType: Maybe<PensionSchemeType>;
  readonly qualifyingSicknessPayouts: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /**
   * Tax category of this component, if known and applicable. Inherits from
   * the insurance unless explicitly different.
   */
  readonly status: Maybe<ExternalInsuranceStatus>;
  readonly taxCategory: Maybe<InsuranceTaxCategory>;
  readonly zt: Maybe<Scalars['Date']['output']>;
}


export interface ExternalInsuranceComponentAccidentstatusArgs {
  origin?: InputMaybe<FieldOrigin>;
}


export interface ExternalInsuranceComponentAccidentztArgs {
  origin?: InputMaybe<FieldOrigin>;
}

export interface ExternalInsuranceComponentHealth extends ExternalInsuranceComponent {
  readonly __typename?: 'ExternalInsuranceComponentHealth';
  readonly componentCode: Maybe<Scalars['String']['output']>;
  readonly deathAmountSurvivorCover: Maybe<DeathAmountSurvivorCover>;
  readonly id: Scalars['ID']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly misCode: Maybe<Scalars['String']['output']>;
  readonly misLifeType: ComponentType;
  readonly pensionSchemeType: Maybe<PensionSchemeType>;
  readonly qualifyingSicknessPayouts: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /**
   * Tax category of this component, if known and applicable. Inherits from
   * the insurance unless explicitly different.
   */
  readonly taxCategory: Maybe<InsuranceTaxCategory>;
}

export interface ExternalInsuranceComponentLife extends ExternalInsuranceComponent {
  readonly __typename?: 'ExternalInsuranceComponentLife';
  readonly componentCode: Maybe<Scalars['String']['output']>;
  readonly coverage: Maybe<LifeCoverage>;
  readonly deathAmountSurvivorCover: Maybe<DeathAmountSurvivorCover>;
  readonly id: Scalars['ID']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly misCode: Maybe<Scalars['String']['output']>;
  readonly misLifeType: ComponentType;
  readonly pensionSchemeType: Maybe<PensionSchemeType>;
  readonly qualifyingSicknessPayouts: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /**
   * Tax category of this component, if known and applicable. Inherits from
   * the insurance unless explicitly different.
   */
  readonly taxCategory: Maybe<InsuranceTaxCategory>;
}

export interface ExternalInsuranceComponentPension extends ExternalInsuranceComponent {
  readonly __typename?: 'ExternalInsuranceComponentPension';
  readonly componentCode: Maybe<Scalars['String']['output']>;
  readonly deathAmountSurvivorCover: Maybe<DeathAmountSurvivorCover>;
  readonly id: Scalars['ID']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly interval: Maybe<Scalars['String']['output']>;
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly misCode: Maybe<Scalars['String']['output']>;
  readonly misLifeType: ComponentType;
  readonly pensionSchemeType: Maybe<PensionSchemeType>;
  readonly qualifyingSicknessPayouts: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /**
   * Tax category of this component, if known and applicable. Inherits from
   * the insurance unless explicitly different.
   */
  readonly taxCategory: Maybe<InsuranceTaxCategory>;
}

export interface ExternalInsuranceComponentSickness extends ExternalInsuranceComponent {
  readonly __typename?: 'ExternalInsuranceComponentSickness';
  readonly componentCode: Maybe<Scalars['String']['output']>;
  readonly deathAmountSurvivorCover: Maybe<DeathAmountSurvivorCover>;
  readonly id: Scalars['ID']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly managementType: Maybe<InsuranceManagementType>;
  readonly misCode: Maybe<Scalars['String']['output']>;
  readonly misLifeType: ComponentType;
  readonly pensionSchemeType: Maybe<PensionSchemeType>;
  readonly qualifyingSicknessPayouts: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /**
   * Tax category of this component, if known and applicable. Inherits from
   * the insurance unless explicitly different.
   */
  readonly taxCategory: Maybe<InsuranceTaxCategory>;
}

export enum ExternalInsuranceStatus {
  /** Insurance is in effect (sv: gällande) */
  EFFECTIVE = 'EFFECTIVE',
  /** Insurance is newly created (sv: nyteckning) */
  NEW = 'NEW',
  /** Premiums have been paid to cover obligations (sv: slutbetald) */
  PAID_IN_FULL = 'PAID_IN_FULL',
  /** No additional premiums will be deposited (sv: fribrev) */
  PAID_UP = 'PAID_UP',
  /** Insurance is being claimed by the beneficiary (sv: utbetalning) */
  PAYING_OUT = 'PAYING_OUT'
}

export interface ExternalItem {
  readonly category: ExternalItemCategory;
  readonly id: Scalars['ID']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly source: ExternalItemSource;
}


export interface ExternalItemnameArgs {
  origin?: InputMaybe<FieldOrigin>;
}

/** Category of an item a suggestion can be given on */
export enum ExternalItemCategory {
  /** Real asset */
  ASSET = 'ASSET',
  /** General-purpose insurance */
  INSURANCE = 'INSURANCE',
  /** Mortgage */
  MORTGAGE = 'MORTGAGE',
  /** National pension insurance */
  NATIONAL_PENSION = 'NATIONAL_PENSION',
  /** Occupational pension insurance */
  OCCUPATIONAL_PENSION = 'OCCUPATIONAL_PENSION',
  /** Private loan other than a mortgage */
  PRIVATE_LOAN = 'PRIVATE_LOAN',
  /** Private pension insurance */
  PRIVATE_PENSION = 'PRIVATE_PENSION',
  /** Savings account */
  SAVINGS = 'SAVINGS',
  /** Transactional account */
  TRANSACTIONAL = 'TRANSACTIONAL'
}

export enum ExternalItemSource {
  /** Item was created manually */
  MANUAL = 'MANUAL',
  /** Item was received through a mislife-file */
  MISLIFE = 'MISLIFE',
  /** Item was found by scraping an institute */
  SCRAPE = 'SCRAPE',
  /** Item was automatically created based on the user's economic profile */
  STUB = 'STUB'
}

export interface ExternalLink {
  readonly __typename?: 'ExternalLink';
  readonly externalItems: ReadonlyArray<ExternalItem>;
  readonly id: Scalars['ID']['output'];
  readonly userAccount: UserAccount;
  /** @deprecated Only used by stiching implementation */
  readonly userAccountId: Scalars['ID']['output'];
}


export interface ExternalLinkexternalItemsArgs {
  withAdvinansPensions?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface FamilySituation {
  readonly __typename?: 'FamilySituation';
  readonly childrenStatus: Maybe<ChildrenStatus>;
  readonly civilStatus: Maybe<CivilStatus>;
  readonly dependentsStatus: Maybe<DependentsStatus>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FamilySituationInput {
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export enum FieldOrigin {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

export interface File {
  readonly __typename?: 'File';
  readonly name: Scalars['String']['output'];
  readonly path: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
}

export interface FinancialLink {
  readonly __typename?: 'FinancialLink';
  readonly id: Scalars['ID']['output'];
  readonly ingestedAt: Maybe<Scalars['Date']['output']>;
  readonly institute: Institute;
  readonly insurances: InsuranceConnection;
  readonly status: Maybe<DataCollectionStatus>;
  readonly statusUpdatedAt: Maybe<Scalars['Date']['output']>;
}


export interface FinancialLinkinsurancesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface FlexAccidentConfiguration {
  /** Optionally enter an agreementId to use for insurances */
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
  readonly level: FlexAccidentLevel;
}

export enum FlexAccidentLevel {
  PBB20 = 'PBB20',
  PBB30 = 'PBB30',
  PBB40 = 'PBB40',
  PBB50 = 'PBB50'
}

export interface FlexAddBenefitsToBenefitPackageInput {
  readonly benefitIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly benefitPackageId: Scalars['ID']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly effectiveDate: Scalars['Date']['input'];
}

export interface FlexAddBenefitsToBenefitPackagePayload {
  readonly __typename?: 'FlexAddBenefitsToBenefitPackagePayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface FlexAgreeToBikeTermsInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['String']['input'];
}

export interface FlexAgreeToBikeTermsPayload {
  readonly __typename?: 'FlexAgreeToBikeTermsPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface FlexAllowedToSwitch {
  readonly __typename?: 'FlexAllowedToSwitch';
  readonly allowedToSwitch: Scalars['Boolean']['output'];
  readonly reason: Maybe<Scalars['String']['output']>;
}

export interface FlexApplicationRestrictionRangeInput {
  readonly lower?: InputMaybe<Scalars['Int']['input']>;
  readonly upper?: InputMaybe<Scalars['Int']['input']>;
}

export interface FlexApproveSalaryExchangeRequestInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly requestId: Scalars['ID']['input'];
}

export interface FlexApproveSalaryExchangeRequestPayload {
  readonly __typename?: 'FlexApproveSalaryExchangeRequestPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly salaryExchangeRequest: FlexSalaryExchangeRequest;
}

export enum FlexBankIdTicketStatus {
  COMPLETE = 'COMPLETE',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  OUTSTANDING_TRANSACTION = 'OUTSTANDING_TRANSACTION',
  USER_CANCEL = 'USER_CANCEL'
}

export enum FlexBaseAmountType {
  IBB = 'IBB',
  PBB = 'PBB'
}

export interface FlexBenefit {
  readonly __typename?: 'FlexBenefit';
  readonly categories: ReadonlyArray<FlexBenefitCategory>;
  readonly changes: ReadonlyArray<FlexBenefitChangesHistoryItemRecord>;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly configEffectiveDate: Maybe<Scalars['Date']['output']>;
  readonly configuration: Maybe<Configuration>;
  readonly content: FlexBenefitContent;
  readonly entitlementRules: Maybe<FlexEntitlementRules>;
  readonly hidden: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly slug: Scalars['String']['output'];
  readonly supplier: FlexBenefitSupplier;
  readonly tags: ReadonlyArray<FlexBenefitTag>;
  readonly type: Scalars['String']['output'];
}


export interface FlexBenefitconfigurationArgs {
  asOf?: InputMaybe<Scalars['String']['input']>;
}


export interface FlexBenefitcontentArgs {
  locale?: InputMaybe<Scalars['String']['input']>;
}


export interface FlexBenefitentitlementRulesArgs {
  asOf?: InputMaybe<Scalars['String']['input']>;
}

export interface FlexBenefitCategory {
  readonly __typename?: 'FlexBenefitCategory';
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly locale: Scalars['String']['output'];
  /** The displayname of the category */
  readonly name: Scalars['String']['output'];
  /** The type name of the category */
  readonly typeName: Maybe<Scalars['String']['output']>;
}

export interface FlexBenefitCategoryTranslation {
  readonly locale: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
}

export interface FlexBenefitChangesHistoryItemRecord {
  readonly __typename?: 'FlexBenefitChangesHistoryItemRecord';
  readonly author: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['Date']['output'];
  readonly from: Scalars['FullDate']['output'];
  /** A UUID v5 based on: from, to, createdAt & author */
  readonly id: Scalars['String']['output'];
  readonly to: Maybe<Scalars['FullDate']['output']>;
}

export interface FlexBenefitConnection {
  readonly __typename?: 'FlexBenefitConnection';
  readonly edges: ReadonlyArray<FlexBenefitEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexBenefitContent {
  readonly __typename?: 'FlexBenefitContent';
  readonly attachments: ReadonlyArray<FlexFile>;
  readonly body: Scalars['String']['output'];
  readonly callToAction: ReadonlyArray<FlexCallToAction>;
  readonly cardImage: Maybe<FlexFile>;
  readonly heroImage: Maybe<FlexFile>;
  /** heroImageAlignment can have values defined in export enum ImageAlignment { TOP = 'TOP', CENTER = 'CENTER', BOTTOM = 'BOTTOM', } */
  readonly heroImageAlignment: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly locale: Scalars['String']['output'];
  readonly short: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
}

export interface FlexBenefitContentInput {
  readonly attachmentFileIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly attachmentPaths?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly callToAction?: InputMaybe<ReadonlyArray<FlexCallToActionInput>>;
  readonly cardImageFileId?: InputMaybe<Scalars['String']['input']>;
  readonly cardImagePath?: InputMaybe<Scalars['String']['input']>;
  readonly heroImageAlignment?: InputMaybe<Scalars['String']['input']>;
  readonly heroImageFileId?: InputMaybe<Scalars['String']['input']>;
  readonly heroImagePath?: InputMaybe<Scalars['String']['input']>;
  readonly locale: Scalars['String']['input'];
  readonly short?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface FlexBenefitEdge {
  readonly __typename?: 'FlexBenefitEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexBenefit;
}

export enum FlexBenefitOrder {
  Newest = 'Newest'
}

export enum FlexBenefitPackageAction {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED'
}

export interface FlexBenefitPackageHistory {
  readonly __typename?: 'FlexBenefitPackageHistory';
  /** The action that was be performed */
  readonly action: FlexBenefitPackageAction;
  readonly actor: Maybe<Actor>;
  readonly author: Maybe<Scalars['String']['output']>;
  readonly benefitId: Scalars['ID']['output'];
  /** The type of benefit that was added/removed */
  readonly benefitTypeName: Scalars['String']['output'];
  /** The date the change was made. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** The effective date on the change. */
  readonly effectiveDate: Scalars['FullDate']['output'];
}

export interface FlexBenefitPackageHistoryConnection {
  readonly __typename?: 'FlexBenefitPackageHistoryConnection';
  readonly edges: ReadonlyArray<FlexBenefitPackageHistoryEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexBenefitPackageHistoryEdge {
  readonly __typename?: 'FlexBenefitPackageHistoryEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexBenefitPackageHistory;
}

/** a null value means that it is a default supplier, which cannot be updated */
export interface FlexBenefitSupplier {
  readonly __typename?: 'FlexBenefitSupplier';
  /** a null value means that it is a default supplier, which cannot be updated */
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly logo: Maybe<FlexFile>;
  readonly name: Scalars['String']['output'];
  readonly websiteUrl: Maybe<Scalars['String']['output']>;
}

export enum FlexBenefitTag {
  CUSTOM_OFFER = 'CUSTOM_OFFER',
  NEW = 'NEW'
}

export interface FlexBenefitType {
  readonly __typename?: 'FlexBenefitType';
  readonly attributes: ReadonlyArray<FlexBenefitTypeAttributes>;
  readonly categories: ReadonlyArray<FlexBenefitCategory>;
  readonly configSchema: Maybe<Scalars['JSON']['output']>;
  readonly name: Scalars['String']['output'];
  readonly supplier: FlexBenefitSupplier;
}

export enum FlexBenefitTypeAttributes {
  /** Benefit type is recently added to Advinans */
  NEW = 'NEW',
  /** Benefit type has no required configuration */
  PRE_CONFIGURED = 'PRE_CONFIGURED'
}

export interface FlexBenefitTypeConnection {
  readonly __typename?: 'FlexBenefitTypeConnection';
  readonly edges: ReadonlyArray<FlexBenefitTypeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexBenefitTypeEdge {
  readonly __typename?: 'FlexBenefitTypeEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexBenefitType;
}

export interface FlexCallToAction {
  readonly __typename?: 'FlexCallToAction';
  readonly link: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export interface FlexCallToActionInput {
  readonly link: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
  /** Can be: primary, secondary */
  readonly type: Scalars['String']['input'];
}

export interface FlexCancelBankIdTicket {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly ticketId: Scalars['ID']['input'];
}

export interface FlexCancelBankIdTicketPayload {
  readonly __typename?: 'FlexCancelBankIdTicketPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface FlexCancelSalaryExchangeRequestInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly requestId: Scalars['ID']['input'];
}

export interface FlexCancelSalaryExchangeRequestPayload {
  readonly __typename?: 'FlexCancelSalaryExchangeRequestPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly salaryExchangeRequest: FlexSalaryExchangeRequest;
}

export interface FlexChange {
  readonly effectiveDate: Scalars['Date']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export enum FlexChangeAction {
  ADJUST = 'ADJUST',
  CREATE = 'CREATE',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  SET_VIOLATED_FALSE = 'SET_VIOLATED_FALSE',
  SET_VIOLATED_TRUE = 'SET_VIOLATED_TRUE',
  SINGLE_PREMIUM = 'SINGLE_PREMIUM',
  TERMINATE = 'TERMINATE'
}

export interface FlexChangeHistory {
  readonly __typename?: 'FlexChangeHistory';
  /** The action that was/will be performed */
  readonly action: Maybe<FlexInsuranceAction>;
  readonly companyId: Scalars['ID']['output'];
  /** The configuration of the change */
  readonly configuration: Maybe<FlexChangeHistoryConfig>;
  /** The effective date on the change. Nullable since not all changes have effectiveDates */
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly id: Scalars['ID']['output'];
  /** The date the change was sent, null if the change has not been sent yet */
  readonly sentAt: Maybe<Scalars['Date']['output']>;
  /** The status of the change */
  readonly status: FlexChangeStatus;
  /** The reason for the status, if any */
  readonly statusReason: Maybe<Scalars['String']['output']>;
  /** The TxId for the change, null if the change has not been sent yet */
  readonly txId: Maybe<Scalars['ID']['output']>;
  readonly type: FlexChangeType;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexChangeHistoryConfig {
  readonly _typename: Scalars['String']['output'];
}

export interface FlexChangeHistoryConnection {
  readonly __typename?: 'FlexChangeHistoryConnection';
  readonly edges: ReadonlyArray<FlexChangeHistoryEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexChangeHistoryEdge {
  readonly __typename?: 'FlexChangeHistoryEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexChangeHistory;
}

export enum FlexChangeStatus {
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  GRACE_PERIOD = 'GRACE_PERIOD',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PRECONDITION = 'PRECONDITION'
}

export enum FlexChangeType {
  ONETIME_SALARY_EXCHANGE_INSURANCE_FUTUR = 'ONETIME_SALARY_EXCHANGE_INSURANCE_FUTUR',
  ONETIME_SALARY_EXCHANGE_INSURANCE_LF = 'ONETIME_SALARY_EXCHANGE_INSURANCE_LF',
  ONETIME_SALARY_EXCHANGE_INSURANCE_NORDEA = 'ONETIME_SALARY_EXCHANGE_INSURANCE_NORDEA',
  ONETIME_SALARY_EXCHANGE_INSURANCE_NORDNET = 'ONETIME_SALARY_EXCHANGE_INSURANCE_NORDNET',
  ONGOING_SALARY_EXCHANGE_INSURANCE_FUTUR = 'ONGOING_SALARY_EXCHANGE_INSURANCE_FUTUR',
  ONGOING_SALARY_EXCHANGE_INSURANCE_LF = 'ONGOING_SALARY_EXCHANGE_INSURANCE_LF',
  ONGOING_SALARY_EXCHANGE_INSURANCE_NORDEA = 'ONGOING_SALARY_EXCHANGE_INSURANCE_NORDEA',
  ONGOING_SALARY_EXCHANGE_INSURANCE_NORDNET = 'ONGOING_SALARY_EXCHANGE_INSURANCE_NORDNET',
  PENSION_FUTUR = 'PENSION_FUTUR',
  PENSION_LF = 'PENSION_LF',
  PENSION_NORDEA = 'PENSION_NORDEA',
  PENSION_NORDNET = 'PENSION_NORDNET',
  PENSION_NORDNET_SSEK = 'PENSION_NORDNET_SSEK',
  RETIREMENT_AGE_NORDEA = 'RETIREMENT_AGE_NORDEA',
  RETIREMENT_AGE_NORDEA_SALARY_EXCHANGE = 'RETIREMENT_AGE_NORDEA_SALARY_EXCHANGE',
  RISK_ACCIDENT = 'RISK_ACCIDENT',
  RISK_HEALTHCARE = 'RISK_HEALTHCARE',
  RISK_LIFE = 'RISK_LIFE',
  RISK_PREMIUM_WAIVER = 'RISK_PREMIUM_WAIVER',
  RISK_SICKNESS = 'RISK_SICKNESS',
  RISK_SICKNESS_AND_ACCIDENT = 'RISK_SICKNESS_AND_ACCIDENT',
  RISK_TGL = 'RISK_TGL',
  SINGLE_PREMIUM_PENSION_FUTUR = 'SINGLE_PREMIUM_PENSION_FUTUR',
  SINGLE_PREMIUM_PENSION_LF = 'SINGLE_PREMIUM_PENSION_LF',
  SINGLE_PREMIUM_PENSION_NORDEA = 'SINGLE_PREMIUM_PENSION_NORDEA',
  SINGLE_PREMIUM_PENSION_NORDNET = 'SINGLE_PREMIUM_PENSION_NORDNET',
  SINGLE_PREMIUM_PENSION_NORDNET_SSEK = 'SINGLE_PREMIUM_PENSION_NORDNET_SSEK'
}

export interface FlexChangesHistoryInputFilter {
  readonly effectiveDateRange?: InputMaybe<FlexDateFilterArguments>;
  readonly insuranceAction?: InputMaybe<ReadonlyArray<FlexInsuranceAction>>;
  readonly status?: InputMaybe<ReadonlyArray<FlexChangeStatus>>;
  readonly type?: InputMaybe<ReadonlyArray<FlexChangeType>>;
}

export interface FlexCreateAccidentBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexAccidentConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateBenefitCategoryInput {
  readonly companyId: Scalars['ID']['input'];
  readonly translations: ReadonlyArray<FlexBenefitCategoryTranslation>;
}

export interface FlexCreateBenefitCategoryPayload {
  readonly __typename?: 'FlexCreateBenefitCategoryPayload';
  readonly categoryId: Scalars['ID']['output'];
}

export interface FlexCreateBenefitInput {
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitTypeName: Scalars['String']['input'];
  readonly categoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration?: InputMaybe<Scalars['JSON']['input']>;
  readonly content?: InputMaybe<FlexBenefitContentInput>;
  readonly entitlementRules?: InputMaybe<FlexEntitlementRulesInput>;
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly supplierId?: InputMaybe<Scalars['ID']['input']>;
}

export interface FlexCreateBenefitSupplierInput {
  readonly companyId: Scalars['ID']['input'];
  readonly logoFileId?: InputMaybe<Scalars['String']['input']>;
  readonly logoPath?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly websiteUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface FlexCreateBenefitSupplierPayload {
  readonly __typename?: 'FlexCreateBenefitSupplierPayload';
  readonly supplier: FlexBenefitSupplier;
}

export interface FlexCreateBenefitsInput {
  readonly benefits: ReadonlyArray<FlexCreateBenefitInput>;
}

export interface FlexCreateBenefitsPayload {
  readonly __typename?: 'FlexCreateBenefitsPayload';
  readonly benefits: ReadonlyArray<FlexBenefit>;
}

export interface FlexCreateHealthcareBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexHealthcareConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateLifeBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexLifeConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateOccupationalPensionBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexOccupationalPensionConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateSalaryExchangeBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexSalaryExchangeConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateSicknessAndAccidentBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexSicknessAndAccidentConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateSicknessBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexSicknessConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCreateTglBenefitInput {
  /** Optionally create the benefit with a predefined id */
  readonly benefitId?: InputMaybe<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configuration: FlexTglConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexCurrentPremiumEffectiveFrom {
  readonly __typename?: 'FlexCurrentPremiumEffectiveFrom';
  readonly effectiveFrom: Scalars['Date']['output'];
  readonly premium: Maybe<Scalars['Money']['output']>;
}

export interface FlexDateFilterArguments {
  /** From (including) */
  readonly from?: InputMaybe<Scalars['Date']['input']>;
  /** Until (including) */
  readonly until?: InputMaybe<Scalars['Date']['input']>;
}

export interface FlexDeduction {
  readonly __typename?: 'FlexDeduction';
  readonly amount: Scalars['Money']['output'];
  readonly deductionType: Scalars['String']['output'];
}

export enum FlexDefaultPensionProvider {
  NordeaFund = 'NordeaFund',
  NordeaTrad = 'NordeaTrad'
}

export interface FlexDeleteAttachmentInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly fileId: Scalars['ID']['input'];
  /** fileName including file extension */
  readonly fileName: Scalars['String']['input'];
}

export interface FlexDeleteAttachmentPayload {
  readonly __typename?: 'FlexDeleteAttachmentPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface FlexEntitlement {
  readonly benefit: FlexBenefit;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly data: Maybe<Scalars['JSON']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexEntitlementConnection {
  readonly __typename?: 'FlexEntitlementConnection';
  readonly edges: ReadonlyArray<FlexEntitlementEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexEntitlementEdge {
  readonly __typename?: 'FlexEntitlementEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexEntitlement;
}

export interface FlexEntitlementInsurance {
  readonly __typename?: 'FlexEntitlementInsurance';
  readonly insuranceNumber: Scalars['String']['output'];
  readonly provider: Scalars['String']['output'];
}

export interface FlexEntitlementRules {
  readonly __typename?: 'FlexEntitlementRules';
  readonly accountingCostIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly author: Maybe<Scalars['String']['output']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly from: Maybe<Scalars['String']['output']>;
  readonly ouIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly to: Maybe<Scalars['String']['output']>;
}

export interface FlexEntitlementRulesInput {
  readonly accountingCostIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  /** ISO 8601 date string (YYYY-MM-DD) */
  readonly from?: InputMaybe<Scalars['String']['input']>;
  readonly ouIds: ReadonlyArray<Scalars['ID']['input']>;
  /** ISO 8601 date string (YYYY-MM-DD) */
  readonly to?: InputMaybe<Scalars['String']['input']>;
}

export enum FlexEpassiBikeCodeStatus {
  CODE_ARRIVED = 'CODE_ARRIVED',
  CODE_ON_WAY = 'CODE_ON_WAY',
  NOT_ORDERED = 'NOT_ORDERED'
}

export interface FlexEpassiBikeEntitlement extends FlexEntitlement {
  readonly __typename?: 'FlexEpassiBikeEntitlement';
  readonly benefit: FlexBenefit;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly data: Maybe<Scalars['JSON']['output']>;
  readonly files: ReadonlyArray<FlexFile>;
  readonly signed: Scalars['Boolean']['output'];
  readonly signedAt: Maybe<Scalars['Date']['output']>;
  readonly status: FlexEpassiBikeCodeStatus;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexFcfwAssertion {
  readonly __typename?: 'FlexFcfwAssertion';
  readonly actor: Maybe<Actor>;
  readonly authorizedBy: Scalars['String']['output'];
  readonly companyId: Scalars['ID']['output'];
  readonly createdAt: Scalars['FullDate']['output'];
  readonly effectiveDate: Scalars['FullDate']['output'];
  readonly text: Maybe<Scalars['String']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexFcfwAssertionConnection {
  readonly __typename?: 'FlexFcfwAssertionConnection';
  readonly edges: ReadonlyArray<FlexFcfwAssertionEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexFcfwAssertionEdge {
  readonly __typename?: 'FlexFcfwAssertionEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexFcfwAssertion;
}

export interface FlexFcfwRequest {
  readonly __typename?: 'FlexFcfwRequest';
  readonly companyId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly membership: Membership;
  /** The allowed dates for admin to assert on, always prefer the requestedDate */
  readonly possibleAssertionDates: ReadonlyArray<Scalars['Date']['output']>;
  /** The effective date on the change */
  readonly requestedDate: Scalars['Date']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexFcfwRequestConnection {
  readonly __typename?: 'FlexFcfwRequestConnection';
  readonly edges: ReadonlyArray<FlexFcfwRequestEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexFcfwRequestEdge {
  readonly __typename?: 'FlexFcfwRequestEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexFcfwRequest;
}

export interface FlexFile {
  readonly __typename?: 'FlexFile';
  readonly name: Scalars['String']['output'];
  readonly path: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
}

export interface FlexFileUploadInput {
  readonly companyId: Scalars['ID']['input'];
  readonly fileName: Scalars['String']['input'];
}

export interface FlexFileUploadUrl {
  readonly __typename?: 'FlexFileUploadUrl';
  readonly name: Maybe<Scalars['String']['output']>;
  readonly path: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface FlexFilmstadenEdge {
  readonly __typename?: 'FlexFilmstadenEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexFilmstadenOrder;
}

export interface FlexFilmstadenOrder {
  readonly __typename?: 'FlexFilmstadenOrder';
  readonly createdAt: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly ticketCodes: ReadonlyArray<Maybe<Scalars['String']['output']>>;
}

export interface FlexFilmstadenOrderConnection {
  readonly __typename?: 'FlexFilmstadenOrderConnection';
  readonly edges: Maybe<ReadonlyArray<Maybe<FlexFilmstadenEdge>>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexFixedPremium {
  readonly __typename?: 'FlexFixedPremium';
  readonly actor: Maybe<Actor>;
  readonly authorizedBy: Scalars['String']['output'];
  readonly companyId: Scalars['ID']['output'];
  readonly createdAt: Scalars['Date']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly premium: Scalars['Money']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexFixedPremiumConnection {
  readonly __typename?: 'FlexFixedPremiumConnection';
  readonly edges: ReadonlyArray<FlexFixedPremiumEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexFixedPremiumEdge {
  readonly __typename?: 'FlexFixedPremiumEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexFixedPremium;
}

export interface FlexGdprDeletionCheck {
  readonly __typename?: 'FlexGdprDeletionCheck';
  readonly okToDelete: Scalars['Boolean']['output'];
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexHealthcareConfiguration {
  /** Optionally enter an agreementId to use for insurances */
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  /** If converting from a different insurance broker */
  readonly convertingDate?: InputMaybe<Scalars['FullDate']['input']>;
  /** Required if convertingOn is set */
  readonly convertingFrom?: InputMaybe<Scalars['String']['input']>;
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
  readonly level: FlexHealthcareLevel;
}

export enum FlexHealthcareLevel {
  BRONS_0 = 'BRONS_0',
  BRONS_500 = 'BRONS_500',
  GOLD = 'GOLD',
  L0 = 'L0',
  L500 = 'L500',
  L750 = 'L750',
  L1000 = 'L1000',
  L1500 = 'L1500'
}

export enum FlexInsuranceAction {
  ADJUST = 'ADJUST',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  SINGLE_PREMIUM = 'SINGLE_PREMIUM',
  TERMINATE = 'TERMINATE'
}

export interface FlexInvoicingData {
  readonly __typename?: 'FlexInvoicingData';
  /**
   * The number of users entitled to advice subscription.
   *
   * Advice subscription is defined as being entitled to
   * a pension benefit with advice deduction within the premium
   * and not having opted out from advice.
   */
  readonly numberOfUsersWithAdviceSubscription: Scalars['Int']['output'];
}

export interface FlexLifeConfiguration {
  /** Optionally enter an agreementId to use for insurances */
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
  readonly level: FlexLifeLevel;
}

export enum FlexLifeLevel {
  PBB5 = 'PBB5',
  PBB10 = 'PBB10',
  PBB15 = 'PBB15',
  PBB20 = 'PBB20',
  PBB25 = 'PBB25',
  PBB30 = 'PBB30',
  PBB35 = 'PBB35',
  PBB40 = 'PBB40',
  PBB45 = 'PBB45',
  PBB50 = 'PBB50'
}

export enum FlexMaxOnetimeRule {
  AVAILABLE_ONETIME_RULE = 'AVAILABLE_ONETIME_RULE',
  ONETIME_DEDUCTION_SHARE_RULE = 'ONETIME_DEDUCTION_SHARE_RULE',
  ONETIME_MAXIMUM_RULE = 'ONETIME_MAXIMUM_RULE',
  TOTAL_EXCHANGE_RULE = 'TOTAL_EXCHANGE_RULE',
  TOTAL_INCOME_RULE = 'TOTAL_INCOME_RULE',
  TOTAL_PENSION_RULE = 'TOTAL_PENSION_RULE',
  TOTAL_PENSION_TO_SALARY_RULE = 'TOTAL_PENSION_TO_SALARY_RULE'
}

export enum FlexMaxOngoingRule {
  AVAILABLE_INCOME_RULE = 'AVAILABLE_INCOME_RULE',
  BASE_SALARY_RULE = 'BASE_SALARY_RULE',
  ONGOING_DEDUCTION_SHARE_RULE = 'ONGOING_DEDUCTION_SHARE_RULE',
  ONGOING_MAXIMUM_RULE = 'ONGOING_MAXIMUM_RULE',
  ONGOING_MINIMUM_RULE = 'ONGOING_MINIMUM_RULE',
  TOTAL_EXCHANGE_RULE = 'TOTAL_EXCHANGE_RULE',
  TOTAL_INCOME_RULE = 'TOTAL_INCOME_RULE',
  TOTAL_PENSION_RULE = 'TOTAL_PENSION_RULE',
  TOTAL_PENSION_TO_SALARY_RULE = 'TOTAL_PENSION_TO_SALARY_RULE'
}

export interface FlexMembershipData {
  readonly benefitPackageId: Scalars['ID']['input'];
  readonly birthDate: Scalars['Date']['input'];
  readonly companyId: Scalars['ID']['input'];
  readonly remunerations: ReadonlyArray<FlexRemuneration>;
}

export interface FlexMembershipKey {
  readonly companyId: Scalars['ID']['input'];
  readonly userAccountId: Scalars['ID']['input'];
}

export interface FlexMiscEntitlement extends FlexEntitlement {
  readonly __typename?: 'FlexMiscEntitlement';
  readonly benefit: FlexBenefit;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly data: Maybe<Scalars['JSON']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexMovieTicketStock {
  readonly __typename?: 'FlexMovieTicketStock';
  readonly isAvailable: Scalars['Boolean']['output'];
  readonly price: Scalars['Money']['output'];
  readonly stockCount: Scalars['Int']['output'];
}

export interface FlexMovieTicketsStock {
  readonly __typename?: 'FlexMovieTicketsStock';
  readonly normalTicket: FlexMovieTicketStock;
}

export interface FlexNannyNuFormPayload {
  readonly __typename?: 'FlexNannyNuFormPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export enum FlexNordeaManagementType {
  FUND = 'FUND',
  TRAD = 'TRAD'
}

export interface FlexOccupationalPensionChange extends FlexChange {
  readonly __typename?: 'FlexOccupationalPensionChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly premium: Scalars['Money']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexOccupationalPensionConfiguration {
  /** The default provider for the pension benefit. Defaults to nordea_fund */
  readonly defaultProvider?: InputMaybe<FlexDefaultPensionProvider>;
  /** If we are to use fixed premium for the benefit. */
  readonly fixedPremium: Scalars['Boolean']['input'];
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
  /** Payroll element code for proportional pension benefits. */
  readonly payrollElementCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Payroll element multiplier for proportional pension benefits, either 12 or 12.2.
   *       Required if premiumMatrix is set.
   */
  readonly payrollElementMultiplier?: InputMaybe<Scalars['String']['input']>;
  /** List of premium deductions */
  readonly premiumDeductions: ReadonlyArray<FlexPremiumCentralDeductionType>;
  /** Premium matrix for the pension benefit, required unless fixed premium is true. */
  readonly premiumMatrix?: InputMaybe<FlexPremiumMatrix>;
  /** If true, will create premium waiver insurance for insurances on this benefit. */
  readonly premiumWaiver: Scalars['Boolean']['input'];
  /** Optional premium waiver agreement id */
  readonly premiumWaiverAgreementId?: InputMaybe<Scalars['ID']['input']>;
  /** Required if premiumWaiverConvertingFrom is set */
  readonly premiumWaiverConvertingDate?: InputMaybe<Scalars['FullDate']['input']>;
  /** If converting from a different insurance broker */
  readonly premiumWaiverConvertingFrom?: InputMaybe<Scalars['String']['input']>;
  /** If the premium waiver covers fixed premium on top of proportional premium. */
  readonly premiumWaiverCoversFixedOnTop?: InputMaybe<Scalars['Boolean']['input']>;
  /** Premium waiver fee as a percentage, represented as a decimal (e.g., 0.0189 for 1.89%). */
  readonly premiumWaiverFee?: InputMaybe<Scalars['Float']['input']>;
  /** List of selectable pension providers */
  readonly providers: ReadonlyArray<FlexSelectablePensionProvider>;
  /** The retirement age for the pension benefit, defaults to the default retirement age for Nordea insurances. */
  readonly retirementAge: Scalars['Int']['input'];
  /** Withdrawal period for the pension, in years (0-30). */
  readonly withdrawalPeriod?: InputMaybe<Scalars['Int']['input']>;
}

export interface FlexOccupationalPensionEntitlement extends FlexEntitlement {
  readonly __typename?: 'FlexOccupationalPensionEntitlement';
  readonly benefit: FlexBenefit;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated use fields on this subtype instead */
  readonly data: Maybe<Scalars['JSON']['output']>;
  readonly deductions: ReadonlyArray<FlexDeduction>;
  /** How much of the total gross premium is made up of fixed premium */
  readonly grossFixedPremium: Maybe<Scalars['Money']['output']>;
  /** Total gross premium */
  readonly grossPremium: Maybe<Scalars['Money']['output']>;
  /** How much of the total gross premium is made up of proportional premium */
  readonly grossProportionalPremium: Maybe<Scalars['Money']['output']>;
  readonly insurance: Maybe<Insurance>;
  /** How much of the total net premium is made up of fixed premium */
  readonly netFixedPremium: Maybe<Scalars['Money']['output']>;
  /** Total net premium */
  readonly netPremium: Maybe<Scalars['Money']['output']>;
  /** How much of the total net premium is made up of proportional premium */
  readonly netProportionalPremium: Maybe<Scalars['Money']['output']>;
  readonly status: Scalars['String']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexOptOutFromPersonalAdviceInput {
  readonly companyId: Scalars['ID']['input'];
}

export interface FlexOptOutFromPersonalAdvicePayload {
  readonly __typename?: 'FlexOptOutFromPersonalAdvicePayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface FlexOrderMovieTicketsInput {
  /** URL to redirect the user to on checkout cancellation */
  readonly cancelRedirectUrl: Scalars['String']['input'];
  /** Recipient of tickets and receipt after purchase */
  readonly email: Scalars['String']['input'];
  /** Number of normal tickets to order */
  readonly normalTickets: Scalars['Int']['input'];
  /** URL to redirect the user to on checkout success */
  readonly successRedirectUrl: Scalars['String']['input'];
}

export interface FlexOrderMovieTicketsPayload {
  readonly __typename?: 'FlexOrderMovieTicketsPayload';
  /** URL to redirect the user to for checkout */
  readonly checkoutUrl: Scalars['String']['output'];
}

export interface FlexPensionProvider {
  readonly __typename?: 'FlexPensionProvider';
  /** If null, the user has no active provider */
  readonly activeProvider: Maybe<Scalars['String']['output']>;
  readonly providerTimeline: ReadonlyArray<FlexProviderState>;
}

export interface FlexPersonalAdviceEntitlement extends FlexEntitlement {
  readonly __typename?: 'FlexPersonalAdviceEntitlement';
  /** True if the user can have a meeting with a personal advisor. */
  readonly allowedToHaveMeeting: Scalars['Boolean']['output'];
  readonly benefit: FlexBenefit;
  readonly bookedMeeting: Maybe<Meeting>;
  readonly bookingLink: Maybe<BookingLink>;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated use fields on this subtype instead */
  readonly data: Maybe<Scalars['JSON']['output']>;
  /** The next date the user can have a meeting with a personal advisor. null if the user cant have any more meetings. */
  readonly earliestNextMeetingDate: Maybe<Scalars['Date']['output']>;
  /** The fee the user pays for the personal advice service. */
  readonly employeeFee: Scalars['Money']['output'];
  /** True if the user has opted out from having personal advice meetings. False otherwise. */
  readonly hasOptedOut: Scalars['Boolean']['output'];
  /** Date of the latest known personal advice meeting. Null if no known meeting exists. */
  readonly latestMeetingDate: Maybe<Scalars['Date']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexPersonalAdviceMeetingHistory {
  readonly __typename?: 'FlexPersonalAdviceMeetingHistory';
  readonly companyId: Scalars['ID']['output'];
  readonly date: Scalars['Date']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexPersonalAdviceMeetingHistoryConnection {
  readonly __typename?: 'FlexPersonalAdviceMeetingHistoryConnection';
  readonly edges: ReadonlyArray<FlexPersonalAdviceMeetingHistoryEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexPersonalAdviceMeetingHistoryEdge {
  readonly __typename?: 'FlexPersonalAdviceMeetingHistoryEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexPersonalAdviceMeetingHistory;
}

export enum FlexPremiumCentralDeductionType {
  ADVINANS_ADVICE_FEE = 'ADVINANS_ADVICE_FEE',
  ADVINANS_MEMBERSHIP_FEE = 'ADVINANS_MEMBERSHIP_FEE',
  PREMIUM_WAIVER = 'PREMIUM_WAIVER',
  SICKNESS_INSURANCE = 'SICKNESS_INSURANCE'
}

export interface FlexPremiumMatrix {
  /**
   * Represents the age brackets.
   *     @example [0, 25, 65] represents ages [0-24], [25-64], [65-inf)
   */
  readonly age: ReadonlyArray<Scalars['Int']['input']>;
  /**
   * Represents the base amount limits. To be multiplied by the base amount.
   *     @example [0.0, 7.5, 30] represents [0-7.5), [7.5, 30), [30, inf)
   */
  readonly baseAmount: ReadonlyArray<Scalars['String']['input']>;
  /** The base amount type which relates to the value the base amount limits will be multiplied by. */
  readonly baseAmountType: FlexBaseAmountType;
  /** Defaults to ibb from @advinans/belt-se-constants. Returns the IBB amount of the year. */
  readonly ibb?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Represents the income shares per age and base amount limits.
   *     First indexing is based on the age brackets, second indexing on the base amount limits.
   *     @example [[0.100, 0.200], [0.300, 0.400]] represents 10% share for the first age bracket,
   *     first base amount limit, 20% share for the first age bracket, second base amount limit etc.
   */
  readonly intervals: ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>;
  /** Defaults to pbb from @advinans/belt-se-constants. Returns the PBB amount of the year. */
  readonly pbb?: InputMaybe<Scalars['Float']['input']>;
}

export interface FlexProviderState {
  readonly __typename?: 'FlexProviderState';
  /** ISO 8601 date string (YYYY-MM-DD) */
  readonly effectiveDate: Scalars['String']['output'];
  readonly provider: Scalars['String']['output'];
}

export interface FlexPurchasedMovieTicket {
  readonly __typename?: 'FlexPurchasedMovieTicket';
  readonly code: Scalars['String']['output'];
  /** The date when the ticket expires and is no longer valid for use at the cinema */
  readonly expirationDate: Scalars['Date']['output'];
  /** The date and time when the ticket was purchased by the user */
  readonly orderedAt: Scalars['DateTime']['output'];
}

export interface FlexPurchasedMovieTicketConnection {
  readonly __typename?: 'FlexPurchasedMovieTicketConnection';
  readonly edges: ReadonlyArray<FlexPurchasedMovieTicketEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexPurchasedMovieTicketEdge {
  readonly __typename?: 'FlexPurchasedMovieTicketEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexPurchasedMovieTicket;
}

export interface FlexRejectSalaryExchangeRequestInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly rejectionReason?: InputMaybe<Scalars['String']['input']>;
  readonly requestId: Scalars['ID']['input'];
}

export interface FlexRejectSalaryExchangeRequestPayload {
  readonly __typename?: 'FlexRejectSalaryExchangeRequestPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly salaryExchangeRequest: FlexSalaryExchangeRequest;
}

export interface FlexRemuneration {
  readonly price: Scalars['Money']['input'];
  readonly typeId: Scalars['String']['input'];
}

export interface FlexRequestSalaryExchangeInput {
  /** Required if provider is advinans */
  readonly adviceId?: InputMaybe<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly deductionValue: Scalars['Money']['input'];
  readonly provider: FlexSalaryExchangeProvider;
}

export interface FlexRequestSalaryExchangePayload {
  readonly __typename?: 'FlexRequestSalaryExchangePayload';
  readonly autoStartToken: Maybe<Scalars['String']['output']>;
  readonly orderRef: Maybe<Scalars['String']['output']>;
  readonly qrData: Maybe<Scalars['String']['output']>;
  readonly ticketId: Scalars['ID']['output'];
}

export interface FlexRetirementAgeChange extends FlexChange {
  readonly __typename?: 'FlexRetirementAgeChange';
  readonly effectiveDate: Scalars['Date']['output'];
  readonly newRetirementAge: Scalars['Int']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskAccidentChange extends FlexChange {
  readonly __typename?: 'FlexRiskAccidentChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly level: Scalars['String']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskEntitlement extends FlexEntitlement {
  readonly __typename?: 'FlexRiskEntitlement';
  readonly benefit: FlexBenefit;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated use fields on this subtype instead */
  readonly data: Maybe<Scalars['JSON']['output']>;
  readonly insurance: Maybe<Insurance>;
  readonly level: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface FlexRiskHealthcareChange extends FlexChange {
  readonly __typename?: 'FlexRiskHealthcareChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly level: Scalars['String']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskLifeChange extends FlexChange {
  readonly __typename?: 'FlexRiskLifeChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly level: Scalars['String']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskPremiumWaiverChange extends FlexChange {
  readonly __typename?: 'FlexRiskPremiumWaiverChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly monthlyPremium: Scalars['Money']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskSicknessAndAccidentChange extends FlexChange {
  readonly __typename?: 'FlexRiskSicknessAndAccidentChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly level: Scalars['String']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskSicknessChange extends FlexChange {
  readonly __typename?: 'FlexRiskSicknessChange';
  readonly action: FlexChangeAction;
  readonly annualSalary: Scalars['Money']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly level: Scalars['String']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexRiskTglChange extends FlexChange {
  readonly __typename?: 'FlexRiskTglChange';
  readonly action: FlexChangeAction;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly status: FlexChangeStatus;
  readonly type: FlexChangeType;
}

export interface FlexSalaryExchange {
  readonly __typename?: 'FlexSalaryExchange';
  readonly benefit: FlexBenefit;
  readonly companyId: Scalars['ID']['output'];
  readonly deductionValue: Scalars['Money']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly id: Scalars['ID']['output'];
  readonly insuranceNumber: Maybe<Scalars['String']['output']>;
  readonly membership: Membership;
  /** What this is depends on the type field. For ongoing this is the monthly premium, for onetime this is the total onetime" amount. */
  readonly premium: Scalars['Money']['output'];
  readonly provider: FlexSalaryExchangeProvider;
  readonly remuneration: Maybe<RemunerationItem>;
  /** The id of the salary exchange request that created this salary exchange. */
  readonly salaryExchangeRequestId: Maybe<Scalars['ID']['output']>;
  readonly status: FlexSalaryExchangeStatus;
  /** The id of the salary exchange request that created this salary exchange. */
  readonly terminationReason: Maybe<Scalars['String']['output']>;
  readonly type: FlexSalaryExchangeType;
  readonly userAccountId: Scalars['ID']['output'];
  readonly violated: Scalars['Boolean']['output'];
}

export interface FlexSalaryExchangeAdvice {
  readonly __typename?: 'FlexSalaryExchangeAdvice';
  /** The difference between monthly premium and deduction value (and ITP 1 compensation) */
  readonly boost: Scalars['Money']['output'];
  readonly confirmationText: Scalars['String']['output'];
  /** Reflected deduction value */
  readonly deductionValue: Scalars['Money']['output'];
  /** Part of monthly premium which is compensation for lower pension premium due to ITP1. Will be 0 for non-ITP1 users. */
  readonly itp1Compensation: Maybe<Scalars['Money']['output']>;
  /** Computed monthly salary exchange premium */
  readonly monthlyPremium: Scalars['Money']['output'];
  /** Reported monthly salary before salary exchange */
  readonly monthlySalary: Scalars['Money']['output'];
  readonly monthlySalaryAfterDeduction: Scalars['Money']['output'];
  readonly needText: Scalars['String']['output'];
  /** The net salary paid after all deductions */
  readonly netSalary: Scalars['Money']['output'];
  /** The effective net salary deduction resulting from this, including tax considerations */
  readonly netSalaryEffect: Scalars['Money']['output'];
  readonly provider: FlexSalaryExchangeProvider;
  readonly requestCode: Scalars['String']['output'];
  /** The tax paid on the gross salary deduction */
  readonly tax: Scalars['Money']['output'];
  readonly taxBefore: Scalars['Money']['output'];
}

export enum FlexSalaryExchangeBenefitProvider {
  Advinans = 'Advinans',
  Collectum = 'Collectum',
  Lf = 'Lf',
  NordeaFund = 'NordeaFund',
  NordeaTrad = 'NordeaTrad',
  Nordnet = 'Nordnet'
}

export interface FlexSalaryExchangeConfiguration {
  /** Days around the confirmation date where new SX applications can't be made. */
  readonly applicationRestrictionRange?: InputMaybe<FlexApplicationRestrictionRangeInput>;
  readonly automaticApproval?: InputMaybe<Scalars['Boolean']['input']>;
  readonly basePensionableIncomePayrollElementCode: Scalars['String']['input'];
  readonly basePensionableIncomePayrollElementMultiplier: Scalars['String']['input'];
  readonly baseSalaryMin?: InputMaybe<Scalars['Int']['input']>;
  readonly baseSalaryPayrollElementCode?: InputMaybe<Scalars['String']['input']>;
  readonly baseSalaryPayrollElementMultiplier?: InputMaybe<Scalars['String']['input']>;
  /**
   * Offsets the date on which admins need to accept salary exchange for it to not become auto-rejected.
   *       The offset is relative to the 1st of the month. Defaults to 0.
   */
  readonly confirmationOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  /** Defaults to 8.07 */
  readonly incomeMinIbb?: InputMaybe<Scalars['Float']['input']>;
  readonly itp1CompensationPremiumMatrix?: InputMaybe<FlexPremiumMatrix>;
  readonly onetimeDeductionShareMax?: InputMaybe<Scalars['Float']['input']>;
  /** The maximum amount that can be exchanged in a single onetime SX */
  readonly onetimeMax?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum amount that can be exchanged in a single onetime SX. Defaults to 1000. */
  readonly onetimeMin?: InputMaybe<Scalars['Int']['input']>;
  readonly onetimeSalaryPayrollElementCode?: InputMaybe<Scalars['String']['input']>;
  readonly ongoingDeductionShareMax?: InputMaybe<Scalars['Float']['input']>;
  /** The maximum amount that can be exchanged in ongoing SX */
  readonly ongoingMax?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum amount that can be exchanged in ongoing SX. Defaults to 1000. */
  readonly ongoingMin?: InputMaybe<Scalars['Int']['input']>;
  /** Defaults to 10. */
  readonly pensionMaxPbb?: InputMaybe<Scalars['Float']['input']>;
  readonly pensionTaxBenefitShare: Scalars['Float']['input'];
  /** Defaults to 0.35 */
  readonly pensionToSalaryMax?: InputMaybe<Scalars['Float']['input']>;
  readonly providers: ReadonlyArray<FlexSalaryExchangeBenefitProvider>;
  /** Defaults to 65 */
  readonly retirementAge?: InputMaybe<Scalars['Int']['input']>;
  /**
   * If true, salary exchanges should be automatically terminated on employee absence (parental leave, off duty, etc.).
   *       Defaults to false
   */
  readonly terminateOnAbsence?: InputMaybe<Scalars['Boolean']['input']>;
  /** he maximum annual amount that can be exchanged, both ongoing and onetime counts towards the total. */
  readonly totalMax?: InputMaybe<Scalars['Int']['input']>;
  /** Defaults to NOTIFY */
  readonly violatedBehavior?: InputMaybe<FlexSalaryExchangeViolatedBehavior>;
  /** Withdrawal period for pension, in years */
  readonly withdrawalPeriod?: InputMaybe<Scalars['Int']['input']>;
}

export interface FlexSalaryExchangeConnection {
  readonly __typename?: 'FlexSalaryExchangeConnection';
  readonly edges: ReadonlyArray<FlexSalaryExchangeEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexSalaryExchangeEdge {
  readonly __typename?: 'FlexSalaryExchangeEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexSalaryExchange;
}

export interface FlexSalaryExchangeEntitlement extends FlexEntitlement {
  readonly __typename?: 'FlexSalaryExchangeEntitlement';
  /** Dates on which the user can request salary exchange for the next month. If we are outside of these dates we can't request salary exchange until we hit these dates.For example from may be 2023-06-04 and to may be 2023-07-03 meaning that before 2023-06-04 and from 2023-07-03 the user can't request salary exchange for 2023-07-01. */
  readonly applicationDateRange: Scalars['DateRange']['output'];
  readonly benefit: FlexBenefit;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated use fields on this subtype instead */
  readonly data: Maybe<Scalars['JSON']['output']>;
  /** True if the user can apply for onetime (bonus) salary exchange. */
  readonly eligibleForOnetimeExchange: Scalars['Boolean']['output'];
  /** True if the user can apply for ongoing salary exchange. */
  readonly eligibleForOngoingExchange: Scalars['Boolean']['output'];
  readonly insurance: Maybe<Insurance>;
  readonly maxOnetimeDeduction: Scalars['Money']['output'];
  readonly maxOnetimeRule: FlexMaxOnetimeRule;
  readonly maxOngoingDeduction: Scalars['Money']['output'];
  readonly maxOngoingRule: FlexMaxOngoingRule;
  readonly minOnetimeDeduction: Scalars['Money']['output'];
  readonly minOngoingDeduction: Scalars['Money']['output'];
  /** Onetime salary (bonus) available for exchange for this month. */
  readonly onetimeSalaryAvailable: Scalars['Money']['output'];
  readonly providers: ReadonlyArray<Scalars['String']['output']>;
  /** The effective date for a salary exchange request if requested today. */
  readonly requestEffectiveDate: Scalars['Date']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export enum FlexSalaryExchangeProvider {
  advinans = 'advinans',
  collectum = 'collectum',
  lf = 'lf',
  nordea_fund = 'nordea_fund',
  nordea_trad = 'nordea_trad',
  nordnet = 'nordnet'
}

export interface FlexSalaryExchangeReport {
  readonly __typename?: 'FlexSalaryExchangeReport';
  readonly file: FlexFile;
  readonly reportCreatedAt: Scalars['Date']['output'];
}

export interface FlexSalaryExchangeReportConnection {
  readonly __typename?: 'FlexSalaryExchangeReportConnection';
  readonly edges: ReadonlyArray<FlexSalaryExchangeReportEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexSalaryExchangeReportEdge {
  readonly __typename?: 'FlexSalaryExchangeReportEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexSalaryExchangeReport;
}

export interface FlexSalaryExchangeRequest {
  readonly __typename?: 'FlexSalaryExchangeRequest';
  readonly adviceId: Maybe<Scalars['ID']['output']>;
  readonly benefit: FlexBenefit;
  readonly companyId: Scalars['ID']['output'];
  readonly deductionValue: Scalars['Money']['output'];
  readonly document: Maybe<Document>;
  readonly effectiveDate: Scalars['Date']['output'];
  readonly id: Scalars['ID']['output'];
  /** Compensation due to lowered pension when making salary exchange under ITP1 collective agreement. Will be 0 for non ITP1 users. */
  readonly itp1Compensation: Maybe<Scalars['Money']['output']>;
  readonly membership: Membership;
  /** What this is depends on the type field. For ongoing this is the monthly premium, for onetime this is the total "onetime" amount. */
  readonly premium: Scalars['Money']['output'];
  readonly provider: FlexSalaryExchangeProvider;
  readonly rejectionReason: Maybe<Scalars['String']['output']>;
  readonly remuneration: Maybe<RemunerationItem>;
  readonly requestedAt: Scalars['Date']['output'];
  readonly status: FlexSalaryExchangeRequestStatus;
  readonly type: FlexSalaryExchangeType;
  readonly userAccountId: Scalars['ID']['output'];
  readonly violated: Scalars['Boolean']['output'];
}

export interface FlexSalaryExchangeRequestConnection {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection';
  readonly edges: ReadonlyArray<FlexSalaryExchangeRequestEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexSalaryExchangeRequestEdge {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexSalaryExchangeRequest;
}

export enum FlexSalaryExchangeRequestStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

export interface FlexSalaryExchangeRequestsHistoryConnection {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryConnection';
  readonly edges: ReadonlyArray<FlexSalaryExchangeRequestsHistoryEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexSalaryExchangeRequestsHistoryEdge {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexSalaryExchangeRequest;
}

export enum FlexSalaryExchangeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum FlexSalaryExchangeType {
  ONETIME = 'ONETIME',
  ONGOING = 'ONGOING'
}

export enum FlexSalaryExchangeViolatedBehavior {
  NOTIFY = 'NOTIFY',
  TERMINATE = 'TERMINATE'
}

export interface FlexSelectPensionProviderInput {
  readonly companyId: Scalars['ID']['input'];
  readonly provider: Scalars['String']['input'];
}

export interface FlexSelectPensionProviderPayload {
  readonly __typename?: 'FlexSelectPensionProviderPayload';
  readonly autoStartToken: Maybe<Scalars['String']['output']>;
  readonly orderRef: Maybe<Scalars['String']['output']>;
  readonly qrData: Maybe<Scalars['String']['output']>;
  readonly ticketId: Scalars['ID']['output'];
}

export enum FlexSelectablePensionProvider {
  Advinans = 'Advinans',
  Lf = 'Lf',
  NordeaFund = 'NordeaFund',
  NordeaTrad = 'NordeaTrad',
  Nordnet = 'Nordnet',
  NordnetSsek = 'NordnetSsek'
}

export interface FlexSetFixedPremiumInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly effectiveDate: Scalars['Date']['input'];
  readonly premium: Scalars['Money']['input'];
  readonly userAccountId: Scalars['ID']['input'];
}

export interface FlexSetFixedPremiumPayload {
  readonly __typename?: 'FlexSetFixedPremiumPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface FlexSicknessAndAccidentConfiguration {
  /** Optionally enter an agreementId to use for insurances */
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
  readonly level: FlexSicknessAndAccidentLevel;
}

export enum FlexSicknessAndAccidentLevel {
  PBB20 = 'PBB20',
  PBB30 = 'PBB30',
  PBB40 = 'PBB40',
  PBB50 = 'PBB50'
}

export interface FlexSicknessConfiguration {
  /** Optionally enter an agreementId to use for insurances */
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  /** If converting from a different insurance broker */
  readonly convertingDate?: InputMaybe<Scalars['FullDate']['input']>;
  /** Required if convertingOn is set */
  readonly convertingFrom?: InputMaybe<Scalars['String']['input']>;
  /** True if users of this benefit has access to extended sick pay */
  readonly extendedSickPay: Scalars['Boolean']['input'];
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
  readonly level: FlexSicknessLevel;
  readonly payrollElementCode: Scalars['String']['input'];
  readonly payrollElementMultiplier: Scalars['String']['input'];
}

export enum FlexSicknessLevel {
  CONSOLIDATION = 'CONSOLIDATION',
  HYBRID = 'HYBRID',
  ITP = 'ITP',
  KOMP = 'KOMP',
  MAX = 'MAX'
}

export interface FlexSinglePremium {
  readonly __typename?: 'FlexSinglePremium';
  readonly actor: Maybe<Actor>;
  readonly authorizedBy: Scalars['String']['output'];
  readonly authorizedByMembership: Maybe<Membership>;
  readonly createdAt: Scalars['Date']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly insuranceNumber: Scalars['String']['output'];
  readonly premium: Scalars['Money']['output'];
}


export interface FlexSinglePremiumauthorizedByMembershipArgs {
  companyId: Scalars['ID']['input'];
}

export interface FlexSinglePremiumConnection {
  readonly __typename?: 'FlexSinglePremiumConnection';
  readonly edges: ReadonlyArray<FlexSinglePremiumEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface FlexSinglePremiumEdge {
  readonly __typename?: 'FlexSinglePremiumEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: FlexSinglePremium;
}

export interface FlexSinglePremiumInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly insuranceInstituteId: Scalars['String']['input'];
  readonly insuranceNumber: Scalars['String']['input'];
  readonly premium: Scalars['Money']['input'];
}

export interface FlexSinglePremiumInputPayload {
  readonly __typename?: 'FlexSinglePremiumInputPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export enum FlexSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface FlexSubmitNannyNuInput {
  readonly city: Scalars['String']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly need: Scalars['String']['input'];
  readonly phone: Scalars['String']['input'];
}

export interface FlexTerminateSalaryExchangeInput {
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly reason?: InputMaybe<Scalars['String']['input']>;
}

export interface FlexTerminateSalaryExchangePayload {
  readonly __typename?: 'FlexTerminateSalaryExchangePayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly salaryExchange: FlexSalaryExchange;
}

export interface FlexTglConfiguration {
  /** Optionally enter an agreementId to use for insurances */
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  /** If the insurance should be active when a user is on parental leave */
  readonly isActiveOnParentalLeave: Scalars['Boolean']['input'];
}

export interface FlexTicketComplete {
  readonly __typename?: 'FlexTicketComplete';
  readonly status: FlexBankIdTicketStatus;
  readonly ticketId: Scalars['ID']['output'];
}

export interface FlexTicketFailure {
  readonly __typename?: 'FlexTicketFailure';
  readonly status: FlexBankIdTicketStatus;
  readonly ticketId: Scalars['ID']['output'];
}

export interface FlexTicketProgress {
  readonly __typename?: 'FlexTicketProgress';
  readonly status: FlexBankIdTicketStatus;
  readonly ticketId: Scalars['ID']['output'];
}

export type FlexTicketStatus = FlexTicketComplete | FlexTicketFailure | FlexTicketProgress;

export interface FlexUpdateAccidentBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexAccidentConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateBenefitCategoryInput {
  readonly id: Scalars['ID']['input'];
  readonly translations: ReadonlyArray<FlexBenefitCategoryTranslation>;
}

export interface FlexUpdateBenefitCategoryPayload {
  readonly __typename?: 'FlexUpdateBenefitCategoryPayload';
  readonly categoryId: Scalars['ID']['output'];
}

export interface FlexUpdateBenefitInput {
  readonly categoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly configEffectiveDate?: InputMaybe<Scalars['Date']['input']>;
  readonly configuration?: InputMaybe<Scalars['JSON']['input']>;
  readonly content?: InputMaybe<FlexBenefitContentInput>;
  readonly entitlementRules?: InputMaybe<FlexEntitlementRulesInput>;
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly supplierId?: InputMaybe<Scalars['ID']['input']>;
}

export interface FlexUpdateBenefitPayload {
  readonly __typename?: 'FlexUpdateBenefitPayload';
  readonly benefit: FlexBenefit;
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface FlexUpdateBenefitSupplierInput {
  readonly id: Scalars['ID']['input'];
  readonly logoPath?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly websiteUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface FlexUpdateBenefitSupplierPayload {
  readonly __typename?: 'FlexUpdateBenefitSupplierPayload';
  readonly supplier: FlexBenefitSupplier;
}

export interface FlexUpdateHealthcareBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexHealthcareConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateLifeBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexLifeConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateOccupationalPensionBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexOccupationalPensionConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateSalaryExchangeBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexSalaryExchangeConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateSicknessAndAccidentBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexSicknessAndAccidentConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateSicknessBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexSicknessConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FlexUpdateTglBenefitInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly configEffectiveDate: Scalars['FullDate']['input'];
  readonly configuration: FlexTglConfiguration;
  /** The date the benefit is valid from, defaults to null */
  readonly entitlementWindowFrom?: InputMaybe<Scalars['FullDate']['input']>;
  /** The date the benefit is valid to, defaults to null */
  readonly entitlementWindowTo?: InputMaybe<Scalars['FullDate']['input']>;
}

export interface FullyCapableForWorkInfo {
  readonly __typename?: 'FullyCapableForWorkInfo';
  readonly companyId: Scalars['ID']['output'];
  readonly infoText: Scalars['String']['output'];
}

export interface FullyCapableForWorkRequest {
  readonly __typename?: 'FullyCapableForWorkRequest';
  readonly companyId: Scalars['ID']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly effectiveUntil: Maybe<Scalars['Date']['output']>;
  readonly membership: Membership;
  readonly type: Scalars['String']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface Fund extends Instrument {
  readonly __typename?: 'Fund';
  /** If offered via Advinans; the offering and associated discounts */
  readonly advinansOffering: Maybe<FundOffering>;
  readonly assetClass: Scalars['String']['output'];
  readonly category: Maybe<Scalars['String']['output']>;
  readonly categoryId: Maybe<Scalars['String']['output']>;
  /** Ordered by share DESC. "null" if datapoint is missing. */
  readonly countries: Maybe<ReadonlyArray<Exposure>>;
  readonly currency: Maybe<Scalars['String']['output']>;
  readonly dayEndNav: Maybe<Scalars['Money']['output']>;
  readonly dayEndNavAsOf: Maybe<Scalars['Date']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  /** Ordered by share DESC. "null" if datapoint is missing. */
  readonly esg: FundEsg;
  readonly externalId: Maybe<Scalars['String']['output']>;
  /** Ordered by share DESC. "null" if datapoint is missing. */
  readonly holdings: Maybe<ReadonlyArray<Exposure>>;
  readonly id: Scalars['ID']['output'];
  /** Is the fund managed passively to follow an index? */
  readonly index: Maybe<Scalars['Boolean']['output']>;
  readonly isin: Maybe<Scalars['String']['output']>;
  readonly kiidUrl: Maybe<Scalars['String']['output']>;
  /** Precision of 4 */
  readonly managementFee: Maybe<Scalars['DecimalFraction']['output']>;
  readonly name: Scalars['String']['output'];
  readonly netAssets: Maybe<Scalars['Money']['output']>;
  readonly netAssetsAsOf: Maybe<Scalars['Date']['output']>;
  /** Precision of 4 */
  readonly ongoingCharge: Maybe<Scalars['DecimalFraction']['output']>;
  /**
   * If any of the benchmarked funds is not part of Advinans' fund offering
   * the field will take longer to resolve.
   */
  readonly performance: Maybe<ReadonlyArray<FundPerformanceItem>>;
  /** Precision of 4 */
  readonly performanceFee: Maybe<Scalars['DecimalFraction']['output']>;
  readonly price: Maybe<CurrencyValue>;
  readonly priceAsOf: Maybe<Scalars['Date']['output']>;
  /** Precision of 4 */
  readonly priceToBookValue: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly priceToCashFlow: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly priceToEarnings: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly priceToRevenue: Maybe<Scalars['DecimalFraction']['output']>;
  /** Fund's benchmark according to its prospectus */
  readonly prospectusBenchmark: Maybe<Scalars['String']['output']>;
  readonly provider: Maybe<Scalars['String']['output']>;
  /**
   * Used for mapping fund logotypes. Several providers might belong to the same
   * holding company
   */
  readonly providerHoldingCompany: Maybe<FundProvider>;
  readonly providerWebsite: Maybe<Scalars['String']['output']>;
  /** Precision of 4 */
  readonly return1M: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return1Y: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return3M: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return3Y: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return5Y: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly return6M: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly returnOnAssets: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly returnOnEquity: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly returnSinceStart: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly returnYTD: Maybe<Scalars['DecimalFraction']['output']>;
  readonly riskIndicator: Maybe<Scalars['Int']['output']>;
  /** Ordered by share DESC. "null" if datapoint is missing. */
  readonly sectors: Maybe<ReadonlyArray<Exposure>>;
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly tags: Maybe<ReadonlyArray<FundTag>>;
  /** Sum of ongoing charge, performanceFee and transaction fee. Precision of 4 */
  readonly totalFee: Maybe<Scalars['DecimalFraction']['output']>;
  /** Precision of 4 */
  readonly transactionFee: Maybe<Scalars['DecimalFraction']['output']>;
  /** Type of fund, e.g. "Equity" and "Fixed Income" */
  readonly type: FundType;
  readonly ucits: Maybe<Scalars['Boolean']['output']>;
}


export interface FundperformanceArgs {
  benchmarkIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}

export interface FundEsg {
  readonly __typename?: 'FundEsg';
  readonly co2RiskScore: Maybe<Scalars['Float']['output']>;
  readonly controversial: Maybe<ReadonlyArray<Exposure>>;
  readonly lowCo2Designation: Maybe<Scalars['Boolean']['output']>;
  readonly positive: Maybe<ReadonlyArray<Exposure>>;
}

export interface FundOffering {
  readonly __typename?: 'FundOffering';
  readonly fund: Fund;
  readonly institute: Institute;
  /** Management fee discount, with a precision of 4 */
  readonly managementFeeDiscount: Maybe<Scalars['DecimalFraction']['output']>;
  /** Ongoing charge with discount with a precision of 4 */
  readonly ongoingCharge: Maybe<Scalars['DecimalFraction']['output']>;
  readonly productId: Maybe<Scalars['String']['output']>;
  /** Associated retail class */
  readonly retailClass: Maybe<Fund>;
  /**
   * Total fund fee (sum of ongoing charge, performance fee, and transaction fee)
   * with discount with a precision of 4
   */
  readonly totalFee: Maybe<Scalars['DecimalFraction']['output']>;
}

export interface FundPerformanceBenchmarkItem {
  readonly __typename?: 'FundPerformanceBenchmarkItem';
  readonly id: Scalars['ID']['output'];
  readonly value: Maybe<Scalars['Float']['output']>;
}

export interface FundPerformanceItem {
  readonly __typename?: 'FundPerformanceItem';
  readonly at: Scalars['Date']['output'];
  /** Specify id(s) of benchmarks and/or funds to compare performance against. */
  readonly benchmarks: Maybe<ReadonlyArray<FundPerformanceBenchmarkItem>>;
  /** NAV (net asset value i.e. price) of fund at this point in time */
  readonly closingPrice: Maybe<Scalars['Money']['output']>;
  /** Expressed in basis points (100 basis points = 1%) */
  readonly value: Scalars['Float']['output'];
}

export enum FundProvider {
  AIFM = 'AIFM',
  AMF = 'AMF',
  AVANZA = 'AVANZA',
  BLACKROCK = 'BLACKROCK',
  BNP_PARIBAS = 'BNP_PARIBAS',
  BROCK_MILTON = 'BROCK_MILTON',
  BRUMMER = 'BRUMMER',
  CARNEGIE = 'CARNEGIE',
  CLIENS = 'CLIENS',
  COELI = 'COELI',
  DANSKE_BANK = 'DANSKE_BANK',
  DNB_ASSET_MANAGEMENT = 'DNB_ASSET_MANAGEMENT',
  EAST_CAPITAL = 'EAST_CAPITAL',
  ENTER = 'ENTER',
  FIDELITY = 'FIDELITY',
  HANDELSBANKEN = 'HANDELSBANKEN',
  IKC = 'IKC',
  LANCELOT = 'LANCELOT',
  LANNEBO = 'LANNEBO',
  LANSFORSAKRINGAR = 'LANSFORSAKRINGAR',
  MORGAN_STANLEY = 'MORGAN_STANLEY',
  MOVESTIC = 'MOVESTIC',
  NORDEA = 'NORDEA',
  NORDNET = 'NORDNET',
  NORRON = 'NORRON',
  ODDO = 'ODDO',
  OHMAN = 'OHMAN',
  PIMCO = 'PIMCO',
  PPM = 'PPM',
  PRIOR_NILSSON = 'PRIOR_NILSSON',
  SEB = 'SEB',
  SIMPLICITY = 'SIMPLICITY',
  SKANDIA = 'SKANDIA',
  SODERBERG_PARTNERS = 'SODERBERG_PARTNERS',
  SPILTAN = 'SPILTAN',
  SPP = 'SPP',
  STOREBRAND = 'STOREBRAND',
  SWEDBANK = 'SWEDBANK',
  TIN = 'TIN',
  UBS = 'UBS',
  VANGUARD = 'VANGUARD'
}

export interface FundTag {
  readonly __typename?: 'FundTag';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
}

export enum FundType {
  EQUITY = 'EQUITY',
  FIXED_INCOME = 'FIXED_INCOME',
  OTHER = 'OTHER'
}

export interface FxRate {
  readonly __typename?: 'FxRate';
  readonly at: Scalars['Date']['output'];
  /** 3-letter ISO 4217 currency code */
  readonly id: Scalars['String']['output'];
  readonly value: Scalars['Float']['output'];
}

export interface GdprDeletionCheckResult {
  readonly __typename?: 'GdprDeletionCheckResult';
  readonly okToDelete: Scalars['Boolean']['output'];
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface GeneratePriceProposalInput {
  readonly proposalId: Scalars['ID']['input'];
}

export interface GenerateProposalInput {
  readonly includeIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  readonly proposalId: Scalars['ID']['input'];
  readonly startDate: Scalars['Date']['input'];
}

export interface Generic extends Instrument {
  readonly __typename?: 'Generic';
  readonly id: Scalars['ID']['output'];
  readonly isin: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly price: Maybe<CurrencyValue>;
  readonly priceAsOf: Maybe<Scalars['Date']['output']>;
}

export interface GraphQLHistoricalInvoicedPremium {
  readonly __typename?: 'GraphQLHistoricalInvoicedPremium';
  readonly date: Scalars['Date']['output'];
  readonly totalInvoicedAmount: Scalars['Money']['output'];
}

/**
 * An import is a set of records representing a desired state of memberships.
 * Import records are created only from the source data, but when run against
 * the system will mutate memberships so that they match the source data.
 * There are no transactional guarantees between records; a single record failure
 * does not affect subsequent mutations. Failed imports can be restarted.
 */
export interface Import {
  readonly __typename?: 'Import';
  readonly actor: Maybe<Actor>;
  readonly analysis: Maybe<AnalysisResult>;
  /**
   * If true, the benefits (for new memberships) start date will fall back to
   * employment start date
   */
  readonly benefitsStartWithEmployment: Maybe<Scalars['Boolean']['output']>;
  /** Optional error text associated with failure status */
  readonly cause: Maybe<Scalars['String']['output']>;
  /**
   * A summary of the changes of the import. Will be set when changes have been generates
   * for the import.
   */
  readonly changesSummary: Maybe<ImportChangesSummary>;
  /**
   * Source column to import record mapping. Applicable to column-based import
   * formats. A decent guess is made, or an explicit source mapping from the
   * most recent import is reused.
   */
  readonly columnMappings: Maybe<ReadonlyArray<Maybe<ImportColumnMapping>>>;
  /**
   * Source column to import record mapping. Applicable to column-based import
   * formats. A decent guess is made, or an explicit source mapping from the
   * most recent import is reused.
   * @deprecated Replaced by columnMappings. Use of this still works, but using both may cause information loss.
   */
  readonly columns: Maybe<ReadonlyArray<ImportColumnMapping>>;
  readonly companyId: Scalars['ID']['output'];
  readonly createMissingOrganizationalUnits: Maybe<Scalars['Boolean']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly encoding: Maybe<Scalars['String']['output']>;
  /** A summary of the errors found in the import's import records. */
  readonly errorSummary: Maybe<ImportErrorSummary>;
  /**
   * The date that will be used as benefit start if no benefit package effective data
   * is provided in the file and the benefitsStartWithEmployment flag is not set.
   * Default value will be first of current month.
   */
  readonly fallbackDateForBenefits: Scalars['Date']['output'];
  readonly fileUrl: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly progress: Maybe<ImportProgress>;
  readonly progressStatus: ProgressStatus;
  readonly records: Maybe<ImportRecordConnection>;
  readonly separator: Maybe<Scalars['String']['output']>;
  /** @deprecated Use progressStatus instead */
  readonly status: ImportStatus;
}


/**
 * An import is a set of records representing a desired state of memberships.
 * Import records are created only from the source data, but when run against
 * the system will mutate memberships so that they match the source data.
 * There are no transactional guarantees between records; a single record failure
 * does not affect subsequent mutations. Failed imports can be restarted.
 */
export interface ImportrecordsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecordFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyWithErrors?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<ImportStatus>;
  statuses?: InputMaybe<ReadonlyArray<ImportStatus>>;
}

export interface ImportChangesSummary {
  readonly __typename?: 'ImportChangesSummary';
  readonly newActivities: Scalars['Int']['output'];
  readonly newMemberships: Scalars['Int']['output'];
  readonly salaryChanges: Scalars['Int']['output'];
  readonly terminatedEmployments: Scalars['Int']['output'];
  readonly unchangedRows: Scalars['Int']['output'];
}

/**
 * A target import column. Used to understand a wide variety of tabular data
 * formats, rather than forcing our column names.
 */
export enum ImportColumn {
  ADDRESS_CAREOF = 'ADDRESS_CAREOF',
  ADDRESS_CITY = 'ADDRESS_CITY',
  ADDRESS_COUNTRY = 'ADDRESS_COUNTRY',
  ADDRESS_LINE1 = 'ADDRESS_LINE1',
  ADDRESS_LINE2 = 'ADDRESS_LINE2',
  ADDRESS_POSTAL_CODE = 'ADDRESS_POSTAL_CODE',
  /** Benefit package */
  BENEFIT_PACKAGE = 'BENEFIT_PACKAGE',
  /** Effective date of benefit package changes */
  BENEFIT_PACKAGE_EFFECTIVE_DATE = 'BENEFIT_PACKAGE_EFFECTIVE_DATE',
  BIRTH_DATE = 'BIRTH_DATE',
  /** Collective agreement code */
  COLLECTIVE_AGREEMENT = 'COLLECTIVE_AGREEMENT',
  /** Company car monthly value (in SEK) */
  COMPANY_CAR_MONTHLY_VALUE = 'COMPANY_CAR_MONTHLY_VALUE',
  /** Company registration number */
  COMPANY_REGISTRATION_NUMBER = 'COMPANY_REGISTRATION_NUMBER',
  /** Cost center */
  COST_CENTER = 'COST_CENTER',
  /** Effective date of changes to status, salary or benefit package. Used as a fallback if a more specific effective date is not specified. */
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  /** E-mail */
  EMAIL = 'EMAIL',
  /** Employee number */
  EMPLOYEE_NUMBER = 'EMPLOYEE_NUMBER',
  /** Employer contribution (% of monthly gross salary) */
  EMPLOYER_CONTRIBUTION_SHARE = 'EMPLOYER_CONTRIBUTION_SHARE',
  /** The type of employment, e.g. hourly */
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  /** Employment extent. This will set a extent(percentage) of the employment to support part-time employment. */
  EXTENT = 'EXTENT',
  /** Fitness contribution balance */
  FITNESS_CONTRIBUTION_BALANCE = 'FITNESS_CONTRIBUTION_BALANCE',
  GIVEN_NAME = 'GIVEN_NAME',
  /** Hours worked */
  HOURS_WORKED = 'HOURS_WORKED',
  /** Income tax table */
  INCOME_TAX_TABLE = 'INCOME_TAX_TABLE',
  /** Employee is certified fully capable for work for any changes */
  IS_FULLY_CAPABLE_FOR_WORK = 'IS_FULLY_CAPABLE_FOR_WORK',
  /** Employee is owner of company */
  IS_OWNER = 'IS_OWNER',
  LAST_NAME = 'LAST_NAME',
  /** Monthly salary (in SEK) */
  MONTHLY_SALARY = 'MONTHLY_SALARY',
  /** Effective date of monthly salary change */
  MONTHLY_SALARY_EFFECTIVE_DATE = 'MONTHLY_SALARY_EFFECTIVE_DATE',
  /** Organizational unit slug */
  ORGANIZATIONAL_UNIT = 'ORGANIZATIONAL_UNIT',
  /** Personal identity number */
  PERSONAL_IDENTITY_NUMBER = 'PERSONAL_IDENTITY_NUMBER',
  /** Phone number */
  PHONE = 'PHONE',
  SEX = 'SEX',
  /**
   * Employment start date. This will set the status to 'ACTIVE' as of this
   * date.
   */
  STARTED_AT = 'STARTED_AT',
  /**
   * Employment status. This can be specified in addition to any other changes
   * to signify e.g. parental leave or off-duty statuses.
   */
  STATUS = 'STATUS',
  /** Effective date of status change */
  STATUS_EFFECTIVE_DATE = 'STATUS_EFFECTIVE_DATE',
  /** Effective until of status change */
  STATUS_EFFECTIVE_UNTIL = 'STATUS_EFFECTIVE_UNTIL',
  /** Absence rate, this will set a percentage of the absence to indicate partials */
  STATUS_RATE = 'STATUS_RATE',
  /**
   * Employment termination date. This will set the status to 'TERMINATED' as
   * of this date.
   */
  TERMINATED_AT = 'TERMINATED_AT',
  /** Vacation days (advance) */
  VACATION_DAYS_ADVANCE = 'VACATION_DAYS_ADVANCE',
  /** Vacation days (paid) */
  VACATION_DAYS_PAID = 'VACATION_DAYS_PAID',
  /** Vacation days (per year) */
  VACATION_DAYS_PER_YEAR = 'VACATION_DAYS_PER_YEAR',
  /** Vacation days (saved) */
  VACATION_DAYS_SAVED = 'VACATION_DAYS_SAVED',
  /** Vacation days (unpaid) */
  VACATION_DAYS_UNPAID = 'VACATION_DAYS_UNPAID'
}

/**
 * Mapping from a source column (in your import data) to a known importable
 * column
 */
export interface ImportColumnMapping {
  readonly __typename?: 'ImportColumnMapping';
  /**
   * Mapped column identifier. If null, the source column does not map to
   * anything.
   */
  readonly column: Maybe<Scalars['String']['output']>;
  /** Column name as it appears in your source data */
  readonly source: Scalars['String']['output'];
}

export interface ImportConnection {
  readonly __typename?: 'ImportConnection';
  readonly edges: ReadonlyArray<ImportEdge>;
  readonly pageInfo: PageInfo;
}

export interface ImportEdge {
  readonly __typename?: 'ImportEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: Import;
}

export interface ImportErrorSummary {
  readonly __typename?: 'ImportErrorSummary';
  readonly errors: ReadonlyArray<ErrorSummary>;
  readonly failedRecords: Scalars['Int']['output'];
}

export interface ImportProgress {
  readonly __typename?: 'ImportProgress';
  readonly preview: Maybe<Progress>;
  readonly runner: Maybe<Progress>;
}

export interface ImportRecord {
  readonly __typename?: 'ImportRecord';
  readonly accountingItems: Maybe<ReadonlyArray<ImportRecordAccountingItem>>;
  /** @deprecated Will always be null, use generatedChanges or fields */
  readonly benefitPackage: Maybe<ImportRecordBenefitPackage>;
  /** @deprecated subset of causes */
  readonly cause: Maybe<Scalars['String']['output']>;
  readonly causes: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly collectiveAgreement: Maybe<CollectiveAgreement>;
  readonly companyCarMonthlyValue: Maybe<Scalars['Money']['output']>;
  readonly companyRegistrationNumber: Maybe<Scalars['String']['output']>;
  readonly costCenter: Maybe<Scalars['String']['output']>;
  readonly customTypeItems: Maybe<ReadonlyArray<Maybe<ImportRecordCustomTypeItem>>>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly employeeNumber: Maybe<Scalars['String']['output']>;
  readonly employerContributionShare: Maybe<Scalars['DecimalFraction']['output']>;
  readonly employment: Maybe<ImportRecordEmployment>;
  readonly fields: Maybe<ImportRecordFields>;
  readonly fitnessContributionBalance: Maybe<Scalars['Money']['output']>;
  readonly generatedChanges: Maybe<ImportRecordChange>;
  readonly id: Maybe<ImportRecordId>;
  readonly incomeTaxTable: Maybe<Scalars['Int']['output']>;
  /** The index of this record in the import file. For row based imports, this is the row in the source data (excluding header rows). */
  readonly index: Scalars['Int']['output'];
  /**
   * Deprecated field, no longer used
   * @deprecated No longer used
   */
  readonly isFullyCapableForWork: Maybe<Scalars['Boolean']['output']>;
  readonly isOwner: Maybe<Scalars['Boolean']['output']>;
  readonly organizationalUnit: Maybe<Scalars['String']['output']>;
  readonly personalIdentityNumber: Maybe<Scalars['PersonalIdentityNumber']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly recordStatus: RecordStatus;
  readonly salaries: ReadonlyArray<Maybe<ImportRecordSalary>>;
  /** @deprecated Use record status instead */
  readonly status: ImportStatus;
  /** This is employment activities. */
  readonly statuses: ReadonlyArray<Maybe<ImportRecordStatus>>;
  readonly vacationDaysAdvance: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysPaid: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysPerYear: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysSaved: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysUnpaid: Maybe<Scalars['Int']['output']>;
}

export interface ImportRecordAccountingItem {
  readonly __typename?: 'ImportRecordAccountingItem';
  readonly dimension: Scalars['String']['output'];
  readonly identifier: Maybe<Scalars['String']['output']>;
}

export interface ImportRecordBenefitPackage {
  readonly __typename?: 'ImportRecordBenefitPackage';
  readonly benefitPackage: BenefitPackage;
  readonly benefitPackageId: Scalars['ID']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
}

export interface ImportRecordBenefitPackageFields {
  readonly __typename?: 'ImportRecordBenefitPackageFields';
  readonly benefitPackageId: ImportRecordString;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
}

export interface ImportRecordBoolean {
  readonly __typename?: 'ImportRecordBoolean';
  readonly generatedChange: Maybe<Scalars['Boolean']['output']>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<Scalars['Boolean']['output']>;
}

export interface ImportRecordChange {
  readonly __typename?: 'ImportRecordChange';
  readonly changes: ImportRecordChangeFields;
  readonly id: ImportRecordChangeId;
}

export interface ImportRecordChangeFields {
  readonly __typename?: 'ImportRecordChangeFields';
  readonly accountingItems: Maybe<ReadonlyArray<ImportResultAccountingItem>>;
  readonly activity: Maybe<ImportResultEmploymentActivity>;
  readonly benefitPackage: Maybe<ImportResultBenefitPackage>;
  readonly collectiveAgreement: Maybe<Scalars['String']['output']>;
  readonly companyCarMonthlyValue: Maybe<Scalars['Money']['output']>;
  readonly costCenter: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly employerContributionShare: Maybe<Scalars['DecimalFraction']['output']>;
  readonly employment: Maybe<ImportResultEmployment>;
  readonly fitnessContributionBalance: Maybe<Scalars['Money']['output']>;
  readonly incomeTaxTable: Maybe<Scalars['Int']['output']>;
  readonly isOwner: Maybe<Scalars['Boolean']['output']>;
  readonly organizationalUnits: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly salary: Maybe<ImportResultSalary>;
  readonly vacationDaysAdvance: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysPaid: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysPerYear: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysSaved: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysUnpaid: Maybe<Scalars['Int']['output']>;
}

export interface ImportRecordChangeId {
  readonly __typename?: 'ImportRecordChangeId';
  readonly employeeNumber: Maybe<Scalars['String']['output']>;
  readonly membershipId: Maybe<Scalars['ID']['output']>;
  readonly personalIdentityNumber: Maybe<Scalars['PersonalIdentityNumber']['output']>;
  readonly userAccountId: Maybe<Scalars['ID']['output']>;
}

export interface ImportRecordCollectiveAgreement {
  readonly __typename?: 'ImportRecordCollectiveAgreement';
  readonly generatedChange: Maybe<CollectiveAgreement>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<CollectiveAgreement>;
}

export interface ImportRecordConnection {
  readonly __typename?: 'ImportRecordConnection';
  readonly edges: ReadonlyArray<ImportRecordEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface ImportRecordCustomItem {
  readonly __typename?: 'ImportRecordCustomItem';
  readonly from: Scalars['String']['output'];
  readonly item: ImportRecordDecimal;
  readonly type: CustomTypeType;
}

export interface ImportRecordCustomTypeItem {
  readonly __typename?: 'ImportRecordCustomTypeItem';
  readonly type: CustomTypeType;
  readonly typeId: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

export interface ImportRecordDate {
  readonly __typename?: 'ImportRecordDate';
  readonly generatedChange: Maybe<Scalars['Date']['output']>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<Scalars['Date']['output']>;
}

export interface ImportRecordDecimal {
  readonly __typename?: 'ImportRecordDecimal';
  readonly generatedChange: Maybe<Scalars['DecimalFraction']['output']>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<Scalars['DecimalFraction']['output']>;
}

export interface ImportRecordEdge {
  readonly __typename?: 'ImportRecordEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: ImportRecord;
}

export interface ImportRecordEmployment {
  readonly __typename?: 'ImportRecordEmployment';
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly effectiveUntil: Maybe<Scalars['Date']['output']>;
  readonly hoursWorked: Maybe<Scalars['DecimalFraction']['output']>;
  readonly rate: Maybe<Scalars['DecimalFraction']['output']>;
  readonly type: Maybe<Scalars['String']['output']>;
}

export interface ImportRecordEmploymentActivity {
  readonly __typename?: 'ImportRecordEmploymentActivity';
  readonly effectiveDate: ImportRecordDate;
  readonly effectiveUntil: ImportRecordDate;
  readonly rate: ImportRecordDecimal;
  readonly type: EmploymentActivityType;
}

export interface ImportRecordEmploymentFields {
  readonly __typename?: 'ImportRecordEmploymentFields';
  readonly effectiveDate: Maybe<ImportRecordDate>;
  readonly effectiveUntil: Maybe<ImportRecordDate>;
  readonly hoursWorked: Maybe<ImportRecordDecimal>;
  readonly rate: Maybe<ImportRecordDecimal>;
  readonly type: Maybe<ImportRecordString>;
}

export interface ImportRecordFieldAccountingItem {
  readonly __typename?: 'ImportRecordFieldAccountingItem';
  readonly dimension: Scalars['String']['output'];
  readonly identifier: ImportRecordString;
}

export interface ImportRecordFields {
  readonly __typename?: 'ImportRecordFields';
  readonly accountingItems: Maybe<ReadonlyArray<ImportRecordFieldAccountingItem>>;
  readonly activity: Maybe<ImportRecordEmploymentActivity>;
  readonly benefitPackage: Maybe<ImportRecordBenefitPackageFields>;
  readonly collectiveAgreement: Maybe<ImportRecordCollectiveAgreement>;
  readonly companyCarMonthlyValue: Maybe<ImportRecordMoney>;
  readonly costCenter: Maybe<ImportRecordString>;
  readonly customTypeItems: Maybe<ReadonlyArray<ImportRecordCustomItem>>;
  readonly email: Maybe<ImportRecordString>;
  readonly employerContributionShare: Maybe<ImportRecordDecimal>;
  readonly employment: Maybe<ImportRecordEmploymentFields>;
  readonly fitnessContributionBalance: Maybe<ImportRecordMoney>;
  readonly incomeTaxTable: Maybe<ImportRecordInt>;
  readonly isOwner: Maybe<ImportRecordBoolean>;
  readonly organizationalUnits: Maybe<ImportRecordOrgUnits>;
  readonly phone: Maybe<ImportRecordString>;
  readonly salary: Maybe<ImportRecordSalaryField>;
  readonly sex: Maybe<ImportRecordString>;
  readonly vacationDaysAdvance: Maybe<ImportRecordInt>;
  readonly vacationDaysPaid: Maybe<ImportRecordInt>;
  readonly vacationDaysPerYear: Maybe<ImportRecordInt>;
  readonly vacationDaysSaved: Maybe<ImportRecordInt>;
  readonly vacationDaysUnpaid: Maybe<ImportRecordInt>;
}

export interface ImportRecordId {
  readonly __typename?: 'ImportRecordId';
  readonly employeeNumber: Maybe<ImportRecordString>;
  readonly membershipId: Maybe<Scalars['ID']['output']>;
  readonly personalIdentityNumber: Maybe<Scalars['PersonalIdentityNumber']['output']>;
  readonly userAccountId: Maybe<Scalars['ID']['output']>;
}

export interface ImportRecordInt {
  readonly __typename?: 'ImportRecordInt';
  readonly generatedChange: Maybe<Scalars['Int']['output']>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<Scalars['Int']['output']>;
}

export interface ImportRecordMoney {
  readonly __typename?: 'ImportRecordMoney';
  readonly generatedChange: Maybe<Scalars['Money']['output']>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<Scalars['Money']['output']>;
}

export interface ImportRecordOrgUnits {
  readonly __typename?: 'ImportRecordOrgUnits';
  readonly generatedChange: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<ReadonlyArray<Scalars['String']['output']>>;
}

export interface ImportRecordSalary {
  readonly __typename?: 'ImportRecordSalary';
  readonly effectiveDate: Scalars['Date']['output'];
  readonly monthlySalary: Scalars['Money']['output'];
}

export interface ImportRecordSalaryField {
  readonly __typename?: 'ImportRecordSalaryField';
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly monthlySalary: ImportRecordMoney;
}

export interface ImportRecordStatus {
  readonly __typename?: 'ImportRecordStatus';
  readonly effectiveDate: Scalars['Date']['output'];
  readonly effectiveUntil: Maybe<Scalars['Date']['output']>;
  readonly rate: Scalars['DecimalFraction']['output'];
  readonly status: EmploymentActivityType;
}

export interface ImportRecordString {
  readonly __typename?: 'ImportRecordString';
  readonly generatedChange: Maybe<Scalars['String']['output']>;
  readonly isChanged: Scalars['Boolean']['output'];
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface ImportResultAccountingItem {
  readonly __typename?: 'ImportResultAccountingItem';
  readonly dimension: Scalars['String']['output'];
  readonly identifier: Maybe<Scalars['String']['output']>;
}

export interface ImportResultBenefitPackage {
  readonly __typename?: 'ImportResultBenefitPackage';
  readonly benefitPackage: Scalars['String']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
}

export interface ImportResultEmployment {
  readonly __typename?: 'ImportResultEmployment';
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly effectiveUntil: Maybe<Scalars['Date']['output']>;
  readonly hoursWorked: Maybe<Scalars['DecimalFraction']['output']>;
  readonly rate: Maybe<Scalars['DecimalFraction']['output']>;
  readonly type: Maybe<Scalars['String']['output']>;
}

export interface ImportResultEmploymentActivity {
  readonly __typename?: 'ImportResultEmploymentActivity';
  readonly effectiveDate: Scalars['Date']['output'];
  readonly effectiveUntil: Maybe<Scalars['Date']['output']>;
  readonly rate: Maybe<Scalars['DecimalFraction']['output']>;
  readonly type: EmploymentActivityType;
}

export interface ImportResultSalary {
  readonly __typename?: 'ImportResultSalary';
  readonly effectiveDate: Scalars['Date']['output'];
  readonly monthlySalary: Scalars['Money']['output'];
}

/** The status of an import */
export enum ImportStatus {
  /** DO NOT USE! This is unused and to be removed, but needs removal from frontend first */
  CANCELED = 'CANCELED',
  /** Import has completed without errors. All mutations have run to completion. */
  COMPLETE = 'COMPLETE',
  /**
   * Import has completed with errors. Some mutations may have been completed.
   * This state also applies to a failure in processing the source file.
   */
  FAILURE = 'FAILURE',
  /** Import has been processed and been determined to not contain any changes. */
  NO_CHANGES = 'NO_CHANGES',
  /**
   * Import is pending confirmation before starting. Records represent the
   * data as interpreted from the source data. When running, each record is
   * compared against the current state of that membership to determine what
   * to do (e.g. create membership, update membership, move benefit package).
   */
  PENDING = 'PENDING',
  /** Import is processing the input file */
  PROCESSING = 'PROCESSING',
  /** Import is working. Some records may have been completed. */
  WORKING = 'WORKING'
}

export interface IngestPayslipInput {
  readonly companyId: Scalars['ID']['input'];
  /** File to ingest */
  readonly fileId: Scalars['String']['input'];
}

export interface IngestPayslipPayload {
  readonly __typename?: 'IngestPayslipPayload';
  readonly success: Scalars['Boolean']['output'];
}

export interface Institute {
  readonly __typename?: 'Institute';
  readonly acceptsPoa: Scalars['Boolean']['output'];
  readonly bic: Maybe<Scalars['String']['output']>;
  readonly fundOfferings: Maybe<ReadonlyArray<FundOffering>>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
}

export interface Instrument {
  readonly id: Scalars['ID']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly price: Maybe<CurrencyValue>;
  readonly priceAsOf: Maybe<Scalars['Date']['output']>;
}

export enum InstrumentType {
  CURRENCY = 'CURRENCY',
  FUND = 'FUND',
  OTHER = 'OTHER',
  STOCK = 'STOCK'
}

export interface Insurance {
  readonly __typename?: 'Insurance';
  /** The date for which the insurance was last seen and updated */
  readonly asOf: Scalars['Date']['output'];
  readonly brokeredByAdvinans: Scalars['Boolean']['output'];
  readonly categoryBenefitPackageNumber: Maybe<Scalars['String']['output']>;
  /**
   * Any collective agreement pension plan (avtalsområde) associated with this insurance.
   * For a list of available pension plans, see avtalsområde under MIS2 in the "PKMP Mappning"-file available
   * [here](https://www.s4i.se/wp-content/uploads/2021/06/mappning-av-pkmp-2021-06-22.xlsx)
   */
  readonly collectivelyAgreedPensionPlan: Maybe<Scalars['String']['output']>;
  /**
   * Any collective agreement pension plan part (avdelning) associated with this insurance.
   * The only expected values are `AVD1` and `AVD2`. An insurance with `collectivelyAgreedPensionPlan` set to `ITP`
   * and `AVD2` represents what in daily speech would be called "ITP2".
   *
   * This field *MUST* only be set if `collectivelyAgreedPensionPlan` has a value as without that context, it makes
   * no sense and can safely be ignored.
   */
  readonly collectivelyAgreedPensionPlanPart: Maybe<Scalars['String']['output']>;
  readonly components: Maybe<ReadonlyArray<InsuranceComponent>>;
  readonly deletedAt: Maybe<Scalars['Date']['output']>;
  /** Description given to the insurance by the institute (if any) */
  readonly description: Maybe<Scalars['String']['output']>;
  /** The date from when the insurance is effective (if any). */
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly entitlement: Maybe<FlexEntitlement>;
  readonly financialLink: Maybe<FinancialLink>;
  /**
   * Globally unique ID as assigned by Advinans.
   *
   * The ID is created deterministically in accordance with the UUID RFC by
   * constructing a URI as follows: "<instituteId>/insurance/<insuranceNumber>"
   * from which a GUID is generated using UUIDv5.
   */
  readonly id: Scalars['ID']['output'];
  readonly ingestedAt: Maybe<Scalars['Date']['output']>;
  readonly institute: Institute;
  /**
   * Insurance number as assigned by the institute.
   * Unique per institute but not globally unique.
   * May not be the same as a component's insuranceNumber field which should be preferred when referring to the component.
   */
  readonly insuranceNumber: Scalars['String']['output'];
  readonly insuredParty: Maybe<Scalars['NaturalPersonIdentifier']['output']>;
  /** Available if a known user is the insured person */
  readonly insuredPerson: Maybe<UserAccount>;
  readonly invoicedUntil: Maybe<Scalars['Date']['output']>;
  /** Name given to the insurance by the institute (if any) */
  readonly name: Maybe<Scalars['String']['output']>;
  /** The next upcoming nominal premium (if known) */
  readonly nextPremiumPayment: Maybe<CurrencyValue>;
  readonly paidUntil: Maybe<Scalars['Date']['output']>;
  /** The pension transfer status (if applicable) */
  readonly pensionTransferStatus: Maybe<Scalars['String']['output']>;
  /** Latest Plus advice for this insurance */
  readonly plusAdvice: Maybe<PlusAdvice>;
  /**
   * Plus advice for this insurance
   * @deprecated Use plusAdvice instead
   */
  readonly plusAdviceHistory: Maybe<PlusAdviceConnection>;
  /** Latest Plus Suggestion for this insurance */
  readonly plusSuggestion: Maybe<PlusSuggestion>;
  readonly policyholder: Maybe<Scalars['IdentityNumber']['output']>;
  /** Available if a known company is the policyholder of the insurance */
  readonly policyholderCompany: Maybe<Company>;
  readonly policyholderName: Maybe<Scalars['String']['output']>;
  readonly premiumEndDate: Maybe<Scalars['Date']['output']>;
  readonly premiumHistory: Maybe<PremiumHistoryConnection>;
  readonly premiumPayerName: Maybe<Scalars['String']['output']>;
  /** Specifies the frequency/interval for which the premiums are to be paid. */
  readonly premiumPaymentFrequency: Maybe<Scalars['ISODuration']['output']>;
  readonly premiumProcedure: Maybe<InsurancePremiumProcedure>;
  /** Last known premium status of the insurance. */
  readonly premiumStatus: Maybe<InsurancePremiumStatus>;
  /** The last paid nominal premium (if known) */
  readonly previousPremiumPayment: Maybe<CurrencyValue>;
  /** Insurer-specific code for this insurance product */
  readonly productCode: Maybe<Scalars['String']['output']>;
  readonly salaryInformation: Maybe<ReadonlyArray<SalaryInformation>>;
  /**
   * If a "value" in nominal or basis amounts can be determined
   * for the sum of the insurances components it will be set.
   */
  readonly totalValue: Maybe<CurrencyValue>;
  /** Aggregated view of the premiums paid for the insurance */
  readonly transactions: Maybe<ComponentTransactionConnection>;
  /** Indicates if this insurance can be transferred */
  readonly transferable: Maybe<Scalars['Boolean']['output']>;
}


export interface InsuranceplusAdviceHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface InsurancepremiumHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface InsurancetransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

export enum InsuranceAmountUnit {
  E = 'E',
  FBB = 'FBB',
  IBB = 'IBB',
  PBB = 'PBB',
  XIB = 'XIB',
  XPB = 'XPB'
}

/**
 * An analysis of life insurance divided into categories. Each category describes
 * the situation for some type of insurance coverage, with suggested actions.
 * Values used for the analysis are also provided.
 */
export interface InsuranceAnalysis {
  readonly __typename?: 'InsuranceAnalysis';
  readonly categories: ReadonlyArray<InsuranceAnalysisCategory>;
  readonly childrenStatus: Maybe<ChildrenStatus>;
  readonly civilStatus: Maybe<CivilStatus>;
  readonly collectiveAgreement: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly dependentsStatus: Maybe<DependentsStatus>;
  /** Document associated with the analysis */
  readonly document: Maybe<Document>;
  readonly hasDanicaPension: Maybe<Scalars['Boolean']['output']>;
  /** EuroAccident accident insurance */
  readonly hasEuroAccidentInsurance: Maybe<Scalars['Boolean']['output']>;
  /** Accident insurance that is not provided through EuroAccident or collectively agreed */
  readonly hasPrivateAccidentInsurance: Maybe<Scalars['Boolean']['output']>;
  readonly hasPrivateLifeInsurance: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  /**
   * Indicates whether there is a new analysis available.
   *
   * Call the mutation `updateInsuranceAnalysis` to get a new up-to-date analysis.
   */
  readonly isOutdated: Scalars['Boolean']['output'];
  readonly liabilities: Maybe<Scalars['Money']['output']>;
  /** EuroAccident TGL insurance amount */
  readonly lifeInsuranceCoverage: Maybe<Scalars['Money']['output']>;
  readonly lifeInsuranceShortfall: Maybe<Scalars['Money']['output']>;
  /** (Yearly income after tax * 3) + liabilities */
  readonly recommendedLifeInsuranceCoverage: Maybe<Scalars['Money']['output']>;
}


/**
 * An analysis of life insurance divided into categories. Each category describes
 * the situation for some type of insurance coverage, with suggested actions.
 * Values used for the analysis are also provided.
 */
export interface InsuranceAnalysiscategoriesArgs {
  type?: InputMaybe<Scalars['ID']['input']>;
}

/**
 * An insurance analysis action, suggesting a course of action.
 * The status of an action is tracked.
 */
export interface InsuranceAnalysisAction {
  readonly __typename?: 'InsuranceAnalysisAction';
  readonly body: Scalars['String']['output'];
  readonly name: Scalars['ID']['output'];
  readonly status: InsuranceAnalysisActionStatus;
  readonly title: Scalars['String']['output'];
  readonly type: Maybe<InsuranceAnalysisActionType>;
}

export enum InsuranceAnalysisActionStatus {
  /** The user has marked the action as complete */
  COMPLETE = 'COMPLETE',
  /** The user has marked the action as ignored */
  INACTIVE = 'INACTIVE',
  /** The user has not interacted with this action */
  PENDING = 'PENDING'
}

export enum InsuranceAnalysisActionType {
  /** The user should add a private insurance */
  ADD_PRIVATE = 'ADD_PRIVATE',
  /** The user should make sure the insurance is configured correctly */
  DOUBLE_CHECK = 'DOUBLE_CHECK',
  /** Sufficient coverage and/or no action required */
  NO_ACTION = 'NO_ACTION'
}

/**
 * An analysis of a type of insurance coverage, summarizing the situation and
 * providing suggested actions.
 */
export interface InsuranceAnalysisCategory {
  readonly __typename?: 'InsuranceAnalysisCategory';
  readonly actions: ReadonlyArray<InsuranceAnalysisAction>;
  /**
   * Opaque identifier for the type of insurance coverage under analysis.
   * Types can be added and removed at any point in time.
   */
  readonly name: Scalars['ID']['output'];
  readonly summaries: ReadonlyArray<InsuranceAnalysisSummary>;
}

export interface InsuranceAnalysisEdge {
  readonly __typename?: 'InsuranceAnalysisEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: InsuranceAnalysis;
}

export interface InsuranceAnalysisHistoryConnection {
  readonly __typename?: 'InsuranceAnalysisHistoryConnection';
  readonly edges: ReadonlyArray<InsuranceAnalysisEdge>;
}

export interface InsuranceAnalysisInput {
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface InsuranceAnalysisSummary {
  readonly __typename?: 'InsuranceAnalysisSummary';
  readonly body: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly title: Maybe<Scalars['String']['output']>;
  readonly type: Maybe<InsuranceAnalysisSummaryType>;
}

export enum InsuranceAnalysisSummaryType {
  /** The insurance covers you around the clock */
  AROUND_THE_CLOCK = 'AROUND_THE_CLOCK',
  /** The insurance covers you while at work and travelig to/from work */
  AT_WORK = 'AT_WORK',
  /** The insurance is paid for by your employer */
  EMPLOYER_COVERAGE = 'EMPLOYER_COVERAGE',
  /** No insurance of this type found */
  NO_INSURANCE = 'NO_INSURANCE',
  /** The insurance is provided by yourself */
  PRIVATE_COVERAGE = 'PRIVATE_COVERAGE',
  /** The insurance is provided by the state */
  STATE_COVERAGE = 'STATE_COVERAGE'
}

export interface InsuranceComponent {
  /** Description given to the component by the institute (if any) */
  readonly description: Maybe<Scalars['String']['output']>;
  /**
   * When `typeCode` is not enough this field will be set. See column "DetaljeradMomenttypkod" in
   * [PKMP Mapping](https://www.s4i.se/wp-content/uploads/2021/06/mappning-av-pkmp-2021-06-22.xlsx) and find
   * additional documentation in
   * [MIS 2 Detaljbeskrivning](https://www.s4i.se/wp-content/uploads/2021/06/mis-2.0-detaljbeskrivning.pdf).
   */
  readonly detailedTypeCode: Maybe<Scalars['String']['output']>;
  /** The date from when the component is effective (if any). */
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  /** Any ID as given by the external source to the component (if any). */
  readonly externalId: Maybe<Scalars['String']['output']>;
  /**
   * A unique identifier as assigned by Advinans. Not to be confused with
   * the non-unique identifier \`externalId\`.
   *
   * The ID strives to be consistent over time, however that's not guaranteed.
   */
  readonly id: Scalars['ID']['output'];
  /** Parent insurance. */
  readonly insurance: Insurance;
  /** Insurance number for this component. To be preferred over insurance.insuranceNumber as they may not be the same */
  readonly insuranceNumber: Scalars['String']['output'];
  /** Name given to the component by the institute (if any) */
  readonly name: Maybe<Scalars['String']['output']>;
  /** The type of event that the insurance component covers. */
  readonly onEvent: InsuranceComponentEvent;
  readonly payout: Maybe<ReadonlyArray<InsuranceComponentPayout>>;
  readonly premium: Maybe<CurrencyValue>;
  readonly status: Maybe<InsuranceComponentStatus>;
  /**
   * Tax category is a reflection of \`skattekategori\` from MIS1, a function of
   * \`skattekategori\` and \`teckningstyp\` from MIS2 and best effort from other
   * gathered data such as scraped information.
   *
   * If it cannot be determined it is set to \`null\`.
   */
  readonly taxCategory: Maybe<InsuranceComponentTaxCategory>;
  /** Premiums paid for the component */
  readonly transactions: ComponentTransactionConnection;
  /**
   * Similar to "momenttypkod" found in MIS2 but with two additional values: "PREMIE" and "INKOMST"
   * to represent insurance components that are part of the "Allmänpension". See column "Momenttypkod" in
   * [PKMP Mapping](https://www.s4i.se/wp-content/uploads/2021/06/mappning-av-pkmp-2021-06-22.xlsx) and find
   * additional documentation in
   * [MIS 2 Detaljbeskrivning](https://www.s4i.se/wp-content/uploads/2021/06/mis-2.0-detaljbeskrivning.pdf).
   */
  readonly typeCode: Maybe<Scalars['String']['output']>;
}


export interface InsuranceComponenttransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

/** A component describing what will happen in the case that the insured party is in an accident. */
export interface InsuranceComponentAccident extends InsuranceComponent {
  readonly __typename?: 'InsuranceComponentAccident';
  /** Terms of accident coverage (only at work or around the clock) */
  readonly coverage: Maybe<AccidentCoverage>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly detailedTypeCode: Maybe<Scalars['String']['output']>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly externalId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly insurance: Insurance;
  readonly insuranceNumber: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  /** \`onEvent\` is always \`ACCIDENT\` for an accident component */
  readonly onEvent: InsuranceComponentEvent;
  readonly payout: Maybe<ReadonlyArray<InsuranceComponentPayout>>;
  readonly premium: Maybe<CurrencyValue>;
  readonly status: Maybe<InsuranceComponentStatus>;
  readonly taxCategory: Maybe<InsuranceComponentTaxCategory>;
  /** Premiums paid for the component */
  readonly transactions: ComponentTransactionConnection;
  readonly typeCode: Maybe<Scalars['String']['output']>;
}


/** A component describing what will happen in the case that the insured party is in an accident. */
export interface InsuranceComponentAccidenttransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

export interface InsuranceComponentCapital {
  readonly __typename?: 'InsuranceComponentCapital';
  /** Latest available report for the Insurance Capital */
  readonly capitalReport: InsuranceComponentCapitalReport;
  readonly component: InsuranceComponentRetirement;
  readonly externalId: Maybe<Scalars['String']['output']>;
  readonly fees: Maybe<InsuranceComponentCapitalFees>;
  readonly id: Scalars['ID']['output'];
  readonly managementType: InsuranceComponentManagementType;
}

export interface InsuranceComponentCapitalFees {
  readonly __typename?: 'InsuranceComponentCapitalFees';
  /** Fixed fee in SEK */
  readonly annualFee: Maybe<Scalars['Int']['output']>;
  /** Annual fee on the capital. AKA 'service fee' and 'försäkringsavgift' */
  readonly capitalFee: Maybe<Scalars['Int']['output']>;
  /** Fee for managing the capital (eg choosing funds) */
  readonly capitalManagementFee: Maybe<Scalars['Int']['output']>;
  /** Weighted sum of ongoing charge and transaction fee per fund for all funds in the capital */
  readonly fundsFee: Maybe<Scalars['Int']['output']>;
  /** Discounted funds fee */
  readonly fundsFeeDiscounted: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use capitalManagementFee */
  readonly managementFee: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use capitalFee */
  readonly serviceFee: Maybe<Scalars['Int']['output']>;
}

export interface InsuranceComponentCapitalPosition {
  readonly __typename?: 'InsuranceComponentCapitalPosition';
  readonly instrument: Instrument;
  readonly marketValue: Maybe<CurrencyValue>;
  readonly offering: Maybe<FundOffering>;
  readonly quantity: Maybe<Scalars['DecimalFraction']['output']>;
}

export interface InsuranceComponentCapitalReport {
  readonly capital: InsuranceComponentCapital;
  /** Retirement component performance compared to benchmark */
  readonly performance: Maybe<ReadonlyArray<ComponentPerformanceItem>>;
  readonly performanceStartDate: Maybe<Scalars['Date']['output']>;
  /**
   * Any positions for the portfolio. Available cash is considered a position and will
   * be reported as such.
   */
  readonly positions: ReadonlyArray<InsuranceComponentCapitalPosition>;
  /** Percentual total returns since creation represented as a decimal with a precision of 2, with values between -1 and 1. */
  readonly returns: Maybe<Scalars['DecimalFraction']['output']>;
  /** Monetary value of total returns since creation (if known) */
  readonly returnsEarned: Maybe<CurrencyValue>;
  readonly value: Maybe<CurrencyValue>;
  readonly valueAsOf: Maybe<Scalars['Date']['output']>;
}


export interface InsuranceComponentCapitalReportperformanceArgs {
  benchmarkId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}

export interface InsuranceComponentCapitalReportOther extends InsuranceComponentCapitalReport {
  readonly __typename?: 'InsuranceComponentCapitalReportOther';
  readonly capital: InsuranceComponentCapital;
  readonly performance: Maybe<ReadonlyArray<ComponentPerformanceItem>>;
  readonly performanceStartDate: Maybe<Scalars['Date']['output']>;
  /**
   * Any positions for the portfolio. Available cash is considered a position and will
   * be reported as such.
   */
  readonly positions: ReadonlyArray<InsuranceComponentCapitalPosition>;
  readonly returns: Maybe<Scalars['DecimalFraction']['output']>;
  readonly returnsEarned: Maybe<CurrencyValue>;
  readonly value: Maybe<CurrencyValue>;
  readonly valueAsOf: Maybe<Scalars['Date']['output']>;
}


export interface InsuranceComponentCapitalReportOtherperformanceArgs {
  benchmarkId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}

export interface InsuranceComponentCapitalReportTrad extends InsuranceComponentCapitalReport {
  readonly __typename?: 'InsuranceComponentCapitalReportTrad';
  readonly capital: InsuranceComponentCapital;
  readonly performance: Maybe<ReadonlyArray<ComponentPerformanceItem>>;
  readonly performanceStartDate: Maybe<Scalars['Date']['output']>;
  /**
   * Any positions for the portfolio. Available cash is considered a position and will
   * be reported as such.
   */
  readonly positions: ReadonlyArray<InsuranceComponentCapitalPosition>;
  readonly returns: Maybe<Scalars['DecimalFraction']['output']>;
  readonly returnsEarned: Maybe<CurrencyValue>;
  /** The net value of future benefits (sv: tekniskt återköpsvärde) */
  readonly technicalSurrenderValue: Maybe<CurrencyValue>;
  readonly technicalSurrenderValueAsOf: Maybe<Scalars['Date']['output']>;
  /** The capital is valued at the retrospective reserve (sv: retrospektivreserv) value. */
  readonly value: Maybe<CurrencyValue>;
  readonly valueAsOf: Maybe<Scalars['Date']['output']>;
}


export interface InsuranceComponentCapitalReportTradperformanceArgs {
  benchmarkId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}

export interface InsuranceComponentCapitalReportUnit extends InsuranceComponentCapitalReport {
  readonly __typename?: 'InsuranceComponentCapitalReportUnit';
  readonly capital: InsuranceComponentCapital;
  readonly investmentPlan: Maybe<ReadonlyArray<InsuranceInvestmentPlanAllocation>>;
  readonly performance: Maybe<ReadonlyArray<ComponentPerformanceItem>>;
  readonly performanceStartDate: Maybe<Scalars['Date']['output']>;
  /**
   * Any positions for the portfolio. Available cash is considered a position and will
   * be reported as such.
   */
  readonly positions: ReadonlyArray<InsuranceComponentCapitalPosition>;
  readonly prognosis: Maybe<InvestedCapitalPrognosis>;
  /** @deprecated Use 'prognosis' instead */
  readonly projectedMonthlyWithdrawals: Maybe<Scalars['Money']['output']>;
  /** @deprecated Use 'prognosis' instead */
  readonly projectedValue: Maybe<Scalars['Money']['output']>;
  /** @deprecated Use 'prognosis' instead */
  readonly projection: Maybe<ReadonlyArray<ProjectionItemCapitalReportUnit>>;
  readonly returns: Maybe<Scalars['DecimalFraction']['output']>;
  readonly returnsEarned: Maybe<CurrencyValue>;
  readonly value: Maybe<CurrencyValue>;
  readonly valueAsOf: Maybe<Scalars['Date']['output']>;
}


export interface InsuranceComponentCapitalReportUnitperformanceArgs {
  benchmarkId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface InsuranceComponentCapitalReportUnitprognosisArgs {
  horizon?: InputMaybe<Scalars['Date']['input']>;
  managementFee?: InputMaybe<Scalars['DecimalFraction']['input']>;
}

export interface InsuranceComponentCapitalTransferrableCapital {
  readonly __typename?: 'InsuranceComponentCapitalTransferrableCapital';
  readonly amount: CurrencyValue;
  readonly asOf: Maybe<Scalars['Date']['output']>;
  /** Can only be transferred to an insurance with traditional capital management */
  readonly traditionalOnly: Maybe<Scalars['Boolean']['output']>;
}

/** A component describing what will happen in the case that the insured party passes away. */
export interface InsuranceComponentDeath extends InsuranceComponent {
  readonly __typename?: 'InsuranceComponentDeath';
  /**
   * Primary beneficiaries listed in descending order. Note that sometimes
   * we are unable to present the primary beneficiary using this field but instead
   * have a human readable version in the '\`beneficaryText\`-field.
   */
  readonly beneficiaries: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly beneficiariesText: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly detailedTypeCode: Maybe<Scalars['String']['output']>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly externalId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly insurance: Insurance;
  readonly insuranceNumber: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  /** \`onEvent\` is always \`DEATH\` for a death component */
  readonly onEvent: InsuranceComponentEvent;
  readonly payout: Maybe<ReadonlyArray<InsuranceComponentPayout>>;
  readonly premium: Maybe<CurrencyValue>;
  readonly repaymentProtection: Maybe<RepaymentProtection>;
  readonly status: Maybe<InsuranceComponentStatus>;
  readonly taxCategory: Maybe<InsuranceComponentTaxCategory>;
  /** Premiums paid for the component */
  readonly transactions: ComponentTransactionConnection;
  readonly typeCode: Maybe<Scalars['String']['output']>;
}


/** A component describing what will happen in the case that the insured party passes away. */
export interface InsuranceComponentDeathtransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

export enum InsuranceComponentEvent {
  /** In the event that the insured individual is in an accident */
  ACCIDENT = 'ACCIDENT',
  /** In the event that the insured individual passes away */
  DEATH = 'DEATH',
  /** In the event that the insured individual is diagnosed */
  DIAGNOSIS = 'DIAGNOSIS',
  /** In the event that the insured individual needs health care */
  HEALTH_CARE = 'HEALTH_CARE',
  /** In the event that the insured individual lives past a certain threshold */
  LIFE = 'LIFE',
  /** In the event that the insured individual becomes sick */
  SICKNESS = 'SICKNESS'
}

/** A component describing what will happen in the case that the insured party receives health care. */
export interface InsuranceComponentHealthCare extends InsuranceComponent {
  readonly __typename?: 'InsuranceComponentHealthCare';
  readonly copayment: Maybe<CurrencyValue>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly detailedTypeCode: Maybe<Scalars['String']['output']>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly existingConditionExclusionPeriod: Maybe<Scalars['String']['output']>;
  readonly externalId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly insurance: Insurance;
  readonly insuranceNumber: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  /** \`onEvent\` is always \`HEALTH_CARE\` for a health care component */
  readonly onEvent: InsuranceComponentEvent;
  readonly payout: Maybe<ReadonlyArray<InsuranceComponentPayout>>;
  readonly premium: Maybe<CurrencyValue>;
  readonly status: Maybe<InsuranceComponentStatus>;
  readonly taxCategory: Maybe<InsuranceComponentTaxCategory>;
  /** Premiums paid for the component */
  readonly transactions: ComponentTransactionConnection;
  readonly typeCode: Maybe<Scalars['String']['output']>;
}


/** A component describing what will happen in the case that the insured party receives health care. */
export interface InsuranceComponentHealthCaretransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

export enum InsuranceComponentManagementType {
  /** (sv: Depåförvaltning) */
  DEPOSITORY = 'DEPOSITORY',
  /** (sv: Fondförvaltning) */
  FUND = 'FUND',
  /** Used for income pension (sv: Annan förvaltning) */
  OTHER = 'OTHER',
  /** (sv: Traditionell förvaltning) */
  TRADITIONAL = 'TRADITIONAL'
}

/** Represents any payout that will be made in case of the parent component's \`onEvent\`-event happening. */
export interface InsuranceComponentPayout {
  readonly __typename?: 'InsuranceComponentPayout';
  /**
   * The amount that will be paid out for each frequency.
   *
   * *Note* that payouts denoted in base amounts e.g. "prisbasbelopp" and "inkomstbasbelopp"
   * will be denoted with currency "XPB" (PBB) and "XIB" (IBB) with value representing the base amount e.g;
   * \`{ "value": "7.5", "currency": "XIB" }\` represents 7.5 IBB.
   */
  readonly amount: Maybe<CurrencyValue>;
  /**
   * A floating qualifying period (sv: R-karens) indicates that the period is not absolute and thus has
   * no defined absolute period.
   *
   * A payout *cannot* both have a qualifying period and a floating qualifying period.
   */
  readonly floatingQualifyingPeriod: Maybe<Scalars['Boolean']['output']>;
  /** The frequency of payouts. */
  readonly frequency: Maybe<Scalars['ISODuration']['output']>;
  /** Name given to the specific payout by the institute, e.g., \`Livsfallsbelopp\`. */
  readonly name: Maybe<Scalars['String']['output']>;
  readonly payoutEvent: Maybe<PayoutEvent>;
  /**
   * Period of time (if any) before payout(s) are started.
   *
   * A \`qualifyingPeriod\` of \`P90D\` would signify a 90 day period before the payout term begins. For
   * payouts with no associated qualifying period the value is set to \`P0D\`.
   *
   * A \`null\` value indicates the abscence of a qualifying period and typically indicates
   * that it is "floating", see the \`floatingQualifyingPeriod\`-field.
   */
  readonly qualifyingPeriod: Maybe<Scalars['ISODuration']['output']>;
  /**
   * The total duration of payouts. A term of \`null\` represents an undeterminate term, i.e., lifelong,
   * unless \`termLastsUntilFloatingQualifyingPeriod\` is set to true.
   */
  readonly term: Maybe<Scalars['ISODuration']['output']>;
  /**
   * The term lasts until the "floating qualifying period" (sv: R-karens) begins.
   *
   * A payout *cannot* last until a floating qualifying period and have an associated term, thus if
   * \`termLastsUntilFloatingQualifyingPeriod\` is \`true\`, term cannot be set.
   */
  readonly termLastsUntilFloatingQualifyingPeriod: Maybe<Scalars['Boolean']['output']>;
  /**
   * Fixed date (if any) for when the term begins. Most often seen in retirement
   * components when the date of retirement of the insured party is known.
   */
  readonly zt: Maybe<Scalars['Date']['output']>;
}

/** A component that pays out when the insured party lives past a certain age. */
export interface InsuranceComponentRetirement extends InsuranceComponent {
  readonly __typename?: 'InsuranceComponentRetirement';
  readonly capital: Maybe<InsuranceComponentCapital>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly detailedTypeCode: Maybe<Scalars['String']['output']>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly externalId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly insurance: Insurance;
  readonly insuranceNumber: Scalars['String']['output'];
  /** (sv: Kapitalpension, hybrid mellan kapitalförsäkring och pensionsförsäkring) */
  readonly isEndowmentPension: Maybe<Scalars['Boolean']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  /** \`onEvent\` is always \`LIFE\` for a retirement component. */
  readonly onEvent: InsuranceComponentEvent;
  readonly payout: Maybe<ReadonlyArray<InsuranceComponentPayout>>;
  readonly premium: Maybe<CurrencyValue>;
  readonly premiumOrBenefitDefined: Maybe<PremiumOrBenefitDefined>;
  readonly status: Maybe<InsuranceComponentStatus>;
  readonly taxCategory: Maybe<InsuranceComponentTaxCategory>;
  /** Premiums paid for the component */
  readonly transactions: ComponentTransactionConnection;
  readonly typeCode: Maybe<Scalars['String']['output']>;
}


/** A component that pays out when the insured party lives past a certain age. */
export interface InsuranceComponentRetirementtransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

/** A component describing what will happen in the case that the insured party goes on sick leave. */
export interface InsuranceComponentSickness extends InsuranceComponent {
  readonly __typename?: 'InsuranceComponentSickness';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly detailedTypeCode: Maybe<Scalars['String']['output']>;
  readonly effectiveDate: Maybe<Scalars['Date']['output']>;
  readonly externalId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly insurance: Insurance;
  readonly insuranceNumber: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  /** \`onEvent\` is always \`SICKNESS\` for a sickness component */
  readonly onEvent: InsuranceComponentEvent;
  readonly payout: Maybe<ReadonlyArray<InsuranceComponentPayout>>;
  readonly premium: Maybe<CurrencyValue>;
  /** Either ITP or MAX for EA insurances */
  readonly sicknessLevel: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<InsuranceComponentStatus>;
  readonly taxCategory: Maybe<InsuranceComponentTaxCategory>;
  /** Premiums paid for the component */
  readonly transactions: ComponentTransactionConnection;
  readonly typeCode: Maybe<Scalars['String']['output']>;
}


/** A component describing what will happen in the case that the insured party goes on sick leave. */
export interface InsuranceComponentSicknesstransactionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  until?: InputMaybe<Scalars['Date']['input']>;
}

export enum InsuranceComponentStatus {
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  PAID_OUT = 'PAID_OUT',
  PAID_UP = 'PAID_UP',
  PAYING_OUT = 'PAYING_OUT'
}

export enum InsuranceComponentTaxCategory {
  ENDOWMENT = 'ENDOWMENT',
  OCCUPATIONAL_PENSION = 'OCCUPATIONAL_PENSION',
  PERSONAL_PENSION = 'PERSONAL_PENSION'
}

export interface InsuranceConnection {
  readonly __typename?: 'InsuranceConnection';
  readonly edges: ReadonlyArray<InsuranceEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface InsuranceEdge {
  readonly __typename?: 'InsuranceEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: Insurance;
}

export interface InsuranceInvestmentPlanAllocation {
  readonly __typename?: 'InsuranceInvestmentPlanAllocation';
  readonly allocations: ReadonlyArray<InsuranceInvestmentPlanAllocationItem>;
  readonly date: Scalars['Date']['output'];
  readonly fees: InsuranceComponentCapitalFees;
}

export interface InsuranceInvestmentPlanAllocationItem {
  readonly __typename?: 'InsuranceInvestmentPlanAllocationItem';
  readonly fund: Fund;
  readonly offering: Maybe<FundOffering>;
  readonly share: Scalars['DecimalFraction']['output'];
}

export enum InsuranceManagementType {
  /** Depository-linked management (sv: depåförvaltning) */
  DEPOSITORY = 'DEPOSITORY',
  /** Traditional management (sv: traditionell förvaltning) */
  TRADITIONAL = 'TRADITIONAL',
  /** Unit-linked management (sv: fondförvaltning) */
  UNIT = 'UNIT'
}

export enum InsurancePremiumProcedure {
  FIXED = 'FIXED',
  PLAN = 'PLAN',
  SCHEDULED = 'SCHEDULED',
  SELF_DIRECTED = 'SELF_DIRECTED',
  VOLUNTARY = 'VOLUNTARY'
}

export enum InsurancePremiumStatus {
  /** Premiums are actively being paid to fund the insurance */
  ACTIVE = 'ACTIVE',
  /** A premium waiver is in effect to pay premiums to fund the insurance */
  ACTIVE_WAIVER = 'ACTIVE_WAIVER',
  /** No more premiums can be paid to fund the insurance */
  COMPLETE = 'COMPLETE',
  /** No premiums are being paid currently but may resume */
  INACTIVE = 'INACTIVE',
  /**
   * Premiums may be paid to fund the insurance, however, it's unknown if that's recurring.
   *
   * This status can be used for pension insurances, instead of `ACTIVE`, if the insurance
   * company does not know what the next premium will be. This is the case with many collectively
   * agreed pensions, where each premium payment is computed by a separate entity (e.g. Collectum).
   * It's also applicable to insurances with no set recurring premium schedule (e.g. single premium insurances).
   */
  OPEN = 'OPEN',
  /** Pending activation before premiums start being paid */
  PENDING_ACTIVATION = 'PENDING_ACTIVATION'
}

export enum InsuranceTaxCategory {
  /** Endowment insurance */
  K = 'K',
  /** Private pension insurance */
  P = 'P',
  /** Occupational pension insurance */
  T = 'T'
}

export interface IntRangeFilter {
  readonly from?: InputMaybe<Scalars['Int']['input']>;
  readonly to?: InputMaybe<Scalars['Int']['input']>;
}

export interface InternalPost {
  readonly __typename?: 'InternalPost';
  readonly attachments: ReadonlyArray<InternalPostAttachment>;
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly benefitPackages: Maybe<BenefitPackageConnection>;
  readonly body: Scalars['String']['output'];
  readonly companyId: Scalars['ID']['output'];
  /** @deprecated No longer supported */
  readonly costCenterIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly description: Scalars['String']['output'];
  readonly heroImageUrl: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly imageUrl: Maybe<Scalars['String']['output']>;
  readonly important: Scalars['Boolean']['output'];
  readonly orgUnitIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly organizationalUnits: Maybe<OrganizationalUnitConnection>;
  /** @deprecated No longer supported */
  readonly publicationDate: Scalars['DateTime']['output'];
  readonly publishedAt: Scalars['DateTime']['output'];
  readonly status: InternalPostStatus;
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
}


export interface InternalPostbenefitPackagesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface InternalPostheroImageUrlArgs {
  crop?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}


export interface InternalPostimageUrlArgs {
  aspect?: InputMaybe<AspectRatio>;
  crop?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}


export interface InternalPostorganizationalUnitsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface InternalPostAttachment {
  readonly __typename?: 'InternalPostAttachment';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly size: Maybe<Scalars['Int']['output']>;
  readonly type: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
}

export interface InternalPostConnection {
  readonly __typename?: 'InternalPostConnection';
  readonly edges: ReadonlyArray<InternalPostEdge>;
  readonly pageInfo: PageInfo;
}

export interface InternalPostEdge {
  readonly __typename?: 'InternalPostEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: InternalPost;
}

export enum InternalPostStatus {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export interface InvestedCapitalPrognosis {
  readonly __typename?: 'InvestedCapitalPrognosis';
  readonly projectedMonthlyWithdrawals: Maybe<Scalars['Money']['output']>;
  readonly projectedValue: Maybe<Scalars['Money']['output']>;
  readonly projection: Maybe<ReadonlyArray<ProjectionItem>>;
}

export interface InvestmentScenarioInput {
  readonly monthlySavings?: InputMaybe<Scalars['Money']['input']>;
  readonly singleDeposit?: InputMaybe<Scalars['Money']['input']>;
  readonly targetHorizon: Scalars['Int']['input'];
  readonly targetMonthlyWithdrawals?: InputMaybe<Scalars['Money']['input']>;
  readonly targetValue?: InputMaybe<Scalars['Money']['input']>;
  readonly withdrawalPeriod?: InputMaybe<Scalars['Int']['input']>;
}

export interface InvestmentScenarioPayload {
  readonly __typename?: 'InvestmentScenarioPayload';
  readonly investmentPlanAllocation: Maybe<InsuranceInvestmentPlanAllocation>;
  readonly projectedMonthlyWithdrawals: Maybe<Scalars['Money']['output']>;
  readonly projectedValue: Maybe<Scalars['Money']['output']>;
  readonly projection: Maybe<ReadonlyArray<ProjectionItem>>;
  readonly projectionFees: Maybe<ProjectionFees>;
  readonly retirementAge: Scalars['Int']['output'];
  readonly suggestedMonthlySavings: Maybe<Scalars['Money']['output']>;
  readonly suggestedOneTimeDeposit: Maybe<Scalars['Money']['output']>;
  readonly withdrawalPeriod: Maybe<Scalars['Int']['output']>;
}


export interface InvestmentScenarioPayloadprojectedMonthlyWithdrawalsArgs {
  monthlySavings?: InputMaybe<Scalars['Money']['input']>;
  singleDeposit?: InputMaybe<Scalars['Money']['input']>;
}


export interface InvestmentScenarioPayloadprojectedValueArgs {
  monthlySavings?: InputMaybe<Scalars['Money']['input']>;
  singleDeposit?: InputMaybe<Scalars['Money']['input']>;
}


export interface InvestmentScenarioPayloadprojectionArgs {
  monthlySavings?: InputMaybe<Scalars['Money']['input']>;
  singleDeposit?: InputMaybe<Scalars['Money']['input']>;
}

export interface Invoice {
  readonly __typename?: 'Invoice';
  readonly companyId: Scalars['ID']['output'];
  readonly dueDate: Maybe<Scalars['Date']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly invoiceNumber: Maybe<Scalars['String']['output']>;
  readonly issueDate: Scalars['Date']['output'];
  readonly payableAmount: Maybe<Scalars['Money']['output']>;
  readonly status: InvoiceStatus;
  /** @deprecated Use supplierType instead */
  readonly supplier: Scalars['String']['output'];
  readonly supplierType: InvoiceSupplier;
  readonly url: Maybe<Scalars['String']['output']>;
}


export interface InvoiceurlArgs {
  format?: InputMaybe<InvoiceFormat>;
}

export interface InvoiceConnection {
  readonly __typename?: 'InvoiceConnection';
  readonly edges: ReadonlyArray<InvoiceEdge>;
  readonly pageInfo: PageInfo;
}

export interface InvoiceEdge {
  readonly __typename?: 'InvoiceEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Invoice;
}

export interface InvoiceFilter {
  readonly dueDate?: InputMaybe<DateFilter>;
  readonly invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  readonly issueDate?: InputMaybe<DateFilter>;
  /** Optional lower and upper limit for payable amount */
  readonly payableAmount?: InputMaybe<IntRangeFilter>;
  readonly status?: InputMaybe<ReadonlyArray<InvoiceStatus>>;
  readonly supplier?: InputMaybe<ReadonlyArray<InvoiceSupplier>>;
}

export enum InvoiceFormat {
  CSV = 'CSV',
  HTML = 'HTML',
  PDF = 'PDF',
  PEPPOL = 'PEPPOL',
  SIE4 = 'SIE4',
  SUMMARYPDF = 'SUMMARYPDF',
  XLSX = 'XLSX'
}

export interface InvoiceLine {
  readonly __typename?: 'InvoiceLine';
  readonly accountingCost: Maybe<Scalars['String']['output']>;
  readonly benefitType: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly invoice: Maybe<Invoice>;
  readonly invoicePeriod: Maybe<InvoicePeriod>;
  readonly invoicedQuantity: Scalars['DecimalFraction']['output'];
  readonly lastName: Maybe<Scalars['String']['output']>;
  readonly lineExtensionAmount: Scalars['Money']['output'];
  readonly lineExtensionCurrency: Maybe<Scalars['String']['output']>;
  readonly lineType: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly pensionLayer: Maybe<Scalars['String']['output']>;
  readonly personalIdentityNumber: Maybe<Scalars['String']['output']>;
  readonly price: Maybe<Scalars['Money']['output']>;
  readonly priceWithVat: Maybe<Scalars['Money']['output']>;
  readonly sellersItemIdentification: Maybe<Scalars['String']['output']>;
  readonly supplier: Maybe<InvoiceSupplier>;
  readonly taxCategory: Maybe<Scalars['String']['output']>;
  readonly taxClass: Maybe<Scalars['String']['output']>;
}

export interface InvoiceLineConnection {
  readonly __typename?: 'InvoiceLineConnection';
  readonly edges: ReadonlyArray<InvoiceLineEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface InvoiceLineEdge {
  readonly __typename?: 'InvoiceLineEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: InvoiceLine;
}

export interface InvoicePeriod {
  readonly __typename?: 'InvoicePeriod';
  readonly endDate: Maybe<Scalars['Date']['output']>;
  readonly startDate: Maybe<Scalars['Date']['output']>;
}

export enum InvoiceSearchFileExportType {
  CSV = 'CSV',
  XLSX = 'XLSX'
}

export interface InvoiceSearchResult {
  readonly __typename?: 'InvoiceSearchResult';
  readonly invoiceLines: InvoiceLineConnection;
  readonly invoices: InvoiceConnection;
  readonly url: Maybe<Scalars['String']['output']>;
}


export interface InvoiceSearchResulturlArgs {
  format?: InputMaybe<InvoiceSearchFileExportType>;
}

export enum InvoiceSortBy {
  COMPANY_ID = 'COMPANY_ID',
  DUE_DATE = 'DUE_DATE',
  ID = 'ID',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  ISSUE_DATE = 'ISSUE_DATE',
  PAYABLE_AMOUNT = 'PAYABLE_AMOUNT',
  STATUS = 'STATUS',
  SUPPLIER = 'SUPPLIER'
}

export enum InvoiceSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum InvoiceStatus {
  IGNORED = 'IGNORED',
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID'
}

export enum InvoiceSupplier {
  ACTIWAY = 'ACTIWAY',
  ADVINANS = 'ADVINANS',
  EDENRED = 'EDENRED',
  EURO_ACCIDENT = 'EURO_ACCIDENT',
  FUTUR = 'FUTUR',
  LF = 'LF',
  /** Nordea Liv & Pension */
  NLP = 'NLP',
  NORDNET = 'NORDNET',
  SL = 'SL'
}

export interface JobOffer {
  readonly __typename?: 'JobOffer';
  readonly attachmentFiles: Maybe<ReadonlyArray<AttachedFile>>;
  readonly benefitPackageId: Maybe<Scalars['String']['output']>;
  /** @deprecated Use birthdate instead */
  readonly birthDate: Maybe<Scalars['Date']['output']>;
  readonly birthdate: Maybe<Scalars['Date']['output']>;
  readonly body: Maybe<Scalars['String']['output']>;
  readonly collectiveAgreement: Maybe<TotalCompensationCollectiveAgreement>;
  readonly companyId: Scalars['ID']['output'];
  readonly companyLogo: Maybe<Scalars['String']['output']>;
  readonly companyName: Scalars['String']['output'];
  readonly coverPictureFile: Maybe<AttachedFile>;
  /** Will null values for public users, and show notification for admins */
  readonly hidden: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly monthlyCompanyCarValue: Maybe<Scalars['Money']['output']>;
  readonly monthlyLunchAllowance: Maybe<Scalars['Money']['output']>;
  /** @deprecated Use remunerations instead */
  readonly monthlySalary: Maybe<Scalars['Money']['output']>;
  readonly optionsProgram: Maybe<Scalars['String']['output']>;
  readonly optionsProgrammeFiles: Maybe<ReadonlyArray<AttachedFile>>;
  readonly profilePictureFile: Maybe<AttachedFile>;
  /** Only visible for administrators */
  readonly prospectName: Maybe<Scalars['String']['output']>;
  readonly remunerations: Maybe<ReadonlyArray<JobOfferRemuneration>>;
  readonly showDiscount: Maybe<Scalars['Boolean']['output']>;
  readonly status: JobOfferStatus;
  readonly title: Maybe<Scalars['String']['output']>;
  readonly totalCompensation: ReadonlyArray<TotalCompensationItem>;
  readonly totalCompensation2: ReadonlyArray<TotalCompensationItem2>;
  readonly updatedAt: Maybe<Scalars['Date']['output']>;
  /** Will null values for public users, and show notification for admins */
  readonly validUntil: Maybe<Scalars['Date']['output']>;
  readonly workLocation: Maybe<Scalars['String']['output']>;
  readonly workSetting: Maybe<Scalars['String']['output']>;
  readonly yearlyVacationDays: Maybe<Scalars['Int']['output']>;
  readonly yearlyWellnessAllowance: Maybe<Scalars['Money']['output']>;
}

export interface JobOfferConnection {
  readonly __typename?: 'JobOfferConnection';
  readonly edges: ReadonlyArray<JobOfferEdge>;
  readonly pageInfo: PageInfo;
}

export interface JobOfferEdge {
  readonly __typename?: 'JobOfferEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: JobOffer;
}

export interface JobOfferPayload {
  readonly __typename?: 'JobOfferPayload';
  readonly jobOffer: Maybe<JobOffer>;
}

export interface JobOfferRemuneration {
  readonly __typename?: 'JobOfferRemuneration';
  readonly idCode: Scalars['String']['output'];
  readonly value: Scalars['Money']['output'];
}

export interface JobOfferRemunerationInput {
  readonly idCode: Scalars['String']['input'];
  readonly value: Scalars['Money']['input'];
}

export enum JobOfferStatus {
  EXPIRED = 'EXPIRED',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum Language {
  EN_US = 'EN_US',
  SV_SE = 'SV_SE'
}

export enum LegalEntityType {
  /** Filial */
  BRANCH = 'BRANCH',
  /** Economic association (sv: Ekonomisk förening) */
  ECONOMIC_ASSOCIATION = 'ECONOMIC_ASSOCIATION',
  /** Foundation (sv: Stiftelse) */
  FOUNDATION = 'FOUNDATION',
  /** Limited company (sv: Aktiebolag) */
  LIMITED_COMPANY = 'LIMITED_COMPANY',
  /** Limited partnership (sv: Kommanditbolag) */
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  /** Non-profit association (sv: Ideell förening) */
  NONPROFIT_ASSOCIATION = 'NONPROFIT_ASSOCIATION',
  /** Sole trader (sv: Enskild näringsidkare) */
  SOLE_TRADER = 'SOLE_TRADER',
  /** Trading partnership (sv: Handelsbolag) */
  TRADING_PARTNERSHIP = 'TRADING_PARTNERSHIP'
}

/** Agreement types for life benefit. */
export enum LifeAgreementType {
  /** "9918LIV100" For companies with 100 or more employees. */
  LIV100 = 'LIV100',
  /**
   * "9918LIV10-99" For companies with 10 to 99 employees.
   * Allowed LifeLevel values: PBB5, PBB10
   */
  LIV1099 = 'LIV1099'
}

export enum LifeCoverage {
  LIFE = 'LIFE',
  SURVIVOR = 'SURVIVOR',
  TGL = 'TGL'
}

export interface LineDateFilter {
  readonly from: Scalars['Date']['input'];
  readonly to: Scalars['Date']['input'];
}

export enum LineType {
  DISBURSEMENT = 'DISBURSEMENT',
  OTHER = 'OTHER',
  OUTSTANDING_PREMIUM_WAIVER = 'OUTSTANDING_PREMIUM_WAIVER',
  PREMIUM = 'PREMIUM',
  SURPLUS = 'SURPLUS'
}

export interface LocalInfo {
  readonly __typename?: 'LocalInfo';
  readonly SE: Maybe<LocalInfoSE>;
}

export interface LocalInfoSE {
  readonly __typename?: 'LocalInfoSE';
  readonly collectiveAgreement: ReadonlyArray<M2CollectiveAgreement>;
  readonly tax: ReadonlyArray<LocalInfoSETax>;
}

export interface LocalInfoSETax {
  readonly __typename?: 'LocalInfoSETax';
  readonly employersContributionsRate: Maybe<Scalars['DecimalFraction']['output']>;
  readonly from: Scalars['Date']['output'];
  readonly incomeTaxAdjustedRate: Maybe<Scalars['DecimalFraction']['output']>;
  readonly incomeTaxColumn: Maybe<Scalars['Int']['output']>;
  readonly incomeTaxTable: Maybe<Scalars['Int']['output']>;
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface LoginInput {
  readonly email?: InputMaybe<Scalars['String']['input']>;
}

export interface LoginPayload {
  readonly __typename?: 'LoginPayload';
  readonly autoStartToken: Scalars['ID']['output'];
  readonly qrData: Scalars['String']['output'];
  readonly ticketId: Scalars['ID']['output'];
}

export type LoginTicket = BankIdTicketFailure | BankIdTicketProgress | LoginTicketComplete;

/** Exchange token for a session cookie against POST /session. */
export interface LoginTicketComplete {
  readonly __typename?: 'LoginTicketComplete';
  /** One-time token passed to session endpoint to get cookies. */
  readonly token: Scalars['String']['output'];
}

export interface M2AccountingCost {
  readonly __typename?: 'M2AccountingCost';
  readonly object: AccountingObject;
  readonly share: Scalars['DecimalFraction']['output'];
}

export interface M2AccountingCostItem {
  readonly __typename?: 'M2AccountingCostItem';
  readonly accountingCost: ReadonlyArray<M2AccountingCost>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface M2BenefitPackage {
  readonly __typename?: 'M2BenefitPackage';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface M2CollectiveAgreement {
  readonly __typename?: 'M2CollectiveAgreement';
  readonly collectiveAgreement: Maybe<CollectiveAgreementType>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface M2Company {
  readonly __typename?: 'M2Company';
  readonly customTypes: ReadonlyArray<CustomType>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly registrationNumber: Scalars['IdentityNumber']['output'];
}

export interface M2Employment {
  readonly __typename?: 'M2Employment';
  readonly accountingCost: ReadonlyArray<AccountingCostItem>;
  readonly balance: ReadonlyArray<BalanceItem>;
  readonly benefitPackage: ReadonlyArray<BenefitPackageItem>;
  readonly employeeId: Maybe<Scalars['String']['output']>;
  readonly employmentRate: ReadonlyArray<EmploymentRateItem>;
  readonly employmentRole: ReadonlyArray<EmploymentRoleItem>;
  readonly employmentType: ReadonlyArray<EmploymentTypeItem>;
  readonly extensions: Maybe<Extensions>;
  readonly from: Scalars['Date']['output'];
  readonly localInfo: Maybe<LocalInfo>;
  readonly ou: ReadonlyArray<OrganizationalUnitItem>;
  readonly remuneration: ReadonlyArray<RemunerationItem>;
  readonly revisions: ReadonlyArray<EmploymentRevision>;
  readonly status: ReadonlyArray<StatusItem>;
  readonly to: Maybe<Scalars['Date']['output']>;
}


export interface M2EmploymentremunerationArgs {
  effectiveAt?: InputMaybe<Scalars['Date']['input']>;
}

export interface M2Membership {
  readonly __typename?: 'M2Membership';
  readonly birthDate: Maybe<Scalars['Date']['output']>;
  readonly company: M2Company;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly employment: ReadonlyArray<M2Employment>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly givenName: Maybe<Scalars['String']['output']>;
  readonly lastName: Maybe<Scalars['String']['output']>;
  readonly naturalPersonIdentifiers: ReadonlyArray<Scalars['NaturalPersonIdentifier']['output']>;
  readonly personalIdentityNumber: Maybe<Scalars['IdentityNumber']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface M2MembershipConnection {
  readonly __typename?: 'M2MembershipConnection';
  readonly edges: ReadonlyArray<M2MembershipEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface M2MembershipEdge {
  readonly __typename?: 'M2MembershipEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: M2Membership;
}

export interface M2OrganizationalUnit {
  readonly __typename?: 'M2OrganizationalUnit';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface MakeKycExceptionInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly reason: Scalars['String']['input'];
  readonly registrationNumber: Scalars['String']['input'];
  readonly validUntil: Scalars['DateTimeISO']['input'];
}

export interface MakeKycExceptionPayload {
  readonly __typename?: 'MakeKycExceptionPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export enum ManagingCompany {
  NLP = 'NLP',
  NODE = 'NODE'
}

export interface MarkNotificationsAsDonePayload {
  readonly __typename?: 'MarkNotificationsAsDonePayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface MarkOutdatedAnalysesPayload {
  readonly __typename?: 'MarkOutdatedAnalysesPayload';
  readonly affectedAnalysisCount: Scalars['Int']['output'];
}

export interface MaxSalaryToPensionRatioExceededMetadata {
  readonly __typename?: 'MaxSalaryToPensionRatioExceededMetadata';
  readonly fullName: Scalars['String']['output'];
  readonly year: Scalars['Int']['output'];
}

export interface MaxTotalPensionCostExceededMetadata {
  readonly __typename?: 'MaxTotalPensionCostExceededMetadata';
  readonly fullName: Scalars['String']['output'];
  readonly maxTotalPensionCost: Scalars['Money']['output'];
  readonly year: Scalars['Int']['output'];
}

export interface Meeting {
  readonly __typename?: 'Meeting';
  readonly advisorName: Maybe<Scalars['String']['output']>;
  readonly companyId: Scalars['ID']['output'];
  readonly confirmed: Scalars['Boolean']['output'];
  readonly date: Scalars['Date']['output'];
  readonly eventTypeCategory: EventTypeCategory;
  readonly id: Scalars['ID']['output'];
  readonly latestEmailSentAt: Maybe<Scalars['Date']['output']>;
  readonly link: Maybe<Scalars['String']['output']>;
  readonly time: Scalars['DateTime']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface MeetingConnection {
  readonly __typename?: 'MeetingConnection';
  readonly edges: ReadonlyArray<MeetingEdge>;
  readonly pageInfo: PageInfo;
}

export interface MeetingEdge {
  readonly __typename?: 'MeetingEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Meeting;
}

export interface MeetingStatistics {
  readonly __typename?: 'MeetingStatistics';
  readonly numberOfBookedMeetings: Scalars['Int']['output'];
  readonly numberOfCancelledMeetings: Scalars['Int']['output'];
  readonly numberOfConfirmedMeetings: Scalars['Int']['output'];
  readonly numberOfCreatedBookings: Scalars['Int']['output'];
  readonly numberOfInvitationsSent: Scalars['Int']['output'];
  readonly numberOfRemindersSent: RemindersSent;
}

export interface Membership {
  readonly __typename?: 'Membership';
  readonly accountingCost: ReadonlyArray<AccountingCostItem>;
  readonly address: Maybe<Address>;
  /** @deprecated Relocated to inside membershipBenefitPackage */
  readonly benefitPackage: Maybe<BenefitPackage>;
  /** @deprecated Relocated to inside membershipBenefitPackage */
  readonly benefitPackageEffectiveDate: Maybe<Scalars['Date']['output']>;
  readonly benefitPackageHistory: ReadonlyArray<BenefitPackageItem>;
  /** @deprecated Relocated to inside membershipBenefitPackage */
  readonly benefitPackageUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  readonly birthdate: Maybe<Scalars['Date']['output']>;
  readonly changes: ReadonlyArray<FlexChange>;
  readonly changesHistory: Maybe<FlexChangeHistoryConnection>;
  readonly collectiveAgreement: Maybe<Scalars['String']['output']>;
  readonly company: Company;
  readonly companyCarMonthlyValue: Maybe<Scalars['Money']['output']>;
  /**
   * Effective date on which the person is allowed to have risk insurances created
   * with conversion.
   *
   * Null if the person is not allowed to convert risk insurances.
   */
  readonly convertRiskInsurances: Maybe<Scalars['Date']['output']>;
  readonly costCenter: Maybe<CostCenter>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly emailLoginAllowed: Scalars['Boolean']['output'];
  readonly employeeNumber: Maybe<Scalars['String']['output']>;
  /** The employer contributions on salary and benefits as a percentage (sv: arbetsgivaravgifter) */
  readonly employerContributionShare: Maybe<Scalars['DecimalFraction']['output']>;
  readonly employment: ReadonlyArray<M2Employment>;
  readonly employments: Maybe<EmploymentConnection>;
  readonly eventHistory: Maybe<MembershipEventHistoryConnection>;
  readonly fcfwAssertions: Maybe<FlexFcfwAssertionConnection>;
  readonly firstName: Scalars['String']['output'];
  readonly fitnessContributionBalance: Maybe<Scalars['Money']['output']>;
  readonly fixedPremiums: Maybe<FlexFixedPremiumConnection>;
  readonly givenName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  /** Income tax table (sv: skattetabell) that this membership belongs to. Used for computing post-income tax effects of e.g. salary exchange and other benefits. */
  readonly incomeTaxTable: Maybe<Scalars['Int']['output']>;
  readonly insurances: Maybe<InsuranceConnection>;
  readonly isOwner: Scalars['Boolean']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly membershipBenefitPackage: Maybe<MembershipBenefitPackage>;
  /** Gross monthly salary in SEK */
  readonly monthlySalary: Maybe<Scalars['Money']['output']>;
  readonly naturalPersonIdentifiers: ReadonlyArray<Scalars['NaturalPersonIdentifier']['output']>;
  readonly organizationalUnit: Maybe<OrganizationalUnit>;
  readonly payslips: Maybe<PayslipConnection>;
  /** @deprecated Replaced by naturalPersonIdentifiers */
  readonly personalIdentityNumber: Maybe<Scalars['PersonalIdentityNumber']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly properties: ReadonlyArray<MembershipProperty>;
  readonly roles: ReadonlyArray<MembershipRole>;
  readonly salaries: Maybe<MembershipSalaryConnection>;
  readonly sex: Maybe<Sex>;
  readonly singlePremiums: Maybe<FlexSinglePremiumConnection>;
  readonly totalCompensation: ReadonlyArray<TotalCompensationItem>;
  readonly totalCompensation2: ReadonlyArray<TotalCompensationItem2>;
  readonly userAccountId: Scalars['ID']['output'];
  readonly vacationDaysAdvance: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysPaid: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysPerYear: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysSaved: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysUnpaid: Maybe<Scalars['Int']['output']>;
}


export interface MembershipchangesHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FlexChangesHistoryInputFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipemploymentsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  effectiveAt?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipeventHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipfcfwAssertionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipfixedPremiumsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipinsurancesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  instituteId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershippayslipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipsalariesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface MembershipsinglePremiumsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

/** A physical address somewhere in the world */
export interface MembershipAddressInput {
  readonly careOf?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly line1?: InputMaybe<Scalars['String']['input']>;
  readonly line2?: InputMaybe<Scalars['String']['input']>;
  readonly postalCode?: InputMaybe<Scalars['String']['input']>;
}

export interface MembershipBenefitPackage {
  readonly __typename?: 'MembershipBenefitPackage';
  readonly benefitPackage: BenefitPackage;
  readonly effectiveDate: Scalars['Date']['output'];
  /** Contains a calculated value of bp.showTotalCompensation and employment rate */
  readonly showTotalCompensation: Scalars['Boolean']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
}

export interface MembershipConnection {
  readonly __typename?: 'MembershipConnection';
  readonly edges: ReadonlyArray<MembershipEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface MembershipEdge {
  readonly __typename?: 'MembershipEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: Membership;
}

export interface MembershipEventHistory {
  readonly __typename?: 'MembershipEventHistory';
  readonly actor: Maybe<Actor>;
  readonly authorName: Maybe<Scalars['String']['output']>;
  readonly authorType: EmployeeHistoryEventAuthorType;
  readonly createdAt: Maybe<Scalars['Date']['output']>;
  readonly effectiveDate: Maybe<Scalars['String']['output']>;
  readonly events: Maybe<ReadonlyArray<MembershipEventHistoryEvent>>;
  readonly source: EmployeeHistoryEventSource;
}

export interface MembershipEventHistoryConnection {
  readonly __typename?: 'MembershipEventHistoryConnection';
  readonly edges: ReadonlyArray<MembershipEventHistoryEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface MembershipEventHistoryEdge {
  readonly __typename?: 'MembershipEventHistoryEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: MembershipEventHistory;
}

export interface MembershipEventHistoryEvent {
  readonly __typename?: 'MembershipEventHistoryEvent';
  readonly event: EmployeeHistoryEventType;
  readonly value: Maybe<Scalars['String']['output']>;
}

export enum MembershipExportFileFormat {
  CSV = 'CSV',
  XLSX = 'XLSX'
}

export interface MembershipKey {
  readonly companyId: Scalars['ID']['input'];
  readonly userAccountId: Scalars['ID']['input'];
}

export interface MembershipProperty {
  readonly __typename?: 'MembershipProperty';
  readonly companyId: Scalars['String']['output'];
  readonly createdAt: Scalars['String']['output'];
  readonly deletedAt: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly property: MembershipPropertyValues;
  readonly userAccountId: Scalars['String']['output'];
}

export interface MembershipPropertyInput {
  readonly companyId: Scalars['String']['input'];
  readonly property: MembershipPropertyValues;
  readonly userAccountId: Scalars['String']['input'];
}

export enum MembershipPropertyValues {
  EMAIL_FILTER_ADMIN__FULLY_CAPABLE_FOR_WORK_REQUIRED = 'EMAIL_FILTER_ADMIN__FULLY_CAPABLE_FOR_WORK_REQUIRED',
  EMAIL_FILTER_ADMIN__IMPORT_TO_ADVINANS_FAILED = 'EMAIL_FILTER_ADMIN__IMPORT_TO_ADVINANS_FAILED',
  EMAIL_FILTER_ADMIN__IMPORT_TO_ADVINANS_SUCCESSFUL = 'EMAIL_FILTER_ADMIN__IMPORT_TO_ADVINANS_SUCCESSFUL',
  EMAIL_FILTER_ADMIN__SALARY_EXCHANGES_TO_REVIEW = 'EMAIL_FILTER_ADMIN__SALARY_EXCHANGES_TO_REVIEW',
  EMAIL_FILTER_ADMIN__SALARY_EXCHANGE_REPORT_AVAILABLE = 'EMAIL_FILTER_ADMIN__SALARY_EXCHANGE_REPORT_AVAILABLE',
  EMAIL_FILTER_ADMIN__SALARY_EXCHANGE_REQUEST_CREATED = 'EMAIL_FILTER_ADMIN__SALARY_EXCHANGE_REQUEST_CREATED',
  EMAIL_FILTER_ADMIN__SL_REPORT_AVAILABLE = 'EMAIL_FILTER_ADMIN__SL_REPORT_AVAILABLE',
  EMAIL_FILTER_ADMIN__SUPPLIER_INVOICE_RECEIVED = 'EMAIL_FILTER_ADMIN__SUPPLIER_INVOICE_RECEIVED',
  EMAIL_OPT_IN__IMPORT_RESULT = 'EMAIL_OPT_IN__IMPORT_RESULT'
}

export interface MembershipRemunerationItemInput {
  readonly from: Scalars['Date']['input'];
  readonly itemTypeId: Scalars['String']['input'];
  readonly value: Scalars['DecimalFraction']['input'];
}

export enum MembershipRole {
  CONTENT = 'CONTENT',
  FINANCE = 'FINANCE',
  HR = 'HR',
  SUPER = 'SUPER'
}

export interface MembershipSalary {
  readonly __typename?: 'MembershipSalary';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly monthlySalary: Scalars['Money']['output'];
}

export interface MembershipSalaryConnection {
  readonly __typename?: 'MembershipSalaryConnection';
  readonly edges: ReadonlyArray<MembershipSalaryEdge>;
  readonly pageInfo: PageInfo;
}

export interface MembershipSalaryEdge {
  readonly __typename?: 'MembershipSalaryEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: MembershipSalary;
}

export interface MiscConfiguration extends Configuration {
  readonly __typename?: 'MiscConfiguration';
  readonly author: Maybe<Scalars['String']['output']>;
  readonly data: Maybe<Scalars['JSON']['output']>;
}

export interface MissingBenefitPackageMetadata {
  readonly __typename?: 'MissingBenefitPackageMetadata';
  readonly fullName: Scalars['String']['output'];
}

export interface MissingEmailMetadata {
  readonly __typename?: 'MissingEmailMetadata';
  readonly fullName: Scalars['String']['output'];
}

export interface MissingFcfwAssertionMetadata {
  readonly __typename?: 'MissingFcfwAssertionMetadata';
  readonly fullName: Scalars['String']['output'];
}

export interface MissingPensionPremiumMetadata {
  readonly __typename?: 'MissingPensionPremiumMetadata';
  readonly fullName: Scalars['String']['output'];
}

export interface MissingSalaryMetadata {
  readonly __typename?: 'MissingSalaryMetadata';
  readonly from: Scalars['Date']['output'];
  readonly fullName: Scalars['String']['output'];
}

export interface Mutation {
  readonly __typename?: 'Mutation';
  /**
   * Activate an Advinans Plus benefit provided by your employer
   * @deprecated Disabled. Plus is automatically available as a benefit
   */
  readonly activatePlus: Maybe<ActivatePlusPayload>;
  readonly addAccountingDimension: Maybe<AccountingDimension>;
  readonly addAccountingObject: Maybe<AccountingObject>;
  readonly addBenefitsToBenefitPackage: FlexAddBenefitsToBenefitPackagePayload;
  readonly addCompanyBillingConfiguration: Maybe<AddCompanyBillingConfigurationPayload>;
  readonly addCustomItem: Maybe<CustomItemWrapper>;
  readonly addCustomType: Maybe<CustomType>;
  readonly addEmployment: Maybe<AddEmploymentPayload>;
  readonly addEmploymentActivity: Maybe<AddEmploymentActivityPayload>;
  readonly addMembershipAccountingItem: AccountingItem;
  readonly agreeToBikeTerms: Maybe<FlexAgreeToBikeTermsPayload>;
  readonly approveSalaryExchangeRequest: FlexApproveSalaryExchangeRequestPayload;
  readonly assertFullyCapableForWork: Maybe<AssertFullyCapableForWorkPayload>;
  /**
   * Buy an Advinans Plus subscription for yourself via credit card. The purchase
   * is completed with an external party.
   * @deprecated Disabled. Plus is only available as a benefit
   */
  readonly buyPlus: Maybe<BuyPlusPayload>;
  /** Cancel an order. This will not result in a status update, but simply abort the BankID transaction. */
  readonly cancel: CancelPayload;
  readonly cancelBankIdTicket: Maybe<FlexCancelBankIdTicketPayload>;
  /** Cancel an initiaded login request */
  readonly cancelLogin: Maybe<CancelLoginPayload>;
  readonly cancelMeeting: CancelMeetingPayload;
  readonly cancelSignPlus: CancelSignPlusPayload;
  readonly cancelSignPoa: CancelSignPoaPayload;
  readonly claimPensionTransferTasks: ClaimPensionTransferTasksPayload;
  readonly confirmMeeting: ConfirmMeetingPayload;
  readonly createAccidentBenefit: FlexBenefit;
  /** Requires Swedish locale */
  readonly createBenefitCategory: Maybe<FlexCreateBenefitCategoryPayload>;
  readonly createBenefitPackage: Maybe<CreateBenefitPackagePayload>;
  readonly createBenefitSupplier: Maybe<FlexCreateBenefitSupplierPayload>;
  readonly createBenefits: FlexCreateBenefitsPayload;
  readonly createCompany2: Maybe<CreateCompany2Payload>;
  readonly createCompanyRepresentative: Proposal;
  readonly createCostCenter: Maybe<CreateCostCenterPayload>;
  /** Create new document */
  readonly createDocument: CreateDocumentPayload;
  readonly createHealthcareBenefit: FlexBenefit;
  readonly createImport: Maybe<CreateImportPayload>;
  readonly createInternalPost: Maybe<CreateInternalPostPayload>;
  readonly createInvoice: Maybe<CreateInvoicePayload>;
  /** Create a new Job Offer, all admins allowed */
  readonly createJobOffer: Maybe<JobOfferPayload>;
  readonly createLifeBenefit: FlexBenefit;
  readonly createMembership: Maybe<CreateMembershipPayload>;
  readonly createNote: Maybe<CreateNotePayload>;
  readonly createOccupationalPensionBenefit: FlexBenefit;
  readonly createOrganizationalUnit: Maybe<CreateOrganizationalUnitPayload>;
  /** Create new Plus profile based on your economic profile and preferences */
  readonly createPlusProfile: Maybe<CreatePlusProfilePayload>;
  readonly createPreSignedAgreements: Maybe<CreatePreSignedAgreementsPayload>;
  readonly createProposal: Proposal;
  readonly createProposalAccidentBenefit: Proposal;
  readonly createProposalBenefitPackage: Proposal;
  readonly createProposalEmployersPensionBenefit: Proposal;
  readonly createProposalHealthcareBenefit: Proposal;
  readonly createProposalLifeBenefit: Proposal;
  readonly createProposalMembership: Proposal;
  readonly createProposalOccupationalPensionBenefit: Proposal;
  readonly createProposalSalaryExchangeBenefit: Proposal;
  readonly createProposalSicknessBenefit: Proposal;
  readonly createProposalTglBenefit: Proposal;
  readonly createSalaryExchangeBenefit: FlexBenefit;
  /**
   * Create shared files from either company uploads or user uploads.
   *
   * Exactly one of `companyId` or `userAccountId` must be specified.
   */
  readonly createSharedFiles: Maybe<CreateSharedFilesPayload>;
  readonly createSicknessAndAccidentBenefit: FlexBenefit;
  readonly createSicknessBenefit: FlexBenefit;
  readonly createSinglePremium: Maybe<FlexSinglePremiumInputPayload>;
  readonly createTglBenefit: FlexBenefit;
  readonly deleteAttachment: FlexDeleteAttachmentPayload;
  readonly deleteCompanyBillingConfiguration: Maybe<DeleteCompanyBillingConfigurationPayload>;
  readonly deleteCompanyRepresentative: Proposal;
  readonly deleteEmployment: Maybe<DeleteEmploymentPayload>;
  readonly deleteEmploymentActivity: Maybe<DeleteEmploymentActivityPayload>;
  readonly deleteInsurances: DeleteInsurancesPayload;
  readonly deleteInternalPost: Maybe<DeleteInternalPostPayload>;
  /** Delete a Job Offer, SUPER & HR can delete all, CONTENT can only delete authored */
  readonly deleteJobOffer: Maybe<DeleteJobOfferPayload>;
  readonly deleteMeeting: DeleteMeetingPayload;
  readonly deleteNote: Maybe<DeleteNotePayload>;
  readonly deleteOrganizationalUnit: Maybe<DeleteOrganizationalUnitPayload>;
  readonly deleteProposal: DeleteProposalPayload;
  readonly deleteProposalBenefit: Proposal;
  readonly deleteProposalBenefitPackage: Proposal;
  readonly deleteProposalMembership: Proposal;
  readonly deleteSharedFile: Maybe<DeleteSharedFilePayload>;
  readonly disablePoa: DisablePoaPayload;
  readonly duplicateProposal: Proposal;
  readonly executeProposal: Proposal;
  readonly flexCancelSalaryExchangeRequest: FlexCancelSalaryExchangeRequestPayload;
  readonly flexRequestSalaryExchange: Maybe<FlexRequestSalaryExchangePayload>;
  readonly flexTerminateSalaryExchange: FlexTerminateSalaryExchangePayload;
  readonly generateProposal: Proposal;
  readonly ingestPayslipFile: Maybe<IngestPayslipPayload>;
  /** Initiate login and poll loginTicket until complete */
  readonly login: Maybe<LoginPayload>;
  readonly makeKycException: Maybe<MakeKycExceptionPayload>;
  /**
   * Marks the current analysis of each user as outdated if it does not match the
   * current user state.
   */
  readonly markOutdatedAnalyses: Maybe<MarkOutdatedAnalysesPayload>;
  readonly optOutFromPersonalAdvice: FlexOptOutFromPersonalAdvicePayload;
  readonly orderMovieTickets: Maybe<FlexOrderMovieTicketsPayload>;
  readonly rejectSalaryExchangeRequest: FlexRejectSalaryExchangeRequestPayload;
  readonly removeMembershipBenefitPackage: Maybe<RemoveMembershipBenefitPackagePayload>;
  readonly removeMembershipProperty: Maybe<MembershipProperty>;
  readonly removeProposalBenefitFromBenefitPackage: Proposal;
  readonly requestBonusSalaryExchange: Maybe<FlexRequestSalaryExchangePayload>;
  readonly resendInvoiceEmails: Maybe<Scalars['String']['output']>;
  readonly resendPoa: ResendPoaPayload;
  readonly selectPensionProvider: Maybe<FlexSelectPensionProviderPayload>;
  readonly sendMeetingInvitations: SendMeetingInvitationsPayload;
  readonly setCompilationLookedAt: Proposal;
  readonly setFeesLookedAt: Proposal;
  readonly setFixedPremium: Maybe<FlexSetFixedPremiumPayload>;
  /** Hide an import. For all intents and purposes it should be considered deleted by this. */
  readonly setImportVisibility: Import;
  readonly setInsuranceDeleted: UpdateInsurancePayload;
  readonly setLocalePreference: Maybe<SetLocalePreferencePayload>;
  readonly setMembershipProperty: Maybe<MembershipProperty>;
  /** This mutation is used when creating or updating the contact person of a company. It expects complete data. */
  readonly setProposalContactPerson: ProposalCompanyContactPerson;
  /** This mutation is used when creating or updating the head admin of a company. It expects complete data. */
  readonly setProposalHeadAdmin: ProposalCompanyHeadAdmin;
  readonly signPensionTransferTask: SignPensionTransferTaskPayload;
  /** Before you can activate or buy plus you must first sign terms of agreement */
  readonly signPlus: Maybe<SignPlusPayload>;
  /**
   * Initiate signing a new private insurance POA for yourself. You cannot sign
   * a new POA while you already have an active one. POAs are always valid from
   * when you've signed them.
   */
  readonly signPoa: SignPoaPayload;
  readonly signupFri2: Maybe<SignupFri2Payload>;
  readonly startImport: Maybe<StartImportPayload>;
  readonly startScrape: ScrapePayload;
  readonly submitNannyNuForm: Maybe<FlexNannyNuFormPayload>;
  readonly updateAccidentBenefit: FlexBenefit;
  readonly updateAccountingDimension: Maybe<AccountingDimension>;
  readonly updateAccountingObject: Maybe<AccountingObject>;
  readonly updateBenefit: Maybe<FlexUpdateBenefitPayload>;
  readonly updateBenefitCategory: Maybe<FlexUpdateBenefitCategoryPayload>;
  readonly updateBenefitPackage: Maybe<UpdateBenefitPackagePayload>;
  readonly updateBenefitSupplier: Maybe<FlexUpdateBenefitSupplierPayload>;
  readonly updateBookkeepingAccount: Maybe<BookkeepingAccount>;
  readonly updateCompany: Maybe<UpdateCompanyPayload>;
  readonly updateCompanyBillingConfiguration: Maybe<UpdateCompanyBillingConfigurationPayload>;
  readonly updateCompanyRepresentative: Proposal;
  readonly updateCompanyStatus: Maybe<UpdateCompanyStatusPayload>;
  readonly updateCostCenter: Maybe<UpdateCostCenterPayload>;
  /** This mutation creates an economic profile if ones does not exist */
  readonly updateEconomicProfile: Maybe<UpdateEconomicProfilePayload>;
  readonly updateEmployment: Maybe<UpdateEmploymentPayload>;
  readonly updateEmploymentActivity: Maybe<UpdateEmploymentActivityPayload>;
  /** Updates family situation */
  readonly updateFamilySituation: Maybe<UpdateFamilySituationPayload>;
  readonly updateHealthcareBenefit: FlexBenefit;
  /**
   * Update an import with new column mappings. This causes the import to
   * restart processing. You cannot update running imports.
   */
  readonly updateImport: Maybe<UpdateImportPayload>;
  /** Can only be used to modify insurances brokered by Advinans */
  readonly updateInsurance: UpdateInsurancePayload;
  /** Generates a new insurance analysis. */
  readonly updateInsuranceAnalysis: Maybe<UpdateInsuranceAnalysisPayload>;
  /** Updates insurance analysis action status. */
  readonly updateInsuranceAnalysisAction: Maybe<UpdateInsuranceAnalysisActionPayload>;
  readonly updateInternalPost: Maybe<UpdateInternalPostPayload>;
  readonly updateInvoiceStatus: Maybe<UpdateInvoiceStatusPayload>;
  /** Update a Job Offer, SUPER & HR can update all, CONTENT can only update authored */
  readonly updateJobOffer: Maybe<JobOfferPayload>;
  readonly updateLifeBenefit: FlexBenefit;
  readonly updateMembership: Maybe<UpdateMemberShipPayload>;
  readonly updateMembershipBenefitPackage: Maybe<UpdateMembershipBenefitPackagePayload>;
  readonly updateMembershipCostCenter: Maybe<UpdateMembershipCostCenterPayload>;
  readonly updateMembershipSalary: Maybe<UpdateMembershipSalaryPayload>;
  readonly updateNote: Maybe<UpdateNotePayload>;
  readonly updateNotification: Maybe<UpdateNotificationPayload>;
  readonly updateOccupationalPensionBenefit: FlexBenefit;
  readonly updateOrganizationalUnit: Maybe<UpdateOrganizationalUnitPayload>;
  readonly updatePensionTransferAdvice: UpdatePensionTransferAdvicePayload;
  /** Receive new Plus advice based on your Plus profile */
  readonly updatePlusAdvice: Maybe<UpdatePlusAdvicePayload>;
  readonly updatePlusSuggestionStatus: Maybe<UpdatePlusSuggestionStatusPayload>;
  readonly updateProposal: Proposal;
  readonly updateProposalAccidentBenefit: Proposal;
  readonly updateProposalBenefitPackage: Proposal;
  readonly updateProposalEmployersPensionBenefit: Proposal;
  readonly updateProposalHealthcareBenefit: Proposal;
  readonly updateProposalLifeBenefit: Proposal;
  readonly updateProposalMembership: ProposalMembership;
  readonly updateProposalOccupationalPensionBenefit: Proposal;
  readonly updateProposalSalaryExchangeBenefit: Proposal;
  readonly updateProposalSicknessBenefit: Proposal;
  readonly updateProposalTglBenefit: Proposal;
  readonly updateSalaryExchangeBenefit: FlexBenefit;
  readonly updateSicknessAndAccidentBenefit: FlexBenefit;
  readonly updateSicknessBenefit: FlexBenefit;
  readonly updateTglBenefit: FlexBenefit;
  readonly updateTotalCompensationConfiguration: TotalCompensationConfiguration;
  readonly updateUserAccount: Maybe<UpdateUserAccountPayload>;
  readonly uploadCompanyFile: Maybe<UploadedCompanyFileInfoData>;
  readonly uploadPdfInvoice: UploadPdfInvoicePayload;
}


export interface MutationactivatePlusArgs {
  input: ActivatePlusInput;
}


export interface MutationaddAccountingDimensionArgs {
  input?: InputMaybe<AddAccountingDimensionInput>;
}


export interface MutationaddAccountingObjectArgs {
  input?: InputMaybe<AddAccountingObjectInput>;
}


export interface MutationaddBenefitsToBenefitPackageArgs {
  input: FlexAddBenefitsToBenefitPackageInput;
}


export interface MutationaddCompanyBillingConfigurationArgs {
  input: AddCompanyBillingConfigurationInput;
}


export interface MutationaddCustomItemArgs {
  input?: InputMaybe<AddCustomItemInput>;
}


export interface MutationaddCustomTypeArgs {
  input?: InputMaybe<AddCustomTypeInput>;
}


export interface MutationaddEmploymentArgs {
  input: AddEmploymentInput;
}


export interface MutationaddEmploymentActivityArgs {
  input: AddEmploymentActivityInput;
}


export interface MutationaddMembershipAccountingItemArgs {
  input?: InputMaybe<AddMembershipAccountingItemInput>;
}


export interface MutationagreeToBikeTermsArgs {
  input: FlexAgreeToBikeTermsInput;
}


export interface MutationapproveSalaryExchangeRequestArgs {
  input: FlexApproveSalaryExchangeRequestInput;
}


export interface MutationassertFullyCapableForWorkArgs {
  input: AssertFullyCapableForWorkInput;
}


export interface MutationbuyPlusArgs {
  input: BuyPlusInput;
}


export interface MutationcancelArgs {
  input: CancelInput;
}


export interface MutationcancelBankIdTicketArgs {
  input: FlexCancelBankIdTicket;
}


export interface MutationcancelLoginArgs {
  input: CancelLoginInput;
}


export interface MutationcancelMeetingArgs {
  input: CancelMeetingInput;
}


export interface MutationcancelSignPlusArgs {
  input: CancelSignPlusInput;
}


export interface MutationcancelSignPoaArgs {
  input: CancelSignPoaInput;
}


export interface MutationclaimPensionTransferTasksArgs {
  input: ClaimPensionTransferTasksInput;
}


export interface MutationconfirmMeetingArgs {
  input: ConfirmMeetingInput;
}


export interface MutationcreateAccidentBenefitArgs {
  input: FlexCreateAccidentBenefitInput;
}


export interface MutationcreateBenefitCategoryArgs {
  input?: InputMaybe<FlexCreateBenefitCategoryInput>;
}


export interface MutationcreateBenefitPackageArgs {
  input?: InputMaybe<CreateBenefitPackageInput>;
}


export interface MutationcreateBenefitSupplierArgs {
  input?: InputMaybe<FlexCreateBenefitSupplierInput>;
}


export interface MutationcreateBenefitsArgs {
  input: FlexCreateBenefitsInput;
}


export interface MutationcreateCompany2Args {
  input: CreateCompany2Input;
}


export interface MutationcreateCompanyRepresentativeArgs {
  input: CreateCompanyRepresentativeInput;
}


export interface MutationcreateCostCenterArgs {
  input: CreateCostCenterInput;
}


export interface MutationcreateDocumentArgs {
  input: CreateDocumentInput;
}


export interface MutationcreateHealthcareBenefitArgs {
  input: FlexCreateHealthcareBenefitInput;
}


export interface MutationcreateImportArgs {
  input: CreateImportInput;
}


export interface MutationcreateInternalPostArgs {
  input: CreateInternalPostInput;
}


export interface MutationcreateInvoiceArgs {
  input: CreateInvoiceInput;
}


export interface MutationcreateJobOfferArgs {
  input: CreateJobOfferInput;
}


export interface MutationcreateLifeBenefitArgs {
  input: FlexCreateLifeBenefitInput;
}


export interface MutationcreateMembershipArgs {
  input: CreateMembershipInput;
}


export interface MutationcreateNoteArgs {
  input: CreateNoteInput;
}


export interface MutationcreateOccupationalPensionBenefitArgs {
  input: FlexCreateOccupationalPensionBenefitInput;
}


export interface MutationcreateOrganizationalUnitArgs {
  input: CreateOrganizationalUnitInput;
}


export interface MutationcreatePlusProfileArgs {
  input: CreatePlusProfileInput;
}


export interface MutationcreatePreSignedAgreementsArgs {
  input: CreatePreSignedAgreementsInput;
}


export interface MutationcreateProposalArgs {
  input: CreateProposalInput;
}


export interface MutationcreateProposalAccidentBenefitArgs {
  input: CreateProposalAccidentBenefitInput;
}


export interface MutationcreateProposalBenefitPackageArgs {
  input: CreateProposalBenefitPackageInput;
}


export interface MutationcreateProposalEmployersPensionBenefitArgs {
  input: CreateProposalEmployersPensionBenefitInput;
}


export interface MutationcreateProposalHealthcareBenefitArgs {
  input: CreateProposalHealthcareBenefitInput;
}


export interface MutationcreateProposalLifeBenefitArgs {
  input: CreateProposalLifeBenefitInput;
}


export interface MutationcreateProposalMembershipArgs {
  input: CreateProposalMembershipInput;
}


export interface MutationcreateProposalOccupationalPensionBenefitArgs {
  input: CreateProposalOccupationalPensionBenefitInput;
}


export interface MutationcreateProposalSalaryExchangeBenefitArgs {
  input: CreateProposalSalaryExchangeBenefitInput;
}


export interface MutationcreateProposalSicknessBenefitArgs {
  input: CreateProposalSicknessBenefitInput;
}


export interface MutationcreateProposalTglBenefitArgs {
  input: CreateProposalTglBenefitInput;
}


export interface MutationcreateSalaryExchangeBenefitArgs {
  input: FlexCreateSalaryExchangeBenefitInput;
}


export interface MutationcreateSharedFilesArgs {
  input: CreateSharedFilesInput;
}


export interface MutationcreateSicknessAndAccidentBenefitArgs {
  input: FlexCreateSicknessAndAccidentBenefitInput;
}


export interface MutationcreateSicknessBenefitArgs {
  input: FlexCreateSicknessBenefitInput;
}


export interface MutationcreateSinglePremiumArgs {
  input?: InputMaybe<FlexSinglePremiumInput>;
}


export interface MutationcreateTglBenefitArgs {
  input: FlexCreateTglBenefitInput;
}


export interface MutationdeleteAttachmentArgs {
  input: FlexDeleteAttachmentInput;
}


export interface MutationdeleteCompanyBillingConfigurationArgs {
  input: DeleteCompanyBillingConfigurationInput;
}


export interface MutationdeleteCompanyRepresentativeArgs {
  input: DeleteCompanyRepresentativeInput;
}


export interface MutationdeleteEmploymentArgs {
  input: DeleteEmploymentInput;
}


export interface MutationdeleteEmploymentActivityArgs {
  input: DeleteEmploymentActivityInput;
}


export interface MutationdeleteInsurancesArgs {
  input: DeleteInsurancesInput;
}


export interface MutationdeleteInternalPostArgs {
  input: DeleteInternalPostInput;
}


export interface MutationdeleteJobOfferArgs {
  input: DeleteJobOfferInput;
}


export interface MutationdeleteMeetingArgs {
  input: DeleteMeetingInput;
}


export interface MutationdeleteNoteArgs {
  input: DeleteNoteInput;
}


export interface MutationdeleteOrganizationalUnitArgs {
  input: DeleteOrganizationalUnitInput;
}


export interface MutationdeleteProposalArgs {
  input: DeleteProposalInput;
}


export interface MutationdeleteProposalBenefitArgs {
  input: DeleteProposalBenefitInput;
}


export interface MutationdeleteProposalBenefitPackageArgs {
  input: DeleteProposalBenefitPackageInput;
}


export interface MutationdeleteProposalMembershipArgs {
  input: DeleteProposalMembershipInput;
}


export interface MutationdeleteSharedFileArgs {
  input: DeleteSharedFileInput;
}


export interface MutationdisablePoaArgs {
  input?: InputMaybe<DisablePoaInput>;
}


export interface MutationduplicateProposalArgs {
  input: DuplicateProposalInput;
}


export interface MutationexecuteProposalArgs {
  input: ExecuteProposalInput;
}


export interface MutationflexCancelSalaryExchangeRequestArgs {
  input: FlexCancelSalaryExchangeRequestInput;
}


export interface MutationflexRequestSalaryExchangeArgs {
  input: FlexRequestSalaryExchangeInput;
}


export interface MutationflexTerminateSalaryExchangeArgs {
  input: FlexTerminateSalaryExchangeInput;
}


export interface MutationgenerateProposalArgs {
  input: GenerateProposalInput;
}


export interface MutationingestPayslipFileArgs {
  input: IngestPayslipInput;
}


export interface MutationloginArgs {
  input?: InputMaybe<LoginInput>;
}


export interface MutationmakeKycExceptionArgs {
  input: MakeKycExceptionInput;
}


export interface MutationoptOutFromPersonalAdviceArgs {
  input: FlexOptOutFromPersonalAdviceInput;
}


export interface MutationorderMovieTicketsArgs {
  input: FlexOrderMovieTicketsInput;
}


export interface MutationrejectSalaryExchangeRequestArgs {
  input: FlexRejectSalaryExchangeRequestInput;
}


export interface MutationremoveMembershipBenefitPackageArgs {
  input?: InputMaybe<RemoveMembershipBenefitPackageInput>;
}


export interface MutationremoveMembershipPropertyArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface MutationremoveProposalBenefitFromBenefitPackageArgs {
  input: RemoveProposalBenefitFromBenefitPackageInput;
}


export interface MutationrequestBonusSalaryExchangeArgs {
  input: FlexRequestSalaryExchangeInput;
}


export interface MutationresendInvoiceEmailsArgs {
  input: ResendInvoiceEmailsInput;
}


export interface MutationresendPoaArgs {
  input: ResendPoaInput;
}


export interface MutationselectPensionProviderArgs {
  input?: InputMaybe<FlexSelectPensionProviderInput>;
}


export interface MutationsendMeetingInvitationsArgs {
  input: SendMeetingInvitationsInput;
}


export interface MutationsetCompilationLookedAtArgs {
  input: ProposalIdInput;
}


export interface MutationsetFeesLookedAtArgs {
  input: ProposalIdInput;
}


export interface MutationsetFixedPremiumArgs {
  input?: InputMaybe<FlexSetFixedPremiumInput>;
}


export interface MutationsetImportVisibilityArgs {
  hidden: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
}


export interface MutationsetInsuranceDeletedArgs {
  input: SetInsuranceDeletedInput;
}


export interface MutationsetLocalePreferenceArgs {
  input: SetLocalePreferenceInput;
}


export interface MutationsetMembershipPropertyArgs {
  input?: InputMaybe<MembershipPropertyInput>;
}


export interface MutationsetProposalContactPersonArgs {
  input: SetProposalCompanyContactPersonInput;
}


export interface MutationsetProposalHeadAdminArgs {
  input: SetProposalCompanyHeadAdminInput;
}


export interface MutationsignPensionTransferTaskArgs {
  input: SignPensionTransferTaskInput;
}


export interface MutationsignPoaArgs {
  input?: InputMaybe<SignPoaInput>;
}


export interface MutationsignupFri2Args {
  input: SignupFri2Input;
}


export interface MutationstartImportArgs {
  input: StartImportInput;
}


export interface MutationstartScrapeArgs {
  input: ScrapeInput;
}


export interface MutationsubmitNannyNuFormArgs {
  input: FlexSubmitNannyNuInput;
}


export interface MutationupdateAccidentBenefitArgs {
  input: FlexUpdateAccidentBenefitInput;
}


export interface MutationupdateAccountingDimensionArgs {
  input?: InputMaybe<UpdateAccountingDimensionInput>;
}


export interface MutationupdateAccountingObjectArgs {
  input?: InputMaybe<UpdateAccountingObjectInput>;
}


export interface MutationupdateBenefitArgs {
  input: FlexUpdateBenefitInput;
}


export interface MutationupdateBenefitCategoryArgs {
  input?: InputMaybe<FlexUpdateBenefitCategoryInput>;
}


export interface MutationupdateBenefitPackageArgs {
  input?: InputMaybe<UpdateBenefitPackageInput>;
}


export interface MutationupdateBenefitSupplierArgs {
  input?: InputMaybe<FlexUpdateBenefitSupplierInput>;
}


export interface MutationupdateBookkeepingAccountArgs {
  input: UpdateBookkeepingAccountInput;
}


export interface MutationupdateCompanyArgs {
  input: UpdateCompanyInput;
}


export interface MutationupdateCompanyBillingConfigurationArgs {
  input: UpdateCompanyBillingConfigurationInput;
}


export interface MutationupdateCompanyRepresentativeArgs {
  input: UpdateCompanyRepresentativeInput;
}


export interface MutationupdateCompanyStatusArgs {
  input: UpdateCompanyStatusInput;
}


export interface MutationupdateCostCenterArgs {
  input: UpdateCostCenterInput;
}


export interface MutationupdateEconomicProfileArgs {
  input: EconomicProfileInput;
}


export interface MutationupdateEmploymentArgs {
  input: UpdateEmploymentInput;
}


export interface MutationupdateEmploymentActivityArgs {
  input: UpdateEmploymentActivityInput;
}


export interface MutationupdateFamilySituationArgs {
  input: UpdateFamilySituationInput;
}


export interface MutationupdateHealthcareBenefitArgs {
  input: FlexUpdateHealthcareBenefitInput;
}


export interface MutationupdateImportArgs {
  input: UpdateImportInput;
}


export interface MutationupdateInsuranceArgs {
  input: UpdateInsuranceInput;
}


export interface MutationupdateInsuranceAnalysisActionArgs {
  input: UpdateInsuranceAnalysisActionInput;
}


export interface MutationupdateInternalPostArgs {
  input: UpdateInternalPostInput;
}


export interface MutationupdateInvoiceStatusArgs {
  input: UpdateInvoiceStatusInput;
}


export interface MutationupdateJobOfferArgs {
  input: UpdateJobOfferInput;
}


export interface MutationupdateLifeBenefitArgs {
  input: FlexUpdateLifeBenefitInput;
}


export interface MutationupdateMembershipArgs {
  input: UpdateMembershipInput;
}


export interface MutationupdateMembershipBenefitPackageArgs {
  input?: InputMaybe<UpdateMembershipBenefitPackageInput>;
}


export interface MutationupdateMembershipCostCenterArgs {
  input: UpdateMembershipCostCenterInput;
}


export interface MutationupdateMembershipSalaryArgs {
  input: UpdateMembershipSalaryInput;
}


export interface MutationupdateNoteArgs {
  input: UpdateNoteInput;
}


export interface MutationupdateNotificationArgs {
  input: UpdateNotificationInput;
}


export interface MutationupdateOccupationalPensionBenefitArgs {
  input: FlexUpdateOccupationalPensionBenefitInput;
}


export interface MutationupdateOrganizationalUnitArgs {
  input: UpdateOrganizationalUnitInput;
}


export interface MutationupdatePensionTransferAdviceArgs {
  input: UpdatePensionTransferAdviceInput;
}


export interface MutationupdatePlusAdviceArgs {
  input: UpdatePlusAdviceInput;
}


export interface MutationupdatePlusSuggestionStatusArgs {
  input: UpdatePlusSuggestionStatusInput;
}


export interface MutationupdateProposalArgs {
  input: UpdateProposalInput;
}


export interface MutationupdateProposalAccidentBenefitArgs {
  input: UpdateProposalAccidentBenefitInput;
}


export interface MutationupdateProposalBenefitPackageArgs {
  input: UpdateProposalBenefitPackageInput;
}


export interface MutationupdateProposalEmployersPensionBenefitArgs {
  input: UpdateProposalEmployersPensionBenefitInput;
}


export interface MutationupdateProposalHealthcareBenefitArgs {
  input: UpdateProposalHealthcareBenefitInput;
}


export interface MutationupdateProposalLifeBenefitArgs {
  input: UpdateProposalLifeBenefitInput;
}


export interface MutationupdateProposalMembershipArgs {
  input: UpdateProposalMembershipInput;
}


export interface MutationupdateProposalOccupationalPensionBenefitArgs {
  input: UpdateProposalOccupationalPensionBenefitInput;
}


export interface MutationupdateProposalSalaryExchangeBenefitArgs {
  input: UpdateProposalSalaryExchangeBenefitInput;
}


export interface MutationupdateProposalSicknessBenefitArgs {
  input: UpdateProposalSicknessBenefitInput;
}


export interface MutationupdateProposalTglBenefitArgs {
  input: UpdateProposalTglBenefitInput;
}


export interface MutationupdateSalaryExchangeBenefitArgs {
  input: FlexUpdateSalaryExchangeBenefitInput;
}


export interface MutationupdateSicknessAndAccidentBenefitArgs {
  input: FlexUpdateSicknessAndAccidentBenefitInput;
}


export interface MutationupdateSicknessBenefitArgs {
  input: FlexUpdateSicknessBenefitInput;
}


export interface MutationupdateTglBenefitArgs {
  input: FlexUpdateTglBenefitInput;
}


export interface MutationupdateTotalCompensationConfigurationArgs {
  input: TotalCompensationConfigurationInput;
}


export interface MutationupdateUserAccountArgs {
  input: UpdateUserAccountInput;
}


export interface MutationuploadCompanyFileArgs {
  companyId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
}


export interface MutationuploadPdfInvoiceArgs {
  input: UploadPdfInvoiceInput;
}

export interface NLPAdministrativeFeeDiscount {
  readonly DiscountType: NLPAdministrativeFeeDiscountType;
  readonly DiscountValue?: InputMaybe<Scalars['Float']['input']>;
  /** 0 - 100, example: 0.75 = 0.75% */
  readonly FeePercentage?: InputMaybe<Scalars['Float']['input']>;
}

export enum NLPAdministrativeFeeDiscountType {
  ANNUAL_FEE_DEPOT = 'ANNUAL_FEE_DEPOT',
  ANNUAL_FEE_FUND = 'ANNUAL_FEE_FUND',
  ANNUAL_FEE_GUARANTEE = 'ANNUAL_FEE_GUARANTEE',
  CAPITAL_FEE_DEPOT = 'CAPITAL_FEE_DEPOT',
  CAPITAL_FEE_FUND = 'CAPITAL_FEE_FUND',
  CAPITAL_FEE_GUARANTEE = 'CAPITAL_FEE_GUARANTEE'
}

export interface NLPAgreementFeeDiscounts {
  readonly __typename?: 'NLPAgreementFeeDiscounts';
  readonly ANNUAL_FEE_FUND: Maybe<Scalars['Float']['output']>;
  readonly ANNUAL_FEE_GUARANTEE: Maybe<Scalars['Float']['output']>;
  readonly CAPITAL_FEE_FUND: Maybe<Scalars['Float']['output']>;
  readonly CAPITAL_FEE_GUARANTEE: Maybe<Scalars['Float']['output']>;
}

export interface NLPAgreementPensionPlanGroup {
  readonly __typename?: 'NLPAgreementPensionPlanGroup';
  readonly pensionPlanGroupExternalId: Maybe<Scalars['String']['output']>;
  readonly pensionPlanGroupName: Maybe<Scalars['String']['output']>;
}

export enum NLPFundFeeDiscountType {
  FUND_AGREEMENT_20 = 'FUND_AGREEMENT_20',
  FUND_AGREEMENT_30 = 'FUND_AGREEMENT_30',
  FUND_AGREEMENT_50 = 'FUND_AGREEMENT_50'
}

export enum NLPPaymentMethodType {
  COLLECTION_ACCOUNT_BANK_ACCOUNT = 'COLLECTION_ACCOUNT_BANK_ACCOUNT',
  COLLECTION_ACCOUNT_PLUSGIRO = 'COLLECTION_ACCOUNT_PLUSGIRO',
  /** Bankgiro */
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  INVOICE = 'INVOICE'
}

export enum NLPResponsible {
  NLP = 'NLP',
  NORDEA_NODE = 'NORDEA_NODE'
}

export interface NordeaAgreementInput {
  /** optional if createNordea */
  readonly nlpAdministrativeFeeDiscount?: InputMaybe<ReadonlyArray<InputMaybe<NLPAdministrativeFeeDiscount>>>;
  /** optional if createNordea */
  readonly nlpFundFeeDiscountType?: InputMaybe<NLPFundFeeDiscountType>;
  /** required if createNordea */
  readonly nlpStartDate: Scalars['Date']['input'];
}

export interface Note {
  readonly __typename?: 'Note';
  readonly attachments: ReadonlyArray<AttachedFile>;
  readonly author: Author;
  readonly category: NoteCategory;
  readonly content: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['Date']['output'];
  readonly id: Scalars['ID']['output'];
  readonly subject: Scalars['String']['output'];
}

export enum NoteCategory {
  ADVICE = 'ADVICE',
  AGREEMENT = 'AGREEMENT',
  ARCHIVED = 'ARCHIVED',
  INSURANCE = 'INSURANCE',
  MISC = 'MISC',
  PENSION = 'PENSION'
}

export interface NoteConnection {
  readonly __typename?: 'NoteConnection';
  readonly edges: ReadonlyArray<NoteEdge>;
  readonly pageInfo: PageInfo;
}

export interface NoteEdge {
  readonly __typename?: 'NoteEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Note;
}

export interface Notification {
  readonly __typename?: 'Notification';
  readonly actionable: Scalars['Boolean']['output'];
  /** Required for NotificationTarget.COMPANY_ADMIN */
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly createdAt: Scalars['Date']['output'];
  /** Defined by the caller to able to update or remove the same notification */
  readonly externalId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly level: NotificationLevel;
  readonly metadata: Maybe<NotificationMetadata>;
  readonly status: NotificationStatus;
  readonly statusChangedBy: Maybe<Author>;
  readonly statusUpdatedAt: Maybe<Scalars['Date']['output']>;
  /** Required if target includes NotificationTarget.COMPANY_ADMIN */
  readonly targetRoles: Maybe<ReadonlyArray<MembershipRole>>;
  readonly targets: ReadonlyArray<NotificationTarget>;
  readonly type: NotificationType;
  readonly userAccountId: Scalars['ID']['output'];
}

export interface NotificationConnection {
  readonly __typename?: 'NotificationConnection';
  readonly edges: ReadonlyArray<NotificationEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface NotificationEdge {
  readonly __typename?: 'NotificationEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Notification;
}

export interface NotificationGroup {
  readonly __typename?: 'NotificationGroup';
  readonly notifications: Maybe<NotificationConnection>;
  readonly type: NotificationType;
}


export interface NotificationGroupnotificationsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface NotificationGroupConnection {
  readonly __typename?: 'NotificationGroupConnection';
  readonly edges: ReadonlyArray<NotificationGroupEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface NotificationGroupEdge {
  readonly __typename?: 'NotificationGroupEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: NotificationGroup;
}

export enum NotificationLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export type NotificationMetadata = MaxSalaryToPensionRatioExceededMetadata | MaxTotalPensionCostExceededMetadata | MissingBenefitPackageMetadata | MissingEmailMetadata | MissingFcfwAssertionMetadata | MissingPensionPremiumMetadata | MissingSalaryMetadata | SxErrorMetadata | SxOnetimeAvailableMetadata | SxRequestApprovedMetadata | SxRequestRejectedMetadata | SxRequestedMetadata | SxViolatedMetadata;

export enum NotificationStatus {
  DISMISSED = 'DISMISSED',
  OPEN = 'OPEN'
}

export enum NotificationTarget {
  BACKSTAGE_ADMIN = 'BACKSTAGE_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  USER = 'USER'
}

/**
 * NotificationType that require companyId to be defined
 * should be added to companyRequiredTypes in validator.ts
 */
export enum NotificationType {
  MAX_SALARY_TO_PENSION_RATIO_EXCEEDED = 'MAX_SALARY_TO_PENSION_RATIO_EXCEEDED',
  MAX_TOTAL_PENSION_COST_EXCEEDED = 'MAX_TOTAL_PENSION_COST_EXCEEDED',
  MISSING_BENEFIT_PACKAGE = 'MISSING_BENEFIT_PACKAGE',
  MISSING_EMAIL = 'MISSING_EMAIL',
  MISSING_FCFW_ASSERTION = 'MISSING_FCFW_ASSERTION',
  MISSING_NAME = 'MISSING_NAME',
  MISSING_PENSION_PREMIUM = 'MISSING_PENSION_PREMIUM',
  MISSING_PENSION_PROVIDER_CHOICE = 'MISSING_PENSION_PROVIDER_CHOICE',
  MISSING_SALARY = 'MISSING_SALARY',
  NEW_ADVICE = 'NEW_ADVICE',
  RENEW_POA = 'RENEW_POA',
  SX_ERROR = 'SX_ERROR',
  SX_ONETIME_AVAILABLE = 'SX_ONETIME_AVAILABLE',
  SX_REQUESTED = 'SX_REQUESTED',
  SX_REQUEST_APPROVED = 'SX_REQUEST_APPROVED',
  SX_REQUEST_REJECTED = 'SX_REQUEST_REJECTED',
  SX_VIOLATED = 'SX_VIOLATED'
}

export interface NotificationTypeToMetadata {
  readonly __typename?: 'NotificationTypeToMetadata';
  readonly MAX_SALARY_TO_PENSION_RATIO_EXCEEDED: MaxSalaryToPensionRatioExceededMetadata;
  readonly MAX_TOTAL_PENSION_COST_EXCEEDED: MaxTotalPensionCostExceededMetadata;
  readonly MISSING_BENEFIT_PACKAGE: MissingBenefitPackageMetadata;
  readonly MISSING_EMAIL: MissingEmailMetadata;
  readonly MISSING_FCFW_ASSERTION: MissingFcfwAssertionMetadata;
  readonly MISSING_NAME: Maybe<Scalars['Undefined']['output']>;
  readonly MISSING_PENSION_PREMIUM: MissingPensionPremiumMetadata;
  readonly MISSING_PENSION_PROVIDER_CHOICE: Maybe<Scalars['Undefined']['output']>;
  readonly MISSING_SALARY: MissingSalaryMetadata;
  readonly NEW_ADVICE: Maybe<Scalars['Undefined']['output']>;
  readonly RENEW_POA: Maybe<Scalars['Undefined']['output']>;
  readonly SX_ERROR: SxErrorMetadata;
  readonly SX_ONETIME_AVAILABLE: SxOnetimeAvailableMetadata;
  readonly SX_REQUESTED: SxRequestedMetadata;
  readonly SX_REQUEST_APPROVED: SxRequestApprovedMetadata;
  readonly SX_REQUEST_REJECTED: SxRequestRejectedMetadata;
  readonly SX_VIOLATED: SxViolatedMetadata;
}

export interface OccupationalPensionChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'OccupationalPensionChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly nordeaManagementType: Maybe<FlexNordeaManagementType>;
  readonly premium: Scalars['Money']['output'];
}

export interface Order {
  readonly direction: Direction;
  readonly orderBy: OrderBy;
}

export enum OrderBy {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MONTHLY_SALARY = 'MONTHLY_SALARY',
  PERSONAL_IDENTITY_NUMBER = 'PERSONAL_IDENTITY_NUMBER'
}

export interface OrganizationalUnit {
  readonly __typename?: 'OrganizationalUnit';
  readonly company: Company;
  readonly id: Scalars['ID']['output'];
  readonly memberships: Maybe<MembershipConnection>;
  readonly name: Scalars['String']['output'];
  readonly path: Scalars['String']['output'];
  readonly slug: Scalars['String']['output'];
  readonly unitOf: Maybe<OrganizationalUnit>;
}

export interface OrganizationalUnitConnection {
  readonly __typename?: 'OrganizationalUnitConnection';
  readonly edges: ReadonlyArray<OrganizationalUnitEdge>;
  readonly pageInfo: PageInfo;
}

export interface OrganizationalUnitEdge {
  readonly __typename?: 'OrganizationalUnitEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: OrganizationalUnit;
}

export interface OrganizationalUnitItem {
  readonly __typename?: 'OrganizationalUnitItem';
  readonly from: Scalars['Date']['output'];
  readonly ou: ReadonlyArray<M2OrganizationalUnit>;
  readonly to: Maybe<Scalars['Date']['output']>;
}

/** Information about pagination in a connection. */
export interface PageInfo {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor: Maybe<Scalars['String']['output']>;
}

export enum PaymentMethod {
  AUTOGIRO = 'AUTOGIRO',
  HAMT_ACCOUNT = 'HAMT_ACCOUNT',
  HAMT_PLUSGIRO = 'HAMT_PLUSGIRO',
  INVOICE = 'INVOICE'
}

export enum PayoutEvent {
  ACCIDENT = 'ACCIDENT',
  ECONOMIC_DISABILITY = 'ECONOMIC_DISABILITY',
  MEDICAL_DISABILITY = 'MEDICAL_DISABILITY'
}

export enum PayrollElementAccountCodes {
  /**  064: Förmånscykel, bruttolöneavdrag  */
  BIKE_GROSS_SALARY_DEDUCTION = 'BIKE_GROSS_SALARY_DEDUCTION',
  /**  934: Förmånscykel, nettolöneavdrag  */
  BIKE_NET_SALARY_DEDUCTION = 'BIKE_NET_SALARY_DEDUCTION',
  /**  25001: Olycksfallsförsäkring  */
  INSURANCE_ACCIDENT = 'INSURANCE_ACCIDENT',
  /**  25003: Sjukvårdsförsäkring  */
  INSURANCE_HEALTH = 'INSURANCE_HEALTH',
  /**  71620; K-sjukförsäkring  */
  INSURANCE_K_DISABILITY = 'INSURANCE_K_DISABILITY',
  /**  25002: Livförsäkring  */
  INSURANCE_LIFE = 'INSURANCE_LIFE',
  /**  21410: Lunchkort, förmånsvärde  */
  LUNCHKORT_BENEFIT_VALUE = 'LUNCHKORT_BENEFIT_VALUE',
  /**  71410: Lunchkort, nettolöneavdrag  */
  LUNCHKORT_NET_SALARY_DEDUCTION = 'LUNCHKORT_NET_SALARY_DEDUCTION',
  /**  81002: Löneväxling, engångsbelopp  */
  ONE_TIME_SALARY_EXCHANGE = 'ONE_TIME_SALARY_EXCHANGE',
  /**  21410: Rikskortet, förmånsvärde  */
  RIKSKORTET_BENEFIT_VALUE = 'RIKSKORTET_BENEFIT_VALUE',
  /**  71410: Rikskortet, nettolöneavdrag  */
  RIKSKORTET_NET_SALARY_DEDUCTION = 'RIKSKORTET_NET_SALARY_DEDUCTION',
  /**  81001: Löneväxling  */
  SALARY_EXCHANGE = 'SALARY_EXCHANGE'
}

export interface Payslip {
  readonly __typename?: 'Payslip';
  readonly companyId: Scalars['ID']['output'];
  readonly grossSalary: Maybe<Scalars['Money']['output']>;
  readonly id: Scalars['ID']['output'];
  /** The date field reflects the date we received the payslip (effectively our creation date) */
  readonly importDate: Scalars['Date']['output'];
  readonly netSalary: Maybe<Scalars['Money']['output']>;
  /**  LON Payslip specific fields  */
  readonly paidAt: Maybe<Scalars['Date']['output']>;
  /** Defaults to HTML */
  readonly url: Scalars['String']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}


export interface PayslipurlArgs {
  format?: InputMaybe<PayslipFormat>;
}

export interface PayslipConnection {
  readonly __typename?: 'PayslipConnection';
  readonly edges: ReadonlyArray<PayslipEdge>;
  readonly pageInfo: PageInfo;
}

export interface PayslipEdge {
  readonly __typename?: 'PayslipEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Payslip;
}

export enum PayslipFormat {
  HTML = 'HTML',
  PDF = 'PDF'
}

export interface PayslipImport {
  readonly __typename?: 'PayslipImport';
  readonly filename: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly importDate: Scalars['DateTime']['output'];
  readonly status: PayslipImportStatus;
}

export interface PayslipImportConnection {
  readonly __typename?: 'PayslipImportConnection';
  readonly edges: ReadonlyArray<PayslipImportEdge>;
  readonly pageInfo: PageInfo;
}

export interface PayslipImportEdge {
  readonly __typename?: 'PayslipImportEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: PayslipImport;
}

export enum PayslipImportStatus {
  COMPLETE = 'COMPLETE',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING'
}

export enum PensionLayer {
  GENERAL = 'GENERAL',
  OCCUPATIONAL = 'OCCUPATIONAL',
  PRIVATE = 'PRIVATE'
}

export enum PensionSchemeType {
  F = 'F',
  P = 'P'
}

/**
 * A PensionTransferAdvice is considered "pending" if no destination has been set.
 * Only one pending PensionTransferAdvice is allowed per user, however an unlimited
 * number of "signed" PensionTransferTasks are allowed.
 */
export interface PensionTransferAdvice {
  readonly __typename?: 'PensionTransferAdvice';
  readonly destination: Maybe<PensionTransferDestination>;
  readonly effectOfLoss: Maybe<EffectOfLoss>;
  /** @deprecated Used for stitching insurance */
  readonly existingSourceIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly existingSources: ReadonlyArray<Insurance>;
  readonly id: Scalars['ID']['output'];
  readonly manualSources: ReadonlyArray<PensionTransferSourceManual>;
  readonly positiveImpactImportance: Maybe<PositiveImpactImportance>;
  readonly possibleDestinations: Maybe<ReadonlyArray<PensionTransferDestinationExisting>>;
  /** @deprecated Used for stitching company */
  readonly possiblePolicyholderIds: Maybe<ReadonlyArray<Scalars['ID']['output']>>;
  readonly possiblePolicyholders: ReadonlyArray<Company>;
  readonly productCapability: Maybe<ProductCapability>;
  readonly productExperience: Maybe<ProductExperience>;
  readonly rebalancePreference: Maybe<RebalancePreference>;
  readonly recommendedProduct: Maybe<PensionTransferProduct>;
  readonly reduceNegativeImpactImportance: Maybe<ReduceNegativeImpactImportance>;
  readonly riskRewardPreference: Maybe<RiskRewardPreference>;
  readonly sustainablePreference: Maybe<SustainablePreference>;
  readonly transferReasons: Maybe<ReadonlyArray<TransferReason>>;
  /** @deprecated Used for stitching insurance */
  readonly transferableInsuranceIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly transferableInsurances: ReadonlyArray<Insurance>;
}

export type PensionTransferDestination = PensionTransferDestinationExisting | PensionTransferDestinationNew;

export interface PensionTransferDestinationExisting {
  readonly __typename?: 'PensionTransferDestinationExisting';
  readonly agreementLinks: ReadonlyArray<AgreementLink>;
  /** @deprecated Used for stitching insurance */
  readonly id: Scalars['ID']['output'];
  readonly insurance: Insurance;
  readonly product: PensionTransferProduct;
}

export interface PensionTransferDestinationInput {
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly pensionTransferProduct?: InputMaybe<PensionTransferProduct>;
  readonly policyholderId?: InputMaybe<Scalars['ID']['input']>;
}

export interface PensionTransferDestinationNew {
  readonly __typename?: 'PensionTransferDestinationNew';
  readonly agreementLinks: ReadonlyArray<AgreementLink>;
  readonly policyholder: Maybe<PensionTransferPolicyholderDestination>;
  readonly policyholderId: Scalars['ID']['output'];
  readonly product: PensionTransferProduct;
}

export type PensionTransferPolicyholderDestination = Company | UserAccount;

export enum PensionTransferProduct {
  NordeaFond = 'NordeaFond',
  NordeaTrad = 'NordeaTrad',
  Nordnet = 'Nordnet'
}

export interface PensionTransferSourceManual {
  readonly __typename?: 'PensionTransferSourceManual';
  readonly instituteId: Scalars['ID']['output'];
  readonly instituteName: Scalars['String']['output'];
  readonly insuranceNumber: Scalars['String']['output'];
  readonly managementType: InsuranceManagementType;
  readonly policyholderIdentityNumber: Scalars['IdentityNumber']['output'];
  readonly policyholderName: Scalars['String']['output'];
  readonly value: Scalars['Money']['output'];
}

export interface PensionTransferSourceManualInput {
  readonly instituteId: Scalars['ID']['input'];
  readonly insuranceNumber: Scalars['String']['input'];
  readonly managementType: InsuranceManagementType;
  readonly policyholderIdentityNumber: Scalars['IdentityNumber']['input'];
  readonly policyholderName: Scalars['String']['input'];
  readonly value: Scalars['Money']['input'];
}

export interface PensionTransferStatistics {
  readonly __typename?: 'PensionTransferStatistics';
  readonly totalTransferredCapital: Scalars['Money']['output'];
  readonly transferredCapitalToInstitute: ReadonlyArray<TransferredCapitalByInstitute>;
}

export interface Person {
  readonly __typename?: 'Person';
  readonly collectiveAgreement: Maybe<Scalars['String']['output']>;
  readonly company: Company;
  readonly companyCarMonthlyValue: Maybe<Scalars['Money']['output']>;
  readonly contactInformation: PersonContactInformation;
  readonly costCenter: CostCenter;
  readonly employeeNumber: Maybe<Scalars['String']['output']>;
  readonly employerContributionShare: Maybe<Scalars['DecimalFraction']['output']>;
  readonly firstName: Scalars['String']['output'];
  readonly givenName: Scalars['String']['output'];
  readonly incomeTaxTable: Maybe<Scalars['Int']['output']>;
  readonly isOwner: Scalars['Boolean']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly monthlySalary: Maybe<Scalars['Int']['output']>;
  readonly personalIdentityNumber: Scalars['String']['output'];
  readonly status: Maybe<EmployeeStatus>;
  readonly userAccountId: Scalars['ID']['output'];
  readonly vacationDaysPaid: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysSaved: Maybe<Scalars['Int']['output']>;
  readonly vacationDaysUnpaid: Maybe<Scalars['Int']['output']>;
}

export interface PersonConnection {
  readonly __typename?: 'PersonConnection';
  readonly edges: Maybe<ReadonlyArray<Maybe<PersonEdge>>>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
}

export interface PersonContactInformation {
  readonly __typename?: 'PersonContactInformation';
  readonly email: Scalars['String']['output'];
  readonly phone: Scalars['String']['output'];
}

export interface PersonEdge {
  readonly __typename?: 'PersonEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Person;
}

export interface PersonId {
  readonly companyId: Scalars['String']['input'];
  readonly userAccountId: Scalars['String']['input'];
}

/**
 * To follow Plus advice is to take an action to move from your current
 * investments to a new target path.
 */
export interface PlusAction {
  /**
   * Short HTML description of how to follow through on this action (typically
   * a single <p>)
   * @deprecated Prefer instructions on suggestion
   */
  readonly instructions: Maybe<Scalars['String']['output']>;
  readonly targetPath: ReadonlyArray<PlusTargetPathItem>;
}


/**
 * To follow Plus advice is to take an action to move from your current
 * investments to a new target path.
 */
export interface PlusActiontargetPathArgs {
  first?: InputMaybe<Scalars['Int']['input']>;
}

/** Advice given on investments outside Advinans */
export interface PlusAdvice {
  readonly __typename?: 'PlusAdvice';
  readonly createdAt: Scalars['DateTime']['output'];
  /** Document associated with the advice */
  readonly document: Maybe<Document>;
  readonly id: Scalars['ID']['output'];
  /** If true, this advice is up-to-date */
  readonly isCurrent: Scalars['Boolean']['output'];
  readonly profile: PlusProfile;
  readonly suggestions: ReadonlyArray<PlusSuggestion>;
}

export interface PlusAdviceConnection {
  readonly __typename?: 'PlusAdviceConnection';
  readonly edges: ReadonlyArray<PlusAdviceEdge>;
}

export interface PlusAdviceEdge {
  readonly __typename?: 'PlusAdviceEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: PlusAdvice;
}

export interface PlusAllocationAction extends PlusAction {
  readonly __typename?: 'PlusAllocationAction';
  /**
   * Short HTML description of how to follow through on this action (typically
   * a single <p>)
   * @deprecated Prefer instructions on suggestion
   */
  readonly instructions: Maybe<Scalars['String']['output']>;
  readonly targetPath: ReadonlyArray<PlusTargetPathItem>;
}


export interface PlusAllocationActiontargetPathArgs {
  first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PlusPreferencesInput {
  readonly activityLevel: ActivityLevel;
  readonly riskPreference: RiskPreference;
  readonly sustainabilityCategory?: InputMaybe<SustainabilityCategory>;
  readonly sustainabilityPreferences?: InputMaybe<SustainabilityPreferencesInput>;
  readonly withdrawalPeriod: Scalars['Int']['input'];
  readonly zt: Scalars['Date']['input'];
}

/** Conditions under which Plus advice is given */
export interface PlusProfile {
  readonly __typename?: 'PlusProfile';
  readonly activityLevel: ActivityLevel;
  readonly assets: Scalars['Money']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly liabilities: Scalars['Money']['output'];
  readonly monthlyExpenses: Scalars['Money']['output'];
  readonly monthlyIncome: Scalars['Money']['output'];
  readonly riskPreference: RiskPreference;
  /** @deprecated Use `sustainabilityPreferences` */
  readonly sustainabilityCategory: SustainabilityCategory;
  /** Sustainability preferences expressed by the client */
  readonly sustainabilityPreferences: SustainabilityPreferences;
  readonly userAccountId: Scalars['ID']['output'];
  /** Amount of years to withdraw pension */
  readonly withdrawalPeriod: Scalars['Int']['output'];
  /** Date of retirement. Only year is significant, for now (January 1) */
  readonly zt: Scalars['Date']['output'];
}

export interface PlusSubscription {
  readonly __typename?: 'PlusSubscription';
  readonly activatedAt: Maybe<Scalars['DateTime']['output']>;
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly expiresAt: Maybe<Scalars['DateTime']['output']>;
  readonly id: Maybe<Scalars['ID']['output']>;
  readonly paymentId: Maybe<Scalars['ID']['output']>;
  readonly userAccountId: Scalars['ID']['output'];
}

/** For a group of items, the actions that can be taken to follow advice */
export interface PlusSuggestion {
  readonly __typename?: 'PlusSuggestion';
  readonly actions: ReadonlyArray<PlusAction>;
  readonly advice: PlusAdvice;
  /** Category of insurance that the suggestion applies to */
  readonly category: ExternalItemCategory;
  /**
   * Short HTML description of this suggestion group, if applicable. Suitable
   * for display in a single `<p>` element.
   */
  readonly description: Maybe<Scalars['String']['output']>;
  /** @deprecated Use category */
  readonly groupId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly instructions: Maybe<ReadonlyArray<PlusSuggestionInstruction>>;
  readonly insuranceIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly insurances: Maybe<ReadonlyArray<Maybe<Insurance>>>;
  /** @deprecated Use stubIds and insuranceIds */
  readonly itemIds: ReadonlyArray<Scalars['ID']['output']>;
  /**
   * Items that advice was given on. There may be null entries because items
   * can be removed after you received the suggestions (each null item
   * corresponds to a removed item).
   * @deprecated Use 'insurances' instead
   */
  readonly items: ReadonlyArray<Maybe<ExternalItem>>;
  readonly status: Maybe<PlusSuggestionStatus>;
  /** Used for stitching */
  readonly stubIds: ReadonlyArray<Scalars['ID']['output']>;
  readonly stubs: Maybe<ReadonlyArray<Maybe<ExternalItem>>>;
  /**
   * User-visible title for this suggestion, e.g.
   * "Kollektivavtalad tjänstepension (ITP 1)". Localized, if possible.
   */
  readonly title: Scalars['String']['output'];
}

export interface PlusSuggestionConnection {
  readonly __typename?: 'PlusSuggestionConnection';
  readonly edges: ReadonlyArray<PlusSuggestionEdge>;
}

export interface PlusSuggestionEdge {
  readonly __typename?: 'PlusSuggestionEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: PlusSuggestion;
}

/** An instruction step in order to follow a suggestion */
export interface PlusSuggestionInstruction {
  readonly __typename?: 'PlusSuggestionInstruction';
  readonly action: Maybe<PlusAction>;
  readonly description: Scalars['String']['output'];
  readonly linkText: Maybe<Scalars['String']['output']>;
  readonly linkUrl: Maybe<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
}

export enum PlusSuggestionStatus {
  COMPLETED = 'COMPLETED'
}

export interface PlusSwitchAction extends PlusAction {
  readonly __typename?: 'PlusSwitchAction';
  readonly canTransfer: Scalars['Boolean']['output'];
  /**
   * Short HTML description of how to follow through on this action (typically
   * a single <p>)
   * @deprecated Prefer instructions on suggestion
   */
  readonly instructions: Maybe<Scalars['String']['output']>;
  readonly targetPath: ReadonlyArray<PlusTargetPathItem>;
  readonly transferFeeFixed: Maybe<Scalars['String']['output']>;
}


export interface PlusSwitchActiontargetPathArgs {
  first?: InputMaybe<Scalars['Int']['input']>;
}

export interface PlusTarget {
  readonly __typename?: 'PlusTarget';
  readonly allocation: ReadonlyArray<PlusTargetAllocationItem>;
  readonly institute: Institute;
  readonly instituteId: Scalars['ID']['output'];
  readonly managementType: Maybe<InsuranceManagementType>;
  /** @deprecated Not used anymore */
  readonly qualityScore: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use risk indicator on security (banking) */
  readonly riskScore: Maybe<Scalars['Int']['output']>;
  /** @deprecated Not used anymore */
  readonly sustainabilityScore: Maybe<Scalars['Int']['output']>;
}

export interface PlusTargetAllocationItem {
  readonly __typename?: 'PlusTargetAllocationItem';
  readonly fund: Maybe<Fund>;
  readonly internalCode: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  /**
   * Annual fractional ongoing charge, the sum of management fee and operational
   * costs
   */
  readonly ongoingCharge: Maybe<Scalars['DecimalFraction']['output']>;
  readonly share: Scalars['DecimalFraction']['output'];
}

export interface PlusTargetPathItem {
  readonly __typename?: 'PlusTargetPathItem';
  readonly date: Scalars['Date']['output'];
  readonly target: PlusTarget;
}

export interface Poa {
  readonly __typename?: 'Poa';
  readonly disabled: Scalars['Boolean']['output'];
  readonly effective: Scalars['DateRange']['output'];
  /** User that signed this Power of attorney */
  readonly userAccount: UserAccount;
}

export interface Position {
  readonly __typename?: 'Position';
  readonly date: Scalars['Date']['output'];
  /**
   * 1-7 efficiency score (compensated risk)
   * @deprecated Not used anymore
   */
  readonly qualityScore: Maybe<Scalars['Int']['output']>;
  /**
   * 1-7 risk score (EU standard)
   * @deprecated Use risk indicator from banking
   */
  readonly riskScore: Maybe<Scalars['Int']['output']>;
  readonly shares: ReadonlyArray<ShareItem>;
  /**
   * 1-7 weighted sustainability score
   * @deprecated Not used anymore
   */
  readonly sustainabilityScore: Maybe<Scalars['Int']['output']>;
}

/** Shares held in each security at a point in time */
export interface PositionInput {
  /** Required if sending more than one position. Otherwise, assumed the first day of next month. Always format as first day of the month */
  readonly date?: InputMaybe<Scalars['Date']['input']>;
  readonly shares: ReadonlyArray<ShareInput>;
}

/** Hur viktigt är det för dig att dina investeringar bidrar positivt till miljö och samhälle? */
export enum PositiveImpactImportance {
  /** Viktigt */
  IMPORTANT = 'IMPORTANT',
  /** Inte viktigt */
  NOT_IMPORTANT = 'NOT_IMPORTANT'
}

export interface PremiumHistory {
  readonly __typename?: 'PremiumHistory';
  readonly effective: Scalars['DateRange']['output'];
  readonly insuranceId: Scalars['ID']['output'];
  readonly status: InsurancePremiumStatus;
  readonly value: Maybe<Scalars['Float']['output']>;
}

export interface PremiumHistoryConnection {
  readonly __typename?: 'PremiumHistoryConnection';
  readonly edges: ReadonlyArray<PremiumHistoryEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface PremiumHistoryEdge {
  readonly __typename?: 'PremiumHistoryEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: PremiumHistory;
}

export enum PremiumOrBenefitDefined {
  BENEFIT = 'BENEFIT',
  PREMIUM = 'PREMIUM'
}

export interface PriceItem {
  readonly __typename?: 'PriceItem';
  readonly at: Scalars['Date']['output'];
  readonly value: Scalars['Money']['output'];
}

export enum PrincipalAdverseIndicatorEnvironmental {
  BIODIVERSITY_SENSITIVE_AREAS = 'BIODIVERSITY_SENSITIVE_AREAS',
  CARBON_FOOTPRINT = 'CARBON_FOOTPRINT',
  ENERGY_INEFFICIENT_REAL_ESTATE = 'ENERGY_INEFFICIENT_REAL_ESTATE',
  FOSSIL_FUELS = 'FOSSIL_FUELS',
  FOSSIL_FUELS_THROUGH_REAL_ESTATE = 'FOSSIL_FUELS_THROUGH_REAL_ESTATE',
  GHG_EMISSIONS = 'GHG_EMISSIONS',
  GHG_INTENSITY = 'GHG_INTENSITY',
  HAZARDOUS_WASTE = 'HAZARDOUS_WASTE',
  HIGH_IMPACT_CLIMATE_SECTOR_ENERGY = 'HIGH_IMPACT_CLIMATE_SECTOR_ENERGY',
  NON_RENEWABLE_ENERGY = 'NON_RENEWABLE_ENERGY',
  WATER_EMISSIONS = 'WATER_EMISSIONS'
}

export enum PrincipalAdverseIndicatorSocial {
  BOARD_GENDER_DIVERSITY = 'BOARD_GENDER_DIVERSITY',
  CONTROVERSIAL_WEAPONS = 'CONTROVERSIAL_WEAPONS',
  GENDER_PAY_GAP = 'GENDER_PAY_GAP',
  SOCIAL_VIOLATION_COUNTRIES = 'SOCIAL_VIOLATION_COUNTRIES',
  UN_OECD_MONITORING = 'UN_OECD_MONITORING',
  UN_OECD_VIOLATIONS = 'UN_OECD_VIOLATIONS'
}

/** Vilket uttalande om investeringsmöjligheter stämmer bäst överens med dina preferenser? */
export enum ProductCapability {
  /** Jag vill bara investera i fonder */
  FUNDS = 'FUNDS',
  /** Jag vill investera i ett så brett utbud av värdepapper och fonder som möjligt, inklusive aktier */
  STOCKS = 'STOCKS'
}

export enum ProductExperience {
  HAS_DEPA_EXPERIENCE = 'HAS_DEPA_EXPERIENCE',
  HAS_DEPA_UNDERSTANDING = 'HAS_DEPA_UNDERSTANDING',
  HAS_FUND_EXPERIENCE = 'HAS_FUND_EXPERIENCE',
  HAS_FUND_UNDERSTANDING = 'HAS_FUND_UNDERSTANDING',
  HAS_TRAD_EXPERIENCE = 'HAS_TRAD_EXPERIENCE',
  HAS_TRAD_UNDERSTANDING = 'HAS_TRAD_UNDERSTANDING'
}

export interface ProductKnowledge {
  readonly __typename?: 'ProductKnowledge';
  readonly adviceSavingsInsurance: Maybe<Scalars['Boolean']['output']>;
  readonly notes: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionFee: Maybe<Scalars['Boolean']['output']>;
  readonly pensionInstrumentKnowledge: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionInstruments: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionPayoutAge: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionReturns: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionRisk: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionTraditional: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionTraditionalFee: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove after checking with fe */
  readonly pensionTraditionalRisk: Maybe<Scalars['Boolean']['output']>;
  readonly professionalExperiencePension: Maybe<Scalars['Boolean']['output']>;
  readonly recentExperiencePension: Maybe<Scalars['Boolean']['output']>;
}

export interface ProductKnowledgeInput {
  readonly adviceSavingsInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly pensionInstrumentKnowledge?: InputMaybe<Scalars['Boolean']['input']>;
  readonly professionalExperiencePension?: InputMaybe<Scalars['Boolean']['input']>;
  readonly recentExperiencePension?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface Progress {
  readonly __typename?: 'Progress';
  readonly done: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
}

export enum ProgressStatus {
  /** Import has completed without errors. */
  COMPLETE = 'COMPLETE',
  /**
   * Import has completed with errors. Import can have failed in any state.
   * Import may be partially finished
   */
  FAILURE = 'FAILURE',
  /** Changes has been generated for the import and can be run, or replaced */
  GENERATED_CHANGES = 'GENERATED_CHANGES',
  /** Change diff is being generated for import */
  GENERATING_CHANGES = 'GENERATING_CHANGES',
  /** Import has been processed and been determined to not contain any changes. */
  NO_CHANGES = 'NO_CHANGES',
  /** Import is created but change diff is not generated */
  PENDING = 'PENDING',
  /** Import is processing the input file */
  PROCESSING = 'PROCESSING',
  /** Import is being executed. Some records may have finished */
  WORKING = 'WORKING'
}

export interface Projection {
  readonly __typename?: 'Projection';
  readonly date: Scalars['Date']['output'];
  /** Deposits made during this period, after value */
  readonly deposit: Scalars['Money']['output'];
  /** Cumulative amount invested up to start of this period */
  readonly invested: Scalars['Money']['output'];
  /** Percentiles (5, 10, 33, 67, 90, 95) */
  readonly percentiles: ReadonlyArray<Scalars['Money']['output']>;
  /** Expected value (median) */
  readonly value: Scalars['Money']['output'];
}

export interface ProjectionFees {
  readonly __typename?: 'ProjectionFees';
  /** Annual management fee in basis points of the underlying allocations */
  readonly fundsFee: Maybe<Scalars['Int']['output']>;
  /** Annual management fee in basis points charged for advisory services, if applicable */
  readonly managementFee: Maybe<Scalars['Int']['output']>;
  /** Annual service fee in basis points charged for maintaining the account, if applicable */
  readonly serviceFee: Maybe<Scalars['Int']['output']>;
  /** Combined yearly fee on capital in basis points */
  readonly totalFee: Maybe<Scalars['Int']['output']>;
}

export interface ProjectionItem {
  readonly __typename?: 'ProjectionItem';
  /** Date when the projection applies */
  readonly date: Scalars['Date']['output'];
  /** Defaults to median value. Percentile can currently be one of 5, 10, 33, 50, 67, 90, 95 */
  readonly estimatedValue: Scalars['Money']['output'];
  /** Invested amount thus far */
  readonly invested: Scalars['Money']['output'];
}


export interface ProjectionItemestimatedValueArgs {
  percentile?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectionItemCapitalReportUnit {
  readonly __typename?: 'ProjectionItemCapitalReportUnit';
  /** Date when the projection applies */
  readonly date: Scalars['Date']['output'];
  /** Defaults to median value. Percentile can currently be one of 5, 10, 33, 50, 67, 90, 95 */
  readonly estimatedValue: Scalars['Money']['output'];
  /** Invested amount thus far */
  readonly invested: Scalars['Money']['output'];
}


export interface ProjectionItemCapitalReportUnitestimatedValueArgs {
  percentile?: InputMaybe<Scalars['Int']['input']>;
}

export interface Proposal {
  readonly __typename?: 'Proposal';
  /** If true, the need and product knowledge fields ar not required on the proposal */
  readonly adviceWaived: ProposalAdviceOption;
  readonly availableBenefitTypes: ReadonlyArray<ProposalBenefitType>;
  readonly benefit: ProposalBenefit;
  readonly benefitPackages: ReadonlyArray<ProposalBenefitPackage>;
  readonly benefits: ReadonlyArray<ProposalBenefit>;
  readonly companyId: Scalars['ID']['output'];
  readonly companySize: ProposalCompanySize;
  /** The time when proposal compilation was read */
  readonly compilationLookedAt: Maybe<Scalars['DateTime']['output']>;
  /** Calculates cost for the proposal, requires that benefits, memberships and fees are valid. */
  readonly cost: Maybe<ProposalCost>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly createdBy: AdminUser;
  readonly createdById: Scalars['ID']['output'];
  readonly documents: Maybe<AgreementDocumentRecord>;
  /** The time when proposal was executed */
  readonly executedAt: Maybe<Scalars['DateTime']['output']>;
  readonly fees: ProposalFees;
  /** The time when proposal was generated */
  readonly generatedAt: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastEditedBy: AdminUser;
  readonly lastEditedById: Scalars['ID']['output'];
  readonly memberships: ReadonlyArray<ProposalMembership>;
  readonly name: Scalars['String']['output'];
  readonly need: Maybe<ProposalNeed>;
  readonly productKnowledge: Maybe<ProductKnowledge>;
  readonly representatives: ReadonlyArray<CompanyRepresentative>;
  /** Start date of the proposal */
  readonly startDate: Maybe<Scalars['Date']['output']>;
  readonly status: ProposalStatus;
  readonly summary: Maybe<ProposalSummary>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly validity: ProposalValidity;
}


export interface ProposalbenefitArgs {
  id: Scalars['ID']['input'];
}


export interface ProposalbenefitsArgs {
  input?: InputMaybe<ProposalBenefitFilterInput>;
}

export interface ProposalAccidentBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalAccidentBenefitConfig';
  readonly accidentLevel: ProposalAccidentLevel;
  readonly benefitTypeName: Scalars['String']['output'];
}

export enum ProposalAccidentLevel {
  PBB20 = 'PBB20',
  PBB30 = 'PBB30',
  PBB40 = 'PBB40',
  PBB50 = 'PBB50'
}

export enum ProposalAdviceOption {
  ADVICE_REQUIRED = 'ADVICE_REQUIRED',
  ADVICE_WAIVED_CUSTOMER_INITIATED = 'ADVICE_WAIVED_CUSTOMER_INITIATED',
  ADVICE_WAIVED_OWNER = 'ADVICE_WAIVED_OWNER'
}

export enum ProposalBaseAmountType {
  IBB = 'IBB',
  PBB = 'PBB'
}

export interface ProposalBenefit {
  readonly __typename?: 'ProposalBenefit';
  readonly benefitPackages: ReadonlyArray<ProposalBenefitPackage>;
  readonly configuration: ProposalBenefitConfig;
  readonly id: Scalars['ID']['output'];
  readonly proposalId: Scalars['ID']['output'];
  readonly type: ProposalBenefitType;
}

export interface ProposalBenefitConfig {
  readonly benefitTypeName: Scalars['String']['output'];
}

export interface ProposalBenefitFilterInput {
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}

export interface ProposalBenefitPackage {
  readonly __typename?: 'ProposalBenefitPackage';
  readonly benefits: ReadonlyArray<ProposalBenefit>;
  readonly cost: Maybe<BenefitPackageCost>;
  readonly id: Scalars['String']['output'];
  readonly memberships: ReadonlyArray<ProposalMembership>;
  readonly name: Scalars['String']['output'];
  readonly proposalId: Scalars['ID']['output'];
}

export enum ProposalBenefitType {
  EMPLOYERS_PENSION = 'EMPLOYERS_PENSION',
  OCCUPATIONAL_PENSION = 'OCCUPATIONAL_PENSION',
  RISK_ACCIDENT = 'RISK_ACCIDENT',
  RISK_HEALTHCARE = 'RISK_HEALTHCARE',
  RISK_LIFE = 'RISK_LIFE',
  RISK_SICKNESS = 'RISK_SICKNESS',
  RISK_TGL = 'RISK_TGL',
  SALARY_EXCHANGE = 'SALARY_EXCHANGE'
}

export interface ProposalCompanyContactPerson {
  readonly __typename?: 'ProposalCompanyContactPerson';
  readonly companyId: Scalars['ID']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly lastEditedBy: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly personalIdentityNumber: Maybe<Scalars['NaturalPersonIdentifier']['output']>;
  readonly phoneNumber: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
}

export interface ProposalCompanyHeadAdmin {
  readonly __typename?: 'ProposalCompanyHeadAdmin';
  readonly companyId: Scalars['ID']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly lastEditedBy: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['output'];
  readonly phoneNumber: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
}

export enum ProposalCompanySize {
  GTE_50 = 'GTE_50',
  GTE_250 = 'GTE_250',
  GTE_1000 = 'GTE_1000',
  LT_10 = 'LT_10',
  LT_50 = 'LT_50'
}

export interface ProposalConnection {
  readonly __typename?: 'ProposalConnection';
  readonly edges: ReadonlyArray<ProposalEdge>;
  readonly pageInfo: PageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface ProposalCost {
  readonly __typename?: 'ProposalCost';
  /** The total employers pension cost */
  readonly employersPension: Maybe<Scalars['Money']['output']>;
  /** The total occupational pension cost */
  readonly occupationalPension: Maybe<Scalars['Money']['output']>;
  /** The total accident cost */
  readonly riskAccident: Maybe<Scalars['Money']['output']>;
  /** The total healthcare cost */
  readonly riskHealthcare: Maybe<Scalars['Money']['output']>;
  /** The total life cost */
  readonly riskLife: Maybe<Scalars['Money']['output']>;
  /** The total pension premium waiver cost */
  readonly riskPensionPremiumWaiver: Maybe<Scalars['Money']['output']>;
  /** The total sickness cost */
  readonly riskSickness: Maybe<Scalars['Money']['output']>;
  /** The total TGL cost */
  readonly riskTgl: Maybe<Scalars['Money']['output']>;
  /** The total risk cost */
  readonly riskTotal: Maybe<Scalars['Money']['output']>;
  /** The total salary exchange cost, returns 0 since salary exchange is cost neutral for the employer. Null if no salary exchange benefit is preset */
  readonly salaryExchange: Maybe<Scalars['Money']['output']>;
  /** The total cost for the proposal */
  readonly total: Maybe<Scalars['Money']['output']>;
}

export enum ProposalDefaultBenefitPackage {
  EMPLOYEE_WITH_RISK = 'EMPLOYEE_WITH_RISK',
  OWNER_FIXED_PREMIUM = 'OWNER_FIXED_PREMIUM',
  OWNER_OR_CEO_FULL_PROTECTION = 'OWNER_OR_CEO_FULL_PROTECTION',
  PROBATIONARY_EMPLOYEE = 'PROBATIONARY_EMPLOYEE',
  SICKNESS_ONLY = 'SICKNESS_ONLY'
}

export enum ProposalDefaultManagementType {
  FUND = 'FUND',
  PORTFOLIO = 'PORTFOLIO',
  TRAD = 'TRAD'
}

export interface ProposalEdge {
  readonly __typename?: 'ProposalEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: Proposal;
}

export interface ProposalEmployersPensionBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig';
  readonly benefitTypeName: Scalars['String']['output'];
  readonly convertingPremiumWaiverFrom: Maybe<Scalars['String']['output']>;
  readonly defaultManagementType: ProposalDefaultManagementType;
  readonly extraFixedPremium: Scalars['Boolean']['output'];
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod;
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>;
  readonly premiumMatrix: Maybe<ProposalPensionPremiumMatrix>;
  readonly premiumWaiver: Scalars['Boolean']['output'];
  readonly retirementAge: Scalars['Int']['output'];
}

export interface ProposalFees {
  readonly __typename?: 'ProposalFees';
  readonly annualFeeFund: Scalars['Boolean']['output'];
  readonly annualFeePortfolio: Scalars['Boolean']['output'];
  readonly annualFeeTraditional: Scalars['Boolean']['output'];
  readonly annualFixedFee: Scalars['Money']['output'];
  readonly annualFixedFeeFund: Scalars['Money']['output'];
  readonly annualFixedFeePortfolio: Scalars['Money']['output'];
  readonly annualFixedFeeTraditional: Scalars['Money']['output'];
  readonly capitalFeeFund: Maybe<Scalars['BasisPoint']['output']>;
  readonly capitalFeePortfolio: Maybe<Scalars['BasisPoint']['output']>;
  readonly capitalFeeTraditional: Maybe<Scalars['BasisPoint']['output']>;
  readonly fundDiscountPermitted: Scalars['Boolean']['output'];
  readonly fundDiscountValue: Maybe<Scalars['Float']['output']>;
  readonly fundRange: ReadonlyArray<Scalars['BasisPoint']['output']>;
  readonly portfolioRange: ReadonlyArray<Scalars['BasisPoint']['output']>;
  readonly provisionWaiverAllowed: Scalars['Boolean']['output'];
  readonly provisionWaiverHealthcare: Maybe<Scalars['Float']['output']>;
  readonly provisionWaiverPremiumWaiver: Maybe<Scalars['Float']['output']>;
  readonly provisionWaiverSickness: Maybe<Scalars['Float']['output']>;
  readonly riskBucket: Maybe<RiskBucket>;
}

export interface ProposalFeesInput {
  readonly annualFeeFund?: InputMaybe<Scalars['Boolean']['input']>;
  readonly annualFeePortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  readonly annualFeeTraditional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Number of BasisPoints, 1 translates to 0.01% */
  readonly capitalFeeFund?: InputMaybe<Scalars['BasisPoint']['input']>;
  /** Number of BasisPoints, 1 translates to 0.01% */
  readonly capitalFeePortfolio?: InputMaybe<Scalars['BasisPoint']['input']>;
  /** Number of BasisPoints, 1 translates to 0.01% */
  readonly capitalFeeTraditional?: InputMaybe<Scalars['BasisPoint']['input']>;
  readonly fundDiscountPermitted?: InputMaybe<Scalars['Boolean']['input']>;
  /** One of 0.2, 0.3, 0.5 representing 20%, 30%, 50% discount */
  readonly fundDiscountValue?: InputMaybe<Scalars['Float']['input']>;
  readonly provisionWaiverAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** One of 0, 0.5, 1 (no waiver, half waiver, full waiver) */
  readonly provisionWaiverHealthcare?: InputMaybe<Scalars['Float']['input']>;
  /** One of 0, 0.5, 1 (no waiver, half waiver, full waiver) */
  readonly provisionWaiverPremiumWaiver?: InputMaybe<Scalars['Float']['input']>;
  /** One of 0, 0.5, 1 (no waiver, half waiver, full waiver) */
  readonly provisionWaiverSickness?: InputMaybe<Scalars['Float']['input']>;
  readonly riskBucket?: InputMaybe<RiskBucket>;
}

export interface ProposalHealthcareBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalHealthcareBenefitConfig';
  readonly benefitTypeName: Scalars['String']['output'];
  readonly converting: Scalars['Boolean']['output'];
  readonly convertingFrom: Maybe<Scalars['String']['output']>;
  readonly healthcareLevel: ProposalHealthcareLevel;
  readonly waitingPeriod: Scalars['Boolean']['output'];
}

export enum ProposalHealthcareLevel {
  BRONS_0 = 'BRONS_0',
  BRONS_500 = 'BRONS_500',
  GOLD = 'GOLD',
  L0 = 'L0',
  L500 = 'L500',
  L750 = 'L750',
  L1000 = 'L1000',
  L1500 = 'L1500'
}

export interface ProposalIdInput {
  readonly proposalId: Scalars['ID']['input'];
}

export interface ProposalInput {
  readonly adviceWaived?: InputMaybe<ProposalAdviceOption>;
  readonly companySize?: InputMaybe<ProposalCompanySize>;
  readonly fees?: InputMaybe<ProposalFeesInput>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
}

export interface ProposalLifeBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalLifeBenefitConfig';
  readonly agreementType: LifeAgreementType;
  readonly benefitTypeName: Scalars['String']['output'];
  readonly lifeLevel: ProposalLifeLevel;
}

export enum ProposalLifeLevel {
  PBB5 = 'PBB5',
  PBB10 = 'PBB10',
  PBB15 = 'PBB15',
  PBB20 = 'PBB20',
  PBB25 = 'PBB25',
  PBB30 = 'PBB30',
  PBB35 = 'PBB35',
  PBB40 = 'PBB40',
  PBB45 = 'PBB45',
  PBB50 = 'PBB50'
}

export interface ProposalMembership {
  readonly __typename?: 'ProposalMembership';
  readonly age: Scalars['Int']['output'];
  readonly benefitPackage: ProposalBenefitPackage;
  readonly cost: Maybe<Cost>;
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  /** Whether the user is fully capable for work or not, null before being set */
  readonly isFcfw: Maybe<Scalars['Boolean']['output']>;
  /** Whether the user is the owner of the company or not, defaults to false */
  readonly isOwner: Scalars['Boolean']['output'];
  readonly lastName: Scalars['String']['output'];
  /** Set if the admin wants the user to have fixed premium. Either on top of their proportional or on a fixed premium benefit. */
  readonly monthlyFixedPensionPremium: Maybe<Scalars['Money']['output']>;
  readonly monthlySalary: Scalars['Money']['output'];
  /** Coordination number or swedish personal identity number */
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['output'];
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly proposalId: Scalars['ID']['output'];
}

export interface ProposalNeed {
  readonly __typename?: 'ProposalNeed';
  readonly collectiveAgreementBlueCollar: Maybe<CollectiveAgreementOption>;
  readonly collectiveAgreementWhiteCollar: Maybe<CollectiveAgreementOption>;
  readonly documentationProvided: Maybe<Scalars['Boolean']['output']>;
  readonly text: Maybe<Scalars['String']['output']>;
}

export interface ProposalNeedInput {
  readonly collectiveAgreementBlueCollar?: InputMaybe<CollectiveAgreementOption>;
  readonly collectiveAgreementWhiteCollar?: InputMaybe<CollectiveAgreementOption>;
  readonly documentationProvided?: InputMaybe<Scalars['Boolean']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
}

export interface ProposalOccupationalPensionBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig';
  readonly benefitTypeName: Scalars['String']['output'];
  readonly convertingPremiumWaiverFrom: Maybe<Scalars['String']['output']>;
  readonly defaultManagementType: ProposalDefaultManagementType;
  readonly extraFixedPremium: Scalars['Boolean']['output'];
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod;
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>;
  readonly premiumMatrix: Maybe<ProposalPensionPremiumMatrix>;
  readonly premiumWaiver: Scalars['Boolean']['output'];
  readonly retirementAge: Scalars['Int']['output'];
}

export interface ProposalPensionPremiumMatrix {
  readonly __typename?: 'ProposalPensionPremiumMatrix';
  readonly age: ReadonlyArray<Scalars['Int']['output']>;
  readonly baseAmount: ReadonlyArray<Scalars['String']['output']>;
  readonly baseAmountType: ProposalBaseAmountType;
  readonly intervals: ReadonlyArray<ReadonlyArray<Scalars['String']['output']>>;
  readonly salaryMultiplier: Scalars['Float']['output'];
}

export interface ProposalPensionPremiumMatrixInput {
  readonly age: ReadonlyArray<Scalars['Int']['input']>;
  readonly baseAmount: ReadonlyArray<Scalars['String']['input']>;
  readonly baseAmountType: ProposalBaseAmountType;
  readonly intervals: ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>;
  /** This field is the same as pensionable annual salary factor. It must be either 12 or 12.2 */
  readonly salaryMultiplier: Scalars['Float']['input'];
}

export enum ProposalPremiumCalculationMethod {
  CUSTOM_MATRIX = 'CUSTOM_MATRIX',
  FIXED_PREMIUM_ONLY = 'FIXED_PREMIUM_ONLY',
  ITP_MATRIX = 'ITP_MATRIX'
}

export enum ProposalPremiumDeductions {
  PREMIUM_WAIVER = 'PREMIUM_WAIVER',
  SICKNESS_INSURANCE = 'SICKNESS_INSURANCE'
}

export interface ProposalSalaryExchangeBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig';
  readonly automaticApproval: Scalars['Boolean']['output'];
  readonly benefitTypeName: Scalars['String']['output'];
  readonly incomeMinIBB: Scalars['Float']['output'];
  readonly ongoingMax: Scalars['Money']['output'];
  readonly pensionTaxBenefitShare: Scalars['Float']['output'];
  readonly pensionToSalaryMax: Scalars['Float']['output'];
  readonly premiumWaiver: Scalars['Boolean']['output'];
  readonly retirementAge: Scalars['Int']['output'];
  readonly salaryMultiplier: Scalars['Float']['output'];
}

export interface ProposalSicknessBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalSicknessBenefitConfig';
  readonly benefitTypeName: Scalars['String']['output'];
  readonly converting: Scalars['Boolean']['output'];
  readonly convertingFrom: Maybe<Scalars['String']['output']>;
  /** Indicates if the salary limit can be extended for 1-9 insured. Required for CompanySize.LT_10 */
  readonly extendSalaryLimit: Maybe<Scalars['Boolean']['output']>;
  readonly extendedSickPay: Scalars['Boolean']['output'];
  readonly salaryMultiplier: Scalars['Float']['output'];
  readonly sicknessLevel: ProposalSicknessLevel;
}

export enum ProposalSicknessLevel {
  CONSOLIDATION = 'CONSOLIDATION',
  HYBRID = 'HYBRID',
  ITP = 'ITP',
  KOMP = 'KOMP',
  MAX = 'MAX'
}

export enum ProposalStatus {
  GENERATED = 'GENERATED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  STARTED = 'STARTED'
}

export enum ProposalStatusFilter {
  GENERATED = 'GENERATED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  NO_PROPOSALS = 'NO_PROPOSALS',
  STARTED = 'STARTED'
}

export interface ProposalSummary {
  readonly __typename?: 'ProposalSummary';
  readonly checkDeductionSpace: Scalars['Boolean']['output'];
  readonly checkExistingCoverage: Scalars['Boolean']['output'];
  readonly checkFinancialSituation: Scalars['Boolean']['output'];
  readonly checkNeed: Scalars['Boolean']['output'];
  readonly checkPlannedDevelopment: Scalars['Boolean']['output'];
  readonly format: Maybe<ProposalSummaryFormat>;
  readonly text: Maybe<Scalars['String']['output']>;
}

export enum ProposalSummaryFormat {
  DIGITAL = 'DIGITAL',
  PAPER = 'PAPER'
}

export interface ProposalSummaryInput {
  readonly checkDeductionSpace?: InputMaybe<Scalars['Boolean']['input']>;
  readonly checkExistingCoverage?: InputMaybe<Scalars['Boolean']['input']>;
  readonly checkFinancialSituation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly checkNeed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly checkPlannedDevelopment?: InputMaybe<Scalars['Boolean']['input']>;
  readonly format?: InputMaybe<ProposalSummaryFormat>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
}

export interface ProposalTglBenefitConfig extends ProposalBenefitConfig {
  readonly __typename?: 'ProposalTglBenefitConfig';
  readonly benefitTypeName: Scalars['String']['output'];
}

export interface ProposalValidity {
  readonly __typename?: 'ProposalValidity';
  /** Check if allowed to download the price proposal. This is true if at least proposal fees, memberships and benefits are valid */
  readonly isPriceProposalDownloadAllowed: ValidityObject;
  /** Check if the proposal benefits is valid */
  readonly validBenefits: ValidityObject;
  /** Check if the proposal compilation is valid */
  readonly validCompilation: ValidityObject;
  /** Check if the proposal membership is valid */
  readonly validEmployees: ValidityObject;
  /** Check if the proposal fees is valid */
  readonly validFees: ValidityObject;
  /** Check if the proposal need is valid */
  readonly validNeed: ValidityObject;
  /** Check if the proposal product knowledge is valid */
  readonly validProductKnowledge: ValidityObject;
  /** Check if the whole proposal is valid. This is true if all other valid* fields are true. */
  readonly validProposal: ValidityObject;
  /** Check if the proposal need is valid */
  readonly validSummary: ValidityObject;
}

export interface ProposalsFilterArguments {
  readonly statuses: ReadonlyArray<ProposalStatus>;
}

export interface ProposalsSortingArguments {
  readonly direction: SortingDirection;
  readonly field: SortingFields;
}

export enum PurchaseContext {
  INSURANCE = 'INSURANCE',
  PENSION = 'PENSION'
}

export interface Query {
  readonly __typename?: 'Query';
  /** @deprecated Merged into membership */
  readonly M2Membership: Maybe<M2Membership>;
  /** @deprecated Merged into membership */
  readonly M2Memberships: Maybe<M2MembershipConnection>;
  readonly accountingDimensions: ReadonlyArray<AccountingDimension>;
  readonly accountingObject: Maybe<AccountingObject>;
  readonly accountingObjects: Maybe<AccountingObjectConnection>;
  readonly adminViewer: Maybe<AdminUser>;
  readonly advinansFundOffering: ReadonlyArray<Maybe<FundOffering>>;
  readonly advisors: ReadonlyArray<Advisor>;
  readonly agreement: Maybe<Agreement>;
  readonly agreementRequestStaticAttachments: Maybe<ReadonlyArray<AgreementAttachment>>;
  readonly agreements: ReadonlyArray<Agreement>;
  /** Returns a list of all benchmarks available on the Advinans platform */
  readonly availableBenchmarks: ReadonlyArray<Benchmark>;
  readonly bankIdTicketStatus: Maybe<FlexTicketStatus>;
  readonly benefitCategories: ReadonlyArray<FlexBenefitCategory>;
  readonly benefitPackage: Maybe<BenefitPackage>;
  readonly benefitPackageBenefitHistory: Maybe<FlexBenefitPackageHistoryConnection>;
  readonly benefitPackages: Maybe<BenefitPackageConnection>;
  /** Query all benefit types that benefits can be created from */
  readonly benefitTypes: Maybe<FlexBenefitTypeConnection>;
  /** Returns the latest active bonus salary exchange for a specific user. */
  readonly bonusSalaryExchange: Maybe<FlexSalaryExchange>;
  readonly bonusSalaryExchangeAdvice: Maybe<FlexSalaryExchangeAdvice>;
  /** Same as salaryExchangeRequest but for bonus salary exchange requests. See documentation on salaryExchangeRequest for more information. */
  readonly bonusSalaryExchangeRequest: Maybe<FlexSalaryExchangeRequest>;
  /** Same as salaryExchangeRequests but for bonus salary exchange requests. See documentation on salaryExchangeRequests for more information. */
  readonly bonusSalaryExchangeRequests: Maybe<FlexSalaryExchangeRequestConnection>;
  /** Same as salaryExchangeRequestsHistory but for bonus salary exchange. See documentation on salaryExchangeRequestsHistory for more information. */
  readonly bonusSalaryExchangeRequestsHistory: Maybe<FlexSalaryExchangeRequestsHistoryConnection>;
  /** Returns all bonus salary exchange for users in a specific company. */
  readonly bonusSalaryExchanges: FlexSalaryExchangeConnection;
  readonly bookingLink: BookingLink;
  readonly changes: Maybe<ReadonlyArray<FlexChange>>;
  readonly changesHistory: Maybe<FlexChangeHistoryConnection>;
  /** Get all clearing codes. Search string must be at least three characters. */
  readonly clearingCodes: ReadonlyArray<ClearingCode>;
  readonly companies: Maybe<CompanyConnection>;
  /** @deprecated No longer supported */
  readonly companiesByUserAccountId: ReadonlyArray<Company>;
  readonly company: Maybe<Company>;
  /** @deprecated No longer supported */
  readonly companyAdministrator: Maybe<Person>;
  /** Used to query data for cards on admin dashboard */
  readonly companyAnalytics: Maybe<CompanyAnalytics>;
  readonly companyByRegistrationNumber: Maybe<Company>;
  readonly companyRepresentative: CompanyRepresentative;
  readonly confirmedPersonalAdviceMeetingHistory: ReadonlyArray<Scalars['Date']['output']>;
  readonly costCenter: Maybe<CostCenter>;
  /** Project the cumulative return of portfolio and money, and/or score a plan */
  readonly cumulativeReturn: CumulativeReturn;
  /** Return latest document satisfying given conditions */
  readonly document: Maybe<Document>;
  /** Available document types */
  readonly documentTypes: ReadonlyArray<DocumentTypeInfo>;
  /** List documents satisfying given conditions */
  readonly documents: DocumentConnection;
  readonly employeeMeetings: EmployeeMeetingConnection;
  /** @deprecated Prefer Membership.salaryEntries */
  readonly employeeSalary: ReadonlyArray<EmployeeSalaryEvent>;
  /** @deprecated Prefer Membership.statusEntries */
  readonly employeeStatus: ReadonlyArray<EmployeeStatusEvent>;
  readonly employeesWithPersonalAdvice: EmployeeWithPersonalAdviceConnection;
  readonly employeesWithTransferableCapital: EmployeeWithTransferableCapitalConnection;
  readonly employment: Maybe<Employment>;
  readonly entitlement: Maybe<FlexEntitlement>;
  readonly entitlements: Maybe<FlexEntitlementConnection>;
  readonly eventTypes: ReadonlyArray<EventType>;
  readonly externalItem: Maybe<ExternalItem>;
  /** Retrieves the most recent family situation. */
  readonly familySituation: Maybe<FamilySituation>;
  readonly fcfwAssertions: Maybe<FlexFcfwAssertionConnection>;
  readonly fcfwRequests: Maybe<FlexFcfwRequestConnection>;
  readonly fileUploadUrl: ReadonlyArray<FlexFileUploadUrl>;
  readonly financialLink: Maybe<FinancialLink>;
  readonly fixedPremium: Maybe<FlexFixedPremiumConnection>;
  readonly flexBenefit: Maybe<FlexBenefit>;
  readonly flexSalaryExchangeReports: Maybe<FlexSalaryExchangeReportConnection>;
  /**
   * Returns the fully capable for work information text for a company.
   * Returns null if the company does not have an agreement, or if you do not have the necessary permissions.
   */
  readonly fullyCapableForWorkInfoText: Maybe<QueryFullyCapableForWorkInfoTextPayload>;
  readonly fund: Maybe<Fund>;
  /**
   * Returns a list of foreign exchange rates sorted by ascending FxRate.id and
   * then by descending FxRate.at.
   *
   * If the since date is omitted then only the latest rate from the past 7 days
   * is returned for each ID.
   */
  readonly fxRates: ReadonlyArray<FxRate>;
  /** The text to be signed by the user when signing a power of attorney. */
  readonly getPoaText: Scalars['String']['output'];
  readonly getPriceProposal: Maybe<File>;
  readonly groupedNotifications: Maybe<NotificationGroupConnection>;
  /** Indicates if the user has already signed the plus terms of agreement */
  readonly hasSignedPlus: Scalars['Boolean']['output'];
  readonly import: Maybe<Import>;
  readonly imports: Maybe<ImportConnection>;
  readonly institute: Maybe<Institute>;
  readonly institutes: ReadonlyArray<Institute>;
  /** A specific insurance by ID */
  readonly insurance: Maybe<Insurance>;
  /**
   * Retrieves the most recent analysis of your insurance coverage.
   *
   * Call the `updateInsuranceAnalysis` mutation to generate a new analysis.
   */
  readonly insuranceAnalysis: Maybe<InsuranceAnalysis>;
  readonly insuranceAnalysisHistory: Maybe<InsuranceAnalysisHistoryConnection>;
  /** Generates an insurance ID from the given parameters, just as it would be generated internally. */
  readonly insuranceId: Scalars['ID']['output'];
  readonly insurances: InsuranceConnection;
  readonly internalPost: Maybe<InternalPost>;
  readonly investmentScenario: Maybe<InvestmentScenarioPayload>;
  readonly invoice: Maybe<Invoice>;
  readonly invoiceSearch: Maybe<InvoiceSearchResult>;
  /** Returns invoicing data for a company for the current month. */
  readonly invoicingData: Maybe<FlexInvoicingData>;
  /** View one Job Offer, will be publicly accessible, admins can see hidden/expired, hidden/expired have all fields nulled for public */
  readonly jobOffer: Maybe<JobOffer>;
  readonly jobOfferEntitlements: Maybe<FlexEntitlementConnection>;
  readonly latestSentEmails: ReadonlyArray<SentEmail>;
  readonly loginTicket: Maybe<LoginTicket>;
  readonly meetingStatistics: MeetingStatistics;
  readonly meetings: MeetingConnection;
  readonly membership: Maybe<Membership>;
  readonly memberships: Maybe<MembershipConnection>;
  readonly movieTicketsStock: Maybe<FlexMovieTicketsStock>;
  readonly nlpSalesRep: Maybe<AdminUser>;
  readonly nlpSalesReps: ReadonlyArray<AdminUser>;
  readonly nordeaPensionPortfolioInvestmentPlan: Maybe<ReadonlyArray<InsuranceInvestmentPlanAllocation>>;
  readonly note: Maybe<Note>;
  readonly noteAuthors: ReadonlyArray<Author>;
  readonly notes: Maybe<NoteConnection>;
  readonly notifications: Maybe<NotificationConnection>;
  readonly organizationalUnit: Maybe<OrganizationalUnit>;
  readonly organizationalUnits: Maybe<OrganizationalUnitConnection>;
  readonly payslips: Maybe<PayslipConnection>;
  readonly pensionTransferStatistics: PensionTransferStatistics;
  /** Look up permissions based on the current token */
  readonly permissions: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Prefer batching */
  readonly persons: ReadonlyArray<Maybe<Person>>;
  /** Look up a single piece of advice from your history */
  readonly plusAdvice: Maybe<PlusAdvice>;
  /**
   * Plus advice history, with recent advice first
   *
   * Specify `itemId` to only fetch advice pertaining to that item.
   */
  readonly plusAdviceHistory: Maybe<PlusAdviceConnection>;
  /** Your active Plus profile, if you have one */
  readonly plusProfile: Maybe<PlusProfile>;
  /** @deprecated Plus is available as a benefit */
  readonly plusSubscriptions: Maybe<ReadonlyArray<PlusSubscription>>;
  /** Look up a single suggestion */
  readonly plusSuggestion: Maybe<PlusSuggestion>;
  /**
   * Plus suggestions, with most recent advice first
   *
   * Fetches only suggestions based on the current profile by default.
   * Pass `includeHistory: true` to include suggestions based on older profiles.
   */
  readonly plusSuggestions: Maybe<PlusSuggestionConnection>;
  /** Poll the status of an ongoing bankid signing process. When the ticket is complete the finalized data will be available. */
  readonly pollBankidTicket: Maybe<Ticket>;
  readonly proposal: Maybe<Proposal>;
  readonly proposalBenefit: ProposalBenefit;
  /** If there is no contact person set for the company, this field will return null. */
  readonly proposalContactPerson: Maybe<ProposalCompanyContactPerson>;
  /** If there is no head admin set for the company, this field will return null. */
  readonly proposalHeadAdmin: Maybe<ProposalCompanyHeadAdmin>;
  readonly proposalMembership: ProposalMembership;
  readonly proposals: Maybe<ProposalConnection>;
  /** Returns a connection with all movie tickets purchased by the current user.The connection is sorted so that the latest purchased ticket is first. */
  readonly purchasedMovieTickets: Maybe<FlexPurchasedMovieTicketConnection>;
  readonly revenueBreakdownExportUrl: Scalars['String']['output'];
  /** Returns the latest active salary exchange for a specific user. Returns null if the salary exchange has been terminated. */
  readonly salaryExchange: Maybe<FlexSalaryExchange>;
  readonly salaryExchangeAdvice: Maybe<FlexSalaryExchangeAdvice>;
  /** Returns the relevant salary exchange request for a specific user. The most recent request will always be returned.A request that shows up here is requested, approved, and rejected with a future effective date, or rejected with a past effective date.Note that cancelled requests will NEVER appear here, they just disappear when they are cancelled. */
  readonly salaryExchangeRequest: Maybe<FlexSalaryExchangeRequest>;
  /** Returns all ongoing requests for a company matching statuses. The requests will be sorted with the latest requested request first. */
  readonly salaryExchangeRequests: Maybe<FlexSalaryExchangeRequestConnection>;
  /** Returns all ongoing requests for a company. The requests will be sorted with the latest requested request first. Includes all request statuses. */
  readonly salaryExchangeRequestsHistory: Maybe<FlexSalaryExchangeRequestsHistoryConnection>;
  /** Returns the latest salary exchanges for users in a specific company. Returns an inactive salary exchange if the latest salary exchange has been terminated. */
  readonly salaryExchanges: FlexSalaryExchangeConnection;
  readonly scrape: Maybe<Scrape>;
  readonly session: Maybe<Session>;
  readonly sharedFiles: Maybe<SharedFileConnection>;
  /** Query the progress of an ongoing signing */
  readonly signPlusTicket: Maybe<SignPlusTicket>;
  /** Status of an ongoing or completed power of attorney signature request. */
  readonly signPoaTicket: Maybe<SignPoaTicket>;
  readonly signedPensionTransfers: ReadonlyArray<SignedPensionTransfer>;
  readonly singlePremiums: Maybe<FlexSinglePremiumConnection>;
  /** Returns a connection of the total transferable capital per user, sorted by capital in sortOrder (defaults to descending). */
  readonly totalTransferableCapital: Maybe<TransferableCapitalConnection>;
  readonly userAccount: Maybe<UserAccount>;
  /** @deprecated Prefer membershipsByUserAccountId */
  readonly userAccountPersons: ReadonlyArray<Person>;
  readonly viewer: Maybe<UserAccount>;
  readonly wellnessBalance: Maybe<Scalars['Money']['output']>;
}


export interface QueryM2MembershipArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QueryM2MembershipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryaccountingDimensionsArgs {
  companyId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryaccountingObjectArgs {
  companyId: Scalars['ID']['input'];
  dimensionId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}


export interface QueryaccountingObjectsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  dimensionId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryadvinansFundOfferingArgs {
  countries?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}


export interface QueryagreementArgs {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<AgreementType>;
}


export interface QueryagreementRequestStaticAttachmentsArgs {
  type: AgreementRequestStaticAttachmentsType;
}


export interface QueryagreementsArgs {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  euroAccidentSubAgreementNumber?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<AgreementStatus>;
  type?: InputMaybe<AgreementType>;
}


export interface QuerybankIdTicketStatusArgs {
  ticketId: Scalars['ID']['input'];
}


export interface QuerybenefitCategoriesArgs {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerybenefitPackageArgs {
  id: Scalars['ID']['input'];
}


export interface QuerybenefitPackageBenefitHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitPackageId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QuerybenefitPackagesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerybenefitTypesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyGetBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  onlyGetDiscounts?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface QuerybonusSalaryExchangeArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QuerybonusSalaryExchangeAdviceArgs {
  adviceId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  deductionValue: Scalars['Money']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<FlexSalaryExchangeProvider>;
}


export interface QuerybonusSalaryExchangeRequestArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QuerybonusSalaryExchangeRequestsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
  requestIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerybonusSalaryExchangeRequestsHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerybonusSalaryExchangesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeStatus>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerybookingLinkArgs {
  companyId: Scalars['ID']['input'];
  eventTypeUri?: InputMaybe<Scalars['String']['input']>;
}


export interface QuerychangesArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QuerychangesHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<FlexChangesHistoryInputFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId: Scalars['ID']['input'];
}


export interface QueryclearingCodesArgs {
  searchString: Scalars['String']['input'];
}


export interface QuerycompaniesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CompanyFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CompaniesOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
}


export interface QuerycompaniesByUserAccountIdArgs {
  userAccountId: Scalars['ID']['input'];
}


export interface QuerycompanyArgs {
  id: Scalars['ID']['input'];
}


export interface QuerycompanyAdministratorArgs {
  personalIdentityNumber: Scalars['String']['input'];
  registrationNumber: Scalars['String']['input'];
}


export interface QuerycompanyAnalyticsArgs {
  companyId: Scalars['ID']['input'];
}


export interface QuerycompanyByRegistrationNumberArgs {
  registrationNumber: Scalars['String']['input'];
}


export interface QuerycompanyRepresentativeArgs {
  id: Scalars['ID']['input'];
}


export interface QueryconfirmedPersonalAdviceMeetingHistoryArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QuerycostCenterArgs {
  id: Scalars['ID']['input'];
}


export interface QuerycumulativeReturnArgs {
  input: CumulativeReturnInput;
}


export interface QuerydocumentArgs {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentType?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<ReadonlyArray<DocumentLabelInput>>;
  owner?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerydocumentsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentType?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<ReadonlyArray<DocumentLabelInput>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryemployeeMeetingsArgs {
  advisorId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  eventTypeCategories?: InputMaybe<ReadonlyArray<EventTypeCategory>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface QueryemployeeSalaryArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QueryemployeeStatusArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QueryemployeesWithPersonalAdviceArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxBirthdate?: InputMaybe<Scalars['Date']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minBirthdate?: InputMaybe<Scalars['Date']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryemployeesWithTransferableCapitalArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  brokeredByNode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxCapital?: InputMaybe<Scalars['Int']['input']>;
  minCapital?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortTransferableCapitalBy>;
  sortOrder?: InputMaybe<SortOrder>;
}


export interface QueryemploymentArgs {
  id: Scalars['ID']['input'];
}


export interface QueryentitlementArgs {
  benefitId?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  userAccountId: Scalars['ID']['input'];
}


export interface QueryentitlementsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  benefitSearch?: InputMaybe<Scalars['String']['input']>;
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<FlexBenefitOrder>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  slugs?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  supplierIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  userAccountId: Scalars['ID']['input'];
}


export interface QueryexternalItemArgs {
  id: Scalars['ID']['input'];
}


export interface QueryfamilySituationArgs {
  input?: InputMaybe<FamilySituationInput>;
}


export interface QueryfcfwAssertionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryfcfwRequestsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryfileUploadUrlArgs {
  files?: InputMaybe<ReadonlyArray<FlexFileUploadInput>>;
}


export interface QueryfinancialLinkArgs {
  id: Scalars['ID']['input'];
}


export interface QueryfixedPremiumArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId: Scalars['ID']['input'];
}


export interface QueryflexBenefitArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryflexSalaryExchangeReportsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryfullyCapableForWorkInfoTextArgs {
  companyId: Scalars['ID']['input'];
}


export interface QueryfundArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
}


export interface QueryfxRatesArgs {
  since?: InputMaybe<Scalars['Date']['input']>;
}


export interface QuerygetPriceProposalArgs {
  input: GeneratePriceProposalInput;
}


export interface QuerygroupedNotificationsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  levels?: InputMaybe<ReadonlyArray<NotificationLevel>>;
  status?: InputMaybe<NotificationStatus>;
  targetRoles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  targets?: InputMaybe<ReadonlyArray<NotificationTarget>>;
  types?: InputMaybe<ReadonlyArray<NotificationType>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryimportArgs {
  id: Scalars['ID']['input'];
}


export interface QueryimportsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryinstituteArgs {
  id: Scalars['ID']['input'];
}


export interface QueryinstitutesArgs {
  acceptsPoa?: InputMaybe<Scalars['Boolean']['input']>;
  hasTransferablePension?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface QueryinsuranceArgs {
  id: Scalars['ID']['input'];
}


export interface QueryinsuranceAnalysisArgs {
  input?: InputMaybe<InsuranceAnalysisInput>;
}


export interface QueryinsuranceAnalysisHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryinsuranceIdArgs {
  instituteId: Scalars['ID']['input'];
  insuranceNumber: Scalars['String']['input'];
}


export interface QueryinsurancesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brokeredByAdvinans?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  instituteId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  policyholderCompanyId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryinternalPostArgs {
  id: Scalars['ID']['input'];
}


export interface QueryinvestmentScenarioArgs {
  input: InvestmentScenarioInput;
}


export interface QueryinvoiceArgs {
  id: Scalars['ID']['input'];
}


export interface QueryinvoiceSearchArgs {
  accountingCost?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  benefitPackageId?: InputMaybe<Scalars['String']['input']>;
  benefitType?: InputMaybe<ReadonlyArray<BenefitType>>;
  companyId: Scalars['String']['input'];
  dueDate?: InputMaybe<DateFilter>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invoiceLineItemNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  invoicesAfter?: InputMaybe<Scalars['String']['input']>;
  invoicesBefore?: InputMaybe<Scalars['String']['input']>;
  invoicesFirst?: InputMaybe<Scalars['Int']['input']>;
  invoicesLast?: InputMaybe<Scalars['Int']['input']>;
  issueDate?: InputMaybe<DateFilter>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linePeriod?: InputMaybe<LineDateFilter>;
  lineType?: InputMaybe<LineType>;
  linesAfter?: InputMaybe<Scalars['String']['input']>;
  linesBefore?: InputMaybe<Scalars['String']['input']>;
  linesFirst?: InputMaybe<Scalars['Int']['input']>;
  linesLast?: InputMaybe<Scalars['Int']['input']>;
  pensionLayer?: InputMaybe<PensionLayer>;
  pin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  sellersItemIdentification?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<InvoiceSortBy>;
  sortOrder?: InputMaybe<InvoiceSortOrder>;
  supplier?: InputMaybe<ReadonlyArray<InvoiceSupplier>>;
  taxCategory?: InputMaybe<TaxCategoryTypes>;
  taxClass?: InputMaybe<TaxClass>;
  userAccountId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryinvoicingDataArgs {
  companyId: Scalars['ID']['input'];
}


export interface QueryjobOfferArgs {
  id: Scalars['ID']['input'];
}


export interface QueryjobOfferEntitlementsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  jobOfferId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  membershipData?: InputMaybe<FlexMembershipData>;
}


export interface QuerylatestSentEmailsArgs {
  eventTypeCategory?: InputMaybe<EventTypeCategory>;
  membershipKeys?: InputMaybe<ReadonlyArray<MembershipKey>>;
  userAccountIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}


export interface QueryloginTicketArgs {
  ticketId: Scalars['ID']['input'];
}


export interface QuerymeetingStatisticsArgs {
  advisorId?: InputMaybe<Scalars['ID']['input']>;
  companyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  eventTypeCategories?: InputMaybe<ReadonlyArray<EventTypeCategory>>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface QuerymeetingsArgs {
  advisorId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  eventTypeCategories?: InputMaybe<ReadonlyArray<EventTypeCategory>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  membershipKeys?: InputMaybe<ReadonlyArray<MembershipKey>>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface QuerymembershipArgs {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerymembershipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  birthdateRange?: InputMaybe<Scalars['DateRange']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  membershipIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  personalIdentityNumber?: InputMaybe<Scalars['IdentityNumber']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  remunerationRange?: InputMaybe<RemunerationRange>;
  roles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
  userAccountIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}


export interface QuerynlpSalesRepArgs {
  id: Scalars['ID']['input'];
}


export interface QuerynordeaPensionPortfolioInvestmentPlanArgs {
  retirementAge?: InputMaybe<Scalars['Int']['input']>;
  withdrawalPeriod?: InputMaybe<Scalars['Int']['input']>;
}


export interface QuerynoteArgs {
  id: Scalars['ID']['input'];
}


export interface QuerynotesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<AuthorInput>;
  before?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<ReadonlyArray<NoteCategory>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerynotificationsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  levels?: InputMaybe<ReadonlyArray<NotificationLevel>>;
  status?: InputMaybe<NotificationStatus>;
  targetRoles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  targets?: InputMaybe<ReadonlyArray<NotificationTarget>>;
  types?: InputMaybe<ReadonlyArray<NotificationType>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryorganizationalUnitArgs {
  id: Scalars['ID']['input'];
}


export interface QueryorganizationalUnitsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids: ReadonlyArray<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QuerypayslipsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QuerypensionTransferStatisticsArgs {
  advisorId?: InputMaybe<Scalars['ID']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface QuerypersonsArgs {
  ids: ReadonlyArray<PersonId>;
}


export interface QueryplusAdviceArgs {
  id: Scalars['ID']['input'];
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryplusAdviceHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryplusSubscriptionsArgs {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryplusSuggestionArgs {
  id: Scalars['ID']['input'];
}


export interface QueryplusSuggestionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeHistory?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QuerypollBankidTicketArgs {
  id: Scalars['ID']['input'];
}


export interface QueryproposalArgs {
  id: Scalars['ID']['input'];
}


export interface QueryproposalBenefitArgs {
  id: Scalars['ID']['input'];
}


export interface QueryproposalContactPersonArgs {
  companyId: Scalars['ID']['input'];
}


export interface QueryproposalHeadAdminArgs {
  companyId: Scalars['ID']['input'];
}


export interface QueryproposalMembershipArgs {
  id: Scalars['ID']['input'];
}


export interface QueryproposalsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  filter?: InputMaybe<ProposalsFilterArguments>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProposalsSortingArguments>;
}


export interface QuerypurchasedMovieTicketsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryrevenueBreakdownExportUrlArgs {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
}


export interface QuerysalaryExchangeArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QuerysalaryExchangeAdviceArgs {
  adviceId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  deductionValue: Scalars['Money']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<FlexSalaryExchangeProvider>;
}


export interface QuerysalaryExchangeRequestArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}


export interface QuerysalaryExchangeRequestsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
  requestIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerysalaryExchangeRequestsHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerysalaryExchangesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeStatus>>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryscrapeArgs {
  id: Scalars['ID']['input'];
}


export interface QuerysharedFilesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerysignPlusTicketArgs {
  id: Scalars['ID']['input'];
}


export interface QuerysignPoaTicketArgs {
  id: Scalars['ID']['input'];
}


export interface QuerysignedPensionTransfersArgs {
  date?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<TransferType>;
  userAccountId: Scalars['ID']['input'];
}


export interface QuerysinglePremiumsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
}


export interface QuerytotalTransferableCapitalArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brokeredByNode?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxCapital?: InputMaybe<Scalars['Int']['input']>;
  minCapital?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  userAccountIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}


export interface QueryuserAccountArgs {
  userAccountId: Scalars['ID']['input'];
}


export interface QueryuserAccountPersonsArgs {
  userAccountId: Scalars['ID']['input'];
}


export interface QuerywellnessBalanceArgs {
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}

export interface QueryFullyCapableForWorkInfoTextPayload {
  readonly __typename?: 'QueryFullyCapableForWorkInfoTextPayload';
  readonly text: Scalars['String']['output'];
}

/** Vilket uttalande om förvaltningsform stämmer bäst överens med dina preferenser? */
export enum RebalancePreference {
  /** Jag söker en förvaltning som sköter sig själv */
  AUTO = 'AUTO',
  /** Jag är en investerare som vill välja fonder eller aktier själv */
  MANUAL = 'MANUAL'
}

/**
 * If followed, target goal should be reached at horizon
 *             with 2/3 probability (target goal is at 33 percentile)
 */
export interface Recommendation {
  readonly __typename?: 'Recommendation';
  /** One-time deposit required given monthly savings */
  readonly initialDeposit: Scalars['Money']['output'];
  /** Constant nominal monthly deposit given initial deposit */
  readonly monthlySavings: Scalars['Money']['output'];
  /** Target value necessary to achieve withdrawal goal (or target value from input if there is no withdrawal goal */
  readonly targetValue: Scalars['Money']['output'];
  /** Probability that target value will not be reached (higher is less likely) */
  readonly targetValuePercentile: Scalars['DecimalFraction']['output'];
}

export enum RecordFilter {
  NEW_ACTIVITIES = 'NEW_ACTIVITIES',
  NEW_MEMBERSHIPS = 'NEW_MEMBERSHIPS',
  SALARY_CHANGES = 'SALARY_CHANGES',
  TERMINATED_EMPLOYMENTS = 'TERMINATED_EMPLOYMENTS',
  UNCHANGED_ROWS = 'UNCHANGED_ROWS'
}

export enum RecordStatus {
  /** Record finished without errors */
  COMPLETE = 'COMPLETE',
  /** Record finished with errors */
  FAILURE = 'FAILURE',
  /** Record has been processed and been determined to not contain any changes. */
  NO_CHANGES = 'NO_CHANGES',
  /** Created, awaiting re-mapping */
  PENDING = 'PENDING',
  /** Awaiting changes to be generated */
  PENDING_CHANGES = 'PENDING_CHANGES',
  /** Awaiting record to be executed */
  PENDING_RUN = 'PENDING_RUN'
}

/** Hur viktigt är det för dig att dina investeringar arbetar för att minska negativa påverkan på miljö och samhälle? */
export enum ReduceNegativeImpactImportance {
  /** Viktigt */
  IMPORTANT = 'IMPORTANT',
  /** Inte viktigt */
  NOT_IMPORTANT = 'NOT_IMPORTANT'
}

export interface RemindersSent {
  readonly __typename?: 'RemindersSent';
  readonly first: Scalars['Int']['output'];
  readonly second: Scalars['Int']['output'];
}

export interface RemoveMembershipBenefitPackageInput {
  readonly companyId: Scalars['ID']['input'];
  readonly effectiveDate: Scalars['Date']['input'];
  readonly userAccountId: Scalars['ID']['input'];
}

export interface RemoveMembershipBenefitPackagePayload {
  readonly __typename?: 'RemoveMembershipBenefitPackagePayload';
  readonly companyId: Scalars['ID']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface RemoveProposalBenefitFromBenefitPackageInput {
  readonly benefitId: Scalars['ID']['input'];
  readonly benefitPackageId: Scalars['ID']['input'];
  readonly proposalId: Scalars['ID']['input'];
}

export interface RemunerationItem {
  readonly __typename?: 'RemunerationItem';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly currency: Maybe<Scalars['String']['output']>;
  readonly from: Scalars['Date']['output'];
  readonly price: Scalars['Money']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
  readonly type: RemunerationItemType;
  readonly unitCode: Maybe<Scalars['String']['output']>;
}

export interface RemunerationItemType {
  readonly __typename?: 'RemunerationItemType';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface RemunerationRange {
  readonly max?: InputMaybe<Scalars['Int']['input']>;
  readonly min?: InputMaybe<Scalars['Int']['input']>;
  readonly type: Scalars['String']['input'];
}

export interface RemunerationType {
  readonly __typename?: 'RemunerationType';
  readonly currency: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly unitCode: Maybe<Scalars['String']['output']>;
}

export interface RemunerationTypeConnection {
  readonly __typename?: 'RemunerationTypeConnection';
  readonly edges: ReadonlyArray<RemunerationTypeEdge>;
  readonly pageInfo: PageInfo;
}

export interface RemunerationTypeEdge {
  readonly __typename?: 'RemunerationTypeEdge';
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly node: RemunerationType;
}

export interface RepaymentProtection {
  readonly __typename?: 'RepaymentProtection';
  readonly repaymentCoverForExternalId: Scalars['String']['output'];
  readonly repaymentCoverShare: Maybe<Scalars['DecimalFraction']['output']>;
  readonly repaymentCoversComponent: Maybe<InsuranceComponentRetirement>;
}

export enum ReportFormat {
  CSV = 'CSV',
  EXCEL = 'EXCEL'
}

export interface ResendInvoiceEmailsInput {
  readonly companyId: Scalars['ID']['input'];
  readonly invoiceId: Scalars['ID']['input'];
}

export interface ResendPoaInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Not supplying select institutes will resend the Power of Attorney to all connected parties */
  readonly instituteIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}

export interface ResendPoaPayload {
  readonly __typename?: 'ResendPoaPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly financialLinks: ReadonlyArray<FinancialLink>;
}

export interface RetirementAgeChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RetirementAgeChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly insuranceNumber: Scalars['String']['output'];
  readonly newRetirementAge: Scalars['Float']['output'];
}

export interface RiskAccidentChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskAccidentChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly accidentLevel: FlexAccidentLevel;
}

export enum RiskBucket {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
  LEVEL_4 = 'LEVEL_4',
  LEVEL_5 = 'LEVEL_5',
  LEVEL_6 = 'LEVEL_6',
  LEVEL_7 = 'LEVEL_7',
  NEW = 'NEW',
  SMALL = 'SMALL'
}

export interface RiskHealthcareChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskHealthcareChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly healthcareLevel: FlexHealthcareLevel;
}

export interface RiskLifeChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskLifeChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly lifeLevel: Scalars['String']['output'];
}

/** Designates the risk preference for an investment strategy */
export enum RiskPreference {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export interface RiskPremiumWaiverChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskPremiumWaiverChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly monthlyPremium: Scalars['Money']['output'];
}

/** Vilket uttalande om förhållande mellan risk och avkastning stämmer bäst överens med dina preferenser? */
export enum RiskRewardPreference {
  /** Jag söker en automatisk förvaltning där min pension har en lägre risk i utbyte mot en lägre förväntad avkastning */
  GUARANTEED = 'GUARANTEED',
  /** Jag söker en automatisk förvaltning där jag accepterar en högre risk i utbyte mot en högre förväntad avkastning */
  RISKY = 'RISKY'
}

export interface RiskSicknessAndAccidentChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskSicknessAndAccidentChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly sicknessAndAccidentLevel: FlexSicknessAndAccidentLevel;
}

export interface RiskSicknessChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskSicknessChangeConfig';
  readonly _typename: Scalars['String']['output'];
  readonly annualSalary: Scalars['Money']['output'];
  readonly sicknessLevel: FlexSicknessLevel;
}

export interface RiskTglChangeConfig extends FlexChangeHistoryConfig {
  readonly __typename?: 'RiskTglChangeConfig';
  readonly _typename: Scalars['String']['output'];
}

export enum SUPER_TYPE {
  BALANCE = 'BALANCE',
  REMUNERATION = 'REMUNERATION'
}

export interface SalaryInformation {
  readonly __typename?: 'SalaryInformation';
  readonly amount: CurrencyValue;
  readonly date: Maybe<Scalars['Date']['output']>;
  readonly pensionAge: Maybe<Scalars['Int']['output']>;
  /**
   * Salary periodicity, where P1M represents monthly salary, P1Y represents annual
   * salary, etc.
   */
  readonly periodicity: Scalars['ISODuration']['output'];
  readonly type: SalaryType;
  readonly year: Maybe<Scalars['Int']['output']>;
}

export interface SalaryReport {
  readonly __typename?: 'SalaryReport';
  readonly companyId: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly month: Scalars['Int']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<Scalars['String']['output']>;
  readonly year: Scalars['Int']['output'];
}


export interface SalaryReporturlArgs {
  format?: InputMaybe<ReportFormat>;
}

export interface SalaryReportConnection {
  readonly __typename?: 'SalaryReportConnection';
  readonly edges: ReadonlyArray<SalaryReportEdge>;
  readonly pageInfo: PageInfo;
}

export interface SalaryReportEdge {
  readonly __typename?: 'SalaryReportEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: SalaryReport;
}

export enum SalaryType {
  GROSS_SALARY_PAID_IN_CASH = 'GROSS_SALARY_PAID_IN_CASH',
  PENSIONABLE_SALARY = 'PENSIONABLE_SALARY',
  REPORTED_ANNUAL_SALARY = 'REPORTED_ANNUAL_SALARY',
  REPORTED_SALARY = 'REPORTED_SALARY',
  SEVERANCE_PAY = 'SEVERANCE_PAY',
  SICKNESS_PENSION_QUALIFYING_SALARY = 'SICKNESS_PENSION_QUALIFYING_SALARY'
}

export interface Scrape {
  readonly __typename?: 'Scrape';
  readonly autostartToken: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly status: ScrapeStatus;
}

export interface ScrapeInput {
  readonly instituteId: Scalars['ID']['input'];
}

export interface ScrapePayload {
  readonly __typename?: 'ScrapePayload';
  readonly id: Scalars['ID']['output'];
}

export enum ScrapeStatus {
  FAILED = 'FAILED',
  OK = 'OK',
  PENDING = 'PENDING'
}

export interface SendMeetingInvitationsInput {
  /** Required if service token. */
  readonly author?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly eventTypeUri: Scalars['String']['input'];
  readonly membershipKeys: ReadonlyArray<MembershipKey>;
}

export interface SendMeetingInvitationsPayload {
  readonly __typename?: 'SendMeetingInvitationsPayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
}

export interface SentEmail {
  readonly __typename?: 'SentEmail';
  readonly companyId: Maybe<Scalars['ID']['output']>;
  readonly eventTypeCategory: EventTypeCategory;
  readonly sentAt: Scalars['Date']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

/** Describes a logged in user's session. */
export interface Session {
  readonly __typename?: 'Session';
  readonly authenticationMethod: AuthenticationMethod;
}

export interface SetInsuranceDeletedInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Marks the insurance as deleted which makes it invisible in the system. Passing 'false' would remove the flag and make the insurance visible again. */
  readonly deleted: Scalars['Boolean']['input'];
  readonly instituteId: Scalars['String']['input'];
  readonly insuranceNumber: Scalars['String']['input'];
}

export interface SetLocalePreferenceInput {
  /** Use either 'sv-SE' or 'en-US' */
  readonly locale: Scalars['String']['input'];
}

export interface SetLocalePreferencePayload {
  readonly __typename?: 'SetLocalePreferencePayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly userAccount: Maybe<UserAccount>;
  readonly userAccountId: Maybe<Scalars['ID']['output']>;
}

export interface SetProposalCompanyContactPersonInput {
  readonly companyId: Scalars['ID']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly personalIdentityNumber?: InputMaybe<Scalars['NaturalPersonIdentifier']['input']>;
  readonly phoneNumber: Scalars['String']['input'];
}

export interface SetProposalCompanyHeadAdminInput {
  readonly companyId: Scalars['ID']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly personalIdentityNumber: Scalars['NaturalPersonIdentifier']['input'];
  readonly phoneNumber: Scalars['String']['input'];
}

export enum Sex {
  F = 'F',
  M = 'M'
}

export interface ShareInput {
  /** Asset class of position */
  readonly assetClass: Scalars['String']['input'];
  /** Annual position fee for this period */
  readonly fee: Scalars['DecimalFraction']['input'];
  /** Security ID */
  readonly id: Scalars['ID']['input'];
  /** Share of position this period (as fraction) */
  readonly share: Scalars['DecimalFraction']['input'];
}

export interface ShareItem {
  readonly __typename?: 'ShareItem';
  /** Security ID */
  readonly id: Scalars['ID']['output'];
  readonly share: Scalars['DecimalFraction']['output'];
}

export interface SharedFile {
  readonly __typename?: 'SharedFile';
  readonly author: Author;
  readonly createdAt: Scalars['Date']['output'];
  readonly file: AttachedFile;
  readonly id: Scalars['ID']['output'];
}

export interface SharedFileConnection {
  readonly __typename?: 'SharedFileConnection';
  readonly edges: ReadonlyArray<SharedFileEdge>;
  readonly pageInfo: PageInfo;
}

export interface SharedFileEdge {
  readonly __typename?: 'SharedFileEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: SharedFile;
}

export interface SignPensionTransferTaskInput {
  readonly pensionTransferAdviceId: Scalars['ID']['input'];
}

export interface SignPensionTransferTaskPayload {
  readonly __typename?: 'SignPensionTransferTaskPayload';
  readonly autoStartToken: Scalars['ID']['output'];
  readonly orderRef: Scalars['String']['output'];
  readonly qrData: Scalars['String']['output'];
  readonly ticketId: Scalars['ID']['output'];
}

export interface SignPlusPayload {
  readonly __typename?: 'SignPlusPayload';
  /** BankID auto-start token */
  readonly autoStartToken: Scalars['ID']['output'];
  /** BankID QR code data, for mobile signing. */
  readonly qrData: Scalars['String']['output'];
  /** TicketID for querying the progress in signPlusTicket */
  readonly ticketId: Scalars['ID']['output'];
}

export type SignPlusTicket = SignPlusTicketCancel | SignPlusTicketCompleted | SignPlusTicketFailure | SignPlusTicketProgress;

export interface SignPlusTicketCancel {
  readonly __typename?: 'SignPlusTicketCancel';
  readonly id: Scalars['ID']['output'];
  readonly status: Scalars['String']['output'];
}

export interface SignPlusTicketCompleted {
  readonly __typename?: 'SignPlusTicketCompleted';
  readonly id: Scalars['ID']['output'];
  readonly status: Scalars['String']['output'];
}

export interface SignPlusTicketFailure {
  readonly __typename?: 'SignPlusTicketFailure';
  readonly id: Scalars['ID']['output'];
  readonly status: Scalars['String']['output'];
}

export interface SignPlusTicketProgress {
  readonly __typename?: 'SignPlusTicketProgress';
  /** BankID auto-start token */
  readonly autoStartToken: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  /** BankID QR code data, for mobile signing. */
  readonly qrData: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
}

export interface SignPoaInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly validTo?: InputMaybe<Scalars['String']['input']>;
}

export interface SignPoaPayload {
  readonly __typename?: 'SignPoaPayload';
  readonly autoStartToken: Scalars['String']['output'];
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly qrData: Scalars['String']['output'];
  readonly ticketId: Scalars['ID']['output'];
}

export type SignPoaTicket = SignPoaTicketComplete | SignPoaTicketFailure | SignPoaTicketProgress;

export interface SignPoaTicketComplete {
  readonly __typename?: 'SignPoaTicketComplete';
  readonly financialLinks: ReadonlyArray<FinancialLink>;
  readonly poa: Poa;
}

export interface SignPoaTicketFailure {
  readonly __typename?: 'SignPoaTicketFailure';
  readonly status: Scalars['String']['output'];
}

export interface SignPoaTicketProgress {
  readonly __typename?: 'SignPoaTicketProgress';
  readonly autoStartToken: Scalars['String']['output'];
  readonly qrData: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
}

export interface SignedPensionTransfer {
  readonly __typename?: 'SignedPensionTransfer';
  readonly destination: Maybe<SignedPensionTransferDestination>;
  /** Used for field resolver */
  readonly pensionTransferTaskId: Scalars['Int']['output'];
  readonly signedAt: Scalars['Date']['output'];
  readonly sources: ReadonlyArray<SignedPensionTransferSource>;
  readonly totalCapital: Scalars['Money']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface SignedPensionTransferDestination {
  readonly __typename?: 'SignedPensionTransferDestination';
  readonly instituteId: Scalars['String']['output'];
  readonly insuranceNumber: Scalars['String']['output'];
}

export interface SignedPensionTransferSource {
  readonly __typename?: 'SignedPensionTransferSource';
  readonly capital: Scalars['Money']['output'];
  readonly insuranceNumber: Scalars['String']['output'];
}

export interface SignupFri2Input {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
}

export interface SignupFri2Payload {
  readonly __typename?: 'SignupFri2Payload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortTransferableCapitalBy {
  CAPITAL = 'CAPITAL',
  LATEST_EMAIL_SENT_AT = 'LATEST_EMAIL_SENT_AT'
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortingFields {
  CREATED_AT = 'CREATED_AT',
  EXECUTED_AT = 'EXECUTED_AT',
  GENERATED_AT = 'GENERATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export interface StartImportInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  /** Deprecated field, no longer used */
  readonly isFullyCapableForWork?: InputMaybe<Scalars['Boolean']['input']>;
  /** "Deprecated: Has no effect, changes are always run" */
  readonly runChanges?: InputMaybe<Scalars['Boolean']['input']>;
  readonly skipWelcomeEmails?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface StartImportPayload {
  readonly __typename?: 'StartImportPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly import: Import;
}

export interface StatusItem {
  readonly __typename?: 'StatusItem';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly extent: Maybe<Scalars['DecimalFraction']['output']>;
  readonly from: Scalars['Date']['output'];
  readonly to: Maybe<Scalars['Date']['output']>;
  readonly type: Maybe<StatusItemType>;
}

export interface StatusItemType {
  readonly __typename?: 'StatusItemType';
  readonly id: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface Stock extends Instrument {
  readonly __typename?: 'Stock';
  readonly id: Scalars['ID']['output'];
  readonly isin: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly price: Maybe<CurrencyValue>;
  readonly priceAsOf: Maybe<Scalars['Date']['output']>;
}

export interface SupplierContactMappingInput {
  readonly ACTIWAY?: InputMaybe<ContactReference>;
  readonly ADVINANS?: InputMaybe<ContactReference>;
  readonly EURO_ACCIDENT?: InputMaybe<ContactReference>;
  readonly FUTUR?: InputMaybe<ContactReference>;
  readonly LF?: InputMaybe<ContactReference>;
  readonly NLP?: InputMaybe<ContactReference>;
  readonly NORDNET?: InputMaybe<ContactReference>;
}

export interface SupplierReferenceMapping {
  readonly __typename?: 'SupplierReferenceMapping';
  readonly ACTIWAY: Maybe<Scalars['String']['output']>;
  readonly ADVINANS: Maybe<Scalars['String']['output']>;
  readonly EURO_ACCIDENT: Maybe<Scalars['String']['output']>;
  readonly FUTUR: Maybe<Scalars['String']['output']>;
  readonly LF: Maybe<Scalars['String']['output']>;
  readonly NLP: Maybe<Scalars['String']['output']>;
  readonly NORDNET: Maybe<Scalars['String']['output']>;
}

export interface SupplierReferenceMappingInput {
  readonly ACTIWAY?: InputMaybe<Scalars['String']['input']>;
  readonly ADVINANS?: InputMaybe<Scalars['String']['input']>;
  readonly EURO_ACCIDENT?: InputMaybe<Scalars['String']['input']>;
  readonly FUTUR?: InputMaybe<Scalars['String']['input']>;
  readonly LF?: InputMaybe<Scalars['String']['input']>;
  readonly NLP?: InputMaybe<Scalars['String']['input']>;
  readonly NORDNET?: InputMaybe<Scalars['String']['input']>;
}

/** Designates sustanability prferences for an investment strategy */
export enum SustainabilityCategory {
  BROAD = 'BROAD',
  ETHICAL = 'ETHICAL',
  SUSTAINABLE = 'SUSTAINABLE'
}

export interface SustainabilityPreferences {
  readonly __typename?: 'SustainabilityPreferences';
  /** EU Taxonomy environmental indicators */
  readonly environmentallySustainableInvestmentsMinShare: Maybe<Scalars['DecimalFraction']['output']>;
  /** If custom preferences were provided */
  readonly hasCustomPreferences: Scalars['Boolean']['output'];
  /**
   * "
   * NULL means no consideration is needed. Empty array means no SPECIFIC consideration is needed.
   * Else, special consideration needs to be accounted for given the preferences.
   */
  readonly principalAdverseIndicatorEnvironmental: Maybe<ReadonlyArray<PrincipalAdverseIndicatorEnvironmental>>;
  /**
   * "
   * NULL means no consideration is needed. Empty array means no SPECIFIC consideration is needed.
   * Else, special consideration needs to be accounted for given the preferences.
   */
  readonly principalAdverseIndicatorSocial: Maybe<ReadonlyArray<PrincipalAdverseIndicatorSocial>>;
  readonly sustainabilityCategory: SustainabilityCategory;
  /** SFDR sustainability indicators */
  readonly sustainableInvestmentsMinShare: Maybe<Scalars['DecimalFraction']['output']>;
}

export interface SustainabilityPreferencesInput {
  /** EU Taxonomy environmental indicators, a value between 0 and 1 */
  readonly environmentallySustainableInvestmentsMinShare?: InputMaybe<Scalars['DecimalFraction']['input']>;
  /** If custom preferences were provided */
  readonly hasCustomPreferences?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * "
   * NULL means no consideration is needed. Empty array means no SPECIFIC consideration is needed.
   * Else, special consideration needs to be accounted for given the preferences.
   */
  readonly principalAdverseIndicatorEnvironmental?: InputMaybe<ReadonlyArray<PrincipalAdverseIndicatorEnvironmental>>;
  /**
   * "
   * NULL means no consideration is needed. Empty array means no SPECIFIC consideration is needed.
   * Else, special consideration needs to be accounted for given the preferences.
   */
  readonly principalAdverseIndicatorSocial?: InputMaybe<ReadonlyArray<PrincipalAdverseIndicatorSocial>>;
  readonly sustainabilityCategory?: InputMaybe<SustainabilityCategory>;
  /** SFDR sustainability indicators, a value between 0 and 1 */
  readonly sustainableInvestmentsMinShare?: InputMaybe<Scalars['DecimalFraction']['input']>;
}

/** Vill du ha fokus på hållbarhet i dina investeringar? */
export enum SustainablePreference {
  /** Nej, jag vill hellre investera så brett som möjligt */
  BROAD = 'BROAD',
  /** Ja, jag vill ha fokus på hållbara investeringar */
  SUSTAINABLE = 'SUSTAINABLE'
}

export interface SxErrorMetadata {
  readonly __typename?: 'SxErrorMetadata';
  readonly deductionValue: Scalars['Money']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly fullName: Scalars['String']['output'];
}

export interface SxOnetimeAvailableMetadata {
  readonly __typename?: 'SxOnetimeAvailableMetadata';
  readonly cutoffDate: Scalars['Date']['output'];
  readonly maxDeductionValue: Scalars['Money']['output'];
  readonly onetimeSalary: Scalars['Money']['output'];
}

export interface SxRequestApprovedMetadata {
  readonly __typename?: 'SxRequestApprovedMetadata';
  readonly deductionValue: Scalars['Money']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly fullName: Scalars['String']['output'];
}

export interface SxRequestRejectedMetadata {
  readonly __typename?: 'SxRequestRejectedMetadata';
  readonly deductionValue: Scalars['Money']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly reason: Maybe<Scalars['String']['output']>;
}

export interface SxRequestedMetadata {
  readonly __typename?: 'SxRequestedMetadata';
  readonly fullName: Scalars['String']['output'];
}

export interface SxViolatedMetadata {
  readonly __typename?: 'SxViolatedMetadata';
  readonly fullName: Scalars['String']['output'];
}

export enum TaxCategoryTypes {
  ENDOWMENT = 'ENDOWMENT',
  OCCUPATIONAL = 'OCCUPATIONAL',
  PRIVATE = 'PRIVATE'
}

export enum TaxClass {
  ENDOWMENT_INSURANCE = 'ENDOWMENT_INSURANCE',
  NONE = 'NONE',
  PENSION_INSURANCE = 'PENSION_INSURANCE'
}

export enum TaxScheme {
  ISK = 'ISK',
  PENSION = 'PENSION'
}

export type Ticket = TicketCancelled | TicketComplete | TicketFailure | TicketProgress;

export interface TicketCancelled {
  readonly __typename?: 'TicketCancelled';
  readonly id: Scalars['ID']['output'];
  readonly status: TicketStatus;
}

export interface TicketComplete {
  readonly __typename?: 'TicketComplete';
  readonly finalizedData: Scalars['JSON']['output'];
  readonly id: Scalars['ID']['output'];
  readonly signature: Scalars['String']['output'];
  readonly status: TicketStatus;
}

export interface TicketFailure {
  readonly __typename?: 'TicketFailure';
  readonly id: Scalars['ID']['output'];
  readonly status: TicketStatus;
}

export interface TicketProgress {
  readonly __typename?: 'TicketProgress';
  readonly autoStartToken: Scalars['ID']['output'];
  readonly id: Scalars['ID']['output'];
  readonly qrData: Scalars['String']['output'];
  readonly status: TicketStatus;
}

export enum TicketStatus {
  /** Ticket cancelled by user */
  CANCELLED = 'CANCELLED',
  /** Ticket signed and completed */
  COMPLETED = 'COMPLETED',
  /** Ticket signing failed */
  FAILED = 'FAILED',
  /** Sign ongoing */
  PENDING = 'PENDING',
  /** Signed but not finalized, completionData will not be available yet */
  PROCESSING = 'PROCESSING'
}

export enum TotalCompensationCategory {
  BENEFITS = 'BENEFITS',
  CASH = 'CASH',
  EMPLOYERS_CONTRIBUTIONS = 'EMPLOYERS_CONTRIBUTIONS',
  PENSION = 'PENSION'
}

export enum TotalCompensationCategory2 {
  BENEFITS = 'BENEFITS',
  BONUS = 'BONUS',
  CASH = 'CASH',
  EMPLOYERS_CONTRIBUTIONS = 'EMPLOYERS_CONTRIBUTIONS',
  PENSION = 'PENSION'
}

export enum TotalCompensationCollectiveAgreement {
  AKAPKL = 'AKAPKL',
  AKAPKR = 'AKAPKR',
  BTP1 = 'BTP1',
  ITP = 'ITP',
  ITP1 = 'ITP1',
  KAPKL = 'KAPKL',
  PAKFS09 = 'PAKFS09',
  SAFLO = 'SAFLO'
}

export interface TotalCompensationConfiguration {
  readonly __typename?: 'TotalCompensationConfiguration';
  readonly benefitPackages: Maybe<ReadonlyArray<BenefitPackage>>;
  readonly benefits: Scalars['Boolean']['output'];
  readonly companyId: Scalars['String']['output'];
  readonly pension: Scalars['Boolean']['output'];
  readonly salary: Scalars['Boolean']['output'];
  readonly salaryExchange: Scalars['Boolean']['output'];
  readonly salaryPayrollElementCode: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTimeISO']['output'];
  readonly vacation: Scalars['Boolean']['output'];
  readonly variableCompensation: Scalars['Boolean']['output'];
  readonly variableCompensationPayrollElementCode: Scalars['String']['output'];
  readonly wellness: Scalars['Boolean']['output'];
}

export interface TotalCompensationConfigurationInput {
  /** Used to set total compensation visibility on benefit packages, only       specified benefit packages will be updated ie. this supports partial updates. */
  readonly benefitPackagesVisibility?: InputMaybe<ReadonlyArray<UpdateBenefitPackagesVisibilityInput>>;
  /** Include Benefits in total compensation */
  readonly benefits?: InputMaybe<Scalars['Boolean']['input']>;
  readonly companyId: Scalars['String']['input'];
  /** Include Pension in total compensation */
  readonly pension?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include Salary in total compensation */
  readonly salary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include Salary Exchange in total compensation */
  readonly salaryExchange?: InputMaybe<Scalars['Boolean']['input']>;
  /** salaryPayrollElementCode, defauts to the pay type 11100 */
  readonly salaryPayrollElementCode?: InputMaybe<Scalars['String']['input']>;
  /** Include Vacation in total compensation */
  readonly vacation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include Variable Compensation in total compensation */
  readonly variableCompensation?: InputMaybe<Scalars['Boolean']['input']>;
  /** variableCompensationPayrollElementCode, defauts to the pay type 11100 */
  readonly variableCompensationPayrollElementCode?: InputMaybe<Scalars['String']['input']>;
  /** Include Wellness in total compensation */
  readonly wellness?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum TotalCompensationId {
  AKAPKL = 'AKAPKL',
  AKAPKR = 'AKAPKR',
  BTP1 = 'BTP1',
  CASH_SALARY = 'CASH_SALARY',
  COMPANY_CAR = 'COMPANY_CAR',
  EMPLOYERS_CONTRIBUTIONS = 'EMPLOYERS_CONTRIBUTIONS',
  INCOME_TAX = 'INCOME_TAX',
  /** Collective Agreements */
  ITP = 'ITP',
  ITP1 = 'ITP1',
  ITPK = 'ITPK',
  KAPKL = 'KAPKL',
  LUNCH_ALLOWANCE = 'LUNCH_ALLOWANCE',
  NET_SALARY = 'NET_SALARY',
  PAKFS09 = 'PAKFS09',
  PENSION = 'PENSION',
  SAFLO = 'SAFLO',
  SALARY_EXCHANGE = 'SALARY_EXCHANGE',
  SPECIAL_PAYROLL_TAX = 'SPECIAL_PAYROLL_TAX'
}

export enum TotalCompensationId2 {
  AKAPKL = 'AKAPKL',
  AKAPKR = 'AKAPKR',
  BTP1 = 'BTP1',
  CASH_SALARY = 'CASH_SALARY',
  COMPANY_CAR = 'COMPANY_CAR',
  EMPLOYERS_CONTRIBUTIONS = 'EMPLOYERS_CONTRIBUTIONS',
  GROSS_SALARY = 'GROSS_SALARY',
  INCOME_TAX = 'INCOME_TAX',
  /** Collective Agreements */
  ITP = 'ITP',
  ITP1 = 'ITP1',
  ITPK = 'ITPK',
  KAPKL = 'KAPKL',
  LUNCH_ALLOWANCE = 'LUNCH_ALLOWANCE',
  NET_SALARY = 'NET_SALARY',
  PAKFS09 = 'PAKFS09',
  PENSION = 'PENSION',
  SAFLO = 'SAFLO',
  SALARY_EXCHANGE = 'SALARY_EXCHANGE',
  SALARY_EXCHANGE_DEDUCTION = 'SALARY_EXCHANGE_DEDUCTION',
  SPECIAL_PAYROLL_TAX = 'SPECIAL_PAYROLL_TAX',
  VARIABLE_SALARY = 'VARIABLE_SALARY',
  WELLNESS = 'WELLNESS'
}

export interface TotalCompensationItem {
  readonly __typename?: 'TotalCompensationItem';
  readonly annualCompensation: Maybe<Scalars['Money']['output']>;
  readonly category: TotalCompensationCategory;
  readonly id: TotalCompensationId;
  readonly includedInCompensation: Scalars['Boolean']['output'];
}

export interface TotalCompensationItem2 {
  readonly __typename?: 'TotalCompensationItem2';
  readonly annualCompensation: Maybe<Scalars['Money']['output']>;
  readonly category: TotalCompensationCategory2;
  readonly id: TotalCompensationId2;
  readonly includedInCompensation: Scalars['Boolean']['output'];
}

/** Varför vill du flytta din pension? */
export enum TransferReason {
  /** Jag vill ha en automatisk förvaltning och riskjustering av min pension */
  AUTO_MGMT_RISK = 'AUTO_MGMT_RISK',
  /** Jag vill samla min pension på ett ställe */
  CONSOLIDATION = 'CONSOLIDATION',
  /** Jag vill sänka mina pensionsavgifter */
  LOWER_FEES = 'LOWER_FEES',
  /** Jag vill anpassa risknivån i mitt sparande */
  RISK_ADJUSTMENT = 'RISK_ADJUSTMENT',
  /** Jag vill välja hållbara placeringar för min pension */
  SUSTAINABLE = 'SUSTAINABLE'
}

export enum TransferType {
  PT = 'PT',
  SCRIVE = 'SCRIVE'
}

export interface TransferableCapital {
  readonly __typename?: 'TransferableCapital';
  readonly capital: Scalars['Money']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface TransferableCapitalConnection {
  readonly __typename?: 'TransferableCapitalConnection';
  readonly edges: ReadonlyArray<TransferableCapitalEdge>;
  readonly pageInfo: PageInfo;
}

export interface TransferableCapitalEdge {
  readonly __typename?: 'TransferableCapitalEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: TransferableCapital;
}

export interface TransferredCapitalByInstitute {
  readonly __typename?: 'TransferredCapitalByInstitute';
  readonly capital: Scalars['Money']['output'];
  readonly instituteId: Scalars['String']['output'];
}

export interface UpdateAccountingDimensionInput {
  readonly companyId: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  /** identifier needs to be a number > 20 */
  readonly identifier?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateAccountingObjectInput {
  readonly companyId: Scalars['String']['input'];
  readonly dimensionId: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly identifier?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateBenefitPackageInput {
  readonly benefitPackageId: Scalars['String']['input'];
  readonly hasBenefitPortal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Used as a soft delete to hide the benefit package from the UI. Defaults to false */
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly showTotalCompensation?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UpdateBenefitPackagePayload {
  readonly __typename?: 'UpdateBenefitPackagePayload';
  readonly benefitPackage: Maybe<BenefitPackage>;
}

export interface UpdateBenefitPackagesVisibilityInput {
  readonly id: Scalars['String']['input'];
  readonly showTotalCompensation: Scalars['Boolean']['input'];
}

export interface UpdateBookkeepingAccountInput {
  readonly accountKey: BookkeepingAccountKey;
  readonly companyId: Scalars['String']['input'];
  readonly customAccountName?: InputMaybe<Scalars['String']['input']>;
  readonly customAccountNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateCompanyBillingConfigurationInput {
  readonly attachmentFormats?: InputMaybe<ReadonlyArray<CompanyBillingAttachmentFormat>>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface UpdateCompanyBillingConfigurationPayload {
  readonly __typename?: 'UpdateCompanyBillingConfigurationPayload';
  readonly billingConfiguration: CompanyBillingConfiguration;
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

/** Note: logo is deprecated, prefer logoId. */
export interface UpdateCompanyInput {
  /** Int value between 1 and 12, representing the month of account closure for the company */
  readonly accountClosureMonth?: InputMaybe<Scalars['Int']['input']>;
  readonly accountNumber?: InputMaybe<Scalars['String']['input']>;
  readonly accountReference?: InputMaybe<SupplierReferenceMappingInput>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  /** Clearing code id of the NLP office that is associated with the company. The id that is inputted here will replace the existing one for the company, nullable. */
  readonly clearingCode?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly co?: InputMaybe<Scalars['String']['input']>;
  readonly contactReference?: InputMaybe<SupplierContactMappingInput>;
  readonly contractReference?: InputMaybe<SupplierReferenceMappingInput>;
  readonly displayName?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly emailLoginAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly employeeEmailLanguage?: InputMaybe<Language>;
  readonly hasCompanyGroup?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly line1?: InputMaybe<Scalars['String']['input']>;
  readonly line2?: InputMaybe<Scalars['String']['input']>;
  readonly logo?: InputMaybe<Scalars['String']['input']>;
  readonly logoId?: InputMaybe<Scalars['String']['input']>;
  readonly newsletterOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  /** Payment method for the company. Used for generating SME onboarding proposals. */
  readonly paymentMethod?: InputMaybe<PaymentMethod>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly postalCode?: InputMaybe<Scalars['String']['input']>;
  readonly refreshExternalInformation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly refreshSignatories?: InputMaybe<Scalars['Boolean']['input']>;
  /** Int value between 1 and 12, representing the month of salary review for the company */
  readonly salaryReviewMonth?: InputMaybe<Scalars['Int']['input']>;
  /** Id of the sales representative that is associated with the company. The id that are inputted here will replace the existing one for the company. */
  readonly salesRep?: InputMaybe<Scalars['ID']['input']>;
}

export interface UpdateCompanyPayload {
  readonly __typename?: 'UpdateCompanyPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly company: Company;
}

export interface UpdateCompanyRepresentativeInput {
  /** Given name of the representative */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  /** Identification method used, for example drivers license */
  readonly identification?: InputMaybe<CompanyRepresentativeIdentificationType>;
  /** For example, an advisor ID, passport number or drivers license number */
  readonly identificationReference?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly personalIdentityNumber?: InputMaybe<Scalars['NaturalPersonIdentifier']['input']>;
  readonly proposalId: Scalars['ID']['input'];
  readonly role?: InputMaybe<CompanyRepresentativeRole>;
}

export interface UpdateCompanyStatusInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly effectiveDate: Scalars['FullDate']['input'];
  readonly status: CompanyStatusStatus;
}

export interface UpdateCompanyStatusPayload {
  readonly __typename?: 'UpdateCompanyStatusPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface UpdateCostCenterInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateCostCenterPayload {
  readonly __typename?: 'UpdateCostCenterPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly costCenter: CostCenter;
}

export interface UpdateEconomicProfilePayload {
  readonly __typename?: 'UpdateEconomicProfilePayload';
  readonly economicProfile: EconomicProfile;
}

export interface UpdateEmploymentActivityInput {
  readonly effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  readonly effectiveUntil?: InputMaybe<Scalars['Date']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly rate?: InputMaybe<Scalars['DecimalFraction']['input']>;
}

export interface UpdateEmploymentActivityPayload {
  readonly __typename?: 'UpdateEmploymentActivityPayload';
  readonly employmentActivity: EmploymentActivity;
}

export interface UpdateEmploymentInput {
  readonly effectiveDate?: InputMaybe<Scalars['Date']['input']>;
  readonly effectiveUntil?: InputMaybe<Scalars['Date']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly rate?: InputMaybe<Scalars['DecimalFraction']['input']>;
}

export interface UpdateEmploymentPayload {
  readonly __typename?: 'UpdateEmploymentPayload';
  readonly employment: Employment;
}

export interface UpdateFamilySituationInput {
  readonly childrenStatus?: InputMaybe<ChildrenStatus>;
  readonly civilStatus?: InputMaybe<CivilStatus>;
  readonly dependentsStatus?: InputMaybe<DependentsStatus>;
  readonly userAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface UpdateFamilySituationPayload {
  readonly __typename?: 'UpdateFamilySituationPayload';
  readonly familySituation: FamilySituation;
}

export interface UpdateImportInput {
  readonly benefitsStartWithEmployment?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /**
   * For CSV/Excel imports, map columns in source file to these record columns.
   * Allows for any string to be passed as column name, allowing for custom columns.
   * Use null to skip columns.
   */
  readonly columnMappings?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /**
   * For CSV/Excel imports, map columns in source file to these record columns.
   * Use null to skip columns.
   *
   * Deprecated:
   * Replaced by columnMappings. Use of this still works,
   * but using both may cause information loss.
   */
  readonly columns?: InputMaybe<ReadonlyArray<InputMaybe<ImportColumn>>>;
  /**
   * Create missing organizational units automatically, if they don't exist.
   * If not provided, all organizational units must already exist.
   */
  readonly createMissingOrganizationalUnits?: InputMaybe<Scalars['Boolean']['input']>;
  readonly encoding?: InputMaybe<Scalars['String']['input']>;
  /**
   * Passing null here will set it to the default value, i.e. the first of
   * current month.
   */
  readonly fallbackDateForBenefits?: InputMaybe<Scalars['Date']['input']>;
  /** "Deprecated: Has no effect anymore, changes are always generated" */
  readonly generateChanges?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly separator?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateImportPayload {
  readonly __typename?: 'UpdateImportPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly import: Import;
}

export interface UpdateInsuranceAnalysisActionInput {
  readonly name: Scalars['ID']['input'];
  readonly status: InsuranceAnalysisActionStatus;
}

export interface UpdateInsuranceAnalysisActionPayload {
  readonly __typename?: 'UpdateInsuranceAnalysisActionPayload';
  readonly action: InsuranceAnalysisAction;
}

export interface UpdateInsuranceAnalysisPayload {
  readonly __typename?: 'UpdateInsuranceAnalysisPayload';
  readonly insuranceAnalysis: InsuranceAnalysis;
}

export interface UpdateInsuranceInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly instituteId: Scalars['String']['input'];
  readonly insuranceNumber: Scalars['String']['input'];
  readonly nextPremiumPayment: CurrencyValueInput;
  /** INACTIVE | ACTIVE | PAUSED */
  readonly premiumStatus: InsurancePremiumStatus;
  /** aka Zt */
  readonly retirementDate?: InputMaybe<Scalars['Date']['input']>;
  /** Dates in the future are not allowed, unless insurance is effective in the future */
  readonly statusEffectiveDate: Scalars['Date']['input'];
  readonly withdrawalPeriodYears?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateInsurancePayload {
  readonly __typename?: 'UpdateInsurancePayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly insurance: Insurance;
}

export interface UpdateInternalPostInput {
  readonly attachmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly costCenterIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly heroImageId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly important?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orgUnitIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly publicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly status?: InputMaybe<InternalPostStatus>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateInternalPostPayload {
  readonly __typename?: 'UpdateInternalPostPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly internalPost: Maybe<InternalPost>;
}

export interface UpdateInvoiceStatusInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly invoiceId: Scalars['ID']['input'];
  readonly status: InvoiceStatus;
}

export interface UpdateInvoiceStatusPayload {
  readonly __typename?: 'UpdateInvoiceStatusPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly invoice: Invoice;
}

export interface UpdateJobOfferInput {
  readonly attachmentFiles?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly benefitPackageId?: InputMaybe<Scalars['String']['input']>;
  readonly birthdate?: InputMaybe<Scalars['Date']['input']>;
  /** Rich text */
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly collectiveAgreement?: InputMaybe<TotalCompensationCollectiveAgreement>;
  readonly coverPictureFile?: InputMaybe<Scalars['String']['input']>;
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly monthlyCompanyCarValue?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlyLunchAllowance?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlySalary?: InputMaybe<Scalars['Money']['input']>;
  readonly optionsProgram?: InputMaybe<Scalars['String']['input']>;
  readonly optionsProgrammeFiles?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly profilePictureFile?: InputMaybe<Scalars['String']['input']>;
  readonly prospectName?: InputMaybe<Scalars['String']['input']>;
  readonly remunerations?: InputMaybe<ReadonlyArray<JobOfferRemunerationInput>>;
  readonly showDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly validUntil?: InputMaybe<Scalars['Date']['input']>;
  readonly workLocation?: InputMaybe<Scalars['String']['input']>;
  readonly workSetting?: InputMaybe<Scalars['String']['input']>;
  readonly yearlyVacationDays?: InputMaybe<Scalars['Int']['input']>;
  readonly yearlyWellnessAllowance?: InputMaybe<Scalars['Money']['input']>;
}

export interface UpdateMemberShipPayload {
  readonly __typename?: 'UpdateMemberShipPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly membership: Maybe<Membership>;
}

export interface UpdateMembershipBenefitPackageInput {
  readonly benefitPackageId: Scalars['ID']['input'];
  readonly companyId: Scalars['ID']['input'];
  readonly effectiveDate: Scalars['Date']['input'];
  readonly userAccountId: Scalars['ID']['input'];
}

export interface UpdateMembershipBenefitPackagePayload {
  readonly __typename?: 'UpdateMembershipBenefitPackagePayload';
  readonly benefitPackageId: Scalars['ID']['output'];
  readonly companyId: Scalars['ID']['output'];
  readonly effectiveDate: Scalars['Date']['output'];
  readonly userAccountId: Scalars['ID']['output'];
}

export interface UpdateMembershipCostCenterInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly costCenterId?: InputMaybe<Scalars['ID']['input']>;
  readonly userAccountId: Scalars['ID']['input'];
}

export interface UpdateMembershipCostCenterPayload {
  readonly __typename?: 'UpdateMembershipCostCenterPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly membership: Membership;
}

export interface UpdateMembershipInput {
  readonly address?: InputMaybe<MembershipAddressInput>;
  readonly birthdate?: InputMaybe<Scalars['Date']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly collectiveAgreement?: InputMaybe<Scalars['String']['input']>;
  readonly companyCarMonthlyValue?: InputMaybe<Scalars['Money']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly emailLoginAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly employeeNumber?: InputMaybe<Scalars['String']['input']>;
  readonly employerContributionShare?: InputMaybe<Scalars['DecimalFraction']['input']>;
  readonly fitnessContributionBalance?: InputMaybe<Scalars['Money']['input']>;
  readonly givenName?: InputMaybe<Scalars['String']['input']>;
  readonly incomeTaxTable?: InputMaybe<Scalars['Int']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** Setting this will not replace any existing naturalPersonIdentifier, only append new ones */
  readonly naturalPersonIdentifier?: InputMaybe<Scalars['NaturalPersonIdentifier']['input']>;
  readonly organizationalUnitId?: InputMaybe<Scalars['ID']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly roles?: InputMaybe<ReadonlyArray<MembershipRole>>;
  readonly sex?: InputMaybe<Sex>;
  readonly userAccountId: Scalars['ID']['input'];
  readonly vacationDaysAdvance?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysPaid?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysPerYear?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysSaved?: InputMaybe<Scalars['Int']['input']>;
  readonly vacationDaysUnpaid?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateMembershipSalaryInput {
  /** This field is deprecated and has no effect */
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyId: Scalars['ID']['input'];
  readonly effectiveDate: Scalars['Date']['input'];
  readonly monthlySalary: Scalars['Money']['input'];
  readonly userAccountId: Scalars['ID']['input'];
}

export interface UpdateMembershipSalaryPayload {
  readonly __typename?: 'UpdateMembershipSalaryPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly membership: Membership;
}

export interface UpdateNoteInput {
  readonly category?: InputMaybe<NoteCategory>;
  readonly clientMutationId?: InputMaybe<Scalars['ID']['input']>;
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly fileIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly id: Scalars['ID']['input'];
  readonly subject?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateNotePayload {
  readonly __typename?: 'UpdateNotePayload';
  readonly clientMutationId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['String']['output'];
}

export interface UpdateNotificationInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
  readonly status: NotificationStatus;
}

export interface UpdateNotificationPayload {
  readonly __typename?: 'UpdateNotificationPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface UpdateOrganizationalUnitInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly unitOf?: InputMaybe<Scalars['ID']['input']>;
}

export interface UpdateOrganizationalUnitPayload {
  readonly __typename?: 'UpdateOrganizationalUnitPayload';
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
  readonly organizationalUnit: OrganizationalUnit;
}

export interface UpdatePensionTransferAdviceInput {
  readonly destination?: InputMaybe<PensionTransferDestinationInput>;
  readonly effectOfLoss?: InputMaybe<EffectOfLoss>;
  readonly existingSources?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly manualSources?: InputMaybe<ReadonlyArray<PensionTransferSourceManualInput>>;
  readonly positiveImpactImportance?: InputMaybe<PositiveImpactImportance>;
  readonly productCapability?: InputMaybe<ProductCapability>;
  readonly productExperience?: InputMaybe<ProductExperience>;
  readonly rebalancePreference?: InputMaybe<RebalancePreference>;
  readonly reduceNegativeImpactImportance?: InputMaybe<ReduceNegativeImpactImportance>;
  readonly riskRewardPreference?: InputMaybe<RiskRewardPreference>;
  readonly sustainablePreference?: InputMaybe<SustainablePreference>;
  readonly transferReasons?: InputMaybe<ReadonlyArray<TransferReason>>;
}

export interface UpdatePensionTransferAdvicePayload {
  readonly __typename?: 'UpdatePensionTransferAdvicePayload';
  readonly pensionTransferAdvice: PensionTransferAdvice;
}

export interface UpdatePlusAdviceInput {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePlusAdvicePayload {
  readonly __typename?: 'UpdatePlusAdvicePayload';
  readonly advice: PlusAdvice;
  readonly clientMutationId: Maybe<Scalars['String']['output']>;
}

export interface UpdatePlusSuggestionStatusInput {
  readonly id: Scalars['ID']['input'];
  readonly status?: InputMaybe<PlusSuggestionStatus>;
}

export interface UpdatePlusSuggestionStatusPayload {
  readonly __typename?: 'UpdatePlusSuggestionStatusPayload';
  readonly suggestion: PlusSuggestion;
}

export interface UpdateProposalAccidentBenefitInput {
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly level: ProposalAccidentLevel;
}

export interface UpdateProposalBenefitPackageInput {
  readonly id: Scalars['ID']['input'];
  readonly name: Scalars['String']['input'];
  readonly proposalId: Scalars['ID']['input'];
}

export interface UpdateProposalEmployersPensionBenefitInput {
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly convertingPremiumWaiverFrom?: InputMaybe<Scalars['String']['input']>;
  readonly defaultManagementType: ProposalDefaultManagementType;
  readonly extraFixedPremium: Scalars['Boolean']['input'];
  readonly id: Scalars['ID']['input'];
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod;
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>;
  /** Premium matrix for the employers pension. Must be set if premiumCalculationMethod is CUSTOM_MATRIX */
  readonly premiumMatrix?: InputMaybe<ProposalPensionPremiumMatrixInput>;
  readonly premiumWaiver: Scalars['Boolean']['input'];
  readonly retirementAge: Scalars['Int']['input'];
}

export interface UpdateProposalHealthcareBenefitInput {
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  /** If this field is true, convertingFrom is required */
  readonly converting: Scalars['Boolean']['input'];
  /** This field is required if convertingFrom is set to true */
  readonly convertingFrom?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly level: ProposalHealthcareLevel;
  readonly waitingPeriod: Scalars['Boolean']['input'];
}

export interface UpdateProposalInput {
  readonly id: Scalars['ID']['input'];
  readonly need?: InputMaybe<ProposalNeedInput>;
  readonly productKnowledge?: InputMaybe<ProductKnowledgeInput>;
  readonly proposal?: InputMaybe<ProposalInput>;
  readonly summary?: InputMaybe<ProposalSummaryInput>;
}

export interface UpdateProposalLifeBenefitInput {
  readonly agreementType: LifeAgreementType;
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly level: ProposalLifeLevel;
}

export interface UpdateProposalMembershipInput {
  readonly benefitPackageId?: InputMaybe<Scalars['ID']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  /** Whether the user is fully capable for work or not, null before being set */
  readonly isFcfw?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the user is the owner of the company or not, defaults to false */
  readonly isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** Set if the admin wants the user to have fixed premium. Either on top of their proportional or on a fixed premium benefit. */
  readonly monthlyFixedPensionPremium?: InputMaybe<Scalars['Money']['input']>;
  readonly monthlySalary?: InputMaybe<Scalars['Money']['input']>;
  /** Coordination number or swedish personal identity number */
  readonly personalIdentityNumber?: InputMaybe<Scalars['NaturalPersonIdentifier']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly proposalId: Scalars['ID']['input'];
}

export interface UpdateProposalOccupationalPensionBenefitInput {
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly convertingPremiumWaiverFrom?: InputMaybe<Scalars['String']['input']>;
  readonly defaultManagementType: ProposalDefaultManagementType;
  readonly extraFixedPremium: Scalars['Boolean']['input'];
  readonly id: Scalars['ID']['input'];
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod;
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>;
  /** Premium matrix for the occupational pension. Must be set if premiumCalculationMethod is CUSTOM_MATRIX */
  readonly premiumMatrix?: InputMaybe<ProposalPensionPremiumMatrixInput>;
  readonly premiumWaiver: Scalars['Boolean']['input'];
  readonly retirementAge: Scalars['Int']['input'];
}

export interface UpdateProposalSalaryExchangeBenefitInput {
  readonly automaticApproval: Scalars['Boolean']['input'];
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly incomeMinIBB: Scalars['Float']['input'];
  readonly ongoingMax: Scalars['Money']['input'];
  readonly pensionTaxBenefitShare: Scalars['Float']['input'];
  readonly pensionToSalaryMax: Scalars['Float']['input'];
  readonly premiumWaiver: Scalars['Boolean']['input'];
  readonly retirementAge: Scalars['Int']['input'];
  /** This field is the yearly salary multiplier for this benefit. It must be either 12 or 12.2 */
  readonly salaryMultiplier: Scalars['Float']['input'];
}

export interface UpdateProposalSicknessBenefitInput {
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  /** If this field is true, convertingFrom is required */
  readonly converting: Scalars['Boolean']['input'];
  /** This field is required if convertingFrom is set to true */
  readonly convertingFrom?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the salary limit can be extended for 1-9 insured. Required for CompanySize.LT_10 */
  readonly extendSalaryLimit?: InputMaybe<Scalars['Boolean']['input']>;
  readonly extendedSickPay: Scalars['Boolean']['input'];
  readonly id: Scalars['ID']['input'];
  readonly level: ProposalSicknessLevel;
  /** This field is the yearly salary multiplier for this benefit. It must be either 12 or 12.2 */
  readonly salaryMultiplier: Scalars['Float']['input'];
}

export interface UpdateProposalTglBenefitInput {
  /** Array of benefit package ids must be provided. If empty it will remove all associations for this benefit. */
  readonly benefitPackageIds: ReadonlyArray<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
}

export interface UpdateUserAccountInput {
  readonly appData?: InputMaybe<Scalars['JSON']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly givenName?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** Can be either 'sv-SE' or 'en-US' */
  readonly localePreference?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserAccountPayload {
  readonly __typename?: 'UpdateUserAccountPayload';
  readonly userAccount: UserAccount;
}

export interface UploadPdfInvoiceInput {
  readonly companyId: Scalars['String']['input'];
  readonly dueDate?: InputMaybe<Scalars['Date']['input']>;
  readonly fileId: Scalars['String']['input'];
  readonly invoiceNumber: Scalars['String']['input'];
  readonly issueDate: Scalars['Date']['input'];
  readonly payableAmount: Scalars['Money']['input'];
  readonly supplier: InvoiceSupplier;
}

export interface UploadPdfInvoicePayload {
  readonly __typename?: 'UploadPdfInvoicePayload';
  readonly invoiceId: Scalars['String']['output'];
}

export interface UploadedCompanyFileInfoData {
  readonly __typename?: 'UploadedCompanyFileInfoData';
  readonly contentType: Scalars['String']['output'];
  readonly filename: Maybe<Scalars['String']['output']>;
  /** Filesize in bytes */
  readonly size: Scalars['Int']['output'];
  readonly url: Scalars['String']['output'];
}

export interface UpsertUserInput {
  /** A unique user identifier such as a Nordea ID or Google account email. */
  readonly externalId: Scalars['String']['input'];
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly roles: ReadonlyArray<Scalars['String']['input']>;
}

export interface UpsertUserPayload {
  readonly __typename?: 'UpsertUserPayload';
  readonly externalId: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly permissions: Scalars['JSON']['output'];
}

export interface UserAccount {
  readonly __typename?: 'UserAccount';
  readonly address: Maybe<Address>;
  /**
   * JSON blob that contains the user's app data. This is only intended to be used
   * by the frontend app and should not be read or updated by backend services.
   */
  readonly appData: Maybe<Scalars['JSON']['output']>;
  /**
   * The user's birthdate. If birthdate is not available from SPAR (or if the user
   * has not been verified in SPAR) it will fallback to extracting the birthdate
   * from the user's most relevant PNOSE natural person identifier (if available).
   */
  readonly birthdate: Maybe<Scalars['Date']['output']>;
  readonly customerSupportCode: Maybe<Scalars['Int']['output']>;
  readonly economicProfile: Maybe<EconomicProfile>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly externalLinks: ReadonlyArray<ExternalLink>;
  readonly financialLinks: ReadonlyArray<FinancialLink>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly givenName: Maybe<Scalars['String']['output']>;
  /** If the user currently have access to AdvinansPlus */
  readonly hasActivePlus: Maybe<Scalars['Boolean']['output']>;
  /** If the user has signed RetailCustomer */
  readonly hasSignedFri: Scalars['Boolean']['output'];
  /** If the user has signed AdvinansPlus terms */
  readonly hasSignedPlus: Scalars['Boolean']['output'];
  /**
   * If the user has an active Power of attorney
   * @deprecated Use poa instead
   */
  readonly hasSignedPoa: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly insuranceAnalysisHistory: Maybe<InsuranceAnalysisHistoryConnection>;
  readonly insurances: Maybe<InsuranceConnection>;
  readonly lastName: Maybe<Scalars['String']['output']>;
  /** Will return 'sv-SE' or 'en-US' */
  readonly localePreference: Maybe<Scalars['String']['output']>;
  readonly memberships: Maybe<MembershipConnection>;
  /**
   * Set of natural person identifiers, in ETSI EN 319 412 format,
   * that identify this user account. Ordered by priority, descending.
   */
  readonly naturalPersonIdentifiers: ReadonlyArray<Scalars['NaturalPersonIdentifier']['output']>;
  readonly pensionTransferAdvice: Maybe<PensionTransferAdvice>;
  /** @deprecated Use 'naturalPersonIdentifiers' instead */
  readonly personalIdentityNumber: Maybe<Scalars['IdentityNumber']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  /**
   * Plus advice history, with recent advice first
   *
   * Specify itemId to only fetch advice pertaining to that item.
   */
  readonly plusAdviceHistory: Maybe<PlusAdviceConnection>;
  /** Your active Plus profile, if you have one */
  readonly plusProfile: Maybe<PlusProfile>;
  /** Information about the user's Power of attorney */
  readonly poa: Maybe<Poa>;
  /** @deprecated Use 'address' instead */
  readonly primaryAddress: Maybe<Address>;
  /** Get movie tickets purchased by the current user. Sorted with the latest purchased ticket first. */
  readonly purchasedMovieTickets: Maybe<FlexPurchasedMovieTicketConnection>;
  /** Indicates whether the name has been verified in SPAR, Statens Personadressregister */
  readonly sparVerified: Scalars['Boolean']['output'];
  /** Flag indicating wether the user account has been suspended. */
  readonly suspended: Scalars['Boolean']['output'];
}


export interface UserAccountexternalLinksArgs {
  onlyStubs?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface UserAccountfinancialLinksArgs {
  includeAll?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface UserAccountinsuranceAnalysisHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface UserAccountinsurancesArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brokeredByAdvinans?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  instituteId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  policyholderCompanyId?: InputMaybe<Scalars['ID']['input']>;
}


export interface UserAccountpensionTransferAdviceArgs {
  destination?: InputMaybe<PensionTransferDestinationInput>;
  effectOfLoss?: InputMaybe<EffectOfLoss>;
  existingSources?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  manualSources?: InputMaybe<ReadonlyArray<PensionTransferSourceManualInput>>;
  positiveImpactImportance?: InputMaybe<PositiveImpactImportance>;
  productCapability?: InputMaybe<ProductCapability>;
  productExperience?: InputMaybe<ProductExperience>;
  rebalancePreference?: InputMaybe<RebalancePreference>;
  reduceNegativeImpactImportance?: InputMaybe<ReduceNegativeImpactImportance>;
  riskRewardPreference?: InputMaybe<RiskRewardPreference>;
  sustainablePreference?: InputMaybe<SustainablePreference>;
}


export interface UserAccountplusAdviceHistoryArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}


export interface UserAccountpoaArgs {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface UserAccountpurchasedMovieTicketsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}

export interface ValidityObject {
  readonly __typename?: 'ValidityObject';
  readonly errors: ReadonlyArray<Scalars['String']['output']>;
  readonly info: ReadonlyArray<Scalars['String']['output']>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly warnings: ReadonlyArray<Scalars['String']['output']>;
}

export interface WorkRole {
  readonly __typename?: 'WorkRole';
  readonly id: Maybe<EaWorkRoleId>;
}

export type pageInfoDetails = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type discountLayoutQuery_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly title: string,
  readonly locale: string
};

export type discountLayoutQuery_benefit_FlexBenefit_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string
};

export type discountLayoutQuery_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: discountLayoutQuery_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly supplier: discountLayoutQuery_benefit_FlexBenefit_supplier_FlexBenefitSupplier
};

export type discountLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefit: discountLayoutQuery_benefit_FlexBenefit | null
};


export type discountLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type discountLayoutQuery = discountLayoutQuery_Query;

export type editEmployeeLayoutQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type editEmployeeLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: editEmployeeLayoutQuery_membership_Membership | null
};


export type editEmployeeLayoutQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;


export type editEmployeeLayoutQuery = editEmployeeLayoutQuery_Query;

export type occupationalPensionChangeDetails = {
  readonly __typename?: 'FlexOccupationalPensionChange',
  readonly action: FlexChangeAction,
  readonly effectiveDate: string,
  readonly premium: string,
  readonly status: FlexChangeStatus,
  readonly type: FlexChangeType
};

export type employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_configuration = employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_configuration_EpassiBikeConfiguration | employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_configuration_MiscConfiguration;

export type employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit_configuration | null
};

export type employeePensionBenefitDetails = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly status: string,
  readonly grossPremium: string | null,
  readonly benefit: employeePensionBenefitDetails_FlexOccupationalPensionEntitlement_benefit_FlexBenefit
};

export type employeeDetailsLayoutQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type employeeDetailsLayoutQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string
};

export type employeeDetailsLayoutQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge',
  readonly node: employeeDetailsLayoutQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest
};

export type employeeDetailsLayoutQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly edges: ReadonlyArray<employeeDetailsLayoutQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge>
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement'
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement'
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = (
  {
  readonly __typename?: 'FlexOccupationalPensionEntitlement'
}
  & employeePensionBenefitDetails
);

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement'
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement'
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement'
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement'
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement'
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = (
  {
  readonly __typename?: 'FlexOccupationalPensionEntitlement'
}
  & employeePensionBenefitDetails
);

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement'
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement'
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement'
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type employeeDetailsLayoutQuery_changes_FlexOccupationalPensionChange = (
  {
  readonly __typename?: 'FlexOccupationalPensionChange'
}
  & occupationalPensionChangeDetails
);

export type employeeDetailsLayoutQuery_changes_FlexRetirementAgeChange = {
  readonly __typename?: 'FlexRetirementAgeChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskAccidentChange = {
  readonly __typename?: 'FlexRiskAccidentChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskHealthcareChange = {
  readonly __typename?: 'FlexRiskHealthcareChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskLifeChange = {
  readonly __typename?: 'FlexRiskLifeChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskPremiumWaiverChange = {
  readonly __typename?: 'FlexRiskPremiumWaiverChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskSicknessAndAccidentChange = {
  readonly __typename?: 'FlexRiskSicknessAndAccidentChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskSicknessChange = {
  readonly __typename?: 'FlexRiskSicknessChange'
};

export type employeeDetailsLayoutQuery_changes_FlexRiskTglChange = {
  readonly __typename?: 'FlexRiskTglChange'
};

export type employeeDetailsLayoutQuery_changes = employeeDetailsLayoutQuery_changes_FlexOccupationalPensionChange | employeeDetailsLayoutQuery_changes_FlexRetirementAgeChange | employeeDetailsLayoutQuery_changes_FlexRiskAccidentChange | employeeDetailsLayoutQuery_changes_FlexRiskHealthcareChange | employeeDetailsLayoutQuery_changes_FlexRiskLifeChange | employeeDetailsLayoutQuery_changes_FlexRiskPremiumWaiverChange | employeeDetailsLayoutQuery_changes_FlexRiskSicknessAndAccidentChange | employeeDetailsLayoutQuery_changes_FlexRiskSicknessChange | employeeDetailsLayoutQuery_changes_FlexRiskTglChange;

export type employeeDetailsLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: employeeDetailsLayoutQuery_membership_Membership | null,
  readonly salaryExchangeRequests: employeeDetailsLayoutQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null,
  readonly entitlements: employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection | null,
  readonly upcomingEntitlements: employeeDetailsLayoutQuery_upcomingEntitlements_FlexEntitlementConnection | null,
  readonly changes: ReadonlyArray<employeeDetailsLayoutQuery_changes> | null
};


export type employeeDetailsLayoutQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  salaryExchangeRequeststatuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  entitlementDate?: InputMaybe<Scalars['Date']['input']>;
}>;


export type employeeDetailsLayoutQuery = employeeDetailsLayoutQuery_Query;

export type jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge_node_JobOffer_remunerations_JobOfferRemuneration = {
  readonly __typename?: 'JobOfferRemuneration',
  readonly idCode: string,
  readonly value: string
};

export type jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge_node_JobOffer = {
  readonly __typename?: 'JobOffer',
  readonly id: string,
  readonly prospectName: string | null,
  readonly status: JobOfferStatus,
  readonly updatedAt: string | null,
  readonly remunerations: ReadonlyArray<jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge_node_JobOffer_remunerations_JobOfferRemuneration> | null
};

export type jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge = {
  readonly __typename?: 'JobOfferEdge',
  readonly node: jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge_node_JobOffer
};

export type jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection = {
  readonly __typename?: 'JobOfferConnection',
  readonly pageInfo: jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge>
};

export type jobOffersOverviewQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly jobOffers: jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection | null
};

export type jobOffersOverviewQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: jobOffersOverviewQuery_company_Company | null
};


export type jobOffersOverviewQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type jobOffersOverviewQuery = jobOffersOverviewQuery_Query;

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration = collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration | collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration;

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration | null
};

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type collectumInfoQuery_company_Company_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type collectumInfoQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefits: collectumInfoQuery_company_Company_benefits_FlexBenefitConnection | null
};

export type collectumInfoQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: collectumInfoQuery_company_Company | null
};


export type collectumInfoQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type collectumInfoQuery = collectumInfoQuery_Query;

export type terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly unitCode: string | null,
  readonly currency: string | null,
  readonly value: string
};

export type terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange = {
  readonly __typename?: 'FlexSalaryExchange',
  readonly id: string,
  readonly companyId: string,
  readonly userAccountId: string,
  readonly effectiveDate: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly provider: FlexSalaryExchangeProvider,
  readonly status: FlexSalaryExchangeStatus,
  readonly violated: boolean,
  readonly membership: terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_membership_Membership,
  readonly remuneration: terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_remuneration_RemunerationItem | null
};

export type terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge = {
  readonly __typename?: 'FlexSalaryExchangeEdge',
  readonly node: terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange
};

export type terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection = {
  readonly __typename?: 'FlexSalaryExchangeConnection',
  readonly edges: ReadonlyArray<terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge>
};

export type terminateSalaryExchangesTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchanges: terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection
};


export type terminateSalaryExchangesTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeStatus> | FlexSalaryExchangeStatus>;
}>;


export type terminateSalaryExchangesTableQuery = terminateSalaryExchangesTableQuery_Query;

export type updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal_validity_ProposalValidity_validProposal_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal_validity_ProposalValidity_validCompilation_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal_validity_ProposalValidity = {
  readonly __typename?: 'ProposalValidity',
  readonly validProposal: updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal_validity_ProposalValidity_validProposal_ValidityObject,
  readonly validCompilation: updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal_validity_ProposalValidity_validCompilation_ValidityObject
};

export type updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly validity: updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal_validity_ProposalValidity
};

export type updateCompilationLookedAtMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly setCompilationLookedAt: updateCompilationLookedAtMutation_setCompilationLookedAt_Proposal
};


export type updateCompilationLookedAtMutationVariables = Exact<{
  input: ProposalIdInput;
}>;


export type updateCompilationLookedAtMutation = updateCompilationLookedAtMutation_Mutation;

export type updateFeesLookedAtMutation_setFeesLookedAt_Proposal_validity_ProposalValidity_validCompilation_ValidityObject = (
  {
  readonly __typename?: 'ValidityObject'
}
  & validCompilationDetails
);

export type updateFeesLookedAtMutation_setFeesLookedAt_Proposal_validity_ProposalValidity_validFees_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateFeesLookedAtMutation_setFeesLookedAt_Proposal_validity_ProposalValidity = {
  readonly __typename?: 'ProposalValidity',
  readonly validCompilation: updateFeesLookedAtMutation_setFeesLookedAt_Proposal_validity_ProposalValidity_validCompilation_ValidityObject,
  readonly validFees: updateFeesLookedAtMutation_setFeesLookedAt_Proposal_validity_ProposalValidity_validFees_ValidityObject
};

export type updateFeesLookedAtMutation_setFeesLookedAt_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly validity: updateFeesLookedAtMutation_setFeesLookedAt_Proposal_validity_ProposalValidity
};

export type updateFeesLookedAtMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly setFeesLookedAt: updateFeesLookedAtMutation_setFeesLookedAt_Proposal
};


export type updateFeesLookedAtMutationVariables = Exact<{
  input: ProposalIdInput;
}>;


export type updateFeesLookedAtMutation = updateFeesLookedAtMutation_Mutation;

export type permissionsQuery_adminViewer_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly firstName: string,
  readonly externalId: string
};

export type permissionsQuery_Query = {
  readonly __typename?: 'Query',
  readonly permissions: any | null,
  readonly adminViewer: permissionsQuery_adminViewer_AdminUser | null
};


export type permissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type permissionsQuery = permissionsQuery_Query;

export type eventTypesQuery_eventTypes_EventType = {
  readonly __typename?: 'EventType',
  readonly uri: string,
  readonly name: string
};

export type eventTypesQuery_Query = {
  readonly __typename?: 'Query',
  readonly eventTypes: ReadonlyArray<eventTypesQuery_eventTypes_EventType>
};


export type eventTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type eventTypesQuery = eventTypesQuery_Query;

export type companyLayoutQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly displayName: string,
  readonly registrationNumber: string
};

export type companyLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyLayoutQuery_company_Company | null
};


export type companyLayoutQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyLayoutQuery = companyLayoutQuery_Query;

export type benefitPackageLayoutQuery_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type benefitPackageLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefitPackage: benefitPackageLayoutQuery_benefitPackage_BenefitPackage | null
};


export type benefitPackageLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type benefitPackageLayoutQuery = benefitPackageLayoutQuery_Query;

export type companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string,
  readonly title: string
};

export type companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_configuration = companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration | companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_configuration_MiscConfiguration;

export type companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit_configuration | null
};

export type companyBenefitsEditLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly flexBenefit: companyBenefitsEditLayoutQuery_flexBenefit_FlexBenefit | null
};


export type companyBenefitsEditLayoutQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
}>;


export type companyBenefitsEditLayoutQuery = companyBenefitsEditLayoutQuery_Query;

export type companyEmployeesImportLayoutQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly registrationNumber: string
};

export type companyEmployeesImportLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyEmployeesImportLayoutQuery_company_Company | null
};


export type companyEmployeesImportLayoutQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyEmployeesImportLayoutQuery = companyEmployeesImportLayoutQuery_Query;

export type smeCompaniesLayoutQuery_adminViewer_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string
};

export type smeCompaniesLayoutQuery_nlpSalesReps_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly externalId: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeCompaniesLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly adminViewer: smeCompaniesLayoutQuery_adminViewer_AdminUser | null,
  readonly nlpSalesReps: ReadonlyArray<smeCompaniesLayoutQuery_nlpSalesReps_AdminUser>
};


export type smeCompaniesLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type smeCompaniesLayoutQuery = smeCompaniesLayoutQuery_Query;

export type smeCompanyLayoutQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string
};

export type smeCompanyLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: smeCompanyLayoutQuery_company_Company | null
};


export type smeCompanyLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyLayoutQuery = smeCompanyLayoutQuery_Query;

export type smeCompanyProposalLayoutQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly adviceWaived: ProposalAdviceOption,
  readonly name: string
};

export type smeCompanyProposalLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyProposalLayoutQuery_proposal_Proposal | null
};


export type smeCompanyProposalLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyProposalLayoutQuery = smeCompanyProposalLayoutQuery_Query;

export type smeCompanyProposalEmployeeLayoutQuery_proposalMembership_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeCompanyProposalEmployeeLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposalMembership: smeCompanyProposalEmployeeLayoutQuery_proposalMembership_ProposalMembership
};


export type smeCompanyProposalEmployeeLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyProposalEmployeeLayoutQuery = smeCompanyProposalEmployeeLayoutQuery_Query;

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename: 'ProposalLifeBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename: 'ProposalTglBenefitConfig'
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly configuration: smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyProposalBenefitLayoutQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly benefit: smeCompanyProposalBenefitLayoutQuery_proposal_Proposal_benefit_ProposalBenefit
};

export type smeCompanyProposalBenefitLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyProposalBenefitLayoutQuery_proposal_Proposal | null
};


export type smeCompanyProposalBenefitLayoutQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
  benefitId: Scalars['ID']['input'];
}>;


export type smeCompanyProposalBenefitLayoutQuery = smeCompanyProposalBenefitLayoutQuery_Query;

export type sendMeetingInvitations_sendMeetingInvitations_SendMeetingInvitationsPayload = {
  readonly __typename?: 'SendMeetingInvitationsPayload',
  readonly clientMutationId: string | null
};

export type sendMeetingInvitations_Mutation = {
  readonly __typename?: 'Mutation',
  readonly sendMeetingInvitations: sendMeetingInvitations_sendMeetingInvitations_SendMeetingInvitationsPayload
};


export type sendMeetingInvitationsVariables = Exact<{
  input: SendMeetingInvitationsInput;
}>;


export type sendMeetingInvitations = sendMeetingInvitations_Mutation;

export type confirmMeetingMutation_confirmMeeting_ConfirmMeetingPayload = {
  readonly __typename?: 'ConfirmMeetingPayload',
  readonly clientMutationId: string | null
};

export type confirmMeetingMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly confirmMeeting: confirmMeetingMutation_confirmMeeting_ConfirmMeetingPayload
};


export type confirmMeetingMutationVariables = Exact<{
  input: ConfirmMeetingInput;
}>;


export type confirmMeetingMutation = confirmMeetingMutation_Mutation;

export type claimPensionTransferTasks_claimPensionTransferTasks_ClaimPensionTransferTasksPayload = {
  readonly __typename?: 'ClaimPensionTransferTasksPayload',
  readonly clientMutationId: string | null
};

export type claimPensionTransferTasks_Mutation = {
  readonly __typename?: 'Mutation',
  readonly claimPensionTransferTasks: claimPensionTransferTasks_claimPensionTransferTasks_ClaimPensionTransferTasksPayload
};


export type claimPensionTransferTasksVariables = Exact<{
  input: ClaimPensionTransferTasksInput;
}>;


export type claimPensionTransferTasks = claimPensionTransferTasks_Mutation;

export type deleteMeetingMutation_deleteMeeting_DeleteMeetingPayload = {
  readonly __typename?: 'DeleteMeetingPayload',
  readonly clientMutationId: string | null
};

export type deleteMeetingMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteMeeting: deleteMeetingMutation_deleteMeeting_DeleteMeetingPayload
};


export type deleteMeetingMutationVariables = Exact<{
  input: DeleteMeetingInput;
}>;


export type deleteMeetingMutation = deleteMeetingMutation_Mutation;

export type cancelMeetingMutation_cancelMeeting_CancelMeetingPayload = {
  readonly __typename?: 'CancelMeetingPayload',
  readonly clientMutationId: string | null
};

export type cancelMeetingMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly cancelMeeting: cancelMeetingMutation_cancelMeeting_CancelMeetingPayload
};


export type cancelMeetingMutationVariables = Exact<{
  input: CancelMeetingInput;
}>;


export type cancelMeetingMutation = cancelMeetingMutation_Mutation;

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly displayName: string,
  readonly registrationNumber: string
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_employment_M2Employment_remuneration_RemunerationItem_type_RemunerationItemType = {
  readonly __typename?: 'RemunerationItemType',
  readonly id: string
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_employment_M2Employment_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly price: string,
  readonly createdAt: string,
  readonly type: employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_employment_M2Employment_remuneration_RemunerationItem_type_RemunerationItemType
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_employment_M2Employment = {
  readonly __typename?: 'M2Employment',
  readonly from: string,
  readonly to: string | null,
  readonly remuneration: ReadonlyArray<employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_employment_M2Employment_remuneration_RemunerationItem>
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string,
  readonly company: employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_company_Company,
  readonly employment: ReadonlyArray<employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership_employment_M2Employment>
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice = {
  readonly __typename?: 'EmployeeWithPersonalAdvice',
  readonly userAccountId: string,
  readonly companyId: string,
  readonly latestMeetingDate: string | null,
  readonly allowedToHaveMeeting: boolean | null,
  readonly latestEmailSentAt: string | null,
  readonly membership: employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice_membership_Membership | null
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge = {
  readonly __typename?: 'EmployeeWithPersonalAdviceEdge',
  readonly cursor: string,
  readonly node: employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge_node_EmployeeWithPersonalAdvice
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_pageInfo_PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection = {
  readonly __typename?: 'EmployeeWithPersonalAdviceConnection',
  readonly edges: ReadonlyArray<employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_edges_EmployeeWithPersonalAdviceEdge>,
  readonly pageInfo: employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection_pageInfo_PageInfo
};

export type employeesWithPersonalAdviceQuery_Query = {
  readonly __typename?: 'Query',
  readonly employeesWithPersonalAdvice: employeesWithPersonalAdviceQuery_employeesWithPersonalAdvice_EmployeeWithPersonalAdviceConnection
};


export type employeesWithPersonalAdviceQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minBirthdate?: InputMaybe<Scalars['Date']['input']>;
  maxBirthdate?: InputMaybe<Scalars['Date']['input']>;
  today: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type employeesWithPersonalAdviceQuery = employeesWithPersonalAdviceQuery_Query;

export type membershipByPinAndRegNoQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type membershipByPinAndRegNoQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename?: 'Membership',
  readonly userAccountId: string,
  readonly company: membershipByPinAndRegNoQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_company_Company
};

export type membershipByPinAndRegNoQuery_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: membershipByPinAndRegNoQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type membershipByPinAndRegNoQuery_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<membershipByPinAndRegNoQuery_memberships_MembershipConnection_edges_MembershipEdge>
};

export type membershipByPinAndRegNoQuery_Query = {
  readonly __typename?: 'Query',
  readonly memberships: membershipByPinAndRegNoQuery_memberships_MembershipConnection | null
};


export type membershipByPinAndRegNoQueryVariables = Exact<{
  personalIdentityNumber: Scalars['IdentityNumber']['input'];
  registrationNumber: Scalars['String']['input'];
}>;


export type membershipByPinAndRegNoQuery = membershipByPinAndRegNoQuery_Query;

export type employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting_membership_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly displayName: string
};

export type employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string,
  readonly company: employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting_membership_Membership_company_Company
};

export type employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting = {
  readonly __typename?: 'EmployeeMeeting',
  readonly id: string,
  readonly userAccountId: string,
  readonly companyId: string,
  readonly date: string,
  readonly confirmed: boolean,
  readonly eventTypeCategory: EventTypeCategory,
  readonly latestEmailSentAt: string | null,
  readonly membership: employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting_membership_Membership | null
};

export type employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge = {
  readonly __typename?: 'EmployeeMeetingEdge',
  readonly cursor: string,
  readonly node: employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting
};

export type employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_pageInfo_PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection = {
  readonly __typename?: 'EmployeeMeetingConnection',
  readonly edges: ReadonlyArray<employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge>,
  readonly pageInfo: employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_pageInfo_PageInfo
};

export type employeeMeetingsQuery_Query = {
  readonly __typename?: 'Query',
  readonly employeeMeetings: employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection
};


export type employeeMeetingsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  advisorId?: InputMaybe<Scalars['ID']['input']>;
  eventTypeCategories?: InputMaybe<ReadonlyArray<EventTypeCategory> | EventTypeCategory>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type employeeMeetingsQuery = employeeMeetingsQuery_Query;

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_flexBenefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly displayName: string,
  readonly name: string,
  readonly flexBenefits: companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_flexBenefits_FlexBenefitConnection | null,
  readonly benefitPackages: companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_benefitPackages_BenefitPackageConnection | null
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge = {
  readonly __typename?: 'CompanyEdge',
  readonly node: companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company
};

export type companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection = {
  readonly __typename?: 'CompanyConnection',
  readonly edges: ReadonlyArray<companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection_edges_CompanyEdge>
};

export type companiesWithPersonalAdviceBenefitQuery_Query = {
  readonly __typename?: 'Query',
  readonly companies: companiesWithPersonalAdviceBenefitQuery_companies_CompanyConnection | null
};


export type companiesWithPersonalAdviceBenefitQueryVariables = Exact<{ [key: string]: never; }>;


export type companiesWithPersonalAdviceBenefitQuery = companiesWithPersonalAdviceBenefitQuery_Query;

export type advisorsQuery_advisors_Advisor = {
  readonly __typename?: 'Advisor',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type advisorsQuery_Query = {
  readonly __typename?: 'Query',
  readonly advisors: ReadonlyArray<advisorsQuery_advisors_Advisor>
};


export type advisorsQueryVariables = Exact<{ [key: string]: never; }>;


export type advisorsQuery = advisorsQuery_Query;

export type meetingStatisticsQuery_meetingStatistics_MeetingStatistics_numberOfRemindersSent_RemindersSent = {
  readonly __typename?: 'RemindersSent',
  readonly first: number,
  readonly second: number
};

export type meetingStatisticsQuery_meetingStatistics_MeetingStatistics = {
  readonly __typename?: 'MeetingStatistics',
  readonly numberOfInvitationsSent: number,
  readonly numberOfBookedMeetings: number,
  readonly numberOfCreatedBookings: number,
  readonly numberOfCancelledMeetings: number,
  readonly numberOfConfirmedMeetings: number,
  readonly numberOfRemindersSent: meetingStatisticsQuery_meetingStatistics_MeetingStatistics_numberOfRemindersSent_RemindersSent
};

export type meetingStatisticsQuery_Query = {
  readonly __typename?: 'Query',
  readonly meetingStatistics: meetingStatisticsQuery_meetingStatistics_MeetingStatistics
};


export type meetingStatisticsQueryVariables = Exact<{
  companyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  eventTypeCategories?: InputMaybe<ReadonlyArray<EventTypeCategory> | EventTypeCategory>;
  advisorId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type meetingStatisticsQuery = meetingStatisticsQuery_Query;

export type ptStatisticsQuery_pensionTransferStatistics_PensionTransferStatistics_transferredCapitalToInstitute_TransferredCapitalByInstitute = {
  readonly __typename?: 'TransferredCapitalByInstitute',
  readonly instituteId: string,
  readonly capital: string
};

export type ptStatisticsQuery_pensionTransferStatistics_PensionTransferStatistics = {
  readonly __typename?: 'PensionTransferStatistics',
  readonly totalTransferredCapital: string,
  readonly transferredCapitalToInstitute: ReadonlyArray<ptStatisticsQuery_pensionTransferStatistics_PensionTransferStatistics_transferredCapitalToInstitute_TransferredCapitalByInstitute>
};

export type ptStatisticsQuery_Query = {
  readonly __typename?: 'Query',
  readonly pensionTransferStatistics: ptStatisticsQuery_pensionTransferStatistics_PensionTransferStatistics
};


export type ptStatisticsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  advisorId?: InputMaybe<Scalars['ID']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ptStatisticsQuery = ptStatisticsQuery_Query;

export type signedPensionTransfersQuery_signedPensionTransfers_SignedPensionTransfer_sources_SignedPensionTransferSource = {
  readonly __typename?: 'SignedPensionTransferSource',
  readonly insuranceNumber: string,
  readonly capital: string
};

export type signedPensionTransfersQuery_signedPensionTransfers_SignedPensionTransfer = {
  readonly __typename?: 'SignedPensionTransfer',
  readonly id: number,
  readonly sources: ReadonlyArray<signedPensionTransfersQuery_signedPensionTransfers_SignedPensionTransfer_sources_SignedPensionTransferSource>
};

export type signedPensionTransfersQuery_Query = {
  readonly __typename?: 'Query',
  readonly signedPensionTransfers: ReadonlyArray<signedPensionTransfersQuery_signedPensionTransfers_SignedPensionTransfer>
};


export type signedPensionTransfersQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
}>;


export type signedPensionTransfersQuery = signedPensionTransfersQuery_Query;

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly displayName: string,
  readonly id: string
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment = {
  readonly __typename?: 'Employment',
  readonly period: [string, string | null]
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employments_EmploymentConnection_edges_EmploymentEdge = {
  readonly __typename?: 'EmploymentEdge',
  readonly node: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employments_EmploymentConnection = {
  readonly __typename?: 'EmploymentConnection',
  readonly edges: ReadonlyArray<employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employments_EmploymentConnection_edges_EmploymentEdge>
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string,
  readonly company: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_company_Company,
  readonly employments: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employments_EmploymentConnection | null
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge>
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital = {
  readonly __typename?: 'EmployeeWithTransferableCapital',
  readonly userAccountId: string,
  readonly capital: string,
  readonly latestEmailSentAt: string | null,
  readonly latestMeetingDate: string | null,
  readonly memberships: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection | null
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge = {
  readonly __typename?: 'EmployeeWithTransferableCapitalEdge',
  readonly cursor: string,
  readonly node: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_pageInfo_PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection = {
  readonly __typename?: 'EmployeeWithTransferableCapitalConnection',
  readonly edges: ReadonlyArray<employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge>,
  readonly pageInfo: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_pageInfo_PageInfo
};

export type employeesWithTransferableCapitalQuery_Query = {
  readonly __typename?: 'Query',
  readonly employeesWithTransferableCapital: employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection
};


export type employeesWithTransferableCapitalQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  minCapital?: InputMaybe<Scalars['Int']['input']>;
  maxCapital?: InputMaybe<Scalars['Int']['input']>;
  brokeredByNode?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  sortBy?: InputMaybe<SortTransferableCapitalBy>;
  sortOrder?: InputMaybe<SortOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type employeesWithTransferableCapitalQuery = employeesWithTransferableCapitalQuery_Query;

export type addCustomTypeMutation_addCustomType_CustomType = {
  readonly __typename?: 'CustomType',
  readonly id: string
};

export type addCustomTypeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addCustomType: addCustomTypeMutation_addCustomType_CustomType | null
};


export type addCustomTypeMutationVariables = Exact<{
  input?: InputMaybe<AddCustomTypeInput>;
}>;


export type addCustomTypeMutation = addCustomTypeMutation_Mutation;

export type updateBookkeepingAccountMutation_updateBookkeepingAccount_BookkeepingAccount = {
  readonly __typename?: 'BookkeepingAccount',
  readonly accountKey: BookkeepingAccountKey
};

export type updateBookkeepingAccountMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateBookkeepingAccount: updateBookkeepingAccountMutation_updateBookkeepingAccount_BookkeepingAccount | null
};


export type updateBookkeepingAccountMutationVariables = Exact<{
  input: UpdateBookkeepingAccountInput;
}>;


export type updateBookkeepingAccountMutation = updateBookkeepingAccountMutation_Mutation;

export type companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge_node_CustomType = {
  readonly __typename?: 'CustomType',
  readonly id: string,
  readonly name: string | null,
  readonly superType: SUPER_TYPE,
  readonly unitCode: string | null,
  readonly currency: string | null
};

export type companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge = {
  readonly __typename?: 'CustomTypeEdge',
  readonly node: companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge_node_CustomType
};

export type companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection = {
  readonly __typename?: 'CustomTypeConnection',
  readonly pageInfo: companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge>
};

export type companyCustomTypesQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly customTypes: companyCustomTypesQuery_company_Company_customTypes_CustomTypeConnection | null
};

export type companyCustomTypesQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyCustomTypesQuery_company_Company | null
};


export type companyCustomTypesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  superType?: InputMaybe<SUPER_TYPE>;
  search?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type companyCustomTypesQuery = companyCustomTypesQuery_Query;

export type companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection_edges_BookkeepingAccountEdge_node_BookkeepingAccount = {
  readonly __typename?: 'BookkeepingAccount',
  readonly accountKey: BookkeepingAccountKey,
  readonly customAccountName: string | null,
  readonly customAccountNumber: string | null
};

export type companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection_edges_BookkeepingAccountEdge = {
  readonly __typename?: 'BookkeepingAccountEdge',
  readonly node: companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection_edges_BookkeepingAccountEdge_node_BookkeepingAccount
};

export type companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection = {
  readonly __typename?: 'BookkeepingAccountConnection',
  readonly pageInfo: companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection_edges_BookkeepingAccountEdge>
};

export type companyBookkeepingAccountsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly bookkeepingAccounts: companyBookkeepingAccountsQuery_company_Company_bookkeepingAccounts_BookkeepingAccountConnection | null
};

export type companyBookkeepingAccountsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyBookkeepingAccountsQuery_company_Company | null
};


export type companyBookkeepingAccountsQueryVariables = Exact<{
  includeDefault?: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type companyBookkeepingAccountsQuery = companyBookkeepingAccountsQuery_Query;

export type updateMembershipRolesMutation_updateMembership_UpdateMemberShipPayload_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly roles: ReadonlyArray<MembershipRole>
};

export type updateMembershipRolesMutation_updateMembership_UpdateMemberShipPayload = {
  readonly __typename?: 'UpdateMemberShipPayload',
  readonly membership: updateMembershipRolesMutation_updateMembership_UpdateMemberShipPayload_membership_Membership | null
};

export type updateMembershipRolesMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateMembership: updateMembershipRolesMutation_updateMembership_UpdateMemberShipPayload | null
};


export type updateMembershipRolesMutationVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  roles: ReadonlyArray<MembershipRole> | MembershipRole;
}>;


export type updateMembershipRolesMutation = updateMembershipRolesMutation_Mutation;

export type administratorDetails = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly firstName: string,
  readonly lastName: string,
  readonly roles: ReadonlyArray<MembershipRole>
};

export type companyAdministratorsQuery_company_Company_administrators_MembershipConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyAdministratorsQuery_company_Company_administrators_MembershipConnection_edges_MembershipEdge_node_Membership = (
  {
  readonly __typename?: 'Membership'
}
  & administratorDetails
);

export type companyAdministratorsQuery_company_Company_administrators_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: companyAdministratorsQuery_company_Company_administrators_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type companyAdministratorsQuery_company_Company_administrators_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly pageInfo: companyAdministratorsQuery_company_Company_administrators_MembershipConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyAdministratorsQuery_company_Company_administrators_MembershipConnection_edges_MembershipEdge>
};

export type companyAdministratorsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly administrators: companyAdministratorsQuery_company_Company_administrators_MembershipConnection | null
};

export type companyAdministratorsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyAdministratorsQuery_company_Company | null
};


export type companyAdministratorsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type companyAdministratorsQuery = companyAdministratorsQuery_Query;

export type benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type benefitPackagesOptionsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly benefitPackages: benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection | null
};

export type benefitPackagesOptionsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: benefitPackagesOptionsQuery_company_Company | null
};


export type benefitPackagesOptionsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type benefitPackagesOptionsQuery = benefitPackagesOptionsQuery_Query;

export type companyAgreementsQuery_nlpSalesReps_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementActiway = {
  readonly __typename?: 'AgreementActiway',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementAdminPoa = {
  readonly __typename?: 'AgreementAdminPoa',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementCustom = {
  readonly __typename?: 'AgreementCustom',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementEuroAccident = {
  readonly __typename?: 'AgreementEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementNordea = {
  readonly __typename?: 'AgreementNordea',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementNordnet = {
  readonly __typename?: 'AgreementNordnet',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementRequestAdminPoa = {
  readonly __typename?: 'AgreementRequestAdminPoa',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementRequestEuroAccident = {
  readonly __typename?: 'AgreementRequestEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementRequestNordea = {
  readonly __typename?: 'AgreementRequestNordea',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementRequestNordnet = {
  readonly __typename?: 'AgreementRequestNordnet',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementRequestSuperAdmin = {
  readonly __typename?: 'AgreementRequestSuperAdmin',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements_AgreementSuperAdmin = {
  readonly __typename?: 'AgreementSuperAdmin',
  readonly id: string
};

export type companyAgreementsQuery_company_Company_agreements = companyAgreementsQuery_company_Company_agreements_AgreementActiway | companyAgreementsQuery_company_Company_agreements_AgreementAdminPoa | companyAgreementsQuery_company_Company_agreements_AgreementCustom | companyAgreementsQuery_company_Company_agreements_AgreementEuroAccident | companyAgreementsQuery_company_Company_agreements_AgreementNordea | companyAgreementsQuery_company_Company_agreements_AgreementNordnet | companyAgreementsQuery_company_Company_agreements_AgreementRequestAdminPoa | companyAgreementsQuery_company_Company_agreements_AgreementRequestEuroAccident | companyAgreementsQuery_company_Company_agreements_AgreementRequestNordea | companyAgreementsQuery_company_Company_agreements_AgreementRequestNordnet | companyAgreementsQuery_company_Company_agreements_AgreementRequestSuperAdmin | companyAgreementsQuery_company_Company_agreements_AgreementSuperAdmin;

export type companyAgreementsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly managedBy: ManagingCompany,
  readonly agreements: ReadonlyArray<companyAgreementsQuery_company_Company_agreements>
};

export type companyAgreementsQuery_Query = {
  readonly __typename?: 'Query',
  readonly nlpSalesReps: ReadonlyArray<companyAgreementsQuery_nlpSalesReps_AdminUser>,
  readonly company: companyAgreementsQuery_company_Company | null
};


export type companyAgreementsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type companyAgreementsQuery = companyAgreementsQuery_Query;

export type agreementDetails_AgreementActiway_attachments_AgreementAttachment = {
  readonly __typename?: 'AgreementAttachment',
  readonly name: string,
  readonly type: string,
  readonly url: string | null
};

export type agreementDetails_AgreementActiway = {
  readonly __typename?: 'AgreementActiway',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementAdminPoa = {
  readonly __typename?: 'AgreementAdminPoa',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementCustom = {
  readonly __typename?: 'AgreementCustom',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementEuroAccident = {
  readonly __typename?: 'AgreementEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementNordea = {
  readonly __typename?: 'AgreementNordea',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementNordnet = {
  readonly __typename?: 'AgreementNordnet',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementRequestAdminPoa = {
  readonly __typename?: 'AgreementRequestAdminPoa',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementRequestEuroAccident = {
  readonly __typename?: 'AgreementRequestEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementRequestNordea = {
  readonly __typename?: 'AgreementRequestNordea',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementRequestNordnet = {
  readonly __typename?: 'AgreementRequestNordnet',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementRequestSuperAdmin = {
  readonly __typename?: 'AgreementRequestSuperAdmin',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails_AgreementSuperAdmin = {
  readonly __typename?: 'AgreementSuperAdmin',
  readonly id: string,
  readonly status: AgreementStatus,
  readonly attachments: ReadonlyArray<agreementDetails_AgreementActiway_attachments_AgreementAttachment>
};

export type agreementDetails = agreementDetails_AgreementActiway | agreementDetails_AgreementAdminPoa | agreementDetails_AgreementCustom | agreementDetails_AgreementEuroAccident | agreementDetails_AgreementNordea | agreementDetails_AgreementNordnet | agreementDetails_AgreementRequestAdminPoa | agreementDetails_AgreementRequestEuroAccident | agreementDetails_AgreementRequestNordea | agreementDetails_AgreementRequestNordnet | agreementDetails_AgreementRequestSuperAdmin | agreementDetails_AgreementSuperAdmin;

export type agreementsListQuery_company_Company_agreements_AgreementActiway = (
  {
  readonly __typename?: 'AgreementActiway'
}
  & agreementDetails_AgreementActiway
);

export type agreementsListQuery_company_Company_agreements_AgreementAdminPoa = (
  {
  readonly __typename?: 'AgreementAdminPoa'
}
  & agreementDetails_AgreementAdminPoa
);

export type agreementsListQuery_company_Company_agreements_AgreementCustom = (
  {
  readonly __typename?: 'AgreementCustom'
}
  & agreementDetails_AgreementCustom
);

export type agreementsListQuery_company_Company_agreements_AgreementEuroAccident = (
  {
  readonly __typename?: 'AgreementEuroAccident'
}
  & agreementDetails_AgreementEuroAccident
);

export type agreementsListQuery_company_Company_agreements_AgreementNordea = (
  {
  readonly __typename?: 'AgreementNordea'
}
  & agreementDetails_AgreementNordea
);

export type agreementsListQuery_company_Company_agreements_AgreementNordnet = (
  {
  readonly __typename?: 'AgreementNordnet'
}
  & agreementDetails_AgreementNordnet
);

export type agreementsListQuery_company_Company_agreements_AgreementRequestAdminPoa = (
  {
  readonly __typename?: 'AgreementRequestAdminPoa'
}
  & agreementDetails_AgreementRequestAdminPoa
);

export type agreementsListQuery_company_Company_agreements_AgreementRequestEuroAccident = (
  {
  readonly __typename?: 'AgreementRequestEuroAccident'
}
  & agreementDetails_AgreementRequestEuroAccident
);

export type agreementsListQuery_company_Company_agreements_AgreementRequestNordea = (
  {
  readonly __typename?: 'AgreementRequestNordea'
}
  & agreementDetails_AgreementRequestNordea
);

export type agreementsListQuery_company_Company_agreements_AgreementRequestNordnet = (
  {
  readonly __typename?: 'AgreementRequestNordnet'
}
  & agreementDetails_AgreementRequestNordnet
);

export type agreementsListQuery_company_Company_agreements_AgreementRequestSuperAdmin = (
  {
  readonly __typename?: 'AgreementRequestSuperAdmin'
}
  & agreementDetails_AgreementRequestSuperAdmin
);

export type agreementsListQuery_company_Company_agreements_AgreementSuperAdmin = (
  {
  readonly __typename?: 'AgreementSuperAdmin'
}
  & agreementDetails_AgreementSuperAdmin
);

export type agreementsListQuery_company_Company_agreements = agreementsListQuery_company_Company_agreements_AgreementActiway | agreementsListQuery_company_Company_agreements_AgreementAdminPoa | agreementsListQuery_company_Company_agreements_AgreementCustom | agreementsListQuery_company_Company_agreements_AgreementEuroAccident | agreementsListQuery_company_Company_agreements_AgreementNordea | agreementsListQuery_company_Company_agreements_AgreementNordnet | agreementsListQuery_company_Company_agreements_AgreementRequestAdminPoa | agreementsListQuery_company_Company_agreements_AgreementRequestEuroAccident | agreementsListQuery_company_Company_agreements_AgreementRequestNordea | agreementsListQuery_company_Company_agreements_AgreementRequestNordnet | agreementsListQuery_company_Company_agreements_AgreementRequestSuperAdmin | agreementsListQuery_company_Company_agreements_AgreementSuperAdmin;

export type agreementsListQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly agreements: ReadonlyArray<agreementsListQuery_company_Company_agreements>
};

export type agreementsListQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: agreementsListQuery_company_Company | null
};


export type agreementsListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type agreementsListQuery = agreementsListQuery_Query;

export type addBenefitsToBenefitPackageMutation_addBenefitsToBenefitPackage_FlexAddBenefitsToBenefitPackagePayload = {
  readonly __typename?: 'FlexAddBenefitsToBenefitPackagePayload',
  readonly clientMutationId: string | null
};

export type addBenefitsToBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addBenefitsToBenefitPackage: addBenefitsToBenefitPackageMutation_addBenefitsToBenefitPackage_FlexAddBenefitsToBenefitPackagePayload
};


export type addBenefitsToBenefitPackageMutationVariables = Exact<{
  input: FlexAddBenefitsToBenefitPackageInput;
}>;


export type addBenefitsToBenefitPackageMutation = addBenefitsToBenefitPackageMutation_Mutation;

export type addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly title: string,
  readonly locale: string
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_entitlementRules_FlexEntitlementRules = {
  readonly __typename?: 'FlexEntitlementRules',
  readonly benefitPackageIds: ReadonlyArray<string>
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory = {
  readonly __typename?: 'FlexBenefitCategory',
  readonly id: string,
  readonly name: string
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration = addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration | addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration;

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent,
  readonly supplier: addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_supplier_FlexBenefitSupplier,
  readonly entitlementRules: addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_entitlementRules_FlexEntitlementRules | null,
  readonly categories: ReadonlyArray<addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory>,
  readonly configuration: addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration | null
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type addBenefitsToBenefitPackageQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly managedBy: ManagingCompany,
  readonly benefitPackages: addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection | null,
  readonly benefits: addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection | null
};

export type addBenefitsToBenefitPackageQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: addBenefitsToBenefitPackageQuery_company_Company | null
};


export type addBenefitsToBenefitPackageQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  onlyGetBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type addBenefitsToBenefitPackageQuery = addBenefitsToBenefitPackageQuery_Query;

export type createBenefitPackageMutation_createBenefitPackage_CreateBenefitPackagePayload_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type createBenefitPackageMutation_createBenefitPackage_CreateBenefitPackagePayload = {
  readonly __typename?: 'CreateBenefitPackagePayload',
  readonly benefitPackage: createBenefitPackageMutation_createBenefitPackage_CreateBenefitPackagePayload_benefitPackage_BenefitPackage | null
};

export type createBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createBenefitPackage: createBenefitPackageMutation_createBenefitPackage_CreateBenefitPackagePayload | null
};


export type createBenefitPackageMutationVariables = Exact<{
  input: CreateBenefitPackageInput;
}>;


export type createBenefitPackageMutation = createBenefitPackageMutation_Mutation;

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem_type_StatusItemType = {
  readonly __typename?: 'StatusItemType',
  readonly id: string
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem = {
  readonly __typename?: 'StatusItem',
  readonly from: string,
  readonly to: string | null,
  readonly type: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem_type_StatusItemType | null
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment = {
  readonly __typename?: 'M2Employment',
  readonly from: string,
  readonly to: string | null,
  readonly status: ReadonlyArray<benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem>
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage = {
  readonly __typename?: 'MembershipBenefitPackage',
  readonly benefitPackage: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage_benefitPackage_BenefitPackage
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly monthlySalary: string | null,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly employment: ReadonlyArray<benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment>,
  readonly membershipBenefitPackage: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage | null
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge>,
  readonly pageInfo: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection_pageInfo_PageInfo
};

export type benefitPackageMembershipsQuery_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly memberships: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage_memberships_MembershipConnection | null
};

export type benefitPackageMembershipsQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefitPackage: benefitPackageMembershipsQuery_benefitPackage_BenefitPackage | null
};


export type benefitPackageMembershipsQueryVariables = Exact<{
  benefitPackageId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type benefitPackageMembershipsQuery = benefitPackageMembershipsQuery_Query;

export type renameBenefitPackageMutation_updateBenefitPackage_UpdateBenefitPackagePayload_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type renameBenefitPackageMutation_updateBenefitPackage_UpdateBenefitPackagePayload = {
  readonly __typename?: 'UpdateBenefitPackagePayload',
  readonly benefitPackage: renameBenefitPackageMutation_updateBenefitPackage_UpdateBenefitPackagePayload_benefitPackage_BenefitPackage | null
};

export type renameBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateBenefitPackage: renameBenefitPackageMutation_updateBenefitPackage_UpdateBenefitPackagePayload | null
};


export type renameBenefitPackageMutationVariables = Exact<{
  input: UpdateBenefitPackageInput;
}>;


export type renameBenefitPackageMutation = renameBenefitPackageMutation_Mutation;

export type renameBenefitPackageQuery_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type renameBenefitPackageQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefitPackage: renameBenefitPackageQuery_benefitPackage_BenefitPackage | null
};


export type renameBenefitPackageQueryVariables = Exact<{
  benefitPackageId: Scalars['ID']['input'];
}>;


export type renameBenefitPackageQuery = renameBenefitPackageQuery_Query;

export type updateBenefitPackageSettingsMutation_updateBenefitPackage_UpdateBenefitPackagePayload_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly hasBenefitPortal: boolean,
  readonly showTotalCompensation: boolean
};

export type updateBenefitPackageSettingsMutation_updateBenefitPackage_UpdateBenefitPackagePayload = {
  readonly __typename?: 'UpdateBenefitPackagePayload',
  readonly benefitPackage: updateBenefitPackageSettingsMutation_updateBenefitPackage_UpdateBenefitPackagePayload_benefitPackage_BenefitPackage | null
};

export type updateBenefitPackageSettingsMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateBenefitPackage: updateBenefitPackageSettingsMutation_updateBenefitPackage_UpdateBenefitPackagePayload | null
};


export type updateBenefitPackageSettingsMutationVariables = Exact<{
  input: UpdateBenefitPackageInput;
}>;


export type updateBenefitPackageSettingsMutation = updateBenefitPackageSettingsMutation_Mutation;

export type benefitPackageSettingsQuery_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly hasBenefitPortal: boolean,
  readonly showTotalCompensation: boolean
};

export type benefitPackageSettingsQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefitPackage: benefitPackageSettingsQuery_benefitPackage_BenefitPackage | null
};


export type benefitPackageSettingsQueryVariables = Exact<{
  benefitPackageId: Scalars['ID']['input'];
}>;


export type benefitPackageSettingsQuery = benefitPackageSettingsQuery_Query;

export type createBenefitsMutation_createBenefits_FlexCreateBenefitsPayload_benefits_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string
};

export type createBenefitsMutation_createBenefits_FlexCreateBenefitsPayload = {
  readonly __typename?: 'FlexCreateBenefitsPayload',
  readonly benefits: ReadonlyArray<createBenefitsMutation_createBenefits_FlexCreateBenefitsPayload_benefits_FlexBenefit>
};

export type createBenefitsMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createBenefits: createBenefitsMutation_createBenefits_FlexCreateBenefitsPayload
};


export type createBenefitsMutationVariables = Exact<{
  input: FlexCreateBenefitsInput;
}>;


export type createBenefitsMutation = createBenefitsMutation_Mutation;

export type companyCreateBenefitOptionsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly managedBy: ManagingCompany
};

export type companyCreateBenefitOptionsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyCreateBenefitOptionsQuery_company_Company | null
};


export type companyCreateBenefitOptionsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyCreateBenefitOptionsQuery = companyCreateBenefitOptionsQuery_Query;

export type companyCreateBenefitQuery_company_Company_agreements_AgreementActiway = {
  readonly __typename?: 'AgreementActiway',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementAdminPoa = {
  readonly __typename?: 'AgreementAdminPoa',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementCustom = {
  readonly __typename?: 'AgreementCustom',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementEuroAccident = {
  readonly __typename?: 'AgreementEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementNordea = {
  readonly __typename?: 'AgreementNordea',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementNordnet = {
  readonly __typename?: 'AgreementNordnet',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementRequestAdminPoa = {
  readonly __typename?: 'AgreementRequestAdminPoa',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementRequestEuroAccident = {
  readonly __typename?: 'AgreementRequestEuroAccident',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementRequestNordea = {
  readonly __typename?: 'AgreementRequestNordea',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementRequestNordnet = {
  readonly __typename?: 'AgreementRequestNordnet',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementRequestSuperAdmin = {
  readonly __typename?: 'AgreementRequestSuperAdmin',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements_AgreementSuperAdmin = {
  readonly __typename?: 'AgreementSuperAdmin',
  readonly id: string
};

export type companyCreateBenefitQuery_company_Company_agreements = companyCreateBenefitQuery_company_Company_agreements_AgreementActiway | companyCreateBenefitQuery_company_Company_agreements_AgreementAdminPoa | companyCreateBenefitQuery_company_Company_agreements_AgreementCustom | companyCreateBenefitQuery_company_Company_agreements_AgreementEuroAccident | companyCreateBenefitQuery_company_Company_agreements_AgreementNordea | companyCreateBenefitQuery_company_Company_agreements_AgreementNordnet | companyCreateBenefitQuery_company_Company_agreements_AgreementRequestAdminPoa | companyCreateBenefitQuery_company_Company_agreements_AgreementRequestEuroAccident | companyCreateBenefitQuery_company_Company_agreements_AgreementRequestNordea | companyCreateBenefitQuery_company_Company_agreements_AgreementRequestNordnet | companyCreateBenefitQuery_company_Company_agreements_AgreementRequestSuperAdmin | companyCreateBenefitQuery_company_Company_agreements_AgreementSuperAdmin;

export type companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string
};

export type companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection
};

export type companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type companyCreateBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType = {
  readonly __typename?: 'RemunerationType',
  readonly id: string,
  readonly name: string | null
};

export type companyCreateBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge = {
  readonly __typename?: 'RemunerationTypeEdge',
  readonly node: companyCreateBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType
};

export type companyCreateBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection = {
  readonly __typename?: 'RemunerationTypeConnection',
  readonly edges: ReadonlyArray<companyCreateBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge>
};

export type companyCreateBenefitQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly managedBy: ManagingCompany,
  readonly agreements: ReadonlyArray<companyCreateBenefitQuery_company_Company_agreements>,
  readonly benefitPackages: companyCreateBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection | null,
  readonly remunerationTypes: companyCreateBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection | null
};

export type companyCreateBenefitQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyCreateBenefitQuery_company_Company | null
};


export type companyCreateBenefitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type companyCreateBenefitQuery = companyCreateBenefitQuery_Query;

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration_files_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly useDefaultPolicy: boolean | null,
  readonly customerId: string | null,
  readonly files: ReadonlyArray<companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration_files_FlexFile> | null
};

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration'
};

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration = companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration | companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_MiscConfiguration;

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_entitlementRules_FlexEntitlementRules = {
  readonly __typename?: 'FlexEntitlementRules',
  readonly accountingCostIds: ReadonlyArray<string>,
  readonly benefitPackageIds: ReadonlyArray<string>,
  readonly ouIds: ReadonlyArray<string>
};

export type companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly configEffectiveDate: string | null,
  readonly configuration: companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration | null,
  readonly content: companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_content_FlexBenefitContent,
  readonly entitlementRules: companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_entitlementRules_FlexEntitlementRules | null
};

export type companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string
};

export type companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection
};

export type companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type companyEditEpassiBikeBenefitQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefitPackages: companyEditEpassiBikeBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection | null
};

export type companyEditEpassiBikeBenefitQuery_Query = {
  readonly __typename?: 'Query',
  readonly flexBenefit: companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit | null,
  readonly company: companyEditEpassiBikeBenefitQuery_company_Company | null
};


export type companyEditEpassiBikeBenefitQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;


export type companyEditEpassiBikeBenefitQuery = companyEditEpassiBikeBenefitQuery_Query;

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_configuration = updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_configuration_EpassiBikeConfiguration | updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_configuration_MiscConfiguration;

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_entitlementRules_FlexEntitlementRules = {
  readonly __typename?: 'FlexEntitlementRules',
  readonly benefitPackageIds: ReadonlyArray<string>
};

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly configuration: updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_configuration | null,
  readonly content: updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly entitlementRules: updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit_entitlementRules_FlexEntitlementRules | null
};

export type updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload = {
  readonly __typename?: 'FlexUpdateBenefitPayload',
  readonly benefit: updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload_benefit_FlexBenefit
};

export type updateBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateBenefit: updateBenefitMutation_updateBenefit_FlexUpdateBenefitPayload | null
};


export type updateBenefitMutationVariables = Exact<{
  input: FlexUpdateBenefitInput;
}>;


export type updateBenefitMutation = updateBenefitMutation_Mutation;

export type companyEditBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type companyEditBenefitQuery_flexBenefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type companyEditBenefitQuery_flexBenefit_FlexBenefit_configuration = companyEditBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration | companyEditBenefitQuery_flexBenefit_FlexBenefit_configuration_MiscConfiguration;

export type companyEditBenefitQuery_flexBenefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type companyEditBenefitQuery_flexBenefit_FlexBenefit_entitlementRules_FlexEntitlementRules = {
  readonly __typename?: 'FlexEntitlementRules',
  readonly accountingCostIds: ReadonlyArray<string>,
  readonly benefitPackageIds: ReadonlyArray<string>,
  readonly ouIds: ReadonlyArray<string>
};

export type companyEditBenefitQuery_flexBenefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly configEffectiveDate: string | null,
  readonly configuration: companyEditBenefitQuery_flexBenefit_FlexBenefit_configuration | null,
  readonly content: companyEditBenefitQuery_flexBenefit_FlexBenefit_content_FlexBenefitContent,
  readonly entitlementRules: companyEditBenefitQuery_flexBenefit_FlexBenefit_entitlementRules_FlexEntitlementRules | null
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementActiway = {
  readonly __typename?: 'AgreementActiway',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementAdminPoa = {
  readonly __typename?: 'AgreementAdminPoa',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementCustom = {
  readonly __typename?: 'AgreementCustom',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementEuroAccident = {
  readonly __typename?: 'AgreementEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementNordea = {
  readonly __typename?: 'AgreementNordea',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementNordnet = {
  readonly __typename?: 'AgreementNordnet',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementRequestAdminPoa = {
  readonly __typename?: 'AgreementRequestAdminPoa',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementRequestEuroAccident = {
  readonly __typename?: 'AgreementRequestEuroAccident',
  readonly subAgreementType: AgreementEuroAccidentSubAgreementType,
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementRequestNordea = {
  readonly __typename?: 'AgreementRequestNordea',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementRequestNordnet = {
  readonly __typename?: 'AgreementRequestNordnet',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementRequestSuperAdmin = {
  readonly __typename?: 'AgreementRequestSuperAdmin',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements_AgreementSuperAdmin = {
  readonly __typename?: 'AgreementSuperAdmin',
  readonly id: string
};

export type companyEditBenefitQuery_company_Company_agreements = companyEditBenefitQuery_company_Company_agreements_AgreementActiway | companyEditBenefitQuery_company_Company_agreements_AgreementAdminPoa | companyEditBenefitQuery_company_Company_agreements_AgreementCustom | companyEditBenefitQuery_company_Company_agreements_AgreementEuroAccident | companyEditBenefitQuery_company_Company_agreements_AgreementNordea | companyEditBenefitQuery_company_Company_agreements_AgreementNordnet | companyEditBenefitQuery_company_Company_agreements_AgreementRequestAdminPoa | companyEditBenefitQuery_company_Company_agreements_AgreementRequestEuroAccident | companyEditBenefitQuery_company_Company_agreements_AgreementRequestNordea | companyEditBenefitQuery_company_Company_agreements_AgreementRequestNordnet | companyEditBenefitQuery_company_Company_agreements_AgreementRequestSuperAdmin | companyEditBenefitQuery_company_Company_agreements_AgreementSuperAdmin;

export type companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string
};

export type companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection
};

export type companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type companyEditBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType = {
  readonly __typename?: 'RemunerationType',
  readonly id: string,
  readonly name: string | null
};

export type companyEditBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge = {
  readonly __typename?: 'RemunerationTypeEdge',
  readonly node: companyEditBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType
};

export type companyEditBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection = {
  readonly __typename?: 'RemunerationTypeConnection',
  readonly edges: ReadonlyArray<companyEditBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge>
};

export type companyEditBenefitQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly managedBy: ManagingCompany,
  readonly agreements: ReadonlyArray<companyEditBenefitQuery_company_Company_agreements>,
  readonly benefitPackages: companyEditBenefitQuery_company_Company_benefitPackages_BenefitPackageConnection | null,
  readonly remunerationTypes: companyEditBenefitQuery_company_Company_remunerationTypes_RemunerationTypeConnection | null
};

export type companyEditBenefitQuery_Query = {
  readonly __typename?: 'Query',
  readonly flexBenefit: companyEditBenefitQuery_flexBenefit_FlexBenefit | null,
  readonly company: companyEditBenefitQuery_company_Company | null
};


export type companyEditBenefitQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;


export type companyEditBenefitQuery = companyEditBenefitQuery_Query;

export type companyBillingConfigurationDetails = {
  readonly __typename?: 'CompanyBillingConfiguration',
  readonly id: string,
  readonly email: string,
  readonly attachmentFormats: ReadonlyArray<CompanyBillingAttachmentFormat>
};

export type addCompanyBillingConfigurationMutation_addCompanyBillingConfiguration_AddCompanyBillingConfigurationPayload_billingConfiguration_CompanyBillingConfiguration = (
  {
  readonly __typename?: 'CompanyBillingConfiguration'
}
  & companyBillingConfigurationDetails
);

export type addCompanyBillingConfigurationMutation_addCompanyBillingConfiguration_AddCompanyBillingConfigurationPayload = {
  readonly __typename?: 'AddCompanyBillingConfigurationPayload',
  readonly billingConfiguration: addCompanyBillingConfigurationMutation_addCompanyBillingConfiguration_AddCompanyBillingConfigurationPayload_billingConfiguration_CompanyBillingConfiguration
};

export type addCompanyBillingConfigurationMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addCompanyBillingConfiguration: addCompanyBillingConfigurationMutation_addCompanyBillingConfiguration_AddCompanyBillingConfigurationPayload | null
};


export type addCompanyBillingConfigurationMutationVariables = Exact<{
  input: AddCompanyBillingConfigurationInput;
}>;


export type addCompanyBillingConfigurationMutation = addCompanyBillingConfigurationMutation_Mutation;

export type updateCompanyBillingConfigurationMutation_updateCompanyBillingConfiguration_UpdateCompanyBillingConfigurationPayload_billingConfiguration_CompanyBillingConfiguration = (
  {
  readonly __typename?: 'CompanyBillingConfiguration'
}
  & companyBillingConfigurationDetails
);

export type updateCompanyBillingConfigurationMutation_updateCompanyBillingConfiguration_UpdateCompanyBillingConfigurationPayload = {
  readonly __typename?: 'UpdateCompanyBillingConfigurationPayload',
  readonly billingConfiguration: updateCompanyBillingConfigurationMutation_updateCompanyBillingConfiguration_UpdateCompanyBillingConfigurationPayload_billingConfiguration_CompanyBillingConfiguration
};

export type updateCompanyBillingConfigurationMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateCompanyBillingConfiguration: updateCompanyBillingConfigurationMutation_updateCompanyBillingConfiguration_UpdateCompanyBillingConfigurationPayload | null
};


export type updateCompanyBillingConfigurationMutationVariables = Exact<{
  input: UpdateCompanyBillingConfigurationInput;
}>;


export type updateCompanyBillingConfigurationMutation = updateCompanyBillingConfigurationMutation_Mutation;

export type deleteCompanyBillingConfigurationMutation_deleteCompanyBillingConfiguration_DeleteCompanyBillingConfigurationPayload = {
  readonly __typename?: 'DeleteCompanyBillingConfigurationPayload',
  readonly clientMutationId: string | null
};

export type deleteCompanyBillingConfigurationMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteCompanyBillingConfiguration: deleteCompanyBillingConfigurationMutation_deleteCompanyBillingConfiguration_DeleteCompanyBillingConfigurationPayload | null
};


export type deleteCompanyBillingConfigurationMutationVariables = Exact<{
  input: DeleteCompanyBillingConfigurationInput;
}>;


export type deleteCompanyBillingConfigurationMutation = deleteCompanyBillingConfigurationMutation_Mutation;

export type companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection_edges_CompanyBillingConfigurationEdge_node_CompanyBillingConfiguration = (
  {
  readonly __typename?: 'CompanyBillingConfiguration'
}
  & companyBillingConfigurationDetails
);

export type companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection_edges_CompanyBillingConfigurationEdge = {
  readonly __typename?: 'CompanyBillingConfigurationEdge',
  readonly node: companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection_edges_CompanyBillingConfigurationEdge_node_CompanyBillingConfiguration
};

export type companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection = {
  readonly __typename?: 'CompanyBillingConfigurationConnection',
  readonly pageInfo: companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection_edges_CompanyBillingConfigurationEdge>
};

export type companyBillingConfigurationQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly billingConfigurations: companyBillingConfigurationQuery_company_Company_billingConfigurations_CompanyBillingConfigurationConnection
};

export type companyBillingConfigurationQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyBillingConfigurationQuery_company_Company | null
};


export type companyBillingConfigurationQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type companyBillingConfigurationQuery = companyBillingConfigurationQuery_Query;

export type addAccountingDimensionMutation_addAccountingDimension_AccountingDimension = {
  readonly __typename?: 'AccountingDimension',
  readonly id: string
};

export type addAccountingDimensionMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addAccountingDimension: addAccountingDimensionMutation_addAccountingDimension_AccountingDimension | null
};


export type addAccountingDimensionMutationVariables = Exact<{
  input?: InputMaybe<AddAccountingDimensionInput>;
}>;


export type addAccountingDimensionMutation = addAccountingDimensionMutation_Mutation;

export type updateAccountingDimensionMutation_updateAccountingDimension_AccountingDimension = {
  readonly __typename?: 'AccountingDimension',
  readonly id: string
};

export type updateAccountingDimensionMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateAccountingDimension: updateAccountingDimensionMutation_updateAccountingDimension_AccountingDimension | null
};


export type updateAccountingDimensionMutationVariables = Exact<{
  input?: InputMaybe<UpdateAccountingDimensionInput>;
}>;


export type updateAccountingDimensionMutation = updateAccountingDimensionMutation_Mutation;

export type addAccountingObjectMutation_addAccountingObject_AccountingObject = {
  readonly __typename?: 'AccountingObject',
  readonly id: string
};

export type addAccountingObjectMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addAccountingObject: addAccountingObjectMutation_addAccountingObject_AccountingObject | null
};


export type addAccountingObjectMutationVariables = Exact<{
  input?: InputMaybe<AddAccountingObjectInput>;
}>;


export type addAccountingObjectMutation = addAccountingObjectMutation_Mutation;

export type updateAccountingObjectMutation_updateAccountingObject_AccountingObject = {
  readonly __typename?: 'AccountingObject',
  readonly id: string
};

export type updateAccountingObjectMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateAccountingObject: updateAccountingObjectMutation_updateAccountingObject_AccountingObject | null
};


export type updateAccountingObjectMutationVariables = Exact<{
  input?: InputMaybe<UpdateAccountingObjectInput>;
}>;


export type updateAccountingObjectMutation = updateAccountingObjectMutation_Mutation;

export type accountingDimensionsQuery_accountingDimensions_AccountingDimension = {
  readonly __typename?: 'AccountingDimension',
  readonly id: string,
  readonly identifier: string | null,
  readonly name: string
};

export type accountingDimensionsQuery_Query = {
  readonly __typename?: 'Query',
  readonly accountingDimensions: ReadonlyArray<accountingDimensionsQuery_accountingDimensions_AccountingDimension>
};


export type accountingDimensionsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type accountingDimensionsQuery = accountingDimensionsQuery_Query;

export type accountingObjectQuery_accountingObject_AccountingObject = {
  readonly __typename?: 'AccountingObject',
  readonly id: string,
  readonly name: string | null,
  readonly identifier: string
};

export type accountingObjectQuery_Query = {
  readonly __typename?: 'Query',
  readonly accountingObject: accountingObjectQuery_accountingObject_AccountingObject | null
};


export type accountingObjectQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  dimensionId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}>;


export type accountingObjectQuery = accountingObjectQuery_Query;

export type discountConfigurationQuery_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type discountConfigurationQuery_benefit_FlexBenefit_entitlementRules_FlexEntitlementRules = {
  readonly __typename?: 'FlexEntitlementRules',
  readonly benefitPackageIds: ReadonlyArray<string>,
  readonly ouIds: ReadonlyArray<string>
};

export type discountConfigurationQuery_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly hidden: boolean | null,
  readonly content: discountConfigurationQuery_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly entitlementRules: discountConfigurationQuery_benefit_FlexBenefit_entitlementRules_FlexEntitlementRules | null
};

export type discountConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type discountConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: discountConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type discountConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<discountConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type discountConfigurationQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit = {
  readonly __typename?: 'OrganizationalUnit',
  readonly id: string,
  readonly name: string
};

export type discountConfigurationQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge = {
  readonly __typename?: 'OrganizationalUnitEdge',
  readonly node: discountConfigurationQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit
};

export type discountConfigurationQuery_company_Company_organizationalUnits_OrganizationalUnitConnection = {
  readonly __typename?: 'OrganizationalUnitConnection',
  readonly edges: ReadonlyArray<discountConfigurationQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge>
};

export type discountConfigurationQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefitPackages: discountConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection | null,
  readonly organizationalUnits: discountConfigurationQuery_company_Company_organizationalUnits_OrganizationalUnitConnection | null
};

export type discountConfigurationQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefit: discountConfigurationQuery_benefit_FlexBenefit | null,
  readonly company: discountConfigurationQuery_company_Company | null
};


export type discountConfigurationQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  benefitId: Scalars['ID']['input'];
}>;


export type discountConfigurationQuery = discountConfigurationQuery_Query;

export type createBenefitCategoryMutation_createBenefitCategory_FlexCreateBenefitCategoryPayload = {
  readonly __typename?: 'FlexCreateBenefitCategoryPayload',
  readonly categoryId: string
};

export type createBenefitCategoryMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createBenefitCategory: createBenefitCategoryMutation_createBenefitCategory_FlexCreateBenefitCategoryPayload | null
};


export type createBenefitCategoryMutationVariables = Exact<{
  input: FlexCreateBenefitCategoryInput;
}>;


export type createBenefitCategoryMutation = createBenefitCategoryMutation_Mutation;

export type benefitCategoriesQuery_benefitCategories_FlexBenefitCategory = {
  readonly __typename?: 'FlexBenefitCategory',
  readonly id: string,
  readonly companyId: string | null,
  readonly name: string,
  readonly locale: string
};

export type benefitCategoriesQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefitCategories: ReadonlyArray<benefitCategoriesQuery_benefitCategories_FlexBenefitCategory>
};


export type benefitCategoriesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>;
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type benefitCategoriesQuery = benefitCategoriesQuery_Query;

export type benefitSuppliersQuery_company_Company_benefitSuppliers_FlexBenefitSupplier_logo_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly url: string,
  readonly path: string
};

export type benefitSuppliersQuery_company_Company_benefitSuppliers_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string,
  readonly websiteUrl: string | null,
  readonly companyId: string | null,
  readonly logo: benefitSuppliersQuery_company_Company_benefitSuppliers_FlexBenefitSupplier_logo_FlexFile | null
};

export type benefitSuppliersQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly benefitSuppliers: ReadonlyArray<benefitSuppliersQuery_company_Company_benefitSuppliers_FlexBenefitSupplier> | null
};

export type benefitSuppliersQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: benefitSuppliersQuery_company_Company | null
};


export type benefitSuppliersQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type benefitSuppliersQuery = benefitSuppliersQuery_Query;

export type createBenefitSupplierMutation_createBenefitSupplier_FlexCreateBenefitSupplierPayload_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string
};

export type createBenefitSupplierMutation_createBenefitSupplier_FlexCreateBenefitSupplierPayload = {
  readonly __typename?: 'FlexCreateBenefitSupplierPayload',
  readonly supplier: createBenefitSupplierMutation_createBenefitSupplier_FlexCreateBenefitSupplierPayload_supplier_FlexBenefitSupplier
};

export type createBenefitSupplierMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createBenefitSupplier: createBenefitSupplierMutation_createBenefitSupplier_FlexCreateBenefitSupplierPayload | null
};


export type createBenefitSupplierMutationVariables = Exact<{
  input: FlexCreateBenefitSupplierInput;
}>;


export type createBenefitSupplierMutation = createBenefitSupplierMutation_Mutation;

export type updateBenefitSupplierMutation_updateBenefitSupplier_FlexUpdateBenefitSupplierPayload_supplier_FlexBenefitSupplier_logo_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly url: string,
  readonly path: string,
  readonly name: string
};

export type updateBenefitSupplierMutation_updateBenefitSupplier_FlexUpdateBenefitSupplierPayload_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string,
  readonly websiteUrl: string | null,
  readonly logo: updateBenefitSupplierMutation_updateBenefitSupplier_FlexUpdateBenefitSupplierPayload_supplier_FlexBenefitSupplier_logo_FlexFile | null
};

export type updateBenefitSupplierMutation_updateBenefitSupplier_FlexUpdateBenefitSupplierPayload = {
  readonly __typename?: 'FlexUpdateBenefitSupplierPayload',
  readonly supplier: updateBenefitSupplierMutation_updateBenefitSupplier_FlexUpdateBenefitSupplierPayload_supplier_FlexBenefitSupplier
};

export type updateBenefitSupplierMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateBenefitSupplier: updateBenefitSupplierMutation_updateBenefitSupplier_FlexUpdateBenefitSupplierPayload | null
};


export type updateBenefitSupplierMutationVariables = Exact<{
  input: FlexUpdateBenefitSupplierInput;
}>;


export type updateBenefitSupplierMutation = updateBenefitSupplierMutation_Mutation;

export type discountPresentationQuery_benefit_FlexBenefit_supplier_FlexBenefitSupplier_logo_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly url: string,
  readonly path: string
};

export type discountPresentationQuery_benefit_FlexBenefit_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string,
  readonly websiteUrl: string | null,
  readonly companyId: string | null,
  readonly logo: discountPresentationQuery_benefit_FlexBenefit_supplier_FlexBenefitSupplier_logo_FlexFile | null
};

export type discountPresentationQuery_benefit_FlexBenefit_categories_FlexBenefitCategory = {
  readonly __typename?: 'FlexBenefitCategory',
  readonly id: string,
  readonly locale: string,
  readonly companyId: string | null,
  readonly name: string
};

export type discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_cardImage_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly url: string,
  readonly path: string
};

export type discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_heroImage_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly url: string,
  readonly path: string
};

export type discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_callToAction_FlexCallToAction = {
  readonly __typename?: 'FlexCallToAction',
  readonly title: string,
  readonly link: string,
  readonly type: string
};

export type discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_attachments_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly title: string,
  readonly short: string,
  readonly body: string,
  readonly locale: string,
  readonly heroImageAlignment: string,
  readonly cardImage: discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_cardImage_FlexFile | null,
  readonly heroImage: discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_heroImage_FlexFile | null,
  readonly callToAction: ReadonlyArray<discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_callToAction_FlexCallToAction>,
  readonly attachments: ReadonlyArray<discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_attachments_FlexFile>
};

export type discountPresentationQuery_benefit_FlexBenefit_configuration_EpassiBikeConfiguration_files_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type discountPresentationQuery_benefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null,
  readonly files: ReadonlyArray<discountPresentationQuery_benefit_FlexBenefit_configuration_EpassiBikeConfiguration_files_FlexFile> | null
};

export type discountPresentationQuery_benefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type discountPresentationQuery_benefit_FlexBenefit_configuration = discountPresentationQuery_benefit_FlexBenefit_configuration_EpassiBikeConfiguration | discountPresentationQuery_benefit_FlexBenefit_configuration_MiscConfiguration;

export type discountPresentationQuery_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly supplier: discountPresentationQuery_benefit_FlexBenefit_supplier_FlexBenefitSupplier,
  readonly categories: ReadonlyArray<discountPresentationQuery_benefit_FlexBenefit_categories_FlexBenefitCategory>,
  readonly content: discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: discountPresentationQuery_benefit_FlexBenefit_configuration | null
};

export type discountPresentationQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefit: discountPresentationQuery_benefit_FlexBenefit | null
};


export type discountPresentationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type discountPresentationQuery = discountPresentationQuery_Query;

export type fileUpload_fileUploadUrl_FlexFileUploadUrl = {
  readonly __typename?: 'FlexFileUploadUrl',
  readonly url: string | null,
  readonly path: string | null,
  readonly name: string | null
};

export type fileUpload_Query = {
  readonly __typename?: 'Query',
  readonly fileUploadUrl: ReadonlyArray<fileUpload_fileUploadUrl_FlexFileUploadUrl>
};


export type fileUploadVariables = Exact<{
  files?: InputMaybe<ReadonlyArray<FlexFileUploadInput> | FlexFileUploadInput>;
}>;


export type fileUpload = fileUpload_Query;

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly id: string,
  readonly title: string,
  readonly locale: string
};

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory = {
  readonly __typename?: 'FlexBenefitCategory',
  readonly id: string,
  readonly name: string
};

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string
};

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent,
  readonly categories: ReadonlyArray<discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory>,
  readonly supplier: discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_supplier_FlexBenefitSupplier
};

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>,
  readonly pageInfo: discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_pageInfo_PageInfo
};

export type discountsOverviewQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefits: discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection | null
};

export type discountsOverviewQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: discountsOverviewQuery_company_Company | null
};


export type discountsOverviewQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyGetDiscounts?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type discountsOverviewQuery = discountsOverviewQuery_Query;

export type benefitPackagesOverviewQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type benefitPackagesOverviewQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: benefitPackagesOverviewQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type benefitPackagesOverviewQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<benefitPackagesOverviewQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type benefitPackagesOverviewQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefitPackages: benefitPackagesOverviewQuery_company_Company_benefitPackages_BenefitPackageConnection | null
};

export type benefitPackagesOverviewQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: benefitPackagesOverviewQuery_company_Company | null
};


export type benefitPackagesOverviewQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type benefitPackagesOverviewQuery = benefitPackagesOverviewQuery_Query;

export type organizationFieldsQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge_node_CostCenter = {
  readonly __typename?: 'CostCenter',
  readonly id: string,
  readonly identifier: string,
  readonly name: string
};

export type organizationFieldsQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge = {
  readonly __typename?: 'CostCenterEdge',
  readonly node: organizationFieldsQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge_node_CostCenter
};

export type organizationFieldsQuery_company_Company_costCenters_CostCenterConnection = {
  readonly __typename?: 'CostCenterConnection',
  readonly edges: ReadonlyArray<organizationFieldsQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge>
};

export type organizationFieldsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit = {
  readonly __typename?: 'OrganizationalUnit',
  readonly id: string,
  readonly name: string,
  readonly slug: string,
  readonly path: string
};

export type organizationFieldsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge = {
  readonly __typename?: 'OrganizationalUnitEdge',
  readonly node: organizationFieldsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit
};

export type organizationFieldsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection = {
  readonly __typename?: 'OrganizationalUnitConnection',
  readonly edges: ReadonlyArray<organizationFieldsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge>
};

export type organizationFieldsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly costCenters: organizationFieldsQuery_company_Company_costCenters_CostCenterConnection | null,
  readonly organizationalUnits: organizationFieldsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection | null
};

export type organizationFieldsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: organizationFieldsQuery_company_Company | null
};


export type organizationFieldsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type organizationFieldsQuery = organizationFieldsQuery_Query;

export type createMembershipMutation_createMembership_CreateMembershipPayload_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly userAccountId: string
};

export type createMembershipMutation_createMembership_CreateMembershipPayload = {
  readonly __typename?: 'CreateMembershipPayload',
  readonly membership: createMembershipMutation_createMembership_CreateMembershipPayload_membership_Membership
};

export type createMembershipMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createMembership: createMembershipMutation_createMembership_CreateMembershipPayload | null
};


export type createMembershipMutationVariables = Exact<{
  input: CreateMembershipInput;
}>;


export type createMembershipMutation = createMembershipMutation_Mutation;

export type assertFullyCapableForWorkMutation_assertFullyCapableForWork_AssertFullyCapableForWorkPayload = {
  readonly __typename?: 'AssertFullyCapableForWorkPayload',
  readonly clientMutationId: string | null
};

export type assertFullyCapableForWorkMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly assertFullyCapableForWork: assertFullyCapableForWorkMutation_assertFullyCapableForWork_AssertFullyCapableForWorkPayload | null
};


export type assertFullyCapableForWorkMutationVariables = Exact<{
  input: AssertFullyCapableForWorkInput;
}>;


export type assertFullyCapableForWorkMutation = assertFullyCapableForWorkMutation_Mutation;

export type companyFullyCapableForWorkRequestsQuery_company_Company_fullyCapableForWorkInfo_FullyCapableForWorkInfo = {
  readonly __typename?: 'FullyCapableForWorkInfo',
  readonly infoText: string
};

export type companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string
};

export type companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest = {
  readonly __typename?: 'FlexFcfwRequest',
  readonly userAccountId: string,
  readonly companyId: string,
  readonly requestedDate: string,
  readonly possibleAssertionDates: ReadonlyArray<string>,
  readonly membership: companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest_membership_Membership
};

export type companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge = {
  readonly __typename?: 'FlexFcfwRequestEdge',
  readonly node: companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest
};

export type companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection = {
  readonly __typename?: 'FlexFcfwRequestConnection',
  readonly edges: ReadonlyArray<companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge>
};

export type companyFullyCapableForWorkRequestsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly fullyCapableForWorkInfo: companyFullyCapableForWorkRequestsQuery_company_Company_fullyCapableForWorkInfo_FullyCapableForWorkInfo | null,
  readonly fcfwRequests: companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection
};

export type companyFullyCapableForWorkRequestsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyFullyCapableForWorkRequestsQuery_company_Company | null
};


export type companyFullyCapableForWorkRequestsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyFullyCapableForWorkRequestsQuery = companyFullyCapableForWorkRequestsQuery_Query;

export type membershipBenefitPackageQuery_membership_Membership_employment_M2Employment_benefitPackage_BenefitPackageItem_benefitPackage_M2BenefitPackage = {
  readonly __typename?: 'M2BenefitPackage',
  readonly id: string,
  readonly name: string | null
};

export type membershipBenefitPackageQuery_membership_Membership_employment_M2Employment_benefitPackage_BenefitPackageItem = {
  readonly __typename?: 'BenefitPackageItem',
  readonly to: string | null,
  readonly from: string,
  readonly benefitPackage: membershipBenefitPackageQuery_membership_Membership_employment_M2Employment_benefitPackage_BenefitPackageItem_benefitPackage_M2BenefitPackage | null
};

export type membershipBenefitPackageQuery_membership_Membership_employment_M2Employment = {
  readonly __typename?: 'M2Employment',
  readonly benefitPackage: ReadonlyArray<membershipBenefitPackageQuery_membership_Membership_employment_M2Employment_benefitPackage_BenefitPackageItem>
};

export type membershipBenefitPackageQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly employment: ReadonlyArray<membershipBenefitPackageQuery_membership_Membership_employment_M2Employment>
};

export type membershipBenefitPackageQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: membershipBenefitPackageQuery_membership_Membership | null
};


export type membershipBenefitPackageQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type membershipBenefitPackageQuery = membershipBenefitPackageQuery_Query;

export type updateMembershipBenefitPackageMutation_updateMembershipBenefitPackage_UpdateMembershipBenefitPackagePayload = {
  readonly __typename?: 'UpdateMembershipBenefitPackagePayload',
  readonly benefitPackageId: string
};

export type updateMembershipBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateMembershipBenefitPackage: updateMembershipBenefitPackageMutation_updateMembershipBenefitPackage_UpdateMembershipBenefitPackagePayload | null
};


export type updateMembershipBenefitPackageMutationVariables = Exact<{
  input: UpdateMembershipBenefitPackageInput;
}>;


export type updateMembershipBenefitPackageMutation = updateMembershipBenefitPackageMutation_Mutation;

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly benefit: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type editEmployeeDetailsFormQuery_membership_Membership = (
  {
  readonly __typename?: 'Membership'
}
  & membershipDetails
);

export type editEmployeeDetailsFormQuery_Query = {
  readonly __typename?: 'Query',
  readonly entitlements: editEmployeeDetailsFormQuery_entitlements_FlexEntitlementConnection | null,
  readonly membership: editEmployeeDetailsFormQuery_membership_Membership | null
};


export type editEmployeeDetailsFormQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type editEmployeeDetailsFormQuery = editEmployeeDetailsFormQuery_Query;

export type membershipDetails_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type membershipDetails = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly email: string | null,
  readonly phone: string | null,
  readonly collectiveAgreement: string | null,
  readonly employeeNumber: string | null,
  readonly incomeTaxTable: number | null,
  readonly employerContributionShare: string | null,
  readonly companyCarMonthlyValue: string | null,
  readonly fitnessContributionBalance: string | null,
  readonly vacationDaysSaved: number | null,
  readonly vacationDaysPaid: number | null,
  readonly vacationDaysUnpaid: number | null,
  readonly vacationDaysAdvance: number | null,
  readonly vacationDaysPerYear: number | null,
  readonly company: membershipDetails_Membership_company_Company
};

export type membershipDetailsQuery_membership_Membership = (
  {
  readonly __typename?: 'Membership'
}
  & membershipDetails
);

export type membershipDetailsQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: membershipDetailsQuery_membership_Membership | null
};


export type membershipDetailsQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;


export type membershipDetailsQuery = membershipDetailsQuery_Query;

export type addEmploymentMutation_addEmployment_AddEmploymentPayload_employment_Employment = (
  {
  readonly __typename?: 'Employment'
}
  & employmentDetails
);

export type addEmploymentMutation_addEmployment_AddEmploymentPayload = {
  readonly __typename?: 'AddEmploymentPayload',
  readonly employment: addEmploymentMutation_addEmployment_AddEmploymentPayload_employment_Employment
};

export type addEmploymentMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addEmployment: addEmploymentMutation_addEmployment_AddEmploymentPayload | null
};


export type addEmploymentMutationVariables = Exact<{
  input: AddEmploymentInput;
}>;


export type addEmploymentMutation = addEmploymentMutation_Mutation;

export type addEmploymentModalQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type addEmploymentModalQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: addEmploymentModalQuery_membership_Membership | null
};


export type addEmploymentModalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type addEmploymentModalQuery = addEmploymentModalQuery_Query;

export type addEmploymentActivityMutation_addEmploymentActivity_AddEmploymentActivityPayload_employmentActivity_EmploymentActivity = (
  {
  readonly __typename?: 'EmploymentActivity'
}
  & employmentActivityDetails
);

export type addEmploymentActivityMutation_addEmploymentActivity_AddEmploymentActivityPayload = {
  readonly __typename?: 'AddEmploymentActivityPayload',
  readonly employmentActivity: addEmploymentActivityMutation_addEmploymentActivity_AddEmploymentActivityPayload_employmentActivity_EmploymentActivity
};

export type addEmploymentActivityMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addEmploymentActivity: addEmploymentActivityMutation_addEmploymentActivity_AddEmploymentActivityPayload | null
};


export type addEmploymentActivityMutationVariables = Exact<{
  input: AddEmploymentActivityInput;
}>;


export type addEmploymentActivityMutation = addEmploymentActivityMutation_Mutation;

export type updateEmploymentActivityMutation_updateEmploymentActivity_UpdateEmploymentActivityPayload_employmentActivity_EmploymentActivity = (
  {
  readonly __typename?: 'EmploymentActivity'
}
  & employmentActivityDetails
);

export type updateEmploymentActivityMutation_updateEmploymentActivity_UpdateEmploymentActivityPayload = {
  readonly __typename?: 'UpdateEmploymentActivityPayload',
  readonly employmentActivity: updateEmploymentActivityMutation_updateEmploymentActivity_UpdateEmploymentActivityPayload_employmentActivity_EmploymentActivity
};

export type updateEmploymentActivityMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateEmploymentActivity: updateEmploymentActivityMutation_updateEmploymentActivity_UpdateEmploymentActivityPayload | null
};


export type updateEmploymentActivityMutationVariables = Exact<{
  input: UpdateEmploymentActivityInput;
}>;


export type updateEmploymentActivityMutation = updateEmploymentActivityMutation_Mutation;

export type employmentActivityDetails = {
  readonly __typename?: 'EmploymentActivity',
  readonly id: string,
  readonly type: EmploymentActivityType,
  readonly period: [string, string | null],
  readonly rate: string
};

export type deleteEmploymentActivityMutation_deleteEmploymentActivity_DeleteEmploymentActivityPayload_employmentActivity_EmploymentActivity = (
  {
  readonly __typename?: 'EmploymentActivity'
}
  & employmentActivityDetails
);

export type deleteEmploymentActivityMutation_deleteEmploymentActivity_DeleteEmploymentActivityPayload = {
  readonly __typename?: 'DeleteEmploymentActivityPayload',
  readonly employmentActivity: deleteEmploymentActivityMutation_deleteEmploymentActivity_DeleteEmploymentActivityPayload_employmentActivity_EmploymentActivity
};

export type deleteEmploymentActivityMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteEmploymentActivity: deleteEmploymentActivityMutation_deleteEmploymentActivity_DeleteEmploymentActivityPayload | null
};


export type deleteEmploymentActivityMutationVariables = Exact<{
  input: DeleteEmploymentActivityInput;
}>;


export type deleteEmploymentActivityMutation = deleteEmploymentActivityMutation_Mutation;

export type employmentActivitiesQuery_employment_Employment_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string
};

export type employmentActivitiesQuery_employment_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge_node_EmploymentActivity = (
  {
  readonly __typename?: 'EmploymentActivity'
}
  & employmentActivityDetails
);

export type employmentActivitiesQuery_employment_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge = {
  readonly __typename?: 'EmploymentActivityEdge',
  readonly node: employmentActivitiesQuery_employment_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge_node_EmploymentActivity
};

export type employmentActivitiesQuery_employment_Employment_activities_EmploymentActivityConnection = {
  readonly __typename?: 'EmploymentActivityConnection',
  readonly edges: ReadonlyArray<employmentActivitiesQuery_employment_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge>
};

export type employmentActivitiesQuery_employment_Employment = {
  readonly __typename?: 'Employment',
  readonly id: string,
  readonly period: [string, string | null],
  readonly rate: string,
  readonly membership: employmentActivitiesQuery_employment_Employment_membership_Membership,
  readonly activities: employmentActivitiesQuery_employment_Employment_activities_EmploymentActivityConnection | null
};

export type employmentActivitiesQuery_Query = {
  readonly __typename?: 'Query',
  readonly employment: employmentActivitiesQuery_employment_Employment | null
};


export type employmentActivitiesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type employmentActivitiesQuery = employmentActivitiesQuery_Query;

export type deleteEmploymentMutation_deleteEmployment_DeleteEmploymentPayload_employment_Employment = {
  readonly __typename?: 'Employment',
  readonly id: string
};

export type deleteEmploymentMutation_deleteEmployment_DeleteEmploymentPayload = {
  readonly __typename?: 'DeleteEmploymentPayload',
  readonly employment: deleteEmploymentMutation_deleteEmployment_DeleteEmploymentPayload_employment_Employment
};

export type deleteEmploymentMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteEmployment: deleteEmploymentMutation_deleteEmployment_DeleteEmploymentPayload | null
};


export type deleteEmploymentMutationVariables = Exact<{
  input: DeleteEmploymentInput;
}>;


export type deleteEmploymentMutation = deleteEmploymentMutation_Mutation;

export type updateEmploymentMutation_updateEmployment_UpdateEmploymentPayload_employment_Employment = (
  {
  readonly __typename?: 'Employment'
}
  & employmentDetails
);

export type updateEmploymentMutation_updateEmployment_UpdateEmploymentPayload = {
  readonly __typename?: 'UpdateEmploymentPayload',
  readonly employment: updateEmploymentMutation_updateEmployment_UpdateEmploymentPayload_employment_Employment
};

export type updateEmploymentMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateEmployment: updateEmploymentMutation_updateEmployment_UpdateEmploymentPayload | null
};


export type updateEmploymentMutationVariables = Exact<{
  input: UpdateEmploymentInput;
}>;


export type updateEmploymentMutation = updateEmploymentMutation_Mutation;

export type employmentDetailsQuery_employment_Employment_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string
};

export type employmentDetailsQuery_employment_Employment = {
  readonly __typename?: 'Employment',
  readonly id: string,
  readonly period: [string, string | null],
  readonly rate: string,
  readonly membership: employmentDetailsQuery_employment_Employment_membership_Membership
};

export type employmentDetailsQuery_Query = {
  readonly __typename?: 'Query',
  readonly employment: employmentDetailsQuery_employment_Employment | null
};


export type employmentDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type employmentDetailsQuery = employmentDetailsQuery_Query;

export type employmentSalaryDetails = {
  readonly __typename?: 'EmploymentSalary',
  readonly monthlySalary: string,
  readonly effectiveDate: string
};

export type employmentDetails_Employment_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string
};

export type employmentDetails_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge_node_EmploymentActivity = (
  {
  readonly __typename?: 'EmploymentActivity'
}
  & employmentActivityDetails
);

export type employmentDetails_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge = {
  readonly __typename?: 'EmploymentActivityEdge',
  readonly node: employmentDetails_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge_node_EmploymentActivity
};

export type employmentDetails_Employment_activities_EmploymentActivityConnection = {
  readonly __typename?: 'EmploymentActivityConnection',
  readonly edges: ReadonlyArray<employmentDetails_Employment_activities_EmploymentActivityConnection_edges_EmploymentActivityEdge>
};

export type employmentDetails_Employment_salaries_EmploymentSalariesConnection_edges_EmploymentSalariesEdge_node_EmploymentSalary = (
  {
  readonly __typename?: 'EmploymentSalary'
}
  & employmentSalaryDetails
);

export type employmentDetails_Employment_salaries_EmploymentSalariesConnection_edges_EmploymentSalariesEdge = {
  readonly __typename?: 'EmploymentSalariesEdge',
  readonly node: employmentDetails_Employment_salaries_EmploymentSalariesConnection_edges_EmploymentSalariesEdge_node_EmploymentSalary
};

export type employmentDetails_Employment_salaries_EmploymentSalariesConnection = {
  readonly __typename?: 'EmploymentSalariesConnection',
  readonly edges: ReadonlyArray<employmentDetails_Employment_salaries_EmploymentSalariesConnection_edges_EmploymentSalariesEdge>
};

export type employmentDetails = {
  readonly __typename?: 'Employment',
  readonly id: string,
  readonly period: [string, string | null],
  readonly rate: string,
  readonly membership: employmentDetails_Employment_membership_Membership,
  readonly activities: employmentDetails_Employment_activities_EmploymentActivityConnection | null,
  readonly salaries: employmentDetails_Employment_salaries_EmploymentSalariesConnection | null
};

export type membershipEmploymentsQuery_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment = (
  {
  readonly __typename?: 'Employment'
}
  & employmentDetails
);

export type membershipEmploymentsQuery_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge = {
  readonly __typename?: 'EmploymentEdge',
  readonly node: membershipEmploymentsQuery_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment
};

export type membershipEmploymentsQuery_membership_Membership_employments_EmploymentConnection = {
  readonly __typename?: 'EmploymentConnection',
  readonly edges: ReadonlyArray<membershipEmploymentsQuery_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge>
};

export type membershipEmploymentsQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly employments: membershipEmploymentsQuery_membership_Membership_employments_EmploymentConnection | null
};

export type membershipEmploymentsQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: membershipEmploymentsQuery_membership_Membership | null
};


export type membershipEmploymentsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type membershipEmploymentsQuery = membershipEmploymentsQuery_Query;

export type membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory_events_MembershipEventHistoryEvent = {
  readonly __typename?: 'MembershipEventHistoryEvent',
  readonly event: EmployeeHistoryEventType,
  readonly value: string | null
};

export type membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory = {
  readonly __typename?: 'MembershipEventHistory',
  readonly effectiveDate: string | null,
  readonly createdAt: string | null,
  readonly authorType: EmployeeHistoryEventAuthorType,
  readonly authorName: string | null,
  readonly source: EmployeeHistoryEventSource,
  readonly events: ReadonlyArray<membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory_events_MembershipEventHistoryEvent> | null
};

export type membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge = {
  readonly __typename?: 'MembershipEventHistoryEdge',
  readonly node: membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory
};

export type membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection = {
  readonly __typename?: 'MembershipEventHistoryConnection',
  readonly edges: ReadonlyArray<membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge>,
  readonly pageInfo: membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_pageInfo_PageInfo
};

export type membershipEventHistoryTableQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly eventHistory: membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection | null
};

export type membershipEventHistoryTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: membershipEventHistoryTableQuery_membership_Membership | null
};


export type membershipEventHistoryTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type membershipEventHistoryTableQuery = membershipEventHistoryTableQuery_Query;

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_OccupationalPensionChangeConfig = {
  readonly __typename?: 'OccupationalPensionChangeConfig',
  readonly premium: string,
  readonly nordeaManagementType: FlexNordeaManagementType | null
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RetirementAgeChangeConfig = {
  readonly __typename?: 'RetirementAgeChangeConfig',
  readonly newRetirementAge: number,
  readonly insuranceNumber: string
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskAccidentChangeConfig = {
  readonly __typename?: 'RiskAccidentChangeConfig',
  readonly accidentLevel: FlexAccidentLevel
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskHealthcareChangeConfig = {
  readonly __typename?: 'RiskHealthcareChangeConfig',
  readonly healthcareLevel: FlexHealthcareLevel
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskLifeChangeConfig = {
  readonly __typename?: 'RiskLifeChangeConfig',
  readonly lifeLevel: string
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskPremiumWaiverChangeConfig = {
  readonly __typename?: 'RiskPremiumWaiverChangeConfig',
  readonly monthlyPremium: string
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskSicknessAndAccidentChangeConfig = {
  readonly __typename?: 'RiskSicknessAndAccidentChangeConfig',
  readonly sicknessAndAccidentLevel: FlexSicknessAndAccidentLevel
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskSicknessChangeConfig = {
  readonly __typename?: 'RiskSicknessChangeConfig',
  readonly sicknessLevel: FlexSicknessLevel,
  readonly annualSalary: string
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskTglChangeConfig = {
  readonly __typename?: 'RiskTglChangeConfig'
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration = insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_OccupationalPensionChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RetirementAgeChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskAccidentChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskHealthcareChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskLifeChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskPremiumWaiverChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskSicknessAndAccidentChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskSicknessChangeConfig | insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration_RiskTglChangeConfig;

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory = {
  readonly __typename?: 'FlexChangeHistory',
  readonly id: string,
  readonly action: FlexInsuranceAction | null,
  readonly status: FlexChangeStatus,
  readonly effectiveDate: string | null,
  readonly type: FlexChangeType,
  readonly txId: string | null,
  readonly statusReason: string | null,
  readonly sentAt: string | null,
  readonly configuration: insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration | null
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge = {
  readonly __typename?: 'FlexChangeHistoryEdge',
  readonly node: insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory
};

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection = {
  readonly __typename?: 'FlexChangeHistoryConnection',
  readonly edges: ReadonlyArray<insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge>,
  readonly pageInfo: insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_pageInfo_PageInfo
};

export type insuranceEventsTableQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly changesHistory: insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection | null
};

export type insuranceEventsTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: insuranceEventsTableQuery_membership_Membership | null
};


export type insuranceEventsTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FlexChangesHistoryInputFilter>;
}>;


export type insuranceEventsTableQuery = insuranceEventsTableQuery_Query;

export type employeeOverviewNotificationsQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType = {
  readonly __typename?: 'RemunerationType',
  readonly id: string,
  readonly name: string | null
};

export type employeeOverviewNotificationsQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge = {
  readonly __typename?: 'RemunerationTypeEdge',
  readonly node: employeeOverviewNotificationsQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType
};

export type employeeOverviewNotificationsQuery_company_Company_remunerationTypes_RemunerationTypeConnection = {
  readonly __typename?: 'RemunerationTypeConnection',
  readonly edges: ReadonlyArray<employeeOverviewNotificationsQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge>
};

export type employeeOverviewNotificationsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly remunerationTypes: employeeOverviewNotificationsQuery_company_Company_remunerationTypes_RemunerationTypeConnection | null
};

export type employeeOverviewNotificationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem_type_RemunerationItemType = {
  readonly __typename?: 'RemunerationItemType',
  readonly id: string
};

export type employeeOverviewNotificationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly price: string,
  readonly type: employeeOverviewNotificationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem_type_RemunerationItemType
};

export type employeeOverviewNotificationsQuery_membership_Membership_employment_M2Employment = {
  readonly __typename?: 'M2Employment',
  readonly from: string,
  readonly remuneration: ReadonlyArray<employeeOverviewNotificationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem>
};

export type employeeOverviewNotificationsQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly email: string | null,
  readonly employment: ReadonlyArray<employeeOverviewNotificationsQuery_membership_Membership_employment_M2Employment>
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration = employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_EpassiBikeConfiguration | employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_MiscConfiguration;

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration | null
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly benefit: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type employeeOverviewNotificationsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: employeeOverviewNotificationsQuery_company_Company | null,
  readonly membership: employeeOverviewNotificationsQuery_membership_Membership | null,
  readonly entitlements: employeeOverviewNotificationsQuery_entitlements_FlexEntitlementConnection | null
};


export type employeeOverviewNotificationsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type employeeOverviewNotificationsQuery = employeeOverviewNotificationsQuery_Query;

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly content: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly benefit: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type digitalAdviceTableQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<digitalAdviceTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type digitalAdviceTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly entitlements: digitalAdviceTableQuery_entitlements_FlexEntitlementConnection | null
};


export type digitalAdviceTableQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type digitalAdviceTableQuery = digitalAdviceTableQuery_Query;

export type occupationalPensionBenefitDetails_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type occupationalPensionBenefitDetails_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type occupationalPensionBenefitDetails_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type occupationalPensionBenefitDetails_FlexBenefit_configuration = occupationalPensionBenefitDetails_FlexBenefit_configuration_EpassiBikeConfiguration | occupationalPensionBenefitDetails_FlexBenefit_configuration_MiscConfiguration;

export type occupationalPensionBenefitDetails = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: occupationalPensionBenefitDetails_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: occupationalPensionBenefitDetails_FlexBenefit_configuration | null
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute = {
  readonly __typename?: 'Institute',
  readonly id: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_nextPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_previousPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement_capital_InsuranceComponentCapital = {
  readonly __typename?: 'InsuranceComponentCapital',
  readonly id: string,
  readonly managementType: InsuranceComponentManagementType
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident = {
  readonly __typename?: 'InsuranceComponentAccident',
  readonly id: string,
  readonly typeCode: string | null,
  readonly status: InsuranceComponentStatus | null,
  readonly insuranceNumber: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath = {
  readonly __typename?: 'InsuranceComponentDeath',
  readonly id: string,
  readonly typeCode: string | null,
  readonly status: InsuranceComponentStatus | null,
  readonly insuranceNumber: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare = {
  readonly __typename?: 'InsuranceComponentHealthCare',
  readonly id: string,
  readonly typeCode: string | null,
  readonly status: InsuranceComponentStatus | null,
  readonly insuranceNumber: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement = {
  readonly __typename?: 'InsuranceComponentRetirement',
  readonly id: string,
  readonly typeCode: string | null,
  readonly status: InsuranceComponentStatus | null,
  readonly insuranceNumber: string,
  readonly capital: pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement_capital_InsuranceComponentCapital | null
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness = {
  readonly __typename?: 'InsuranceComponentSickness',
  readonly id: string,
  readonly typeCode: string | null,
  readonly status: InsuranceComponentStatus | null,
  readonly insuranceNumber: string
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components = pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident | pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath | pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare | pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement | pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness;

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly effectiveDate: string | null,
  readonly premiumStatus: InsurancePremiumStatus | null,
  readonly insuranceNumber: string,
  readonly institute: pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute,
  readonly nextPremiumPayment: pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_nextPremiumPayment_CurrencyValue | null,
  readonly previousPremiumPayment: pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_previousPremiumPayment_CurrencyValue | null,
  readonly components: ReadonlyArray<pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components> | null
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge = {
  readonly __typename?: 'InsuranceEdge',
  readonly node: pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance
};

export type pensionTableQuery_membership_Membership_insurances_InsuranceConnection = {
  readonly __typename?: 'InsuranceConnection',
  readonly edges: ReadonlyArray<pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge>
};

export type pensionTableQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly insurances: pensionTableQuery_membership_Membership_insurances_InsuranceConnection | null
};

export type pensionTableQuery_changes_FlexOccupationalPensionChange = (
  {
  readonly __typename?: 'FlexOccupationalPensionChange'
}
  & occupationalPensionChangeDetails
);

export type pensionTableQuery_changes_FlexRetirementAgeChange = {
  readonly __typename?: 'FlexRetirementAgeChange'
};

export type pensionTableQuery_changes_FlexRiskAccidentChange = {
  readonly __typename?: 'FlexRiskAccidentChange'
};

export type pensionTableQuery_changes_FlexRiskHealthcareChange = {
  readonly __typename?: 'FlexRiskHealthcareChange'
};

export type pensionTableQuery_changes_FlexRiskLifeChange = {
  readonly __typename?: 'FlexRiskLifeChange'
};

export type pensionTableQuery_changes_FlexRiskPremiumWaiverChange = {
  readonly __typename?: 'FlexRiskPremiumWaiverChange'
};

export type pensionTableQuery_changes_FlexRiskSicknessAndAccidentChange = {
  readonly __typename?: 'FlexRiskSicknessAndAccidentChange'
};

export type pensionTableQuery_changes_FlexRiskSicknessChange = {
  readonly __typename?: 'FlexRiskSicknessChange'
};

export type pensionTableQuery_changes_FlexRiskTglChange = {
  readonly __typename?: 'FlexRiskTglChange'
};

export type pensionTableQuery_changes = pensionTableQuery_changes_FlexOccupationalPensionChange | pensionTableQuery_changes_FlexRetirementAgeChange | pensionTableQuery_changes_FlexRiskAccidentChange | pensionTableQuery_changes_FlexRiskHealthcareChange | pensionTableQuery_changes_FlexRiskLifeChange | pensionTableQuery_changes_FlexRiskPremiumWaiverChange | pensionTableQuery_changes_FlexRiskSicknessAndAccidentChange | pensionTableQuery_changes_FlexRiskSicknessChange | pensionTableQuery_changes_FlexRiskTglChange;

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit = (
  {
  readonly __typename?: 'FlexBenefit'
}
  & occupationalPensionBenefitDetails
);

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly benefit: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type pensionTableQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit = (
  {
  readonly __typename?: 'FlexBenefit'
}
  & occupationalPensionBenefitDetails
);

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly benefit: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type pensionTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: pensionTableQuery_membership_Membership | null,
  readonly changes: ReadonlyArray<pensionTableQuery_changes> | null,
  readonly entitlements: pensionTableQuery_entitlements_FlexEntitlementConnection | null,
  readonly upcomingEntitlements: pensionTableQuery_upcomingEntitlements_FlexEntitlementConnection | null
};


export type pensionTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  entitlementDate?: InputMaybe<Scalars['Date']['input']>;
}>;


export type pensionTableQuery = pensionTableQuery_Query;

export type employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange = {
  readonly __typename?: 'FlexSalaryExchange',
  readonly id: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly provider: FlexSalaryExchangeProvider,
  readonly status: FlexSalaryExchangeStatus,
  readonly effectiveDate: string,
  readonly violated: boolean,
  readonly insuranceNumber: string | null
};

export type employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge = {
  readonly __typename?: 'FlexSalaryExchangeEdge',
  readonly node: employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange
};

export type employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection = {
  readonly __typename?: 'FlexSalaryExchangeConnection',
  readonly edges: ReadonlyArray<employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge>
};

export type employeeSalaryExchangeQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly premium: string,
  readonly effectiveDate: string,
  readonly status: FlexSalaryExchangeRequestStatus
};

export type employeeSalaryExchangeQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge',
  readonly node: employeeSalaryExchangeQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest
};

export type employeeSalaryExchangeQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly edges: ReadonlyArray<employeeSalaryExchangeQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge>
};

export type employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly userAccountId: string,
  readonly companyId: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly status: FlexSalaryExchangeRequestStatus,
  readonly requestedAt: string,
  readonly effectiveDate: string
};

export type employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryEdge',
  readonly node: employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest
};

export type employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryConnection',
  readonly edges: ReadonlyArray<employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge>
};

export type employeeSalaryExchangeQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchanges: employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection,
  readonly salaryExchangeRequests: employeeSalaryExchangeQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null,
  readonly salaryExchangeRequestsHistory: employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection | null
};


export type employeeSalaryExchangeQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
}>;


export type employeeSalaryExchangeQuery = employeeSalaryExchangeQuery_Query;

export type singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge_node_FlexSinglePremium_authorizedByMembership_Membership = {
  readonly __typename?: 'Membership',
  readonly givenName: string,
  readonly lastName: string
};

export type singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge_node_FlexSinglePremium = {
  readonly __typename?: 'FlexSinglePremium',
  readonly effectiveDate: string,
  readonly premium: string,
  readonly authorizedBy: string,
  readonly authorizedByMembership: singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge_node_FlexSinglePremium_authorizedByMembership_Membership | null
};

export type singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge = {
  readonly __typename?: 'FlexSinglePremiumEdge',
  readonly node: singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge_node_FlexSinglePremium
};

export type singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection = {
  readonly __typename?: 'FlexSinglePremiumConnection',
  readonly edges: ReadonlyArray<singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge>,
  readonly pageInfo: singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_pageInfo_PageInfo
};

export type singlePremiumDepositHistoryTableQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly singlePremiums: singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection | null
};

export type singlePremiumDepositHistoryTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: singlePremiumDepositHistoryTableQuery_membership_Membership | null
};


export type singlePremiumDepositHistoryTableQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type singlePremiumDepositHistoryTableQuery = singlePremiumDepositHistoryTableQuery_Query;

export type setFixedPremiumMutation_setFixedPremium_FlexSetFixedPremiumPayload = {
  readonly __typename?: 'FlexSetFixedPremiumPayload',
  readonly clientMutationId: string | null
};

export type setFixedPremiumMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly setFixedPremium: setFixedPremiumMutation_setFixedPremium_FlexSetFixedPremiumPayload | null
};


export type setFixedPremiumMutationVariables = Exact<{
  input: FlexSetFixedPremiumInput;
}>;


export type setFixedPremiumMutation = setFixedPremiumMutation_Mutation;

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute = {
  readonly __typename?: 'Institute',
  readonly id: string
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_nextPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_previousPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident = {
  readonly __typename?: 'InsuranceComponentAccident',
  readonly id: string,
  readonly status: InsuranceComponentStatus | null,
  readonly typeCode: string | null
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath = {
  readonly __typename?: 'InsuranceComponentDeath',
  readonly id: string,
  readonly status: InsuranceComponentStatus | null,
  readonly typeCode: string | null
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare = {
  readonly __typename?: 'InsuranceComponentHealthCare',
  readonly id: string,
  readonly status: InsuranceComponentStatus | null,
  readonly typeCode: string | null
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement = {
  readonly __typename?: 'InsuranceComponentRetirement',
  readonly id: string,
  readonly status: InsuranceComponentStatus | null,
  readonly typeCode: string | null
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness = {
  readonly __typename?: 'InsuranceComponentSickness',
  readonly id: string,
  readonly status: InsuranceComponentStatus | null,
  readonly typeCode: string | null
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components = updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident | updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath | updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare | updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement | updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness;

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly effectiveDate: string | null,
  readonly institute: updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute,
  readonly nextPremiumPayment: updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_nextPremiumPayment_CurrencyValue | null,
  readonly previousPremiumPayment: updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_previousPremiumPayment_CurrencyValue | null,
  readonly components: ReadonlyArray<updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components> | null
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge = {
  readonly __typename?: 'InsuranceEdge',
  readonly node: updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance
};

export type updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection = {
  readonly __typename?: 'InsuranceConnection',
  readonly edges: ReadonlyArray<updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge>
};

export type updatePensionPremiumModalQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly benefitPackageEffectiveDate: string | null,
  readonly insurances: updatePensionPremiumModalQuery_membership_Membership_insurances_InsuranceConnection | null
};

export type updatePensionPremiumModalQuery_fixedPremium_FlexFixedPremiumConnection_edges_FlexFixedPremiumEdge_node_FlexFixedPremium = {
  readonly __typename?: 'FlexFixedPremium',
  readonly userAccountId: string,
  readonly companyId: string,
  readonly premium: string,
  readonly effectiveDate: string,
  readonly createdAt: string
};

export type updatePensionPremiumModalQuery_fixedPremium_FlexFixedPremiumConnection_edges_FlexFixedPremiumEdge = {
  readonly __typename?: 'FlexFixedPremiumEdge',
  readonly node: updatePensionPremiumModalQuery_fixedPremium_FlexFixedPremiumConnection_edges_FlexFixedPremiumEdge_node_FlexFixedPremium
};

export type updatePensionPremiumModalQuery_fixedPremium_FlexFixedPremiumConnection = {
  readonly __typename?: 'FlexFixedPremiumConnection',
  readonly edges: ReadonlyArray<updatePensionPremiumModalQuery_fixedPremium_FlexFixedPremiumConnection_edges_FlexFixedPremiumEdge>
};

export type updatePensionPremiumModalQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: updatePensionPremiumModalQuery_membership_Membership | null,
  readonly fixedPremium?: updatePensionPremiumModalQuery_fixedPremium_FlexFixedPremiumConnection | null
};


export type updatePensionPremiumModalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  includeFixedPremium: Scalars['Boolean']['input'];
}>;


export type updatePensionPremiumModalQuery = updatePensionPremiumModalQuery_Query;

export type occupationalPensionChangesQuery_changes_FlexOccupationalPensionChange = (
  {
  readonly __typename?: 'FlexOccupationalPensionChange'
}
  & occupationalPensionChangeDetails
);

export type occupationalPensionChangesQuery_changes_FlexRetirementAgeChange = {
  readonly __typename?: 'FlexRetirementAgeChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskAccidentChange = {
  readonly __typename?: 'FlexRiskAccidentChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskHealthcareChange = {
  readonly __typename?: 'FlexRiskHealthcareChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskLifeChange = {
  readonly __typename?: 'FlexRiskLifeChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskPremiumWaiverChange = {
  readonly __typename?: 'FlexRiskPremiumWaiverChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskSicknessAndAccidentChange = {
  readonly __typename?: 'FlexRiskSicknessAndAccidentChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskSicknessChange = {
  readonly __typename?: 'FlexRiskSicknessChange'
};

export type occupationalPensionChangesQuery_changes_FlexRiskTglChange = {
  readonly __typename?: 'FlexRiskTglChange'
};

export type occupationalPensionChangesQuery_changes = occupationalPensionChangesQuery_changes_FlexOccupationalPensionChange | occupationalPensionChangesQuery_changes_FlexRetirementAgeChange | occupationalPensionChangesQuery_changes_FlexRiskAccidentChange | occupationalPensionChangesQuery_changes_FlexRiskHealthcareChange | occupationalPensionChangesQuery_changes_FlexRiskLifeChange | occupationalPensionChangesQuery_changes_FlexRiskPremiumWaiverChange | occupationalPensionChangesQuery_changes_FlexRiskSicknessAndAccidentChange | occupationalPensionChangesQuery_changes_FlexRiskSicknessChange | occupationalPensionChangesQuery_changes_FlexRiskTglChange;

export type occupationalPensionChangesQuery_Query = {
  readonly __typename?: 'Query',
  readonly changes: ReadonlyArray<occupationalPensionChangesQuery_changes> | null
};


export type occupationalPensionChangesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type occupationalPensionChangesQuery = occupationalPensionChangesQuery_Query;

export type createSinglePremiumMutation_createSinglePremium_FlexSinglePremiumInputPayload = {
  readonly __typename?: 'FlexSinglePremiumInputPayload',
  readonly clientMutationId: string | null
};

export type createSinglePremiumMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createSinglePremium: createSinglePremiumMutation_createSinglePremium_FlexSinglePremiumInputPayload | null
};


export type createSinglePremiumMutationVariables = Exact<{
  input: FlexSinglePremiumInput;
}>;


export type createSinglePremiumMutation = createSinglePremiumMutation_Mutation;

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute = {
  readonly __typename?: 'Institute',
  readonly id: string
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_nextPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_previousPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident = {
  readonly __typename?: 'InsuranceComponentAccident',
  readonly id: string,
  readonly typeCode: string | null
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath = {
  readonly __typename?: 'InsuranceComponentDeath',
  readonly id: string,
  readonly typeCode: string | null
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare = {
  readonly __typename?: 'InsuranceComponentHealthCare',
  readonly id: string,
  readonly typeCode: string | null
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement = {
  readonly __typename?: 'InsuranceComponentRetirement',
  readonly id: string,
  readonly typeCode: string | null
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness = {
  readonly __typename?: 'InsuranceComponentSickness',
  readonly id: string,
  readonly typeCode: string | null
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components = singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident | singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath | singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare | singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement | singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness;

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly insuranceNumber: string,
  readonly institute: singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute,
  readonly nextPremiumPayment: singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_nextPremiumPayment_CurrencyValue | null,
  readonly previousPremiumPayment: singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_previousPremiumPayment_CurrencyValue | null,
  readonly components: ReadonlyArray<singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components> | null
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge = {
  readonly __typename?: 'InsuranceEdge',
  readonly node: singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance
};

export type singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection = {
  readonly __typename?: 'InsuranceConnection',
  readonly edges: ReadonlyArray<singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge>
};

export type singlePremiumDepositModalQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly insurances: singlePremiumDepositModalQuery_membership_Membership_insurances_InsuranceConnection | null
};

export type singlePremiumDepositModalQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: singlePremiumDepositModalQuery_membership_Membership | null
};


export type singlePremiumDepositModalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type singlePremiumDepositModalQuery = singlePremiumDepositModalQuery_Query;

export type addMembershipAccountingItemMutation_addMembershipAccountingItem_AccountingItem_accountingObject_AccountingObject = {
  readonly __typename?: 'AccountingObject',
  readonly id: string
};

export type addMembershipAccountingItemMutation_addMembershipAccountingItem_AccountingItem = {
  readonly __typename?: 'AccountingItem',
  readonly accountingObject: addMembershipAccountingItemMutation_addMembershipAccountingItem_AccountingItem_accountingObject_AccountingObject
};

export type addMembershipAccountingItemMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addMembershipAccountingItem: addMembershipAccountingItemMutation_addMembershipAccountingItem_AccountingItem
};


export type addMembershipAccountingItemMutationVariables = Exact<{
  input?: InputMaybe<AddMembershipAccountingItemInput>;
}>;


export type addMembershipAccountingItemMutation = addMembershipAccountingItemMutation_Mutation;

export type organizationMembership_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type organizationMembership_Membership_organizationalUnit_OrganizationalUnit = {
  readonly __typename?: 'OrganizationalUnit',
  readonly id: string,
  readonly name: string
};

export type organizationMembership_Membership_accountingCost_AccountingCostItem_accountingCost_AccountingCost_object_AccountingObject_dimension_AccountingDimension = {
  readonly __typename?: 'AccountingDimension',
  readonly id: string
};

export type organizationMembership_Membership_accountingCost_AccountingCostItem_accountingCost_AccountingCost_object_AccountingObject = {
  readonly __typename?: 'AccountingObject',
  readonly id: string,
  readonly name: string | null,
  readonly dimension: organizationMembership_Membership_accountingCost_AccountingCostItem_accountingCost_AccountingCost_object_AccountingObject_dimension_AccountingDimension | null
};

export type organizationMembership_Membership_accountingCost_AccountingCostItem_accountingCost_AccountingCost = {
  readonly __typename?: 'AccountingCost',
  readonly object: organizationMembership_Membership_accountingCost_AccountingCostItem_accountingCost_AccountingCost_object_AccountingObject
};

export type organizationMembership_Membership_accountingCost_AccountingCostItem = {
  readonly __typename?: 'AccountingCostItem',
  readonly from: string,
  readonly to: string | null,
  readonly accountingCost: ReadonlyArray<organizationMembership_Membership_accountingCost_AccountingCostItem_accountingCost_AccountingCost>
};

export type organizationMembership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly company: organizationMembership_Membership_company_Company,
  readonly organizationalUnit: organizationMembership_Membership_organizationalUnit_OrganizationalUnit | null,
  readonly accountingCost: ReadonlyArray<organizationMembership_Membership_accountingCost_AccountingCostItem>
};

export type organizationCompany_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit = {
  readonly __typename?: 'OrganizationalUnit',
  readonly id: string,
  readonly name: string
};

export type organizationCompany_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge = {
  readonly __typename?: 'OrganizationalUnitEdge',
  readonly node: organizationCompany_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit
};

export type organizationCompany_Company_organizationalUnits_OrganizationalUnitConnection = {
  readonly __typename?: 'OrganizationalUnitConnection',
  readonly edges: ReadonlyArray<organizationCompany_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge>
};

export type organizationCompany_Company_costCenters_CostCenterConnection_edges_CostCenterEdge_node_CostCenter = {
  readonly __typename?: 'CostCenter',
  readonly id: string,
  readonly identifier: string,
  readonly name: string
};

export type organizationCompany_Company_costCenters_CostCenterConnection_edges_CostCenterEdge = {
  readonly __typename?: 'CostCenterEdge',
  readonly node: organizationCompany_Company_costCenters_CostCenterConnection_edges_CostCenterEdge_node_CostCenter
};

export type organizationCompany_Company_costCenters_CostCenterConnection = {
  readonly __typename?: 'CostCenterConnection',
  readonly edges: ReadonlyArray<organizationCompany_Company_costCenters_CostCenterConnection_edges_CostCenterEdge>
};

export type organizationCompany = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly organizationalUnits: organizationCompany_Company_organizationalUnits_OrganizationalUnitConnection | null,
  readonly costCenters: organizationCompany_Company_costCenters_CostCenterConnection | null
};

export type organizationAccountingDimension = {
  readonly __typename?: 'AccountingDimension',
  readonly id: string,
  readonly identifier: string | null,
  readonly name: string
};

export type membershipOrganizationQuery_membership_Membership = (
  {
  readonly __typename?: 'Membership'
}
  & organizationMembership
);

export type membershipOrganizationQuery_accountingDimensions_AccountingDimension = (
  {
  readonly __typename?: 'AccountingDimension'
}
  & organizationAccountingDimension
);

export type membershipOrganizationQuery_company_Company = (
  {
  readonly __typename?: 'Company'
}
  & organizationCompany
);

export type membershipOrganizationQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: membershipOrganizationQuery_membership_Membership | null,
  readonly accountingDimensions: ReadonlyArray<membershipOrganizationQuery_accountingDimensions_AccountingDimension>,
  readonly company: membershipOrganizationQuery_company_Company | null
};


export type membershipOrganizationQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type membershipOrganizationQuery = membershipOrganizationQuery_Query;

export type addCustomItemMutation_addCustomItem_CustomItemWrapper_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly unitCode: string | null
};

export type addCustomItemMutation_addCustomItem_CustomItemWrapper = {
  readonly __typename?: 'CustomItemWrapper',
  readonly remuneration: addCustomItemMutation_addCustomItem_CustomItemWrapper_remuneration_RemunerationItem | null
};

export type addCustomItemMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly addCustomItem: addCustomItemMutation_addCustomItem_CustomItemWrapper | null
};


export type addCustomItemMutationVariables = Exact<{
  input?: InputMaybe<AddCustomItemInput>;
}>;


export type addCustomItemMutation = addCustomItemMutation_Mutation;

export type membershipRemunerationsQuery_employment_Employment = {
  readonly __typename?: 'Employment',
  readonly id: string,
  readonly period: [string, string | null]
};

export type membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem_type_RemunerationItemType = {
  readonly __typename?: 'RemunerationItemType',
  readonly id: string,
  readonly name: string | null
};

export type membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly from: string,
  readonly to: string | null,
  readonly currency: string | null,
  readonly unitCode: string | null,
  readonly value: string,
  readonly type: membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem_type_RemunerationItemType
};

export type membershipRemunerationsQuery_membership_Membership_employment_M2Employment = {
  readonly __typename?: 'M2Employment',
  readonly from: string,
  readonly to: string | null,
  readonly remuneration: ReadonlyArray<membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem>
};

export type membershipRemunerationsQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly employment: ReadonlyArray<membershipRemunerationsQuery_membership_Membership_employment_M2Employment>
};

export type membershipRemunerationsQuery_Query = {
  readonly __typename?: 'Query',
  readonly employment?: membershipRemunerationsQuery_employment_Employment | null,
  readonly membership: membershipRemunerationsQuery_membership_Membership | null
};


export type membershipRemunerationsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  employmentId: Scalars['ID']['input'];
  hasEmploymentId: Scalars['Boolean']['input'];
}>;


export type membershipRemunerationsQuery = membershipRemunerationsQuery_Query;

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance_institute_Institute = {
  readonly __typename?: 'Institute',
  readonly id: string,
  readonly name: string
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance_nextPremiumPayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string,
  readonly currency: string
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly asOf: string,
  readonly deletedAt: string | null,
  readonly brokeredByAdvinans: boolean,
  readonly effectiveDate: string | null,
  readonly premiumPaymentFrequency: string | null,
  readonly institute: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance_institute_Institute,
  readonly nextPremiumPayment: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance_nextPremiumPayment_CurrencyValue | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout_amount_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly currency: string,
  readonly value: string
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout = {
  readonly __typename?: 'InsuranceComponentPayout',
  readonly payoutEvent: PayoutEvent | null,
  readonly amount: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout_amount_CurrencyValue | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare_copayment_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string,
  readonly currency: string
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident = {
  readonly __typename?: 'InsuranceComponentAccident',
  readonly coverage: AccidentCoverage | null,
  readonly id: string,
  readonly insuranceNumber: string,
  readonly status: InsuranceComponentStatus | null,
  readonly insurance: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance,
  readonly payout: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout> | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath = {
  readonly __typename?: 'InsuranceComponentDeath',
  readonly typeCode: string | null,
  readonly id: string,
  readonly insuranceNumber: string,
  readonly status: InsuranceComponentStatus | null,
  readonly insurance: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance,
  readonly payout: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout> | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare = {
  readonly __typename?: 'InsuranceComponentHealthCare',
  readonly id: string,
  readonly insuranceNumber: string,
  readonly status: InsuranceComponentStatus | null,
  readonly copayment: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare_copayment_CurrencyValue | null,
  readonly insurance: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance,
  readonly payout: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout> | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement = {
  readonly __typename?: 'InsuranceComponentRetirement',
  readonly id: string,
  readonly insuranceNumber: string,
  readonly status: InsuranceComponentStatus | null,
  readonly insurance: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance,
  readonly payout: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout> | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness = {
  readonly __typename?: 'InsuranceComponentSickness',
  readonly sicknessLevel: string | null,
  readonly typeCode: string | null,
  readonly id: string,
  readonly insuranceNumber: string,
  readonly status: InsuranceComponentStatus | null,
  readonly insurance: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_insurance_Insurance,
  readonly payout: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident_payout_InsuranceComponentPayout> | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components = riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentAccident | riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath | riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare | riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement | riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness;

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly components: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components> | null
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge = {
  readonly __typename?: 'InsuranceEdge',
  readonly node: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance
};

export type riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection = {
  readonly __typename?: 'InsuranceConnection',
  readonly edges: ReadonlyArray<riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge>
};

export type riskInsurancesAdminQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly insurances: riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection | null
};

export type riskInsurancesAdminQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: riskInsurancesAdminQuery_membership_Membership | null
};


export type riskInsurancesAdminQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  includeDeleted: Scalars['Boolean']['input'];
}>;


export type riskInsurancesAdminQuery = riskInsurancesAdminQuery_Query;

export type membershipRolesQuery_membership_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type membershipRolesQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly roles: ReadonlyArray<MembershipRole>,
  readonly company: membershipRolesQuery_membership_Membership_company_Company
};

export type membershipRolesQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: membershipRolesQuery_membership_Membership | null
};


export type membershipRolesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type membershipRolesQuery = membershipRolesQuery_Query;

export type totalCompensationDetailsQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly salary: boolean,
  readonly variableCompensation: boolean,
  readonly pension: boolean,
  readonly salaryExchange: boolean,
  readonly benefits: boolean,
  readonly wellness: boolean
};

export type totalCompensationDetailsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly totalCompensationConfiguration: totalCompensationDetailsQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type totalCompensationDetailsQuery_membership_Membership_totalCompensation_TotalCompensationItem2 = {
  readonly __typename?: 'TotalCompensationItem2',
  readonly category: TotalCompensationCategory2,
  readonly annualCompensation: string | null,
  readonly key: TotalCompensationId2
};

export type totalCompensationDetailsQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly employerContributionShare: string | null,
  readonly totalCompensation: ReadonlyArray<totalCompensationDetailsQuery_membership_Membership_totalCompensation_TotalCompensationItem2>
};

export type totalCompensationDetailsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: totalCompensationDetailsQuery_company_Company | null,
  readonly membership: totalCompensationDetailsQuery_membership_Membership | null
};


export type totalCompensationDetailsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type totalCompensationDetailsQuery = totalCompensationDetailsQuery_Query;

export type fitnessContributionCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly wellness: boolean
};

export type fitnessContributionCardQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly totalCompensationConfiguration: fitnessContributionCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type fitnessContributionCardQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly fitnessContributionBalance: string | null
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration = fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_EpassiBikeConfiguration | fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration_MiscConfiguration;

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit_configuration | null
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly benefit: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type fitnessContributionCardQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type fitnessContributionCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: fitnessContributionCardQuery_company_Company | null,
  readonly membership: fitnessContributionCardQuery_membership_Membership | null,
  readonly entitlements: fitnessContributionCardQuery_entitlements_FlexEntitlementConnection | null
};


export type fitnessContributionCardQueryVariables = Exact<{
  userAccountId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
}>;


export type fitnessContributionCardQuery = fitnessContributionCardQuery_Query;

export type pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent
};

export type pensionCardDetails_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement',
  readonly benefit: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionCardDetails_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement',
  readonly benefit: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionCardDetails_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement',
  readonly status: string,
  readonly netPremium: string | null,
  readonly benefit: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionCardDetails_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement',
  readonly benefit: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionCardDetails_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement',
  readonly benefit: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionCardDetails_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly benefit: pensionCardDetails_FlexEpassiBikeEntitlement_benefit_FlexBenefit
};

export type pensionCardDetails = pensionCardDetails_FlexEpassiBikeEntitlement | pensionCardDetails_FlexMiscEntitlement | pensionCardDetails_FlexOccupationalPensionEntitlement | pensionCardDetails_FlexPersonalAdviceEntitlement | pensionCardDetails_FlexRiskEntitlement | pensionCardDetails_FlexSalaryExchangeEntitlement;

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = (
  {
  readonly __typename?: 'FlexEpassiBikeEntitlement'
}
  & pensionCardDetails_FlexEpassiBikeEntitlement
);

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = (
  {
  readonly __typename?: 'FlexMiscEntitlement'
}
  & pensionCardDetails_FlexMiscEntitlement
);

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = (
  {
  readonly __typename?: 'FlexOccupationalPensionEntitlement'
}
  & pensionCardDetails_FlexOccupationalPensionEntitlement
);

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = (
  {
  readonly __typename?: 'FlexPersonalAdviceEntitlement'
}
  & pensionCardDetails_FlexPersonalAdviceEntitlement
);

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = (
  {
  readonly __typename?: 'FlexRiskEntitlement'
}
  & pensionCardDetails_FlexRiskEntitlement
);

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = (
  {
  readonly __typename?: 'FlexSalaryExchangeEntitlement'
}
  & pensionCardDetails_FlexSalaryExchangeEntitlement
);

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type pensionCardQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = (
  {
  readonly __typename?: 'FlexEpassiBikeEntitlement'
}
  & pensionCardDetails_FlexEpassiBikeEntitlement
);

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = (
  {
  readonly __typename?: 'FlexMiscEntitlement'
}
  & pensionCardDetails_FlexMiscEntitlement
);

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = (
  {
  readonly __typename?: 'FlexOccupationalPensionEntitlement'
}
  & pensionCardDetails_FlexOccupationalPensionEntitlement
);

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = (
  {
  readonly __typename?: 'FlexPersonalAdviceEntitlement'
}
  & pensionCardDetails_FlexPersonalAdviceEntitlement
);

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = (
  {
  readonly __typename?: 'FlexRiskEntitlement'
}
  & pensionCardDetails_FlexRiskEntitlement
);

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = (
  {
  readonly __typename?: 'FlexSalaryExchangeEntitlement'
}
  & pensionCardDetails_FlexSalaryExchangeEntitlement
);

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type pensionCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly pension: boolean
};

export type pensionCardQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly totalCompensationConfiguration: pensionCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type pensionCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly entitlements: pensionCardQuery_entitlements_FlexEntitlementConnection | null,
  readonly entitlementsOnDate: pensionCardQuery_entitlementsOnDate_FlexEntitlementConnection | null,
  readonly company: pensionCardQuery_company_Company | null
};


export type pensionCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  userAccountId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
}>;


export type pensionCardQuery = pensionCardQuery_Query;

export type salaryExchangeCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly salaryExchange: boolean
};

export type salaryExchangeCardQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly totalCompensationConfiguration: salaryExchangeCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement_benefit_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly content: salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement_benefit_FlexBenefit_content_FlexBenefitContent
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement = {
  readonly __typename?: 'FlexEpassiBikeEntitlement'
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement = {
  readonly __typename?: 'FlexMiscEntitlement'
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement = {
  readonly __typename?: 'FlexOccupationalPensionEntitlement'
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement = {
  readonly __typename?: 'FlexPersonalAdviceEntitlement'
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement = {
  readonly __typename?: 'FlexRiskEntitlement'
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement = {
  readonly __typename?: 'FlexSalaryExchangeEntitlement',
  readonly eligibleForOngoingExchange: boolean,
  readonly eligibleForOnetimeExchange: boolean,
  readonly benefit: salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement_benefit_FlexBenefit
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node = salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement | salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexMiscEntitlement | salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement | salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement | salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexRiskEntitlement | salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement;

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge = {
  readonly __typename?: 'FlexEntitlementEdge',
  readonly node: salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node
};

export type salaryExchangeCardQuery_entitlements_FlexEntitlementConnection = {
  readonly __typename?: 'FlexEntitlementConnection',
  readonly edges: ReadonlyArray<salaryExchangeCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge>
};

export type salaryExchangeCardQuery_salaryExchange_FlexSalaryExchange = {
  readonly __typename?: 'FlexSalaryExchange',
  readonly id: string,
  readonly status: FlexSalaryExchangeStatus,
  readonly premium: string
};

export type salaryExchangeCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly status: FlexSalaryExchangeRequestStatus,
  readonly premium: string
};

export type salaryExchangeCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge',
  readonly node: salaryExchangeCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest
};

export type salaryExchangeCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly edges: ReadonlyArray<salaryExchangeCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge>
};

export type salaryExchangeCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: salaryExchangeCardQuery_company_Company | null,
  readonly entitlements: salaryExchangeCardQuery_entitlements_FlexEntitlementConnection | null,
  readonly salaryExchange: salaryExchangeCardQuery_salaryExchange_FlexSalaryExchange | null,
  readonly salaryExchangeRequests: salaryExchangeCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type salaryExchangeCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  requestStatuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
}>;


export type salaryExchangeCardQuery = salaryExchangeCardQuery_Query;

export type totalCompensationSummaryCardQuery_membership_Membership_totalCompensation_TotalCompensationItem2 = {
  readonly __typename?: 'TotalCompensationItem2',
  readonly category: TotalCompensationCategory2,
  readonly includedInCompensation: boolean,
  readonly annualCompensation: string | null,
  readonly key: TotalCompensationId2
};

export type totalCompensationSummaryCardQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly totalCompensation: ReadonlyArray<totalCompensationSummaryCardQuery_membership_Membership_totalCompensation_TotalCompensationItem2>
};

export type totalCompensationSummaryCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: totalCompensationSummaryCardQuery_membership_Membership | null
};


export type totalCompensationSummaryCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type totalCompensationSummaryCardQuery = totalCompensationSummaryCardQuery_Query;

export type vacationCardQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly vacationDaysPaid: number | null,
  readonly vacationDaysSaved: number | null,
  readonly vacationDaysUnpaid: number | null,
  readonly vacationDaysAdvance: number | null,
  readonly vacationDaysPerYear: number | null
};

export type vacationCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly vacation: boolean
};

export type vacationCardQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly totalCompensationConfiguration: vacationCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type vacationCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: vacationCardQuery_membership_Membership | null,
  readonly company: vacationCardQuery_company_Company | null
};


export type vacationCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type vacationCardQuery = vacationCardQuery_Query;

export type variableCompensationCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly variableCompensation: boolean
};

export type variableCompensationCardQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly totalCompensationConfiguration: variableCompensationCardQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type variableCompensationCardQuery_membership_Membership_totalCompensation_TotalCompensationItem2 = {
  readonly __typename?: 'TotalCompensationItem2',
  readonly annualCompensation: string | null,
  readonly key: TotalCompensationId2
};

export type variableCompensationCardQuery_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly totalCompensation: ReadonlyArray<variableCompensationCardQuery_membership_Membership_totalCompensation_TotalCompensationItem2>
};

export type variableCompensationCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: variableCompensationCardQuery_company_Company | null,
  readonly membership: variableCompensationCardQuery_membership_Membership | null
};


export type variableCompensationCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type variableCompensationCardQuery = variableCompensationCardQuery_Query;

export type updateMembershipMutation_updateMembership_UpdateMemberShipPayload_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string
};

export type updateMembershipMutation_updateMembership_UpdateMemberShipPayload = {
  readonly __typename?: 'UpdateMemberShipPayload',
  readonly membership: updateMembershipMutation_updateMembership_UpdateMemberShipPayload_membership_Membership | null
};

export type updateMembershipMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateMembership: updateMembershipMutation_updateMembership_UpdateMemberShipPayload | null
};


export type updateMembershipMutationVariables = Exact<{
  input: UpdateMembershipInput;
}>;


export type updateMembershipMutation = updateMembershipMutation_Mutation;

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem_type_StatusItemType = {
  readonly __typename?: 'StatusItemType',
  readonly id: string
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem = {
  readonly __typename?: 'StatusItem',
  readonly from: string,
  readonly to: string | null,
  readonly type: companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem_type_StatusItemType | null
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment = {
  readonly __typename?: 'M2Employment',
  readonly from: string,
  readonly to: string | null,
  readonly status: ReadonlyArray<companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment_status_StatusItem>
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage = {
  readonly __typename?: 'MembershipBenefitPackage',
  readonly benefitPackage: companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage_benefitPackage_BenefitPackage
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly monthlySalary: string | null,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly employment: ReadonlyArray<companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_employment_M2Employment>,
  readonly membershipBenefitPackage: companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_membershipBenefitPackage_MembershipBenefitPackage | null
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge>,
  readonly pageInfo: companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_pageInfo_PageInfo
};

export type companyEmployeesOverviewQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly membershipExportUrl: string | null,
  readonly memberships: companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection | null
};

export type companyEmployeesOverviewQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyEmployeesOverviewQuery_company_Company | null
};


export type companyEmployeesOverviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type companyEmployeesOverviewQuery = companyEmployeesOverviewQuery_Query;

export type companyImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge_node_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly name: string,
  readonly status: ImportStatus,
  readonly createdAt: string
};

export type companyImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge = {
  readonly __typename?: 'ImportEdge',
  readonly node: companyImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge_node_Import
};

export type companyImportsQuery_company_Company_imports_ImportConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyImportsQuery_company_Company_imports_ImportConnection = {
  readonly __typename?: 'ImportConnection',
  readonly edges: ReadonlyArray<companyImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge>,
  readonly pageInfo: companyImportsQuery_company_Company_imports_ImportConnection_pageInfo_PageInfo
};

export type companyImportsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly imports: companyImportsQuery_company_Company_imports_ImportConnection
};

export type companyImportsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyImportsQuery_company_Company | null
};


export type companyImportsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type companyImportsQuery = companyImportsQuery_Query;

export type companyCustomColumnsQuery_accountingDimensions_AccountingDimension = {
  readonly __typename?: 'AccountingDimension',
  readonly id: string,
  readonly name: string
};

export type companyCustomColumnsQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge_node_CustomType = {
  readonly __typename?: 'CustomType',
  readonly id: string,
  readonly currency: string | null,
  readonly name: string | null,
  readonly superType: SUPER_TYPE,
  readonly unitCode: string | null
};

export type companyCustomColumnsQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge = {
  readonly __typename?: 'CustomTypeEdge',
  readonly node: companyCustomColumnsQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge_node_CustomType
};

export type companyCustomColumnsQuery_company_Company_customTypes_CustomTypeConnection = {
  readonly __typename?: 'CustomTypeConnection',
  readonly edges: ReadonlyArray<companyCustomColumnsQuery_company_Company_customTypes_CustomTypeConnection_edges_CustomTypeEdge>
};

export type companyCustomColumnsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly customTypes: companyCustomColumnsQuery_company_Company_customTypes_CustomTypeConnection | null
};

export type companyCustomColumnsQuery_Query = {
  readonly __typename?: 'Query',
  readonly accountingDimensions: ReadonlyArray<companyCustomColumnsQuery_accountingDimensions_AccountingDimension>,
  readonly company: companyCustomColumnsQuery_company_Company | null
};


export type companyCustomColumnsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  superType?: InputMaybe<SUPER_TYPE>;
}>;


export type companyCustomColumnsQuery = companyCustomColumnsQuery_Query;

export type updateImportMutation_updateImport_UpdateImportPayload_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string
};

export type updateImportMutation_updateImport_UpdateImportPayload = {
  readonly __typename?: 'UpdateImportPayload',
  readonly import: updateImportMutation_updateImport_UpdateImportPayload_import_Import
};

export type updateImportMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateImport: updateImportMutation_updateImport_UpdateImportPayload | null
};


export type updateImportMutationVariables = Exact<{
  input: UpdateImportInput;
}>;


export type updateImportMutation = updateImportMutation_Mutation;

export type importColumnsQuery_import_Import_columnMappings_ImportColumnMapping = {
  readonly __typename?: 'ImportColumnMapping',
  readonly source: string,
  readonly column: string | null
};

export type importColumnsQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly companyId: string,
  readonly name: string,
  readonly createdAt: string,
  readonly status: ImportStatus,
  readonly benefitsStartWithEmployment: boolean | null,
  readonly fallbackDateForBenefits: string,
  readonly createMissingOrganizationalUnits: boolean | null,
  readonly columnMappings: ReadonlyArray<importColumnsQuery_import_Import_columnMappings_ImportColumnMapping | null> | null
};

export type importColumnsQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: importColumnsQuery_import_Import | null
};


export type importColumnsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type importColumnsQuery = importColumnsQuery_Query;

export type importErrorsTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord = (
  {
  readonly __typename?: 'ImportRecord'
}
  & importRecordDetails
);

export type importErrorsTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge = {
  readonly __typename?: 'ImportRecordEdge',
  readonly node: importErrorsTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord
};

export type importErrorsTableQuery_import_Import_records_ImportRecordConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type importErrorsTableQuery_import_Import_records_ImportRecordConnection = {
  readonly __typename?: 'ImportRecordConnection',
  readonly totalCount: number,
  readonly edges: ReadonlyArray<importErrorsTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge>,
  readonly pageInfo: importErrorsTableQuery_import_Import_records_ImportRecordConnection_pageInfo_PageInfo
};

export type importErrorsTableQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly records: importErrorsTableQuery_import_Import_records_ImportRecordConnection | null
};

export type importErrorsTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: importErrorsTableQuery_import_Import | null
};


export type importErrorsTableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type importErrorsTableQuery = importErrorsTableQuery_Query;

export type importRecordString = {
  readonly __typename?: 'ImportRecordString',
  readonly value: string | null,
  readonly generatedChange: string | null,
  readonly isChanged: boolean
};

export type importRecordBoolean = {
  readonly __typename?: 'ImportRecordBoolean',
  readonly value: boolean | null,
  readonly generatedChange: boolean | null,
  readonly isChanged: boolean
};

export type importRecordInt = {
  readonly __typename?: 'ImportRecordInt',
  readonly value: number | null,
  readonly generatedChange: number | null,
  readonly isChanged: boolean
};

export type importRecordMoney = {
  readonly __typename?: 'ImportRecordMoney',
  readonly value: string | null,
  readonly generatedChange: string | null,
  readonly isChanged: boolean
};

export type importRecordDecimal = {
  readonly __typename?: 'ImportRecordDecimal',
  readonly value: string | null,
  readonly generatedChange: string | null,
  readonly isChanged: boolean
};

export type importRecordDate = {
  readonly __typename?: 'ImportRecordDate',
  readonly value: string | null,
  readonly generatedChange: string | null,
  readonly isChanged: boolean
};

export type importRecordCollectiveAgreement = {
  readonly __typename?: 'ImportRecordCollectiveAgreement',
  readonly value: CollectiveAgreement | null,
  readonly generatedChange: CollectiveAgreement | null,
  readonly isChanged: boolean
};

export type importRecordCustomItem_ImportRecordCustomItem_type_CustomTypeType = {
  readonly __typename?: 'CustomTypeType',
  readonly id: string | null,
  readonly type: SUPER_TYPE | null
};

export type importRecordCustomItem_ImportRecordCustomItem_item_ImportRecordDecimal = (
  {
  readonly __typename?: 'ImportRecordDecimal'
}
  & importRecordDecimal
);

export type importRecordCustomItem = {
  readonly __typename?: 'ImportRecordCustomItem',
  readonly from: string,
  readonly type: importRecordCustomItem_ImportRecordCustomItem_type_CustomTypeType,
  readonly item: importRecordCustomItem_ImportRecordCustomItem_item_ImportRecordDecimal
};

export type importRecordFieldsDetails_ImportRecordFields_email_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordFieldsDetails_ImportRecordFields_phone_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordFieldsDetails_ImportRecordFields_isOwner_ImportRecordBoolean = (
  {
  readonly __typename?: 'ImportRecordBoolean'
}
  & importRecordBoolean
);

export type importRecordFieldsDetails_ImportRecordFields_collectiveAgreement_ImportRecordCollectiveAgreement = (
  {
  readonly __typename?: 'ImportRecordCollectiveAgreement'
}
  & importRecordCollectiveAgreement
);

export type importRecordFieldsDetails_ImportRecordFields_vacationDaysSaved_ImportRecordInt = (
  {
  readonly __typename?: 'ImportRecordInt'
}
  & importRecordInt
);

export type importRecordFieldsDetails_ImportRecordFields_vacationDaysPaid_ImportRecordInt = (
  {
  readonly __typename?: 'ImportRecordInt'
}
  & importRecordInt
);

export type importRecordFieldsDetails_ImportRecordFields_vacationDaysUnpaid_ImportRecordInt = (
  {
  readonly __typename?: 'ImportRecordInt'
}
  & importRecordInt
);

export type importRecordFieldsDetails_ImportRecordFields_vacationDaysPerYear_ImportRecordInt = (
  {
  readonly __typename?: 'ImportRecordInt'
}
  & importRecordInt
);

export type importRecordFieldsDetails_ImportRecordFields_vacationDaysAdvance_ImportRecordInt = (
  {
  readonly __typename?: 'ImportRecordInt'
}
  & importRecordInt
);

export type importRecordFieldsDetails_ImportRecordFields_companyCarMonthlyValue_ImportRecordMoney = (
  {
  readonly __typename?: 'ImportRecordMoney'
}
  & importRecordMoney
);

export type importRecordFieldsDetails_ImportRecordFields_employerContributionShare_ImportRecordDecimal = (
  {
  readonly __typename?: 'ImportRecordDecimal'
}
  & importRecordDecimal
);

export type importRecordFieldsDetails_ImportRecordFields_fitnessContributionBalance_ImportRecordMoney = (
  {
  readonly __typename?: 'ImportRecordMoney'
}
  & importRecordMoney
);

export type importRecordFieldsDetails_ImportRecordFields_incomeTaxTable_ImportRecordInt = (
  {
  readonly __typename?: 'ImportRecordInt'
}
  & importRecordInt
);

export type importRecordFieldsDetails_ImportRecordFields_costCenter_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordFieldsDetails_ImportRecordFields_customTypeItems_ImportRecordCustomItem = (
  {
  readonly __typename?: 'ImportRecordCustomItem'
}
  & importRecordCustomItem
);

export type importRecordFieldsDetails_ImportRecordFields_benefitPackage_ImportRecordBenefitPackageFields_benefitPackageId_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordFieldsDetails_ImportRecordFields_benefitPackage_ImportRecordBenefitPackageFields = {
  readonly __typename?: 'ImportRecordBenefitPackageFields',
  readonly effectiveDate: string | null,
  readonly benefitPackageId: importRecordFieldsDetails_ImportRecordFields_benefitPackage_ImportRecordBenefitPackageFields_benefitPackageId_ImportRecordString
};

export type importRecordFieldsDetails_ImportRecordFields_salary_ImportRecordSalaryField_monthlySalary_ImportRecordMoney = (
  {
  readonly __typename?: 'ImportRecordMoney'
}
  & importRecordMoney
);

export type importRecordFieldsDetails_ImportRecordFields_salary_ImportRecordSalaryField = {
  readonly __typename?: 'ImportRecordSalaryField',
  readonly effectiveDate: string | null,
  readonly monthlySalary: importRecordFieldsDetails_ImportRecordFields_salary_ImportRecordSalaryField_monthlySalary_ImportRecordMoney
};

export type importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity_effectiveDate_ImportRecordDate = (
  {
  readonly __typename?: 'ImportRecordDate'
}
  & importRecordDate
);

export type importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity_effectiveUntil_ImportRecordDate = (
  {
  readonly __typename?: 'ImportRecordDate'
}
  & importRecordDate
);

export type importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity_rate_ImportRecordDecimal = (
  {
  readonly __typename?: 'ImportRecordDecimal'
}
  & importRecordDecimal
);

export type importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity = {
  readonly __typename?: 'ImportRecordEmploymentActivity',
  readonly type: EmploymentActivityType,
  readonly effectiveDate: importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity_effectiveDate_ImportRecordDate,
  readonly effectiveUntil: importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity_effectiveUntil_ImportRecordDate,
  readonly rate: importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity_rate_ImportRecordDecimal
};

export type importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_effectiveDate_ImportRecordDate = (
  {
  readonly __typename?: 'ImportRecordDate'
}
  & importRecordDate
);

export type importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_effectiveUntil_ImportRecordDate = (
  {
  readonly __typename?: 'ImportRecordDate'
}
  & importRecordDate
);

export type importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_rate_ImportRecordDecimal = (
  {
  readonly __typename?: 'ImportRecordDecimal'
}
  & importRecordDecimal
);

export type importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_type_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_hoursWorked_ImportRecordDecimal = (
  {
  readonly __typename?: 'ImportRecordDecimal'
}
  & importRecordDecimal
);

export type importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields = {
  readonly __typename?: 'ImportRecordEmploymentFields',
  readonly effectiveDate: importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_effectiveDate_ImportRecordDate | null,
  readonly effectiveUntil: importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_effectiveUntil_ImportRecordDate | null,
  readonly rate: importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_rate_ImportRecordDecimal | null,
  readonly type: importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_type_ImportRecordString | null,
  readonly hoursWorked: importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields_hoursWorked_ImportRecordDecimal | null
};

export type importRecordFieldsDetails_ImportRecordFields_organizationalUnits_ImportRecordOrgUnits = {
  readonly __typename?: 'ImportRecordOrgUnits',
  readonly value: ReadonlyArray<string> | null,
  readonly generatedChange: ReadonlyArray<string> | null,
  readonly isChanged: boolean
};

export type importRecordFieldsDetails_ImportRecordFields_accountingItems_ImportRecordFieldAccountingItem_identifier_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordFieldsDetails_ImportRecordFields_accountingItems_ImportRecordFieldAccountingItem = {
  readonly __typename?: 'ImportRecordFieldAccountingItem',
  readonly dimension: string,
  readonly identifier: importRecordFieldsDetails_ImportRecordFields_accountingItems_ImportRecordFieldAccountingItem_identifier_ImportRecordString
};

export type importRecordFieldsDetails = {
  readonly __typename?: 'ImportRecordFields',
  readonly email: importRecordFieldsDetails_ImportRecordFields_email_ImportRecordString | null,
  readonly phone: importRecordFieldsDetails_ImportRecordFields_phone_ImportRecordString | null,
  readonly isOwner: importRecordFieldsDetails_ImportRecordFields_isOwner_ImportRecordBoolean | null,
  readonly collectiveAgreement: importRecordFieldsDetails_ImportRecordFields_collectiveAgreement_ImportRecordCollectiveAgreement | null,
  readonly vacationDaysSaved: importRecordFieldsDetails_ImportRecordFields_vacationDaysSaved_ImportRecordInt | null,
  readonly vacationDaysPaid: importRecordFieldsDetails_ImportRecordFields_vacationDaysPaid_ImportRecordInt | null,
  readonly vacationDaysUnpaid: importRecordFieldsDetails_ImportRecordFields_vacationDaysUnpaid_ImportRecordInt | null,
  readonly vacationDaysPerYear: importRecordFieldsDetails_ImportRecordFields_vacationDaysPerYear_ImportRecordInt | null,
  readonly vacationDaysAdvance: importRecordFieldsDetails_ImportRecordFields_vacationDaysAdvance_ImportRecordInt | null,
  readonly companyCarMonthlyValue: importRecordFieldsDetails_ImportRecordFields_companyCarMonthlyValue_ImportRecordMoney | null,
  readonly employerContributionShare: importRecordFieldsDetails_ImportRecordFields_employerContributionShare_ImportRecordDecimal | null,
  readonly fitnessContributionBalance: importRecordFieldsDetails_ImportRecordFields_fitnessContributionBalance_ImportRecordMoney | null,
  readonly incomeTaxTable: importRecordFieldsDetails_ImportRecordFields_incomeTaxTable_ImportRecordInt | null,
  readonly costCenter: importRecordFieldsDetails_ImportRecordFields_costCenter_ImportRecordString | null,
  readonly customTypeItems: ReadonlyArray<importRecordFieldsDetails_ImportRecordFields_customTypeItems_ImportRecordCustomItem> | null,
  readonly benefitPackage: importRecordFieldsDetails_ImportRecordFields_benefitPackage_ImportRecordBenefitPackageFields | null,
  readonly salary: importRecordFieldsDetails_ImportRecordFields_salary_ImportRecordSalaryField | null,
  readonly activity: importRecordFieldsDetails_ImportRecordFields_activity_ImportRecordEmploymentActivity | null,
  readonly employment: importRecordFieldsDetails_ImportRecordFields_employment_ImportRecordEmploymentFields | null,
  readonly organizationalUnits: importRecordFieldsDetails_ImportRecordFields_organizationalUnits_ImportRecordOrgUnits | null,
  readonly accountingItems: ReadonlyArray<importRecordFieldsDetails_ImportRecordFields_accountingItems_ImportRecordFieldAccountingItem> | null
};

export type importRecordDetails_ImportRecord_id_ImportRecordId_employeeNumber_ImportRecordString = (
  {
  readonly __typename?: 'ImportRecordString'
}
  & importRecordString
);

export type importRecordDetails_ImportRecord_id_ImportRecordId = {
  readonly __typename?: 'ImportRecordId',
  readonly personalIdentityNumber: string | null,
  readonly userAccountId: string | null,
  readonly employeeNumber: importRecordDetails_ImportRecord_id_ImportRecordId_employeeNumber_ImportRecordString | null
};

export type importRecordDetails_ImportRecord_fields_ImportRecordFields = (
  {
  readonly __typename?: 'ImportRecordFields'
}
  & importRecordFieldsDetails
);

export type importRecordDetails = {
  readonly __typename?: 'ImportRecord',
  readonly index: number,
  readonly status: ImportStatus,
  readonly causes: ReadonlyArray<string | null> | null,
  readonly id: importRecordDetails_ImportRecord_id_ImportRecordId | null,
  readonly fields: importRecordDetails_ImportRecord_fields_ImportRecordFields | null
};

export type startImportMutation_startImport_StartImportPayload_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string
};

export type startImportMutation_startImport_StartImportPayload = {
  readonly __typename?: 'StartImportPayload',
  readonly import: startImportMutation_startImport_StartImportPayload_import_Import
};

export type startImportMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly startImport: startImportMutation_startImport_StartImportPayload | null
};


export type startImportMutationVariables = Exact<{
  input: StartImportInput;
}>;


export type startImportMutation = startImportMutation_Mutation;

export type importPollingQuery_import_Import_progress_ImportProgress_preview_Progress = {
  readonly __typename?: 'Progress',
  readonly total: number,
  readonly done: number
};

export type importPollingQuery_import_Import_progress_ImportProgress_runner_Progress = {
  readonly __typename?: 'Progress',
  readonly total: number,
  readonly done: number
};

export type importPollingQuery_import_Import_progress_ImportProgress = {
  readonly __typename?: 'ImportProgress',
  readonly preview: importPollingQuery_import_Import_progress_ImportProgress_preview_Progress | null,
  readonly runner: importPollingQuery_import_Import_progress_ImportProgress_runner_Progress | null
};

export type importPollingQuery_import_Import_records_ImportRecordConnection = {
  readonly __typename?: 'ImportRecordConnection',
  readonly totalCount: number
};

export type importPollingQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly status: ImportStatus,
  readonly createdAt: string,
  readonly fileUrl: string | null,
  readonly progress: importPollingQuery_import_Import_progress_ImportProgress | null,
  readonly records: importPollingQuery_import_Import_records_ImportRecordConnection | null
};

export type importPollingQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: importPollingQuery_import_Import | null
};


export type importPollingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<ImportStatus> | ImportStatus>;
}>;


export type importPollingQuery = importPollingQuery_Query;

export type importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord_id_ImportRecordId = {
  readonly __typename?: 'ImportRecordId',
  readonly membershipId: string | null
};

export type importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord = {
  readonly __typename?: 'ImportRecord',
  readonly id: importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord_id_ImportRecordId | null
};

export type importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge = {
  readonly __typename?: 'ImportRecordEdge',
  readonly node: importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord
};

export type importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection = {
  readonly __typename?: 'ImportRecordConnection',
  readonly edges: ReadonlyArray<importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge>
};

export type importCompleteUserRecordsQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly records: importCompleteUserRecordsQuery_import_Import_records_ImportRecordConnection | null
};

export type importCompleteUserRecordsQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: importCompleteUserRecordsQuery_import_Import | null
};


export type importCompleteUserRecordsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type importCompleteUserRecordsQuery = importCompleteUserRecordsQuery_Query;

export type latestImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge_node_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly status: ImportStatus,
  readonly createdAt: string,
  readonly progressStatus: ProgressStatus
};

export type latestImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge = {
  readonly __typename?: 'ImportEdge',
  readonly node: latestImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge_node_Import
};

export type latestImportsQuery_company_Company_imports_ImportConnection = {
  readonly __typename?: 'ImportConnection',
  readonly edges: ReadonlyArray<latestImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge>
};

export type latestImportsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly imports: latestImportsQuery_company_Company_imports_ImportConnection
};

export type latestImportsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: latestImportsQuery_company_Company | null
};


export type latestImportsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type latestImportsQuery = latestImportsQuery_Query;

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment = {
  readonly __typename?: 'Employment',
  readonly id: string,
  readonly period: [string, string | null]
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge = {
  readonly __typename?: 'EmploymentEdge',
  readonly node: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership_employments_EmploymentConnection = {
  readonly __typename?: 'EmploymentConnection',
  readonly edges: ReadonlyArray<inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge>
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string,
  readonly employments: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership_employments_EmploymentConnection | null
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment = {
  readonly __typename?: 'AnalysisResultInactiveEmployment',
  readonly membership: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment_membership_Membership | null
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge = {
  readonly __typename?: 'AnalysisResultInactiveEmploymentEdge',
  readonly node: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge_node_AnalysisResultInactiveEmployment
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection = {
  readonly __typename?: 'AnalysisResultInactiveEmploymentConnection',
  readonly totalCount: number,
  readonly pageInfo: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection_edges_AnalysisResultInactiveEmploymentEdge>
};

export type inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult = {
  readonly __typename?: 'AnalysisResult',
  readonly inactiveEmployments: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult_inactiveEmployments_AnalysisResultInactiveEmploymentConnection
};

export type inactiveEmploymentsQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly analysis: inactiveEmploymentsQuery_import_Import_analysis_AnalysisResult | null
};

export type inactiveEmploymentsQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: inactiveEmploymentsQuery_import_Import | null
};


export type inactiveEmploymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type inactiveEmploymentsQuery = inactiveEmploymentsQuery_Query;

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment = {
  readonly __typename?: 'Employment',
  readonly id: string,
  readonly period: [string, string | null]
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge = {
  readonly __typename?: 'EmploymentEdge',
  readonly node: missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge_node_Employment
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership_employments_EmploymentConnection = {
  readonly __typename?: 'EmploymentConnection',
  readonly edges: ReadonlyArray<missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership_employments_EmploymentConnection_edges_EmploymentEdge>
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string,
  readonly employments: missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership_employments_EmploymentConnection | null
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment = {
  readonly __typename?: 'AnalysisResultMissingEmployment',
  readonly membership: missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment_membership_Membership
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge = {
  readonly __typename?: 'AnalysisResultMissingEmploymentEdge',
  readonly node: missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge_node_AnalysisResultMissingEmployment
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection = {
  readonly __typename?: 'AnalysisResultMissingEmploymentConnection',
  readonly totalCount: number,
  readonly pageInfo: missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection_edges_AnalysisResultMissingEmploymentEdge>
};

export type missingEmploymentsQuery_import_Import_analysis_AnalysisResult = {
  readonly __typename?: 'AnalysisResult',
  readonly missingEmployments: missingEmploymentsQuery_import_Import_analysis_AnalysisResult_missingEmployments_AnalysisResultMissingEmploymentConnection
};

export type missingEmploymentsQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly analysis: missingEmploymentsQuery_import_Import_analysis_AnalysisResult | null
};

export type missingEmploymentsQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: missingEmploymentsQuery_import_Import | null
};


export type missingEmploymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type missingEmploymentsQuery = missingEmploymentsQuery_Query;

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge_node_AnalysisResultOverlappingEmployment_membership_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly displayName: string,
  readonly registrationNumber: string
};

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge_node_AnalysisResultOverlappingEmployment_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly givenName: string,
  readonly lastName: string,
  readonly company: overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge_node_AnalysisResultOverlappingEmployment_membership_Membership_company_Company
};

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge_node_AnalysisResultOverlappingEmployment = {
  readonly __typename?: 'AnalysisResultOverlappingEmployment',
  readonly membership: overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge_node_AnalysisResultOverlappingEmployment_membership_Membership
};

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge = {
  readonly __typename?: 'AnalysisResultOverlappingEmploymentEdge',
  readonly node: overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge_node_AnalysisResultOverlappingEmployment
};

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection = {
  readonly __typename?: 'AnalysisResultOverlappingEmploymentConnection',
  readonly totalCount: number,
  readonly pageInfo: overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection_edges_AnalysisResultOverlappingEmploymentEdge>
};

export type overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult = {
  readonly __typename?: 'AnalysisResult',
  readonly overlappingEmployments: overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult_overlappingEmployments_AnalysisResultOverlappingEmploymentConnection
};

export type overlappingEmploymentsQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly analysis: overlappingEmploymentsQuery_import_Import_analysis_AnalysisResult | null
};

export type overlappingEmploymentsQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: overlappingEmploymentsQuery_import_Import | null
};


export type overlappingEmploymentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type overlappingEmploymentsQuery = overlappingEmploymentsQuery_Query;

export type previewTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord = (
  {
  readonly __typename?: 'ImportRecord'
}
  & importRecordDetails
);

export type previewTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge = {
  readonly __typename?: 'ImportRecordEdge',
  readonly node: previewTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge_node_ImportRecord
};

export type previewTableQuery_import_Import_records_ImportRecordConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type previewTableQuery_import_Import_records_ImportRecordConnection = {
  readonly __typename?: 'ImportRecordConnection',
  readonly totalCount: number,
  readonly edges: ReadonlyArray<previewTableQuery_import_Import_records_ImportRecordConnection_edges_ImportRecordEdge>,
  readonly pageInfo: previewTableQuery_import_Import_records_ImportRecordConnection_pageInfo_PageInfo
};

export type previewTableQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly status: ImportStatus,
  readonly records: previewTableQuery_import_Import_records_ImportRecordConnection | null
};

export type previewTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: previewTableQuery_import_Import | null
};


export type previewTableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<ReadonlyArray<ImportStatus> | ImportStatus>;
}>;


export type previewTableQuery = previewTableQuery_Query;

export type importSummaryCardsQuery_import_Import_changesSummary_ImportChangesSummary = {
  readonly __typename?: 'ImportChangesSummary',
  readonly newMemberships: number,
  readonly salaryChanges: number,
  readonly newActivities: number,
  readonly terminatedEmployments: number,
  readonly unchangedRows: number
};

export type importSummaryCardsQuery_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string,
  readonly status: ImportStatus,
  readonly changesSummary: importSummaryCardsQuery_import_Import_changesSummary_ImportChangesSummary | null
};

export type importSummaryCardsQuery_Query = {
  readonly __typename?: 'Query',
  readonly import: importSummaryCardsQuery_import_Import | null
};


export type importSummaryCardsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type importSummaryCardsQuery = importSummaryCardsQuery_Query;

export type createImportMutation_createImport_CreateImportPayload_import_Import = {
  readonly __typename?: 'Import',
  readonly id: string
};

export type createImportMutation_createImport_CreateImportPayload = {
  readonly __typename?: 'CreateImportPayload',
  readonly import: createImportMutation_createImport_CreateImportPayload_import_Import
};

export type createImportMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createImport: createImportMutation_createImport_CreateImportPayload | null
};


export type createImportMutationVariables = Exact<{
  input: CreateImportInput;
}>;


export type createImportMutation = createImportMutation_Mutation;

export type membershipsSearchQueryDetails = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string
};

export type membershipsSearchQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = (
  {
  readonly __typename?: 'Membership'
}
  & membershipsSearchQueryDetails
);

export type membershipsSearchQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: membershipsSearchQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type membershipsSearchQuery_company_Company_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<membershipsSearchQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge>
};

export type membershipsSearchQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly memberships: membershipsSearchQuery_company_Company_memberships_MembershipConnection | null
};

export type membershipsSearchQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: membershipsSearchQuery_company_Company | null
};


export type membershipsSearchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type membershipsSearchQuery = membershipsSearchQuery_Query;

export type employeeNameSearchQuery_membership_Membership = (
  {
  readonly __typename?: 'Membership'
}
  & membershipsSearchQueryDetails
);

export type employeeNameSearchQuery_Query = {
  readonly __typename?: 'Query',
  readonly membership: employeeNameSearchQuery_membership_Membership | null
};


export type employeeNameSearchQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  userAccountId: Scalars['ID']['input'];
}>;


export type employeeNameSearchQuery = employeeNameSearchQuery_Query;

export type companyCostCentersQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge_node_CostCenter = {
  readonly __typename?: 'CostCenter',
  readonly id: string,
  readonly name: string
};

export type companyCostCentersQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge = {
  readonly __typename?: 'CostCenterEdge',
  readonly node: companyCostCentersQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge_node_CostCenter
};

export type companyCostCentersQuery_company_Company_costCenters_CostCenterConnection = {
  readonly __typename?: 'CostCenterConnection',
  readonly edges: ReadonlyArray<companyCostCentersQuery_company_Company_costCenters_CostCenterConnection_edges_CostCenterEdge>
};

export type companyCostCentersQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly costCenters: companyCostCentersQuery_company_Company_costCenters_CostCenterConnection | null
};

export type companyCostCentersQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyCostCentersQuery_company_Company | null
};


export type companyCostCentersQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyCostCentersQuery = companyCostCentersQuery_Query;

export type companyBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type companyBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: companyBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type companyBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<companyBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type companyBenefitPackagesQuery_companyBenefitPackages_Company = {
  readonly __typename?: 'Company',
  readonly benefitPackages: companyBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection | null
};

export type companyBenefitPackagesQuery_Query = {
  readonly __typename?: 'Query',
  readonly companyBenefitPackages: companyBenefitPackagesQuery_companyBenefitPackages_Company | null
};


export type companyBenefitPackagesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyBenefitPackagesQuery = companyBenefitPackagesQuery_Query;

export type companyInvoiceSuppliersQuery_companyInvoiceSuppliers_Company = {
  readonly __typename?: 'Company',
  readonly invoiceSuppliers: ReadonlyArray<InvoiceSupplier>
};

export type companyInvoiceSuppliersQuery_Query = {
  readonly __typename?: 'Query',
  readonly companyInvoiceSuppliers: companyInvoiceSuppliersQuery_companyInvoiceSuppliers_Company | null
};


export type companyInvoiceSuppliersQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyInvoiceSuppliersQuery = companyInvoiceSuppliersQuery_Query;

export type companyInvoiceLineItemNamesQuery_companyInvoiceLineItemNames_Company = {
  readonly __typename?: 'Company',
  readonly invoiceLineItemNames: ReadonlyArray<string>
};

export type companyInvoiceLineItemNamesQuery_Query = {
  readonly __typename?: 'Query',
  readonly companyInvoiceLineItemNames: companyInvoiceLineItemNamesQuery_companyInvoiceLineItemNames_Company | null
};


export type companyInvoiceLineItemNamesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type companyInvoiceLineItemNamesQuery = companyInvoiceLineItemNamesQuery_Query;

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine_invoicePeriod_InvoicePeriod = {
  readonly __typename?: 'InvoicePeriod',
  readonly startDate: string | null,
  readonly endDate: string | null
};

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine_invoice_Invoice = {
  readonly __typename?: 'Invoice',
  readonly id: string,
  readonly supplier: string
};

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine = {
  readonly __typename?: 'InvoiceLine',
  readonly id: string,
  readonly name: string,
  readonly firstName: string | null,
  readonly lastName: string | null,
  readonly invoicedQuantity: string,
  readonly personalIdentityNumber: string | null,
  readonly priceWithVat: string | null,
  readonly lineExtensionCurrency: string | null,
  readonly invoicePeriod: invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine_invoicePeriod_InvoicePeriod | null,
  readonly invoice: invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine_invoice_Invoice | null
};

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge = {
  readonly __typename?: 'InvoiceLineEdge',
  readonly node: invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine
};

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection = {
  readonly __typename?: 'InvoiceLineConnection',
  readonly totalCount: number,
  readonly edges: ReadonlyArray<invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge>,
  readonly pageInfo: invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_pageInfo_PageInfo
};

export type invoiceLinesQuery_invoiceSearch_InvoiceSearchResult = {
  readonly __typename?: 'InvoiceSearchResult',
  readonly excelUrl: string | null,
  readonly csvUrl: string | null,
  readonly invoiceLines: invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection
};

export type invoiceLinesQuery_Query = {
  readonly __typename?: 'Query',
  readonly invoiceSearch: invoiceLinesQuery_invoiceSearch_InvoiceSearchResult | null
};


export type invoiceLinesQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  userAccountIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  accountingCosts?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  suppliers?: InputMaybe<ReadonlyArray<InvoiceSupplier> | InvoiceSupplier>;
  taxClass?: InputMaybe<TaxClass>;
  pensionLayer?: InputMaybe<PensionLayer>;
  linePeriod?: InputMaybe<LineDateFilter>;
  issueDate?: InputMaybe<DateFilter>;
  dueDate?: InputMaybe<DateFilter>;
  invoiceLineItemNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  benefitPackageId?: InputMaybe<Scalars['String']['input']>;
}>;


export type invoiceLinesQuery = invoiceLinesQuery_Query;

export type invoiceDetails = {
  readonly __typename?: 'Invoice',
  readonly id: string,
  readonly companyId: string,
  readonly status: InvoiceStatus,
  readonly payableAmount: string | null,
  readonly invoiceNumber: string | null,
  readonly issueDate: string,
  readonly dueDate: string | null,
  readonly supplierType: InvoiceSupplier,
  readonly peppolUrl: string | null,
  readonly sie4Url: string | null,
  readonly pdfUrl: string | null,
  readonly htmlUrl: string | null,
  readonly excelUrl: string | null,
  readonly csvUrl: string | null
};

export type updateInvoiceStatusMutation_updateInvoiceStatus_UpdateInvoiceStatusPayload_invoice_Invoice = (
  {
  readonly __typename?: 'Invoice'
}
  & invoiceDetails
);

export type updateInvoiceStatusMutation_updateInvoiceStatus_UpdateInvoiceStatusPayload = {
  readonly __typename?: 'UpdateInvoiceStatusPayload',
  readonly invoice: updateInvoiceStatusMutation_updateInvoiceStatus_UpdateInvoiceStatusPayload_invoice_Invoice
};

export type updateInvoiceStatusMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateInvoiceStatus: updateInvoiceStatusMutation_updateInvoiceStatus_UpdateInvoiceStatusPayload | null
};


export type updateInvoiceStatusMutationVariables = Exact<{
  input: UpdateInvoiceStatusInput;
}>;


export type updateInvoiceStatusMutation = updateInvoiceStatusMutation_Mutation;

export type uploadPdfInvoiceMutation_uploadPdfInvoice_UploadPdfInvoicePayload = {
  readonly __typename?: 'UploadPdfInvoicePayload',
  readonly invoiceId: string
};

export type uploadPdfInvoiceMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly uploadPdfInvoice: uploadPdfInvoiceMutation_uploadPdfInvoice_UploadPdfInvoicePayload
};


export type uploadPdfInvoiceMutationVariables = Exact<{
  input: UploadPdfInvoiceInput;
}>;


export type uploadPdfInvoiceMutation = uploadPdfInvoiceMutation_Mutation;

export type companyInvoicesQuery_company_Company_invoices_InvoiceConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyInvoicesQuery_company_Company_invoices_InvoiceConnection_edges_InvoiceEdge_node_Invoice = (
  {
  readonly __typename?: 'Invoice'
}
  & invoiceDetails
);

export type companyInvoicesQuery_company_Company_invoices_InvoiceConnection_edges_InvoiceEdge = {
  readonly __typename?: 'InvoiceEdge',
  readonly node: companyInvoicesQuery_company_Company_invoices_InvoiceConnection_edges_InvoiceEdge_node_Invoice
};

export type companyInvoicesQuery_company_Company_invoices_InvoiceConnection = {
  readonly __typename?: 'InvoiceConnection',
  readonly pageInfo: companyInvoicesQuery_company_Company_invoices_InvoiceConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyInvoicesQuery_company_Company_invoices_InvoiceConnection_edges_InvoiceEdge>
};

export type companyInvoicesQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly invoices: companyInvoicesQuery_company_Company_invoices_InvoiceConnection
};

export type companyInvoicesQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyInvoicesQuery_company_Company | null
};


export type companyInvoicesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<ReadonlyArray<InvoiceSupplier> | InvoiceSupplier>;
  issueDate?: InputMaybe<DateFilter>;
  dueDate?: InputMaybe<DateFilter>;
  status?: InputMaybe<ReadonlyArray<InvoiceStatus> | InvoiceStatus>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  payableAmount?: InputMaybe<IntRangeFilter>;
}>;


export type companyInvoicesQuery = companyInvoicesQuery_Query;

export type jobOfferStatusNotificationQuery_jobOffer_JobOffer = {
  readonly __typename?: 'JobOffer',
  readonly id: string,
  readonly status: JobOfferStatus
};

export type jobOfferStatusNotificationQuery_Query = {
  readonly __typename?: 'Query',
  readonly jobOffer: jobOfferStatusNotificationQuery_jobOffer_JobOffer | null
};


export type jobOfferStatusNotificationQueryVariables = Exact<{
  jobOfferId: Scalars['ID']['input'];
}>;


export type jobOfferStatusNotificationQuery = jobOfferStatusNotificationQuery_Query;

export type createJobOfferLayoutQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly logoUrl: string | null
};

export type createJobOfferLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: createJobOfferLayoutQuery_company_Company | null
};


export type createJobOfferLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type createJobOfferLayoutQuery = createJobOfferLayoutQuery_Query;

export type editJobOfferDetails_JobOffer_remunerations_JobOfferRemuneration = {
  readonly __typename?: 'JobOfferRemuneration',
  readonly idCode: string,
  readonly value: string
};

export type editJobOfferDetails_JobOffer_attachmentFiles_AttachedFile = {
  readonly __typename?: 'AttachedFile',
  readonly id: string,
  readonly url: string,
  readonly filename: string | null
};

export type editJobOfferDetails_JobOffer_optionsProgrammeFiles_AttachedFile = {
  readonly __typename?: 'AttachedFile',
  readonly id: string,
  readonly url: string,
  readonly filename: string | null
};

export type editJobOfferDetails_JobOffer_coverPictureFile_AttachedFile = {
  readonly __typename?: 'AttachedFile',
  readonly id: string,
  readonly url: string,
  readonly filename: string | null
};

export type editJobOfferDetails_JobOffer_profilePictureFile_AttachedFile = {
  readonly __typename?: 'AttachedFile',
  readonly id: string,
  readonly url: string,
  readonly filename: string | null
};

export type editJobOfferDetails = {
  readonly __typename?: 'JobOffer',
  readonly id: string,
  readonly title: string | null,
  readonly body: string | null,
  readonly prospectName: string | null,
  readonly yearlyVacationDays: number | null,
  readonly collectiveAgreement: TotalCompensationCollectiveAgreement | null,
  readonly monthlyLunchAllowance: string | null,
  readonly yearlyWellnessAllowance: string | null,
  readonly monthlyCompanyCarValue: string | null,
  readonly hidden: boolean | null,
  readonly validUntil: string | null,
  readonly status: JobOfferStatus,
  readonly companyLogo: string | null,
  readonly workLocation: string | null,
  readonly workSetting: string | null,
  readonly showDiscount: boolean | null,
  readonly optionsProgram: string | null,
  readonly birthdate: string | null,
  readonly benefitPackageId: string | null,
  readonly remunerations: ReadonlyArray<editJobOfferDetails_JobOffer_remunerations_JobOfferRemuneration> | null,
  readonly attachmentFiles: ReadonlyArray<editJobOfferDetails_JobOffer_attachmentFiles_AttachedFile> | null,
  readonly optionsProgrammeFiles: ReadonlyArray<editJobOfferDetails_JobOffer_optionsProgrammeFiles_AttachedFile> | null,
  readonly coverPictureFile: editJobOfferDetails_JobOffer_coverPictureFile_AttachedFile | null,
  readonly profilePictureFile: editJobOfferDetails_JobOffer_profilePictureFile_AttachedFile | null
};

export type createJobOfferMutation_createJobOffer_JobOfferPayload_jobOffer_JobOffer_remunerations_JobOfferRemuneration = {
  readonly __typename?: 'JobOfferRemuneration',
  readonly idCode: string,
  readonly value: string
};

export type createJobOfferMutation_createJobOffer_JobOfferPayload_jobOffer_JobOffer = {
  readonly __typename?: 'JobOffer',
  readonly id: string,
  readonly companyId: string,
  readonly title: string | null,
  readonly body: string | null,
  readonly prospectName: string | null,
  readonly yearlyVacationDays: number | null,
  readonly collectiveAgreement: TotalCompensationCollectiveAgreement | null,
  readonly monthlyLunchAllowance: string | null,
  readonly yearlyWellnessAllowance: string | null,
  readonly monthlyCompanyCarValue: string | null,
  readonly hidden: boolean | null,
  readonly validUntil: string | null,
  readonly status: JobOfferStatus,
  readonly updatedAt: string | null,
  readonly companyName: string,
  readonly companyLogo: string | null,
  readonly workLocation: string | null,
  readonly workSetting: string | null,
  readonly showDiscount: boolean | null,
  readonly optionsProgram: string | null,
  readonly birthdate: string | null,
  readonly benefitPackageId: string | null,
  readonly remunerations: ReadonlyArray<createJobOfferMutation_createJobOffer_JobOfferPayload_jobOffer_JobOffer_remunerations_JobOfferRemuneration> | null
};

export type createJobOfferMutation_createJobOffer_JobOfferPayload = {
  readonly __typename?: 'JobOfferPayload',
  readonly jobOffer: createJobOfferMutation_createJobOffer_JobOfferPayload_jobOffer_JobOffer | null
};

export type createJobOfferMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createJobOffer: createJobOfferMutation_createJobOffer_JobOfferPayload | null
};


export type createJobOfferMutationVariables = Exact<{
  input: CreateJobOfferInput;
}>;


export type createJobOfferMutation = createJobOfferMutation_Mutation;

export type editJobOfferMutation_updateJobOffer_JobOfferPayload_jobOffer_JobOffer = (
  {
  readonly __typename?: 'JobOffer'
}
  & editJobOfferDetails
);

export type editJobOfferMutation_updateJobOffer_JobOfferPayload = {
  readonly __typename?: 'JobOfferPayload',
  readonly jobOffer: editJobOfferMutation_updateJobOffer_JobOfferPayload_jobOffer_JobOffer | null
};

export type editJobOfferMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateJobOffer: editJobOfferMutation_updateJobOffer_JobOfferPayload | null
};


export type editJobOfferMutationVariables = Exact<{
  input: UpdateJobOfferInput;
}>;


export type editJobOfferMutation = editJobOfferMutation_Mutation;

export type deleteJobOfferMutation_deleteJobOffer_DeleteJobOfferPayload = {
  readonly __typename?: 'DeleteJobOfferPayload',
  readonly jobOfferId: string
};

export type deleteJobOfferMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteJobOffer: deleteJobOfferMutation_deleteJobOffer_DeleteJobOfferPayload | null
};


export type deleteJobOfferMutationVariables = Exact<{
  input: DeleteJobOfferInput;
}>;


export type deleteJobOfferMutation = deleteJobOfferMutation_Mutation;

export type jobOfferBenefitPackagesQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type jobOfferBenefitPackagesQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: jobOfferBenefitPackagesQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type jobOfferBenefitPackagesQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<jobOfferBenefitPackagesQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type jobOfferBenefitPackagesQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefitPackages: jobOfferBenefitPackagesQuery_company_Company_benefitPackages_BenefitPackageConnection | null
};

export type jobOfferBenefitPackagesQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: jobOfferBenefitPackagesQuery_company_Company | null
};


export type jobOfferBenefitPackagesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type jobOfferBenefitPackagesQuery = jobOfferBenefitPackagesQuery_Query;

export type editJobOfferQuery_jobOffer_JobOffer = (
  {
  readonly __typename?: 'JobOffer'
}
  & editJobOfferDetails
);

export type editJobOfferQuery_Query = {
  readonly __typename?: 'Query',
  readonly jobOffer: editJobOfferQuery_jobOffer_JobOffer | null
};


export type editJobOfferQueryVariables = Exact<{
  jobOfferId: Scalars['ID']['input'];
}>;


export type editJobOfferQuery = editJobOfferQuery_Query;

export type createNoteMutation_createNote_CreateNotePayload = {
  readonly __typename?: 'CreateNotePayload',
  readonly clientMutationId: string | null,
  readonly id: string
};

export type createNoteMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createNote: createNoteMutation_createNote_CreateNotePayload | null
};


export type createNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;


export type createNoteMutation = createNoteMutation_Mutation;

export type updateNoteMutation_updateNote_UpdateNotePayload = {
  readonly __typename?: 'UpdateNotePayload',
  readonly clientMutationId: string | null,
  readonly id: string
};

export type updateNoteMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateNote: updateNoteMutation_updateNote_UpdateNotePayload | null
};


export type updateNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;


export type updateNoteMutation = updateNoteMutation_Mutation;

export type deleteNoteMutation_deleteNote_DeleteNotePayload = {
  readonly __typename?: 'DeleteNotePayload',
  readonly clientMutationId: string | null,
  readonly id: string
};

export type deleteNoteMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteNote: deleteNoteMutation_deleteNote_DeleteNotePayload | null
};


export type deleteNoteMutationVariables = Exact<{
  input: DeleteNoteInput;
}>;


export type deleteNoteMutation = deleteNoteMutation_Mutation;

export type noteFragmentDetails_Note_author_Author = {
  readonly __typename?: 'Author',
  readonly name: string | null,
  readonly adminUserExternalId: string | null
};

export type noteFragmentDetails_Note_attachments_AttachedFile = {
  readonly __typename?: 'AttachedFile',
  readonly id: string,
  readonly filename: string | null,
  readonly url: string
};

export type noteFragmentDetails = {
  readonly __typename?: 'Note',
  readonly id: string,
  readonly subject: string,
  readonly content: string | null,
  readonly category: NoteCategory,
  readonly createdAt: string,
  readonly author: noteFragmentDetails_Note_author_Author,
  readonly attachments: ReadonlyArray<noteFragmentDetails_Note_attachments_AttachedFile>
};

export type notesQuery_notes_NoteConnection_edges_NoteEdge_node_Note = (
  {
  readonly __typename?: 'Note'
}
  & noteFragmentDetails
);

export type notesQuery_notes_NoteConnection_edges_NoteEdge = {
  readonly __typename?: 'NoteEdge',
  readonly node: notesQuery_notes_NoteConnection_edges_NoteEdge_node_Note
};

export type notesQuery_notes_NoteConnection_pageInfo_PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type notesQuery_notes_NoteConnection = {
  readonly __typename?: 'NoteConnection',
  readonly edges: ReadonlyArray<notesQuery_notes_NoteConnection_edges_NoteEdge>,
  readonly pageInfo: notesQuery_notes_NoteConnection_pageInfo_PageInfo
};

export type notesQuery_Query = {
  readonly __typename?: 'Query',
  readonly notes: notesQuery_notes_NoteConnection | null
};


export type notesQueryVariables = Exact<{
  userAccountId?: InputMaybe<Scalars['ID']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  categories?: InputMaybe<ReadonlyArray<NoteCategory> | NoteCategory>;
  author?: InputMaybe<AuthorInput>;
  sortOrder?: InputMaybe<SortOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type notesQuery = notesQuery_Query;

export type noteAuthorsQuery_noteAuthors_Author = {
  readonly __typename?: 'Author',
  readonly adminUserExternalId: string | null,
  readonly name: string | null
};

export type noteAuthorsQuery_Query = {
  readonly __typename?: 'Query',
  readonly noteAuthors: ReadonlyArray<noteAuthorsQuery_noteAuthors_Author>
};


export type noteAuthorsQueryVariables = Exact<{ [key: string]: never; }>;


export type noteAuthorsQuery = noteAuthorsQuery_Query;

export type noteQuery_note_Note = (
  {
  readonly __typename?: 'Note'
}
  & noteFragmentDetails
);

export type noteQuery_Query = {
  readonly __typename?: 'Query',
  readonly note: noteQuery_note_Note | null
};


export type noteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type noteQuery = noteQuery_Query;

export type updateNotificationMutation_updateNotification_UpdateNotificationPayload = {
  readonly __typename?: 'UpdateNotificationPayload',
  readonly clientMutationId: string | null
};

export type updateNotificationMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateNotification: updateNotificationMutation_updateNotification_UpdateNotificationPayload | null
};


export type updateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;


export type updateNotificationMutation = updateNotificationMutation_Mutation;

export type companyNotificationsQuery_notifications_NotificationConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_statusChangedBy_Author = {
  readonly __typename?: 'Author',
  readonly name: string | null,
  readonly userAccountId: string | null,
  readonly adminUserExternalId: string | null
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MaxSalaryToPensionRatioExceededMetadata = {
  readonly __typename?: 'MaxSalaryToPensionRatioExceededMetadata',
  readonly fullName: string,
  readonly year: number
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MaxTotalPensionCostExceededMetadata = {
  readonly __typename?: 'MaxTotalPensionCostExceededMetadata',
  readonly fullName: string,
  readonly year: number,
  readonly maxTotalPensionCost: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingBenefitPackageMetadata = {
  readonly __typename?: 'MissingBenefitPackageMetadata',
  readonly fullName: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingEmailMetadata = {
  readonly __typename?: 'MissingEmailMetadata',
  readonly fullName: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingFcfwAssertionMetadata = {
  readonly __typename?: 'MissingFcfwAssertionMetadata',
  readonly fullName: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingPensionPremiumMetadata = {
  readonly __typename?: 'MissingPensionPremiumMetadata',
  readonly fullName: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingSalaryMetadata = {
  readonly __typename?: 'MissingSalaryMetadata',
  readonly fullName: string,
  readonly from: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxErrorMetadata = {
  readonly __typename?: 'SxErrorMetadata',
  readonly fullName: string,
  readonly deductionValue: string,
  readonly effectiveDate: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxOnetimeAvailableMetadata = {
  readonly __typename?: 'SxOnetimeAvailableMetadata',
  readonly onetimeSalary: string,
  readonly cutoffDate: string,
  readonly maxDeductionValue: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxRequestApprovedMetadata = {
  readonly __typename?: 'SxRequestApprovedMetadata',
  readonly fullName: string,
  readonly deductionValue: string,
  readonly effectiveDate: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxRequestRejectedMetadata = {
  readonly __typename?: 'SxRequestRejectedMetadata',
  readonly deductionValue: string,
  readonly effectiveDate: string,
  readonly reason: string | null
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxRequestedMetadata = {
  readonly __typename?: 'SxRequestedMetadata',
  readonly fullName: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxViolatedMetadata = {
  readonly __typename?: 'SxViolatedMetadata',
  readonly fullName: string
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata = companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MaxSalaryToPensionRatioExceededMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MaxTotalPensionCostExceededMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingBenefitPackageMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingEmailMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingFcfwAssertionMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingPensionPremiumMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_MissingSalaryMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxErrorMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxOnetimeAvailableMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxRequestApprovedMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxRequestRejectedMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxRequestedMetadata | companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata_SxViolatedMetadata;

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification = {
  readonly __typename?: 'Notification',
  readonly id: string,
  readonly type: NotificationType,
  readonly userAccountId: string,
  readonly companyId: string | null,
  readonly externalId: string,
  readonly status: NotificationStatus,
  readonly statusUpdatedAt: string | null,
  readonly createdAt: string,
  readonly statusChangedBy: companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_statusChangedBy_Author | null,
  readonly metadata: companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification_metadata | null
};

export type companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge = {
  readonly __typename?: 'NotificationEdge',
  readonly node: companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification
};

export type companyNotificationsQuery_notifications_NotificationConnection = {
  readonly __typename?: 'NotificationConnection',
  readonly totalCount: number | null,
  readonly pageInfo: companyNotificationsQuery_notifications_NotificationConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge>
};

export type companyNotificationsQuery_Query = {
  readonly __typename?: 'Query',
  readonly notifications: companyNotificationsQuery_notifications_NotificationConnection | null
};


export type companyNotificationsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  status: NotificationStatus;
}>;


export type companyNotificationsQuery = companyNotificationsQuery_Query;

export type organizationalUnitDetails_OrganizationalUnit_unitOf_OrganizationalUnit = {
  readonly __typename?: 'OrganizationalUnit',
  readonly id: string,
  readonly name: string
};

export type organizationalUnitDetails_OrganizationalUnit_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename: 'Membership',
  readonly id: string
};

export type organizationalUnitDetails_OrganizationalUnit_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: organizationalUnitDetails_OrganizationalUnit_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type organizationalUnitDetails_OrganizationalUnit_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<organizationalUnitDetails_OrganizationalUnit_memberships_MembershipConnection_edges_MembershipEdge>
};

export type organizationalUnitDetails = {
  readonly __typename?: 'OrganizationalUnit',
  readonly id: string,
  readonly name: string,
  readonly slug: string,
  readonly path: string,
  readonly unitOf: organizationalUnitDetails_OrganizationalUnit_unitOf_OrganizationalUnit | null,
  readonly memberships: organizationalUnitDetails_OrganizationalUnit_memberships_MembershipConnection | null
};

export type createOrganizationalUnitMutation_createOrganizationalUnit_CreateOrganizationalUnitPayload_organizationalUnit_OrganizationalUnit = (
  {
  readonly __typename?: 'OrganizationalUnit'
}
  & organizationalUnitDetails
);

export type createOrganizationalUnitMutation_createOrganizationalUnit_CreateOrganizationalUnitPayload = {
  readonly __typename?: 'CreateOrganizationalUnitPayload',
  readonly organizationalUnit: createOrganizationalUnitMutation_createOrganizationalUnit_CreateOrganizationalUnitPayload_organizationalUnit_OrganizationalUnit
};

export type createOrganizationalUnitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createOrganizationalUnit: createOrganizationalUnitMutation_createOrganizationalUnit_CreateOrganizationalUnitPayload | null
};


export type createOrganizationalUnitMutationVariables = Exact<{
  input: CreateOrganizationalUnitInput;
}>;


export type createOrganizationalUnitMutation = createOrganizationalUnitMutation_Mutation;

export type updateOrganizationalUnitMutation_updateOrganizationalUnit_UpdateOrganizationalUnitPayload_organizationalUnit_OrganizationalUnit = (
  {
  readonly __typename?: 'OrganizationalUnit'
}
  & organizationalUnitDetails
);

export type updateOrganizationalUnitMutation_updateOrganizationalUnit_UpdateOrganizationalUnitPayload = {
  readonly __typename?: 'UpdateOrganizationalUnitPayload',
  readonly organizationalUnit: updateOrganizationalUnitMutation_updateOrganizationalUnit_UpdateOrganizationalUnitPayload_organizationalUnit_OrganizationalUnit
};

export type updateOrganizationalUnitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateOrganizationalUnit: updateOrganizationalUnitMutation_updateOrganizationalUnit_UpdateOrganizationalUnitPayload | null
};


export type updateOrganizationalUnitMutationVariables = Exact<{
  input: UpdateOrganizationalUnitInput;
}>;


export type updateOrganizationalUnitMutation = updateOrganizationalUnitMutation_Mutation;

export type deleteOrganizationalUnitMutation_deleteOrganizationalUnit_DeleteOrganizationalUnitPayload = {
  readonly __typename?: 'DeleteOrganizationalUnitPayload',
  readonly id: string
};

export type deleteOrganizationalUnitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteOrganizationalUnit: deleteOrganizationalUnitMutation_deleteOrganizationalUnit_DeleteOrganizationalUnitPayload | null
};


export type deleteOrganizationalUnitMutationVariables = Exact<{
  input: DeleteOrganizationalUnitInput;
}>;


export type deleteOrganizationalUnitMutation = deleteOrganizationalUnitMutation_Mutation;

export type organizationalUnitsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit = (
  {
  readonly __typename?: 'OrganizationalUnit'
}
  & organizationalUnitDetails
);

export type organizationalUnitsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge = {
  readonly __typename?: 'OrganizationalUnitEdge',
  readonly node: organizationalUnitsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge_node_OrganizationalUnit
};

export type organizationalUnitsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection = {
  readonly __typename?: 'OrganizationalUnitConnection',
  readonly edges: ReadonlyArray<organizationalUnitsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection_edges_OrganizationalUnitEdge>
};

export type organizationalUnitsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly organizationalUnits: organizationalUnitsQuery_company_Company_organizationalUnits_OrganizationalUnitConnection | null
};

export type organizationalUnitsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: organizationalUnitsQuery_company_Company | null
};


export type organizationalUnitsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type organizationalUnitsQuery = organizationalUnitsQuery_Query;

export type organizationalUnitQuery_organizationalUnit_OrganizationalUnit = (
  {
  readonly __typename?: 'OrganizationalUnit'
}
  & organizationalUnitDetails
);

export type organizationalUnitQuery_Query = {
  readonly __typename?: 'Query',
  readonly organizationalUnit: organizationalUnitQuery_organizationalUnit_OrganizationalUnit | null
};


export type organizationalUnitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type organizationalUnitQuery = organizationalUnitQuery_Query;

export type bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly effectiveDate: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly provider: FlexSalaryExchangeProvider,
  readonly membership: bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest_membership_Membership
};

export type bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryEdge',
  readonly node: bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest
};

export type bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryConnection',
  readonly edges: ReadonlyArray<bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge>,
  readonly pageInfo: bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_pageInfo_PageInfo
};

export type bonusSalaryExchangesTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly bonusSalaryExchangeRequestsHistory: bonusSalaryExchangesTableQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection | null
};


export type bonusSalaryExchangesTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
}>;


export type bonusSalaryExchangesTableQuery = bonusSalaryExchangesTableQuery_Query;

export type bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest = (
  {
  readonly __typename?: 'FlexSalaryExchangeRequest'
}
  & salaryExchangeEventDetails
);

export type bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryEdge',
  readonly node: bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest
};

export type bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryConnection',
  readonly edges: ReadonlyArray<bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge>,
  readonly pageInfo: bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_pageInfo_PageInfo
};

export type bonusSalaryExchangeEventsQuery_Query = {
  readonly __typename?: 'Query',
  readonly bonusSalaryExchangeRequestsHistory: bonusSalaryExchangeEventsQuery_bonusSalaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection | null
};


export type bonusSalaryExchangeEventsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type bonusSalaryExchangeEventsQuery = bonusSalaryExchangeEventsQuery_Query;

export type salaryExchangeEventDetails_FlexSalaryExchangeRequest_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly unitCode: string | null,
  readonly currency: string | null,
  readonly value: string
};

export type salaryExchangeEventDetails_FlexSalaryExchangeRequest_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>
};

export type salaryExchangeEventDetails = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly effectiveDate: string,
  readonly status: FlexSalaryExchangeRequestStatus,
  readonly provider: FlexSalaryExchangeProvider,
  readonly premium: string,
  readonly deductionValue: string,
  readonly remuneration: salaryExchangeEventDetails_FlexSalaryExchangeRequest_remuneration_RemunerationItem | null,
  readonly membership: salaryExchangeEventDetails_FlexSalaryExchangeRequest_membership_Membership
};

export type salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest = (
  {
  readonly __typename?: 'FlexSalaryExchangeRequest'
}
  & salaryExchangeEventDetails
);

export type salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryEdge',
  readonly node: salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest
};

export type salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestsHistoryConnection',
  readonly edges: ReadonlyArray<salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge>,
  readonly pageInfo: salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_pageInfo_PageInfo
};

export type salaryExchangeEventsQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchangeRequestsHistory: salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection | null
};


export type salaryExchangeEventsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type salaryExchangeEventsQuery = salaryExchangeEventsQuery_Query;

export type salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly unitCode: string | null,
  readonly currency: string | null,
  readonly value: string
};

export type salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange = {
  readonly __typename?: 'FlexSalaryExchange',
  readonly id: string,
  readonly companyId: string,
  readonly userAccountId: string,
  readonly effectiveDate: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly provider: FlexSalaryExchangeProvider,
  readonly status: FlexSalaryExchangeStatus,
  readonly violated: boolean,
  readonly membership: salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_membership_Membership,
  readonly remuneration: salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange_remuneration_RemunerationItem | null
};

export type salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge = {
  readonly __typename?: 'FlexSalaryExchangeEdge',
  readonly node: salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange
};

export type salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection = {
  readonly __typename?: 'FlexSalaryExchangeConnection',
  readonly edges: ReadonlyArray<salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge>,
  readonly pageInfo: salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_pageInfo_PageInfo
};

export type salaryExchangesTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchanges: salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection
};


export type salaryExchangesTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeStatus> | FlexSalaryExchangeStatus>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type salaryExchangesTableQuery = salaryExchangesTableQuery_Query;

export type salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_edges_FlexSalaryExchangeReportEdge_node_FlexSalaryExchangeReport_file_FlexFile = {
  readonly __typename?: 'FlexFile',
  readonly url: string,
  readonly name: string
};

export type salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_edges_FlexSalaryExchangeReportEdge_node_FlexSalaryExchangeReport = {
  readonly __typename?: 'FlexSalaryExchangeReport',
  readonly reportCreatedAt: string,
  readonly file: salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_edges_FlexSalaryExchangeReportEdge_node_FlexSalaryExchangeReport_file_FlexFile
};

export type salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_edges_FlexSalaryExchangeReportEdge = {
  readonly __typename?: 'FlexSalaryExchangeReportEdge',
  readonly node: salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_edges_FlexSalaryExchangeReportEdge_node_FlexSalaryExchangeReport
};

export type salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection = {
  readonly __typename?: 'FlexSalaryExchangeReportConnection',
  readonly edges: ReadonlyArray<salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_edges_FlexSalaryExchangeReportEdge>,
  readonly pageInfo: salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection_pageInfo_PageInfo
};

export type salaryExchangeReportsQuery_Query = {
  readonly __typename?: 'Query',
  readonly flexSalaryExchangeReports: salaryExchangeReportsQuery_flexSalaryExchangeReports_FlexSalaryExchangeReportConnection | null
};


export type salaryExchangeReportsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type salaryExchangeReportsQuery = salaryExchangeReportsQuery_Query;

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration = salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration | salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration;

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent,
  readonly configuration: salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration | null
};

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type salaryExchangeReportsIntroQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly flexBenefits: salaryExchangeReportsIntroQuery_company_Company_flexBenefits_FlexBenefitConnection | null
};

export type salaryExchangeReportsIntroQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: salaryExchangeReportsIntroQuery_company_Company | null
};


export type salaryExchangeReportsIntroQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type salaryExchangeReportsIntroQuery = salaryExchangeReportsIntroQuery_Query;

export type approveSalaryExchangeRequestMutation_approveSalaryExchangeRequest_FlexApproveSalaryExchangeRequestPayload_salaryExchangeRequest_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string
};

export type approveSalaryExchangeRequestMutation_approveSalaryExchangeRequest_FlexApproveSalaryExchangeRequestPayload = {
  readonly __typename?: 'FlexApproveSalaryExchangeRequestPayload',
  readonly salaryExchangeRequest: approveSalaryExchangeRequestMutation_approveSalaryExchangeRequest_FlexApproveSalaryExchangeRequestPayload_salaryExchangeRequest_FlexSalaryExchangeRequest
};

export type approveSalaryExchangeRequestMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly approveSalaryExchangeRequest: approveSalaryExchangeRequestMutation_approveSalaryExchangeRequest_FlexApproveSalaryExchangeRequestPayload
};


export type approveSalaryExchangeRequestMutationVariables = Exact<{
  input: FlexApproveSalaryExchangeRequestInput;
}>;


export type approveSalaryExchangeRequestMutation = approveSalaryExchangeRequestMutation_Mutation;

export type bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest = (
  {
  readonly __typename?: 'FlexSalaryExchangeRequest'
}
  & requestDetails
);

export type bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge',
  readonly node: bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest
};

export type bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly edges: ReadonlyArray<bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge>,
  readonly pageInfo: bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection_pageInfo_PageInfo
};

export type bonusSalaryExchangeRequestsQuery_Query = {
  readonly __typename?: 'Query',
  readonly bonusSalaryExchangeRequests: bonusSalaryExchangeRequestsQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type bonusSalaryExchangeRequestsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  requestIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type bonusSalaryExchangeRequestsQuery = bonusSalaryExchangeRequestsQuery_Query;

export type requestDetails_FlexSalaryExchangeRequest_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>
};

export type requestDetails_FlexSalaryExchangeRequest_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly unitCode: string | null,
  readonly currency: string | null,
  readonly value: string
};

export type requestDetails = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly provider: FlexSalaryExchangeProvider,
  readonly membership: requestDetails_FlexSalaryExchangeRequest_membership_Membership,
  readonly remuneration: requestDetails_FlexSalaryExchangeRequest_remuneration_RemunerationItem | null
};

export type salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest = (
  {
  readonly __typename?: 'FlexSalaryExchangeRequest'
}
  & requestDetails
);

export type salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge',
  readonly node: salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest
};

export type salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly edges: ReadonlyArray<salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge>,
  readonly pageInfo: salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_pageInfo_PageInfo
};

export type salaryExchangeRequestsQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchangeRequests: salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type salaryExchangeRequestsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  requestIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type salaryExchangeRequestsQuery = salaryExchangeRequestsQuery_Query;

export type rejectSalaryExchangeRequestMutation_rejectSalaryExchangeRequest_FlexRejectSalaryExchangeRequestPayload_salaryExchangeRequest_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string
};

export type rejectSalaryExchangeRequestMutation_rejectSalaryExchangeRequest_FlexRejectSalaryExchangeRequestPayload = {
  readonly __typename?: 'FlexRejectSalaryExchangeRequestPayload',
  readonly salaryExchangeRequest: rejectSalaryExchangeRequestMutation_rejectSalaryExchangeRequest_FlexRejectSalaryExchangeRequestPayload_salaryExchangeRequest_FlexSalaryExchangeRequest
};

export type rejectSalaryExchangeRequestMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly rejectSalaryExchangeRequest: rejectSalaryExchangeRequestMutation_rejectSalaryExchangeRequest_FlexRejectSalaryExchangeRequestPayload
};


export type rejectSalaryExchangeRequestMutationVariables = Exact<{
  input: FlexRejectSalaryExchangeRequestInput;
}>;


export type rejectSalaryExchangeRequestMutation = rejectSalaryExchangeRequestMutation_Mutation;

export type pendingSalaryExchangeRequestsCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly totalCount: number | null
};

export type pendingSalaryExchangeRequestsCardQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly totalCount: number | null
};

export type pendingSalaryExchangeRequestsCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchangeRequests: pendingSalaryExchangeRequestsCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null,
  readonly bonusSalaryExchangeRequests: pendingSalaryExchangeRequestsCardQuery_bonusSalaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type pendingSalaryExchangeRequestsCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
}>;


export type pendingSalaryExchangeRequestsCardQuery = pendingSalaryExchangeRequestsCardQuery_Query;

export type salaryExchangesCardQuery_salaryExchanges_FlexSalaryExchangeConnection = {
  readonly __typename?: 'FlexSalaryExchangeConnection',
  readonly totalCount: number | null
};

export type salaryExchangesCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchanges: salaryExchangesCardQuery_salaryExchanges_FlexSalaryExchangeConnection
};


export type salaryExchangesCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeStatus> | FlexSalaryExchangeStatus>;
}>;


export type salaryExchangesCardQuery = salaryExchangesCardQuery_Query;

export type terminateSalaryExchangeMutation_flexTerminateSalaryExchange_FlexTerminateSalaryExchangePayload_salaryExchange_FlexSalaryExchange = {
  readonly __typename?: 'FlexSalaryExchange',
  readonly id: string,
  readonly userAccountId: string,
  readonly status: FlexSalaryExchangeStatus,
  readonly deductionValue: string,
  readonly premium: string,
  readonly effectiveDate: string
};

export type terminateSalaryExchangeMutation_flexTerminateSalaryExchange_FlexTerminateSalaryExchangePayload = {
  readonly __typename?: 'FlexTerminateSalaryExchangePayload',
  readonly salaryExchange: terminateSalaryExchangeMutation_flexTerminateSalaryExchange_FlexTerminateSalaryExchangePayload_salaryExchange_FlexSalaryExchange
};

export type terminateSalaryExchangeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly flexTerminateSalaryExchange: terminateSalaryExchangeMutation_flexTerminateSalaryExchange_FlexTerminateSalaryExchangePayload
};


export type terminateSalaryExchangeMutationVariables = Exact<{
  input: FlexTerminateSalaryExchangeInput;
}>;


export type terminateSalaryExchangeMutation = terminateSalaryExchangeMutation_Mutation;

export type upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest_membership_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly givenName: string,
  readonly lastName: string
};

export type upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest_remuneration_RemunerationItem = {
  readonly __typename?: 'RemunerationItem',
  readonly unitCode: string | null,
  readonly currency: string | null,
  readonly value: string
};

export type upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest = {
  readonly __typename?: 'FlexSalaryExchangeRequest',
  readonly id: string,
  readonly companyId: string,
  readonly userAccountId: string,
  readonly effectiveDate: string,
  readonly deductionValue: string,
  readonly premium: string,
  readonly provider: FlexSalaryExchangeProvider,
  readonly status: FlexSalaryExchangeRequestStatus,
  readonly violated: boolean,
  readonly membership: upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest_membership_Membership,
  readonly remuneration: upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest_remuneration_RemunerationItem | null
};

export type upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge = {
  readonly __typename?: 'FlexSalaryExchangeRequestEdge',
  readonly node: upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest
};

export type upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly edges: ReadonlyArray<upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge>,
  readonly pageInfo: upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_pageInfo_PageInfo
};

export type upcomingSalaryExchangesTableQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchangeRequests: upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type upcomingSalaryExchangesTableQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type upcomingSalaryExchangesTableQuery = upcomingSalaryExchangesTableQuery_Query;

export type upcomingSalaryExchangesCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly totalCount: number | null
};

export type upcomingSalaryExchangesCardQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchangeRequests: upcomingSalaryExchangesCardQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type upcomingSalaryExchangesCardQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
}>;


export type upcomingSalaryExchangesCardQuery = upcomingSalaryExchangesCardQuery_Query;

export type violatedSalaryExchangesBannerQuery_salaryExchanges_FlexSalaryExchangeConnection = {
  readonly __typename?: 'FlexSalaryExchangeConnection',
  readonly totalCount: number | null
};

export type violatedSalaryExchangesBannerQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection = {
  readonly __typename?: 'FlexSalaryExchangeRequestConnection',
  readonly totalCount: number | null
};

export type violatedSalaryExchangesBannerQuery_Query = {
  readonly __typename?: 'Query',
  readonly salaryExchanges: violatedSalaryExchangesBannerQuery_salaryExchanges_FlexSalaryExchangeConnection,
  readonly salaryExchangeRequests: violatedSalaryExchangesBannerQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection | null
};


export type violatedSalaryExchangesBannerQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  statuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeStatus> | FlexSalaryExchangeStatus>;
  requestStatuses?: InputMaybe<ReadonlyArray<FlexSalaryExchangeRequestStatus> | FlexSalaryExchangeRequestStatus>;
  onlyViolated?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type violatedSalaryExchangesBannerQuery = violatedSalaryExchangesBannerQuery_Query;

export type selectClearingQuery_clearingCodes_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly code: string,
  readonly name: string
};

export type selectClearingQuery_Query = {
  readonly __typename?: 'Query',
  readonly clearingCodes: ReadonlyArray<selectClearingQuery_clearingCodes_ClearingCode>
};


export type selectClearingQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type selectClearingQuery = selectClearingQuery_Query;

export type createSharedFilesMutation_createSharedFiles_CreateSharedFilesPayload = {
  readonly __typename?: 'CreateSharedFilesPayload',
  readonly clientMutationId: string | null
};

export type createSharedFilesMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createSharedFiles: createSharedFilesMutation_createSharedFiles_CreateSharedFilesPayload | null
};


export type createSharedFilesMutationVariables = Exact<{
  input: CreateSharedFilesInput;
}>;


export type createSharedFilesMutation = createSharedFilesMutation_Mutation;

export type deleteSharedFileMutation_deleteSharedFile_DeleteSharedFilePayload = {
  readonly __typename?: 'DeleteSharedFilePayload',
  readonly clientMutationId: string | null
};

export type deleteSharedFileMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteSharedFile: deleteSharedFileMutation_deleteSharedFile_DeleteSharedFilePayload | null
};


export type deleteSharedFileMutationVariables = Exact<{
  input: DeleteSharedFileInput;
}>;


export type deleteSharedFileMutation = deleteSharedFileMutation_Mutation;

export type sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile_file_AttachedFile = {
  readonly __typename?: 'AttachedFile',
  readonly id: string,
  readonly url: string,
  readonly filename: string | null
};

export type sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile_author_Author = {
  readonly __typename?: 'Author',
  readonly adminUserExternalId: string | null,
  readonly name: string | null
};

export type sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile = {
  readonly __typename?: 'SharedFile',
  readonly id: string,
  readonly createdAt: string,
  readonly file: sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile_file_AttachedFile,
  readonly author: sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile_author_Author
};

export type sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge = {
  readonly __typename?: 'SharedFileEdge',
  readonly node: sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile
};

export type sharedFilesQuery_sharedFiles_SharedFileConnection_pageInfo_PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type sharedFilesQuery_sharedFiles_SharedFileConnection = {
  readonly __typename?: 'SharedFileConnection',
  readonly edges: ReadonlyArray<sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge>,
  readonly pageInfo: sharedFilesQuery_sharedFiles_SharedFileConnection_pageInfo_PageInfo
};

export type sharedFilesQuery_Query = {
  readonly __typename?: 'Query',
  readonly sharedFiles: sharedFilesQuery_sharedFiles_SharedFileConnection | null
};


export type sharedFilesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  sortOrder?: InputMaybe<SortOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type sharedFilesQuery = sharedFilesQuery_Query;

export type totalCompensationConfigurationDetails = {
  readonly __typename?: 'TotalCompensationConfiguration',
  readonly companyId: string,
  readonly salary: boolean,
  readonly variableCompensation: boolean,
  readonly pension: boolean,
  readonly salaryExchange: boolean,
  readonly benefits: boolean,
  readonly wellness: boolean,
  readonly vacation: boolean,
  readonly salaryPayrollElementCode: string,
  readonly variableCompensationPayrollElementCode: string
};

export type updateTotalCompensationConfigurationMutation_updateTotalCompensationConfiguration_TotalCompensationConfiguration = (
  {
  readonly __typename?: 'TotalCompensationConfiguration'
}
  & totalCompensationConfigurationDetails
);

export type updateTotalCompensationConfigurationMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateTotalCompensationConfiguration: updateTotalCompensationConfigurationMutation_updateTotalCompensationConfiguration_TotalCompensationConfiguration
};


export type updateTotalCompensationConfigurationMutationVariables = Exact<{
  input: TotalCompensationConfigurationInput;
}>;


export type updateTotalCompensationConfigurationMutation = updateTotalCompensationConfigurationMutation_Mutation;

export type totalCompensationConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly showTotalCompensation: boolean
};

export type totalCompensationConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: totalCompensationConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type totalCompensationConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<totalCompensationConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType = {
  readonly __typename?: 'RemunerationType',
  readonly id: string,
  readonly name: string | null
};

export type totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge = {
  readonly __typename?: 'RemunerationTypeEdge',
  readonly node: totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType
};

export type totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection = {
  readonly __typename?: 'RemunerationTypeConnection',
  readonly pageInfo: totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge>
};

export type totalCompensationConfigurationQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration = (
  {
  readonly __typename?: 'TotalCompensationConfiguration'
}
  & totalCompensationConfigurationDetails
);

export type totalCompensationConfigurationQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly benefitPackages: totalCompensationConfigurationQuery_company_Company_benefitPackages_BenefitPackageConnection | null,
  readonly remunerationTypes: totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection | null,
  readonly totalCompensationConfiguration: totalCompensationConfigurationQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration | null
};

export type totalCompensationConfigurationQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: totalCompensationConfigurationQuery_company_Company | null
};


export type totalCompensationConfigurationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type totalCompensationConfigurationQuery = totalCompensationConfigurationQuery_Query;

export type createCompany2Mutation_createCompany2_CreateCompany2Payload_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type createCompany2Mutation_createCompany2_CreateCompany2Payload = {
  readonly __typename?: 'CreateCompany2Payload',
  readonly company: createCompany2Mutation_createCompany2_CreateCompany2Payload_company_Company
};

export type createCompany2Mutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createCompany2: createCompany2Mutation_createCompany2_CreateCompany2Payload | null
};


export type createCompany2MutationVariables = Exact<{
  input: CreateCompany2Input;
}>;


export type createCompany2Mutation = createCompany2Mutation_Mutation;

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementActiway = {
  readonly __typename?: 'AgreementActiway',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementAdminPoa = {
  readonly __typename?: 'AgreementAdminPoa',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementCustom = {
  readonly __typename?: 'AgreementCustom',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementEuroAccident = {
  readonly __typename?: 'AgreementEuroAccident',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementNordea = {
  readonly __typename?: 'AgreementNordea',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementNordnet = {
  readonly __typename?: 'AgreementNordnet',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestAdminPoa = {
  readonly __typename?: 'AgreementRequestAdminPoa',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestEuroAccident = {
  readonly __typename?: 'AgreementRequestEuroAccident',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestNordea = {
  readonly __typename?: 'AgreementRequestNordea',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestNordnet = {
  readonly __typename?: 'AgreementRequestNordnet',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestSuperAdmin = {
  readonly __typename?: 'AgreementRequestSuperAdmin',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementSuperAdmin = {
  readonly __typename?: 'AgreementSuperAdmin',
  readonly id: string
};

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements = createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementActiway | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementAdminPoa | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementCustom | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementEuroAccident | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementNordea | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementNordnet | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestAdminPoa | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestEuroAccident | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestNordea | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestNordnet | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementRequestSuperAdmin | createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements_AgreementSuperAdmin;

export type createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload = {
  readonly __typename?: 'CreatePreSignedAgreementsPayload',
  readonly agreements: ReadonlyArray<createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload_agreements> | null
};

export type createPreSignedAgreementsMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createPreSignedAgreements: createPreSignedAgreementsMutation_createPreSignedAgreements_CreatePreSignedAgreementsPayload | null
};


export type createPreSignedAgreementsMutationVariables = Exact<{
  input: CreatePreSignedAgreementsInput;
}>;


export type createPreSignedAgreementsMutation = createPreSignedAgreementsMutation_Mutation;

export type createCompanyQuery_nlpSalesReps_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type createCompanyQuery_Query = {
  readonly __typename?: 'Query',
  readonly nlpSalesReps: ReadonlyArray<createCompanyQuery_nlpSalesReps_AdminUser>
};


export type createCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type createCompanyQuery = createCompanyQuery_Query;

export type updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_clearingCode_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly code: string,
  readonly displayName: string
};

export type updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_nlpSalesRep_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_address_Address = {
  readonly __typename?: 'Address',
  readonly line1: string | null,
  readonly line2: string | null,
  readonly co: string | null,
  readonly postalCode: string,
  readonly city: string
};

export type updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly registrationNumber: string,
  readonly name: string,
  readonly displayName: string,
  readonly logoUrl: string | null,
  readonly email: string | null,
  readonly phone: string | null,
  readonly hasJobOffers: boolean,
  readonly newsletterOptOut: boolean | null,
  readonly accountClosureMonth: number | null,
  readonly hasCompanyGroup: boolean | null,
  readonly salaryReviewMonth: number | null,
  readonly emailLoginAllowed: boolean | null,
  readonly clearingCode: updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_clearingCode_ClearingCode | null,
  readonly nlpSalesRep: updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_nlpSalesRep_AdminUser | null,
  readonly address: updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_address_Address
};

export type updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload = {
  readonly __typename?: 'UpdateCompanyPayload',
  readonly company: updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company
};

export type updateCompanyDetailsMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateCompany: updateCompanyDetailsMutation_updateCompany_UpdateCompanyPayload | null
};


export type updateCompanyDetailsMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type updateCompanyDetailsMutation = updateCompanyDetailsMutation_Mutation;

export type refreshExternalCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_address_Address = {
  readonly __typename?: 'Address',
  readonly line1: string | null,
  readonly line2: string | null,
  readonly co: string | null,
  readonly postalCode: string,
  readonly city: string
};

export type refreshExternalCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly registrationNumber: string,
  readonly legalEntityType: LegalEntityType,
  readonly foundedAt: string | null,
  readonly name: string,
  readonly address: refreshExternalCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_address_Address
};

export type refreshExternalCompanyDetailsMutation_updateCompany_UpdateCompanyPayload = {
  readonly __typename?: 'UpdateCompanyPayload',
  readonly company: refreshExternalCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company
};

export type refreshExternalCompanyDetailsMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateCompany: refreshExternalCompanyDetailsMutation_updateCompany_UpdateCompanyPayload | null
};


export type refreshExternalCompanyDetailsMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type refreshExternalCompanyDetailsMutation = refreshExternalCompanyDetailsMutation_Mutation;

export type updateBenefitPackageNameMutation_updateBenefitPackage_UpdateBenefitPackagePayload_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type updateBenefitPackageNameMutation_updateBenefitPackage_UpdateBenefitPackagePayload = {
  readonly __typename?: 'UpdateBenefitPackagePayload',
  readonly benefitPackage: updateBenefitPackageNameMutation_updateBenefitPackage_UpdateBenefitPackagePayload_benefitPackage_BenefitPackage | null
};

export type updateBenefitPackageNameMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateBenefitPackage: updateBenefitPackageNameMutation_updateBenefitPackage_UpdateBenefitPackagePayload | null
};


export type updateBenefitPackageNameMutationVariables = Exact<{
  input: UpdateBenefitPackageInput;
}>;


export type updateBenefitPackageNameMutation = updateBenefitPackageNameMutation_Mutation;

export type companiesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_companyAnalytics_CompanyAnalytics = {
  readonly __typename?: 'CompanyAnalytics',
  readonly numberOfActiveEmployments: number | null
};

export type companiesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly displayName: string,
  readonly registrationNumber: string,
  readonly notifications: number | null,
  readonly companyAnalytics: companiesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_companyAnalytics_CompanyAnalytics | null
};

export type companiesQuery_companies_CompanyConnection_edges_CompanyEdge = {
  readonly __typename?: 'CompanyEdge',
  readonly node: companiesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company
};

export type companiesQuery_companies_CompanyConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companiesQuery_companies_CompanyConnection = {
  readonly __typename?: 'CompanyConnection',
  readonly edges: ReadonlyArray<companiesQuery_companies_CompanyConnection_edges_CompanyEdge>,
  readonly pageInfo: companiesQuery_companies_CompanyConnection_pageInfo_PageInfo
};

export type companiesQuery_Query = {
  readonly __typename?: 'Query',
  readonly companies: companiesQuery_companies_CompanyConnection | null
};


export type companiesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CompanyFilters>;
}>;


export type companiesQuery = companiesQuery_Query;

export type companyDetailsQuery_nlpSalesReps_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type companyDetailsQuery_company_Company_nlpSalesRep_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type companyDetailsQuery_company_Company_clearingCode_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly code: string,
  readonly displayName: string
};

export type companyDetailsQuery_company_Company_address_Address = {
  readonly __typename?: 'Address',
  readonly line1: string | null,
  readonly line2: string | null,
  readonly co: string | null,
  readonly postalCode: string,
  readonly city: string
};

export type companyDetailsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly hasCompanyGroup: boolean | null,
  readonly legalEntityType: LegalEntityType,
  readonly salaryReviewMonth: number | null,
  readonly accountClosureMonth: number | null,
  readonly registrationNumber: string,
  readonly name: string,
  readonly displayName: string,
  readonly logoUrl: string | null,
  readonly email: string | null,
  readonly phone: string | null,
  readonly hasJobOffers: boolean,
  readonly newsletterOptOut: boolean | null,
  readonly validKyc: boolean | null,
  readonly emailLoginAllowed: boolean | null,
  readonly employeeEmailLanguage: Language | null,
  readonly nlpSalesRep: companyDetailsQuery_company_Company_nlpSalesRep_AdminUser | null,
  readonly clearingCode: companyDetailsQuery_company_Company_clearingCode_ClearingCode | null,
  readonly address: companyDetailsQuery_company_Company_address_Address
};

export type companyDetailsQuery_Query = {
  readonly __typename?: 'Query',
  readonly nlpSalesReps: ReadonlyArray<companyDetailsQuery_nlpSalesReps_AdminUser>,
  readonly company: companyDetailsQuery_company_Company | null
};


export type companyDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type companyDetailsQuery = companyDetailsQuery_Query;

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_memberships_MembershipConnection_edges_MembershipEdge>
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string,
  readonly title: string
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly hasBenefitPortal: boolean,
  readonly showTotalCompensation: boolean,
  readonly memberships: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_memberships_MembershipConnection | null,
  readonly benefits: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage_benefits_FlexBenefitConnection
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge = {
  readonly __typename?: 'BenefitPackageEdge',
  readonly node: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection = {
  readonly __typename?: 'BenefitPackageConnection',
  readonly edges: ReadonlyArray<allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge>
};

export type allBenefitPackagesQuery_companyBenefitPackages_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly benefitPackages: allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection | null
};

export type allBenefitPackagesQuery_Query = {
  readonly __typename?: 'Query',
  readonly companyBenefitPackages: allBenefitPackagesQuery_companyBenefitPackages_Company | null
};


export type allBenefitPackagesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type allBenefitPackagesQuery = allBenefitPackagesQuery_Query;

export type benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory = {
  readonly __typename?: 'FlexBenefitCategory',
  readonly id: string,
  readonly name: string
};

export type benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly locale: string
};

export type benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly categories: ReadonlyArray<benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory>,
  readonly content: benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent
};

export type benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent = {
  readonly __typename?: 'FlexBenefitContent',
  readonly title: string,
  readonly locale: string
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_supplier_FlexBenefitSupplier = {
  readonly __typename?: 'FlexBenefitSupplier',
  readonly id: string,
  readonly name: string
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_entitlementRules_FlexEntitlementRules = {
  readonly __typename?: 'FlexEntitlementRules',
  readonly benefitPackageIds: ReadonlyArray<string>
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory = {
  readonly __typename?: 'FlexBenefitCategory',
  readonly id: string,
  readonly name: string
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration = {
  readonly __typename?: 'EpassiBikeConfiguration',
  readonly data: any | null
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration = {
  readonly __typename?: 'MiscConfiguration',
  readonly data: any | null
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration = benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_EpassiBikeConfiguration | benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration_MiscConfiguration;

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit = {
  readonly __typename?: 'FlexBenefit',
  readonly id: string,
  readonly type: string,
  readonly content: benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_content_FlexBenefitContent,
  readonly supplier: benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_supplier_FlexBenefitSupplier,
  readonly entitlementRules: benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_entitlementRules_FlexEntitlementRules | null,
  readonly categories: ReadonlyArray<benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory>,
  readonly configuration: benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_configuration | null
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge = {
  readonly __typename?: 'FlexBenefitEdge',
  readonly node: benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit
};

export type benefitsQuery_company_Company_benefits_FlexBenefitConnection = {
  readonly __typename?: 'FlexBenefitConnection',
  readonly edges: ReadonlyArray<benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge>
};

export type benefitsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly managedBy: ManagingCompany,
  readonly benefitCategories: benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection | null,
  readonly benefits: benefitsQuery_company_Company_benefits_FlexBenefitConnection | null
};

export type benefitsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: benefitsQuery_company_Company | null
};


export type benefitsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  benefitPackageIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  benefitTypeNames?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  onlyGetBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type benefitsQuery = benefitsQuery_Query;

export type benefitPackageNameQuery_benefitPackage_BenefitPackage = {
  readonly __typename?: 'BenefitPackage',
  readonly id: string,
  readonly name: string
};

export type benefitPackageNameQuery_Query = {
  readonly __typename?: 'Query',
  readonly benefitPackage: benefitPackageNameQuery_benefitPackage_BenefitPackage | null
};


export type benefitPackageNameQueryVariables = Exact<{
  benefitPackageId: Scalars['ID']['input'];
}>;


export type benefitPackageNameQuery = benefitPackageNameQuery_Query;

export type salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_edges_SalaryReportEdge_node_SalaryReport = {
  readonly __typename?: 'SalaryReport',
  readonly id: string,
  readonly companyId: string,
  readonly year: number,
  readonly month: number,
  readonly name: string | null,
  readonly excelUrl: string | null
};

export type salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_edges_SalaryReportEdge = {
  readonly __typename?: 'SalaryReportEdge',
  readonly cursor: string,
  readonly node: salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_edges_SalaryReportEdge_node_SalaryReport
};

export type salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection = {
  readonly __typename?: 'SalaryReportConnection',
  readonly pageInfo: salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_edges_SalaryReportEdge>
};

export type salaryReportsQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly salaryReports: salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection
};

export type salaryReportsQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: salaryReportsQuery_company_Company | null
};


export type salaryReportsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type salaryReportsQuery = salaryReportsQuery_Query;

export type companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType = {
  readonly __typename?: 'RemunerationType',
  readonly id: string,
  readonly name: string | null,
  readonly currency: string | null,
  readonly unitCode: string | null
};

export type companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge = {
  readonly __typename?: 'RemunerationTypeEdge',
  readonly node: companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType
};

export type companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection = {
  readonly __typename?: 'RemunerationTypeConnection',
  readonly pageInfo: companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge>
};

export type companyRemunerationTypesQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly remunerationTypes: companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection | null
};

export type companyRemunerationTypesQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: companyRemunerationTypesQuery_company_Company | null
};


export type companyRemunerationTypesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type companyRemunerationTypesQuery = companyRemunerationTypesQuery_Query;

export type accountingObjectsQuery_accountingObjects_AccountingObjectConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type accountingObjectsQuery_accountingObjects_AccountingObjectConnection_edges_AccountingObjectEdge_node_AccountingObject_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly totalCount: number
};

export type accountingObjectsQuery_accountingObjects_AccountingObjectConnection_edges_AccountingObjectEdge_node_AccountingObject = {
  readonly __typename?: 'AccountingObject',
  readonly id: string,
  readonly name: string | null,
  readonly identifier: string,
  readonly memberships: accountingObjectsQuery_accountingObjects_AccountingObjectConnection_edges_AccountingObjectEdge_node_AccountingObject_memberships_MembershipConnection | null
};

export type accountingObjectsQuery_accountingObjects_AccountingObjectConnection_edges_AccountingObjectEdge = {
  readonly __typename?: 'AccountingObjectEdge',
  readonly node: accountingObjectsQuery_accountingObjects_AccountingObjectConnection_edges_AccountingObjectEdge_node_AccountingObject
};

export type accountingObjectsQuery_accountingObjects_AccountingObjectConnection = {
  readonly __typename?: 'AccountingObjectConnection',
  readonly totalCount: number,
  readonly pageInfo: accountingObjectsQuery_accountingObjects_AccountingObjectConnection_pageInfo_PageInfo,
  readonly edges: ReadonlyArray<accountingObjectsQuery_accountingObjects_AccountingObjectConnection_edges_AccountingObjectEdge>
};

export type accountingObjectsQuery_Query = {
  readonly __typename?: 'Query',
  readonly accountingObjects: accountingObjectsQuery_accountingObjects_AccountingObjectConnection | null
};


export type accountingObjectsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  dimensionId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type accountingObjectsQuery = accountingObjectsQuery_Query;

export type membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly name: string,
  readonly displayName: string
};

export type membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership = {
  readonly __typename?: 'Membership',
  readonly id: string,
  readonly userAccountId: string,
  readonly givenName: string,
  readonly lastName: string,
  readonly naturalPersonIdentifiers: ReadonlyArray<string>,
  readonly company: membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership_company_Company
};

export type membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge = {
  readonly __typename?: 'MembershipEdge',
  readonly node: membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership
};

export type membershipsQuery_memberships_MembershipConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type membershipsQuery_memberships_MembershipConnection = {
  readonly __typename?: 'MembershipConnection',
  readonly edges: ReadonlyArray<membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge>,
  readonly pageInfo: membershipsQuery_memberships_MembershipConnection_pageInfo_PageInfo
};

export type membershipsQuery_Query = {
  readonly __typename?: 'Query',
  readonly memberships: membershipsQuery_memberships_MembershipConnection | null
};


export type membershipsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  includeTerminated?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type membershipsQuery = membershipsQuery_Query;

export type setInsuranceDeleted_setInsuranceDeleted_UpdateInsurancePayload_insurance_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly deletedAt: string | null
};

export type setInsuranceDeleted_setInsuranceDeleted_UpdateInsurancePayload = {
  readonly __typename?: 'UpdateInsurancePayload',
  readonly insurance: setInsuranceDeleted_setInsuranceDeleted_UpdateInsurancePayload_insurance_Insurance
};

export type setInsuranceDeleted_Mutation = {
  readonly __typename?: 'Mutation',
  readonly setInsuranceDeleted: setInsuranceDeleted_setInsuranceDeleted_UpdateInsurancePayload
};


export type setInsuranceDeletedVariables = Exact<{
  instituteId: Scalars['String']['input'];
  insuranceNumber: Scalars['String']['input'];
}>;


export type setInsuranceDeleted = setInsuranceDeleted_Mutation;

export type insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute = {
  readonly __typename?: 'Institute',
  readonly id: string,
  readonly name: string
};

export type insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_totalValue_CurrencyValue = {
  readonly __typename?: 'CurrencyValue',
  readonly value: string,
  readonly currency: string
};

export type insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance = {
  readonly __typename?: 'Insurance',
  readonly id: string,
  readonly insuranceNumber: string,
  readonly insuredParty: string | null,
  readonly policyholderName: string | null,
  readonly deletedAt: string | null,
  readonly institute: insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_institute_Institute,
  readonly totalValue: insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_totalValue_CurrencyValue | null
};

export type insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge = {
  readonly __typename?: 'InsuranceEdge',
  readonly cursor: string | null,
  readonly node: insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance
};

export type insurancesQuery_insurances_InsuranceConnection_pageInfo_PageInfo = {
  readonly __typename?: 'PageInfo',
  readonly hasNextPage: boolean,
  readonly hasPreviousPage: boolean,
  readonly startCursor: string | null,
  readonly endCursor: string | null
};

export type insurancesQuery_insurances_InsuranceConnection = {
  readonly __typename?: 'InsuranceConnection',
  readonly edges: ReadonlyArray<insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge>,
  readonly pageInfo: insurancesQuery_insurances_InsuranceConnection_pageInfo_PageInfo
};

export type insurancesQuery_Query = {
  readonly __typename?: 'Query',
  readonly insurances: insurancesQuery_insurances_InsuranceConnection
};


export type insurancesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type insurancesQuery = insurancesQuery_Query;

export type updateSmeCompanyMutation_updateCompany_UpdateCompanyPayload_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type updateSmeCompanyMutation_updateCompany_UpdateCompanyPayload = {
  readonly __typename?: 'UpdateCompanyPayload',
  readonly clientMutationId: string | null,
  readonly company: updateSmeCompanyMutation_updateCompany_UpdateCompanyPayload_company_Company
};

export type updateSmeCompanyMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateCompany: updateSmeCompanyMutation_updateCompany_UpdateCompanyPayload | null
};


export type updateSmeCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type updateSmeCompanyMutation = updateSmeCompanyMutation_Mutation;

export type smeEditCompanyQuery_company_Company_clearingCode_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly code: string,
  readonly displayName: string
};

export type smeEditCompanyQuery_company_Company_nlpSalesRep_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeEditCompanyQuery_company_Company_address_Address = {
  readonly __typename?: 'Address',
  readonly postalCode: string,
  readonly line1: string | null,
  readonly city: string
};

export type smeEditCompanyQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly hasCompanyGroup: boolean | null,
  readonly accountNumber: string | null,
  readonly displayName: string,
  readonly registrationNumber: string,
  readonly legalEntityType: LegalEntityType,
  readonly salaryReviewMonth: number | null,
  readonly accountClosureMonth: number | null,
  readonly paymentMethod: PaymentMethod | null,
  readonly clearingCode: smeEditCompanyQuery_company_Company_clearingCode_ClearingCode | null,
  readonly nlpSalesRep: smeEditCompanyQuery_company_Company_nlpSalesRep_AdminUser | null,
  readonly address: smeEditCompanyQuery_company_Company_address_Address
};

export type smeEditCompanyQuery_nlpSalesReps_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type smeEditCompanyQuery_proposalContactPerson_ProposalCompanyContactPerson = {
  readonly __typename?: 'ProposalCompanyContactPerson',
  readonly firstName: string,
  readonly lastName: string,
  readonly personalIdentityNumber: string | null,
  readonly email: string,
  readonly phoneNumber: string
};

export type smeEditCompanyQuery_proposalHeadAdmin_ProposalCompanyHeadAdmin = {
  readonly __typename?: 'ProposalCompanyHeadAdmin',
  readonly firstName: string,
  readonly lastName: string,
  readonly personalIdentityNumber: string,
  readonly email: string,
  readonly phoneNumber: string
};

export type smeEditCompanyQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: smeEditCompanyQuery_company_Company | null,
  readonly nlpSalesReps: ReadonlyArray<smeEditCompanyQuery_nlpSalesReps_AdminUser>,
  readonly proposalContactPerson: smeEditCompanyQuery_proposalContactPerson_ProposalCompanyContactPerson | null,
  readonly proposalHeadAdmin: smeEditCompanyQuery_proposalHeadAdmin_ProposalCompanyHeadAdmin | null
};


export type smeEditCompanyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeEditCompanyQuery = smeEditCompanyQuery_Query;

export type smeCompanyQuery_company_Company_clearingCode_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly displayName: string
};

export type smeCompanyQuery_company_Company_nlpSalesRep_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly externalId: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeCompanyQuery_company_Company_address_Address = {
  readonly __typename?: 'Address',
  readonly line1: string | null
};

export type smeCompanyQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly accountNumber: string | null,
  readonly displayName: string,
  readonly registrationNumber: string,
  readonly hasCompanyGroup: boolean | null,
  readonly legalEntityType: LegalEntityType,
  readonly accountClosureMonth: number | null,
  readonly paymentMethod: PaymentMethod | null,
  readonly salaryReviewMonth: number | null,
  readonly clearingCode: smeCompanyQuery_company_Company_clearingCode_ClearingCode | null,
  readonly nlpSalesRep: smeCompanyQuery_company_Company_nlpSalesRep_AdminUser | null,
  readonly address: smeCompanyQuery_company_Company_address_Address
};

export type smeCompanyQuery_proposalContactPerson_ProposalCompanyContactPerson = {
  readonly __typename?: 'ProposalCompanyContactPerson',
  readonly firstName: string,
  readonly lastName: string,
  readonly personalIdentityNumber: string | null,
  readonly email: string,
  readonly phoneNumber: string
};

export type smeCompanyQuery_proposalHeadAdmin_ProposalCompanyHeadAdmin = {
  readonly __typename?: 'ProposalCompanyHeadAdmin',
  readonly firstName: string,
  readonly lastName: string,
  readonly personalIdentityNumber: string,
  readonly email: string,
  readonly phoneNumber: string
};

export type smeCompanyQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: smeCompanyQuery_company_Company | null,
  readonly proposalContactPerson: smeCompanyQuery_proposalContactPerson_ProposalCompanyContactPerson | null,
  readonly proposalHeadAdmin: smeCompanyQuery_proposalHeadAdmin_ProposalCompanyHeadAdmin | null
};


export type smeCompanyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyQuery = smeCompanyQuery_Query;

export type createProposalMutation_createProposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string
};

export type createProposalMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposal: createProposalMutation_createProposal_Proposal
};


export type createProposalMutationVariables = Exact<{
  input: CreateProposalInput;
}>;


export type createProposalMutation = createProposalMutation_Mutation;

export type duplicateProposalMutation_duplicateProposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly name: string
};

export type duplicateProposalMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly duplicateProposal: duplicateProposalMutation_duplicateProposal_Proposal
};


export type duplicateProposalMutationVariables = Exact<{
  input: DuplicateProposalInput;
}>;


export type duplicateProposalMutation = duplicateProposalMutation_Mutation;

export type duplicateProposalQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly name: string,
  readonly companySize: ProposalCompanySize,
  readonly adviceWaived: ProposalAdviceOption
};

export type duplicateProposalQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: duplicateProposalQuery_proposal_Proposal | null
};


export type duplicateProposalQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type duplicateProposalQuery = duplicateProposalQuery_Query;

export type createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage>
};

export type createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalBenefitPackage: createProposalBenefitPackageMutation_createProposalBenefitPackage_Proposal
};


export type createProposalBenefitPackageMutationVariables = Exact<{
  input: CreateProposalBenefitPackageInput;
}>;


export type createProposalBenefitPackageMutation = createProposalBenefitPackageMutation_Mutation;

export type deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly benefitPackages: ReadonlyArray<deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage>
};

export type deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type deleteBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteProposalBenefitPackage: deleteBenefitPackageMutation_deleteProposalBenefitPackage_Proposal
};


export type deleteBenefitPackageMutationVariables = Exact<{
  input: DeleteProposalBenefitPackageInput;
}>;


export type deleteBenefitPackageMutation = deleteBenefitPackageMutation_Mutation;

export type editBenefitPackageMutation_updateProposalBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type editBenefitPackageMutation_updateProposalBenefitPackage_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editBenefitPackageMutation_updateProposalBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editBenefitPackageMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalBenefitPackage: editBenefitPackageMutation_updateProposalBenefitPackage_Proposal
};


export type editBenefitPackageMutationVariables = Exact<{
  input: UpdateProposalBenefitPackageInput;
}>;


export type editBenefitPackageMutation = editBenefitPackageMutation_Mutation;

export type editBenefitPackageModalQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type editBenefitPackageModalQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editBenefitPackageModalQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editBenefitPackageModalQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: editBenefitPackageModalQuery_proposal_Proposal | null
};


export type editBenefitPackageModalQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type editBenefitPackageModalQuery = editBenefitPackageModalQuery_Query;

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig',
  readonly accidentLevel: ProposalAccidentLevel
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig',
  readonly healthcareLevel: ProposalHealthcareLevel,
  readonly waitingPeriod: boolean
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig',
  readonly lifeLevel: ProposalLifeLevel
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig',
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly extraFixedPremium: boolean,
  readonly premiumWaiver: boolean
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig',
  readonly pensionToSalaryMax: number
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig',
  readonly sicknessLevel: ProposalSicknessLevel
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration = smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly configuration: smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: ReadonlyArray<smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type smeCompanyBenefitPackagesQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyBenefitPackagesQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyBenefitPackagesQuery_proposal_Proposal | null
};


export type smeCompanyBenefitPackagesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyBenefitPackagesQuery = smeCompanyBenefitPackagesQuery_Query;

export type removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly benefitPackages: ReadonlyArray<removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage>
};

export type removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type removeBenefitFromBenefitPackage_Mutation = {
  readonly __typename?: 'Mutation',
  readonly removeProposalBenefitFromBenefitPackage: removeBenefitFromBenefitPackage_removeProposalBenefitFromBenefitPackage_Proposal
};


export type removeBenefitFromBenefitPackageVariables = Exact<{
  input: RemoveProposalBenefitFromBenefitPackageInput;
}>;


export type removeBenefitFromBenefitPackage = removeBenefitFromBenefitPackage_Mutation;

export type createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalAccidentBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalAccidentBenefit: createProposalAccidentBenefitMutation_createProposalAccidentBenefit_Proposal
};


export type createProposalAccidentBenefitMutationVariables = Exact<{
  input: CreateProposalAccidentBenefitInput;
}>;


export type createProposalAccidentBenefitMutation = createProposalAccidentBenefitMutation_Mutation;

export type createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalDisabilityBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalSicknessBenefit: createProposalDisabilityBenefitMutation_createProposalSicknessBenefit_Proposal
};


export type createProposalDisabilityBenefitMutationVariables = Exact<{
  input: CreateProposalSicknessBenefitInput;
}>;


export type createProposalDisabilityBenefitMutation = createProposalDisabilityBenefitMutation_Mutation;

export type createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalEmployersPensionBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalEmployersPensionBenefit: createProposalEmployersPensionBenefitMutation_createProposalEmployersPensionBenefit_Proposal
};


export type createProposalEmployersPensionBenefitMutationVariables = Exact<{
  input: CreateProposalEmployersPensionBenefitInput;
}>;


export type createProposalEmployersPensionBenefitMutation = createProposalEmployersPensionBenefitMutation_Mutation;

export type smeCompanyCreateBenefitQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyCreateBenefitQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly companySize: ProposalCompanySize,
  readonly benefitPackages: ReadonlyArray<smeCompanyCreateBenefitQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyCreateBenefitQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyCreateBenefitQuery_proposal_Proposal | null
};


export type smeCompanyCreateBenefitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyCreateBenefitQuery = smeCompanyCreateBenefitQuery_Query;

export type createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalHealthcareBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalHealthcareBenefit: createProposalHealthcareBenefitMutation_createProposalHealthcareBenefit_Proposal
};


export type createProposalHealthcareBenefitMutationVariables = Exact<{
  input: CreateProposalHealthcareBenefitInput;
}>;


export type createProposalHealthcareBenefitMutation = createProposalHealthcareBenefitMutation_Mutation;

export type createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalLifeBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalLifeBenefit: createProposalLifeBenefitMutation_createProposalLifeBenefit_Proposal
};


export type createProposalLifeBenefitMutationVariables = Exact<{
  input: CreateProposalLifeBenefitInput;
}>;


export type createProposalLifeBenefitMutation = createProposalLifeBenefitMutation_Mutation;

export type createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalOccupationalPensionBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalOccupationalPensionBenefit: createProposalOccupationalPensionBenefitMutation_createProposalOccupationalPensionBenefit_Proposal
};


export type createProposalOccupationalPensionBenefitMutationVariables = Exact<{
  input: CreateProposalOccupationalPensionBenefitInput;
}>;


export type createProposalOccupationalPensionBenefitMutation = createProposalOccupationalPensionBenefitMutation_Mutation;

export type createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalSalaryExchangeBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalSalaryExchangeBenefit: createProposalSalaryExchangeBenefitMutation_createProposalSalaryExchangeBenefit_Proposal
};


export type createProposalSalaryExchangeBenefitMutationVariables = Exact<{
  input: CreateProposalSalaryExchangeBenefitInput;
}>;


export type createProposalSalaryExchangeBenefitMutation = createProposalSalaryExchangeBenefitMutation_Mutation;

export type createProposalTglBenefitMutation_createProposalTglBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalTglBenefitMutation_createProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type createProposalTglBenefitMutation_createProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalTglBenefitMutation_createProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type createProposalTglBenefitMutation_createProposalTglBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<createProposalTglBenefitMutation_createProposalTglBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<createProposalTglBenefitMutation_createProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type createProposalTglBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalTglBenefit: createProposalTglBenefitMutation_createProposalTglBenefit_Proposal
};


export type createProposalTglBenefitMutationVariables = Exact<{
  input: CreateProposalTglBenefitInput;
}>;


export type createProposalTglBenefitMutation = createProposalTglBenefitMutation_Mutation;

export type deleteBenefitMutation_deleteProposalBenefit_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type deleteBenefitMutation_deleteProposalBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type deleteBenefitMutation_deleteProposalBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<deleteBenefitMutation_deleteProposalBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type deleteBenefitMutation_deleteProposalBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<deleteBenefitMutation_deleteProposalBenefit_Proposal_benefits_ProposalBenefit>,
  readonly benefitPackages: ReadonlyArray<deleteBenefitMutation_deleteProposalBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type deleteBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteProposalBenefit: deleteBenefitMutation_deleteProposalBenefit_Proposal
};


export type deleteBenefitMutationVariables = Exact<{
  input: DeleteProposalBenefitInput;
}>;


export type deleteBenefitMutation = deleteBenefitMutation_Mutation;

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig',
  readonly accidentLevel: ProposalAccidentLevel
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalAccidentBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalAccidentBenefit: editProposalAccidentBenefitMutation_updateProposalAccidentBenefit_Proposal
};


export type editProposalAccidentBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalAccidentBenefitInput;
}>;


export type editProposalAccidentBenefitMutation = editProposalAccidentBenefitMutation_Mutation;

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig',
  readonly accidentLevel: ProposalAccidentLevel
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditAccidentQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditAccidentQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: smeCompanyEditAccidentQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditAccidentQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditAccidentQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditAccidentQuery_proposal_Proposal | null
};


export type smeCompanyEditAccidentQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditAccidentQuery = smeCompanyEditAccidentQuery_Query;

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig',
  readonly converting: boolean,
  readonly convertingFrom: string | null,
  readonly extendedSickPay: boolean,
  readonly extendSalaryLimit: boolean | null,
  readonly salaryMultiplier: number,
  readonly sicknessLevel: ProposalSicknessLevel
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalDisabilityBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalSicknessBenefit: editProposalDisabilityBenefitMutation_updateProposalSicknessBenefit_Proposal
};


export type editProposalDisabilityBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalSicknessBenefitInput;
}>;


export type editProposalDisabilityBenefitMutation = editProposalDisabilityBenefitMutation_Mutation;

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig',
  readonly converting: boolean,
  readonly convertingFrom: string | null,
  readonly extendSalaryLimit: boolean | null,
  readonly extendedSickPay: boolean,
  readonly salaryMultiplier: number,
  readonly sicknessLevel: ProposalSicknessLevel
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditDisabilityQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly companySize: ProposalCompanySize,
  readonly benefit: smeCompanyEditDisabilityQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditDisabilityQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditDisabilityQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditDisabilityQuery_proposal_Proposal | null
};


export type smeCompanyEditDisabilityQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditDisabilityQuery = smeCompanyEditDisabilityQuery_Query;

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix = {
  readonly __typename?: 'ProposalPensionPremiumMatrix',
  readonly age: ReadonlyArray<number>,
  readonly intervals: ReadonlyArray<ReadonlyArray<string>>,
  readonly baseAmount: ReadonlyArray<string>,
  readonly baseAmountType: ProposalBaseAmountType,
  readonly salaryMultiplier: number
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig',
  readonly convertingPremiumWaiverFrom: string | null,
  readonly defaultManagementType: ProposalDefaultManagementType,
  readonly extraFixedPremium: boolean,
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly premiumWaiver: boolean,
  readonly retirementAge: number,
  readonly premiumMatrix: editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix | null
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalEmployersPensionBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalEmployersPensionBenefit: editProposalEmployersPensionBenefitMutation_updateProposalEmployersPensionBenefit_Proposal
};


export type editProposalEmployersPensionBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalEmployersPensionBenefitInput;
}>;


export type editProposalEmployersPensionBenefitMutation = editProposalEmployersPensionBenefitMutation_Mutation;

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix = {
  readonly __typename?: 'ProposalPensionPremiumMatrix',
  readonly age: ReadonlyArray<number>,
  readonly intervals: ReadonlyArray<ReadonlyArray<string>>,
  readonly baseAmount: ReadonlyArray<string>,
  readonly baseAmountType: ProposalBaseAmountType,
  readonly salaryMultiplier: number
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig',
  readonly convertingPremiumWaiverFrom: string | null,
  readonly defaultManagementType: ProposalDefaultManagementType,
  readonly extraFixedPremium: boolean,
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly premiumWaiver: boolean,
  readonly retirementAge: number,
  readonly premiumMatrix: smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix | null
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditEmployersPensionQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditEmployersPensionQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditEmployersPensionQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditEmployersPensionQuery_proposal_Proposal | null
};


export type smeCompanyEditEmployersPensionQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditEmployersPensionQuery = smeCompanyEditEmployersPensionQuery_Query;

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig',
  readonly converting: boolean,
  readonly convertingFrom: string | null,
  readonly healthcareLevel: ProposalHealthcareLevel,
  readonly waitingPeriod: boolean
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalHealthcareBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalHealthcareBenefit: editProposalHealthcareBenefitMutation_updateProposalHealthcareBenefit_Proposal
};


export type editProposalHealthcareBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalHealthcareBenefitInput;
}>;


export type editProposalHealthcareBenefitMutation = editProposalHealthcareBenefitMutation_Mutation;

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig',
  readonly converting: boolean,
  readonly convertingFrom: string | null,
  readonly healthcareLevel: ProposalHealthcareLevel,
  readonly waitingPeriod: boolean
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditHealthcareQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: smeCompanyEditHealthcareQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditHealthcareQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditHealthcareQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditHealthcareQuery_proposal_Proposal | null
};


export type smeCompanyEditHealthcareQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditHealthcareQuery = smeCompanyEditHealthcareQuery_Query;

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig',
  readonly lifeLevel: ProposalLifeLevel
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalLifeBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalLifeBenefit: editProposalLifeBenefitMutation_updateProposalLifeBenefit_Proposal
};


export type editProposalLifeBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalLifeBenefitInput;
}>;


export type editProposalLifeBenefitMutation = editProposalLifeBenefitMutation_Mutation;

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig',
  readonly lifeLevel: ProposalLifeLevel,
  readonly agreementType: LifeAgreementType
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditLifeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditLifeQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly companySize: ProposalCompanySize,
  readonly benefit: smeCompanyEditLifeQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditLifeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditLifeQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditLifeQuery_proposal_Proposal | null
};


export type smeCompanyEditLifeQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditLifeQuery = smeCompanyEditLifeQuery_Query;

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix = {
  readonly __typename?: 'ProposalPensionPremiumMatrix',
  readonly age: ReadonlyArray<number>,
  readonly intervals: ReadonlyArray<ReadonlyArray<string>>,
  readonly baseAmount: ReadonlyArray<string>,
  readonly baseAmountType: ProposalBaseAmountType,
  readonly salaryMultiplier: number
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig',
  readonly convertingPremiumWaiverFrom: string | null,
  readonly defaultManagementType: ProposalDefaultManagementType,
  readonly extraFixedPremium: boolean,
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly premiumWaiver: boolean,
  readonly retirementAge: number,
  readonly premiumMatrix: editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix | null
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalOccupationalPensionBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalOccupationalPensionBenefit: editProposalOccupationalPensionBenefitMutation_updateProposalOccupationalPensionBenefit_Proposal
};


export type editProposalOccupationalPensionBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalOccupationalPensionBenefitInput;
}>;


export type editProposalOccupationalPensionBenefitMutation = editProposalOccupationalPensionBenefitMutation_Mutation;

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix = {
  readonly __typename?: 'ProposalPensionPremiumMatrix',
  readonly age: ReadonlyArray<number>,
  readonly intervals: ReadonlyArray<ReadonlyArray<string>>,
  readonly baseAmount: ReadonlyArray<string>,
  readonly baseAmountType: ProposalBaseAmountType,
  readonly salaryMultiplier: number
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig',
  readonly convertingPremiumWaiverFrom: string | null,
  readonly defaultManagementType: ProposalDefaultManagementType,
  readonly extraFixedPremium: boolean,
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly premiumWaiver: boolean,
  readonly retirementAge: number,
  readonly premiumMatrix: smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix | null
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditOccupationalPensionQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditOccupationalPensionQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditOccupationalPensionQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditOccupationalPensionQuery_proposal_Proposal | null
};


export type smeCompanyEditOccupationalPensionQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditOccupationalPensionQuery = smeCompanyEditOccupationalPensionQuery_Query;

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig',
  readonly automaticApproval: boolean,
  readonly incomeMinIBB: number,
  readonly pensionToSalaryMax: number,
  readonly ongoingMax: string,
  readonly pensionTaxBenefitShare: number,
  readonly premiumWaiver: boolean,
  readonly retirementAge: number,
  readonly salaryMultiplier: number
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration = editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit_configuration
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalSalaryExchangeBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalSalaryExchangeBenefit: editProposalSalaryExchangeBenefitMutation_updateProposalSalaryExchangeBenefit_Proposal
};


export type editProposalSalaryExchangeBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalSalaryExchangeBenefitInput;
}>;


export type editProposalSalaryExchangeBenefitMutation = editProposalSalaryExchangeBenefitMutation_Mutation;

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig',
  readonly automaticApproval: boolean,
  readonly incomeMinIBB: number,
  readonly pensionToSalaryMax: number,
  readonly ongoingMax: string,
  readonly pensionTaxBenefitShare: number,
  readonly premiumWaiver: boolean,
  readonly retirementAge: number,
  readonly salaryMultiplier: number
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditSalaryExchangeQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditSalaryExchangeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditSalaryExchangeQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditSalaryExchangeQuery_proposal_Proposal | null
};


export type smeCompanyEditSalaryExchangeQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditSalaryExchangeQuery = smeCompanyEditSalaryExchangeQuery_Query;

export type editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>
};

export type editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string
};

export type editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type editProposalTglBenefitMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalTglBenefit: editProposalTglBenefitMutation_updateProposalTglBenefit_Proposal
};


export type editProposalTglBenefitMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  input: UpdateProposalTglBenefitInput;
}>;


export type editProposalTglBenefitMutation = editProposalTglBenefitMutation_Mutation;

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename: 'ProposalOccupationalPensionBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration = smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit_configuration
};

export type smeCompanyEditTglQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEditTglQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefit: smeCompanyEditTglQuery_proposal_Proposal_benefit_ProposalBenefit,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditTglQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditTglQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditTglQuery_proposal_Proposal | null
};


export type smeCompanyEditTglQueryVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditTglQuery = smeCompanyEditTglQuery_Query;

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig',
  readonly accidentLevel: ProposalAccidentLevel
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig',
  readonly healthcareLevel: ProposalHealthcareLevel,
  readonly waitingPeriod: boolean
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig',
  readonly lifeLevel: ProposalLifeLevel
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig',
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly extraFixedPremium: boolean,
  readonly premiumWaiver: boolean
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig',
  readonly pensionToSalaryMax: number
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig',
  readonly sicknessLevel: ProposalSicknessLevel
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration = smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly benefitPackages: ReadonlyArray<smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_benefitPackages_ProposalBenefitPackage>,
  readonly configuration: smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit_configuration
};

export type smeCompanyBenefitsQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefits: ReadonlyArray<smeCompanyBenefitsQuery_proposal_Proposal_benefits_ProposalBenefit>
};

export type smeCompanyBenefitsQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyBenefitsQuery_proposal_Proposal | null
};


export type smeCompanyBenefitsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyBenefitsQuery = smeCompanyBenefitsQuery_Query;

export type smeCompanyBenefitCostsQuery_proposal_Proposal_cost_ProposalCost = {
  readonly __typename?: 'ProposalCost',
  readonly total: string | null,
  readonly riskTotal: string | null,
  readonly riskSickness: string | null,
  readonly riskAccident: string | null,
  readonly riskLife: string | null,
  readonly riskHealthcare: string | null,
  readonly riskTgl: string | null,
  readonly riskPensionPremiumWaiver: string | null,
  readonly salaryExchange: string | null,
  readonly occupationalPension: string | null,
  readonly employersPension: string | null
};

export type smeCompanyBenefitCostsQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly cost: smeCompanyBenefitCostsQuery_proposal_Proposal_cost_ProposalCost | null
};

export type smeCompanyBenefitCostsQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyBenefitCostsQuery_proposal_Proposal | null
};


export type smeCompanyBenefitCostsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyBenefitCostsQuery = smeCompanyBenefitCostsQuery_Query;

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix = {
  readonly __typename?: 'ProposalPensionPremiumMatrix',
  readonly age: ReadonlyArray<number>,
  readonly baseAmount: ReadonlyArray<string>,
  readonly baseAmountType: ProposalBaseAmountType,
  readonly intervals: ReadonlyArray<ReadonlyArray<string>>,
  readonly salaryMultiplier: number
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix = {
  readonly __typename?: 'ProposalPensionPremiumMatrix',
  readonly age: ReadonlyArray<number>,
  readonly baseAmount: ReadonlyArray<string>,
  readonly baseAmountType: ProposalBaseAmountType,
  readonly intervals: ReadonlyArray<ReadonlyArray<string>>,
  readonly salaryMultiplier: number
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig',
  readonly accidentLevel: ProposalAccidentLevel
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig',
  readonly defaultManagementType: ProposalDefaultManagementType,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly premiumWaiver: boolean,
  readonly premiumMatrix: smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix | null
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig',
  readonly healthcareLevel: ProposalHealthcareLevel,
  readonly waitingPeriod: boolean
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig',
  readonly lifeLevel: ProposalLifeLevel
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig',
  readonly defaultManagementType: ProposalDefaultManagementType,
  readonly premiumDeductions: ReadonlyArray<ProposalPremiumDeductions>,
  readonly premiumWaiver: boolean,
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly premiumMatrix: smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig_premiumMatrix_ProposalPensionPremiumMatrix | null
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig',
  readonly premiumWaiver: boolean
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig',
  readonly sicknessLevel: ProposalSicknessLevel
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration = smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly configuration: smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_cost_BenefitPackageCost = {
  readonly __typename?: 'BenefitPackageCost',
  readonly employersPension: string | null,
  readonly occupationalPension: string | null,
  readonly riskSickness: string | null,
  readonly riskAccident: string | null,
  readonly riskLife: string | null,
  readonly riskHealthcare: string | null,
  readonly riskTgl: string | null,
  readonly riskPensionPremiumWaiver: string | null,
  readonly total: string
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership_cost_Cost = {
  readonly __typename?: 'Cost',
  readonly employersPension: string | null,
  readonly occupationalPension: string | null,
  readonly riskSickness: string | null,
  readonly riskAccident: string | null,
  readonly riskLife: string | null,
  readonly riskHealthcare: string | null,
  readonly riskTgl: string | null,
  readonly riskPensionPremiumWaiver: string | null,
  readonly total: string | null
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly monthlySalary: string,
  readonly personalIdentityNumber: string,
  readonly cost: smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership_cost_Cost | null
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: ReadonlyArray<smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>,
  readonly cost: smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_cost_BenefitPackageCost | null,
  readonly memberships: ReadonlyArray<smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership>
};

export type smeCompanyBenefitPackageDetailsQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyBenefitPackageDetailsQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyBenefitPackageDetailsQuery_proposal_Proposal | null
};


export type smeCompanyBenefitPackageDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyBenefitPackageDetailsQuery = smeCompanyBenefitPackageDetailsQuery_Query;

export type downloadPriceProposalLayoutQuery_proposal_Proposal_validity_ProposalValidity_isPriceProposalDownloadAllowed_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type downloadPriceProposalLayoutQuery_proposal_Proposal_validity_ProposalValidity = {
  readonly __typename?: 'ProposalValidity',
  readonly isPriceProposalDownloadAllowed: downloadPriceProposalLayoutQuery_proposal_Proposal_validity_ProposalValidity_isPriceProposalDownloadAllowed_ValidityObject
};

export type downloadPriceProposalLayoutQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly validity: downloadPriceProposalLayoutQuery_proposal_Proposal_validity_ProposalValidity
};

export type downloadPriceProposalLayoutQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: downloadPriceProposalLayoutQuery_proposal_Proposal | null
};


export type downloadPriceProposalLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type downloadPriceProposalLayoutQuery = downloadPriceProposalLayoutQuery_Query;

export type downloadPriceProposalFileQuery_getPriceProposal_File = {
  readonly __typename?: 'File',
  readonly url: string,
  readonly name: string
};

export type downloadPriceProposalFileQuery_Query = {
  readonly __typename?: 'Query',
  readonly getPriceProposal: downloadPriceProposalFileQuery_getPriceProposal_File | null
};


export type downloadPriceProposalFileQueryVariables = Exact<{
  input: GeneratePriceProposalInput;
}>;


export type downloadPriceProposalFileQuery = downloadPriceProposalFileQuery_Query;

export type validCompilationDetails = {
  readonly __typename?: 'ValidityObject',
  readonly errors: ReadonlyArray<string>,
  readonly warnings: ReadonlyArray<string>,
  readonly info: ReadonlyArray<string>
};

export type compilationNotificationsQuery_proposal_Proposal_validity_ProposalValidity_validCompilation_ValidityObject = (
  {
  readonly __typename?: 'ValidityObject'
}
  & validCompilationDetails
);

export type compilationNotificationsQuery_proposal_Proposal_validity_ProposalValidity = {
  readonly __typename?: 'ProposalValidity',
  readonly validCompilation: compilationNotificationsQuery_proposal_Proposal_validity_ProposalValidity_validCompilation_ValidityObject
};

export type compilationNotificationsQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly validity: compilationNotificationsQuery_proposal_Proposal_validity_ProposalValidity
};

export type compilationNotificationsQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: compilationNotificationsQuery_proposal_Proposal | null
};


export type compilationNotificationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type compilationNotificationsQuery = compilationNotificationsQuery_Query;

export type smeCompanyPensionFeesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType
};

export type smeCompanyPensionFeesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly benefits: ReadonlyArray<smeCompanyPensionFeesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type smeCompanyPensionFeesQuery_proposal_Proposal_fees_ProposalFees = {
  readonly __typename?: 'ProposalFees',
  readonly annualFixedFeeTraditional: string,
  readonly annualFixedFeeFund: string,
  readonly annualFixedFeePortfolio: string,
  readonly capitalFeeFund: number | null,
  readonly capitalFeePortfolio: number | null,
  readonly capitalFeeTraditional: number | null,
  readonly fundRange: ReadonlyArray<number>,
  readonly portfolioRange: ReadonlyArray<number>
};

export type smeCompanyPensionFeesQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyPensionFeesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>,
  readonly fees: smeCompanyPensionFeesQuery_proposal_Proposal_fees_ProposalFees
};

export type smeCompanyPensionFeesQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyPensionFeesQuery_proposal_Proposal | null
};


export type smeCompanyPensionFeesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyPensionFeesQuery = smeCompanyPensionFeesQuery_Query;

export type createProposalEmployeeMutation_createProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string
};

export type createProposalEmployeeMutation_createProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly memberships: ReadonlyArray<createProposalEmployeeMutation_createProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership>
};

export type createProposalEmployeeMutation_createProposalMembership_Proposal_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string
};

export type createProposalEmployeeMutation_createProposalMembership_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<createProposalEmployeeMutation_createProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage>,
  readonly memberships: ReadonlyArray<createProposalEmployeeMutation_createProposalMembership_Proposal_memberships_ProposalMembership>
};

export type createProposalEmployeeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createProposalMembership: createProposalEmployeeMutation_createProposalMembership_Proposal
};


export type createProposalEmployeeMutationVariables = Exact<{
  input: CreateProposalMembershipInput;
}>;


export type createProposalEmployeeMutation = createProposalEmployeeMutation_Mutation;

export type deleteProposalEmployeeMutation_deleteProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string
};

export type deleteProposalEmployeeMutation_deleteProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly memberships: ReadonlyArray<deleteProposalEmployeeMutation_deleteProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership>
};

export type deleteProposalEmployeeMutation_deleteProposalMembership_Proposal_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string
};

export type deleteProposalEmployeeMutation_deleteProposalMembership_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<deleteProposalEmployeeMutation_deleteProposalMembership_Proposal_benefitPackages_ProposalBenefitPackage>,
  readonly memberships: ReadonlyArray<deleteProposalEmployeeMutation_deleteProposalMembership_Proposal_memberships_ProposalMembership>
};

export type deleteProposalEmployeeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteProposalMembership: deleteProposalEmployeeMutation_deleteProposalMembership_Proposal
};


export type deleteProposalEmployeeMutationVariables = Exact<{
  input: DeleteProposalMembershipInput;
}>;


export type deleteProposalEmployeeMutation = deleteProposalEmployeeMutation_Mutation;

export type editProposalEmployeeMutation_updateProposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type editProposalEmployeeMutation_updateProposalMembership_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly email: string,
  readonly firstName: string,
  readonly isOwner: boolean,
  readonly lastName: string,
  readonly monthlyFixedPensionPremium: string | null,
  readonly monthlySalary: string,
  readonly personalIdentityNumber: string,
  readonly phoneNumber: string | null,
  readonly benefitPackage: editProposalEmployeeMutation_updateProposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage
};

export type editProposalEmployeeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalMembership: editProposalEmployeeMutation_updateProposalMembership_ProposalMembership
};


export type editProposalEmployeeMutationVariables = Exact<{
  input: UpdateProposalMembershipInput;
}>;


export type editProposalEmployeeMutation = editProposalEmployeeMutation_Mutation;

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = (
  {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
}
  & employersPensionBenefitConfigWithFixedPremium
);

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = (
  {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
}
  & occupationalPensionBenefitConfigWithFixedPremium
);

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration = smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly configuration: smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: ReadonlyArray<smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type smeCompanyEditEmployeeQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyEditEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyEditEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string
};

export type smeCompanyEditEmployeeQuery_proposalMembership_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly email: string,
  readonly firstName: string,
  readonly isOwner: boolean,
  readonly lastName: string,
  readonly monthlyFixedPensionPremium: string | null,
  readonly monthlySalary: string,
  readonly personalIdentityNumber: string,
  readonly phoneNumber: string | null,
  readonly benefitPackage: smeCompanyEditEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage
};

export type smeCompanyEditEmployeeQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEditEmployeeQuery_proposal_Proposal | null,
  readonly proposalMembership: smeCompanyEditEmployeeQuery_proposalMembership_ProposalMembership
};


export type smeCompanyEditEmployeeQueryVariables = Exact<{
  employeeId: Scalars['ID']['input'];
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyEditEmployeeQuery = smeCompanyEditEmployeeQuery_Query;

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = (
  {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
}
  & employersPensionBenefitConfigWithFixedPremium
);

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = (
  {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
}
  & occupationalPensionBenefitConfigWithFixedPremium
);

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration = smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly configuration: smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit_configuration
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: ReadonlyArray<smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type smeCompanyEmployeeQuery_proposalMembership_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly email: string,
  readonly firstName: string,
  readonly isFcfw: boolean | null,
  readonly isOwner: boolean,
  readonly lastName: string,
  readonly monthlyFixedPensionPremium: string | null,
  readonly monthlySalary: string,
  readonly personalIdentityNumber: string,
  readonly phoneNumber: string | null,
  readonly benefitPackage: smeCompanyEmployeeQuery_proposalMembership_ProposalMembership_benefitPackage_ProposalBenefitPackage
};

export type smeCompanyEmployeeQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposalMembership: smeCompanyEmployeeQuery_proposalMembership_ProposalMembership
};


export type smeCompanyEmployeeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyEmployeeQuery = smeCompanyEmployeeQuery_Query;

export type editProposalFcfwMutation_updateProposalMembership_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly isFcfw: boolean | null
};

export type editProposalFcfwMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposalMembership: editProposalFcfwMutation_updateProposalMembership_ProposalMembership
};


export type editProposalFcfwMutationVariables = Exact<{
  input: UpdateProposalMembershipInput;
}>;


export type editProposalFcfwMutation = editProposalFcfwMutation_Mutation;

export type smeCompanyFcfwQuery_proposal_Proposal_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly isFcfw: boolean | null,
  readonly personalIdentityNumber: string
};

export type smeCompanyFcfwQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly memberships: ReadonlyArray<smeCompanyFcfwQuery_proposal_Proposal_memberships_ProposalMembership>
};

export type smeCompanyFcfwQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyFcfwQuery_proposal_Proposal | null
};


export type smeCompanyFcfwQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyFcfwQuery = smeCompanyFcfwQuery_Query;

export type smeCompanyFcfwAssistChipQuery_proposal_Proposal_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string
};

export type smeCompanyFcfwAssistChipQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly memberships: ReadonlyArray<smeCompanyFcfwAssistChipQuery_proposal_Proposal_memberships_ProposalMembership>
};

export type smeCompanyFcfwAssistChipQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyFcfwAssistChipQuery_proposal_Proposal | null
};


export type smeCompanyFcfwAssistChipQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyFcfwAssistChipQuery = smeCompanyFcfwAssistChipQuery_Query;

export type smeCompanyFcfwCompanySizeQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly companySize: ProposalCompanySize
};

export type smeCompanyFcfwCompanySizeQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyFcfwCompanySizeQuery_proposal_Proposal | null
};


export type smeCompanyFcfwCompanySizeQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type smeCompanyFcfwCompanySizeQuery = smeCompanyFcfwCompanySizeQuery_Query;

export type smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership_benefitPackage_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string
};

export type smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership = {
  readonly __typename?: 'ProposalMembership',
  readonly id: string,
  readonly firstName: string,
  readonly isFcfw: boolean | null,
  readonly isOwner: boolean,
  readonly lastName: string,
  readonly monthlySalary: string,
  readonly personalIdentityNumber: string,
  readonly benefitPackage: smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership_benefitPackage_ProposalBenefitPackage
};

export type smeCompanyEmployeesQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly memberships: ReadonlyArray<smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership>
};

export type smeCompanyEmployeesQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyEmployeesQuery_proposal_Proposal | null
};


export type smeCompanyEmployeesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyEmployeesQuery = smeCompanyEmployeesQuery_Query;

export type employersPensionBenefitConfigWithFixedPremium = {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig',
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly extraFixedPremium: boolean
};

export type occupationalPensionBenefitConfigWithFixedPremium = {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig',
  readonly premiumCalculationMethod: ProposalPremiumCalculationMethod,
  readonly extraFixedPremium: boolean
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig = {
  readonly __typename?: 'ProposalAccidentBenefitConfig'
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig = (
  {
  readonly __typename?: 'ProposalEmployersPensionBenefitConfig'
}
  & employersPensionBenefitConfigWithFixedPremium
);

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig = {
  readonly __typename?: 'ProposalHealthcareBenefitConfig'
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig = {
  readonly __typename?: 'ProposalLifeBenefitConfig'
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig = (
  {
  readonly __typename?: 'ProposalOccupationalPensionBenefitConfig'
}
  & occupationalPensionBenefitConfigWithFixedPremium
);

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig = {
  readonly __typename?: 'ProposalSalaryExchangeBenefitConfig'
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig = {
  readonly __typename?: 'ProposalSicknessBenefitConfig'
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig = {
  readonly __typename?: 'ProposalTglBenefitConfig'
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration = smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig | smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalTglBenefitConfig;

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit = {
  readonly __typename?: 'ProposalBenefit',
  readonly id: string,
  readonly type: ProposalBenefitType,
  readonly configuration: smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage = {
  readonly __typename?: 'ProposalBenefitPackage',
  readonly id: string,
  readonly name: string,
  readonly benefits: ReadonlyArray<smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit>
};

export type smeCompanyCreateEmployeeQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly benefitPackages: ReadonlyArray<smeCompanyCreateEmployeeQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage>
};

export type smeCompanyCreateEmployeeQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyCreateEmployeeQuery_proposal_Proposal | null
};


export type smeCompanyCreateEmployeeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyCreateEmployeeQuery = smeCompanyCreateEmployeeQuery_Query;

export type smeUpdateMissingCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_clearingCode_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly code: string
};

export type smeUpdateMissingCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly accountNumber: string | null,
  readonly paymentMethod: PaymentMethod | null,
  readonly clearingCode: smeUpdateMissingCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company_clearingCode_ClearingCode | null
};

export type smeUpdateMissingCompanyDetailsMutation_updateCompany_UpdateCompanyPayload = {
  readonly __typename?: 'UpdateCompanyPayload',
  readonly company: smeUpdateMissingCompanyDetailsMutation_updateCompany_UpdateCompanyPayload_company_Company
};

export type smeUpdateMissingCompanyDetailsMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateCompany: smeUpdateMissingCompanyDetailsMutation_updateCompany_UpdateCompanyPayload | null
};


export type smeUpdateMissingCompanyDetailsMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;


export type smeUpdateMissingCompanyDetailsMutation = smeUpdateMissingCompanyDetailsMutation_Mutation;

export type smeGenerateProposalMutation_generateProposal_Proposal_documents_AgreementDocumentRecord_offer_File = {
  readonly __typename?: 'File',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type smeGenerateProposalMutation_generateProposal_Proposal_documents_AgreementDocumentRecord_otherDocuments_File = {
  readonly __typename?: 'File',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type smeGenerateProposalMutation_generateProposal_Proposal_documents_AgreementDocumentRecord = {
  readonly __typename?: 'AgreementDocumentRecord',
  readonly offer: smeGenerateProposalMutation_generateProposal_Proposal_documents_AgreementDocumentRecord_offer_File,
  readonly otherDocuments: ReadonlyArray<smeGenerateProposalMutation_generateProposal_Proposal_documents_AgreementDocumentRecord_otherDocuments_File>
};

export type smeGenerateProposalMutation_generateProposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly status: ProposalStatus,
  readonly documents: smeGenerateProposalMutation_generateProposal_Proposal_documents_AgreementDocumentRecord | null
};

export type smeGenerateProposalMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly generateProposal: smeGenerateProposalMutation_generateProposal_Proposal
};


export type smeGenerateProposalMutationVariables = Exact<{
  input: GenerateProposalInput;
}>;


export type smeGenerateProposalMutation = smeGenerateProposalMutation_Mutation;

export type smeGenerateProposalQuery_company_Company_clearingCode_ClearingCode = {
  readonly __typename?: 'ClearingCode',
  readonly code: string,
  readonly displayName: string
};

export type smeGenerateProposalQuery_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly accountNumber: string | null,
  readonly paymentMethod: PaymentMethod | null,
  readonly clearingCode: smeGenerateProposalQuery_company_Company_clearingCode_ClearingCode | null
};

export type smeGenerateProposalQuery_Query = {
  readonly __typename?: 'Query',
  readonly company: smeGenerateProposalQuery_company_Company | null
};


export type smeGenerateProposalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type smeGenerateProposalQuery = smeGenerateProposalQuery_Query;

export type smeGenerateProposalDocumentsQuery_proposal_Proposal_documents_AgreementDocumentRecord_offer_File = {
  readonly __typename?: 'File',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type smeGenerateProposalDocumentsQuery_proposal_Proposal_documents_AgreementDocumentRecord_otherDocuments_File = {
  readonly __typename?: 'File',
  readonly name: string,
  readonly path: string,
  readonly url: string
};

export type smeGenerateProposalDocumentsQuery_proposal_Proposal_documents_AgreementDocumentRecord = {
  readonly __typename?: 'AgreementDocumentRecord',
  readonly offer: smeGenerateProposalDocumentsQuery_proposal_Proposal_documents_AgreementDocumentRecord_offer_File,
  readonly otherDocuments: ReadonlyArray<smeGenerateProposalDocumentsQuery_proposal_Proposal_documents_AgreementDocumentRecord_otherDocuments_File>
};

export type smeGenerateProposalDocumentsQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly documents: smeGenerateProposalDocumentsQuery_proposal_Proposal_documents_AgreementDocumentRecord | null
};

export type smeGenerateProposalDocumentsQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeGenerateProposalDocumentsQuery_proposal_Proposal | null
};


export type smeGenerateProposalDocumentsQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type smeGenerateProposalDocumentsQuery = smeGenerateProposalDocumentsQuery_Query;

export type updateProposalMutation_updateProposal_Proposal_lastEditedBy_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type updateProposalMutation_updateProposal_Proposal_fees_ProposalFees = {
  readonly __typename?: 'ProposalFees',
  readonly annualFixedFeeTraditional: string,
  readonly annualFixedFeeFund: string,
  readonly annualFixedFeePortfolio: string,
  readonly annualFeeFund: boolean,
  readonly annualFeePortfolio: boolean,
  readonly annualFeeTraditional: boolean,
  readonly capitalFeeFund: number | null,
  readonly capitalFeePortfolio: number | null,
  readonly capitalFeeTraditional: number | null,
  readonly fundDiscountPermitted: boolean,
  readonly fundDiscountValue: number | null,
  readonly fundRange: ReadonlyArray<number>,
  readonly provisionWaiverAllowed: boolean,
  readonly provisionWaiverHealthcare: number | null,
  readonly provisionWaiverPremiumWaiver: number | null,
  readonly provisionWaiverSickness: number | null,
  readonly riskBucket: RiskBucket | null
};

export type updateProposalMutation_updateProposal_Proposal_need_ProposalNeed = {
  readonly __typename?: 'ProposalNeed',
  readonly collectiveAgreementBlueCollar: CollectiveAgreementOption | null,
  readonly collectiveAgreementWhiteCollar: CollectiveAgreementOption | null,
  readonly documentationProvided: boolean | null,
  readonly text: string | null
};

export type updateProposalMutation_updateProposal_Proposal_productKnowledge_ProductKnowledge = {
  readonly __typename?: 'ProductKnowledge',
  readonly adviceSavingsInsurance: boolean | null,
  readonly recentExperiencePension: boolean | null,
  readonly professionalExperiencePension: boolean | null,
  readonly pensionInstrumentKnowledge: boolean | null,
  readonly notes: string | null
};

export type updateProposalMutation_updateProposal_Proposal_summary_ProposalSummary = {
  readonly __typename?: 'ProposalSummary',
  readonly checkNeed: boolean,
  readonly checkExistingCoverage: boolean,
  readonly checkPlannedDevelopment: boolean,
  readonly checkDeductionSpace: boolean,
  readonly checkFinancialSituation: boolean,
  readonly format: ProposalSummaryFormat | null,
  readonly text: string | null
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validProposal_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validNeed_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validProductKnowledge_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validBenefits_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validEmployees_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validFees_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validCompilation_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean,
  readonly errors: ReadonlyArray<string>,
  readonly warnings: ReadonlyArray<string>,
  readonly info: ReadonlyArray<string>
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validSummary_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity = {
  readonly __typename?: 'ProposalValidity',
  readonly validProposal: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validProposal_ValidityObject,
  readonly validNeed: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validNeed_ValidityObject,
  readonly validProductKnowledge: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validProductKnowledge_ValidityObject,
  readonly validBenefits: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validBenefits_ValidityObject,
  readonly validEmployees: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validEmployees_ValidityObject,
  readonly validFees: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validFees_ValidityObject,
  readonly validCompilation: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validCompilation_ValidityObject,
  readonly validSummary: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity_validSummary_ValidityObject
};

export type updateProposalMutation_updateProposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly companySize: ProposalCompanySize,
  readonly updatedAt: string,
  readonly lastEditedBy: updateProposalMutation_updateProposal_Proposal_lastEditedBy_AdminUser,
  readonly fees: updateProposalMutation_updateProposal_Proposal_fees_ProposalFees,
  readonly need?: updateProposalMutation_updateProposal_Proposal_need_ProposalNeed | null,
  readonly productKnowledge?: updateProposalMutation_updateProposal_Proposal_productKnowledge_ProductKnowledge | null,
  readonly summary?: updateProposalMutation_updateProposal_Proposal_summary_ProposalSummary | null,
  readonly validity: updateProposalMutation_updateProposal_Proposal_validity_ProposalValidity
};

export type updateProposalMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposal: updateProposalMutation_updateProposal_Proposal
};


export type updateProposalMutationVariables = Exact<{
  input: UpdateProposalInput;
  skipAdviceData: Scalars['Boolean']['input'];
}>;


export type updateProposalMutation = updateProposalMutation_Mutation;

export type editProposalNameMutation_updateProposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly name: string
};

export type editProposalNameMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateProposal: editProposalNameMutation_updateProposal_Proposal
};


export type editProposalNameMutationVariables = Exact<{
  input: UpdateProposalInput;
}>;


export type editProposalNameMutation = editProposalNameMutation_Mutation;

export type updateProposalQuery_proposal_Proposal_fees_ProposalFees = {
  readonly __typename?: 'ProposalFees',
  readonly annualFeeFund: boolean,
  readonly annualFeePortfolio: boolean,
  readonly annualFeeTraditional: boolean,
  readonly capitalFeeFund: number | null,
  readonly capitalFeePortfolio: number | null,
  readonly capitalFeeTraditional: number | null,
  readonly fundDiscountPermitted: boolean,
  readonly fundDiscountValue: number | null,
  readonly provisionWaiverAllowed: boolean,
  readonly provisionWaiverHealthcare: number | null,
  readonly provisionWaiverPremiumWaiver: number | null,
  readonly provisionWaiverSickness: number | null,
  readonly riskBucket: RiskBucket | null
};

export type updateProposalQuery_proposal_Proposal_need_ProposalNeed = {
  readonly __typename?: 'ProposalNeed',
  readonly collectiveAgreementBlueCollar: CollectiveAgreementOption | null,
  readonly collectiveAgreementWhiteCollar: CollectiveAgreementOption | null,
  readonly documentationProvided: boolean | null,
  readonly text: string | null
};

export type updateProposalQuery_proposal_Proposal_productKnowledge_ProductKnowledge = {
  readonly __typename?: 'ProductKnowledge',
  readonly adviceSavingsInsurance: boolean | null,
  readonly recentExperiencePension: boolean | null,
  readonly professionalExperiencePension: boolean | null,
  readonly pensionInstrumentKnowledge: boolean | null,
  readonly notes: string | null
};

export type updateProposalQuery_proposal_Proposal_summary_ProposalSummary = {
  readonly __typename?: 'ProposalSummary',
  readonly checkNeed: boolean,
  readonly checkExistingCoverage: boolean,
  readonly checkPlannedDevelopment: boolean,
  readonly checkDeductionSpace: boolean,
  readonly checkFinancialSituation: boolean,
  readonly format: ProposalSummaryFormat | null,
  readonly text: string | null
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validProposal_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validNeed_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validProductKnowledge_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validBenefits_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validEmployees_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validFees_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validCompilation_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean,
  readonly info: ReadonlyArray<string>,
  readonly warnings: ReadonlyArray<string>,
  readonly errors: ReadonlyArray<string>
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validSummary_ValidityObject = {
  readonly __typename?: 'ValidityObject',
  readonly isValid: boolean
};

export type updateProposalQuery_proposal_Proposal_validity_ProposalValidity = {
  readonly __typename?: 'ProposalValidity',
  readonly validProposal: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validProposal_ValidityObject,
  readonly validNeed: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validNeed_ValidityObject,
  readonly validProductKnowledge: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validProductKnowledge_ValidityObject,
  readonly validBenefits: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validBenefits_ValidityObject,
  readonly validEmployees: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validEmployees_ValidityObject,
  readonly validFees: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validFees_ValidityObject,
  readonly validCompilation: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validCompilation_ValidityObject,
  readonly validSummary: updateProposalQuery_proposal_Proposal_validity_ProposalValidity_validSummary_ValidityObject
};

export type updateProposalQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly companySize: ProposalCompanySize,
  readonly fees: updateProposalQuery_proposal_Proposal_fees_ProposalFees,
  readonly need?: updateProposalQuery_proposal_Proposal_need_ProposalNeed | null,
  readonly productKnowledge?: updateProposalQuery_proposal_Proposal_productKnowledge_ProductKnowledge | null,
  readonly summary?: updateProposalQuery_proposal_Proposal_summary_ProposalSummary | null,
  readonly validity: updateProposalQuery_proposal_Proposal_validity_ProposalValidity
};

export type updateProposalQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: updateProposalQuery_proposal_Proposal | null
};


export type updateProposalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  skipAdviceData: Scalars['Boolean']['input'];
}>;


export type updateProposalQuery = updateProposalQuery_Query;

export type proposalSavingsAdviceQuery_proposal_Proposal_productKnowledge_ProductKnowledge = {
  readonly __typename?: 'ProductKnowledge',
  readonly adviceSavingsInsurance: boolean | null
};

export type proposalSavingsAdviceQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly adviceWaived: ProposalAdviceOption,
  readonly companySize: ProposalCompanySize,
  readonly productKnowledge: proposalSavingsAdviceQuery_proposal_Proposal_productKnowledge_ProductKnowledge | null
};

export type proposalSavingsAdviceQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: proposalSavingsAdviceQuery_proposal_Proposal | null
};


export type proposalSavingsAdviceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type proposalSavingsAdviceQuery = proposalSavingsAdviceQuery_Query;

export type changeProposalNameQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly name: string
};

export type changeProposalNameQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: changeProposalNameQuery_proposal_Proposal | null
};


export type changeProposalNameQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type changeProposalNameQuery = changeProposalNameQuery_Query;

export type createRepresentativeMutation_createCompanyRepresentative_Proposal_representatives_CompanyRepresentative = {
  readonly __typename?: 'CompanyRepresentative',
  readonly id: string
};

export type createRepresentativeMutation_createCompanyRepresentative_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly representatives: ReadonlyArray<createRepresentativeMutation_createCompanyRepresentative_Proposal_representatives_CompanyRepresentative>
};

export type createRepresentativeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createCompanyRepresentative: createRepresentativeMutation_createCompanyRepresentative_Proposal
};


export type createRepresentativeMutationVariables = Exact<{
  input: CreateCompanyRepresentativeInput;
}>;


export type createRepresentativeMutation = createRepresentativeMutation_Mutation;

export type createRepresentativeModalQuery_proposalContactPerson_ProposalCompanyContactPerson = {
  readonly __typename?: 'ProposalCompanyContactPerson',
  readonly firstName: string,
  readonly lastName: string,
  readonly personalIdentityNumber: string | null,
  readonly email: string,
  readonly phoneNumber: string
};

export type createRepresentativeModalQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposalContactPerson: createRepresentativeModalQuery_proposalContactPerson_ProposalCompanyContactPerson | null
};


export type createRepresentativeModalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type createRepresentativeModalQuery = createRepresentativeModalQuery_Query;

export type deleteRepresentativeMutation_deleteCompanyRepresentative_Proposal_representatives_CompanyRepresentative = {
  readonly __typename?: 'CompanyRepresentative',
  readonly id: string
};

export type deleteRepresentativeMutation_deleteCompanyRepresentative_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly representatives: ReadonlyArray<deleteRepresentativeMutation_deleteCompanyRepresentative_Proposal_representatives_CompanyRepresentative>
};

export type deleteRepresentativeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteCompanyRepresentative: deleteRepresentativeMutation_deleteCompanyRepresentative_Proposal
};


export type deleteRepresentativeMutationVariables = Exact<{
  input: DeleteCompanyRepresentativeInput;
}>;


export type deleteRepresentativeMutation = deleteRepresentativeMutation_Mutation;

export type editRepresentativeMutation_updateCompanyRepresentative_Proposal_representatives_CompanyRepresentative = {
  readonly __typename?: 'CompanyRepresentative',
  readonly firstName: string,
  readonly id: string,
  readonly identificationType: CompanyRepresentativeIdentificationType,
  readonly identificationReference: string | null,
  readonly lastName: string,
  readonly personalIdentityNumber: string,
  readonly role: CompanyRepresentativeRole
};

export type editRepresentativeMutation_updateCompanyRepresentative_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly representatives: ReadonlyArray<editRepresentativeMutation_updateCompanyRepresentative_Proposal_representatives_CompanyRepresentative>
};

export type editRepresentativeMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly updateCompanyRepresentative: editRepresentativeMutation_updateCompanyRepresentative_Proposal
};


export type editRepresentativeMutationVariables = Exact<{
  input: UpdateCompanyRepresentativeInput;
}>;


export type editRepresentativeMutation = editRepresentativeMutation_Mutation;

export type editRepresentativeModalQuery_proposalContactPerson_ProposalCompanyContactPerson = {
  readonly __typename?: 'ProposalCompanyContactPerson',
  readonly firstName: string,
  readonly lastName: string,
  readonly personalIdentityNumber: string | null
};

export type editRepresentativeModalQuery_companyRepresentative_CompanyRepresentative = {
  readonly __typename?: 'CompanyRepresentative',
  readonly id: string,
  readonly firstName: string,
  readonly identificationReference: string | null,
  readonly identificationType: CompanyRepresentativeIdentificationType,
  readonly lastName: string,
  readonly personalIdentityNumber: string,
  readonly role: CompanyRepresentativeRole
};

export type editRepresentativeModalQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposalContactPerson: editRepresentativeModalQuery_proposalContactPerson_ProposalCompanyContactPerson | null,
  readonly companyRepresentative: editRepresentativeModalQuery_companyRepresentative_CompanyRepresentative
};


export type editRepresentativeModalQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  representativeId: Scalars['ID']['input'];
}>;


export type editRepresentativeModalQuery = editRepresentativeModalQuery_Query;

export type smeCompanyRepresentativesQuery_proposal_Proposal_representatives_CompanyRepresentative = {
  readonly __typename?: 'CompanyRepresentative',
  readonly id: string,
  readonly firstName: string,
  readonly identificationReference: string | null,
  readonly identificationType: CompanyRepresentativeIdentificationType,
  readonly lastName: string,
  readonly personalIdentityNumber: string,
  readonly role: CompanyRepresentativeRole
};

export type smeCompanyRepresentativesQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly representatives: ReadonlyArray<smeCompanyRepresentativesQuery_proposal_Proposal_representatives_CompanyRepresentative>
};

export type smeCompanyRepresentativesQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeCompanyRepresentativesQuery_proposal_Proposal | null
};


export type smeCompanyRepresentativesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type smeCompanyRepresentativesQuery = smeCompanyRepresentativesQuery_Query;

export type executeProposalMutation_executeProposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly name: string,
  readonly status: ProposalStatus
};

export type executeProposalMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly executeProposal: executeProposalMutation_executeProposal_Proposal
};


export type executeProposalMutationVariables = Exact<{
  input: ExecuteProposalInput;
}>;


export type executeProposalMutation = executeProposalMutation_Mutation;

export type executeProposalQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly startDate: string | null
};

export type executeProposalQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: executeProposalQuery_proposal_Proposal | null
};


export type executeProposalQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type executeProposalQuery = executeProposalQuery_Query;

export type deleteProposalMutation_deleteProposal_DeleteProposalPayload = {
  readonly __typename?: 'DeleteProposalPayload',
  readonly clientMutationId: string | null
};

export type deleteProposalMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly deleteProposal: deleteProposalMutation_deleteProposal_DeleteProposalPayload
};


export type deleteProposalMutationVariables = Exact<{
  input: DeleteProposalInput;
}>;


export type deleteProposalMutation = deleteProposalMutation_Mutation;

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_createdBy_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_lastEditedBy_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_documents_AgreementDocumentRecord_offer_File = {
  readonly __typename?: 'File',
  readonly name: string,
  readonly url: string
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_documents_AgreementDocumentRecord_otherDocuments_File = {
  readonly __typename?: 'File',
  readonly name: string,
  readonly url: string
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_documents_AgreementDocumentRecord = {
  readonly __typename?: 'AgreementDocumentRecord',
  readonly offer: smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_documents_AgreementDocumentRecord_offer_File,
  readonly otherDocuments: ReadonlyArray<smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_documents_AgreementDocumentRecord_otherDocuments_File>
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly name: string,
  readonly status: ProposalStatus,
  readonly updatedAt: string,
  readonly createdBy: smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_createdBy_AdminUser,
  readonly lastEditedBy: smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_lastEditedBy_AdminUser,
  readonly documents: smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal_documents_AgreementDocumentRecord | null
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge = {
  readonly __typename?: 'ProposalEdge',
  readonly node: smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal
};

export type smeCompanyProposalsQuery_proposals_ProposalConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type smeCompanyProposalsQuery_proposals_ProposalConnection = {
  readonly __typename?: 'ProposalConnection',
  readonly edges: ReadonlyArray<smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge>,
  readonly pageInfo: smeCompanyProposalsQuery_proposals_ProposalConnection_pageInfo_PageInfo
};

export type smeCompanyProposalsQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposals: smeCompanyProposalsQuery_proposals_ProposalConnection | null
};


export type smeCompanyProposalsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  companyId: Scalars['ID']['input'];
  orderBy?: InputMaybe<ProposalsSortingArguments>;
}>;


export type smeCompanyProposalsQuery = smeCompanyProposalsQuery_Query;

export type smeProposalReadonlyQuery_proposal_Proposal = {
  readonly __typename?: 'Proposal',
  readonly id: string,
  readonly status: ProposalStatus
};

export type smeProposalReadonlyQuery_Query = {
  readonly __typename?: 'Query',
  readonly proposal: smeProposalReadonlyQuery_proposal_Proposal | null
};


export type smeProposalReadonlyQueryVariables = Exact<{
  proposalId: Scalars['ID']['input'];
}>;


export type smeProposalReadonlyQuery = smeProposalReadonlyQuery_Query;

export type updateSmeCompanyContactPersonMutation_setProposalContactPerson_ProposalCompanyContactPerson = {
  readonly __typename?: 'ProposalCompanyContactPerson',
  readonly firstName: string,
  readonly lastName: string,
  readonly phoneNumber: string,
  readonly email: string,
  readonly personalIdentityNumber: string | null
};

export type updateSmeCompanyContactPersonMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly setProposalContactPerson: updateSmeCompanyContactPersonMutation_setProposalContactPerson_ProposalCompanyContactPerson
};


export type updateSmeCompanyContactPersonMutationVariables = Exact<{
  input: SetProposalCompanyContactPersonInput;
}>;


export type updateSmeCompanyContactPersonMutation = updateSmeCompanyContactPersonMutation_Mutation;

export type updateSmeCompanyHeadAdminMutation_setProposalHeadAdmin_ProposalCompanyHeadAdmin = {
  readonly __typename?: 'ProposalCompanyHeadAdmin',
  readonly firstName: string,
  readonly lastName: string,
  readonly phoneNumber: string,
  readonly email: string,
  readonly personalIdentityNumber: string
};

export type updateSmeCompanyHeadAdminMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly setProposalHeadAdmin: updateSmeCompanyHeadAdminMutation_setProposalHeadAdmin_ProposalCompanyHeadAdmin
};


export type updateSmeCompanyHeadAdminMutationVariables = Exact<{
  input: SetProposalCompanyHeadAdminInput;
}>;


export type updateSmeCompanyHeadAdminMutation = updateSmeCompanyHeadAdminMutation_Mutation;

export type smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_nlpSalesRep_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string
};

export type smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company = {
  readonly __typename?: 'Company',
  readonly id: string,
  readonly latestProposalStatus: ProposalStatus | null,
  readonly name: string,
  readonly registrationNumber: string,
  readonly nlpSalesRep: smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company_nlpSalesRep_AdminUser | null
};

export type smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge = {
  readonly __typename?: 'CompanyEdge',
  readonly node: smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company
};

export type smeCompaniesQuery_companies_CompanyConnection_pageInfo_PageInfo = (
  {
  readonly __typename?: 'PageInfo'
}
  & pageInfoDetails
);

export type smeCompaniesQuery_companies_CompanyConnection = {
  readonly __typename?: 'CompanyConnection',
  readonly edges: ReadonlyArray<smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge>,
  readonly pageInfo: smeCompaniesQuery_companies_CompanyConnection_pageInfo_PageInfo
};

export type smeCompaniesQuery_Query = {
  readonly __typename?: 'Query',
  readonly companies: smeCompaniesQuery_companies_CompanyConnection | null
};


export type smeCompaniesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CompanyFilters>;
  orderBy?: InputMaybe<CompaniesOrderBy>;
}>;


export type smeCompaniesQuery = smeCompaniesQuery_Query;

export type createSmeCompanyMutation_createCompany2_CreateCompany2Payload_company_Company = {
  readonly __typename?: 'Company',
  readonly id: string
};

export type createSmeCompanyMutation_createCompany2_CreateCompany2Payload = {
  readonly __typename?: 'CreateCompany2Payload',
  readonly company: createSmeCompanyMutation_createCompany2_CreateCompany2Payload_company_Company
};

export type createSmeCompanyMutation_Mutation = {
  readonly __typename?: 'Mutation',
  readonly createCompany2: createSmeCompanyMutation_createCompany2_CreateCompany2Payload | null
};


export type createSmeCompanyMutationVariables = Exact<{
  input: CreateCompany2Input;
}>;


export type createSmeCompanyMutation = createSmeCompanyMutation_Mutation;

export type createSmeCompanyQuery_nlpSalesReps_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly externalId: string
};

export type createSmeCompanyQuery_adminViewer_AdminUser = {
  readonly __typename?: 'AdminUser',
  readonly id: string
};

export type createSmeCompanyQuery_Query = {
  readonly __typename?: 'Query',
  readonly nlpSalesReps: ReadonlyArray<createSmeCompanyQuery_nlpSalesReps_AdminUser>,
  readonly adminViewer: createSmeCompanyQuery_adminViewer_AdminUser | null
};


export type createSmeCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type createSmeCompanyQuery = createSmeCompanyQuery_Query;
