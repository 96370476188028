import { ContentContainer, Icon } from '@frontend/ui';
import { search as searchIcon } from '@frontend/ui/icons';
import { insuranceMessages } from 'app/messages/insurance';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Insurances } from 'features/insurances';
import { Page } from 'features/page';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

export const InsurancesRoute: React.FC<RouteComponentProps> = ({ match }) => {
  const { permissions } = useCurrentUser();

  if (!hasBackstagePermission('backstageApp', 'insurancesTab', permissions)) {
    return <Redirect to="/" />;
  }

  return (
    <NavigationAnchor title={insuranceMessages.insurances} url="/insurances">
      <Page title={<FormattedMessage {...insuranceMessages.insurances} />}>
        <Switch>
          <Route exact path={match.url}>
            <ContentContainer>
              <SearchField
                leadingIcon={<Icon icon={searchIcon} decorative />}
                label={
                  <FormattedMessage
                    {...insuranceMessages.searchInsuranceNumber}
                  />
                }
              />
              <Insurances />
            </ContentContainer>
          </Route>
          <Route component={EmptyState} />
        </Switch>
      </Page>
    </NavigationAnchor>
  );
};
