import { shield } from '@frontend/ui/icons';
import {
  insurancesQuery,
  insurancesQueryVariables,
} from 'app/apollo/graphql/types';
import { insuranceMessages } from 'app/messages/insurance';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';

import { INSURANCES_QUERY } from './graphql/queries';
import { updateInsurancesQuery } from './lib/update-insurances-query';
import { InsuranceTable } from './table';
import { useDeleteInsurances } from './utils/use-delete-insurances';

export const Insurances: React.FC = () => {
  const { deleteInsurances, deleteInsuranceError } = useDeleteInsurances();
  const { formatMessage } = useIntl();

  const { search: _search, per_page } = qs.parse(location.search);
  const search = _search?.trim();

  const { data, loading, previousData, error, fetchMore } = useQuery<
    insurancesQuery,
    insurancesQueryVariables
  >(INSURANCES_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
    },
    skip: !search || search.length < 3,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  const insuranceEdges = _data?.insurances;
  const pageInfo = insuranceEdges?.pageInfo;

  if (!insuranceEdges || !pageInfo) {
    return (
      <>
        {loading && <TopLoading />}
        <EmptyState
          icon={shield}
          error={error}
          title={<FormattedMessage {...insuranceMessages.searchInsurance} />}
        >
          <FormattedMessage {...insuranceMessages.searchInsuranceDescription} />
        </EmptyState>
      </>
    );
  }

  const insurances = insuranceEdges.edges.map(edge => edge.node);

  return (
    <>
      {loading && <TopLoading />}
      {(error || deleteInsuranceError) && (
        <GraphQlError error={error ?? deleteInsuranceError} />
      )}
      {insurances.length ? (
        <InsuranceTable
          pageInfo={pageInfo}
          insurances={insurances}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery: updateInsurancesQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery: updateInsurancesQuery,
            });
          }}
          deleteInsurances={deleteInsurances}
        />
      ) : (
        !loading && (
          <span role="status">
            {formatMessage(insuranceMessages.noInsurancesFound, {
              search,
            })}
          </span>
        )
      )}
    </>
  );
};
