import { A, ASet, ASetItem, WizardContentCard } from '@frontend/ui';
import { attachFile, description } from '@frontend/ui/icons';
import {
  smeGenerateProposalDocumentsQuery,
  smeGenerateProposalDocumentsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import { smeGenerateProposalMessages } from 'features/sme/messages/sme';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { SME_GENERATE_PROPOSAL_DOCUMENTS_QUERY } from '../graphql/queries';

type Props = RouteComponentProps<MatchParams>;

export const GenerateProposalDocuments: React.FC<Props> = ({
  match: { params },
}) => {
  const { data, loading, error } = useQuery<
    smeGenerateProposalDocumentsQuery,
    smeGenerateProposalDocumentsQueryVariables
  >(SME_GENERATE_PROPOSAL_DOCUMENTS_QUERY, {
    errorPolicy: 'all',
    variables: { proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const documents = data?.proposal?.documents;
  const hasDocuments = documents?.offer || !!documents?.otherDocuments?.length;

  return (
    <SimpleWizard
      actions={
        <LinkButton filled to={`/sme/${params.companyId}/proposals`}>
          <FormattedMessage {...commonMessages.done} />
        </LinkButton>
      }
      offWhite
      parentLink={`/sme/${params.companyId}/proposals`}
      title={
        <FormattedMessage {...smeGenerateProposalMessages.generateProposal} />
      }
    >
      <WizardContentCard
        header={
          <FormattedMessage
            {...smeGenerateProposalMessages.proposalsAndAttachments}
          />
        }
      >
        {error && <GraphQlError error={error} />}
        {hasDocuments && (
          <ASet>
            {documents.offer && (
              <ASetItem>
                <A
                  download
                  href={documents.offer.url}
                  icon={description}
                  target="_blank"
                >
                  {documents.offer.name}
                </A>
              </ASetItem>
            )}
            {documents.otherDocuments?.map(attachment => (
              <ASetItem>
                <A
                  download
                  href={attachment.url}
                  icon={attachFile}
                  target="_blank"
                >
                  {attachment.name}
                </A>
              </ASetItem>
            ))}
          </ASet>
        )}
      </WizardContentCard>
    </SimpleWizard>
  );
};
