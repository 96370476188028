import {
  AdvinansOccupationalPension,
  AdvinansSalaryExchange,
  isAccidentInsuranceBenefit,
  isBenefitType,
  isDisabilityInsuranceBenefit,
  isEpassiBikeBenefit,
  isHealthInsuranceBenefit,
  isLifeInsuranceBenefit,
  isOccupationalPensionBenefit,
  isSalaryExchangeBenefit,
  isSicknessAccidentInsuranceBenefit,
  isWellnessBenefit,
} from '@frontend/benefit-types';
import {
  benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType as RemunerationType,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  pensionBenefitDeductionMessages,
  pensionBenefitMessages,
  salaryExchangeBenefitMessages,
  sicknessBenefitLevelMessages,
  sicknessBenefitMessages,
  wellnessBenefitMessages,
} from 'app/messages/benefits';
import { PENSION_PROVIDER, translatePBBLevel } from 'app/utils/constants';
import { DetailCell as Cell } from 'components/DetailCell';
import {
  FormattedCurrency,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import { useRemunerationTypes } from 'features/companies/company/utils/use-remuneration-types';
import React from 'react';

import {
  benefitFormMessages,
  benefitHealthcareLevelMessages,
  benefitPensionPremiumCalculationMethodMessages,
} from '../../../form/messages';
import { getPremiumCalculationMethod } from '../../../form/utils';
import {
  PENSION_PREMIUM_CALCULATION_METHODS_NLP,
  PENSION_PREMIUM_CALCULATION_METHODS_NODE,
} from '../../../form/utils/constants';

interface SalaryExchangeDetailProps {
  companyId: string;
  salaryExchange: AdvinansSalaryExchange<Benefit>;
}

const SalaryExchangeDetail: React.FC<SalaryExchangeDetailProps> = ({
  companyId,
  salaryExchange,
}) => {
  const { remunerationTypes } = useRemunerationTypes({ companyId });

  if (!salaryExchange.configuration?.data) {
    return <NoValue />;
  }
  const {
    onetimeMax,
    onetimeDeductionShareMax,
    ongoingMax,
    ongoingDeductionShareMax,
    basePensionableIncomePayrollElementCode,
    basePensionableIncomePayrollElementMultiplier,
  } = salaryExchange.configuration.data;

  const basePensionableIncomePayrollElement = remunerationTypes.find(
    type => type.id === basePensionableIncomePayrollElementCode,
  );

  return (
    <div>
      {(ongoingMax || ongoingDeductionShareMax) && (
        <FormattedMessage
          {...salaryExchangeBenefitMessages.salaryExchangeDeductionValue}
          values={{ amount: ongoingMax, share: ongoingDeductionShareMax }}
        />
      )}
      {(onetimeMax || onetimeDeductionShareMax) && (
        <FormattedMessage
          {...salaryExchangeBenefitMessages.oneTimeSalaryExchangeDeductionValue}
          values={{ amount: onetimeMax, share: onetimeDeductionShareMax }}
        />
      )}
      {!!basePensionableIncomePayrollElement &&
        !!basePensionableIncomePayrollElementMultiplier && (
          <p>
            <FormattedMessage
              {...commonBenefitMessages.benefitQualifyingIncomeTableDescription}
              values={{
                annualFactor: basePensionableIncomePayrollElementMultiplier,
                remunerationType: `${basePensionableIncomePayrollElement.id} ${
                  basePensionableIncomePayrollElement.name ?? ''
                }`,
              }}
            />
          </p>
        )}
    </div>
  );
};

interface OccupationalPensionDetailProps {
  managedBy: ManagingCompany;
  occupationalPension: AdvinansOccupationalPension<Benefit>;
  remunerationTypes: readonly RemunerationType[];
}

const OccupationalPensionDetail: React.FC<OccupationalPensionDetailProps> = ({
  managedBy,
  occupationalPension,
  remunerationTypes,
}) => {
  const { formatMessage } = useIntl();

  if (!occupationalPension.configuration?.data) {
    return <NoValue />;
  }
  const {
    payrollElementCode,
    payrollElementMultiplier,
    premiumMatrix,
    providers: _providers,
    premiumDeductions,
    fixedPremium,
    premiumWaiver,
  } = occupationalPension.configuration.data;

  const pensionPayrollElement = remunerationTypes.find(
    type => type.id === payrollElementCode,
  );
  const providers = _providers?.includes('nordea_trad')
    ? _providers.filter(p => p !== 'nordea_trad')
    : _providers;

  const deductions = premiumDeductions?.length
    ? premiumDeductions
        .map(deduction =>
          formatMessage({
            messages: pensionBenefitDeductionMessages,
            select: deduction,
          }),
        )
        .join(', ')
    : null;

  const isManagedByNode = managedBy === 'NODE';

  const PREMIUM_CALCULATION_METHOD_OPTIONS = isManagedByNode
    ? PENSION_PREMIUM_CALCULATION_METHODS_NODE
    : PENSION_PREMIUM_CALCULATION_METHODS_NLP;

  const premiumCalculationMethod = getPremiumCalculationMethod({
    availableOptions: PREMIUM_CALCULATION_METHOD_OPTIONS,
    premiumMatrix,
  });

  return (
    <Cell>
      <p>
        <FormattedMessage
          select={premiumCalculationMethod}
          messages={benefitPensionPremiumCalculationMethodMessages}
        />
      </p>
      {premiumWaiver && (
        <p>
          <FormattedMessage {...pensionBenefitMessages.premiumWaiver} />
        </p>
      )}
      {!!fixedPremium && (
        <p>
          <FormattedMessage {...pensionBenefitMessages.fixedExtraPremium} />
        </p>
      )}
      {!!providers?.length && (
        <p>
          <FormattedMessage
            {...pensionBenefitMessages.pensionProviders}
            values={{
              count: providers.length,
            }}
          />
          <span>: </span>
          {providers.map((p, i) => (
            <span key={i}>
              {PENSION_PROVIDER[p]}
              {i !== providers.length - 1 && ', '}
            </span>
          ))}
        </p>
      )}
      {pensionPayrollElement && payrollElementMultiplier && (
        <p>
          <FormattedMessage
            {...commonBenefitMessages.benefitQualifyingIncomeTableDescription}
            values={{
              annualFactor: payrollElementMultiplier,
              remunerationType: `${pensionPayrollElement.id} ${
                pensionPayrollElement.name ?? ''
              }`,
            }}
          />
        </p>
      )}
      {!!deductions?.length && (
        <p>
          <FormattedMessage
            {...pensionBenefitMessages.deductionsTableMessage}
            values={{ deductions }}
          />
        </p>
      )}
    </Cell>
  );
};

interface Props {
  benefit: Benefit;
  companyId: string;
  managedBy: ManagingCompany;
}

export const DetailCell: React.FC<Props> = ({
  companyId,
  benefit,
  managedBy,
}) => {
  const { remunerationTypes } = useRemunerationTypes({ companyId });

  let cell: React.ReactNode = null;
  if (!isBenefitType(benefit.type)) {
    return <NoValue />;
  }

  switch (benefit.type) {
    case 'advinans_salary_exchange':
      cell = isSalaryExchangeBenefit(benefit) && (
        <SalaryExchangeDetail companyId={companyId} salaryExchange={benefit} />
      );
      break;
    case 'advinans_occupational_pension':
      cell = isOccupationalPensionBenefit(benefit) && (
        <OccupationalPensionDetail
          managedBy={managedBy}
          occupationalPension={benefit}
          remunerationTypes={remunerationTypes}
        />
      );
      break;
    case 'euro_accident_accident':
      cell =
        isAccidentInsuranceBenefit(benefit) &&
        benefit.configuration?.data &&
        translatePBBLevel(benefit.configuration.data.level);
      break;
    case 'euro_accident_sickness_and_accident':
      cell =
        isSicknessAccidentInsuranceBenefit(benefit) &&
        benefit.configuration?.data &&
        translatePBBLevel(benefit.configuration.data.level);
      break;
    case 'euro_accident_plansjuk':
      if (
        isDisabilityInsuranceBenefit(benefit) &&
        benefit.configuration?.data
      ) {
        const configurationData = benefit.configuration.data;
        const sicknessPayrollElement = remunerationTypes.find(
          type => type.id === configurationData.payrollElementCode,
        );
        const { level, payrollElementMultiplier, extendedSickPay } =
          benefit.configuration.data;
        cell = (
          <Cell>
            <p>
              <FormattedMessage
                select={level}
                messages={sicknessBenefitLevelMessages}
              />
              {extendedSickPay && (
                <>
                  {' '}
                  <FormattedMessage
                    {...sicknessBenefitMessages.withExtendedSickPay}
                  />
                </>
              )}
            </p>
            {!!sicknessPayrollElement && !!payrollElementMultiplier && (
              <p>
                <FormattedMessage
                  {...commonBenefitMessages.benefitQualifyingIncomeTableDescription}
                  values={{
                    annualFactor: payrollElementMultiplier,
                    remunerationType: `${sicknessPayrollElement.id} ${
                      sicknessPayrollElement.name ?? ''
                    }`,
                  }}
                />
              </p>
            )}
          </Cell>
        );
      }
      break;
    case 'euro_accident_life':
      cell =
        isLifeInsuranceBenefit(benefit) &&
        benefit.configuration?.data &&
        translatePBBLevel(benefit.configuration.data.level);
      break;
    case 'euro_accident_tgl':
      cell = <FormattedMessage {...commonBenefitMessages.tgl} />;
      break;
    case 'euro_accident_privataccess':
      cell = isHealthInsuranceBenefit(benefit) &&
        benefit.configuration?.data && (
          <FormattedMessage
            messages={benefitHealthcareLevelMessages}
            select={benefit.configuration.data.level}
          />
        );
      break;
    case 'nordea_node_digital_advice':
      cell = <NoValue />;
      break;
    case 'epassi':
      cell = <FormattedMessage {...wellnessBenefitMessages.viaEpassi} />;
      break;
    case 'epassi_bike':
      cell = isEpassiBikeBenefit(benefit) ? (
        <FormattedMessage
          {...(benefit.configuration?.data?.useDefaultPolicy
            ? benefitFormMessages.epassiBikeStandardPolicy
            : benefitFormMessages.epassiBikeCustomCompanyPolicy)}
        />
      ) : null;
      break;
    case 'wellness':
      cell = isWellnessBenefit(benefit) &&
        benefit.configuration?.data?.allowance && (
          <FormattedCurrency
            value={benefit.configuration?.data.allowance}
            after="PER_YEAR"
            currency="SEK"
          />
        );
      break;
    default:
      return null;
  }
  return <span>{cell ?? <NoValue />}</span>;
};
