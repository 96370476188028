import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  goBack: {
    id: 'formMessages.goBack',
    defaultMessage: 'Gå tillbaka',
  },
  previous: {
    id: 'formMessages.previous',
    defaultMessage: 'Föregående',
  },
  start: {
    id: 'formMessages.start',
    description: 'Start',
    defaultMessage: 'Sätt igång',
  },
  addBenefits: {
    id: 'formMessages.addBenefits',
    description: 'Add benefits',
    defaultMessage: 'Lägg till förmåner',
  },
  saveAndExit: {
    id: 'formMessages.saveAndExit',
    description: 'Save and exit',
    defaultMessage: 'Spara och avsluta',
  },
  addToBenefitPackage: {
    id: 'formMessages.addToBenefitPackage',
    description: 'Lägg till i förmånsgrupp',
    defaultMessage: 'Lägg till i förmånsgrupp',
  },
  changeBenefitPackage: {
    id: 'formMessages.changeBenefitPackage',
    description: 'Change benefit package',
    defaultMessage: 'Byt förmånsgrupp',
  },
  createBenefit: {
    id: 'formMessages.createBenefit',
    description: 'Skapa förmån',
    defaultMessage: 'Skapa förmån',
  },
  selectBenefitType: {
    id: 'formMessages.selectBenefitType',
    defaultMessage: 'Välj förmån',
  },
  benefitPackagesPlaceholder: {
    id: 'formMessages.benefitPackagesPlaceholder',
    description: 'Välj förmånsgrupp',
    defaultMessage: 'Välj förmånsgrupp',
  },
  currentBenefitPackage: {
    id: 'formMessages.currentBenefitPackage',
    description: 'Current benefit package',
    defaultMessage: 'Nuvarande förmånsgrupp',
  },
  createOffer: {
    id: 'formMessages.createOffer',
    description: 'Skapa eget erbjudande',
    defaultMessage: 'Skapa eget erbjudande',
  },
  createCompany: {
    id: 'formMessages.createCompany',
    description: 'Skapa företag',
    defaultMessage: 'Skapa företag',
  },
  costCenterDetails: {
    id: 'formMessages.costCenterDetails',
    description: 'Cost center details',
    defaultMessage: 'Kontor',
  },
  costCenterContact: {
    id: 'formMessages.costCenterContact',
    description: 'Cost center contact',
    defaultMessage: 'Kontaktuppgifter',
  },
  addExistingBenefit: {
    id: 'formMessages.addExistingBenefit',
    description: 'Lägg till befintlig förmån',
    defaultMessage: 'Lägg till befintlig förmån',
  },
  searchEmployee: {
    id: 'formMessages.searchEmployee',
    description: 'Sök efter anställd',
    defaultMessage: 'Sök bland anställda',
  },
  searchEmployeeHelperText: {
    id: 'formMessages.searchEmployeeHelperText',
    description:
      'Sök efter anställd (personnummer, förnamn, efternamn och förmånsgrupp)',
    defaultMessage:
      'Sök bland anställda (personnummer, namn eller förmånsgrupp)',
  },
  noEmployeeText: {
    id: 'formMessages.noEmployeeText',
    description: 'Employee empty table helper text.',
    defaultMessage: 'Inga anställda',
  },
  adminNoEmployee: {
    id: 'formMessages.adminNoEmployee',
    description: 'Employee table empty on admin route',
    defaultMessage: 'Det finns inga anställda att visa',
  },
  adminNoEmployeeText: {
    id: 'formMessages.adminNoEmployeeText',
    description: 'Employee table empty on admin route helper text',
    defaultMessage: 'Anställda som läggs till i portalen kommer att visas här.',
  },
  searchBenefitsHelperText: {
    id: 'formMessages.searchBenefitsHelperText',
    description: 'Sök efter förmåner (förmånnamn, förmånsgrupp, och kategori)',
    defaultMessage: 'Sök bland förmåner (namn, förmånsgrupp eller kategori)',
  },
  searchOffersHelperText: {
    id: 'formMessages.searchOffersHelperText',
    description: 'Sök efter erbjudanden (bolag och kategori)',
    defaultMessage: 'Sök bland erbjudanden (namn, bolag eller kategori)',
  },
  approve: {
    id: 'formMessages.approve',
    description: 'Godkänn',
    defaultMessage: 'Godkänn',
  },
  reject: {
    id: 'formMessages.reject',
    description: 'Avslå',
    defaultMessage: 'Avslå',
  },
  save: {
    id: 'formMessages.save',
    description: 'Spara',
    defaultMessage: 'Spara',
  },
  retry: {
    id: 'formMessages.retry',
    description: 'Retry',
    defaultMessage: 'Försök igen',
  },
  field: {
    id: 'formMessages.field',
    description: 'Fält',
    defaultMessage: 'Fält',
  },
  createdAt: {
    id: 'formMessages.createdAt',
    description: 'Created at',
    defaultMessage: 'Skapad',
  },
  scrapeWithQRCode: {
    id: 'formMessages.scrapeWithQRCode',
    description:
      'Message displayed when scraping institute which needs login with QR code',
    defaultMessage: `Starta BankID-appen och läs av QR-koden.`,
  },
  finishWizard: {
    id: 'formMessages.finishWizard',
    defaultMessage: `Slutför assistenten`,
  },
  queryErrorMessage: {
    id: 'formMessages.queryErrorMessage',
    description: 'The generic error message when there is a network issue.',
    defaultMessage:
      'Det verkar som att det blev något fel vid inhämtning av data.',
  },
  createOrgUnit: {
    id: 'formMessages.createOrgUnit',
    description: 'Create organizational unit',
    defaultMessage: 'Skapa avdelning',
  },
  editOrgUnit: {
    id: 'formMessages.editOrgUnit',
    description: 'Edit organizational unit',
    defaultMessage: 'Ändra avdelning',
  },
  deleteOrgUnit: {
    id: 'formMessages.deleteOrgUnit',
    description: 'Delete organizational unit',
    defaultMessage: 'Ta bort avdelning',
  },
  unitOf: {
    id: 'formMessages.unitOf',
    description: 'unit of',
    defaultMessage: 'Underavdelning till',
  },
  characterLimit: {
    id: 'formMessages.characterLimit',
    defaultMessage: '{nCharacters}/{nMaxCharacters} tecken',
  },
  cancel: {
    id: 'formMessages.cancel',
    description: 'Cancel',
    defaultMessage: 'Avbryt',
  },
  selectAll: {
    id: 'formMessages.selectAll',
    defaultMessage: 'Välj alla',
  },
  confirmLeaveFormTitle: {
    id: 'formMessages.confirmLeaveFormTitle',
    defaultMessage: 'Lämna sidan?',
  },
  confirmLeaveFormDescription: {
    id: 'formMessages.confirmLeaveFormDescription',
    defaultMessage: 'Du har osparade ändringar som kommer att förloras.',
  },
});

export const formSubmitMessages = defineMessages({
  editBenefitSuccessMessage: {
    id: 'formSubmitMessages.editBenefitSuccessMessage',
    description: 'Benefit has been updated',
    defaultMessage: 'Förmånen har uppdaterats',
  },
  editContactInformationSubmitSuccess: {
    id: 'formSubmitMessages.editContactInformationSubmitSuccess',
    description: 'Uppgifterna har uppdaterats',
    defaultMessage: 'Uppgifterna har sparats',
  },
  editEmployeeBenefitPackagesSubmitSuccess: {
    id: 'formSubmitMessages.editEmployeeBenefitPackagesSubmitSuccess',
    description:
      'Success message shown when employee has successfully been added to a benefit package',
    defaultMessage: 'Förmånsgrupp har uppdaterats',
  },
  updateEmployeeAdditionalSubmitError: {
    id: 'formSubmitMessages.updateEmployeeAdditionalSubmitError',
    description:
      'Kunde inte uppdatera {field}, vänligen försöker igen senare eller kontakta kundtjänst',
    defaultMessage:
      'Kunde inte uppdatera {field}, vänligen försöker igen senare eller kontakta kundtjänst',
  },
  updateEmployeeAdditionalSubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeAdditionalSubmitSuccess',
    description: '{field} har uppdaterats',
    defaultMessage: '{field} har uppdaterats',
  },
  updateEmployeeCostCenterSubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeCostCenterSubmitSuccess',
    description: 'Kostnadsställe har uppdaterats',
    defaultMessage: 'Kostnadsställe har sparats',
  },
  updateEmployeeSalarySubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeSalarySubmitSuccess',
    description: 'Lönen har uppdaterats',
    defaultMessage: 'Lön har sparats',
  },
  updateEmployeeStatusSubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeStatusSubmitSuccess',
    description: 'Status har uppdaterats',
    defaultMessage: 'Status har sparats',
  },
  deleteEmployeeStatusSubmitSuccess: {
    id: 'formSubmitMessages.deleteEmployeeStatusSubmitSuccess',
    description: 'Message saying that membership status has been deleted',
    defaultMessage: 'Status har tagits bort',
  },
  updateFixedPensionPremiumSubmitSuccess: {
    id: 'formSubmitMessages.updateFixedPensionPremiumSubmitSuccess',
    description:
      'Submission success message for updating fixed pension premium',
    defaultMessage: 'Pensionspremie har uppdaterats',
  },
  createOfferSubmitSuccess: {
    id: 'formSubmitMessages.createOfferSubmitSuccess',
    description: 'Ditt erbjudande har skapats',
    defaultMessage: 'Erbjudandet har sparats',
  },
  createdBenefitPackageMessageText: {
    id: 'formSubmitMessages.createdBenefitPackageMessageText',
    description: 'har skapats',
    defaultMessage: 'Ny förmånsgrupp {name} har sparats',
  },
  deleteEmployeeStatusSubmitError: {
    id: 'formSubmitMessages.deleteEmployeeStatusSubmitError',
    description: 'Message saying that membership status could not be deleted',
    defaultMessage: 'Status kunde inte tas bort',
  },
  createAgreementSubmitError: {
    id: 'formSubmitMessages.createAgreementSubmitError',
    description:
      'Det blev ett fel när avtalen skulle skapas. Försök igen och kontakta kundtjänst om felet kvarstår.',
    defaultMessage: 'Det gick inte att spara avtalen. Försök igen.',
  },
  signAgreementSubmitError: {
    id: 'formSubmitMessages.signAgreementSubmitError',
    description: 'Det gick inte att skriva under avtalen. Försök igen.',
    defaultMessage: 'Det gick inte att skriva under avtalet. Försök igen.',
  },
  signAgreementSubmitSuccessTitle: {
    id: 'formSubmitMessages.signAgreementSubmitSuccessTitle',
    description: 'Title shown when agreement is successfully signed',
    defaultMessage: 'Du har skrivit under',
  },
  updateCompanySuccess: {
    id: 'formSubmitMessages.updateCompanySuccess',
    description: 'Företagets uppgifter har uppdaterats',
    defaultMessage: '{companyName} har uppdaterats',
  },
  agreementReminderSubmitError: {
    id: 'formSubmitMessages.agreementReminderSubmitError',
    description:
      'Det blev ett fel när påminnelsen skulle skickas. Försök igen och kontakta kundtjänst om felet kvarstår.',
    defaultMessage: 'Det gick inte att skicka påminnelsen. Försök igen.',
  },
  addEmployeeSubmitSuccess: {
    id: 'formSubmitMessages.addEmployeeSubmitSuccess',
    description: 'Personen har skapats',
    defaultMessage: 'Den anställda har lagts till',
  },
  createNewsSuccessText: {
    id: 'formSubmitMessages.createNewsSuccessText',
    description: 'Artikeln har skapats',
    defaultMessage: '{title} har sparats',
  },
  deleteNewsSubmitError: {
    id: 'formSubmitMessages.deleteNewsSubmitError',
    description: 'Submit error of deleting internal news',
    defaultMessage: 'Det gick inte att ta bort artikeln. Försök igen.',
  },
  deleteNewsSuccessText: {
    id: 'formSubmitMessages.deleteNewsSuccessText',
    description: 'News has been deleted',
    defaultMessage: 'Artikeln har tagits bort',
  },
  updateNewsSuccessTitle: {
    id: 'formSubmitMessages.updateNewsSuccessTitle',
    description: 'The title of the message when internal news is updated',
    defaultMessage: 'Artikel sparad',
  },
  updateNewsSuccessText: {
    id: 'formSubmitMessages.updateNewsSuccessText',
    description: '{title} is updated',
    defaultMessage: '{title} har sparats',
  },
  signupSubmitError: {
    id: 'formSubmitMessages.signupSubmitError',
    description: 'Error when the submit in signup fails',
    defaultMessage: 'Det gick inte att bli kund. Försök igen.',
  },
  benefitAddedToBenefitPackagesSuccess: {
    id: 'formSubmitMessages.benefitAddedToBenefitPackagesSuccess',
    defaultMessage: `Förmånen har lagts till i {count, plural, =1 {1 förmånsgrupp} other {# förmånsgrupper}}`,
  },
  benefitsAddedToBenefitPackageSuccess: {
    id: 'formSubmitMessages.benefitsAddedToBenefitPackageSuccess',
    defaultMessage: `{count, plural, =1 {1 förmån} other {# förmåner}} har lagts till i förmånsgruppen`,
  },
  offerFormSubmissionSuccess: {
    id: 'formSubmitMessages.offerFormSubmissionSuccess',
    description: 'Success message shown when an offer form is submitted',
    defaultMessage: 'Formuläret har skickats',
  },
  offerFormSubmissionSuccessBook: {
    id: 'formSubmitMessages.offerFormSubmissionSuccessBook',
    description: 'Success message shown when an offer form is submitted',
    defaultMessage: 'Formuläret har skickats, kom ihåg att boka tid',
  },
  updateFileImportSuccess: {
    id: 'formSubmitMessages.updateFileImportSuccess',
    description: 'Success message shown when an import is updated',
    defaultMessage: 'Importen har uppdaterats',
  },
  createOrgUnitSuccess: {
    id: 'formSubmitMessages.createOrgUnitSuccess',
    description: 'Success message shown when an organizational unit is created',
    defaultMessage: 'Avdelningen har skapats',
  },
  updateOrgUnitSuccess: {
    id: 'formSubmitMessages.updateOrgUnitSuccess',
    description: 'Success message shown when an organizational unit is updated',
    defaultMessage: 'Avdelningen har uppdaterats',
  },
  deleteOrgUnitSuccess: {
    id: 'formSubmitMessages.deleteOrgUnitSuccess',
    description: 'Success message shown when an organizational unit is deleted',
    defaultMessage: 'Avdelningen har tagits bort',
  },
  frontendSubmitError: {
    id: 'formSubmitMessages.frontendSubmitError',
    description:
      'Error shown when the frontend for some reason won´t submit a form',
    defaultMessage: 'Något gick fel. Försök igen',
  },
  book: {
    id: 'formSubmitMessages.book',
    description: 'Book appointment',
    defaultMessage: 'Boka tid',
  },
  bookSynsamDescr: {
    id: 'formSubmitMessages.bookSynsamDescr',
    defaultMessage:
      'Gå till Synsams webbsida och boka tid i den butik som du valde.',
  },
  addEmploymentSuccess: {
    id: 'formSubmitMessages.addEmploymentSuccess',
    defaultMessage: 'Anställningen har lagts till',
  },
  updateEmploymentSuccess: {
    id: 'formSubmitMessages.updateEmploymentSuccess',
    defaultMessage: 'Anställningen har ändrats',
  },
  deleteEmploymentSuccess: {
    id: 'formSubmitMessages.deleteEmploymentSuccess',
    defaultMessage: 'Anställningen har tagits bort',
  },
  onSalaryExchangeRequestSent: {
    id: 'formSubmitMessages.onSalaryExchangeRequestSent',
    defaultMessage: 'Din ansökan har tagits emot',
  },
  addEmploymentActivitySuccess: {
    id: 'formSubmitMessages.addEmploymentActivitySuccess',
    defaultMessage: `{type, select,
        OFF_DUTY {Tjänstledighet}
        PARENTAL_LEAVE {Föräldraledighet}
        other {Fel}
      } har lagts till`,
  },
  editEmploymentActivitySuccess: {
    id: 'formSubmitMessages.editEmploymentActivitySuccess',
    defaultMessage: `{type, select,
        OFF_DUTY {Tjänstledighet}
        PARENTAL_LEAVE {Föräldraledighet}
        other {Fel}
      } har ändrats`,
  },
  confirmExitWizard: {
    id: 'formSubmitMessages.confirmExitWizard',
    defaultMessage: 'Du har osparade ändringar som kommer att förloras.',
  },
  discardChanges: {
    id: 'formSubmitMessages.discardChanges',
    defaultMessage: 'Lämna sidan?',
  },
});
