import { ApolloError } from '@apollo/client';
import {
  DeductionType /* PensionDefaultProvider */,
  PensionDefaultProvider,
} from '@frontend/benefit-types';
import {
  CheckboxField,
  CheckboxGroupField,
  DatePickerField,
  Form,
  NumberField,
  RadioGroupField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { info } from '@frontend/ui/icons';
import { toNumber } from '@frontend/utils';
import { ManagingCompany } from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { suffixMessages, validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell25, GridCell33, TextGrid } from 'components/GridCell';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import isAfter from 'date-fns/isAfter';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { ConversionModal } from '../components/ConversionModal';
import { PensionProviderSection } from '../components/PensionProviderSection';
import { PremiumWaiverSection } from '../components/PremiumWaiverSection';
import {
  FormValues as QualifyingIncomeFormValues,
  QualifyingIncomeSection,
  validationSchema as qualifyingIncomeValidationSchema,
} from '../components/QualifyingIncomeSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import {
  CustomPremiumMatrixSection,
  validationSchema as customPremiumMatrixValidationSchema,
} from '../custom-premium-matrix';
import {
  benefitFormMessages,
  benefitPensionDefaultProviderMessages,
  benefitPensionPremiumCalculationMethodMessages,
  benefitPensionPremiumDeductionMessages,
} from '../messages';
import { StaticPremiumMatrixSection } from '../static-premium-matrix';
import {
  Agreements,
  BenefitPackageOption,
  PensionPremiumCalculationMethod,
  PensionProviderOptionValue,
  PremiumMatrixFormValues,
  RemunerationTypes,
} from '../types';
import { getEarliestRetroactiveDate } from '../utils';
import {
  PENSION_DEFAULT_PROVIDER_OPTION_VALUES,
  PREMIUM_DEDUCTIONS_NLP,
  PREMIUM_DEDUCTIONS_NODE,
} from '../utils/constants';

export interface FormValues extends QualifyingIncomeFormValues {
  benefitPackageIds: string[];
  customPremiumMatrix: PremiumMatrixFormValues;
  defaultProvider: PensionDefaultProvider | '';
  effectiveFrom: string;
  extraFixedPremium: boolean;
  isActiveOnParentalLeave: boolean;
  itpLikePremiumMatrix: PremiumMatrixFormValues;
  itpPremiumMatrix: PremiumMatrixFormValues;
  premiumCalculationMethod: PensionPremiumCalculationMethod | '';
  premiumDeductions: DeductionType[];
  premiumWaiver: 'yes' | 'no' | '';
  premiumWaiverConverting: boolean;
  premiumWaiverConvertingFrom: string;
  providers: PensionProviderOptionValue[];
  retirementAge: string;
}

interface Props {
  agreements: Agreements;
  benefitPackageOptions: BenefitPackageOption[];
  managedBy: ManagingCompany;
  premiumCalculationMethodOptions: readonly PensionPremiumCalculationMethod[];
  remunerationTypes: RemunerationTypes;
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape, isEdit?: boolean) =>
  Yup.object()
    .shape({
      premiumWaiverConvertingFrom: Yup.string().when(
        'premiumWaiverConverting',
        {
          is: true,
          then: schema =>
            schema.required(
              intl.formatMessage(validationMessages.mandatoryField),
            ),
        },
      ),
      defaultProvider: Yup.string().when('providers', {
        is: (providers: PensionProviderOptionValue[]) =>
          providers.includes('NORDEA'),
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
      effectiveFrom: Yup.string().when([], {
        is: () => isEdit,
        then: schema =>
          schema
            .required(intl.formatMessage(validationMessages.mandatoryField))
            .test({
              name: 'valid date',
              message: intl.formatMessage(
                validationMessages.dateMaxThreeMonthsOld,
              ),
              test: (value: string) =>
                isAfter(
                  new Date(value),
                  new Date(getEarliestRetroactiveDate()),
                ),
            }),
      }),
      premiumCalculationMethod: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      premiumWaiver: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      providers: Yup.array().min(
        1,
        intl.formatMessage(validationMessages.minOneOptionRequired),
      ),
      retirementAge: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test({
          name: 'at least 55',
          message: intl.formatMessage(benefitFormMessages.minRetirementAge),
          test: (value: string) => (toNumber(value) ?? 0) >= 55,
        }),
    })
    .concat(customPremiumMatrixValidationSchema('customPremiumMatrix', intl))
    .concat(qualifyingIncomeValidationSchema(intl));

export const OccupationalPensionForm: React.FC<Props> = ({
  agreements,
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  managedBy,
  premiumCalculationMethodOptions,
  remunerationTypes,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const { values, isValid, isSubmitting } = useFormikContext<FormValues>();

  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);

  const isManagedByNode = managedBy === 'NODE';

  return (
    <>
      <Form>
        <Section>
          <UpcomingChangesNotification
            latestChangesEffectiveDate={latestChangesEffectiveDate}
          />
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.retirementAge} />
            </SubsectionHeader>
            <Grid>
              <GridCell25>
                <NumberField
                  affix={formatMessage(suffixMessages.age)}
                  allowNegative={false}
                  decimalScale={0}
                  dense
                  gridMargin
                  label={
                    <FormattedMessage {...benefitFormMessages.retirementAge} />
                  }
                  locale={locale}
                  name="retirementAge"
                  required
                />
              </GridCell25>
            </Grid>
          </Subsection>
          {isManagedByNode && (
            <PensionProviderSection agreements={agreements} name="providers" />
          )}
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...benefitFormMessages.premiumCalculationMethod}
              />
            </SubsectionHeader>
            <RadioGroupField
              name="premiumCalculationMethod"
              options={premiumCalculationMethodOptions.map(method => ({
                label: formatMessage({
                  select: method,
                  messages: benefitPensionPremiumCalculationMethodMessages,
                }),
                value: method,
              }))}
              required
            />
          </Subsection>
          {values.premiumCalculationMethod === 'CUSTOM_MATRIX' && (
            <CustomPremiumMatrixSection name="customPremiumMatrix" />
          )}
          {values.premiumCalculationMethod === 'ITP_MATRIX' && (
            <StaticPremiumMatrixSection name="itpPremiumMatrix" />
          )}
          {values.premiumCalculationMethod === 'ITP_LIKE_MATRIX' && (
            <StaticPremiumMatrixSection name="itpLikePremiumMatrix" />
          )}
          {['CUSTOM_MATRIX', 'ITP_MATRIX', 'ITP_LIKE_MATRIX'].includes(
            values.premiumCalculationMethod,
          ) && (
            <>
              <QualifyingIncomeSection remunerationTypes={remunerationTypes} />
              <Subsection>
                <SubsectionHeader>
                  <FormattedMessage
                    {...benefitFormMessages.extraFixedPremium}
                  />
                </SubsectionHeader>
                <TextGrid>
                  <DescriptionWrapper>
                    <FormattedMessage
                      {...benefitFormMessages.extraFixedPremiumDescription}
                    />
                  </DescriptionWrapper>
                </TextGrid>
                <CheckboxField
                  label={
                    <FormattedMessage
                      {...benefitFormMessages.extraFixedPremiumLabel}
                    />
                  }
                  name="extraFixedPremium"
                />
              </Subsection>
            </>
          )}
          {values.providers.includes('NORDEA') && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...benefitFormMessages.defaultManagementType}
                />
              </SubsectionHeader>
              <RadioGroupField
                name="defaultProvider"
                options={PENSION_DEFAULT_PROVIDER_OPTION_VALUES.map(type => ({
                  label: formatMessage({
                    select: type,
                    messages: benefitPensionDefaultProviderMessages,
                  }),
                  value: type,
                }))}
                required
              />
            </Subsection>
          )}
          <PremiumWaiverSection agreements={agreements} name="premiumWaiver" />
          {values.premiumWaiver === 'yes' && (
            <Subsection>
              <SubsectionHeader
                iconButton={
                  <IconButton
                    onClick={() => setIsConversionModalOpen(true)}
                    label={formatMessage(a11yMessages.information)}
                    icon={info}
                    size="small"
                  />
                }
              >
                <FormattedMessage {...benefitFormMessages.convert} />
              </SubsectionHeader>
              <CheckboxField
                name="premiumWaiverConverting"
                label={<FormattedMessage {...benefitFormMessages.convert} />}
              />
              {values.premiumWaiverConverting && (
                <TextGrid>
                  <TextField
                    name="premiumWaiverConvertingFrom"
                    dense
                    label={
                      <FormattedMessage
                        {...benefitFormMessages.conversionFrom}
                      />
                    }
                    required
                    gridMargin
                  />
                </TextGrid>
              )}
            </Subsection>
          )}
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.premiumDeduction} />
            </SubsectionHeader>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.premiumDeductionDescription}
              />
            </DescriptionWrapper>
            <CheckboxGroupField
              name="premiumDeductions"
              options={(isManagedByNode
                ? PREMIUM_DEDUCTIONS_NODE
                : PREMIUM_DEDUCTIONS_NLP
              ).map(deduction => ({
                label: formatMessage({
                  select: deduction,
                  messages: benefitPensionPremiumDeductionMessages,
                }),
                value: deduction,
              }))}
            />
          </Subsection>
          {isManagedByNode && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...benefitFormMessages.isActiveOnParentalLeave}
                />
              </SubsectionHeader>
              <CheckboxField
                name="isActiveOnParentalLeave"
                label={formatMessage(
                  benefitFormMessages.pensionIsActiveOnParentalLeave,
                )}
              />
            </Subsection>
          )}
          <BenefitPackagesSection
            benefitPackageOptions={benefitPackageOptions}
            name="benefitPackageIds"
          />
          {isEdit && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...benefitFormMessages.effectiveEditDate} />
              </SubsectionHeader>
              <TextGrid>
                <DescriptionWrapper>
                  <FormattedMessage
                    {...benefitFormMessages.effectiveEditDateDescription}
                  />
                </DescriptionWrapper>
              </TextGrid>
              <Grid>
                <GridCell33>
                  <DatePickerField
                    dense
                    gridMargin
                    label={
                      <FormattedMessage
                        {...benefitFormMessages.effectiveEditDateLabel}
                      />
                    }
                    min={getEarliestRetroactiveDate()}
                    name="effectiveFrom"
                    required
                    type="month"
                  />
                </GridCell33>
              </Grid>
            </Subsection>
          )}
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={!isValid}
            >
              <FormattedMessage {...benefitFormMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <ConversionModal
        isOpen={isConversionModalOpen}
        setIsOpen={setIsConversionModalOpen}
      />
    </>
  );
};
