import { Formik } from '@frontend/formik';
import {
  smeCompanyEditLifeQuery,
  smeCompanyEditLifeQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { getAgreementTypeForCompanySize } from '../../create/life/utils/get-agreement-type-for-company-size';
import { FormValues, LifeForm, validationSchema } from '../../form/life';
import { SME_COMPANY_EDIT_LIFE_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

interface Config {
  __typename?: string;
}

interface LifeConfig {
  __typename: 'ProposalLifeBenefitConfig';
}

export const isLifeConfig = (config: Config): config is LifeConfig =>
  config.__typename === 'ProposalLifeBenefitConfig';

export const EditLife: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<BenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyEditLifeQuery,
    smeCompanyEditLifeQueryVariables
  >(SME_COMPANY_EDIT_LIFE_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefit) {
    return <EmptyState error={error} />;
  }

  const { benefit, benefitPackages, companySize } = data.proposal;

  if (!benefit?.configuration || !isLifeConfig(benefit.configuration)) {
    return null;
  }

  const initialValues: FormValues = {
    // The call to getAgreementTypeForCompanySize only here for backwards compatibility.
    // The agreementType should be correctly configured on all created benefits, but if it's not,
    // we'll default to the agreementType for the company size.
    agreementType:
      benefit.configuration.agreementType ||
      getAgreementTypeForCompanySize({ companySize }),
    benefitPackageIds: benefit.benefitPackages.map(({ id }) => id),
    level: benefit.configuration.lifeLevel || '',
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting }) => (
        <LifeForm
          companySize={companySize}
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
        />
      )}
    </Formik>
  );
};
