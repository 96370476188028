import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const INVOICE_LINES_QUERY = gql`
  query invoiceLinesQuery(
    $companyId: String!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $userAccountIds: [String]
    $accountingCosts: [String!]
    $suppliers: [InvoiceSupplier!]
    $taxClass: TaxClass
    $pensionLayer: PensionLayer
    $linePeriod: LineDateFilter
    $issueDate: DateFilter
    $dueDate: DateFilter
    $invoiceLineItemNames: [String!]
    $benefitPackageId: String
  ) {
    invoiceSearch(
      companyId: $companyId
      linesFirst: $first
      linesLast: $last
      linesAfter: $after
      linesBefore: $before
      userAccountId: $userAccountIds
      accountingCost: $accountingCosts
      supplier: $suppliers
      taxClass: $taxClass
      pensionLayer: $pensionLayer
      linePeriod: $linePeriod
      issueDate: $issueDate
      dueDate: $dueDate
      invoiceLineItemNames: $invoiceLineItemNames
      benefitPackageId: $benefitPackageId
    ) {
      invoiceLines {
        edges {
          node {
            id
            name
            firstName
            lastName
            invoicedQuantity
            personalIdentityNumber
            invoicePeriod {
              startDate
              endDate
            }
            invoice {
              id
              supplier
            }
            priceWithVat
            lineExtensionCurrency
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
        totalCount
      }
      excelUrl: url(format: XLSX)
      csvUrl: url(format: CSV)
    }
  }
  ${pageInfoDetails}
`;
