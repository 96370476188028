import {
  SideSheetContentContainer,
  SideSheetSection,
  SideSheetSubtitle,
} from 'components/SideSheet';
import React from 'react';

type Header =
  | { header: React.ReactNode; id?: string }
  | { header?: never; id?: never };

type FilterSectionProps = {
  children: React.ReactNode;
} & Header;

export const FilterSection: React.FC<FilterSectionProps> = ({
  header,
  children,
  id,
}) => (
  <SideSheetSection>
    {header && <SideSheetSubtitle id={id}>{header}</SideSheetSubtitle>}
    <SideSheetContentContainer>{children}</SideSheetContentContainer>
  </SideSheetSection>
);
