import { Formik } from '@frontend/formik';
import {
  companyEditEpassiBikeBenefitQuery,
  companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration as Configuration,
  companyEditEpassiBikeBenefitQuery_flexBenefit_FlexBenefit_configuration_EpassiBikeConfiguration as EpassiBikeConfiguration,
  companyEditEpassiBikeBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  EpassiBikeForm,
  FormValues,
  PolicyType,
  validationSchema,
} from '../../form/epassi-bike';
import { getBenefitPackageOptions } from '../../form/utils';
import { COMPANY_EDIT_EPASSI_BIKE_BENEFIT_QUERY } from './queries';
import { useSubmit } from './utils/use-submit';

const isEpassiBikeConfiguration = (
  configuration: Configuration | null,
): configuration is EpassiBikeConfiguration =>
  configuration?.__typename === 'EpassiBikeConfiguration';

export const EditEpassiBike: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditEpassiBikeBenefitQuery,
    companyEditEpassiBikeBenefitQueryVariables
  >(COMPANY_EDIT_EPASSI_BIKE_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (
    !data?.company?.benefitPackages ||
    !data.flexBenefit?.configuration ||
    !data.flexBenefit?.entitlementRules
  ) {
    return <EmptyState error={error} />;
  }

  const benefit = data.flexBenefit;

  const { benefitPackages } = data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'epassi_bike',
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, configuration, entitlementRules } = benefit;

  if (!isEpassiBikeConfiguration(configuration) || !entitlementRules) {
    return null;
  }

  const initialValues: FormValues = {
    customerId: configuration.customerId ?? '',
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    policyType: configuration.useDefaultPolicy
      ? PolicyType.EPASSI_DEFAULT_POLICY
      : PolicyType.CUSTOM_COMPANY_POLICY,
    effectiveFrom: '',
    files: [
      ...(configuration.files?.map(file => ({
        // TODO: Use the ID from the API when it's exposed.
        // This is required for enabling editing the benefit.
        // Until the following ticket is completed, we keep
        // the submission disabled.
        // Issue: https://nordeanode.atlassian.net/browse/COM-1866
        id: file.url,
        name: file.name,
        url: file.url,
      })) ?? []),
    ],
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <EpassiBikeForm
        benefitPackageOptions={benefitPackageOptions}
        companyId={params.companyId}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        submissionError={submissionError}
      />
    </Formik>
  );
};
