import { ProposalCompanySize } from 'app/apollo/graphql/types';

interface Proposal {
  companySize: ProposalCompanySize;
}

/**
 * Computes if the proposal should allow creation of life insurance benefits
 *
 * @returns true iff the proposal allows creation of life insurance benefits, otherwise false
 */
export const hasLifeBenefitAccess = <T extends Proposal>(
  proposal?: T | null,
): boolean => !!proposal && proposal.companySize !== ProposalCompanySize.LT_10;
