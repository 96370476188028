import { EventTypeCategory } from 'app/apollo/graphql/types';
import { MeetingStatisticsKey } from 'features/advisor/meeting-statistics';
import { PtStatisticsKey } from 'features/advisor/pt-statistics';
import { defineMessages } from 'react-intl';

export const advisorMessages = defineMessages({
  searchEntitledEmployee: {
    id: 'advisor.searchEntitledEmployee',
    defaultMessage: 'Sök berättigad anställd',
  },
  searchMeeting: {
    id: 'advisor.searchMeeting',
    defaultMessage: 'Sök efter möte',
  },
  advisor: {
    id: 'advisor.advisor',
    defaultMessage: 'Rådgivare',
  },
  employees: {
    id: 'employees.employees',
    defaultMessage: 'Anställda',
  },
  noEmployeeMatch: {
    id: 'employees.noEmployeeMatch',
    defaultMessage: 'Ingen anställd hittades',
  },
  noMeetingMatch: {
    id: 'employees.noMeetingMatch',
    defaultMessage: 'Inget möte hittades',
  },
  searchStringTooShort: {
    id: 'employees.searchStringTooShort',
    defaultMessage: 'Sök med minst 3 tecken...',
  },
  sendInvitation: {
    id: 'advisor.sendInvitation',
    defaultMessage: 'Skicka inbjudan',
  },
  sendInvitations: {
    id: 'advisor.sendInvitations',
    defaultMessage: 'Skicka inbjudningar',
  },
  sendInvitationsTo: {
    id: 'advisor.sendInvitationsTo',
    defaultMessage:
      'Skicka {count, plural, =0 {inbjudan till anställd} =1 {inbjudan till anställd} other {inbjudningar till # anställda}}',
  },
  addHeldMeeting: {
    id: 'advisor.addHeldMeeting',
    defaultMessage: 'Lägg till avklarat möte',
  },
  meetingHistory: {
    id: 'advisor.meetingHistory',
    defaultMessage: 'Möteshistorik',
  },
  lastMeeting: {
    id: 'advisor.lastMeeting',
    defaultMessage: 'Senaste möte',
  },
  entitledToNewMeeting: {
    id: 'advisor.entitledToNewMeeting',
    defaultMessage: 'Berättigad till nytt möte',
  },
  clearFilter: {
    id: 'advisor.clearFilter',
    defaultMessage: 'Rensa filter',
  },
  allFilters: {
    id: 'advisor.allFilters',
    defaultMessage: 'Alla filter',
  },
  allowedToHaveMeeting: {
    id: 'advisor.allowedToHaveMeeting',
    defaultMessage: 'Ja',
  },
  notAllowedToHaveMeeting: {
    id: 'advisor.notAllowedToHaveMeeting',
    defaultMessage: 'Nej',
  },
  invitationsSent: {
    id: 'advisor.invitationsSent',
    defaultMessage: 'Inbjudningar skickade!',
  },
  confirmSendInvitations: {
    id: 'advisor.confirmSendInvitations',
    defaultMessage:
      'Är du säker på att du vill skicka {count, plural, =1 {en inbjudan} other {inbjudningar}} till {count, plural, =1 {en anställd} other {# anställda}}?',
  },
  heldMeetingAdded: {
    id: 'advisor.heldMeetingAdded',
    defaultMessage: 'Avklarat möte tillagt!',
  },
  addHeldMeetingFailed: {
    id: 'advisor.addHeldMeetingFailed',
    defaultMessage: 'Avklarat möte kunde inte läggas till: {error}',
  },
  filters: {
    id: 'advisor.filters',
    defaultMessage: 'Filter',
  },
  excludeMeetingType: {
    id: 'advisor.excludeMeetingType',
    defaultMessage: 'Exkludera mötestyp',
  },
  period: {
    id: 'advisor.period',
    defaultMessage: 'Period',
  },
  chooseCompany: {
    id: 'advisor.chooseCompany',
    defaultMessage: 'Välj företag',
  },
  chooseBenefitPackage: {
    id: 'advisor.chooseBenefitPackage',
    defaultMessage: 'Välj förmånsgrupp',
  },
  chooseAdvisor: {
    id: 'advisor.chooseAdvisor',
    defaultMessage: 'Välj rådgivare',
  },
  personalAdvice: {
    id: 'advisor.personalAdvice',
    defaultMessage: 'Personlig rådgivning',
  },
  personalAdviceMeeting: {
    id: 'advisor.personalAdviceMeeting',
    defaultMessage: 'Personligt rådgivningsmöte',
  },
  pensionTransferMeeting: {
    id: 'advisor.pensionTransferMeeting',
    defaultMessage: 'Pensionsöverföringsmöte',
  },
  loadingFilters: {
    id: 'advisor.loadingFilters',
    defaultMessage: 'Laddar filter...',
  },
  eventType: {
    id: 'advisor.eventType',
    defaultMessage: 'Eventtyp',
  },
  category: {
    id: 'advisor.category',
    defaultMessage: 'Kategori',
  },
  latestEmailSentAt: {
    id: 'advisor.latestEmailSentAt',
    defaultMessage: 'Senast e-post skickades',
  },
  meetingStatistics: {
    id: 'advisor.meetingStatistics',
    defaultMessage: 'Mötesstatistik',
  },
  meetingType: {
    id: 'advisor.meetingType',
    defaultMessage: 'Mötestyp',
  },
  chooseCompanies: {
    id: 'advisor.chooseCompanies',
    defaultMessage: 'Välj företag',
  },
  noTransferredInsurances: {
    id: 'advisor.noTransferredInsurances',
    defaultMessage: 'Det finns inga flyttade försäkringar för datumet',
  },
  transferredInsurancesDesc: {
    id: 'advisor.transferredInsurancesDesc',
    defaultMessage: 'Följande försäkringar flyttades på samma datum:',
  },
  transferredInsuranceCheck: {
    id: 'advisor.transferredInsuranceCheck',
    defaultMessage:
      '<check>{insuranceNumber} - {capital, number, ::currency/SEK .}</check>',
  },
  transferAdvice: {
    id: 'advisor.transferAdvice',
    defaultMessage: 'Flyttrådgivning',
  },
  pensionTransfer: {
    id: 'advisor.pensionTransfer',
    defaultMessage: 'Pension transfer',
  },
  transferableCapital: {
    id: 'advisor.transferableCapital',
    defaultMessage: 'Flyttbart kapital',
  },
  minCapital: {
    id: 'advisor.minCapital',
    defaultMessage: 'Minimikapital',
  },
  maxCapital: {
    id: 'advisor.maxCapital',
    defaultMessage: 'Maxkapital',
  },
  capital: {
    id: 'advisor.capital',
    defaultMessage: 'Kapital',
  },
  excludeBrokeredByNode: {
    id: 'advisor.brokeredByNode',
    defaultMessage: 'Exkludera förmedlade av Nordea Node',
  },
  deleteMeeting: {
    id: 'advisor.deleteMeeting',
    defaultMessage: 'Ta bort möte',
  },
  deleteMeetingConfirmation: {
    id: 'advisor.deleteMeetingConfirmation',
    defaultMessage: 'Är du säker på att du vill ta bort mötet?',
  },
  meetingDeleted: {
    id: 'advisor.meetingDeleted',
    defaultMessage: 'Möte borttaget.',
  },
  cancelMeeting: {
    id: 'advisor.cancelMeeting',
    defaultMessage: 'Avboka möte',
  },
  cancelMeetingConfirmation: {
    id: 'advisor.cancelMeetingConfirmation',
    defaultMessage: 'Är du säker på att du vill avboka mötet?',
  },
  meetingCancelled: {
    id: 'advisor.meetingCancelled',
    defaultMessage: 'Möte avbokat.',
  },
  ptStatistics: {
    id: 'advisor.ptStatistics',
    defaultMessage: 'PT-statistik',
  },
  transferredBy: {
    id: 'advisor.transferredBy',
    defaultMessage: 'Flyttat av {advisorName}',
  },
  followUpMeeting: {
    id: 'advisor.followUpMeeting',
    defaultMessage: 'Uppföljningsmöte',
  },
  salary: {
    id: 'advisor.salary',
    defaultMessage: 'Lön',
  },
  minSalary: {
    id: 'advisor.minSalary',
    defaultMessage: 'Minimilön',
  },
  maxSalary: {
    id: 'advisor.maxSalary',
    defaultMessage: 'Maxlön',
  },
  minBirthdate: {
    id: 'advisor.minBirthdate',
    defaultMessage: 'Minimifödelsedatum',
  },
  maxBirthdate: {
    id: 'advisor.maxBirthdate',
    defaultMessage: 'Maxfödelsedatum',
  },
});

export const ptStatisticsMessages = defineMessages<PtStatisticsKey>({
  totalTransferredCapital: {
    id: 'advisor.totalTransferredCapital',
    defaultMessage: 'Totalt flyttat kapital',
  },
  transferredCapitalToNordea: {
    id: 'advisor.transferredCapitalToNordea',
    defaultMessage: 'Kapital flyttat till Nordea',
  },
  transferredCapitalToNordnet: {
    id: 'advisor.transferredCapitalToNordnet',
    defaultMessage: 'Kapital flyttat till Nordnet',
  },
});

export const eventTypeCategoryMessages = defineMessages<EventTypeCategory>({
  [EventTypeCategory.PENSION_TRANSFER]: {
    id: 'messages.PENSION_TRANSFER',
    defaultMessage: 'Pension Transfer',
  },
  [EventTypeCategory.PERSONAL_ADVICE]: {
    id: 'messages.PERSONAL_ADVICE',
    defaultMessage: 'Personlig Rådgivning',
  },
  [EventTypeCategory.FOLLOW_UP]: {
    id: 'messages.FOLLOW_UP',
    defaultMessage: 'Uppföljningsmöte',
  },
});

export const meetingStatisticsMessages = defineMessages<MeetingStatisticsKey>({
  numberOfInvitationsSent: {
    id: 'advisor.numberOfInvitationsSent',
    defaultMessage: 'Antal inbjudningar skickade',
  },
  numberOfFirstRemindersSent: {
    id: 'advisor.numberOfFirstRemindersSent',
    defaultMessage: 'Antal första påminnelser skickade',
  },
  numberOfSecondRemindersSent: {
    id: 'advisor.numberOfSecondRemindersSent',
    defaultMessage: 'Antal andra påminnelser skickade',
  },
  numberOfCreatedBookings: {
    id: 'advisor.numberOfCreatedBookings',
    defaultMessage:
      '{hasPeriodFilter, select, true {Antal bokningar som skett under perioden} other {Antal skapade bokningar}}',
  },
  numberOfBookedMeetings: {
    id: 'advisor.numberOfBookedMeetings',
    defaultMessage:
      '{hasPeriodFilter, select, true {Möten som infaller under perioden} other {Antal bokade möten}}',
  },
  numberOfCancelledMeetings: {
    id: 'advisor.numberOfCancelledMeetings',
    defaultMessage: 'Antal avbokade möten',
  },
  numberOfConfirmedMeetings: {
    id: 'advisor.numberOfConfirmedMeetings',
    defaultMessage: 'Antal bekräftade möten',
  },
});
