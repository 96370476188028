import {
  AccidentLevel,
  BaseAmountType,
  DeductionType,
  DisabilityLevel,
  HealthLevel,
  LifeLevel,
  PensionDefaultProvider,
  PersonalAdvicePaymentMethod,
  SicknessAccidentLevel,
} from '@frontend/benefit-types';
import { defineMessages } from 'react-intl';

import {
  PensionPremiumCalculationMethod,
  PersonalAdviceMeetingFrequencyOptionValue,
} from '../types';

export const benefitFormMessages = defineMessages({
  ageIntervals: {
    id: 'benefitFormMessages.ageIntervals',
    defaultMessage: 'Åldersintervall',
  },
  allowance: {
    id: 'benefitFormMessages.allowance',
    defaultMessage: 'Årligt friskvårdsbidrag',
  },
  allowanceLabel: {
    id: 'benefitFormMessages.allowanceLabel',
    defaultMessage: 'Friskvårdsbidrag per år',
  },
  automaticApproval: {
    id: 'benefitFormMessages.automaticApproval',
    defaultMessage: 'Godkänn ansökningar automatiskt',
  },
  automaticApprovalTitle: {
    id: 'benefitFormMessages.automaticApprovalTitle',
    defaultMessage: 'Hantera ansökningar automatiskt',
  },
  automaticApprovalDescription: {
    id: 'benefitFormMessages.automaticApprovalDescription',
    defaultMessage:
      'Ansökningar om löneväxling kan godkännas automatiskt om de faller inom arbetsgivarens policy. Utan automatiska godkännanden måste varje ansökan behandlas av en behörig administratör. Automatiska godkännanden innebär att avtal om löneavdrag och extra pensionsinbetalningar träder i kraft så snart en anställd lämnat in en godkänd ansökan.',
  },
  baseAmountIntervals: {
    id: 'benefitFormMessages.baseAmountIntervals',
    defaultMessage: 'Basbeloppsintervall',
  },
  benefitPackages: {
    id: 'benefitFormMessages.benefitPackages',
    defaultMessage: 'Förmånsgrupper',
  },
  benefitPackagesDescription: {
    id: 'benefitFormMessages.benefitPackagesDescription',
    defaultMessage:
      'Välj vilken eller vilka förmånsgrupper som denna produkt ska ingå i.',
  },
  benefitPackagesWellnessDescription: {
    id: 'benefitFormMessages.benefitPackagesWellnessDescription',
    defaultMessage:
      'För att friskvård ska räknas som en personalvårdsförmån och därmed vara skattefri för anställda ska den riktas till hela personalen. Läs mer om <rulesHref>regler för friskvård hos Skatteverket</rulesHref>.',
  },
  benefitQualifyingIncome: {
    id: 'benefitFormMessages.benefitQualifyingIncome',
    defaultMessage: 'Förmånsgrundande löneart',
  },
  benefitQualifyingIncomeDescription: {
    id: 'benefitFormMessages.benefitQualifyingIncomeDescription',
    defaultMessage:
      'Välj vilken löneart som ska vara förmånsgrundande för denna förmån. Lönearten måste räknas om till en årslön. För att ta hänsyn till semestertillägg på samma sätt som ITP 2, använd 12,2 som årslönefaktor. Om den förmånsgrundande lönearten redan är en årslön, använd 1,00 som årslönefaktor.',
  },
  benefitSaved: {
    id: 'benefitFormMessages.benefitSaved',
    defaultMessage: 'Förmån sparad',
  },
  collectum: {
    id: 'benefitFormMessages.collectum',
    defaultMessage: 'Kollektivavtal ITP',
  },
  companySizeLife: {
    id: 'benefitFormMessages.companySizeLife',
    defaultMessage:
      'Livförsäkring (obligatorisk) får ej tecknas utan godkännande från Euro Accident om antalet försäkrade understiger 100 personer',
  },
  configurePremiumIntervals: {
    id: 'benefitFormMessages.configurePremiumIntervals',
    defaultMessage: 'Konfigurera intervall',
  },
  configurePremiumIntervalsDescription: {
    id: 'benefitFormMessages.configurePremiumIntervalsDescription',
    defaultMessage:
      'Premietrappan anger procentuell avsättning av lön per ålders- och basbeloppsintervall. Ett intervall gäller från och med det lägre värdet upp till nästa värde.',
  },
  convert: {
    id: 'benefitFormMessages.convert',
    defaultMessage: 'Nyteckna försäkring med konvertering',
  },
  conversion: {
    id: 'benefitFormMessages.conversion',
    defaultMessage: 'Konvertering',
  },
  conversionExplanation: {
    id: 'benefitFormMessages.conversionExplanation',
    defaultMessage: `
    <p>Konvertering gäller enbart om det är till samma eller lägre ersättningsbelopp.</p>
    <ul>
      <li>Vid konvertering av sjukvårdsförsäkring sker konvertering från 5 personer vid obligatorisk grupp (hela gruppen ska konverteras).</li>
      <li>Vid konvertering av sjukförsäkring och premiebefrielse sker konvertering från 1 person vid obligatorisk grupp (hela gruppen ska konverteras).</li>
      <li>Mer information finns på intranät.</li>
    </ul>
    `,
  },
  conversionFrom: {
    id: 'benefitFormMessages.conversionFrom',
    defaultMessage: 'Konvertering sker från följande bolag',
  },
  customerId: {
    id: 'benefitFormMessages.customerId',
    defaultMessage: 'Epassi kund-ID',
  },
  customerIdLabel: {
    id: 'benefitFormMessages.customerIdLabel',
    defaultMessage: 'Kund-ID',
  },
  defaultManagementType: {
    id: 'benefitFormMessages.defaultManagementType',
    defaultMessage: 'Ange förvaltningsform',
  },
  editPremiumIntervals: {
    id: 'benefitFormMessages.editPremiumIntervals',
    defaultMessage: 'Ändra premieintervaller',
  },
  effectiveEditDate: {
    id: 'benefitFormMessages.effectiveEditDate',
    defaultMessage: 'Välj ändringsdatum',
  },
  effectiveEditDateDescription: {
    id: 'pensionBenefitMessages.effectiveEditDateDescription',
    defaultMessage:
      'Ange den månad från vilken angivna ändringarna ska börja gälla. Ändringar blir verkställda från den 1:a i vald månad. Observera att om du anger ett historiskt datum kommer premier och/eller försäkringsskydd att ändras retroaktivt.',
  },
  effectiveEditDateLabel: {
    id: 'benefitFormMessages.effectiveEditDateLabel',
    defaultMessage: 'Ändringsdatum',
  },
  effectiveStartDate: {
    id: 'benefitFormMessages.effectiveStartDate',
    defaultMessage: 'Välj startdatum',
  },
  effectiveStartDateDescription: {
    id: 'benefitFormMessages.effectiveStartDateDescription',
    defaultMessage:
      'Ange den månad från vilken förmånen ska börja gälla. Startdatum är alltid den 1:a i vald månad. Observera att om du anger ett historiskt datum kommer premier och/eller försäkringsskydd att startas retroaktivt. Du kan välja ett startdatum upp till tre månader bakåt i tiden.',
  },
  effectiveStartDateLabel: {
    id: 'benefitFormMessages.effectiveStartDateLabel',
    defaultMessage: 'Startdatum',
  },
  employmentStartFreePeriod: {
    id: 'benefitFormMessages.employmentStartFreePeriod',
    defaultMessage:
      'Nya medarbetare har möjlighet att boka möte utan kostnad under 6 månader från anställningsstart',
  },
  epassiAllowanceDescription: {
    id: 'benefitFormMessages.epassiAllowanceDescription',
    defaultMessage:
      'Ange det årliga friskvårdsbidrag som är bestämt i avtalet med Epassi. Noterat att om siffran angiven här skiljer sig från den i avtalet med Epassi så är det det bidraget som är angett i avtalet som gäller.',
  },
  epassiBikePolicy: {
    id: 'benefitFormMessages.epassiBikePolicy',
    defaultMessage: 'Policy/villkor',
  },
  epassiBikePolicyDescription: {
    id: 'benefitFormMessages.epassiBikePolicyDescription',
    defaultMessage:
      'Företaget kan utgå från <policyHref>Epassis standardpolicy</policyHref> eller skapa sin egen policy/villkor som gäller för sina anställda. Vid val av egen policy/villkor bifogas dessa nedan och den anställda kommer behöva bekräfta att den tagit del av de bifogade dokumenten för att ha rätt till sin personliga kod.',
  },
  epassiBikeStandardPolicy: {
    id: 'benefitFormMessages.epassiBikeStandardPolicy',
    defaultMessage: 'Epassis standardpolicy',
  },
  epassiBikeCustomCompanyPolicy: {
    id: 'benefitFormMessages.epassiBikeCustomCompanyPolicy',
    defaultMessage: 'Egen policy',
  },
  extendedSickPay: {
    id: 'benefitFormMessages.extendedSickPay',
    defaultMessage: 'Utökad sjuklön',
  },
  extendedSickPayLabel: {
    id: 'benefitFormMessages.extendedSickPayLabel',
    defaultMessage: 'Medarbetare får utökad sjuklön enligt ITP',
  },
  extraFixedPremium: {
    id: 'benefitFormMessages.extraFixedPremium',
    defaultMessage: 'Fast extra premie',
  },
  extraFixedPremiumDescription: {
    id: 'benefitFormMessages.extraFixedPremiumDescription',
    defaultMessage:
      'Välj om företagets anställda ska ha en fast extra premie utöver den premietrappa som valts ovan. Den extra premien anges på respektive anställd senare.',
  },
  extraFixedPremiumLabel: {
    id: 'benefitFormMessages.extraFixedPremiumLabel',
    defaultMessage:
      'Företagets anställda ska ha en fast extra premie utöver ovan premietrappa.',
  },
  freeAdviceAtLaunch: {
    id: 'benefitFormMessages.freeAdviceAtLaunch',
    defaultMessage: 'Gratis möte vid lansering',
  },
  incomeMinIbb: {
    id: 'benefitFormMessages.incomeMinIbb',
    defaultMessage: 'Lägsta årslön efter bruttolöneavdrag',
  },
  incomeMinIbbTitle: {
    id: 'benefitFormMessages.incomeMinIbbTitle',
    defaultMessage: 'Allmänna pensionsavsättningar',
  },
  incomeMinIbbDescription: {
    id: 'benefitFormMessages.incomeMinIbbDescription',
    defaultMessage:
      'Bruttolöneavdrag kan påverka anställdas pensionsgrundande inkomst (PGI). Om årsinkomsten blir lägre än {incomeLimitIbb, number} inkomstbasbelopp (IBB), {yearlyThreshold, number, ::currency/SEK .} för {year}, minskar avsättningarna till allmän pension. En anställd kan aldrig välja ett löneväxlingsbelopp som gör att den rapporterade månadslönen minus bruttolöneavdraget blir lägre än {monthlyThreshold, number, ::currency/SEK .} ({yearlyThreshold, number, ::currency/SEK .} / 12) om ni väljer att ha golvet vid {incomeLimitIbb, number}. Notera att det är på årsinkomsten som den allmänna pensionen baseras på, viktigt att tänka på vid exempelvis föräldraledighet eller löneförändringar som kan påverka den totala årsinkomsten.',
  },
  isActiveOnParentalLeave: {
    id: 'benefitFormMessages.isActiveOnParentalLeave',
    defaultMessage: 'Föräldraledighet',
  },
  level: {
    id: 'benefitFormMessages.level',
    defaultMessage: 'Välj nivå',
  },
  meetingFrequencyMonths: {
    id: 'benefitFormMessages.meetingFrequencyMonthsTitle',
    defaultMessage: 'Mötesfrekvens',
  },
  meetingFrequencyMonthsDescription: {
    id: 'benefitFormMessages.meetingFrequencyMonthsDescription',
    defaultMessage: 'Antal månader innan möjlighet ges att boka nytt möte',
  },
  meetingFrequencyMonthsLabel: {
    id: 'benefitFormMessages.meetingFrequencyMonthsLabel',
    defaultMessage: 'Begränsning',
  },
  missingAccidentAgreement: {
    id: 'benefitFormMessages.missingAccidentAgreement',
    defaultMessage:
      'För att teckna olycksfallsförsäkring behöver företaget teckna avtalet "Euro Accident Gruppavtal"',
  },
  missingDisabilityAgreement: {
    id: 'benefitFormMessages.missingDisabilityAgreement',
    defaultMessage:
      'För att teckna sjukförsäkring behöver företaget teckna avtalet "Euro Accident Tjänstepensionsförsäkringsavtal"',
  },
  missingHealthcareAgreement: {
    id: 'benefitFormMessages.missingHealthcareAgreement',
    defaultMessage:
      'För att teckna sjukvårdsförsäkring behöver företaget teckna avtalet "Euro Accident Gruppavtal"',
  },
  missingLifeAgreement: {
    id: 'benefitFormMessages.missingLifeAgreement',
    defaultMessage:
      'För att teckna livförsäkring behöver företaget teckna avtalet "Euro Accident Gruppavtal"',
  },
  missingPremiumWaiverAgreement: {
    id: 'benefitFormMessages.missingPremiumWaiverAgreement',
    defaultMessage:
      'För att teckna premiebefrielseförsäkring behöver ert företag teckna avtalet "Euro Accident Tjänstepensionsförsäkringsavtal"',
  },
  missingProviderAgreement: {
    id: 'benefitFormMessages.missingProviderAgreement',
    defaultMessage:
      'Företaget saknar avtal med någon av de valbara leverantörerna',
  },
  missingSicknessAndAccidentAgreement: {
    id: 'benefitFormMessages.missingSicknessAndAccidentAgreement',
    defaultMessage:
      'För att teckna sjuk- och olycksfallsförsäkring behöver företaget teckna avtalet "Euro Accident Gruppavtal"',
  },
  missingTglAgreement: {
    id: 'benefitFormMessages.missingTglAgreement',
    defaultMessage:
      'För att teckna tjänstegrupplivförsäkring behöver företaget teckna avtalet "Euro Accident Tjänstepensionsförsäkringsavtal"',
  },
  minRetirementAge: {
    id: 'benefitFormMessages.minRetirementAge',
    defaultMessage: 'Lägsta giltiga pensionsålder är 55 år',
  },
  noPensionProviders: {
    id: 'benefitFormMessages.noPensionProviders',
    defaultMessage: 'Det finns inga pensionsleverantörer',
  },
  onboadingFreePeriod: {
    id: 'benefitFormMessages.onboadingFreePeriod',
    defaultMessage:
      'Medarbetare har möjlighet att boka möte utan kostnad under 6 månader från lansering',
  },
  onboardingFreePeriodStartDate: {
    id: 'benefitFormMessages.onboardingFreePeriodStartDate',
    defaultMessage: 'Lanseringsdatum',
  },
  ongoingMax: {
    id: 'benefitFormMessages.ongoingMax',
    defaultMessage: 'Högsta tillåtna bruttolöneavdrag',
  },
  ongoingMaxDescription: {
    id: 'benefitFormMessages.ongoingMaxDescription',
    defaultMessage:
      'Högsta tillåtna bruttolöneavdrag kan anges i kronor och i procent av bruttolön. Den anställda kan aldrig välja ett högre avdrag än rapporterad bruttolön. Det lägsta bruttolöneavdrag som en anställd kan göra är 1000 kr.',
  },
  ongoingMaxSek: {
    id: 'benefitFormMessages.ongoingMaxSek',
    defaultMessage: 'Högsta bruttolöneavdrag i kronor',
  },
  ongoingMaxShare: {
    id: 'benefitFormMessages.ongoingMaxShare',
    defaultMessage: 'Högsta bruttolöneavdrag i procent',
  },
  paymentMethod: {
    id: 'benefitFormMessages.paymentMethod',
    defaultMessage: 'Betalningsmetod',
  },
  payrollElementCode: {
    id: 'benefitFormMessages.payrollElementCode',
    defaultMessage: 'Löneart',
  },
  payrollElementMultiplier: {
    id: 'benefitFormMessages.payrollElementMultiplier',
    defaultMessage: 'Årslönefaktor',
  },
  pensionIsActiveOnParentalLeave: {
    id: 'benefitFormMessages.pensionIsActiveOnParentalLeave',
    defaultMessage:
      'Pensionsförmånen ska vara aktiv för anställda under föräldraledighet',
  },
  pensionTaxBenefitShare: {
    id: 'benefitFormMessages.pensionTaxBenefitShare',
    defaultMessage: 'Andel av skattefördelen',
  },
  pensionTaxBenefitShareTitle: {
    id: 'benefitFormMessages.pensionTaxBenefitShareTitle',
    defaultMessage: 'Fördelning av skatteeffekten',
  },
  pensionTaxBenefitShareDescription: {
    id: 'benefitFormMessages.pensionTaxBenefitShareDescription',
    defaultMessage:
      'Pensionsavsättningar belastas endast med 24,26 % i särskild löneskatt jämfört med 31,42 % i arbetsgivaravgifter på lön. Skillnaden på 5,76 % kan tilldelas den anställda i form av en högre premie eller behållas av företaget. Här väljer ni hur stor del av denna effekt som tillfaller den anställde.',
  },
  pensionTaxBenefitShareHelperText: {
    id: 'benefitFormMessages.pensionTaxBenefitShareHelperText',
    defaultMessage: '= {share, number, ::percent .00} högre premie',
  },
  premiumCalculationMethod: {
    id: 'benefitFormMessages.premiumCalculationMethod',
    defaultMessage: 'Premieberäkningsmetod',
  },
  premiumDeduction: {
    id: 'benefitFormMessages.premiumDeduction',
    defaultMessage: 'Inom premieutrymme',
  },
  premiumDeductionDescription: {
    id: 'benefitFormMessages.premiumDeductionDescription',
    defaultMessage:
      'Ange om någon av nedanstående produkter ska finansieras inom premieutrymmet',
  },
  premiumMatrix: {
    id: 'benefitFormMessages.premiumMatrix',
    defaultMessage: 'Premietrappa',
  },
  premiumWaiver: {
    id: 'benefitFormMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielse',
  },
  providers: {
    id: 'benefitFormMessages.providers',
    defaultMessage: 'Valbara leverantörer',
  },
  providersDescription: {
    id: 'benefitFormMessages.providersDescription',
    defaultMessage:
      'Välj vilka försäkringsleverantörer som ska vara tillgängliga för anställda.',
  },
  requiresBusinessCouncilDecision: {
    id: 'benefitFormMessages.requiresBusinessCouncilDecision',
    defaultMessage: 'Kräver affärsrådsbeslut',
  },
  retirementAge: {
    id: 'benefitFormMessages.retirementAge',
    defaultMessage: 'Pensionsålder',
  },
  save: {
    id: 'benefitFormMessages.save',
    defaultMessage: 'Spara förmån',
  },
  selectFiles: {
    id: 'benefitFormMessages.selectFiles',
    defaultMessage: 'Välj filer',
  },
  upcomingChanges: {
    id: 'benefitFormMessages.upcomingChanges',
    defaultMessage: 'Kommande ändringar',
  },
  upcomingChangesDescription: {
    id: 'benefitFormMessages.upcomingChangesDescription',
    defaultMessage:
      'Förmånen har sparade konfigurationsändringar. Sidan nedan visar konfiguration som kommer börja gälla fr.o.m {configEffectiveDate, date, ::MMMMyyyy}',
  },
  waitingPeriod: {
    id: 'benefitFormMessages.waitingPeriod',
    defaultMessage: 'Karens',
  },
  waitingPeriodYes: {
    id: 'benefitFormMessages.waitingPeriodYes',
    defaultMessage: 'Med karens',
  },
  waitingPeriodNo: {
    id: 'benefitFormMessages.waitingPeriodNo',
    defaultMessage: 'Utan karens',
  },
  wellnessAllowanceDescription: {
    id: 'benefitFormMessages.wellnessAllowanceDescription',
    defaultMessage:
      'Om företaget har ett årligt friskvårdsbidrag för alla medarbetare kan det visas upp i portalen.',
  },
});

export const benefitPensionBaseAmountTypeMessages =
  defineMessages<BaseAmountType>({
    IBB: {
      id: 'benefitPensionBaseAmountTypeMessages.IBB',
      defaultMessage: 'Inkomstbasbelopp (IBB)',
    },
    PBB: {
      id: 'benefitPensionBaseAmountTypeMessages.PBB',
      defaultMessage: 'Prisbasbelopp (PBB)',
    },
  });

export const benefitPensionDefaultProviderMessages =
  defineMessages<PensionDefaultProvider>({
    nordea_fund: {
      id: 'benefitPensionDefaultProviderMessages.nordea_fund',
      defaultMessage: 'Fondförvaltning',
    },
    nordea_trad: {
      id: 'benefitPensionDefaultProviderMessages.nordea_trad',
      defaultMessage: 'Garantiförvaltning',
    },
  });

export const benefitPensionPremiumCalculationMethodMessages =
  defineMessages<PensionPremiumCalculationMethod>({
    ITP_MATRIX: {
      id: 'benefitPensionPremiumTypeMessages.ITP_MATRIX',
      defaultMessage: 'ITP-trappa',
    },
    ITP_LIKE_MATRIX: {
      id: 'benefitPensionPremiumTypeMessages.ITP_LIKE_MATRIX',
      defaultMessage: 'ITP-liknande trappa',
    },
    CUSTOM_MATRIX: {
      id: 'benefitPensionPremiumTypeMessages.CUSTOM_MATRIX',
      defaultMessage: 'Egen premietrappa',
    },
    FIXED_PREMIUM_ONLY: {
      id: 'benefitPensionPremiumTypeMessages.FIXED_PREMIUM_ONLY',
      defaultMessage: 'Fast individuell premie',
    },
  });

export const benefitPensionPremiumDeductionMessages =
  defineMessages<DeductionType>({
    PREMIUM_WAIVER: {
      id: 'benefitPensionPremiumDeductionMessages.PREMIUM_WAIVER',
      defaultMessage: 'Premiebefrielseförsäkring för tjänstepension',
    },
    SICKNESS_INSURANCE: {
      id: 'benefitPensionPremiumDeductionMessages.SICKNESS_INSURANCE',
      defaultMessage: 'Sjukförsäkring',
    },
    // TODO: Add this type when it exists in the API
    /*     SURVIVORS_PENSION: {
      id: 'benefitPensionPremiumDeductionMessages.SURVIVORS_PENSION',
      defaultMessage: 'Efterlevandepension',
    }, */
    ADVINANS_ADVICE_FEE: {
      id: 'benefitPensionPremiumDeductionMessages.ADVINANS_ADVICE_FEE',
      defaultMessage: 'Personlig rådgivning',
    },
    ADVINANS_MEMBERSHIP_FEE: {
      id: 'benefitPensionPremiumDeductionMessages.ADVINANS_MEMBERSHIP_FEE',
      defaultMessage: 'Nordea Nodes plattformsavgift',
    },
  });

export const benefitAccidentLevelMessages = defineMessages<AccidentLevel>({
  PBB20: {
    id: 'benefitAccidentLevelMessages.PBB20',
    defaultMessage: '20 Prisbasbelopp',
  },
  PBB30: {
    id: 'benefitAccidentLevelMessages.PBB30',
    defaultMessage: '30 Prisbasbelopp',
  },
  PBB40: {
    id: 'benefitAccidentLevelMessages.PBB40',
    defaultMessage: '40 Prisbasbelopp',
  },
  PBB50: {
    id: 'benefitAccidentLevelMessages.PBB50',
    defaultMessage: '50 Prisbasbelopp',
  },
});

export const benefitDisabilityLevelMessages = defineMessages<DisabilityLevel>({
  ITP: {
    id: 'benefitDisabilityLevelMessages.ITP',
    defaultMessage: 'ITP-sjuk',
  },
  KOMP: {
    id: 'benefitDisabilityLevelMessages.KOMP',
    defaultMessage: 'ITP-komplettering',
  },
  MAX: {
    id: 'benefitDisabilityLevelMessages.MAX',
    defaultMessage: 'MAX-sjuk',
  },
  CONSOLIDATION: {
    id: 'benefitDisabilityLevelMessages.CONSOLIDATION',
    defaultMessage: 'Konsolideringsnivå',
  },
  HYBRID: {
    id: 'benefitDisabilityLevelMessages.HYBRID',
    defaultMessage: 'Hybridsjuk',
  },
});

export const ownerDescriptionMessages = defineMessages({
  jointStockCompany: {
    id: 'ownerDescriptionMessages.jointStockCompany',
    defaultMessage: 'Aktiebolag',
  },
  jointStockCompanyDescription: {
    id: 'ownerDescriptionMessages.jointStockCompanyDescription',
    defaultMessage: `
    <ul>
      <li>
        Företagare som antingen själv eller tillsammans med make/maka,
        registrerad partner, förälder eller barn, äger en tredjedel eller
        mer av aktierna i företaget.
      </li>
      <li>
        Företagarens i företaget verksamma make/maka, registrerad partner.
      </li>
      <li>
        Barn till företagare räknas som företagare om de äger minst en
        aktie.
      </li>
    </ul>
    <p>Syskon kan inte räkna ihop sina aktier.</p>`,
  },
  generalPartnership: {
    id: 'ownerDescriptionMessages.generalPartnership',
    defaultMessage: 'Handelsbolag',
  },
  generalPartnershipDescription: {
    id: 'ownerDescriptionMessages.generalPartnershipDescription',
    defaultMessage: `
    <ul>
      <li>Delägare</li>
      <li>Delägarens make/maka, registrerad partner.</li>
    </ul>`,
  },
  limitedPartnership: {
    id: 'ownerDescriptionMessages.limitedPartnership',
    defaultMessage: 'Kommanditbolag',
  },
  limitedPartnershipDescription: {
    id: 'ownerDescriptionMessages.limitedPartnershipDescription',
    defaultMessage: `
    <ul>
      <li>Komplementär</li>
      <li>Komplementär make/maka, registrerad partner.</li>
    </ul>`,
  },
  proprietorship: {
    id: 'ownerDescriptionMessages.proprietorship',
    defaultMessage: 'Enskild Näringsidkare',
  },
  proprietorshipDescription: {
    id: 'ownerDescriptionMessages.proprietorshipDescription',
    defaultMessage: `
    <ul>
      <li>Samtliga företagare</li>
    </ul>`,
  },
});

export const benefitHealthcareLevelMessages = defineMessages<HealthLevel>({
  BRONS_0: {
    id: 'smeHealthcareLevelMessages.BRONS_0',
    defaultMessage: 'Brons utan självrisk',
  },
  BRONS_500: {
    id: 'smeHealthcareLevelMessages.BRONS_500',
    defaultMessage: 'Brons 500 kr',
  },
  L0: {
    id: 'smeHealthcareLevelMessages.L0',
    defaultMessage: 'Silver utan självrisk',
  },
  L500: {
    id: 'smeHealthcareLevelMessages.L500',
    defaultMessage: 'Silver 500 kr',
  },
  L750: {
    id: 'smeHealthcareLevelMessages.L750',
    defaultMessage: 'Silver 750 kr',
  },
  L1000: {
    id: 'smeHealthcareLevelMessages.L1000',
    defaultMessage: 'Silver 1000 kr',
  },
  L1500: {
    id: 'smeHealthcareLevelMessages.L1500',
    defaultMessage: 'Silver 1500 kr',
  },
  GOLD: {
    id: 'smeHealthcareLevelMessages.GOLD',
    defaultMessage: 'Guld',
  },
});

export const benefitLifeLevelMessages = defineMessages<LifeLevel>({
  PBB5: {
    id: 'benefitLifeLevelMessages.PBB5',
    defaultMessage: '5 Prisbasbelopp',
  },
  PBB10: {
    id: 'benefitLifeLevelMessages.PBB10',
    defaultMessage: '10 Prisbasbelopp',
  },
  PBB15: {
    id: 'benefitLifeLevelMessages.PBB15',
    defaultMessage: '15 Prisbasbelopp',
  },
  PBB20: {
    id: 'benefitLifeLevelMessages.PBB20',
    defaultMessage: '20 Prisbasbelopp',
  },
  PBB25: {
    id: 'benefitLifeLevelMessages.PBB25',
    defaultMessage: '25 Prisbasbelopp',
  },
  PBB30: {
    id: 'benefitLifeLevelMessages.PBB30',
    defaultMessage: '30 Prisbasbelopp',
  },
  PBB35: {
    id: 'benefitLifeLevelMessages.PBB35',
    defaultMessage: '35 Prisbasbelopp',
  },
  PBB40: {
    id: 'benefitLifeLevelMessages.PBB40',
    defaultMessage: '40 Prisbasbelopp',
  },
  PBB45: {
    id: 'benefitLifeLevelMessages.PBB45',
    defaultMessage: '45 Prisbasbelopp',
  },
  PBB50: {
    id: 'benefitLifeLevelMessages.PBB50',
    defaultMessage: '50 Prisbasbelopp',
  },
});

export const benefitSicknessAndAccidentLevelMessages =
  defineMessages<SicknessAccidentLevel>({
    PBB20: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB20',
      defaultMessage: '20 Prisbasbelopp',
    },
    PBB30: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB30',
      defaultMessage: '30 Prisbasbelopp',
    },
    PBB40: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB40',
      defaultMessage: '40 Prisbasbelopp',
    },
    PBB50: {
      id: 'benefitSicknessAndAccidentLevelMessages.PBB50',
      defaultMessage: '50 Prisbasbelopp',
    },
  });

export const benefitPersonalAdvicePaymentMethodMessages =
  defineMessages<PersonalAdvicePaymentMethod>({
    EMPLOYER_PAYS_PER_MEETING: {
      id: 'benefitPersonalAdvicePaymentMethodMessages.EMPLOYER_PAYS_PER_MEETING',
      defaultMessage: 'Betalas av arbetsgivaren - Avgift per möte',
    },
    EMPLOYER_PAYS_SUBSCRIPTION: {
      id: 'benefitPersonalAdvicePaymentMethodMessages.EMPLOYER_PAYS_SUBSCRIPTION',
      defaultMessage: 'Betalas av  arbetsgivaren - Månadsabonnemang',
    },
    EMPLOYEE_PAYS_PREMIUM_DEDUCTION: {
      id: 'benefitPersonalAdvicePaymentMethodMessages.EMPLOYEE_PAYS_PREMIUM_DEDUCTION',
      defaultMessage:
        'Betalas av medarbetare - Premieavdrag för tjänstepension',
    },
    EMPLOYEE_PAYS_PER_MEETING: {
      id: 'benefitPersonalAdvicePaymentMethodMessages.EMPLOYEE_PAYS_PER_MEETING',
      defaultMessage: 'Betalas av medarbetare - Avgift per möte',
    },
    CAPITAL_FEE: {
      id: 'benefitPersonalAdvicePaymentMethodMessages.CAPITAL_FEE',
      defaultMessage: 'Rådgivning ingår - Utan extra kostnad',
    },
  });

export const benefitPersonalAdviceMeetingFrequencyMessages =
  defineMessages<PersonalAdviceMeetingFrequencyOptionValue>({
    ONE_ADVICE_MEETING_LIMIT: {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.ONE_ADVICE_MEETING_LIMIT',
      defaultMessage: 'Begränsa till ett möte',
    },
    '0': {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.0',
      defaultMessage: 'Obegränsat',
    },
    '12': {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.12',
      defaultMessage: '12 månader',
    },
    '24': {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.24',
      defaultMessage: '24 månader',
    },
    '36': {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.36',
      defaultMessage: '36 månader',
    },
    '48': {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.48',
      defaultMessage: '48 månader',
    },
    '60': {
      id: 'benefitPersonalAdviceMeetingFrequencyMessages.60',
      defaultMessage: '60 månader',
    },
  });
