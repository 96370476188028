import { ApolloError, useMutation } from '@apollo/client';
import {
  AdvinansOccupationalPension,
  AdvinansOccupationalPensionInput,
} from '@frontend/benefit-types';
import { toNumber } from '@frontend/utils';
import {
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  FlexUpdateBenefitInput,
  updateBenefitMutation,
  updateBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { benefitFormMessages } from '../../../form/messages';
import { FormValues } from '../../../form/occupational-pension';
import { formatPremiumMatrixInput } from '../../../form/utils';
import { PENSION_PROVIDER_INPUT_VALUES } from '../../../form/utils/constants';
import { UPDATE_BENEFIT_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (
    values: FormValues,
    benefit: AdvinansOccupationalPension<Benefit>,
  ) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateBenefit, { error: submissionError }] = useMutation<
    updateBenefitMutation,
    updateBenefitMutationVariables
  >(UPDATE_BENEFIT_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'flexBenefits',
      });
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'benefitPackages',
      });
      cache.gc();
    },
  });

  const submit = async (
    values: FormValues,
    benefit: AdvinansOccupationalPension<Benefit>,
  ) => {
    try {
      const input: AdvinansOccupationalPensionInput<FlexUpdateBenefitInput> = {
        id: params.benefitId,
        configEffectiveDate: format(
          new Date(values.effectiveFrom),
          'yyyy-MM-dd',
        ),
        configuration: {
          defaultProvider: values.defaultProvider || undefined,
          fixedPremium:
            values.premiumCalculationMethod === 'FIXED_PREMIUM_ONLY' ||
            values.extraFixedPremium,
          retirementAge: toNumber(values.retirementAge) ?? 0,
          premiumWaiver: values.premiumWaiver === 'yes',
          premiumDeductions: values.premiumDeductions,
          payrollElementCode:
            values.premiumCalculationMethod !== 'FIXED_PREMIUM_ONLY'
              ? values.payrollElementCode
              : undefined,
          payrollElementMultiplier:
            values.premiumCalculationMethod !== 'FIXED_PREMIUM_ONLY'
              ? values.payrollElementMultiplier
              : undefined,
          isActiveOnParentalLeave: values.isActiveOnParentalLeave,
          providers: values.providers.flatMap(
            provider => PENSION_PROVIDER_INPUT_VALUES[provider],
          ),
          premiumMatrix:
            values.premiumCalculationMethod === 'ITP_MATRIX'
              ? formatPremiumMatrixInput(values.itpPremiumMatrix)
              : values.premiumCalculationMethod === 'ITP_LIKE_MATRIX'
                ? formatPremiumMatrixInput(values.itpLikePremiumMatrix)
                : values.premiumCalculationMethod === 'CUSTOM_MATRIX'
                  ? formatPremiumMatrixInput(values.customPremiumMatrix)
                  : null,
          premiumWaiverConvertingFrom:
            values.premiumWaiver === 'yes'
              ? values.premiumWaiverConverting
                ? values.premiumWaiverConvertingFrom
                : null
              : null,
        },
        entitlementRules: {
          accountingCostIds: benefit.entitlementRules?.accountingCostIds ?? [],
          benefitPackageIds: values.benefitPackageIds,
          ouIds: benefit.entitlementRules?.ouIds ?? [],
        },
      };

      await updateBenefit({
        variables: {
          input,
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });

      push(`/companies/${params.companyId}/benefit-packages/benefits`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
