import {
  Button,
  ButtonLayout,
  CheckboxField,
  ChipsField,
  SelectField,
  TextField,
} from '@frontend/ui';
import { EventTypeCategory } from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { SideSheet } from 'components/SideSheet';
import {
  FilterArrayParamKey,
  FilterParams,
} from 'features/advisor/utils/use-filter-params';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import React from 'react';

type SideSheetProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setFilterArrayParam: (
    key: FilterArrayParamKey,
    element: EventTypeCategory,
    shouldRemoveElement: boolean,
  ) => void;
  setFilterParam: (params: Partial<FilterParams>) => void;
  clearFilter: () => void;
  filterParams: FilterParams;
  advisors: Array<{ label: string; value: string }>;
  companies: Array<{ label: string; value: string }>;
};

export const MeetingStatisticsFilterSideSheet: React.FC<SideSheetProps> = ({
  isOpen,
  setOpen,
  setFilterArrayParam,
  setFilterParam,
  clearFilter,
  filterParams,
  advisors,
  companies,
}) => (
  <SideSheet
    footer={
      <ButtonLayout align="right">
        <Button onClick={clearFilter}>
          <FormattedMessage {...commonMessages.clear} />
        </Button>
        <Button filled onClick={() => setOpen(false)}>
          <FormattedMessage {...commonMessages.done} />
        </Button>
      </ButtonLayout>
    }
    open={isOpen}
    setOpen={setOpen}
    title={<FormattedMessage {...advisorMessages.filters} />}
  >
    <FilterSection
      header={<FormattedMessage {...advisorMessages.meetingType} />}
    >
      <CheckboxField
        label={<FormattedMessage {...advisorMessages.personalAdviceMeeting} />}
        checked={filterParams.eventTypeCategories.includes(
          EventTypeCategory.PERSONAL_ADVICE,
        )}
        onChange={checked => {
          setFilterArrayParam(
            'eventTypeCategories',
            EventTypeCategory.PERSONAL_ADVICE,
            !checked,
          );
        }}
      />
      <CheckboxField
        label={<FormattedMessage {...advisorMessages.pensionTransferMeeting} />}
        checked={filterParams.eventTypeCategories.includes(
          EventTypeCategory.PENSION_TRANSFER,
        )}
        onChange={checked => {
          setFilterArrayParam(
            'eventTypeCategories',
            EventTypeCategory.PENSION_TRANSFER,
            !checked,
          );
        }}
      />
      <CheckboxField
        label={<FormattedMessage {...advisorMessages.followUpMeeting} />}
        checked={filterParams.eventTypeCategories.includes(
          EventTypeCategory.FOLLOW_UP,
        )}
        onChange={checked => {
          setFilterArrayParam(
            'eventTypeCategories',
            EventTypeCategory.FOLLOW_UP,
            !checked,
          );
        }}
      />
    </FilterSection>
    <FilterSection header={<FormattedMessage {...advisorMessages.period} />}>
      <TextField
        dense
        label={<FormattedMessage {...commonMessages.from} />}
        persistentLabel
        type="date"
        value={filterParams.from}
        onChange={event => {
          setFilterParam({ from: event.target.value });
        }}
      />
      <TextField
        dense
        label={<FormattedMessage {...commonMessages.to} />}
        persistentLabel
        type="date"
        value={filterParams.to}
        onChange={event => {
          setFilterParam({ to: event.target.value });
        }}
      />
    </FilterSection>
    <FilterSection
      header={<FormattedMessage {...advisorMessages.chooseAdvisor} />}
    >
      <SelectField
        dense
        label={<FormattedMessage {...advisorMessages.advisor} />}
        options={advisors}
        value={filterParams.advisor}
        onChange={event => {
          setFilterParam({ advisor: event.detail.value });
        }}
      />
    </FilterSection>
    <FilterSection
      header={<FormattedMessage {...advisorMessages.chooseCompanies} />}
    >
      <ChipsField
        dense
        label={<FormattedMessage {...commonMessages.companies} />}
        options={companies}
        value={companies
          .filter(({ value }) => filterParams.companyIds.includes(value))
          .reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})}
        onChange={selections => {
          setFilterParam({ companyIds: Object.keys(selections) });
        }}
      />
    </FilterSection>
  </SideSheet>
);
