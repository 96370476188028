import { ContentContainer, Icon } from '@frontend/ui';
import { search as searchIcon } from '@frontend/ui/icons';
import { employeeMessages } from 'app/messages/employees';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Employees } from 'features/employees';
import { Page } from 'features/page';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

export const EmployeesRoute: React.FC<RouteComponentProps> = ({ match }) => {
  const { permissions } = useCurrentUser();

  if (!hasBackstagePermission('backstageApp', 'employeesTab', permissions)) {
    return <Redirect to="/" />;
  }

  return (
    <NavigationAnchor title={employeeMessages.employees} url={match.url}>
      <Page title={<FormattedMessage {...employeeMessages.employees} />}>
        <Switch>
          <Route exact path={match.url}>
            <ContentContainer>
              <SearchField
                label={<FormattedMessage {...employeeMessages.search} />}
                leadingIcon={<Icon icon={searchIcon} decorative />}
              />
              <Employees />
            </ContentContainer>
          </Route>
          <Route component={EmptyState} />
        </Switch>
      </Page>
    </NavigationAnchor>
  );
};
