import gql from 'graphql-tag';

export const UPDATE_BENEFIT_MUTATION = gql`
  mutation updateBenefitMutation($input: FlexUpdateBenefitInput!) {
    updateBenefit(input: $input) {
      benefit {
        id
        configuration {
          data
        }
        content {
          locale
        }
        entitlementRules {
          benefitPackageIds
        }
      }
    }
  }
`;
