import { person } from '@frontend/ui/icons';
import {
  membershipsQuery,
  membershipsQueryVariables,
} from 'app/apollo/graphql/types';
import { employeeMessages } from 'app/messages/employees';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { EmployeesTable } from './components/EmployeesTable';
import { MEMBERSHIPS_QUERY } from './graphql/queries';
import { updateMembershipsQuery } from './utils/update-company-memberships-query';

export const Employees: React.FC = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  const { search: _search, per_page } = qs.parse(location.search);
  const search = _search?.trim();

  const {
    previousData,
    data: _data,
    loading,
    error,
    fetchMore,
  } = useQuery<membershipsQuery, membershipsQueryVariables>(MEMBERSHIPS_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
      includeTerminated: true,
    },
    errorPolicy: 'all',
    skip: !search || search.length < 3,
    notifyOnNetworkStatusChange: true,
  });

  const data = _data ?? previousData;

  const memberships = data?.memberships;
  const pageInfo = memberships?.pageInfo;

  if (!memberships || !pageInfo) {
    return (
      <>
        {loading && <TopLoading />}
        <EmptyState
          icon={person}
          error={error}
          title={<FormattedMessage {...employeeMessages.search} />}
        >
          <FormattedMessage {...employeeMessages.searchDescription} />
        </EmptyState>
      </>
    );
  }

  const employees = memberships.edges.map(e => e.node);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      {employees.length ? (
        <EmployeesTable
          pageInfo={pageInfo}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery: updateMembershipsQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery: updateMembershipsQuery,
            });
          }}
          employees={employees}
        />
      ) : (
        !loading && (
          <span role="status">
            {formatMessage(employeeMessages.noEmployeeMatchForSearch, {
              search,
            })}
          </span>
        )
      )}
    </>
  );
};
