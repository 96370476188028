import { Formik } from '@frontend/formik';
import {
  ProposalCompanySize,
  smeCompanyCreateBenefitQuery,
  smeCompanyCreateBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CreateBenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/create';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { FormValues, LifeForm, validationSchema } from '../../form/life';
import { SME_COMPANY_CREATE_BENEFIT_QUERY } from '../graphql/queries';
import { getAgreementTypeForCompanySize } from './utils/get-agreement-type-for-company-size';
import { useSubmit } from './utils/use-submit';

export const CreateLife: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<CreateBenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyCreateBenefitQuery,
    smeCompanyCreateBenefitQueryVariables
  >(SME_COMPANY_CREATE_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefitPackages) {
    return <EmptyState error={error} />;
  }

  const { benefitPackages, companySize } = data.proposal;

  // Life benefit is not allowed to be created for companies
  // of size LT_10 so we redirect back to benefits page
  if (companySize === ProposalCompanySize.LT_10) {
    return (
      <Redirect
        to={`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`}
      />
    );
  }

  const initialValues: FormValues = {
    agreementType: getAgreementTypeForCompanySize({ companySize }),
    benefitPackageIds: [],
    level: '',
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting }) => (
        <LifeForm
          companySize={companySize}
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
        />
      )}
    </Formik>
  );
};
