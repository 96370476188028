import gql from 'graphql-tag';

export const SME_COMPANY_EDIT_OCCUPATIONAL_PENSION_QUERY = gql`
  query smeCompanyEditOccupationalPensionQuery(
    $benefitId: ID!
    $proposalId: ID!
  ) {
    proposal(id: $proposalId) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalOccupationalPensionBenefitConfig {
            convertingPremiumWaiverFrom
            defaultManagementType
            extraFixedPremium
            premiumCalculationMethod
            premiumDeductions
            premiumWaiver
            premiumMatrix {
              age
              intervals
              baseAmount
              baseAmountType
              salaryMultiplier
            }
            retirementAge
          }
        }
      }
      benefitPackages {
        id
        name
      }
    }
  }
`;
