import { ApolloError } from '@apollo/client';
import {
  CheckboxGroupField,
  Form,
  RadioGroupField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  LifeAgreementType,
  ProposalCompanySize,
  ProposalLifeLevel,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import {
  smeBenefitFormMessages,
  smeBenefitLifeLevelMessages,
  smeLifeAgreementTypeMessages,
} from 'features/sme/messages/sme';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { LIFE_LEVELS } from '../utils/constants';

export interface FormValues {
  agreementType: LifeAgreementType | '';
  benefitPackageIds: string[];
  level: ProposalLifeLevel | '';
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  companySize: ProposalCompanySize;
  isSubmitting: boolean;
  isValid: boolean;
  benefitPackages?: readonly BenefitPackage[] | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    level: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    agreementType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const LifeForm: React.FC<Props> = ({
  companySize,
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const {
    values: { agreementType, level },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const disabled = useProposalReadonly();

  useEffect(() => {
    /**
     * If the agreement type is changed we make sure to reset the level
     * in case the level is not valid for the new agreement type.
     *
     * To avoid polluting the form with errors when changing agreement type
     * we skip validation on reset.
     */
    if (agreementType && level && !LIFE_LEVELS[agreementType].includes(level)) {
      setFieldValue('level', '', false);
    }
  }, [agreementType, level, setFieldValue]);

  return (
    <Form>
      <Section>
        {companySize === ProposalCompanySize.GTE_50 && (
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.agreementType} />
            </SubsectionHeader>
            <TextGrid>
              <DescriptionWrapper>
                <FormattedMessage
                  {...smeBenefitFormMessages.agreementTypeDescription}
                />
              </DescriptionWrapper>
              <SelectField
                dense
                required
                label="Avtalstyp"
                name="agreementType"
                options={Object.keys(LifeAgreementType).map(type => ({
                  label: formatMessage({
                    messages: smeLifeAgreementTypeMessages,
                    select: type,
                  }),
                  value: type,
                }))}
              />
            </TextGrid>
          </Subsection>
        )}
        {!!agreementType && (
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.level} />
            </SubsectionHeader>
            <RadioGroupField
              name="level"
              options={LIFE_LEVELS[agreementType].map(l => ({
                label: formatMessage({
                  select: l,
                  messages: smeBenefitLifeLevelMessages,
                }),
                value: l,
              }))}
              disabled={disabled}
            />
          </Subsection>
        )}
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
          </SubsectionHeader>
          {benefitPackages?.length ? (
            <>
              <DescriptionWrapper>
                <FormattedMessage
                  {...smeBenefitFormMessages.benefitPackagesDescription}
                />
              </DescriptionWrapper>

              <CheckboxGroupField
                name="benefitPackageIds"
                options={benefitPackages.map(benefitPackage => ({
                  label: benefitPackage.name,
                  value: benefitPackage.id,
                }))}
                disabled={disabled}
              />
            </>
          ) : (
            <NotificationCard type="warning">
              <FormattedMessage {...commonBenefitMessages.noBenefitPackages} />
            </NotificationCard>
          )}
        </Subsection>
        {companySize === ProposalCompanySize.LT_50 && (
          <Subsection>
            <NotificationCard type="warning">
              <FormattedMessage
                {...smeBenefitFormMessages.lifeInsuranceDisclaimer}
              />
            </NotificationCard>
          </Subsection>
        )}
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={disabled || !isValid}
          >
            <FormattedMessage {...smeBenefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
