import { validCompilationDetails } from 'features/sme/companies/company/proposals/edit/compilation/notifications/graphql/fragments';
import gql from 'graphql-tag';

export const UPDATE_FEES_LOOKED_AT_MUTATION = gql`
  mutation updateFeesLookedAtMutation($input: ProposalIdInput!) {
    setFeesLookedAt(input: $input) {
      id
      validity {
        validCompilation {
          ...validCompilationDetails
        }
        validFees {
          isValid
        }
      }
    }
  }
  ${validCompilationDetails}
`;
