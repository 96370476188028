import gql from 'graphql-tag';

export const SME_COMPANY_EDIT_LIFE_QUERY = gql`
  query smeCompanyEditLifeQuery($benefitId: ID!, $proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      companySize
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalLifeBenefitConfig {
            lifeLevel
            agreementType
          }
        }
      }
      benefitPackages {
        id
        name
      }
    }
  }
`;
