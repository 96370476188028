import { ApolloError } from '@apollo/client';
import {
  DatePickerField,
  Form,
  RadioGroupField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  A,
  Button,
  ButtonLayout,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { Attachments } from 'components/Attachments';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { FileUploadField } from 'components/formik/FileUploadField';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import { benefitFormMessages } from '../messages';
import { BenefitPackageOption } from '../types';
import { getEarliestRetroactiveDate } from '../utils';

const PolicyHref = (chunk: React.ReactNode) => (
  <A href={EXTERNAL_URLS.epassiBikeStandardPolicyHref} target="_blank">
    {chunk}
  </A>
);

export enum PolicyType {
  EPASSI_DEFAULT_POLICY = 'EPASSI_DEFAULT_POLICY',
  CUSTOM_COMPANY_POLICY = 'CUSTOM_COMPANY_POLICY',
}

export interface FormValues {
  benefitPackageIds: string[];
  customerId: string;
  effectiveFrom: string;
  files: FileInfo[];
  policyType: PolicyType | '';
}

interface Props {
  benefitPackageOptions: BenefitPackageOption[];
  companyId: string;
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    customerId: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid epassi customer id',
        intl.formatMessage(validationMessages.isValidEpassiCustomerId),
        value => !value || value.length === 4 || value.length === 5,
      ),
    effectiveFrom: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'valid date',
        message: intl.formatMessage(validationMessages.dateMaxThreeMonthsOld),
        test: (value: string) =>
          isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
      }),
    policyType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    files: Yup.array().when('policyType', {
      is: (policyType: PolicyType) =>
        policyType === PolicyType.CUSTOM_COMPANY_POLICY,
      then: schema =>
        schema
          .min(1, intl.formatMessage(validationMessages.mandatoryField))
          .max(2, intl.formatMessage(validationMessages.isMaxTwoFiles)),
    }),
  });

export const EpassiBikeForm: React.FC<Props> = ({
  benefitPackageOptions,
  companyId,
  isEdit = false,
  latestChangesEffectiveDate,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const { values, isValid, isSubmitting } = useFormikContext<FormValues>();

  return (
    <Form>
      <Section>
        <UpcomingChangesNotification
          latestChangesEffectiveDate={latestChangesEffectiveDate}
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.customerId} />
          </SubsectionHeader>
          <Grid>
            <GridCell33>
              <TextField
                dense
                disabled={isEdit}
                gridMargin
                label={
                  <FormattedMessage {...benefitFormMessages.customerIdLabel} />
                }
                name="customerId"
                required
              />
            </GridCell33>
          </Grid>
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.epassiBikePolicy} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.epassiBikePolicyDescription}
                values={{
                  policyHref: PolicyHref,
                }}
              />
            </DescriptionWrapper>
            <RadioGroupField
              disabled={isEdit}
              required
              name="policyType"
              options={[
                {
                  label: formatMessage(
                    benefitFormMessages.epassiBikeStandardPolicy,
                  ),
                  value: PolicyType.EPASSI_DEFAULT_POLICY,
                },
                {
                  label: formatMessage(
                    benefitFormMessages.epassiBikeCustomCompanyPolicy,
                  ),
                  value: PolicyType.CUSTOM_COMPANY_POLICY,
                },
              ]}
            />
            {isEdit ? (
              <Attachments attachments={values.files ?? []} />
            ) : (
              values.policyType === PolicyType.CUSTOM_COMPANY_POLICY && (
                <FileUploadField
                  companyId={companyId}
                  disabled={isEdit}
                  name="files"
                  label={
                    <FormattedMessage {...benefitFormMessages.selectFiles} />
                  }
                />
              )
            )}
          </TextGrid>
        </Subsection>
        <BenefitPackagesSection
          benefitPackageOptions={benefitPackageOptions}
          disabled={isEdit}
          name="benefitPackageIds"
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...(isEdit
                ? benefitFormMessages.effectiveEditDate
                : benefitFormMessages.effectiveStartDate)}
            />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...(isEdit
                  ? benefitFormMessages.effectiveEditDateDescription
                  : benefitFormMessages.effectiveStartDateDescription)}
              />
            </DescriptionWrapper>
          </TextGrid>
          <Grid>
            <GridCell33>
              <DatePickerField
                dense
                disabled={isEdit}
                gridMargin
                label={
                  <FormattedMessage
                    {...(isEdit
                      ? benefitFormMessages.effectiveEditDateLabel
                      : benefitFormMessages.effectiveStartDateLabel)}
                  />
                }
                min={getEarliestRetroactiveDate()}
                name="effectiveFrom"
                required
                type="month"
              />
            </GridCell33>
          </Grid>
        </Subsection>
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage {...benefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
