import { ibb } from '@advinans/belt-se-constants';
import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  DatePickerField,
  Form,
  NumberField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  CalculationLabel,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { roundUpToNearestHundred, toNumber } from '@frontend/utils';
import { ManagingCompany } from 'app/apollo/graphql/types';
import { suffixMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import {
  FormattedCurrency,
  FormattedMessage,
  FormattedPercent,
  IntlShape,
  useIntl,
} from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import {
  GridCell25,
  GridCell33,
  GridCell50,
  TextGrid,
} from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import {
  FormValues as QualifyingIncomeFormValues,
  QualifyingIncomeSection,
  validationSchema as qualifyingIncomeValidationSchema,
} from '../components/QualifyingIncomeSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import { benefitFormMessages } from '../messages';
import {
  Agreements,
  BenefitPackageOption,
  RemunerationTypes,
  SalaryExchangeProviderOptionValue,
} from '../types';
import {
  getEarliestRetroactiveDate,
  getExtraPremiumFromTaxBenefitShare,
} from '../utils';
import { INCOME_MIN_IBB } from '../utils/constants';
import { SalaryExchangeProviderSection } from './SalaryExchangeProviderSection';

export interface FormValues extends QualifyingIncomeFormValues {
  automaticApproval: boolean;
  benefitPackageIds: string[];
  effectiveFrom: string;
  incomeMinIbb: string;
  ongoingDeductionShareMax: string;
  ongoingMax: string;
  pensionTaxBenefitShare: string;
  providers: SalaryExchangeProviderOptionValue[];
  retirementAge: string;
}

interface Props {
  agreements: Agreements;
  benefitPackageOptions: BenefitPackageOption[];
  managedBy: ManagingCompany;
  remunerationTypes: RemunerationTypes;
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape, isEdit?: boolean) =>
  Yup.object()
    .shape({
      effectiveFrom: Yup.string().when([], {
        is: () => isEdit,
        then: schema =>
          schema
            .required(intl.formatMessage(validationMessages.mandatoryField))
            .test({
              name: 'valid date',
              message: intl.formatMessage(
                validationMessages.dateMaxThreeMonthsOld,
              ),
              test: (value: string) =>
                isAfter(
                  new Date(value),
                  new Date(getEarliestRetroactiveDate()),
                ),
            }),
      }),
      incomeMinIbb: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      ongoingDeductionShareMax: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test({
          name: 'max 35%',
          message: intl.formatMessage(validationMessages.max, {
            max: intl.formatNumber(0.35, { style: 'percent' }),
          }),
          test: (value: string) => (toNumber(value) ?? 0) <= 35,
        }),
      ongoingMax: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test({
          name: 'max 30 000',
          message: intl.formatMessage(validationMessages.max, {
            max: intl.formatNumber(30000, {
              style: 'currency',
              currency: 'SEK',
              maximumFractionDigits: 0,
            }),
          }),
          test: (value: string) => (toNumber(value) ?? 0) <= 30000,
        }),
      pensionTaxBenefitShare: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      providers: Yup.array().min(
        1,
        intl.formatMessage(validationMessages.minOneOptionRequired),
      ),
      retirementAge: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test({
          name: 'at least 55',
          message: intl.formatMessage(benefitFormMessages.minRetirementAge),
          test: (value: string) => (toNumber(value) ?? 0) >= 55,
        }),
    })
    .concat(qualifyingIncomeValidationSchema(intl));

export const SalaryExchangeForm: React.FC<Props> = ({
  agreements,
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  managedBy,
  remunerationTypes,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const { values, isValid, isSubmitting } = useFormikContext<FormValues>();

  const isManagedByNode = managedBy === 'NODE';

  const year = new Date().getFullYear();
  const _ibb = ibb(year);

  if (!_ibb) {
    return (
      <NotificationCard type="error">
        <FormattedMessage {...formMessages.queryErrorMessage} />
      </NotificationCard>
    );
  }

  const yearlyThreshold = _ibb * INCOME_MIN_IBB;

  return (
    <Form>
      <Section>
        <UpcomingChangesNotification
          latestChangesEffectiveDate={latestChangesEffectiveDate}
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.retirementAge} />
          </SubsectionHeader>
          <Grid>
            <GridCell25>
              <NumberField
                affix={formatMessage(suffixMessages.age)}
                allowNegative={false}
                decimalScale={0}
                dense
                gridMargin
                label={
                  <FormattedMessage {...benefitFormMessages.retirementAge} />
                }
                locale={locale}
                name="retirementAge"
                required
              />
            </GridCell25>
          </Grid>
        </Subsection>
        {isManagedByNode && (
          <SalaryExchangeProviderSection
            agreements={agreements}
            name="providers"
          />
        )}
        <QualifyingIncomeSection remunerationTypes={remunerationTypes} />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.ongoingMax} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.ongoingMaxDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <Grid>
            <GridCell50>
              <NumberField
                affix={formatMessage(suffixMessages.krPerMonth)}
                decimalScale={0}
                dense
                helperText={
                  <FormattedMessage
                    {...validationMessages.max}
                    values={{
                      max: (
                        <FormattedCurrency
                          after="PER_MONTH"
                          currency="SEK"
                          value={30000}
                        />
                      ),
                    }}
                  />
                }
                label={formatMessage(benefitFormMessages.ongoingMaxSek)}
                locale={locale}
                name="ongoingMax"
                required
              />
              <NumberField
                affix={formatMessage(suffixMessages.percentage)}
                decimalScale={0}
                dense
                gridMargin
                helperText={
                  <FormattedMessage
                    {...validationMessages.max}
                    values={{
                      max: <FormattedPercent integer value={0.35} />,
                    }}
                  />
                }
                label={formatMessage(benefitFormMessages.ongoingMaxShare)}
                locale={locale}
                name="ongoingDeductionShareMax"
                required
              />
            </GridCell50>
          </Grid>
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.incomeMinIbbTitle} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.incomeMinIbbDescription}
                values={{
                  incomeLimitIbb: INCOME_MIN_IBB,
                  monthlyThreshold: yearlyThreshold / 12,
                  year,
                  yearlyThreshold,
                }}
              />
            </DescriptionWrapper>
          </TextGrid>
          <NumberField
            affix="IBB"
            decimalScale={2}
            dense
            label={formatMessage(benefitFormMessages.incomeMinIbb)}
            locale={locale}
            name="incomeMinIbb"
            postfix={
              <CalculationLabel
                prefix="="
                label={
                  <FormattedCurrency
                    currency="SEK"
                    value={
                      values.incomeMinIbb
                        ? roundUpToNearestHundred(
                            (toNumber(values.incomeMinIbb) ?? 0) * _ibb,
                          )
                        : 0
                    }
                  />
                }
              />
            }
            required
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...benefitFormMessages.pensionTaxBenefitShareTitle}
            />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.pensionTaxBenefitShareDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <NumberField
            affix={formatMessage(suffixMessages.percentage)}
            decimalScale={0}
            dense
            label={formatMessage(benefitFormMessages.pensionTaxBenefitShare)}
            locale={locale}
            name="pensionTaxBenefitShare"
            postfix={
              <CalculationLabel
                prefix="="
                label={
                  <FormattedMessage
                    {...benefitFormMessages.pensionTaxBenefitShareHelperText}
                    values={{
                      share: getExtraPremiumFromTaxBenefitShare(
                        toNumber(values.pensionTaxBenefitShare) ?? 0,
                      ),
                    }}
                  />
                }
              />
            }
            required
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.automaticApprovalTitle} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.automaticApprovalDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <CheckboxField
            label={
              <FormattedMessage {...benefitFormMessages.automaticApproval} />
            }
            name="automaticApproval"
          />
        </Subsection>
        <BenefitPackagesSection
          benefitPackageOptions={benefitPackageOptions}
          name="benefitPackageIds"
        />
        {isEdit && (
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.effectiveEditDate} />
            </SubsectionHeader>
            <TextGrid>
              <DescriptionWrapper>
                <FormattedMessage
                  {...benefitFormMessages.effectiveEditDateDescription}
                />
              </DescriptionWrapper>
            </TextGrid>
            <Grid>
              <GridCell33>
                <DatePickerField
                  dense
                  gridMargin
                  label={
                    <FormattedMessage
                      {...benefitFormMessages.effectiveEditDateLabel}
                    />
                  }
                  min={getEarliestRetroactiveDate()}
                  name="effectiveFrom"
                  required
                  type="month"
                />
              </GridCell33>
            </Grid>
          </Subsection>
        )}
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage {...benefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
