import { ApolloError } from '@apollo/client';
import {
  ButtonLayout,
  ContentContainer,
  EmptyState as EmptyStateComponent,
  IconContent,
  Subsection,
} from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlError, GraphQlErrors } from 'components/GraphQlError';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import styled from 'styled-components';

import { emptyStateMessages } from './messages';

export const SpacedEmptyState = styled(EmptyStateComponent)`
  padding-top: 7.5rem;
`;

const isFormattedError = (error: ApolloError | string): error is string =>
  typeof error === 'string';

interface Props {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  error?: ApolloError | string;
  errors?: ApolloError[];
  icon?: IconContent;
  title?: React.ReactNode;
}

export const EmptyState: React.FC<Props> = ({
  actions,
  children,
  error,
  errors,
  icon,
  title,
}) => {
  const formattedError = errors?.length ? (
    <GraphQlErrors errors={errors} />
  ) : error ? (
    isFormattedError(error) ? (
      <NotificationCard type="error">{error}</NotificationCard>
    ) : (
      <GraphQlError error={error} />
    )
  ) : undefined;

  return (
    <>
      {formattedError && <ContentContainer>{formattedError}</ContentContainer>}
      <SpacedEmptyState
        icon={icon}
        title={
          title ?? (
            <FormattedMessage {...emptyStateMessages.emptyState404Message} />
          )
        }
      >
        <Subsection>{children}</Subsection>
        {actions && <ButtonLayout align="center">{actions}</ButtonLayout>}
      </SpacedEmptyState>
    </>
  );
};
