import { CheckboxField, Dot, Td, Tr } from '@frontend/ui';
import { advisorMessages } from 'app/messages/advisor';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { RowOptions } from 'features/advisor/components/RowOptions';
import { PersonalAdviceEmployee } from 'features/advisor/personal-advice';
import React, { useId } from 'react';

interface Props {
  checked: boolean;
  employee: PersonalAdviceEmployee;
  onCheckboxChange: (checked: boolean) => void;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  sendInvitation: () => void;
}

export const PersonalAdviceEmployeeTableRow: React.FC<Props> = ({
  employee,
  sendInvitation,
  openAddHeldMeetingModal,
  checked,
  onCheckboxChange,
}) => {
  const id = useId();
  const disabled = !employee.allowedToHaveMeeting;
  const today = new Date().toISOString().substring(0, 10);
  const salary = employee.membership.employment
    .filter(e => e.from <= today && (!e.to || e.to < today))
    .flatMap(e => e.remuneration)
    .filter(r => r.type.id === '11100')
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0]?.price;

  return (
    <Tr
      inlineMenu={
        <RowOptions
          employee={employee}
          sendInvitation={sendInvitation}
          openAddHeldMeetingModal={openAddHeldMeetingModal}
        />
      }
    >
      <Td type="input">
        <CheckboxField
          disabled={disabled}
          id={employee.membership.id}
          checked={checked}
          onChange={onCheckboxChange}
          aria-labelledby={id}
        />
      </Td>
      <Td align="left" type="number" id={id}>
        <FormattedNaturalPersonIdentifier
          nids={employee.membership.naturalPersonIdentifiers}
        />
      </Td>
      <Td align="left">
        {`${employee.membership.givenName} ${employee.membership.lastName}`}
      </Td>
      <Td>{employee.membership.company.displayName}</Td>
      <Td align="right" type="number">
        {employee.latestMeetingDate ?? <NoValue />}
      </Td>
      <Td align="left">
        {employee.allowedToHaveMeeting ? (
          <Dot
            colorValue="green"
            label={
              <FormattedMessage {...advisorMessages.allowedToHaveMeeting} />
            }
          />
        ) : (
          <Dot
            colorValue="error"
            label={
              <FormattedMessage {...advisorMessages.notAllowedToHaveMeeting} />
            }
          />
        )}
      </Td>
      <Td align="right" type="number">
        {salary ? (
          <FormattedCurrency value={salary} currency="SEK" />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td align="right" type="number">
        {employee.latestEmailSentAt ?? <NoValue />}
      </Td>
    </Tr>
  );
};
