import { ContentContainer } from '@frontend/ui';
import { menuMessages } from 'app/messages/menu';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { InvoiceLines } from 'features/companies/company/invoice-lines';
import { Invoices as InvoicesOverview } from 'features/companies/company/invoices';
import {
  getUploadPdfAction,
  UploadInvoiceModal,
} from 'features/companies/company/invoices/components/UploadInvoiceModal';
import { invoiceMessages } from 'features/companies/company/invoices/messages';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';

export const Invoices: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => {
  const { match, location } = props;

  const tabs: RoutedTab[] = [
    {
      title: menuMessages.overview,
      url: match.url,
    },
    {
      title: invoiceMessages.invoiceLines,
      url: `${match.url}/invoice-lines`,
    },
  ];

  return (
    <NavigationAnchor title={invoiceMessages.invoices} url={match.url}>
      <Page
        title={<FormattedMessage {...invoiceMessages.invoices} />}
        parentLink={`/companies/${match.params.companyId}`}
        tabs={tabs}
        actions={[getUploadPdfAction(location)]}
      >
        <ContentContainer>
          <UploadInvoiceModal companyId={match.params.companyId} />
          <Route exact path={match.path} component={InvoicesOverview} />
          <Route
            exact
            path={`${match.path}/invoice-lines`}
            component={InvoiceLines}
          />
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
