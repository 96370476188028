import {
  A,
  Button,
  ButtonLayout,
  ContentContainer,
  Icon,
  IconButton,
  Loading,
  Text,
} from '@frontend/ui';
import {
  _delete,
  attachFile,
  description as descriptionIcon,
} from '@frontend/ui/icons';
import { commonMessages, fileMessages } from 'app/messages/common';
import { imageUploadMessages } from 'app/messages/image-upload';
import {
  OneOfUserAccountIdOrCompanyId,
  useUploadFiles,
} from 'app/utils/use-upload-files';
import { Dropzone } from 'components/Dropzone';
import { FormattedMessage, useIntl } from 'components/formats';
import {
  ComplementaryContainer,
  HelperText,
  Placeholder,
  Preview,
} from 'components/ImageUpload';
import React, { useId } from 'react';
import { Accept } from 'react-dropzone';
import styled from 'styled-components';

const DROPZONE_DIMENSIONS = { height: '10rem', width: '100%' };

const FileList = styled.div`
  padding-top: 1rem;
`;

const FileListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export interface FileInfo {
  id: string;
  name: string;
  url: string;
}

type Props = {
  label: React.ReactNode;
  onBlur: () => void;
  onChange: (files: FileInfo[]) => void;
  errorText?: string;
  value?: FileInfo[];
  accept?: Accept;
  helperText?: React.ReactNode;
  multiple?: boolean;
} & OneOfUserAccountIdOrCompanyId;

export const FileUploadTable: React.FC<Props> = ({
  label,
  value,
  errorText: externalError,
  onChange,
  onBlur,
  accept,
  helperText,
  multiple = true,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    upload,
    loading,
    error: uploadError,
  } = useUploadFiles({
    ...props,
    onCompleted: files => {
      if (!files) {
        return;
      }
      onChange(value ? [...value, ...files] : files);
    },
  });
  const helperTextId = useId();

  const onDeleteClick = (theIndex: number) => {
    const updatedValue = value?.filter((_, index) => index !== theIndex);
    if (!updatedValue) {
      return;
    }
    onChange(updatedValue);
  };

  const dropzoneDisabled = loading || (!!value?.length && !multiple);

  const actions = (open: () => void) => (
    <ButtonLayout>
      <Button onClick={open} disabled={dropzoneDisabled}>
        {value?.length && multiple ? (
          <FormattedMessage {...fileMessages.addMoreFiles} />
        ) : (
          label
        )}
      </Button>
    </ButtonLayout>
  );

  const error = uploadError ?? externalError;

  return (
    <div>
      <Dropzone
        accept={accept}
        onDropAccepted={upload}
        onFileDialogCancel={onBlur}
        label={label}
        aria-describedby={helperTextId}
        size={DROPZONE_DIMENSIONS}
        actions={actions}
        disabled={dropzoneDisabled}
        multiple={multiple}
        complementary={
          <ComplementaryContainer maxWidth={DROPZONE_DIMENSIONS.width}>
            {error && (
              <HelperText id={helperTextId} error>
                {error}
              </HelperText>
            )}
          </ComplementaryContainer>
        }
      >
        <Preview error={!!error}>
          {loading ? (
            <Loading />
          ) : (
            <Placeholder>
              <Icon
                color={error ? 'error' : 'onSurfaceVariant'}
                size="large"
                icon={descriptionIcon}
                decorative
              />
              <Text
                use="bodyMedium"
                color={error ? 'error' : 'onSurface'}
                style={{ marginTop: '0.75rem' }}
              >
                <ContentContainer>
                  {helperText || (
                    <FormattedMessage {...imageUploadMessages.dragAndDrop} />
                  )}
                </ContentContainer>
              </Text>
            </Placeholder>
          )}
        </Preview>
      </Dropzone>
      {!!value?.length && (
        <FileList>
          {value.map((file, index) => (
            <FileListItem>
              <A icon={attachFile} href={file.url} target="_blank">
                {file.name}
              </A>
              <IconButton
                label={formatMessage(commonMessages.remove)}
                size="small"
                icon={_delete}
                color="primary"
                onClick={() => {
                  onDeleteClick(index);
                }}
              />
            </FileListItem>
          ))}
        </FileList>
      )}
    </div>
  );
};
