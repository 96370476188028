import { Chip, Icon } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { ScrollableSet } from 'components/scrollable/Set';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { companySalaryExchangeMessages } from '../../messages';

interface Props {
  queryParam: string;
}

export const ViolationFilter: React.FC<Props> = ({ queryParam }) => {
  const { push } = useHistory();
  const location = useLocation();

  const parsed = qs.parse(location.search);
  const violated = parsed[queryParam] === 'true';

  const setQueryParamValue = (value?: string) => {
    push({
      ...location,
      search: qs.stringify({
        ...parsed,
        [queryParam]: value,
      }),
    });
  };

  return (
    <ChipsWrapper>
      <ScrollableSet type="chip">
        <Chip
          text={<FormattedMessage {...commonMessages.all} />}
          leadingIcon={!violated ? <Icon icon={check} decorative /> : null}
          onClick={() => setQueryParamValue(undefined)}
          selected={!violated}
        />
        <Chip
          text={
            <FormattedMessage
              {...companySalaryExchangeMessages.violatedSalaryExchangeLabel}
            />
          }
          leadingIcon={violated ? <Icon icon={check} decorative /> : null}
          onClick={() => setQueryParamValue('true')}
          selected={violated}
        />
      </ScrollableSet>
    </ChipsWrapper>
  );
};
