import { ApolloError } from '@apollo/client';
import { HealthLevel } from '@frontend/benefit-types';
import {
  CheckboxField,
  DatePickerField,
  Form,
  RadioGroupField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { info } from '@frontend/ui/icons';
import { ManagingCompany } from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { ConversionModal } from '../components/ConversionModal';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import {
  benefitFormMessages,
  benefitHealthcareLevelMessages,
} from '../messages';
import { Agreements, BenefitPackageOption } from '../types';
import { getEarliestRetroactiveDate } from '../utils';
import { HEALTHCARE_LEVELS } from '../utils/constants';
import { MissingHealthcareAgreementNotification } from './MissingHealthcareAgreementNotification';

export interface FormValues {
  benefitPackageIds: string[];
  converting: boolean;
  convertingFrom: string;
  effectiveFrom: string;
  isActiveOnParentalLeave: boolean;
  level: HealthLevel | '';
  /*   waitingPeriod: string; */
}

interface Props {
  agreements: Agreements;
  benefitPackageOptions: BenefitPackageOption[];
  managedBy: ManagingCompany;
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape, isEdit?: boolean) =>
  Yup.object().shape({
    convertingFrom: Yup.string().when('converting', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    effectiveFrom: Yup.string().when([], {
      is: () => isEdit,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test({
            name: 'valid date',
            message: intl.formatMessage(
              validationMessages.dateMaxThreeMonthsOld,
            ),
            test: (value: string) =>
              isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
          }),
    }),
    level: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    /*     waitingPeriod: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ), */
  });

export const HealthcareForm: React.FC<Props> = ({
  agreements,
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  managedBy,
  submissionError,
}) => {
  const { formatMessage } = useIntl();

  const { values, isValid, isSubmitting } = useFormikContext<FormValues>();

  const isManagedByNode = managedBy === 'NODE';

  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);

  return (
    <>
      <Form>
        <Section>
          <UpcomingChangesNotification
            latestChangesEffectiveDate={latestChangesEffectiveDate}
          />
          <MissingHealthcareAgreementNotification agreements={agreements} />
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.level} />
            </SubsectionHeader>
            <RadioGroupField
              name="level"
              options={HEALTHCARE_LEVELS.map(level => ({
                label: (
                  <FormattedMessage
                    messages={benefitHealthcareLevelMessages}
                    select={level}
                  />
                ),
                value: level,
              }))}
              required
            />
          </Subsection>
          {/*           <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.waitingPeriod} />
            </SubsectionHeader>
            <RadioGroupField
              name="waitingPeriod"
              options={[
                {
                  label: formatMessage(benefitFormMessages.waitingPeriodYes),
                  value: 'true',
                },
                {
                  label: formatMessage(benefitFormMessages.waitingPeriodNo),
                  value: 'false',
                },
              ]}
              required
            />
          </Subsection> */}
          <Subsection>
            <SubsectionHeader
              iconButton={
                <IconButton
                  onClick={() => setIsConversionModalOpen(true)}
                  label={formatMessage(a11yMessages.information)}
                  icon={info}
                  size="small"
                />
              }
            >
              <FormattedMessage {...benefitFormMessages.conversion} />
            </SubsectionHeader>
            <CheckboxField
              name="converting"
              label={<FormattedMessage {...benefitFormMessages.convert} />}
            />
            {values.converting && (
              <TextGrid>
                <TextField
                  name="convertingFrom"
                  dense
                  label={
                    <FormattedMessage {...benefitFormMessages.conversionFrom} />
                  }
                  required
                  gridMargin
                />
              </TextGrid>
            )}
          </Subsection>
          {isManagedByNode && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...benefitFormMessages.isActiveOnParentalLeave}
                />
              </SubsectionHeader>
              <CheckboxField
                name="isActiveOnParentalLeave"
                label={formatMessage(
                  benefitFormMessages.pensionIsActiveOnParentalLeave,
                )}
              />
            </Subsection>
          )}
          <BenefitPackagesSection
            benefitPackageOptions={benefitPackageOptions}
            name="benefitPackageIds"
          />
          {isEdit && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...benefitFormMessages.effectiveEditDate} />
              </SubsectionHeader>
              <TextGrid>
                <DescriptionWrapper>
                  <FormattedMessage
                    {...benefitFormMessages.effectiveEditDateDescription}
                  />
                </DescriptionWrapper>
              </TextGrid>
              <Grid>
                <GridCell33>
                  <DatePickerField
                    dense
                    gridMargin
                    label={
                      <FormattedMessage
                        {...benefitFormMessages.effectiveEditDateLabel}
                      />
                    }
                    min={getEarliestRetroactiveDate()}
                    name="effectiveFrom"
                    required
                    type="month"
                  />
                </GridCell33>
              </Grid>
            </Subsection>
          )}
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={!isValid}
            >
              <FormattedMessage {...benefitFormMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <ConversionModal
        isOpen={isConversionModalOpen}
        setIsOpen={setIsConversionModalOpen}
      />
    </>
  );
};
