import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  uploadPdfInvoiceMutation,
  uploadPdfInvoiceMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { UPLOAD_PDF_INVOICE_MUTATION } from '../../graphql/mutations';
import { COMPANY_INVOICES_QUERY } from '../../graphql/queries';
import { invoiceMessages } from '../../messages';
import { UploadInvoiceFormValues } from './';

export interface SendInvitationEmployee {
  membershipId: string;
}

interface Props {
  companyId: string;
  onRequestClose: () => void;
}

interface Submit {
  loading: boolean;
  submit: (
    values: UploadInvoiceFormValues,
    helpers: FormikHelpers<UploadInvoiceFormValues>,
  ) => void;
  error?: ApolloError;
}

export const useSubmit = ({ companyId, onRequestClose }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [uploadPdfInvoice, { error, loading }] = useMutation<
    uploadPdfInvoiceMutation,
    uploadPdfInvoiceMutationVariables
  >(UPLOAD_PDF_INVOICE_MUTATION, {
    refetchQueries: [COMPANY_INVOICES_QUERY],
  });

  const callback: Submit['submit'] = async (values, helpers) => {
    try {
      if (!values.file?.[0]) {
        helpers.setFieldError(
          'file',
          formatMessage(invoiceMessages.invoiceRequired),
        );
        return;
      }

      await uploadPdfInvoice({
        variables: {
          input: {
            companyId,
            dueDate: values.dueDate,
            fileId: values.file[0].id,
            invoiceNumber: values.invoiceNumber,
            issueDate: values.issueDate,
            payableAmount: values.payableAmount,
            supplier: values.supplier,
          },
        },
      });

      send({
        message: formatMessage(invoiceMessages.uploadPdfInvoiceSuccess),
        type: 'success',
      });

      onRequestClose();
    } catch (e) {
      helpers.setErrors({
        submissionError: e?.message,
      });
    }
  };

  return {
    submit: callback,
    loading,
    error,
  };
};
