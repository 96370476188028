import { ApolloError } from '@apollo/client';
import { AccidentLevel } from '@frontend/benefit-types';
import {
  CheckboxField,
  DatePickerField,
  Form,
  RadioGroupField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { ManagingCompany } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import { benefitAccidentLevelMessages, benefitFormMessages } from '../messages';
import { Agreements, BenefitPackageOption } from '../types';
import { getEarliestRetroactiveDate } from '../utils';
import { ACCIDENT_LEVELS } from '../utils/constants';
import { MissingAccidentAgreementNotification } from './MissingAccidentAgreementNotification';

export interface FormValues {
  benefitPackageIds: string[];
  effectiveFrom: string;
  isActiveOnParentalLeave: boolean;
  level: AccidentLevel | '';
}

interface Props {
  agreements: Agreements;
  benefitPackageOptions: BenefitPackageOption[];
  managedBy: ManagingCompany;
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape, isEdit?: boolean) =>
  Yup.object().shape({
    effectiveFrom: Yup.string().when([], {
      is: () => isEdit,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test({
            name: 'valid date',
            message: intl.formatMessage(
              validationMessages.dateMaxThreeMonthsOld,
            ),
            test: (value: string) =>
              isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
          }),
    }),
    level: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const AccidentForm: React.FC<Props> = ({
  agreements,
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  managedBy,
  submissionError,
}) => {
  const { formatMessage } = useIntl();

  const { isValid, isSubmitting } = useFormikContext<FormValues>();

  const isManagedByNode = managedBy === 'NODE';

  return (
    <Form>
      <Section>
        <UpcomingChangesNotification
          latestChangesEffectiveDate={latestChangesEffectiveDate}
        />
        <MissingAccidentAgreementNotification agreements={agreements} />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.level} />
          </SubsectionHeader>
          <RadioGroupField
            name="level"
            options={ACCIDENT_LEVELS.map(level => ({
              label: (
                <FormattedMessage
                  messages={benefitAccidentLevelMessages}
                  select={level}
                />
              ),
              value: level,
            }))}
            required
          />
        </Subsection>
        {isManagedByNode && (
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...benefitFormMessages.isActiveOnParentalLeave}
              />
            </SubsectionHeader>
            <CheckboxField
              name="isActiveOnParentalLeave"
              label={formatMessage(
                benefitFormMessages.pensionIsActiveOnParentalLeave,
              )}
            />
          </Subsection>
        )}
        <BenefitPackagesSection
          benefitPackageOptions={benefitPackageOptions}
          name="benefitPackageIds"
        />
        {isEdit && (
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.effectiveEditDate} />
            </SubsectionHeader>
            <TextGrid>
              <DescriptionWrapper>
                <FormattedMessage
                  {...benefitFormMessages.effectiveEditDateDescription}
                />
              </DescriptionWrapper>
            </TextGrid>
            <Grid>
              <GridCell33>
                <DatePickerField
                  dense
                  gridMargin
                  label={
                    <FormattedMessage
                      {...benefitFormMessages.effectiveEditDateLabel}
                    />
                  }
                  min={getEarliestRetroactiveDate()}
                  name="effectiveFrom"
                  required
                  type="month"
                />
              </GridCell33>
            </Grid>
          </Subsection>
        )}
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage {...benefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
