import { CheckboxField, Table, Td, Th, Tr } from '@frontend/ui';
import {
  FlexSalaryExchangeStatus,
  salaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange as SalaryExchange,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React, { useId, useState } from 'react';

import { companySalaryExchangeMessages } from '../../messages';
import { Status } from '../Status';
import { TableActions } from '../TableActions';

export const SX_TABLE_ID = 'salary-exchanges-table';

interface Props {
  navigation: React.ReactNode;
  salaryExchanges: SalaryExchange[];
}

export const SalaryExchangesTable: React.FC<Props> = ({
  salaryExchanges,
  navigation,
}) => {
  const { formatMessage } = useIntl();
  const labelId = useId();

  const selectableSalaryExchanges = salaryExchanges.filter(
    sx => sx.status !== FlexSalaryExchangeStatus.INACTIVE,
  );
  const [userAccountIds, setUserAccountIds] = useState<string[]>([]);

  const indeterminate =
    (userAccountIds.length < selectableSalaryExchanges.length &&
      userAccountIds.length !== 0) ||
    !selectableSalaryExchanges.length;

  const selectAll = userAccountIds.length === selectableSalaryExchanges.length;

  const handleRowSelect = (id: string) => {
    const _userAccountIds = userAccountIds.some(_id => _id === id)
      ? userAccountIds.filter(_id => _id !== id)
      : [...userAccountIds, id];

    setUserAccountIds(_userAccountIds);
  };

  const handleAllRowSelect = (checked: boolean) => {
    const _userAccountIds = checked
      ? selectableSalaryExchanges.map(({ userAccountId }) => userAccountId)
      : [];
    setUserAccountIds(_userAccountIds);
  };

  return (
    <div id={SX_TABLE_ID}>
      <Table
        feedback={
          salaryExchanges.length
            ? {
                actions: <TableActions userAccountIds={userAccountIds} />,
                active: !!userAccountIds.length,
                children: (
                  <FormattedMessage
                    {...commonMessages.nRowsSelected}
                    values={{ count: userAccountIds.length }}
                  />
                ),
              }
            : undefined
        }
        navigation={navigation}
      >
        <thead>
          <Tr>
            <Th type="input">
              <CheckboxField
                checked={selectAll}
                onChange={handleAllRowSelect}
                indeterminate={indeterminate}
                disabled={!selectableSalaryExchanges.length}
                aria-label={formatMessage(commonMessages.selectAllRows)}
              />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.from} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th type="number">
              <FormattedMessage
                {...companySalaryExchangeMessages.benefitQualifyingIncome}
              />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.deduction} />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.premium} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.via} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!salaryExchanges.length ? (
            <Tr>
              <Td colSpan={7}>
                <FormattedMessage
                  {...companySalaryExchangeMessages.noSalaryExchangeItems}
                />
              </Td>
            </Tr>
          ) : (
            salaryExchanges.map(item => (
              <Tr key={item.id}>
                <Td type="input">
                  <CheckboxField
                    indeterminate={
                      item.status === FlexSalaryExchangeStatus.INACTIVE
                    }
                    disabled={item.status === FlexSalaryExchangeStatus.INACTIVE}
                    id={item.id}
                    checked={userAccountIds.includes(item.userAccountId)}
                    onChange={() => handleRowSelect(item.userAccountId)}
                    aria-labelledby={labelId}
                  />
                </Td>
                <Td>
                  {item.effectiveDate ? (
                    <FormattedDate value={item.effectiveDate} />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td id={labelId}>
                  {item.membership.givenName} {item.membership.lastName}
                </Td>
                <Td type="number">
                  {item.remuneration ? (
                    <FormattedCurrency
                      currency={item.remuneration.currency ?? ''}
                      value={item.remuneration.value}
                      after={
                        item.remuneration.unitCode &&
                        item.remuneration.unitCode !== UnitCodeOptions.LS ? (
                          <>
                            /
                            <FormattedMessage
                              select={item.remuneration.unitCode}
                              messages={unitCodeSuffixMessages}
                            />
                          </>
                        ) : null
                      }
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td type="number">
                  {item.deductionValue ? (
                    <Status
                      item={item}
                      label={
                        <FormattedCurrency
                          after="PER_MONTH"
                          currency="SEK"
                          value={item.deductionValue}
                        />
                      }
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td type="number">
                  {item.premium ? (
                    <FormattedCurrency
                      after="PER_MONTH"
                      currency="SEK"
                      value={item.premium}
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td>
                  <FormattedMessage
                    select={item.provider}
                    messages={salaryExchangeProviderMessages}
                  />
                </Td>
              </Tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};
