import { ApolloError, useMutation } from '@apollo/client';
import { EpassiInput } from '@frontend/benefit-types';
import { toNumber } from '@frontend/utils';
import {
  createBenefitsMutation,
  createBenefitsMutationVariables,
  FlexCreateBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues } from '../../../form/epassi';
import { benefitFormMessages } from '../../../form/messages';
import { CREATE_BENEFITS_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createBenefit, { error: submissionError }] = useMutation<
    createBenefitsMutation,
    createBenefitsMutationVariables
  >(CREATE_BENEFITS_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'flexBenefits',
      });
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'benefitPackages',
      });
      cache.gc();
    },
  });

  const submit = async (values: FormValues) => {
    try {
      const input: EpassiInput<FlexCreateBenefitInput> = {
        companyId: params.companyId,
        benefitTypeName: 'epassi',
        configuration: {
          allowance: toNumber(values.allowance) ?? null,
          customerId: values.customerId ? values.customerId : null,
          onboardingDate: format(new Date(values.effectiveFrom), 'yyyy-MM-dd'),
        },
        entitlementRules: {
          benefitPackageIds: values.benefitPackageIds,
          ouIds: [],
          accountingCostIds: [],
        },
      };

      await createBenefit({
        variables: {
          input: {
            benefits: [input],
          },
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });

      push(`/companies/${params.companyId}/benefit-packages/benefits`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
