import {
  DatePickerField,
  Form,
  Formik,
  FormikProps,
  NumberField,
  SelectField,
  TextField,
} from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { upload } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { InvoiceSupplier } from 'app/apollo/graphql/types';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { Props as AssistChipProps } from 'components/AssistChip';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { FileUploadField } from 'components/formik/FileUploadField';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import { INVOICE_SUPPLIERS } from '../..';
import { invoiceMessages } from '../../messages';
import { useSubmit } from './use-submit';

export interface UploadInvoiceFormValues {
  dueDate: string | null;
  file: [FileInfo] | null;
  invoiceNumber: string;
  issueDate: string;
  payableAmount: string;
  supplier: InvoiceSupplier;
  submissionError?: string;
}

interface UploadInvoiceFormProps {
  companyId: string;
  onRequestClose: () => void;
  submit?: () => Promise<void>;
}

const UploadInvoiceForm: React.FC<
  FormikProps<UploadInvoiceFormValues> & UploadInvoiceFormProps
> = ({ onRequestClose, companyId, isSubmitting, errors }) => {
  const { formatMessage } = useIntl();

  return (
    <Form>
      <ModalHeader>
        <FormattedMessage {...invoiceMessages.uploadPdfInvoice} />
      </ModalHeader>
      <ModalBody>
        <FileUploadField
          name="file"
          label={<FormattedMessage {...invoiceMessages.uploadPdfInvoice} />}
          accept={{ 'application/pdf': ['.pdf'] }}
          companyId={companyId}
          multiple={false}
        />
        <TextField
          dense
          name="invoiceNumber"
          label={<FormattedMessage {...invoiceMessages.invoiceNumber} />}
          required
        />
        <SelectField
          gridMargin
          name="supplier"
          label={<FormattedMessage {...invoiceMessages.supplier} />}
          options={Object.values(InvoiceSupplier).map(supplier => ({
            label: INVOICE_SUPPLIERS[supplier],
            value: supplier,
          }))}
          required
          fixed
        />
        <DatePickerField
          fixed
          dense
          name="issueDate"
          label={<FormattedMessage {...invoiceMessages.issueDate} />}
          required
        />
        <DatePickerField
          fixed
          dense
          name="dueDate"
          label={<FormattedMessage {...invoiceMessages.dueDate} />}
        />
        <NumberField
          dense
          name="payableAmount"
          label={<FormattedMessage {...invoiceMessages.payableAmount} />}
          affix={formatMessage(suffixMessages.kr)}
          required
        />
        {errors.submissionError && (
          <NotificationCard type="error" inModal>
            {errors.submissionError}
          </NotificationCard>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button text type="submit" loading={isSubmitting}>
            <FormattedMessage {...commonMessages.send} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Form>
  );
};

interface Props {
  companyId: string;
}

export const UploadInvoiceModal: React.FC<Props> = ({ companyId }) => {
  const history = useHistory();
  const location = useLocation();

  const { 'upload-pdf': isOpen } = qs.parse(location.search);

  const onRequestClose = () => {
    stripSearchParams(history, location, ['upload-pdf']);
  };

  const { submit, error } = useSubmit({
    companyId,
    onRequestClose,
  });

  return (
    <Formik<UploadInvoiceFormValues>
      initialValues={{
        dueDate: null,
        file: null,
        invoiceNumber: '',
        issueDate: '',
        payableAmount: '',
        supplier: '' as InvoiceSupplier,
      }}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => (
        <>
          {error && <GraphQlError error={error} />}
          <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
            <UploadInvoiceForm
              {...formikProps}
              {...{
                onRequestClose,
                companyId,
              }}
            />
          </Modal>
        </>
      )}
    </Formik>
  );
};

export const getUploadPdfAction = (
  location: RouteComponentProps['location'],
): AssistChipProps => ({
  to: {
    ...location,
    search: qs.stringify({ 'upload-pdf': true }),
  },
  text: <FormattedMessage {...invoiceMessages.uploadPdfInvoice} />,
  leadingIcon: upload,
});
