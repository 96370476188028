import gql from 'graphql-tag';

import { validCompilationDetails } from './fragments';

export const COMPILATION_NOTIFICATIONS_QUERY = gql`
  query compilationNotificationsQuery($id: ID!) {
    proposal(id: $id) {
      id
      validity {
        validCompilation {
          ...validCompilationDetails
        }
      }
    }
  }
  ${validCompilationDetails}
`;
