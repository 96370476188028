import { defineMessages } from 'react-intl';

export const discountMessages = defineMessages({
  configuration: {
    id: 'discountMessages.configuration',
    defaultMessage: 'Anpassa',
  },
  presentation: {
    id: 'discountMessages.presentation',
    defaultMessage: 'Presentation',
  },
  successfulBenefitSave: {
    id: 'discountMessages.successfulBenefitSave',
    defaultMessage: `Förmånen "{name}" sparades!`,
  },
  createDiscount: {
    id: 'discountMessages.createDiscount',
    defaultMessage: 'Skapa erbjudande',
  },
  editBenefitPresentation: {
    id: 'discountMessages.editBenefitPresentation',
    defaultMessage: 'Redigera presentation',
  },
  editTitle: {
    id: 'discountMessages.editTitle',
    defaultMessage: 'Redigera titel',
  },
  title: {
    id: 'discountMessages.title',
    defaultMessage: 'Titel',
  },
  short: {
    id: 'discountMessages.short',
    defaultMessage: 'Underrubrik',
  },
  primaryCta: {
    id: 'discountMessages.primaryCta',
    defaultMessage: 'Primär CTA',
  },
  secondaryCta: {
    id: 'discountMessages.secondaryCta',
    defaultMessage: 'Sekundär CTA',
  },
  linkAddress: {
    id: 'discountMessages.linkAddress',
    defaultMessage: 'Länkadress',
  },
  editLinks: {
    id: 'discountMessages.editLinks',
    defaultMessage: 'Redigera länkar',
  },
  exampleLink: {
    id: 'discountMessages.exampleLink',
    defaultMessage: 'Exempellänk',
  },
  discountCode: {
    id: 'discountMessages.discountCode',
    defaultMessage: 'Rabattkod',
  },
  attachments: {
    id: 'discountMessages.attachments',
    defaultMessage: 'Bifogad fil',
  },
  attachFile: {
    id: 'discountMessages.attachFile',
    defaultMessage: 'Bifoga fil',
  },
  embeddEditorToolbarOption: {
    id: 'discountMessages.embeddEditorToolbarOption',
    defaultMessage: 'Bädda in',
  },
  newCategory: {
    id: 'discountMessages.newCategory',
    defaultMessage: 'Ny Kategori',
  },
  addCategory: {
    id: 'discountMessages.addCategory',
    defaultMessage: 'Lägg till kategori',
  },
  categoryNameEnglish: {
    id: 'discountMessages.categoryNameEnglish',
    defaultMessage: 'Namn på kategori - Engelska',
  },
  categoryNameSwedish: {
    id: 'discountMessages.categoryNameSwedish',
    defaultMessage: 'Namn på kategori - Svenska',
  },
  offer: {
    id: 'discountMessages.offer',
    defaultMessage: 'Erbjudande',
  },
  websiteAddress: {
    id: 'discountMessages.websiteAddress',
    defaultMessage: 'Address till webbplats',
  },
  addSupplier: {
    id: 'discountMessages.addSupplier',
    defaultMessage: 'Lägg till leverantör',
  },
  visibilityDescription: {
    id: 'discountMessages.visibilityDescription',
    defaultMessage:
      'Om ni vill gömma erbjudandet från förmånsportalen, välj bort Visa i förmånsportal.',
  },
  visibilityLabel: {
    id: 'discountMessages.visibilityLabel',
    defaultMessage: 'Visa i förmånsportal',
  },
  submit: {
    id: 'discountMessages.submit',
    defaultMessage: 'Spara',
  },
  organizationalUnitsDescription: {
    id: 'discountMessages.organizationalUnitsDescription',
    defaultMessage:
      'Ska erbjudandet endast vara tillgängligt för vissa avdelningar väljer du dessa här, annars är de tillgängliga för alla.',
  },
  editDiscount: {
    id: 'discountMessages.editDiscount',
    defaultMessage: 'Redigera {name}',
  },
  alignCoverImageTop: {
    id: 'discountMessages.alignCoverImageTop',
    defaultMessage: 'Justera omslagsbild upptill',
  },
  alignCoverImageCenter: {
    id: 'discountMessages.alignCoverImageCenter',
    defaultMessage: 'Justera omslagsbild i mitten',
  },
  alignCoverImageBottom: {
    id: 'discountMessages.alignCoverImageBottom',
    defaultMessage: 'Justera omslagsbild nedtill',
  },
});
