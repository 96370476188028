import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  CheckboxGroupField,
  Form,
  RadioGroupField,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Icon,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
  Text,
  Tooltip,
} from '@frontend/ui';
import { info } from '@frontend/ui/icons';
import {
  ProposalCompanySize,
  ProposalSicknessLevel,
} from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { commonBenefitMessages } from 'app/messages/benefits';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import {
  smeBenefitDisabilityLevelMessages,
  smeBenefitFormMessages,
} from 'features/sme/messages/sme';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { ConversionModal } from '../components/ConversionModal';
import { SALARY_MULTIPLIER_OPTIONS, SICKNESS_LEVELS } from '../utils/constants';
import { ConsolidationModal } from './ConsolidationModal';

export interface FormValues {
  benefitPackageIds: string[];
  converting: boolean;
  convertingFrom: string;
  extendSalaryLimit: boolean;
  extendedSickPay: boolean;
  level: ProposalSicknessLevel | '';
  salaryMultiplier: string;
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  values: FormValues;
  benefitPackages?: readonly BenefitPackage[] | null;
  companySize?: ProposalCompanySize;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    level: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    convertingFrom: Yup.string().when('converting', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    salaryMultiplier: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const DisabilityForm: React.FC<Props> = ({
  benefitPackages,
  companySize,
  isSubmitting,
  isValid,
  submissionError,
  values,
}) => {
  const { formatNumber } = useIntl();

  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
  const [isConsolidationModalOpen, setIsConsolidationModalOpen] =
    useState(false);
  const { formatMessage } = useIntl();
  const disabled = useProposalReadonly();

  const levelIconButton: Partial<
    Record<ProposalSicknessLevel, React.ReactNode>
  > = {
    CONSOLIDATION: (
      <IconButton
        onClick={() => setIsConsolidationModalOpen(true)}
        label={formatMessage(a11yMessages.information)}
        icon={info}
        size="small"
      />
    ),
    HYBRID: (
      <Tooltip
        parent={<Icon icon={info} size="small" decorative />}
        tooltipContent={
          <FormattedMessage
            {...smeBenefitFormMessages.requiresBusinessCouncilDecision}
          />
        }
      />
    ),
  };

  return (
    <>
      <Form>
        <Section>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.level} />
            </SubsectionHeader>
            <RadioGroupField
              name="level"
              options={SICKNESS_LEVELS.map(level => ({
                label: (
                  <Text icon={levelIconButton[level]}>
                    {formatMessage({
                      select: level,
                      messages: smeBenefitDisabilityLevelMessages,
                    })}
                  </Text>
                ),
                value: level,
              }))}
              disabled={disabled}
            />
          </Subsection>
          {companySize === ProposalCompanySize.LT_10 && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...smeBenefitFormMessages.extendSalaryLimit}
                />
              </SubsectionHeader>
              <CheckboxField
                name="extendSalaryLimit"
                label={formatMessage(
                  smeBenefitFormMessages.extendSalaryLimitLabel,
                )}
              />
            </Subsection>
          )}
          <Subsection>
            <SubsectionHeader
              iconButton={
                <IconButton
                  onClick={() => setIsConversionModalOpen(true)}
                  label={formatMessage(a11yMessages.information)}
                  icon={info}
                  size="small"
                  disabled={disabled}
                />
              }
            >
              <FormattedMessage {...smeBenefitFormMessages.conversion} />
            </SubsectionHeader>
            <CheckboxField
              name="converting"
              label={<FormattedMessage {...smeBenefitFormMessages.convert} />}
              disabled={disabled}
            />
            {values.converting && (
              <TextGrid>
                <TextField
                  name="convertingFrom"
                  dense
                  label={
                    <FormattedMessage
                      {...smeBenefitFormMessages.conversionFrom}
                    />
                  }
                  required
                  gridMargin
                  disabled={disabled}
                />
              </TextGrid>
            )}
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.salaryMultiplier} />
            </SubsectionHeader>
            <Grid>
              <GridCell33>
                <SelectField
                  name="salaryMultiplier"
                  dense
                  label={
                    <FormattedMessage
                      {...smeBenefitFormMessages.salaryMultiplier}
                    />
                  }
                  options={SALARY_MULTIPLIER_OPTIONS.map(value => ({
                    label: formatNumber(value, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                    value,
                  }))}
                  required
                  gridMargin
                  disabled={disabled}
                />
              </GridCell33>
            </Grid>
          </Subsection>
          <Subsection>
            <TextGrid>
              <SubsectionHeader>
                <FormattedMessage {...smeBenefitFormMessages.extendedSickPay} />
              </SubsectionHeader>
              <CheckboxField
                name="extendedSickPay"
                label={formatMessage(
                  smeBenefitFormMessages.extendedSickPayLabel,
                )}
                disabled={disabled}
              />
            </TextGrid>
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
            </SubsectionHeader>
            {benefitPackages?.length ? (
              <>
                <DescriptionWrapper>
                  <FormattedMessage
                    {...smeBenefitFormMessages.benefitPackagesDescription}
                  />
                </DescriptionWrapper>

                <CheckboxGroupField
                  name="benefitPackageIds"
                  options={benefitPackages.map(benefitPackage => ({
                    label: benefitPackage.name,
                    value: benefitPackage.id,
                  }))}
                  disabled={disabled}
                />
              </>
            ) : (
              <NotificationCard type="warning">
                <FormattedMessage
                  {...commonBenefitMessages.noBenefitPackages}
                />
              </NotificationCard>
            )}
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={disabled || !isValid}
            >
              <FormattedMessage {...smeBenefitFormMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <ConversionModal
        isOpen={isConversionModalOpen}
        setIsOpen={setIsConversionModalOpen}
      />
      <ConsolidationModal
        isOpen={isConsolidationModalOpen}
        setIsOpen={setIsConsolidationModalOpen}
      />
    </>
  );
};
