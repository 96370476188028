import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const COMPANY_NOTIFICATIONS_QUERY = gql`
  query companyNotificationsQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $status: NotificationStatus!
  ) {
    notifications(
      companyIds: [$companyId]
      first: $first
      status: $status
      after: $after
    ) {
      totalCount
      pageInfo {
        ...pageInfoDetails
      }
      edges {
        node {
          id
          type
          userAccountId
          companyId
          externalId
          status
          statusChangedBy {
            name
            userAccountId
            adminUserExternalId
          }
          statusUpdatedAt
          createdAt
          metadata {
            ... on MissingBenefitPackageMetadata {
              fullName
            }
            ... on MissingEmailMetadata {
              fullName
            }
            ... on MissingSalaryMetadata {
              fullName
              from
            }
            ... on MissingFcfwAssertionMetadata {
              fullName
            }
            ... on MissingPensionPremiumMetadata {
              fullName
            }
            ... on SxErrorMetadata {
              fullName
              deductionValue
              effectiveDate
            }
            ... on SxRequestedMetadata {
              fullName
            }
            ... on SxRequestApprovedMetadata {
              fullName
              deductionValue
              effectiveDate
            }
            ... on SxRequestRejectedMetadata {
              deductionValue
              effectiveDate
              reason
            }
            ... on SxViolatedMetadata {
              fullName
            }
            ... on SxOnetimeAvailableMetadata {
              onetimeSalary
              cutoffDate
              maxDeductionValue
            }
            ... on MaxTotalPensionCostExceededMetadata {
              fullName
              year
              maxTotalPensionCost
            }
            ... on MaxSalaryToPensionRatioExceededMetadata {
              fullName
              year
            }
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;
