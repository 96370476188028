import gql from 'graphql-tag';

import { invoiceDetails } from './fragments';

export const UPDATE_INVOICE_STATUS_MUTATION = gql`
  mutation updateInvoiceStatusMutation($input: UpdateInvoiceStatusInput!) {
    updateInvoiceStatus(input: $input) {
      invoice {
        ...invoiceDetails
      }
    }
  }
  ${invoiceDetails}
`;

export const UPLOAD_PDF_INVOICE_MUTATION = gql`
  mutation uploadPdfInvoiceMutation($input: UploadPdfInvoiceInput!) {
    uploadPdfInvoice(input: $input) {
      invoiceId
    }
  }
`;
