import gql from 'graphql-tag';

export const DISCOUNT_CONFIGURATION_QUERY = gql`
  query discountConfigurationQuery($companyId: ID!, $benefitId: ID!) {
    benefit: flexBenefit(id: $benefitId) {
      id
      content {
        locale
      }
      entitlementRules {
        benefitPackageIds
        ouIds
      }
      hidden
    }
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
      organizationalUnits {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
