import {
  LifeAgreementType,
  ProposalCompanySize,
} from 'app/apollo/graphql/types';

interface Args {
  companySize: ProposalCompanySize;
}

/**
 * Computes the valid agreement type for the given company size.
 *
 * @returns the valid agreement type for the given company size, or empty string
 * when company size is ProposalCompanySize.GTE_50
 */
export const getAgreementTypeForCompanySize = (
  args: Args,
): LifeAgreementType | '' => {
  const { companySize } = args;

  if (companySize === ProposalCompanySize.LT_50) {
    return LifeAgreementType.LIV1099;
  }

  if (
    companySize === ProposalCompanySize.GTE_250 ||
    companySize === ProposalCompanySize.GTE_1000
  ) {
    return LifeAgreementType.LIV100;
  }

  return '';
};
