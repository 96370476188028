import { Button, CheckboxField, Table, Th, Tr } from '@frontend/ui';
import { MembershipKey, pageInfoDetails } from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyTable } from 'components/EmptyTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import { AdviceFilterChips } from 'features/advisor/components/Filter';
import { useCheckboxRows } from 'features/advisor/utils/use-checkbox-rows';
import React from 'react';

import { PersonalAdviceEmployee } from '..';
import { PersonalAdviceEmployeeTableRow } from '../components/PersonalAdviceEmployeeTableRow';

interface Props {
  clearFilter: () => void;
  employees: Array<PersonalAdviceEmployee>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  openSendInvitationsModal: (membershipKeys: Array<MembershipKey>) => void;
  pageInfo: pageInfoDetails;
  toggleFilterSideSheet: () => void;
}

const EmployeeTableColgroup = () => (
  <colgroup>
    <col style={{ width: '5%' }} />
    <col style={{ width: '13%' }} />
    <col style={{ width: 'auto' }} />
    <col style={{ width: '13%' }} />
    <col style={{ width: '11%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '10%' }} />
    <col style={{ width: '15%' }} />
  </colgroup>
);

const EmployeeTableThead = ({
  allSelected,
  indeterminate,
  toggleSelectAll,
}: {
  allSelected: boolean;
  toggleSelectAll: (checked: boolean) => void;
  indeterminate: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <thead>
      <Tr withInlineMenuTh>
        <Th type="input">
          <CheckboxField
            checked={allSelected}
            onChange={toggleSelectAll}
            indeterminate={indeterminate}
            aria-label={formatMessage(commonMessages.selectAllRows)}
          />
        </Th>
        <Th align="left">
          <FormattedMessage {...commonMessages.id} />
        </Th>
        <Th align="left">
          <FormattedMessage {...commonMessages.name} />
        </Th>
        <Th align="left">
          <FormattedMessage {...commonMessages.company} />
        </Th>
        <Th align="right">
          <FormattedMessage {...advisorMessages.lastMeeting} />
        </Th>
        <Th align="left">
          <FormattedMessage {...advisorMessages.entitledToNewMeeting} />
        </Th>
        <Th align="right">
          <FormattedMessage {...advisorMessages.salary} />
        </Th>
        <Th align="right">
          <FormattedMessage {...advisorMessages.latestEmailSentAt} />
        </Th>
      </Tr>
    </thead>
  );
};

export const PersonalAdviceEmployeesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  employees,
  openAddHeldMeetingModal,
  clearFilter,
  toggleFilterSideSheet,
  openSendInvitationsModal,
}) => {
  const rowKeys = employees
    .filter(({ allowedToHaveMeeting }) => allowedToHaveMeeting)
    .map(employee => ({
      userAccountId: employee.userAccountId,
      companyId: employee.companyId,
      rowId: employee.membership.id,
    }));

  const {
    checkedRowKeys,
    allSelected,
    indeterminate,
    toggleSelectAll,
    toggleSelectRow,
  } = useCheckboxRows(rowKeys);

  return (
    <>
      <SearchField
        label={<FormattedMessage {...advisorMessages.searchEntitledEmployee} />}
      />
      <ChipsWrapper belowSearch>
        <AdviceFilterChips
          clearFilter={clearFilter}
          toggleFilterSideSheet={toggleFilterSideSheet}
        />
      </ChipsWrapper>
      <Table
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        }
        fixed
        feedback={{
          actions: (
            <Button
              text
              onClick={() =>
                openSendInvitationsModal(
                  checkedRowKeys.map(({ userAccountId, companyId }) => ({
                    userAccountId,
                    companyId,
                  })),
                )
              }
            >
              <FormattedMessage {...advisorMessages.sendInvitations} />
            </Button>
          ),
          active: checkedRowKeys.length > 0,
          children: (
            <FormattedMessage
              {...commonMessages.nRowsSelected}
              values={{ count: checkedRowKeys.length }}
            />
          ),
        }}
      >
        {employees.length === 0 ? (
          <EmptyTable noMatchMessage={advisorMessages.noEmployeeMatch} />
        ) : (
          <>
            <EmployeeTableColgroup />
            <EmployeeTableThead
              allSelected={allSelected}
              indeterminate={indeterminate}
              toggleSelectAll={toggleSelectAll}
            />
            <tbody>
              {employees.map(employee => (
                <PersonalAdviceEmployeeTableRow
                  key={employee.membership.id}
                  employee={employee}
                  sendInvitation={() =>
                    openSendInvitationsModal([
                      {
                        userAccountId: employee.userAccountId,
                        companyId: employee.companyId,
                      },
                    ])
                  }
                  openAddHeldMeetingModal={openAddHeldMeetingModal}
                  checked={checkedRowKeys.some(
                    key => key.rowId === employee.membership.id,
                  )}
                  onCheckboxChange={checked => {
                    toggleSelectRow(checked, {
                      userAccountId: employee.userAccountId,
                      companyId: employee.companyId,
                      rowId: employee.membership.id,
                    });
                  }}
                />
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  );
};
