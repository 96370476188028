import { ApolloError, useMutation } from '@apollo/client';
import { AdvinansSalaryExchangeInput } from '@frontend/benefit-types';
import { toDecimalFraction, toNumber } from '@frontend/utils';
import {
  createBenefitsMutation,
  createBenefitsMutationVariables,
  FlexCreateBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { benefitFormMessages } from '../../../form/messages';
import { FormValues } from '../../../form/salary-exchange';
import { SALARY_EXCHANGE_PROVIDER_INPUT_VALUES } from '../../../form/utils/constants';
import { CREATE_BENEFITS_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createBenefit, { error: submissionError }] = useMutation<
    createBenefitsMutation,
    createBenefitsMutationVariables
  >(CREATE_BENEFITS_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'flexBenefits',
      });
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'benefitPackages',
      });
      cache.gc();
    },
  });

  const submit = async (values: FormValues) => {
    try {
      const input: AdvinansSalaryExchangeInput<FlexCreateBenefitInput> = {
        companyId: params.companyId,
        benefitTypeName: 'advinans_salary_exchange',
        configuration: {
          automaticApproval: values.automaticApproval,
          basePensionableIncomePayrollElementCode: values.payrollElementCode,
          basePensionableIncomePayrollElementMultiplier:
            values.payrollElementMultiplier,
          incomeMinIbb: toNumber(values.incomeMinIbb),
          ongoingDeductionShareMax: toNumber(
            toDecimalFraction(values.ongoingDeductionShareMax),
          ),
          ongoingMax: toNumber(values.ongoingMax),
          pensionTaxBenefitShare:
            toNumber(toDecimalFraction(values.pensionTaxBenefitShare)) ?? 0,
          providers: values.providers.flatMap(
            provider => SALARY_EXCHANGE_PROVIDER_INPUT_VALUES[provider],
          ),
          retirementAge: toNumber(values.retirementAge),
        },
        entitlementRules: {
          benefitPackageIds: values.benefitPackageIds,
          ouIds: [],
          accountingCostIds: [],
        },
      };

      await createBenefit({
        variables: {
          input: {
            benefits: [input],
          },
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });

      push(`/companies/${params.companyId}/benefit-packages/benefits`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
