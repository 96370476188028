import { ApolloError } from '@apollo/client';
import {
  DatePickerField,
  Form,
  NumberField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { suffixMessages, validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import { benefitFormMessages } from '../messages';
import { BenefitPackageOption } from '../types';
import { getEarliestRetroactiveDate } from '../utils';

export interface FormValues {
  allowance: string;
  benefitPackageIds: string[];
  effectiveFrom: string;
}

interface Props {
  benefitPackageOptions: BenefitPackageOption[];
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape, isEdit?: boolean) =>
  Yup.object().shape({
    allowance: Yup.number()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .moreThan(0, intl.formatMessage(validationMessages.isPositiveNumber)),
    effectiveFrom: Yup.string().when([], {
      is: () => isEdit,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test({
            name: 'valid date',
            message: intl.formatMessage(
              validationMessages.dateMaxThreeMonthsOld,
            ),
            test: (value: string) =>
              isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
          }),
    }),
  });

export const WellnessForm: React.FC<Props> = ({
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  submissionError,
}) => {
  const { isValid, isSubmitting } = useFormikContext<FormValues>();
  const { formatMessage } = useIntl();

  return (
    <Form>
      <Section>
        <UpcomingChangesNotification
          latestChangesEffectiveDate={latestChangesEffectiveDate}
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.allowance} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...benefitFormMessages.wellnessAllowanceDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <Grid>
            <GridCell33>
              <NumberField
                affix={formatMessage(suffixMessages.kr)}
                decimalScale={0}
                dense
                gridMargin
                label={
                  <FormattedMessage {...benefitFormMessages.allowanceLabel} />
                }
                name="allowance"
                required
              />
            </GridCell33>
          </Grid>
        </Subsection>
        <BenefitPackagesSection
          benefitPackageOptions={benefitPackageOptions}
          isWellnessBenefit
          name="benefitPackageIds"
        />
        {isEdit && (
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.effectiveEditDate} />
            </SubsectionHeader>
            <TextGrid>
              <DescriptionWrapper>
                <FormattedMessage
                  {...benefitFormMessages.effectiveEditDateDescription}
                />
              </DescriptionWrapper>
            </TextGrid>
            <Grid>
              <GridCell33>
                <DatePickerField
                  dense
                  gridMargin
                  label={
                    <FormattedMessage
                      {...benefitFormMessages.effectiveEditDateLabel}
                    />
                  }
                  min={getEarliestRetroactiveDate()}
                  name="effectiveFrom"
                  required
                  type="month"
                />
              </GridCell33>
            </Grid>
          </Subsection>
        )}
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage {...benefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
