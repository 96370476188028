import gql from 'graphql-tag';

export const COMPANY_EDIT_EPASSI_BIKE_BENEFIT_QUERY = gql`
  query companyEditEpassiBikeBenefitQuery($benefitId: ID!, $companyId: ID!) {
    flexBenefit(id: $benefitId) {
      id
      type
      configEffectiveDate
      configuration {
        ... on EpassiBikeConfiguration {
          useDefaultPolicy
          customerId
          files {
            name
            path
            url
          }
        }
      }
      content {
        locale
      }
      entitlementRules {
        accountingCostIds
        benefitPackageIds
        ouIds
      }
    }
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            benefits(onlyGetBenefits: true) {
              edges {
                node {
                  id
                  type
                }
              }
            }
            id
            name
          }
        }
      }
    }
  }
`;
