import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  CheckboxGroupField,
  Form,
  NumberField,
  RadioGroupField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { info } from '@frontend/ui/icons';
import { toNumber } from '@frontend/utils';
import {
  ProposalDefaultManagementType,
  ProposalPremiumCalculationMethod,
  ProposalPremiumDeductions,
} from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { commonBenefitMessages } from 'app/messages/benefits';
import {
  commonMessages,
  suffixMessages,
  validationMessages,
} from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell25, TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import {
  smeBenefitFormMessages,
  smeBenefitPensionManagementTypeMessages,
  smeBenefitPensionPremiumCalculationMethodMessages,
  smePensionPremiumDeductionMessages,
} from 'features/sme/messages/sme';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { ConversionModal } from '../components/ConversionModal';
import {
  CustomPremiumMatrixSection,
  validationSchema as customPremiumMatrixValidationSchema,
} from '../components/CustomPremiumMatrixSection';
import { ItpPremiumMatrixSection } from '../components/ItpPremiumMatrixSection';
import {
  DEFAULT_MANAGEMENT_TYPES,
  PREMIUM_CALCULATION_METHODS,
  PREMIUM_DEDUCTIONS,
} from '../utils/constants';
import { ProposalPremiumMatrixFormValues } from '../utils/types';

export interface FormValues {
  benefitPackageIds: string[];
  convertingPremiumWaiver: boolean;
  convertingPremiumWaiverFrom: string;
  customPremiumMatrix: ProposalPremiumMatrixFormValues;
  defaultManagementType: ProposalDefaultManagementType | '';
  extraFixedPremium: boolean;
  itpPremiumMatrix: ProposalPremiumMatrixFormValues;
  premiumCalculationMethod: ProposalPremiumCalculationMethod | '';
  premiumDeductions: ProposalPremiumDeductions[];
  premiumWaiver: 'yes' | 'no' | '';
  retirementAge: string;
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  values: FormValues;
  benefitPackages?: readonly BenefitPackage[] | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object()
    .shape({
      convertingPremiumWaiverFrom: Yup.string().when(
        'convertingPremiumWaiver',
        {
          is: true,
          then: schema =>
            schema.required(
              intl.formatMessage(validationMessages.mandatoryField),
            ),
        },
      ),
      defaultManagementType: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      premiumCalculationMethod: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      premiumWaiver: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      retirementAge: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test({
          name: 'at least 55',
          message: intl.formatMessage(smeBenefitFormMessages.minRetirementAge),
          test: (value: string) => (toNumber(value) ?? 0) >= 55,
        }),
    })
    .concat(customPremiumMatrixValidationSchema('customPremiumMatrix', intl));

export const EmployersPensionForm: React.FC<Props> = ({
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
  values,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();
  const disabled = useProposalReadonly();
  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);

  return (
    <>
      <Form>
        <Section>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.retirementAge} />
            </SubsectionHeader>
            <Grid>
              <GridCell25>
                <NumberField
                  affix={formatMessage(suffixMessages.age)}
                  allowNegative={false}
                  decimalScale={0}
                  dense
                  gridMargin
                  label={
                    <FormattedMessage
                      {...smeBenefitFormMessages.retirementAge}
                    />
                  }
                  locale={locale}
                  name="retirementAge"
                  required
                  disabled={disabled}
                />
              </GridCell25>
            </Grid>
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...smeBenefitFormMessages.premiumCalculationMethod}
              />
            </SubsectionHeader>
            <RadioGroupField
              name="premiumCalculationMethod"
              options={PREMIUM_CALCULATION_METHODS.map(method => ({
                label: formatMessage({
                  select: method,
                  messages: smeBenefitPensionPremiumCalculationMethodMessages,
                }),
                value: method,
              }))}
              required
              disabled={disabled}
            />
          </Subsection>
          {['CUSTOM_MATRIX', 'ITP_MATRIX'].includes(
            values.premiumCalculationMethod,
          ) && (
            <>
              {values.premiumCalculationMethod === 'CUSTOM_MATRIX' && (
                <CustomPremiumMatrixSection name="customPremiumMatrix" />
              )}
              {values.premiumCalculationMethod === 'ITP_MATRIX' && (
                <ItpPremiumMatrixSection name="itpPremiumMatrix" />
              )}
              <Subsection>
                <SubsectionHeader>
                  <FormattedMessage
                    {...smeBenefitFormMessages.extraFixedPremium}
                  />
                </SubsectionHeader>
                <TextGrid>
                  <DescriptionWrapper>
                    <FormattedMessage
                      {...smeBenefitFormMessages.extraFixedPremiumDescription}
                    />
                  </DescriptionWrapper>
                </TextGrid>
                <CheckboxField
                  label={
                    <FormattedMessage
                      {...smeBenefitFormMessages.extraFixedPremiumLabel}
                    />
                  }
                  name="extraFixedPremium"
                  disabled={disabled}
                />
              </Subsection>
            </>
          )}
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...smeBenefitFormMessages.defaultManagementType}
              />
            </SubsectionHeader>
            <RadioGroupField
              name="defaultManagementType"
              options={DEFAULT_MANAGEMENT_TYPES.map(type => ({
                label: formatMessage({
                  select: type,
                  messages: smeBenefitPensionManagementTypeMessages,
                }),
                value: type,
              }))}
              required
              disabled={disabled}
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.premiumWaiver} />
            </SubsectionHeader>
            <RadioGroupField
              name="premiumWaiver"
              options={[
                {
                  label: formatMessage(commonMessages.yes),
                  value: 'yes',
                },
                {
                  label: formatMessage(commonMessages.no),
                  value: 'no',
                },
              ]}
              required
              disabled={disabled}
            />
          </Subsection>
          {values.premiumWaiver === 'yes' && (
            <Subsection>
              <SubsectionHeader
                iconButton={
                  <IconButton
                    onClick={() => setIsConversionModalOpen(true)}
                    label={formatMessage(a11yMessages.information)}
                    icon={info}
                    size="small"
                    disabled={disabled}
                  />
                }
              >
                <FormattedMessage {...smeBenefitFormMessages.conversion} />
              </SubsectionHeader>
              <CheckboxField
                name="convertingPremiumWaiver"
                label={<FormattedMessage {...smeBenefitFormMessages.convert} />}
                disabled={disabled}
              />
              {values.convertingPremiumWaiver && (
                <TextGrid>
                  <TextField
                    name="convertingPremiumWaiverFrom"
                    dense
                    label={
                      <FormattedMessage
                        {...smeBenefitFormMessages.conversionFrom}
                      />
                    }
                    required
                    gridMargin
                    disabled={disabled}
                  />
                </TextGrid>
              )}
            </Subsection>
          )}

          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.premiumDeduction} />
            </SubsectionHeader>
            <DescriptionWrapper>
              <FormattedMessage
                {...smeBenefitFormMessages.premiumDeductionDescription}
              />
            </DescriptionWrapper>
            <CheckboxGroupField
              name="premiumDeductions"
              options={PREMIUM_DEDUCTIONS.map(deduction => ({
                label: formatMessage({
                  select: deduction,
                  messages: smePensionPremiumDeductionMessages,
                }),
                value: deduction,
              }))}
              disabled={disabled}
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
            </SubsectionHeader>
            {benefitPackages?.length ? (
              <>
                <DescriptionWrapper>
                  <FormattedMessage
                    {...smeBenefitFormMessages.benefitPackagesDescription}
                  />
                </DescriptionWrapper>

                <CheckboxGroupField
                  name="benefitPackageIds"
                  options={benefitPackages.map(benefitPackage => ({
                    label: benefitPackage.name,
                    value: benefitPackage.id,
                  }))}
                  disabled={disabled}
                />
              </>
            ) : (
              <NotificationCard type="warning">
                <FormattedMessage
                  {...commonBenefitMessages.noBenefitPackages}
                />
              </NotificationCard>
            )}
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={disabled || !isValid}
            >
              <FormattedMessage {...smeBenefitFormMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <ConversionModal
        isOpen={isConversionModalOpen}
        setIsOpen={setIsConversionModalOpen}
      />
    </>
  );
};
