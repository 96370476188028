import { DigitalAdvice } from '@frontend/benefit-types';
import { Formik } from '@frontend/formik';
import {
  companyEditBenefitQuery,
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  companyEditBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  DigitalAdviceForm,
  FormValues,
  validationSchema,
} from '../../form/digital-advice';
import { getBenefitPackageOptions } from '../../form/utils';
import { COMPANY_EDIT_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditDigitalAdvice: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditBenefitQuery,
    companyEditBenefitQueryVariables
  >(COMPANY_EDIT_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company?.benefitPackages || !data.flexBenefit?.entitlementRules) {
    return <EmptyState error={error} />;
  }

  const benefit: DigitalAdvice<Benefit> = data.flexBenefit;

  const { benefitPackages } = data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'nordea_node_digital_advice',
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, entitlementRules } = benefit;

  if (!entitlementRules) {
    return null;
  }

  const initialValues: FormValues = {
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    effectiveFrom: '',
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl, true)}
    >
      <DigitalAdviceForm
        benefitPackageOptions={benefitPackageOptions}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        submissionError={submissionError}
      />
    </Formik>
  );
};
